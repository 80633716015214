import { DocumentElementType } from "enums";

const senitizeDataset = (
  fields: DatasetDesign["fields"]["fields"],
  data: Record<string, any>
) => {
  const mediaKeys: string[] = [];
  fields?.forEach((f) => {
    if (
      f.type === DocumentElementType.Image ||
      f.type === DocumentElementType.File ||
      f.type === DocumentElementType.AudioVideo ||
      // f.type === DocumentElementType.Video
      f.type === DocumentElementType.AudioVideo
    ) {
      mediaKeys.push(f.slug);
    }
  });
  for (const key of mediaKeys) {
    const mediaField: any = data.fields[key];
    if (mediaField) delete mediaField.presign_url;
  }
  return data;
};
export default senitizeDataset;