import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { DEFAULT_WIDGET_PROPS } from "constants/gui";
import cloneDeep from "lodash/cloneDeep";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  widget: DashboardWidget | null;
  widgets: DashboardWidget[] | null;
  layout: DashboardLayout | null;
  isWidgetEditorOpen: boolean;
  isActionEditorOpen: boolean;
};

type Actions = {
  setWidget(widget: DashboardWidget | null): void;
  setWidgets(
    widget:
      | DashboardWidget[]
      | ((widgets: DashboardWidget[]) => DashboardWidget[])
  ): void;
  setLayout(
    layout: DashboardLayout | ((layout: DashboardLayout) => DashboardLayout)
  ): void;
  addWidget(widget: DashboardWidget, updateLayout?: boolean): void;
  updateWidget(widget: Partial<DashboardWidget>): void;
  setIsWidgetEditorOpen(value: boolean): void;
  setIsActionEditorOpen(value: boolean): void;
};

const useGuiDashboardV2StoreBase = create<State & Actions>()(
  devtools((set) => ({
    widget: null,
    isWidgetEditorOpen: false,
    widgets: null,
    layout: null,
    isActionEditorOpen: false,
    setIsActionEditorOpen(value) {
      set((state) => {
        return {
          ...state,
          isActionEditorOpen: value,
        };
      });
    },
    setIsWidgetEditorOpen(value) {
      set((state) => {
        return {
          ...state,
          isWidgetEditorOpen: value,
        };
      });
    },
    setWidget(widget) {
      set((state) => {
        return {
          ...state,
          widget,
        };
      });
    },
    setWidgets(widgets) {
      set((state) => {
        const newWidgets =
          typeof widgets === "function"
            ? widgets(state.widgets ?? [])
            : [...widgets];

        let widget = state.widget;
        if (widget) {
          const index = newWidgets.findIndex((w) => w.slug === widget?.slug);
          if (index !== -1) {
            widget = newWidgets[index];
          }
        }
        return {
          ...state,
          widget,
          widgets: newWidgets,
        };
      });
    },
    setLayout(layout) {
      set((state) => {
        return {
          ...state,
          layout:
            typeof layout === "function" ? layout(state.layout ?? []) : layout,
        };
      });
    },
    updateWidget(widget) {
      set((state) => {
        if (!state.widgets) {
          return state;
        }

        const widgets = cloneDeep(state.widgets);

        const index = widgets.findIndex((w) => w.slug === widget.slug);
        if (index === -1) {
          return state;
        }

        widgets[index] = {
          ...widgets[index],
          ...widget,
        };

        return {
          ...state,
          widgets,
        };
      });
    },
    addWidget(widget, updateLayout) {
      set((state) => {
        if (!state.widgets || !state.layout) {
          return state;
        }
        const widgets = cloneDeep(state.widgets);
        widgets.push(widget);

        if (!updateLayout) {
          return {
            ...state,
            widgets,
          };
        }

        const layout = cloneDeep(state.layout);
        const defaultProps = DEFAULT_WIDGET_PROPS[widget.type];

        const newItemWidth = defaultProps.w;
        const newItemHeight = defaultProps.h;

        const maxY = state.layout.reduce((acc, item) => {
          const bottomY = item.y + item.h;
          if (bottomY > acc) {
            return bottomY;
          }
          return acc;
        }, 0);

        const newItem = {
          ...defaultProps,
          i: widget.id,
          x: 0,
          y: maxY + 1,
        };

        for (let i = 0; i <= 24 - newItemWidth; i++) {
          // Adjust for 24 columns
          let spaceAvailable = true;
          for (let j = 0; j < layout.length; j++) {
            const item = layout[j];
            const occupiesLastRow =
              (item.y < maxY && item.y + item.h > maxY - newItemHeight) ||
              (item.y + item.h === maxY &&
                item.y + item.h - newItemHeight < maxY);
            if (
              occupiesLastRow &&
              i < item.x + item.w &&
              i + newItemWidth > item.x
            ) {
              spaceAvailable = false;
              break;
            }
          }
          if (spaceAvailable) {
            newItem.x = i;
            newItem.y = maxY - newItemHeight; // This ensures that the item aligns with the bottom of the last row
            break;
          }
        }

        layout.push(newItem);

        return {
          ...state,
          widgets,
          layout,
        };
      });
    },
  }))
);

export const useGuiDashboardV2Store = createSelectorHooks(
  useGuiDashboardV2StoreBase
);
