import { TextField } from "@mui/material";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { DateType } from "enums/Form";
import moment from "moment";
import { FC } from "react";

type DateFieldProps = {
  field: DataField;
  value?: string;
  onChange?(value: string): void;
};

const DateField: FC<DateFieldProps> = (props) => {
  const { value, onChange, field } = props;

  if (field.date_type === DateType.DateOnly) {
    return (
      <DatePicker
        value={moment(value)}
        onChange={(value) => {
          if (value) {
            onChange?.(value.format());
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            size="small"
            hiddenLabel
          />
        )}
      />
    );
  }

  if (field.date_type === DateType.TimeOnly) {
    return (
      <TimePicker
        value={moment(value)}
        onChange={(value) => {
          if (value) {
            onChange?.(value.format());
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            size="small"
            hiddenLabel
          />
        )}
      />
    );
  }

  return (
    <DateTimePicker
      value={moment(value)}
      onChange={(value) => {
        if (value) {
          onChange?.(value.format());
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          size="small"
          hiddenLabel
        />
      )}
    />
  );
};

export default DateField;
