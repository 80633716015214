import { GuiType } from "enums/gui";
import { AnimationLayoutRef } from "layouts/AnimationLayout";
import React, { RefObject } from "react";
import GuiDashboard from "views/app-view/gui-dashboard";
import GuiDashboardV2 from "views/app-view/gui-dashboard-v2";
import GuiDataset from "views/app-view/gui-dataset";
import GuiDocument from "views/app-view/gui-document";
import GuiReviewer from "views/app-view/gui-reviewer";
import WorkflowBoard from "views/app-view/gui-workflow-board";

type GuiTabContentProps = {
  tab: GuiTab;
  publicAccess?: boolean;
  guiTabEditorRef: RefObject<AnimationLayoutRef>;
};

const GuiTabContent: React.FC<GuiTabContentProps> = (props) => {
  const { tab, publicAccess, guiTabEditorRef } = props;

  switch (tab.tab_type) {
    case GuiType.Dashboard:
      return <GuiDashboard guiTab={tab} editable={!publicAccess} />;
    case GuiType.DashboardV2:
      return <GuiDashboardV2 guiTab={tab} editable={!publicAccess} />;
    case GuiType.RecordList:
      return (
        <GuiDataset
          guiTab={tab}
          publicAccess={publicAccess}
          guiTabEditorRef={guiTabEditorRef}
        />
      );
    case GuiType.WorkflowBoard:
      return (
        <WorkflowBoard
          datasetDesignSlug={tab.dataset_design_slug}
          statuses={tab.statuses_to_include || []}
          statusField={tab.status_field}
        />
      );
    case GuiType.Reviewer:
      return <GuiReviewer publicAccess={publicAccess} />;
    case GuiType.GuiDoc:
      return <GuiDocument guiTab={tab} />;
    default:
      return null;
  }
};

export default GuiTabContent;
