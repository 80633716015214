import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { setSearchParams } from "utils";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

const updateFiltersInUrl = (paramValues: Record<string, unknown>) => {
  setSearchParams((prev) => {
    let newQueryParams = Object.keys(prev)
      .filter((key) => !key.startsWith("filters."))
      .reduce<Record<string, string>>((acc, key) => {
        acc[key] = prev[key];
        return acc;
      }, {});

    Object.entries(paramValues).forEach(([key, value]) => {
      if (value != undefined) {
        newQueryParams[`filters.${key}`] =
          typeof value === "string"
            ? value
            : (value as any)?.toString
            ? (value as any)?.toString()
            : JSON.stringify(value);
      }
    });

    return newQueryParams;
  }, true);
};

type State = {
  guiParamValues: Record<string, unknown>;
  isParamEditorOpen: boolean;
  isFilterViewOpen: boolean;
  paramsUpdateId: number;
};

type Actions = {
  setGuiParamValues(values: Record<string, unknown>): void;
  mergeGuiParamValues(values: Record<string, unknown>): void;
  setIsParamEditorOpen(isOpen: boolean): void;
  setIsFilterViewOpen(isOpen: boolean): void;
  rerenderGui(): void;
};

export const useGuiParamsStoreBase = create(
  devtools(
    immer<State & Actions>((set) => ({
      guiParamValues: {},
      isParamEditorOpen: false,
      isFilterViewOpen: false,
      paramsUpdateId: Date.now(),
      setIsFilterViewOpen(isOpen) {
        set((state) => {
          state.isFilterViewOpen = isOpen;
        });
      },
      setIsParamEditorOpen(isOpen) {
        set((state) => {
          state.isParamEditorOpen = isOpen;
        });
      },
      setGuiParamValues(values) {
        set((state) => {
          state.guiParamValues = values;
          updateFiltersInUrl(state.guiParamValues);
        });
      },
      mergeGuiParamValues(values) {
        set((state) => {
          state.guiParamValues = { ...state.guiParamValues, ...values };
          updateFiltersInUrl(state.guiParamValues);
        });
      },
      rerenderGui() {
        set((state) => {
          state.paramsUpdateId = Date.now();
        });
      },
    }))
  )
);

const useGuiParamsStore = createSelectorHooks(useGuiParamsStoreBase);

export default useGuiParamsStore;
