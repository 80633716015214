import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { Box, IconButton, Stack, Typography, styled } from "@mui/material";
import useSocket from "hooks/useSocket";
import { FC, useEffect } from "react";
import useAgentChatStore from "store/stores/agent-chat";
import ScratchpadDisplay from "./ScratchpadDisplay";

const Container = styled(Box)({
  background: "#131313",
  width: "100%",
  minWidth: "1px",
});

const TopBar = styled(Stack)({
  borderBottom: "1px solid #35373A",
  borderRight: "1px solid #35373A",
  paddingLeft: "16px",
  paddingRight: "16px",
  paddingTop: "16px",
  background: "#1f2125",
  height: "57px",
});

type ScratchpadProps = {};

const Scratchpad: FC<ScratchpadProps> = (props) => {
  const { subscribe, unsubscribe } = useSocket();

  const setIsScratchpadActive = useAgentChatStore.useSetIsScratchpadActive();
  const setSelectedScratchpad = useAgentChatStore.useSetSelectedScratchpad();
  const selectedScratchpad = useAgentChatStore.useSelectedScratchpad();
  const selectedThread = useAgentChatStore.useSelectedThread();

  useEffect(() => {
    subscribe(
      "display_scratchpad",
      "display_scratchpad",
      (
        response: SocketResponse<{
          user_slug: string;
          thread_slug: string;
          scratchpad_slug: string;
          scratchpad?: Scratchpad;
        }>
      ) => {
        const { thread_slug, scratchpad_slug, scratchpad } = response.data;

        if (thread_slug !== `${selectedThread?.id}`) {
          return;
        }

        if (selectedScratchpad?.slug === scratchpad_slug) {
          return;
        }

        if (scratchpad) {
          setSelectedScratchpad(scratchpad);
          setIsScratchpadActive(true);
        }
      }
    );

    subscribe(
      "refresh_scratchpad",
      "refresh_scratchpad",
      (
        response: SocketResponse<{
          user_slug: string;
          thread_slug: string;
          scratchpad_slug: string;
          scratchpad?: Scratchpad;
        }>
      ) => {
        const { thread_slug, scratchpad_slug, scratchpad } = response.data;

        if (thread_slug !== `${selectedThread?.id}`) {
          return;
        }

        if (selectedScratchpad?.slug === scratchpad_slug && scratchpad) {
          setSelectedScratchpad(scratchpad);
        }
      }
    );

    return () => {
      unsubscribe("display_scratchpad", "display_scratchpad");
      unsubscribe("refresh_scratchpad", "refresh_scratchpad");
    };
  }, [
    subscribe,
    unsubscribe,
    selectedThread,
    selectedScratchpad,
    setSelectedScratchpad,
    setIsScratchpadActive,
  ]);

  return (
    <Container>
      <TopBar
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box></Box>
        <Typography fontSize={15} fontWeight={500}>
          {selectedScratchpad?.title}
        </Typography>
        <IconButton
          onClick={() => {
            setIsScratchpadActive(false);
            setSelectedScratchpad(null);
          }}
        >
          <CloseOutlined sx={{ width: "20px", height: "20px" }} />
        </IconButton>
      </TopBar>
      <ScratchpadDisplay />
    </Container>
  );
};

export default Scratchpad;
