import { useMutation } from "@tanstack/react-query";
import GuiTab from "models/GuiTab";

const useDuplicateGuiData = () => {
  return useMutation({
    mutationFn: (data: Parameters<typeof GuiTab.duplicate>[0]) => {
      return GuiTab.duplicate(data);
    },
  });
};

export default useDuplicateGuiData;
