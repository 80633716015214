import { FC } from "react";
import AgentAccess from "./AgentAccess";
import AgentDataset from "./AgentDataset";
import AgentDocuments from "./AgentDocuments";
import AgentSettings from "./AgentSettings";
import AgentSkills from "./AgentSkills";

type TabContentProps = {
  tabIndex: number;
  agent: Agent;
};

const TabContent: FC<TabContentProps> = (props) => {
  const { tabIndex, agent } = props;

  switch (tabIndex) {
    case 0:
      return <AgentSettings agent={agent} />;
    case 1:
      return <AgentSkills agent={agent} />;
    case 2:
      return <AgentDocuments agent={agent} />;
    case 3:
      return <AgentAccess agent={agent} />;
    case 4:
      return <AgentDataset agent={agent} />;

    default:
      return <></>;
  }
};

export default TabContent;
