import { ButtonProps } from "@mui/material";
import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { NAV_SELECTOR_TYPE } from "configs/AppConfig";
import { SYSTEM_NAV } from "configs/NavConfig";
import { cloneDeep } from "lodash";
import { SystemAppBarProps } from "module/Appbar";
import { ReactElement, ReactNode } from "react";
import { getSearchParams } from "utils";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { ApiModelMapping } from "../../queries/apiModelMapping";
import { UpdateMutationParams } from "../../queries/useUpdateItem";

export enum LAYOUT_VIEW {
  Left = "left",
  Right = "right",
}
const appMenu: AppMenu = [];
type ButtonOptions = {
  addFolder?: boolean;
  addItem?: boolean;
};

type TopBarProps = Partial<{
  title: ReactNode;
  icon: ReactNode;
  tabs: {
    label: string;
    icon: ReactElement;
    value: string | number;
    rightIcon?: ReactNode;
  }[];
  extra: { right?: ReactNode; left?: ReactNode };
  tabBarExtra?: ReactNode;
}>;

interface IStore {
  activeTheme: "light" | "dark" | "blue";
  setActiveTheme: (theme: "light" | "dark" | "blue") => void;
  firstItemKey: { id: string; slug: string } | null;
  setFirstItemKey: (key: { id: string; slug: string } | null) => void;
  isLoading: boolean;
  menu: AppMenu;
  setMenu: (_: AppMenu) => void;
  addButtonProps?: Partial<ButtonProps> | any;
  itemProps?: JSX.IntrinsicAttributes & {
    onClick: (_: Pick<AppItem, "key" | "title">, e?: any) => void;
    isActive: (_: Pick<AppItem, "key" | "title">) => boolean;
  };
  showActionButtons: boolean;
  setShowActionButtons: (_: boolean) => void;
  layout: "default" | "search";
  setLayout: (_: "default" | "search") => void;
  setButtonProps: (_: Partial<ButtonProps>) => void;
  buttonOptions: ButtonOptions;
  setButtonOptions: (_: ButtonOptions) => void;
  setItemProps: (_: IStore["itemProps"]) => void;
  resetStore: () => void;
  activeView: `${LAYOUT_VIEW}`;
  goToLeftView: () => void;
  goToRightView: () => void;
  goToView: (_: `${LAYOUT_VIEW}`) => void;
  onRename?: (_: {
    title: string;
    key: string;
    model?: keyof typeof ApiModelMapping;
    updateItemOptions?: Partial<UpdateMutationParams<any>>;
    nameKey?: string;
  }) => void;
  setOnRename: (_: IStore["onRename"]) => void;
  sideNavSelectorOptions: {
    key: string;
    title: string;
    icon?: string;
    id?: string;
  }[];
  sideNavSelectorValue: any;
  setSideNavSelectorValue: (value: any) => void;
  setSideNavSelectorOptions: (
    options: { key: string; title: string; icon?: string; id?: string }[]
  ) => void;
  sideNavItemsLoading: boolean;
  setSideNavItemsLoading: (loading: boolean) => void;
  showSideNavTopSelector: boolean;
  setShowSideNavTopSelector: (value: boolean) => void;
  enableSideNavSearch: boolean;
  searchValue: string;
  customSearch: boolean;
  setSearch: (value: IStore["searchValue"]) => void;
  setCustomSearch: (value: IStore["customSearch"]) => void;
  setEnableSideNavSearch: (value: boolean) => void;
  disableFolders: boolean;
  setDisableFolders: (value: boolean) => void;
  appBarProps: Partial<Omit<SystemAppBarProps, "ref" | "defaultValue" | "sx">>;
  setAppBarProps: (
    props: Partial<Omit<SystemAppBarProps, "ref" | "defaultValue" | "sx">>
  ) => void;
  sideNavSelectorProps: { onSelect?: (value: any) => void };
  setSideNavSelectorProps: (props: { onSelect?: (value: any) => void }) => void;
  sideNavSelectorType: `${NAV_SELECTOR_TYPE}`;
  setSideNavSelectorType: (_: `${NAV_SELECTOR_TYPE}`) => void;
  loadmore?: () => void;
  setLoadmoreCallback: (_: IStore["loadmore"]) => void;
  topBarProps: TopBarProps;
  setTopBarProps(props: TopBarProps): void;
  selectedTopBarTab: string | number;
  setSelectedTopBarTab(tab: string | number): void;
}
let initialState: IStore | undefined;

export const useSystemLayoutBaseStore = create(
  immer<IStore>((set, get) => {
    const store: IStore = {
      activeTheme: "light",
      firstItemKey: null,
      sideNavSelectorType: NAV_SELECTOR_TYPE.SIMPLE,
      sideNavSelectorOptions: SYSTEM_NAV,
      sideNavSelectorValue: SYSTEM_NAV[0],
      isLoading: false,
      menu: appMenu,
      addButtonProps: undefined,
      itemProps: undefined,
      onRename: undefined,
      showActionButtons: true,
      buttonOptions: { addFolder: true, addItem: true },
      showSideNavTopSelector: true,
      enableSideNavSearch: true,
      disableFolders: false,
      appBarProps: {},
      sideNavSelectorProps: {},
      searchValue: "",
      customSearch: false,
      topBarProps: {},
      selectedTopBarTab: "",
      setActiveTheme(theme) {
        set((state) => {
          state.activeTheme = theme;
        });
      },
      setSelectedTopBarTab(tab) {
        set((state) => {
          state.selectedTopBarTab = tab;
        });
      },
      setTopBarProps(props) {
        set((state) => {
          state.topBarProps = props;
        });
      },
      setSideNavSelectorType: (t) => {
        set((state) => {
          state.sideNavSelectorType = t;
        });
      },
      setFirstItemKey(key) {
        set((state) => {
          state.firstItemKey = key;
        });
      },
      setSearch(value) {
        set((state) => {
          state.searchValue = value;
        });
      },
      setCustomSearch(b) {
        set((state) => {
          state.customSearch = b;
        });
      },
      setSideNavSelectorProps(props) {
        set((state) => {
          state.sideNavSelectorProps = props;
        });
      },
      setAppBarProps(props) {
        set((state) => {
          state.appBarProps = props;
        });
      },
      setDisableFolders(value) {
        set((state) => {
          state.disableFolders = value;
        });
      },
      setShowSideNavTopSelector(value) {
        set((state) => {
          state.showSideNavTopSelector = value;
        });
      },
      setEnableSideNavSearch(value) {
        set((state) => {
          state.enableSideNavSearch = value;
        });
      },
      setButtonOptions: (props) => {
        set((state) => {
          state.buttonOptions = { ...state.buttonOptions, ...props };
          return state;
        });
      },
      layout: "default",
      sideNavItemsLoading: false,
      setSideNavItemsLoading(loading: boolean) {
        set((state) => {
          state.sideNavItemsLoading = loading;
        });
      },
      setSideNavSelectorOptions(options) {
        set((state) => {
          state.sideNavSelectorOptions = options;
        });
      },
      setSideNavSelectorValue(value) {
        set((state) => {
          state.sideNavSelectorValue = value;
        });
      },
      setLayout: (props) => {
        set((state) => {
          state.layout = props;
          return state;
        });
      },
      activeView:
        (getSearchParams().get("layout_c_name") as IStore["activeView"]) ||
        LAYOUT_VIEW.Left,
      setItemProps: (props) => {
        set((state) => {
          state.itemProps = props as any;
          return state;
        });
      },
      setButtonProps: (props) => {
        set((state) => {
          state.addButtonProps = props as any;
          return state;
        });
      },
      setMenu: (appm) => {
        set((state) => {
          state.menu = appm;
          return state;
        });
      },
      goToLeftView: () => {
        set((state) => {
          state.activeView = LAYOUT_VIEW.Left;
        });
      },
      goToRightView: () => {
        set((state) => {
          state.activeView = LAYOUT_VIEW.Right;
        });
      },
      goToView: (view) => {
        set((state) => {
          state.activeView = view;
        });
      },
      setOnRename: (callback) => {
        set((state) => {
          state.onRename = callback;
        });
      },
      setLoadmoreCallback: (cb) => {
        set((state) => {
          state.loadmore = cb;
        });
      },
      setShowActionButtons: (value) => {
        set((state) => {
          state.showActionButtons = value;
        });
      },
      resetStore: () => {
        set(() => initialState);
      },
    };
    if (!!initialState) initialState = cloneDeep(store);

    return store;
  })
);

export const useSystemLayoutStore = createSelectorHooks<IStore>(
  useSystemLayoutBaseStore as any
);
