import { zodResolver } from "@hookform/resolvers/zod";
import PlaylistAdd from "@mui/icons-material/PlaylistAdd";
import { LoadingButton } from "@mui/lab";
import { Button, DrawerProps, Stack, styled } from "@mui/material";
import FormField from "components/FormField";
import GfDrawer from "components/GfDrawer";
import { DocumentElementType } from "enums/Form";
import useCurrentUser from "hooks/useCurrentUser";
import FusionModel from "models/Fusion";
import { FC, useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const DrawerStyled = styled(GfDrawer)(({ theme }) => ({}));

const formSchema = z.object({
  user_slug: z.string(),
});

type FormType = z.infer<typeof formSchema>;

type TransferOwnerShipDrawerProps = {
  connectionSlug?: string;
  onClose(): void;
  onDone?(): void;
} & DrawerProps;

const TransferOwnerShipDrawer: FC<TransferOwnerShipDrawerProps> = (props) => {
  const {
    onClose: propsOnClose,
    onDone,
    connectionSlug,
    ...drawerProps
  } = props;

  const user = useCurrentUser();

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
  });

  const [loading, setLoading] = useState(false);

  const onClose = useCallback(() => {
    propsOnClose();
    form.reset();
  }, [propsOnClose]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = async (data: FormType) => {
    const { user_slug } = data;

    if (!connectionSlug) {
      return;
    }

    setLoading(true);
    await FusionModel.transferConnectionOwnership(connectionSlug, user_slug);

    onDone?.();
    onClose();
  };

  return (
    <DrawerStyled
      anchor="right"
      onClose={handleClose}
      title="Create Connection"
      icon={<PlaylistAdd />}
      width="420px"
      defaultTabKey={0}
      ModalProps={{
        keepMounted: false,
      }}
      keepMounted={false}
      variant="temporary"
      sx={{
        ".MuiPaper-root": {
          height: "100%",
        },
      }}
      {...drawerProps}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Stack m={2} gap={2}>
            <FormField
              label="Transfer Ownership to"
              type={DocumentElementType.User}
              formControl={{ control: form.control, name: "user_slug" }}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            justifyContent="center"
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              type="button"
              disabled={loading}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              variant="contained"
              type="submit"
            >
              Submit
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </DrawerStyled>
  );
};

export default TransferOwnerShipDrawer;
