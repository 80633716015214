import { zodResolver } from "@hookform/resolvers/zod";
import GridViewOutlined from "@mui/icons-material/GridViewOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, Button, DrawerProps, Stack, styled } from "@mui/material";
import FormField from "components/FormField";
import GfDrawer from "components/GfDrawer";
import { DocumentElementType } from "enums";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const Container = styled(Box)({
  padding: "16px",
});

const formSchema = z.object({
  enabled: z.number().default(0),
});

type EditAgentAccessFormType = z.infer<typeof formSchema>;

type AgentAccessEditDrawerProps = {
  agentUser?: AgentUser;
  onSubmitDone?(): void;
} & DrawerProps;

const AgentAccessEditDrawer: FC<AgentAccessEditDrawerProps> = (props) => {
  const { open, onClose, agentUser, onSubmitDone, ...drawerProps } = props;

  const form = useForm<EditAgentAccessFormType>({
    defaultValues: { enabled: agentUser?.enabled ? 1 : 0 },
    resolver: zodResolver(formSchema),
  });

  const { control, handleSubmit, setValue } = form;

  const { mutateAsync: updateAgentUser, isLoading } = useUpdateItem({
    modelName: ApiModels.AgentUser,
  });

  useEffect(() => {
    if (open && agentUser) {
      setValue("enabled", agentUser.enabled ? 1 : 0);
    }
  }, [open, setValue, agentUser]);

  const onSubmit = async (data: EditAgentAccessFormType) => {
    if (agentUser) {
      await updateAgentUser({
        slug: agentUser.slug,
        data: {
          ...data,
          enabled: Boolean(data.enabled),
        },
      });
      onClose?.({}, "escapeKeyDown");
      onSubmitDone?.();
    }
  };

  return (
    <GfDrawer
      anchor="right"
      open={open}
      width="420px"
      title="Edit Agent Access"
      icon={<GridViewOutlined />}
      onClose={onClose}
      {...drawerProps}
    >
      <Container>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField
              label="Enabled"
              type={DocumentElementType.Boolean}
              formControl={{ control, name: "enabled" }}
            />
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1.5}
              sx={{ mt: 2 }}
            >
              <Button
                variant="outlined"
                color="inherit"
                sx={{
                  border: "none",
                  color: "#fff",
                  background: (theme) => theme.palette.background.GF10,
                }}
                onClick={(e) => onClose?.(e, "escapeKeyDown")}
                fullWidth
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  borderColor: "#fff",
                  boxShadow: "none",
                }}
                fullWidth
                loading={isLoading}
              >
                Save Changes
              </LoadingButton>
            </Stack>
          </form>
        </FormProvider>
      </Container>
    </GfDrawer>
  );
};

export default AgentAccessEditDrawer;
