import { Grid, styled } from "@mui/material";
import { get } from "lodash";
import React from "react";
import { GridKeyMap, ListItemAction, ListingItem } from ".";
import FolderCard from "../FolderCard";
import GridCard from "../GridCard";

const GridRow = styled(Grid)({});
const GridBox = styled(Grid)({});

type GridViewProps = {
  data: ListingItem[];
  gridKeyMap?: GridKeyMap;
  actions?: ListItemAction[];
  folderActions?: {
    title?: React.ReactNode;
    icon?: React.ReactNode;
    onClick?(folder: Folder): void;
  }[];
  onOpenClick?(id: string): void;
  onMediaClick?(id: string): void;
  onFolderClick?(folder: Folder): void;
};

const GridView: React.FC<GridViewProps> = (props) => {
  const {
    data = [],
    actions = [],
    folderActions = [],
    gridKeyMap,
    onOpenClick,
    onFolderClick,
    onMediaClick,
  } = props;

  return (
    <GridRow container spacing={"20px"}>
      {data.map((item) => {
        if (item.type === "folder") {
          return (
            <GridBox item xs={6} md={4} lg={3} key={item.folder.slug}>
              <FolderCard
                folder={item.folder as Folder}
                onClick={() => onFolderClick?.(item.folder as Folder)}
                actions={folderActions}
              />
            </GridBox>
          );
        }

        const id = get(item, `item.${gridKeyMap?.id}`) as string;
        const imageUrl = get(item, `item.${gridKeyMap?.imageUrl}`) as string;
        const icon = get(item, `item.${gridKeyMap?.icon}`) as string;
        const title = get(item, `item.${gridKeyMap?.title}`) as string;
        const subtitle = get(item, `item.${gridKeyMap?.subtitle}`) as string;
        const link = get(item, `item.${gridKeyMap?.link}`) as string;
        return (
          <GridBox item xs={6} md={4} lg={3} key={id}>
            <GridCard
              id={id}
              imageUrl={imageUrl}
              icon={icon}
              title={title}
              subtitle={subtitle}
              link={link}
              actions={actions}
              onOpenClick={onOpenClick}
              onMediaClick={onMediaClick}
            />
          </GridBox>
        );
      })}
    </GridRow>
  );
};

export default GridView;
