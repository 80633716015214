import { Tooltip } from '@mui/material';
import List from "@mui/material/List";
import styled from "@mui/material/styles/styled";

const BaseListStyled = styled(List)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0 13px 0 0",
  fontSize: "15px",
  lineHeight: "24px",
  fontWeight: "400",

  li: {
    width: "auto",
    padding: "0 4px",

    "&:hover": {
      ">a": {
        background: theme.palette.background.GF10,
      },
    },

    a: {
      height: "34px",
      textDecoration: "none",
      color: theme.palette.common.white,
      padding: "7px 7px",
      width: "auto",
      cursor: "pointer",
      display: "block",
      transition: "all 0.4s ease",
      borderRadius: "4px",
      margin: "0",

      "&:hover": {
        background: theme.palette.background.GF10,
      },
    },
  },
}));

const UserMenuListStyled = styled(BaseListStyled)(({ theme }) => ({
  ":after": {
    content: "''",
    position: "absolute",
    width: "1px",
    height: "24px",
    background:  theme.palette.background.GF10,
    right: "0",
    top: "7px",
  },
}));
const RcToolTip = styled(Tooltip)(({ theme }) => ({
".MuiTooltip-tooltip":{
  background:  theme.palette.text.primary,
  ".MuiTooltip-arrow":{
  background:  theme.palette.text.primary,
}
}  

}));
const Styles = { UserMenuListStyled, BaseListStyled,RcToolTip };

export default Styles;
