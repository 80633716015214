import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type Position = { left: number; top: number };
type View = "chat-list" | "messages";
type Size = { width: number; height: number };

type State = {
  isOpen: boolean;
  position: Position;
  activeChat: string | null;
  activeView: View;
  size: Size;
  showLoadingMessage: boolean;
};

type Actions = {
  setIsOpen(isOpen: boolean): void;
  setPosition(position: Position | ((position: Position) => Position)): void;
  setActiveChat(chat: string): void;
  setActiveView(view: View): void;
  setSize(size: Size): void;
  setShowLoadingMessage(loading: boolean): void;
};

export const useGFChatWindowBase = create(
  devtools(
    immer<State & Actions>((set) => ({
      isOpen: false,
      position: { left: 48, top: 60 },
      activeChat: null,
      activeView: "chat-list",
      size: { width: 380, height: 550 },
      showLoadingMessage: false,
      setIsOpen(isOpen) {
        set((state) => {
          state.isOpen = isOpen;
        });
      },
      setPosition(position) {
        set((state) => {
          if (typeof position === "function") {
            state.position = position(state.position);
          } else {
            state.position = position;
          }
        });
      },
      setActiveChat(chat) {
        set((state) => {
          state.activeChat = chat;
        });
      },
      setActiveView(view) {
        set((state) => {
          state.activeView = view;
        });
      },
      setSize(size) {
        set((state) => {
          state.size = size;
        });
      },
      setShowLoadingMessage(loading) {
        set((state) => {
          state.showLoadingMessage = loading;
        });
      },
    }))
  )
);

const useGFChatWindow = createSelectorHooks(useGFChatWindowBase);

export default useGFChatWindow;
