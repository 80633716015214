import TabStyled from "components/TabStyled";
import InnerPageLayout from "layouts/inner-app-layout";
import { FC, useState } from "react";
import AgentDocumentDetails from "views/app-view/ss-agent/EditDrawer/AgentDocumentSidebar/AgentDocumentDetails";

type TabContentProps = {
  selectedTab: string;
  agentDocument?: AgentDocument;
};

const TabContent: FC<TabContentProps> = (props) => {
  const { selectedTab, agentDocument } = props;

  switch (selectedTab) {
    case "details":
      return <AgentDocumentDetails agentDocument={agentDocument} />;
    default:
      return null;
  }
};

type AgentDocumentSideBarProps = {
  agentDocument?: AgentDocument;
};

const AgentDocumentSideBar: FC<AgentDocumentSideBarProps> = (props) => {
  const { agentDocument } = props;

  const [selectedTab, setSelectedTab] = useState("details");

  return (
    <InnerPageLayout
      hideHeader
      variant="standard"
      value={selectedTab}
      onChange={(_, value) => {
        setSelectedTab(value);
      }}
      updateUrl={false}
      sx={{ width: "100%" }}
      tabList={[
        {
          label: <TabStyled title="Details" />,
          value: "details",
        },
      ]}
    >
      <TabContent selectedTab={selectedTab} agentDocument={agentDocument} />
    </InnerPageLayout>
  );
};

export default AgentDocumentSideBar;
