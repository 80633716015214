import Model from "models";

class OpenAI {
  private model = new Model("/openai", "gui-fusion");

  async listModels() {
    return this.model.list<OpenAIModel>({ path: "/models" });
  }

  async uploadFileFormS3(data: {
    key: string;
    filename?: string;
    bucket?: string;
    [key: string]: unknown;
  }) {
    return this.model.sendRequest<OpenAIFile>("/upload-from-s3", "POST", data);
  }
}

export default new OpenAI();
