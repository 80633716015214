import AddCircle from "@mui/icons-material/AddCircle";
import { Box, IconButton, Input } from "@mui/material";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import SendButton from "assets/icons/SendButton";
import cloneDeep from "lodash/cloneDeep";
import useCreateMessage from "queries/copilot/useCreateMessage";
import { FC, KeyboardEventHandler, useRef } from "react";
import useGFChatWindow from "store/stores/gf-chat-window";
import { v4 } from "uuid";

type ChatInputFieldProps = {};

const ChatInputField: FC<ChatInputFieldProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>();
  const queryClient = useQueryClient();

  const activeChat = useGFChatWindow.useActiveChat();
  const setShowLoadingMessage = useGFChatWindow.useSetShowLoadingMessage();

  const { mutateAsync: createMessage } = useCreateMessage();

  const handleSend = async () => {
    if (!inputRef.current) {
      return;
    }

    const value = inputRef.current?.value;
    if (activeChat && value) {
      inputRef.current.value = "";
      setShowLoadingMessage(true);

      const id = v4();
      queryClient.setQueryData<InfiniteData<ApiResponse<CopilotMessage[]>>>(
        ["copilot", "threads", activeChat, "messages"],
        (oldData) => {
          const prev = cloneDeep(oldData);
          if (!prev) {
            return prev;
          }

          prev.pages.at(-1)?.data.unshift({
            id,
            slug: `${Date.now()}:${v4()}`,
            is_active: 1,
            is_deleted: 0,
            created_at: new Date().toISOString(),
            updated_at: null,
            open_ai_message_id: "",
            sender: "user",
            value,
          });

          return prev;
        }
      );

      const message = await createMessage({
        threadSlug: activeChat,
        data: {
          message: value,
        },
      });

      if (message) {
        queryClient.setQueryData<InfiniteData<ApiResponse<CopilotMessage[]>>>(
          ["copilot", "threads", activeChat, "messages"],
          (oldData) => {
            const prev = cloneDeep(oldData);
            if (!prev) {
              return prev;
            }

            const page = prev.pages.at(-1);

            if (!page) {
              return prev;
            }

            page.data = page.data.map((d) => (d.id === id ? message : d));

            return prev;
          }
        );
      }
    }
  };

  const handleKeydown: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  return (
    <Box>
      <Input
        inputRef={inputRef}
        sx={{
          height: "56px",
          px: 1,
          borderTop: "1px solid #EBEDF1",

          ".MuiInputBase-input": {
            color: "#000",
          },
        }}
        startAdornment={
          <AddCircle fontSize="small" sx={{ color: "#b3b3b3", mr: 1 }} />
        }
        endAdornment={
          <IconButton type="submit" size="small">
            <SendButton fontSize="small" sx={{ color: "#b3b3b3" }} />
          </IconButton>
        }
        disableUnderline
        fullWidth
        placeholder="Type / for commands"
        multiline
        maxRows={2}
        onKeyDown={handleKeydown}
      />
    </Box>
  );
};

export default ChatInputField;
