import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Box, Stack } from "@mui/material";
import { ViewButton } from "components/List/List";
import { SocketState } from "enums";
import useSocket from "hooks/useSocket";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import useListInfiniteItems from "queries/useListInfiniteItems";
import { FC, useEffect, useMemo } from "react";
import { useSocketStore } from "store/stores/socket";
import MessageInputField from "./MessageInputField";
import MessageItem from "./MessageItem";

type ChatViewProps = {
  selectedTabData?: IncludeTabs;
  datasetSlug?: string;
  datasetDesignSlug?: string;
};

const ChatView: FC<ChatViewProps> = (props) => {
  const { selectedTabData, datasetSlug, datasetDesignSlug } = props;

  const { subscribe, unsubscribe } = useSocket();

  const socketState = useSocketStore.useState();

  const { data: dataset } = useGetItem({
    modelName: ApiModels.Dataset,
    slug: datasetSlug,
    requestOptions: { path: datasetDesignSlug },
    queryKey: [ApiModels.Dataset, { datasetDesignSlug, datasetSlug }],
  });

  const datasetQueryFilter = useMemo(
    () => ({
      field_name:
        selectedTabData?.association_type === "reference_table"
          ? "id"
          : selectedTabData?.linking_field,
      field_value:
        selectedTabData?.association_type === "reference_table"
          ? ((dataset?.fields?.[selectedTabData?.linking_field!] ||
              dataset?.id) as string)
          : datasetSlug,
    }),
    [dataset, datasetSlug, selectedTabData]
  );

  const {
    data: datasetResult,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetched,
    refetch,
  } = useListInfiniteItems({
    modelName: ApiModels.Dataset,
    requestOptions: {
      query: {
        dataset_type_slug: selectedTabData?.dataset_to_include,
        ...datasetQueryFilter,
        included_fields: "all",
        // filter: { conditions: tabMetadata.filter_rules ?? [] },
      },
      path: `list/${selectedTabData?.dataset_to_include}`,
    },
    queryKey: [
      ApiModels.Dataset,
      {
        dataset_type_slug: selectedTabData?.dataset_to_include,
        // title: searchTitle,
        rowsInfoString: "all",
        ...datasetQueryFilter,
        // filter: { conditions: tabMetadata.filter_rules ?? [] },
      },
    ],
  });

  useEffect(() => {
    if (socketState === SocketState.Open) {
      const updateChatViewHandler = (data: {
        data: {
          guiSlug: string;
          mainTabId: string;
          subTabId: string;
          parentId: string;
        };
      }) => {
        const { subTabId, parentId } = data.data;

        if (selectedTabData?.id === subTabId && parentId === datasetSlug) {
          refetch({
            refetchPage(_, index, allPages) {
              return index === allPages.length - 1;
            },
          }).then(() => {
            // fetchNextPage();
          });
        }
      };

      subscribe(
        "update_chat_view",
        "updateChatViewHandler",
        updateChatViewHandler
      );

      console.log("subscribed updateChatViewHandler");
    }

    return () => {
      unsubscribe("update_chat_view", "updateChatViewHandler");
      console.log("unsubscribe updateChatViewHandler");
    };
  }, [refetch, socketState, subscribe, unsubscribe]);

  return (
    <Box sx={{ height: "100%" }}>
      <Stack direction="column" sx={{ height: "100%" }}>
        <Box sx={{ flexGrow: 1 }}>
          {datasetResult?.pages.map((page) =>
            page.data.map((item, index) => (
              <MessageItem
                senderName={item.sender_name}
                invert={index % 2 === 0}
              >
                {item.message}
              </MessageItem>
            ))
          )}
          {hasNextPage && (
            <ViewButton
              fullWidth
              variant="outlined"
              size="large"
              disableRipple
              startIcon={<ArrowDropDown />}
              onClick={() => fetchNextPage()}
              loading={isFetching}
              disabled={isFetching}
            >
              View more
            </ViewButton>
          )}
        </Box>
        <Box>
          <MessageInputField
            fusionSlug={selectedTabData?.fusion_id}
            parentSlug={datasetSlug}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default ChatView;
