import AddBoxOutlined from "@mui/icons-material/AddBoxOutlined";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { IconButton, Stack } from "@mui/material";
import FormField from "components/FormField";
import ListWidget from "components/ListWidget";
import { FC, useMemo } from "react";
import useFusionAction from "store/stores/fusion-action";
import { useGuiDashboardV2Store } from "store/stores/gui-dashboard-v2";

type ActionsTabProps = {};

const ActionsTab: FC<ActionsTabProps> = (props) => {
  const widget = useGuiDashboardV2Store.useWidget();
  const setWidget = useFusionAction.useSetWidget();
  const setOpen = useFusionAction.useSetActionEditorOpen();
  const setWidgetActionType = useFusionAction.useSetWidgetActionType();
  const setAction = useFusionAction.useSetEditorAction();

  const actions = useMemo(
    () =>
      widget?.associated_actions?.map((action) => ({
        label: action.action_title,
        id: action.id,
        icon: action.action_icon,
      })) ?? [],
    [widget?.associated_actions]
  );

  return (
    <>
      <FormField
        label="Widget Actions"
        extra={
          <IconButton
            sx={{ p: 0 }}
            onClick={() => {
              setWidget(widget);
              setWidgetActionType("widget");
              setAction(undefined);
              setOpen(true);
            }}
          >
            <AddBoxOutlined fontSize="small" />
          </IconButton>
        }
        collapsible
      >
        <ListWidget
          data={actions}
          itemActions={(_, index) => (
            <Stack direction="row" alignItems="center" gap={1}>
              <IconButton sx={{ p: 0 }}>
                <DeleteOutline fontSize="small" />
              </IconButton>
              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  if (widget?.associated_actions?.[index]) {
                    setWidget(widget);
                    setWidgetActionType("widget");
                    setAction(widget.associated_actions[index]);
                    setOpen(true);
                  }
                }}
              >
                <EditOutlined fontSize="small" />
              </IconButton>
            </Stack>
          )}
        />
      </FormField>
    </>
  );
};

export default ActionsTab;
