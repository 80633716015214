import { useTheme } from "@mui/material";
import { CustomCellEditorProps } from "ag-grid-react";
import Cell from "components/DatasetTable/Cell";
import IOSSwitch from "components/IOSSwitch";
import { FC, memo } from "react";

const BooleanCellEditor: FC<CustomCellEditorProps> = memo((props) => {
  const theme = useTheme();

  return (
    <Cell
      sx={{
        justifyContent: "flex-start",
        paddingLeft: 2,
        background: theme.palette.background.TableBody,
      }}
      isLoading={!props.data}
    >
      <IOSSwitch
        checked={Boolean(props.value)}
        // sx={{
        //   ".Mui-checked+.MuiSwitch-track": {
        //     backgroundColor: theme.palette.text.primary,
        //   },
        // }}
        onChange={(_, checked) => {
          console.log({ checked });
          props.onValueChange?.(checked ? 1 : 0);
        }}
      />
    </Cell>
  );
});

export default BooleanCellEditor;
