import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";

const useDataFields = (designSlug?: string) => {
  const { data: datasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: designSlug,
  });

  return datasetDesign?.fields.fields ?? [];
};

export default useDataFields;
