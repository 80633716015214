import { UniqueIdentifier } from "@dnd-kit/core";
import { Box } from "@mui/material";
import { SortableList } from "components/SortableList";
import Spin from "components/Spin";
import { FC, MouseEvent } from "react";
import { MenuContainer } from "../index.styles";
import ListItem from "./ListItem";
import MenuListContainer from "./MenuListContainer";

export type SortItemType = {
  id: UniqueIdentifier;
  children: { id: UniqueIdentifier; data: { id: string; slug: string } }[];
}[];

type SideNavItemsListingProps = {
  className?: string;
  listContainerClassName?: string;
  listItemClassName?: string;
  layout?: "search" | "default";
  searchValue?: string;
  disableFolders: boolean;
  menu: AppMenu;
  itemsLoading: boolean;
  sortItems: SortItemType;
  sortItemFolderMap: Map<any, any>;
  sortItemMap: Map<any, any>;
  enableContextMenu?: boolean;
  enableItemOptionMenu?: boolean;

  containerSortingHandler(container: SortItemType): void;
  handleContainerContextMenu(event: MouseEvent, id: string): void;
  handleItemContextMenu(event: MouseEvent, extra: AppItem): void;
  itemSortingHandler(
    items: { id: UniqueIdentifier; data: { id: string; slug: string } }[],
    containerId: UniqueIdentifier
  ): void;
};

const SideNavItemsListing: FC<SideNavItemsListingProps> = (props) => {
  const {
    className,
    listContainerClassName,
    listItemClassName,
    layout,
    searchValue,
    disableFolders,
    menu,
    itemsLoading,
    sortItems,
    containerSortingHandler,
    sortItemFolderMap,
    handleContainerContextMenu,
    itemSortingHandler,
    sortItemMap,
    handleItemContextMenu,
    enableContextMenu,
    enableItemOptionMenu,
  } = props;

  return (
    <MenuContainer className={className} sx={{ color: "black" }}>
      {layout === "search" || searchValue?.length || disableFolders ? (
        <Box>
          {menu.map((m) => {
            return (
              <ListItem
                className={listContainerClassName}
                listItemClassName={listItemClassName}
                item={m as any}
                hideDragHandler
                enableContextMenu={enableContextMenu}
                enableItemOptionMenu={enableItemOptionMenu}
              />
            );
          })}
        </Box>
      ) : (
        <Spin spinning={itemsLoading}>
          <Box sx={{ ".SortableItem": { marign: 0 } }}>
            {sortItems?.length > 0 && (
              <SortableList
                items={sortItems}
                onChange={containerSortingHandler}
                renderItem={(item) => {
                  const folder = sortItemFolderMap.get(item.id);
                  if (!folder) return null;

                  return (
                    <SortableList.Item key={item.id} id={item.id} handle>
                      <MenuListContainer
                        title={folder.name}
                        id={item.id}
                        handleContextMenu={handleContainerContextMenu}
                        enableContextMenu={enableContextMenu}
                        enableItemOptionMenu={enableItemOptionMenu}
                      >
                        {item?.children?.length > 0 && (
                          <SortableList
                            items={item?.children}
                            onChange={(newItem) =>
                              itemSortingHandler(newItem, item.id)
                            }
                            renderItem={(folderItem) => {
                              const fItem = sortItemMap.get(folderItem.id);
                              if (!fItem) return null;

                              return (
                                <SortableList.Item
                                  key={folderItem.id}
                                  id={folderItem.id}
                                  handle
                                >
                                  <ListItem
                                    className={listContainerClassName}
                                    listItemClassName={listItemClassName}
                                    item={fItem}
                                    onItemMenuClick={
                                      handleItemContextMenu as any
                                    }
                                    enableContextMenu={enableContextMenu}
                                    enableItemOptionMenu={enableItemOptionMenu}
                                  />
                                </SortableList.Item>
                              );
                            }}
                          />
                        )}
                      </MenuListContainer>
                    </SortableList.Item>
                  );
                }}
              />
            )}
          </Box>
        </Spin>
      )}
    </MenuContainer>
  );
};

export default SideNavItemsListing;
