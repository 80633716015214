import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, Button, ButtonGroup, Stack, styled } from "@mui/material";
import Scrollbar from "components/Scrollbar";
import { DASHBOARD_WIDGET_TYPE } from "constants/gui";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useGuiDashboardV2Store } from "store/stores/gui-dashboard-v2";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import { z } from "zod";
import ActionsTab from "./ActionsTab";
import EditTab from "./EditTab";
import FusionTab from "./FusionTab";
import SettingsTab from "./SettingsTab";

const TabContentContainer = styled(Box)({
  padding: "20px",

  ".form-field-header": {
    marginBottom: "10px",
  },
});

type TabContentProps = {
  activeTab: string | number;
  onClose(): void;
};

const TabContentSwitch: FC<TabContentProps> = (props) => {
  switch (props.activeTab) {
    case 0:
      return <EditTab />;
    case 1:
      return <FusionTab />;
    case 2:
      return <ActionsTab />;
    case 3:
      return <SettingsTab />;
    default:
      return <></>;
  }
};

const widgetSchema = z.object({
  type: z.enum([
    DASHBOARD_WIDGET_TYPE.BAR,
    DASHBOARD_WIDGET_TYPE.DATA_LIST,
    DASHBOARD_WIDGET_TYPE.DIVIDER,
    DASHBOARD_WIDGET_TYPE.LINE,
    DASHBOARD_WIDGET_TYPE.PIE,
    DASHBOARD_WIDGET_TYPE.STAT,
  ]),
  title: z.string(),
  widget_data: z
    .object({
      icon: z.string().optional(),
      base_dataset_slug: z.string().optional(),
    })
    .optional(),
});

export type WidgetFormType = z.infer<typeof widgetSchema>;

const TabContent: FC<TabContentProps> = (props) => {
  const widget = useGuiDashboardV2Store.useWidget();

  const form = useForm<WidgetFormType>({
    defaultValues: {
      type: DASHBOARD_WIDGET_TYPE.STAT,
      title: "",
    },
    resolver: zodResolver(widgetSchema),
  });

  const addWidget = useGuiDashboardV2Store.useAddWidget();
  const updateWidgetInStore = useGuiDashboardV2Store.useUpdateWidget();
  const setLayout = useGuiDashboardV2Store.useSetLayout();
  const guiTab = useGuiDashboardStore.useGuiTabDraft();

  const { mutateAsync: createWidget, isLoading } = useCreateItem({
    modelName: ApiModels.GuiDashboardWidgetV2,
  });

  const { mutateAsync: updateWidget, isLoading: isUpdating } = useUpdateItem({
    modelName: ApiModels.GuiDashboardWidgetV2,
  });

  useEffect(() => {
    if (widget) {
      form.reset({
        type: widget.type ?? DASHBOARD_WIDGET_TYPE.STAT,
        title: widget.title ?? "",
        widget_data: widget.widget_data,
      });
    }
  }, [widget]);

  const handleSubmit = async (data: WidgetFormType) => {
    console.log(data);
    // addWidget({ ...data, slug: v4(), id: v4() });
    if (!widget) {
      const response = await createWidget({
        parent_gui_slug: guiTab?.gui_slug,
        parent_tab_slug: guiTab?.slug,
        ...data,
      }).catch(console.log);

      if (!response) {
        return;
      }

      const { widget, layout } = response as unknown as {
        widget: DashboardWidget;
        layout: DashboardLayout;
      };

      setLayout(layout);
      addWidget(widget);
    } else {
      await updateWidget({
        slug: widget.slug,
        data,
      });

      updateWidgetInStore({ ...data, slug: widget.slug });
    }

    props.onClose();
  };

  return (
    <FormProvider {...form}>
      <Box
        sx={{
          height: "calc(100vh - 96px)",
          ".rc-scrollbar": { height: "100%" },
        }}
        component="form"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <Scrollbar>
          <Stack
            sx={{ height: "100%" }}
            direction="column"
            justifyContent="space-between"
          >
            <TabContentContainer>
              <TabContentSwitch {...props} />
            </TabContentContainer>
            <ButtonGroup
              fullWidth
              variant="contained"
              sx={{
                borderRadius: 0,
                ".MuiButton-root": { borderRadius: 0, height: "60px" },
              }}
            >
              <Button onClick={props.onClose} sx={{ background: "#404045" }}>
                Cancel
              </Button>
              <LoadingButton
                loading={isLoading || isUpdating}
                type="submit"
                sx={{
                  background: (theme) => theme.palette.primary.main,
                  color: "#ffffff",
                }}
              >
                Save
              </LoadingButton>
            </ButtonGroup>
          </Stack>
        </Scrollbar>
      </Box>
    </FormProvider>
  );
};

export default TabContent;
