import { FC } from "react";
import { useHotkeys } from "react-hotkeys-hook";

type RegisterHotKeyProps = {
  hotkey: string;
  onTrigger(): void;
};

const RegisterHotKey: FC<RegisterHotKeyProps> = (props) => {
  const { hotkey, onTrigger } = props;

  useHotkeys(hotkey, (e) => {
    e.preventDefault();
    e.stopPropagation();
    onTrigger();
  });

  return null;
};

export default RegisterHotKey;
