import { json } from "@codemirror/lang-json";
import { OpenInFullOutlined, SouthWestOutlined } from "@mui/icons-material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import { Box, Modal, Stack, Typography } from "@mui/material";
import { CustomCellEditorProps } from "ag-grid-react";
import CodeEditor from "components/CodeEditor";
import { parseValueForCodeEditor } from "helpers";
import { FC, memo, useMemo, useRef, useState } from "react";

const CodeCellEditor: FC<CustomCellEditorProps> = memo((props) => {
  const [open, setOpen] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const codeValueRef = useRef<string>("");

  const value = useMemo(() => {
    return parseValueForCodeEditor(props.value);
  }, [props.value]);

  const handleClose = () => {
    try {
      const editorValue = codeValueRef.current || value;
      const parsed = JSON.parse(editorValue);
      props.onValueChange(parsed);
      setOpen(false);
      props.stopEditing();
    } catch (e) {}
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: 8,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            borderRadius: 1,
            overflow: "hidden",
            ...(expanded
              ? {
                  width: "100%",
                  height: "100%",
                }
              : {
                  width: "500px",
                  height: "350px",
                }),
            ".cm-editor": { border: "none", borderRadius: 0, height: "100%" },
          }}
        >
          <Stack
            sx={{ height: "32px", bgcolor: "#29292e", width: "100%" }}
            justifyContent="space-between"
            alignItems="stretch"
            direction="row"
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              sx={{ bgcolor: "#3e3e42", paddingX: 2 }}
            >
              <InsertDriveFile fontSize="small" />
              <Typography variant="body2">data.json</Typography>
            </Stack>
            <Stack direction="row">
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ bgcolor: "#3e3e42", width: "32px" }}
                onClick={handleClose}
              >
                <CloseOutlined fontSize="small" />
              </Stack>
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ bgcolor: "#3e3e42", width: "32px" }}
                onClick={() => {
                  setExpanded((prev) => !prev);
                }}
              >
                {expanded ? (
                  <SouthWestOutlined fontSize="small" />
                ) : (
                  <OpenInFullOutlined fontSize="small" />
                )}
              </Stack>
            </Stack>
          </Stack>
          <CodeEditor
            style={{
              height: "100%",
            }}
            value={value}
            extensions={[json()]}
            basicSetup={{
              lineNumbers: true,
              syntaxHighlighting: true,
              foldGutter: true,
            }}
            onChange={(value) => {
              codeValueRef.current = value;
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
});

export default CodeCellEditor;
