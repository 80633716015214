import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createTagNode, TagData } from "components/LexicalTagField/TagNode";
import {
  $createLineBreakNode,
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $insertNodes,
  COMMAND_PRIORITY_EDITOR,
  LexicalCommand,
  LexicalNode,
  createCommand,
} from "lexical";
import capitalize from "lodash/capitalize";
import { ApiModels } from "queries/apiModelMapping";
import useGFMLFunctionGroups from "queries/fusion/useGFMLFunctionGroups";
import useListItems from "queries/useListItems";
import { FC, useEffect } from "react";
import { useFusionFlowStore } from "store/stores/fusion-flow";
import { v4 } from "uuid";
import { getSystemModuleInterfaces } from "views/app-view/flow-designer/components/ParamFields/ParamMapper";

type SetValuePayload = {
  value: string;
  clearRoot?: boolean;
};

export const SET_VALUE_COMMAND: LexicalCommand<SetValuePayload> =
  createCommand("SET_VALUE_COMMAND");

type DefaultValuePluginProps = {
  defaultValue?: string;
};

const DefaultValuePlugin: FC<DefaultValuePluginProps> = (props) => {
  const [editor] = useLexicalComposerContext();
  const fusion = useFusionFlowStore.useFusionDraft();
  const { data: datasetDesigns } = useListItems({
    modelName: ApiModels.DatasetDesign,
  });
  const modules = useFusionFlowStore.useAllModules();
  const { data: functionGroups } = useGFMLFunctionGroups();
  const functions = functionGroups?.reduce<GFMLFunction[]>((acc, cur) => {
    acc.push(
      ...cur.function_group_sub_groups.reduce<GFMLFunction[]>((acc2, cur2) => {
        acc2.push(...cur2.functions);

        return acc2;
      }, [])
    );

    return acc;
  }, []);

  const setValue = (value: string, clearRoot = true) => {
    const lineBreakSplit = value
      .split("\n")
      .reduce<string[]>((acc, cur, idx, arr) => {
        if (idx !== arr.length - 1) {
          acc.push(cur);
          acc.push("\n");
        } else {
          acc.push(cur);
        }
        return acc;
      }, []);

    editor.update(() => {
      let lastArrayOperatorInterfaceStop: Record<string, unknown>[];

      if (clearRoot) {
        $getRoot().clear();
        $insertNodes([$createParagraphNode()]);
      }

      const nodes = lineBreakSplit?.reduce<LexicalNode[]>((acc, cur) => {
        if (cur === "\n") {
          acc.push($createLineBreakNode());
        } else {
          cur.split("{{{").forEach((chunk) => {
            const tagEndIndex = chunk.indexOf("}}}");
            if (tagEndIndex < 0) {
              acc.push($createTextNode(chunk));
              return;
            }

            const tag = chunk.substring(0, tagEndIndex);
            const text = chunk.substring(tagEndIndex + 3);
            const [type, identifier, ...slugChunks] = tag.split(".");

            if (type === "var") {
              const operator = fusion?.fusion_operators?.find(
                (op) =>
                  op.slug?.startsWith(identifier) ||
                  op.operator_slug.startsWith(identifier)
              );

              if (operator) {
                const appModule = modules?.find(
                  (m) => m.slug === operator.app_module
                );
                let interfaces =
                  appModule?.interface ||
                  getSystemModuleInterfaces(
                    fusion as any,
                    datasetDesigns || [],
                    operator,
                    []
                  );
                let label = `${operator.operator_title}`;
                slugChunks.forEach((slugChunk, index) => {
                  let chunk = slugChunk;
                  if (
                    index === slugChunks.length - 1 &&
                    slugChunk.endsWith("[")
                  ) {
                    chunk = slugChunk.substring(0, slugChunk.length - 1);
                  }
                  const chunkInterface = interfaces.find(
                    (i: { name: string }) => i.name === chunk
                  );
                  if (chunkInterface) {
                    label += `.${chunkInterface.label}${
                      slugChunk.endsWith("[") ? "[" : ""
                    }`;
                    interfaces =
                      chunkInterface.spec || chunkInterface.specs || [];
                  } else {
                    label += `.${chunk}`;
                  }
                });

                if (slugChunks.at(-1)?.endsWith("[")) {
                  lastArrayOperatorInterfaceStop = interfaces;
                }

                acc.push(
                  $createTagNode(v4(), {
                    type: type as TagData["type"],
                    slug: `${identifier}.${slugChunks.join(".")}`,
                    label,
                  })
                );
              } else if (identifier === "]") {
                let interfaces = lastArrayOperatorInterfaceStop;
                let label = "]";

                slugChunks.forEach((slugChunk, index) => {
                  let chunk = slugChunk;
                  if (index === slugChunks.length - 1 && chunk.endsWith("[")) {
                    chunk = chunk.substring(0, chunk.length - 1);
                  }
                  const chunkInterface = interfaces.find(
                    (i) => i.name === chunk
                  );
                  if (chunkInterface) {
                    label += `.${chunkInterface.label}`;
                    interfaces = (chunkInterface.spec ||
                      chunkInterface.specs ||
                      []) as Record<string, unknown>[];
                  } else {
                    label += `.${chunk}`;
                  }
                });

                if (slugChunks.at(-1)?.endsWith("[")) {
                  lastArrayOperatorInterfaceStop = interfaces;
                }

                acc.push(
                  $createTagNode(v4(), {
                    type: type as TagData["type"],
                    slug: `${identifier}.${slugChunks.join(".")}`,
                    label,
                  })
                );
              } else if (identifier === "session_variables") {
                let interfaces: any[] = [];
                let label = "Session Variables";

                slugChunks.forEach((slugChunk, index) => {
                  let chunk = slugChunk;
                  if (index === slugChunks.length - 1 && chunk.endsWith("[")) {
                    chunk = chunk.substring(0, chunk.length - 1);
                  }
                  const chunkInterface = interfaces.find(
                    (i) => i.name === chunk
                  );
                  if (chunkInterface) {
                    label += `.${chunkInterface.label}`;
                    interfaces = (chunkInterface.spec ||
                      chunkInterface.specs ||
                      []) as Record<string, unknown>[];
                  } else {
                    label += `.${chunk}`;
                  }
                });

                if (slugChunks.at(-1)?.endsWith("[")) {
                  lastArrayOperatorInterfaceStop = interfaces;
                }

                acc.push(
                  $createTagNode(v4(), {
                    type: type as TagData["type"],
                    slug: `${identifier}.${slugChunks.join(".")}`,
                    label,
                  })
                );
              } else if (
                [
                  "popup_variables",
                  "form_data",
                  "gui_params",
                  "filters",
                  "user",
                  "page_size",
                  "page",
                  "offset",
                ].includes(identifier)
              ) {
                let interfaces: any[] = [];
                let label = "Popup Variables";
                if (identifier === "form_data") {
                  label = "Form Data";
                } else if (identifier === "gui_params") {
                  label = "Gui Params";
                } else if (identifier === "filters") {
                  label = "Filters";
                } else if (identifier === "user") {
                  label = "User";
                } else if (
                  ["page_size", "page", "offset"].includes(identifier)
                ) {
                  label = identifier
                    .split("_")
                    .map((v) => capitalize(v))
                    .join(" ");
                }

                slugChunks.forEach((slugChunk, index) => {
                  let chunk = slugChunk;
                  if (index === slugChunks.length - 1 && chunk.endsWith("[")) {
                    chunk = chunk.substring(0, chunk.length - 1);
                  }
                  const chunkInterface = interfaces.find(
                    (i) => i.name === chunk
                  );
                  if (chunkInterface) {
                    label += chunkInterface.label
                      ? `.${chunkInterface.label}`
                      : "";
                    interfaces = (chunkInterface.spec ||
                      chunkInterface.specs ||
                      []) as Record<string, unknown>[];
                  } else {
                    label += chunk ? `.${chunk}` : "";
                  }
                });

                if (slugChunks.at(-1)?.endsWith("[")) {
                  lastArrayOperatorInterfaceStop = interfaces;
                }

                acc.push(
                  $createTagNode(v4(), {
                    type: type as TagData["type"],
                    slug: `${identifier}${
                      slugChunks.length ? "." + slugChunks.join(".") : ""
                    }`,
                    label,
                  })
                );
              } else if (identifier === "chart_inputs") {
                let interfaces: any[] = [];
                let label = "Chart Inputs";

                slugChunks.forEach((slugChunk, index) => {
                  let chunk = slugChunk;
                  if (index === slugChunks.length - 1 && chunk.endsWith("[")) {
                    chunk = chunk.substring(0, chunk.length - 1);
                  }
                  const chunkInterface = interfaces.find(
                    (i) => i.name === chunk
                  );
                  if (chunkInterface) {
                    label += `.${chunkInterface.label}`;
                    interfaces = (chunkInterface.spec ||
                      chunkInterface.specs ||
                      []) as Record<string, unknown>[];
                  } else {
                    label += `.${chunk}`;
                  }
                });

                if (slugChunks.at(-1)?.endsWith("[")) {
                  lastArrayOperatorInterfaceStop = interfaces;
                }

                acc.push(
                  $createTagNode(v4(), {
                    type: type as TagData["type"],
                    slug: `${identifier}.${slugChunks.join(".")}`,
                    label,
                  })
                );
              } else {
                acc.push(
                  $createTagNode(v4(), {
                    type: type as TagData["type"],
                    slug: `${identifier}.${slugChunks.join(".")}`,
                    label: `${identifier}.${slugChunks.join(".")}`,
                    error: true,
                  })
                );
              }
            } else if (type === "fn") {
              const fn = functions?.find(
                (fn) => fn.function_slug === identifier
              );

              if (fn) {
                acc.push(
                  $createTagNode(v4(), {
                    type: type as TagData["type"],
                    slug: `${identifier}`,
                    label: `${fn.function_title}(`,
                  })
                );
              }
            } else if (type === "fn_close") {
              acc.push(
                $createTagNode(v4(), {
                  type: type as TagData["type"],
                  slug: `${identifier}`,
                  label: ")",
                })
              );
            }

            acc.push($createTextNode(text));
          });
        }

        return acc;
      }, []);

      if (nodes) {
        $insertNodes(nodes);
      }
    });
  };

  useEffect(() => {
    editor.registerCommand(
      SET_VALUE_COMMAND,
      (payload) => {
        const { value, clearRoot = true } = payload;

        setValue(value, clearRoot);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );

    let defaultValue = props.defaultValue;
    if (typeof defaultValue !== "string") {
      try {
        defaultValue = JSON.stringify(defaultValue);
      } catch (e) {
        console.log("error parsing: ", defaultValue);
      }
    }

    setValue(defaultValue ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  return null;
};

export default DefaultValuePlugin;
