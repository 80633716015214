import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import React, { ReactNode, useMemo } from "react";

const AccountUserDataProvider: React.FC<{
  allowAllUserType?: boolean;
  userTypes?: string[];
  children?: ReactNode | ((users?: User[]) => ReactNode);
}> = ({ children, allowAllUserType, userTypes }) => {
  const { data: users } = useListItems({
    modelName: ApiModels.AccountUser,
  });

  const options = useMemo(() => {
    //const users=[];
    // TODO: Implement Usertype filter
    return users?.map((user) => ({
      title: user.first_name,
      id: user.slug,
      subtitle: user.email,
      image: user.image,
    }));
  }, [users]);

  if (React.isValidElement(children)) {
    return (
      <React.Fragment>
        {React.cloneElement(children, {
          ...children.props,
          options,
        })}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {typeof children === "function" ? children(users) : children}
    </React.Fragment>
  );
};
export default AccountUserDataProvider;
