import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { SET_VALUE_COMMAND } from "components/LexicalTagField/DefaultValuePlugin";
import { convertLexicalNodesToString } from "helpers/lexicalEditor";
import {
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  LexicalCommand,
  PASTE_COMMAND,
  createCommand,
} from "lexical";
import { FC, useEffect } from "react";

export const COPY_CONTENT_COMMAND: LexicalCommand<{}> = createCommand(
  "COPY_CONTENT_COMMAND"
);

// {{{var.user.slug}}}:{{{var.user.first_name}}} {{{var.user.last_name}}}

// {{{var.user.email}}}

type CopyPastePluginProps = {};

const CopyPastePlugin: FC<CopyPastePluginProps> = (props) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.registerCommand(
      COPY_CONTENT_COMMAND,
      () => {
        const editorState = editor.getEditorState();

        const state = editorState.toJSON();
        const value = convertLexicalNodesToString(state.root.children);
        console.log("🚀 ~ handleCopyClick ~ value:", value);
        navigator.clipboard.writeText(value);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );

    editor.registerCommand<ClipboardEvent>(
      PASTE_COMMAND,
      (event) => {
        event.preventDefault();
        const value = event.clipboardData?.getData("text/plain");

        if (!value) {
          return false;
        }

        editor.dispatchCommand(SET_VALUE_COMMAND, { value, clearRoot: false });

        return true;
      },
      COMMAND_PRIORITY_HIGH
    );
  }, [editor]);

  return null;
};

export default CopyPastePlugin;
