import { Box, Button, Divider, Stack } from "@mui/material";
import { FilterOperators } from "constants/index";
import { ParameterType } from "enums/3pApp";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useFusionFlowStore } from "store/stores/fusion-flow";
import ParamMapperPopover from "views/app-view/flow-designer/components/ParamMapperPopover";
import { ParamField } from "./NodeEditorFields";

type EdgeEditorFieldsProps = {
	edgeData?: FusionOperator["edge_data"];
	onClose?(): void;
	isOperatorConditions?: boolean;
	operator?: FusionOperator;
};

const EdgeEditorFields: React.FC<EdgeEditorFieldsProps> = (props) => {
	const { edgeData, onClose, isOperatorConditions, operator } = props;

	const updateEdgeSettings = useFusionFlowStore.useUpdateEdgeSettings();
	const updateOperatorConditions =
		useFusionFlowStore.useUpdateOperatorConditions();

	const form = useForm();

	console.log(form.formState.isDirty);

	useEffect(() => {
		if (edgeData) {
			Object.entries(edgeData).forEach(([key, value]) => {
				form.setValue(key, value);
			});
		}
	}, [edgeData]);

	const handleSubmit = (values: any) => {
		delete values.mapped;
		if (isOperatorConditions && operator?.operator_slug) {
			updateOperatorConditions({
				operatorSlug: operator?.operator_slug,
				settings: values,
			});
		} else {
			updateEdgeSettings(values);
		}
		onClose?.();
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}>
				<Stack direction="column">
					<ParamField
						mappable={false}
						field={{
							label: "Label",
							name: "label",
							type: ParameterType.Text,
						}}
					/>
					<ParamField
						field={{
							label: "Condition",
							name: "condition_sets",
							type: ParameterType.Filter,
							options: {
								operators: FilterOperators,
							},
						}}
					/>
				</Stack>
				<ParamMapperPopover />
				<Divider sx={{ pt: 2.25 }} />
				<Box
					sx={{
						pt: 2.25,
					}}
				>
					<Stack
						direction="row"
						justifyContent="center"
						alignItems="center"
						spacing={1.5}
					>
						<Button
							variant="outlined"
							color="inherit"
							sx={{
								border: "none",
								color: "#fff",
								background: (theme) => theme.palette.background.GF10,
							}}
							onClick={onClose}
							fullWidth
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							sx={{
								borderColor: "#fff",
								boxShadow: "none",
							}}
							fullWidth
						>
							Save Changes
						</Button>
					</Stack>
				</Box>
			</form>
		</FormProvider>
	);
};

export default EdgeEditorFields;
