import { useQuery } from "@tanstack/react-query";
import FusionModel from "models/Fusion";

const useListUserConnections = (userSlug?: string) => {
  return useQuery({
    queryKey: ["user-connections", userSlug],
    queryFn: async () => {
      if (!userSlug) {
        return [];
      }

      const { data } = await FusionModel.listUserConnections(userSlug);

      return data;
    },
    enabled: !!userSlug,
  });
};

export default useListUserConnections;
