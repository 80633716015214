import Spin from "components/Spin";
import { DASHBOARD_WIDGET_TYPE } from "constants/gui";
import { ParameterType } from "enums/3pApp";
import { GuiType } from "enums/gui";
import { ApiModels } from "queries/apiModelMapping";
import useActionFields from "queries/formDesign/useActionFields";
import useGuiTabWidgets from "queries/gui/useGuiTabWidgets";
import { useGuiTabs } from "queries/gui/useGuiTabs";
import useGetItem from "queries/useGetItem";
import useListItems from "queries/useListItems";
import { FC, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { ParamField, getDocumentCollectionSpec } from ".";

const DoughnutChartFields = () => {
  return (
    <>
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Text,
          name: "title",
          label: "Title",
        }}
        mappable
      />
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Text,
          name: "subtitle",
          label: "Subtitle",
        }}
        mappable
      />
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Code,
          code: "json",
          name: "data",
          label: "Data",
        }}
        mappable
      />
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Array,
          name: "series",
          label: "Series",
          spec: [
            {
              type: ParameterType.Text,
              name: "angleKey",
              label: "Angle Key",
            },
            {
              type: ParameterType.Text,
              name: "calloutLabelKey",
              label: "Callout Label Key",
            },
            {
              type: ParameterType.Text,
              name: "innerRadiusRatio",
              label: "Inner Radius Ratio",
            },
            {
              type: ParameterType.Text,
              name: "outerRadiusRatio",
              label: "Outer Radius Ratio",
            },
            {
              type: ParameterType.Text,
              name: "title",
              label: "Title",
            },
          ],
        }}
        mappable
      />
    </>
  );
};

const LineChartFields = () => {
  return (
    <>
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Text,
          name: "title",
          label: "Title",
        }}
        mappable
      />
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Text,
          name: "subtitle",
          label: "Subtitle",
        }}
        mappable
      />
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Code,
          code: "json",
          name: "data",
          label: "Data",
        }}
        mappable
      />
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Array,
          name: "series",
          label: "Series",
          spec: [
            {
              type: ParameterType.Text,
              name: "xKey",
              label: "X Key",
            },
            {
              type: ParameterType.Text,
              name: "yKey",
              label: "Y Key",
            },
            {
              type: ParameterType.Text,
              name: "xName",
              label: "X Name",
            },
            {
              type: ParameterType.Text,
              name: "yName",
              label: "Y Name",
            },
            {
              type: ParameterType.Text,
              name: "legendItemName",
              label: "Legend Item Name",
            },
          ],
        }}
        mappable
      />
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Collection,
          name: "axes",
          label: "Axes",
          spec: [
            {
              type: ParameterType.Select,
              name: "xType",
              label: "X Axis Type",
              options: [
                { label: "Category", value: "category" },
                { label: "number", value: "number" },
                { label: "time", value: "time" },
              ],
            },
            {
              type: ParameterType.Select,
              name: "yType",
              label: "Y Axis Type",
              options: [
                { label: "Category", value: "category" },
                { label: "number", value: "number" },
                { label: "time", value: "time" },
              ],
            },
          ],
        }}
        mappable
      />
    </>
  );
};

const BarChartFields = () => {
  return (
    <>
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Text,
          name: "title",
          label: "Title",
        }}
        mappable
      />
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Text,
          name: "subtitle",
          label: "Subtitle",
        }}
        mappable
      />
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Code,
          code: "json",
          name: "data",
          label: "Data",
        }}
        mappable
      />
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Array,
          name: "series",
          label: "Series",
          spec: [
            {
              type: ParameterType.Text,
              name: "xKey",
              label: "X Key",
            },
            {
              type: ParameterType.Text,
              name: "yKey",
              label: "Y Key",
            },
            {
              type: ParameterType.Text,
              name: "xName",
              label: "X Name",
            },
            {
              type: ParameterType.Text,
              name: "yName",
              label: "Y Name",
            },
            {
              type: ParameterType.Text,
              name: "legendItemName",
              label: "Legend Item Name",
            },
          ],
        }}
        mappable
      />
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Collection,
          name: "axes",
          label: "Axes",
          spec: [
            {
              type: ParameterType.Select,
              name: "xType",
              label: "X Axis Type",
              options: [
                { label: "Category", value: "category" },
                { label: "number", value: "number" },
                { label: "time", value: "time" },
              ],
            },
            {
              type: ParameterType.Select,
              name: "yType",
              label: "Y Axis Type",
              options: [
                { label: "Category", value: "category" },
                { label: "number", value: "number" },
                { label: "time", value: "time" },
              ],
            },
          ],
        }}
        mappable
      />
    </>
  );
};

const StatWidgetFields = () => {
  const { watch, setValue } = useFormContext();

  const title = watch("widget_data.title");

  useEffect(() => {
    if (title) {
      setValue("widget_data.title2", title);
    }
  }, [setValue, title]);

  return (
    <>
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Text,
          name: "title",
          label: "Title",
        }}
        mappable
      />
      <ParamField
        parentNamePath="widget_data"
        field={{
          type: ParameterType.Text,
          name: "value",
          label: "Value",
        }}
        mappable
      />
    </>
  );
};

type WidgetDataFieldsProps = {
  widget: DashboardWidget;
};

const DataListWidgetFields: FC<WidgetDataFieldsProps> = (props) => {
  const { widget } = props;

  const { data: actionFields = [] } = useActionFields(widget.slug);

  const fields = useMemo(() => {
    return actionFields.map(
      (field) =>
        ({
          ...field,
          ...(field.metadata ?? {}),
          id: field.slug,
          field_slug: field.metadata.slug ?? field.slug,
        } as DataField)
    );
  }, [actionFields]);

  return (
    <ParamField
      mappable
      field={{
        name: "data_rows",
        label: "Data Rows",
        type: ParameterType.Array,
        spec: getDocumentCollectionSpec(fields),
      }}
      parentNamePath="widget_data"
    />
  );
};

const WidgetDataFields: FC<WidgetDataFieldsProps> = (props) => {
  const { widget } = props;

  switch (widget.type) {
    case DASHBOARD_WIDGET_TYPE.STAT:
      return <StatWidgetFields />;
    case DASHBOARD_WIDGET_TYPE.BAR:
      return <BarChartFields />;
    case DASHBOARD_WIDGET_TYPE.PIE:
      return <DoughnutChartFields />;
    case DASHBOARD_WIDGET_TYPE.LINE:
      return <LineChartFields />;
    case DASHBOARD_WIDGET_TYPE.DATA_LIST:
      return <DataListWidgetFields widget={widget} />;
    default:
      return <></>;
  }
};

type PopulateWidgetFieldsProps = {};

const PopulateWidgetFields: FC<PopulateWidgetFieldsProps> = () => {
  const { watch } = useFormContext();

  const { data: guis = [], isFetching: guisLoading } = useListItems({
    modelName: ApiModels.Gui,
  });
  const guiOptions = useMemo(
    () =>
      guis
        .map((gui) => ({ label: gui.name, value: gui.slug }))
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
        ),
    [guis]
  );

  const guiSlug = watch("gui_slug");

  const { data: guiTabs = [], isFetching: tabsLoading } = useGuiTabs(guiSlug);
  const dashboardOptions = useMemo(
    () =>
      guiTabs
        .filter((tab) => tab.tab_type === GuiType.DashboardV2)
        .map((tab) => ({ label: tab.tab_name, value: tab.slug }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    [guiTabs]
  );

  const tabSlug = watch("tab_slug");

  const { data: widgets = [], isFetching: widgetsLoading } =
    useGuiTabWidgets(tabSlug);
  const widgetOptions = useMemo(
    () =>
      widgets
        .map((widget) => ({ label: widget.title ?? "", value: widget.slug }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    [widgets]
  );

  const widgetSlug = watch("widget_slug");

  const { data: widget, isFetching: widgetLoading } = useGetItem({
    modelName: ApiModels.GuiDashboardWidgetV2,
    slug: widgetSlug,
  });

  return (
    <Spin
      spinning={guisLoading || tabsLoading || widgetsLoading || widgetLoading}
    >
      <ParamField
        mappable
        field={{
          name: "gui_slug",
          label: "Gui",
          type: ParameterType.Select,
          options: guiOptions,
        }}
      />
      {guiSlug && (
        <ParamField
          mappable
          field={{
            name: "tab_slug",
            label: "Dashboard",
            type: ParameterType.Select,
            options: dashboardOptions,
          }}
        />
      )}
      {tabSlug && (
        <ParamField
          mappable
          field={{
            name: "widget_slug",
            label: "Widget",
            type: ParameterType.Select,
            options: widgetOptions,
          }}
        />
      )}
      {widget && <WidgetDataFields widget={widget} />}
    </Spin>
  );
};

export default PopulateWidgetFields;
