import LexicalTagField from "components/LexicalTagField";
import { FC } from "react";

type DynamicRHSFieldProps = {
  name: string;
  defaultValue?: string;
  onChange?(value: string): void;
};

const DynamicRHSField: FC<DynamicRHSFieldProps> = (props) => {
  const { name, defaultValue, onChange } = props;

  return (
    <LexicalTagField
      popperId="dynamic-rhs-popper"
      name={name}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};

export default DynamicRHSField;
