const RESERVED_KEYWORDS = [
  "s_name",
  "c_name",
  "s_id",
  "c_id",
  "id",
  "name",
  "t",
  "slug",
  "gui",
];

export default RESERVED_KEYWORDS;
