import DeleteOutline from "@mui/icons-material/DeleteOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import LocalOfferOutlined from "@mui/icons-material/LocalOfferOutlined";
import { Box, Stack, styled, useTheme } from "@mui/material";
import FieldIcon from "components/Form/FieldIcon";
import { FC } from "react";
import ProfileCard from "stories/CompoundComponent/ProfileCard/ProfileCard";

const BoxIcon = styled(Box)(({ theme }) => {
  return {
    width: "20px",
    color: theme.palette.background.GF70,

    svg: {
      maxWidth: "100%",
      height: "20px",
      width: "auto",
      display: "block",
      margin: "auto",

      path: {
        fill: "currentColor",
      },
    },
  };
});

type FieldListItemProps = {
  title?: string;
  subtitle?: string;
  type?: string;
  onClick?(): void;
  onDeleteClick?(): void;
};

const FieldListItem: FC<FieldListItemProps> = (props) => {
  const { title, subtitle, type, onClick, onDeleteClick } = props;

  const theme = useTheme();

  const Icon = FieldIcon[type as keyof typeof FieldIcon] || LocalOfferOutlined;

  return (
    <ProfileCard
      AvatarImage={
        <BoxIcon>
          <Icon />
        </BoxIcon>
      }
      options={{
        draggable: true,
        switcher: false,
      }}
      rightIcon={
        <Stack direction="row" spacing={1}>
          <Box className="edit-icon">
            <DeleteOutline
              sx={{ color: "grey.500" }}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteClick?.();
              }}
            />
          </Box>
          <Box className="edit-icon">
            <EditOutlined sx={{ color: "grey.500" }} />
          </Box>
        </Stack>
      }
      subTitle={subtitle}
      title={title ?? ""}
      sx={{
        background: theme.palette.background.GFRightNavForeground,
        height: 48,
        ".MuiTypography-subtitle1": {
          margin: "0 0 2px",
        },
        ".DragHandle": {
          lineHeight: "1",
          width: "20px",
          height: "20px",
          margin: "0 6px 0 -6px",
          color: theme.palette.background.GF20,

          "&:hover": {
            color: theme.palette.background.GF50,

            path: {
              fill: theme.palette.background.GF50,
            },
          },

          path: {
            fill: theme.palette.background.GF20,
            transition: "all 0.4s ease",
          },

          svg: {
            width: "100%",
            height: "auto",
            display: "block",
          },
        },

        ".card-inner-content": {
          gap: "2px",
        },
      }}
      onClick={onClick}
    />
  );
};

export default FieldListItem;
