import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { Box, ClickAwayListener, IconButton } from "@mui/material";
import { EditorState, LexicalEditor } from "lexical";
import { FC, useCallback, useRef } from "react";

import ContentCopyOutlined from "@mui/icons-material/ContentCopyOutlined";
import AddTagPlugin from "components/LexicalTagField/AddTagPlugin";
import CopyPastePlugin, {
  COPY_CONTENT_COMMAND,
} from "components/LexicalTagField/CopyPastePlugin";
import DefaultValuePlugin from "components/LexicalTagField/DefaultValuePlugin";
import OnDropPlugin from "components/LexicalTagField/OnDropPlugin";
import { convertLexicalNodesToString } from "helpers/lexicalEditor";
import { useLexicalTagField } from "store/stores/lexical-tag-field";
import editorConfig from "./editorConfig";

type LexicalTagFieldProps = {
  name: string;
  defaultValue?: string;
  popperId?: string;
  onChange?(value: string): void;
};

const LexicalTagField: FC<LexicalTagFieldProps> = (props) => {
  const {
    onChange: propsOnChange,
    name,
    defaultValue,
    popperId = "param-mapper-popper",
  } = props;
  const editorRef = useRef<LexicalEditor>();

  const setActiveField = useLexicalTagField.useSetActiveField();
  const activeField = useLexicalTagField.useActiveField();

  const onChange = useCallback(
    (editorState: EditorState) => {
      editorState.read(() => {
        const state = editorState.toJSON();
        const value = convertLexicalNodesToString(state.root.children);
        propsOnChange?.(value);
      });
    },
    [propsOnChange]
  );

  const handleCopyClick = useCallback(() => {
    editorRef.current?.dispatchCommand(COPY_CONTENT_COMMAND, {});
  }, []);

  return (
    <LexicalComposer initialConfig={{ ...editorConfig, namespace: name }}>
      <Box sx={{ width: "100%" }} className="editor-container">
        <PlainTextPlugin
          contentEditable={
            <ClickAwayListener
              onClickAway={(e) => {
                const popperElement = document.getElementById(popperId);
                if (
                  !popperElement?.contains(e.target as Node) &&
                  activeField === name
                ) {
                  setActiveField(null);
                }
              }}
            >
              <Box
                sx={{
                  background: "rgba(255, 255, 255, 0.05)",
                  border: "2px solid transparent",
                  borderRadius: "5px",
                  display: "inline-flex",
                  alignItems: "flex-start",
                  width: "100%",
                  minHeight: "40px",
                  ".editor-input": {
                    width: "100%",
                    // lineHeight: "26px",
                    outline: "none",
                    paddingTop: "6px",
                    paddingBottom: "7px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                  },
                }}
              >
                <ContentEditable
                  id={name}
                  className="editor-input"
                  onClick={() => setActiveField(name, editorRef.current)}
                  onPaste={() => {
                    console.log("on paste");
                  }}
                />
                <IconButton size="small" onClick={handleCopyClick}>
                  <ContentCopyOutlined fontSize="small" />
                </IconButton>
              </Box>
            </ClickAwayListener>
          }
          placeholder={<></>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <OnChangePlugin onChange={onChange} />
        <EditorRefPlugin editorRef={editorRef} />
        <AddTagPlugin />
        <OnDropPlugin />
        <DefaultValuePlugin defaultValue={defaultValue} />
        <CopyPastePlugin />
      </Box>
    </LexicalComposer>
  );
};

export default LexicalTagField;
