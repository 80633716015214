import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBack } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  MenuItem,
  Stack,
  TextField,
  styled,
} from "@mui/material";
import FormField from "components/FormField";
import SidebarSection from "components/RightSidebar";
import { GUI_PARAM_TYPE } from "constants/index";
import RESERVED_KEYWORDS from "constants/reservedKeywords";
import { queryClient } from "queries";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  type: z.string(),
  title: z.string(),
  slug: z
    .string()
    .min(1, "slug is required")
    .refine(
      (value) => {
        return !RESERVED_KEYWORDS.includes(value);
      },
      (arg) => ({ message: `${arg} is a reserved keyword` })
    ),
  description: z.string(),
  is_required: z.boolean(),
  default_value: z.string().optional(),
});
const CheckboxStyled = styled(Checkbox)({
  display: "block",
  width: "fit-content",
});

export type FormType = z.infer<typeof formSchema>;

type props = {
  onBackClick(): void;
  gui: GfGui;
};

const AddGuiParams: React.FC<props> = (props) => {
  const { gui, onBackClick } = props;

  const {
    register,
    formState: { errors, dirtyFields },
    handleSubmit,
    control,
  } = useForm<FormType>({
    defaultValues: {
      type: "",
      title: "",
      slug: "",
      description: "",
      is_required: false,
      default_value: "",
    },
    resolver: zodResolver(formSchema),
  });

  const { mutate: createParam, isLoading } = useCreateItem({
    modelName: ApiModels.GuiParams,
  });

  const onSubmit = (value: FormType) => {
    if (gui?.slug) {
      createParam(
        { ...value, gui_id: gui.slug, is_active: 1 },
        {
          onSuccess: () => {
            queryClient.refetchQueries([ApiModels.GuiParams, gui.slug]);
            onBackClick();
          },
        }
      );
    }
  };

  return (
    <Box sx={{ px: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SidebarSection
          title="Add New Parameter"
          leftIcon={<ArrowBack />}
          rightIcon={false}
          onLeftIconClick={onBackClick}
        >
          <FormField
            label="Parameter Type"
            error={dirtyFields.type ? errors.type : undefined}
          >
            <TextField
              {...register("type")}
              margin="dense"
              id="name"
              type="text"
              hiddenLabel
              size="small"
              variant="filled"
              fullWidth
              select
            >
              {Object.keys(GUI_PARAM_TYPE).map((key) => {
                return (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormField>
          <FormField
            label="Parameter title"
            error={dirtyFields.title ? errors.title : undefined}
          >
            <TextField
              {...register("title")}
              autoFocus
              margin="dense"
              id="name"
              type="text"
              hiddenLabel
              size="small"
              variant="filled"
              fullWidth
            />
          </FormField>
          <FormField
            label="Parameter slug"
            error={dirtyFields.slug ? errors.slug : undefined}
          >
            <TextField
              {...register("slug")}
              margin="dense"
              id="name"
              type="text"
              hiddenLabel
              size="small"
              variant="filled"
              fullWidth
            />
          </FormField>
          <FormField
            label="description"
            error={dirtyFields.description ? errors.description : undefined}
          >
            <TextField
              {...register("description")}
              margin="dense"
              id="name"
              type="text"
              hiddenLabel
              size="small"
              variant="filled"
              fullWidth
              multiline
            />
          </FormField>
          <FormField
            label="Default Value"
            error={dirtyFields.default_value ? errors.default_value : undefined}
          >
            <TextField
              {...register("default_value")}
              margin="dense"
              id="name"
              type="text"
              hiddenLabel
              size="small"
              variant="filled"
              fullWidth
            />
          </FormField>
          <FormField
            label="Required"
            error={dirtyFields.is_required ? errors.is_required : undefined}
          >
            <Controller
              control={control}
              name="is_required"
              render={({ field }) => (
                <CheckboxStyled
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          </FormField>
          {/* <FormField
            label="Is Active"
            error={dirtyFields.is_active ? errors.is_active : undefined}
          >
            <Controller
              control={control}
              name="is_active"
              render={({ field }) => (
                <CheckboxStyled
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          </FormField> */}
        </SidebarSection>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1.5}
          sx={{ mt: 2 }}
        >
          <LoadingButton
            variant="outlined"
            color="inherit"
            sx={{
              border: "none",
              color: "#fff",
              background: (theme) => theme.palette.background.GF10,
            }}
            onClick={onBackClick}
            fullWidth
            loading={isLoading}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{
              borderColor: "#fff",
              boxShadow: "none",
            }}
            fullWidth
            loading={isLoading}
          >
            Save Changes
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

export default AddGuiParams;
