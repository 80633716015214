import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import {
  Box,
  Button,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import SideNav from "components/layout-components/system-layout/SideNav";
import { NAV_SELECTOR_TYPE, SOCKET_URL } from "configs/AppConfig";
import useSocket from "hooks/useSocket";
import SystemAppBar from "module/Appbar";
import AccountMenu from "module/Menu";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { Outlet, useLocation } from "react-router";
import { matchPath } from "react-router-dom";
import { useSystemLayoutStore } from "store/stores/systemLayout";
import { getPathAfterFirstSlash, getSearchParams } from "utils";
import {
  BoxWrap,
  ContentBox,
  MenuIconsList,
  MenuList,
  MenuWrap,
} from "./SystemLayout.styles";

import FloatingChatWindow from "components/FloatingChatWindow";
import AvatarDropDown from "components/layout-components/app-layout/SideNav/AvatarDropDown";
import SideNavV2 from "components/layout-components/system-layout/SideNav/index-v2";
import TopBar from "components/layout-components/system-layout/TopBar";
import { SocketState } from "enums";
import useAccountSlug from "hooks/useAccountSlug";
import useAppConfig from "hooks/useAppConfig";
import useAppNavigate from "hooks/useAppNavigate";
import SidebarNav from "module/SidebarNav";
import { SystemNavRoutes } from "routes";
import { useSocketStore } from "store/stores/socket";
import TwoPanelLayout, {
  LAYOUT_VIEW,
  TwoPanelLayoutRef,
} from "./AnimationLayout/TwoPanelLayout";

type Props = {
  sideNav?: boolean;
  RcAccountMenu?: boolean;
  layoutVersion?: "v2";
};
const navItems = [
  "File",
  "Edit",
  "View",
  "Insert",
  "Format",
  "Slide",
  "Arrange",
  "Tools",
  "Help ",
];
const getInitialComponent = () => {
  return getSearchParams().get("layout_c_name") || "left";
};

const SiteWrapper = styled(Box)({
  "&.side-menu-active": {
    paddingLeft: "48px",

    ".MuiDrawer-paper": {
      left: "48px !important",
      top: "0",
    },
  },
});

const MobileLayout = () => {
  const theme = useTheme();
  const view = useSystemLayoutStore.useActiveView();
  const goToLeftView = useSystemLayoutStore.useGoToLeftView();
  const location = useLocation();
  const accountSlug = useAccountSlug();

  const appBarProps = useSystemLayoutStore.useAppBarProps();

  const animationRef = useRef<TwoPanelLayoutRef | null>();

  useEffect(() => {
    animationRef.current?.gotoComponent(view);
  }, [view]);

  const getComponents = useCallback(() => {
    return {
      left: <SideNav />,
      right: (
        <ContentBox>
          <Outlet />
        </ContentBox>
      ),
    };
  }, []);
  const onBackClick = () => {
    //Todo: you can pass OnBackClick into useSystemLayoutStore
    goToLeftView();
  };
  const initialComponent = getInitialComponent();

  const title = useMemo(() => {
    const match = matchPath(
      `/${accountSlug}/dataset-design/*`,
      location.pathname
    );
    if (match) return "All Datasets";
  }, [location.pathname]);

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <SystemAppBar
        title={view === "right" ? title : "System Settings"}
        onLeftIconClick={onBackClick}
        DropDown={
          <AccountMenu
            DropDown={
              <BoxWrap>
                <Button
                  variant="outlined"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                  sx={{
                    borderRadius: "4px",
                    gap: "5px",
                    px: "5px",
                    borderColor: theme.palette.background.GF20,
                    background: theme.palette.background.GF7,
                  }}
                >
                  <GridViewOutlinedIcon className="grid-icon" />
                  <ExpandMoreIcon className="arrow-holder" />
                </Button>
              </BoxWrap>
            }
          />
        }
        {...appBarProps}
      />
      <TwoPanelLayout
        ref={animationRef as any}
        config={{
          getComponents,
          initialComponent: initialComponent as `${LAYOUT_VIEW}`,
        }}
        urlQueryKey="layout_c"
        dontUnmount={true}
        sx={{ height: "calc(100vh - 57px)", margin: "57px 0 0" }}
      />
    </Box>
  );
};
const ListingNavBar = () => {
  return (
    <MenuWrap>
      <MenuList>
        {navItems.map((ele, index) => {
          return <ListItem key={`${ele}-${index}`}>{ele}</ListItem>;
        })}
      </MenuList>
      <Divider orientation="vertical" />
      <MenuIconsList>
        <ListItem>
          <ListItemIcon>
            <RestoreOutlinedIcon sx={{ width: "18px", height: "auto" }} />
          </ListItemIcon>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <MessageOutlinedIcon sx={{ width: "20px", height: "auto" }} />
          </ListItemIcon>
        </ListItem>
        <ListItem>
          <ListItemButton>
            <Box className="button-text">Slideshow</Box>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton>
            <RemoveRedEyeOutlinedIcon sx={{ width: "18px", height: "auto" }} />
          </ListItemButton>
        </ListItem>
      </MenuIconsList>
    </MenuWrap>
  );
};
const SystemLayout = ({
  sideNav = true,
  RcAccountMenu = true,
  layoutVersion,
}: Props) => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navStyle = useAppConfig()?.nav_style;
  const { connect, disconnect } = useSocket();
  const socketState = useSocketStore.useState();
  const location = useLocation();
  const appNavigate = useAppNavigate();

  const appBarProps = useSystemLayoutStore.useAppBarProps();
  const setSideNavSelectorOptions =
    useSystemLayoutStore.useSetSideNavSelectorOptions();
  const setSideNavSelectorProps =
    useSystemLayoutStore.useSetSideNavSelectorProps();
  const setSideNavSelectorValue =
    useSystemLayoutStore.useSetSideNavSelectorValue();
  const setSideNavSelectorType =
    useSystemLayoutStore.useSetSideNavSelectorType();
  useEffect(() => {
    if (socketState !== SocketState.Open) {
      connect(SOCKET_URL!);
      return () => {
        disconnect();
      };
    }
  }, []);

  useEffect(() => {
    setSideNavSelectorProps({
      onSelect(value) {
        appNavigate(`${value.key}`);
      },
    });

    const currentPath = getPathAfterFirstSlash(location.pathname);
    if (currentPath) {
      const mainRoute = SystemNavRoutes.find((route) => {
        if (
          currentPath.startsWith("/fusion-action") &&
          route.path === "/fusion"
        ) {
          return false;
        }
        return (
          currentPath.startsWith(route.path) ||
          route?.children?.some((c) => currentPath.startsWith(c.path))
        );
      });
      if (mainRoute?.type !== "simple") {
        setSideNavSelectorType(NAV_SELECTOR_TYPE.SELECT);
        setSideNavSelectorOptions(
          mainRoute?.children?.map((c) => ({
            key: c.path,
            title: c.name,
            // icon:mainRoute.icon,
          })) || []
        );
        const selectedRoute = mainRoute?.children?.find((c) =>
          currentPath.startsWith(c.path)
        );
        setSideNavSelectorValue({
          key: selectedRoute?.path,
          title: selectedRoute?.name,
        });
      } else {
        setSideNavSelectorType(NAV_SELECTOR_TYPE.SIMPLE);
        setSideNavSelectorValue({
          key: mainRoute?.path,
          title: mainRoute?.name,
          icon: mainRoute?.icon,
        });
      }
    }
  }, [location.pathname]);

  if (smScreen) {
    return <MobileLayout />;
  }

  return (
    <SiteWrapper
      sx={{ height: "100%" }}
      className={`${navStyle === "left" ? "side-menu-active" : ""}`}
    >
      {navStyle === "left" ? (
        <SidebarNav {...appBarProps} />
      ) : (
        <>
          {layoutVersion === "v2" && <Toolbar className="app-layout-toolbar" />}
          <SystemAppBar
            className="applayout-top-bar"
            DropDown={
              RcAccountMenu ? (
                <BoxWrap sx={{ marginLeft: "0px !important" }}>
                  <AvatarDropDown postion="top" />
                </BoxWrap>
              ) : (
                <ListingNavBar />
              )
            }
            showRightMenu
            {...appBarProps}
          />
        </>
      )}

      {layoutVersion === "v2" && <TopBar />}

      {sideNav ? (
        <>{layoutVersion === "v2" ? <SideNavV2 /> : <SideNav />}</>
      ) : null}
      <ContentBox
        layoutVersion={layoutVersion}
        sx={{
          height:
            navStyle === "top" && layoutVersion === "v2"
              ? "calc(100vh - 98px)"
              : layoutVersion === "v2"
              ? "calc(100vh - 50px)"
              : "calc(100vh - 48px)",
        }}
        className={sideNav ? "side-nav-active" : ""}
      >
        <FloatingChatWindow />
        <Outlet />
      </ContentBox>
    </SiteWrapper>
  );
};

export default SystemLayout;
