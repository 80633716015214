import { Box, styled } from "@mui/material";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import ChatMessageItemLoading from "components/FloatingChatWindow/ChatWindow/ChatMessageItemLoading";
import useSocket from "hooks/useSocket";
import cloneDeep from "lodash/cloneDeep";
import useMessages from "queries/copilot/useMessages";
import { FC, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import useAgentChatStore from "store/stores/agent-chat";
import MessageItem from "./MessageItem";

// const messages = [
//   "Hello Tack Manchester, what can I help you with today?",
//   "I would like to create a new project to promote my new movie.",
//   "That’s great. I can totally help you with that. First, what would you like to call your new project?",
//   "Monkey Madness",
//   "Ok, Monkey Madness.. Sounds interesting!",
//   "Can you create write a few paragraphs promoting my new movie?",
//   "Yes, I can do that for you.",
//   "Please hurry!",
//   "Can you create write a few paragraphs promoting my new movie?",
// ];

const InfiniteScrollContainer = styled(Box)({
  overflow: "auto",
  display: "flex",
  flexDirection: "column-reverse",
  height: "100%",
  marginLeft: "30px",
  marginRight: "30px",

  /* width */
  "::-webkit-scrollbar": {
    width: "10px",
  },

  /* Track */
  "::-webkit-scrollbar-track": {
    background: "transparent",
  },

  /* Handle */
  "::-webkit-scrollbar-thumb": {
    background: "#ffffff66",
    borderRadius: "4px",
    backgroundClip: "padding-box",
    border: "2px solid #1f2125",
  },

  /* Handle on hover */
  "::-webkit-scrollbar-thumb:hover": {
    background: "#ffffff66",
  },
});

type ChatMessagesProps = {
  showAvatarForUser?: boolean;
};

const ChatMessages: FC<ChatMessagesProps> = (props) => {
  const { showAvatarForUser } = props;

  const queryClient = useQueryClient();

  const { subscribe, unsubscribe } = useSocket();

  const thread = useAgentChatStore.useSelectedThread();
  const showLoadingMessage = useAgentChatStore.useShowLoadingMessage();
  const setShowLoadingMessage = useAgentChatStore.useSetShowLoadingMessage();
  const chatStatusMessage = useAgentChatStore.useChatStatusMessage();
  const setChatStatusMessage = useAgentChatStore.useSetChatStatusMessage();

  const { data, fetchNextPage, hasNextPage } = useMessages(thread?.id);

  const [streamingMessage, setStreamingMessage] =
    useState<{ threadId: string; value: string }>();

  const messages = useMemo(
    () =>
      data?.pages.reduce<CopilotMessage[]>((acc, cur) => {
        acc.push(...cur.data);

        return acc;
      }, []) ?? [],
    [data]
  );

  useEffect(() => {
    subscribe(
      "chat_status_update",
      "chat_status_update",
      (response: SocketResponse) => {
        const data = response.data as {
          threadSlug: string;
          status: string;
        };

        if (data.threadSlug === `${thread?.id}`) {
          setChatStatusMessage(data.status);
        }
      }
    );
    subscribe(
      "copilot_response",
      "copilot_response",
      (response: SocketResponse) => {
        const data = response.data as {
          eventName: string;
          eventData: Record<string, any>;
          threadSlug: string;
        };

        if (data.threadSlug !== `${thread?.id}`) {
          return;
        }
        if (
          showLoadingMessage === data.threadSlug &&
          data.eventName === "thread.message.delta"
        ) {
          setShowLoadingMessage(null);
          setChatStatusMessage("");
        }

        if (data.eventName === "thread.run.created") {
          // setCurrentRunId(data.eventData.id);
        }
        if (data.eventName === "thread.message.delta") {
          try {
            let text = "";
            data.eventData.delta.content.forEach((c: any) => {
              if (c.type === "text") {
                const value = c.text.value;
                if (value) {
                  text += value;
                }
              }
            });

            setStreamingMessage((prev) => ({
              threadId: data.threadSlug,
              value: `${prev?.value ?? ""}${text}`,
            }));
          } catch (e) {
            console.log(e);
          }
        } else if (data.eventName === "message_created") {
          setStreamingMessage(undefined);
          queryClient.setQueryData<InfiniteData<ApiResponse<CopilotMessage[]>>>(
            ["copilot", "threads", thread?.id, "messages"],
            (oldData) => {
              const prev = cloneDeep(oldData);
              if (!prev) {
                return prev;
              }

              prev.pages.at(-1)?.data.unshift(data.eventData as CopilotMessage);

              return prev;
            }
          );
        } else if (data.eventName === "thread.run.completed") {
          // setCurrentRunId(undefined);
          queryClient.refetchQueries({
            queryKey: ["copilot", "threads", thread?.id, "attachments"],
          });
        }
      }
    );

    return () => {
      unsubscribe("copilot_response", "copilot_response");
      unsubscribe("chat_status_update", "chat_status_update");
    };
  }, [
    subscribe,
    unsubscribe,
    setShowLoadingMessage,
    streamingMessage,
    showLoadingMessage,
    queryClient,
    thread?.id,
    setChatStatusMessage,
  ]);

  return (
    <InfiniteScrollContainer id="chat-messages-container">
      <InfiniteScroll
        dataLength={
          messages.length +
          (showLoadingMessage ? 1 : 0) +
          (!!streamingMessage ? 1 : 0)
        }
        next={() => {
          fetchNextPage();
        }}
        style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
        inverse={true}
        hasMore={hasNextPage ?? false}
        loader={<h4>Loading...</h4>}
        scrollableTarget="chat-messages-container"
      >
        {showLoadingMessage === `${thread?.id}` && (
          <ChatMessageItemLoading statusMessage={chatStatusMessage} />
        )}
        {/* <ChatMessageItemLoading statusMessage={chatStatusMessage} /> */}
        {streamingMessage?.threadId === `${thread?.id}` &&
          streamingMessage?.value && (
            <MessageItem
              type="agent"
              message={streamingMessage.value}
              attachments={[]}
            />
          )}
        {messages.map((message) => (
          <MessageItem
            type={message.sender === "assistant" ? "agent" : "user"}
            message={message.value ?? ""}
            attachments={message.attachments}
            showAvatarForUser={showAvatarForUser}
          />
        ))}
      </InfiniteScroll>
    </InfiniteScrollContainer>
  );
};

export default ChatMessages;
