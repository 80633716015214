import { ArrowBack } from "@mui/icons-material";
import { Checkbox, MenuItem, TextField } from "@mui/material";
import FormField from "components/FormField";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { CROPPER_TYPE } from "constants/gui";
import { cloneDeep, debounce, set } from "lodash";
import get from "lodash/get";
import { ApiModels } from "queries/apiModelMapping";
import { useGuiTabs } from "queries/gui/useGuiTabs";
import useUpdateItem from "queries/useUpdateItem";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import useSystemLayoutStore from "store";
import { normalizeObjectForAPI } from "utils";

const ImageSetting: React.FC<{
  gui: GfGui;
  onClickBack: () => void;
  indexKey: "included_tabs" | "included_sidebar_widgets" | "general";
  path?: string[];
  selectedField: DataField;
}> = ({
  gui,
  onClickBack,
  indexKey = "included_tabs",
  path = [],
  selectedField,
}) => {
  const { control, reset, register, handleSubmit, watch, setValue, ...rest } =
    useForm();

  const initialValueSet = useRef(false);
  const allowNetworkRequest = useRef(false);

  const { data: guiTabs = [] } = useGuiTabs(gui?.slug);

  const selectedGuiTab = useSystemLayoutStore.useSelectedTopBarTab();

  const guiTab = useMemo(() => {
    if (selectedGuiTab != null && guiTabs) {
      return guiTabs?.[Number(selectedGuiTab)];
    }
  }, [guiTabs, selectedGuiTab]);

  const { selectedTab } = useMemo(() => {
    if (guiTab?.tab_type === "record_list") {
      if (indexKey === "general") {
        return {
          selectedTab: guiTab,
        };
      }

      let parentTab = guiTab[indexKey];

      const selectedTab = get(parentTab, path.slice(0, -1));

      return {
        selectedTab,
        parentTab:
          path.length > 2 ? get(parentTab, path.slice(0, -3)) : undefined,
      } as { selectedTab?: IncludeTabs; parentTab?: IncludeTabs };
    } else {
      return {};
    }
  }, [guiTab, indexKey, path]);

  const { mutate: updateGuiTab } = useUpdateItem({
    modelName: ApiModels.GuiTab,
  });

  useEffect(() => {
    initialValueSet.current = false;
    allowNetworkRequest.current = false;
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab && !initialValueSet.current) {
      reset(selectedTab?.[`field_metadata`]?.[selectedField.id]);
      initialValueSet.current = true;
      setTimeout(() => {
        allowNetworkRequest.current = true;
      }, 3000);
    }
  }, [selectedTab]);

  const submitHandler = useCallback(
    async (data: Partial<{ conditions: Condition[] }>) => {
      if (
        guiTab?.slug &&
        allowNetworkRequest.current &&
        guiTab?.tab_type === "record_list"
      ) {
        if (indexKey === "general") {
          updateGuiTab(
            {
              slug: guiTab.slug,
              data: normalizeObjectForAPI(
                {
                  ...guiTab,
                  field_metadata: {
                    ...guiTab.field_metadata,
                    [selectedField.id]: {
                      ...(guiTab.field_metadata?.[selectedField.id] ?? {}),
                      ...data,
                    },
                  },
                },
                ["gui_slug", "parent_tab_slug", "tab_type"]
              ),
            },
            {
              onSuccess: () => {
                console.log("AccountUser edit success");
              },
            }
          );
          return;
        }

        let datasetListSetting = cloneDeep(guiTab);

        const metadataPath = [
          indexKey,
          ...path.slice(0, -1),
          "field_metadata",
          selectedField.id,
        ];
        const metadata = get(datasetListSetting, metadataPath);
        set(datasetListSetting, metadataPath, { ...metadata, ...data });

        updateGuiTab({
          slug: guiTab.slug,
          data: normalizeObjectForAPI({ ...guiTab, ...datasetListSetting }, [
            "gui_slug",
            "parent_tab_slug",
            "tab_type",
          ]),
        });
      }
    },
    [guiTab, indexKey, path, selectedField.id, updateGuiTab]
  );

  useEffect(() => {
    const submitDeb = debounce(() => {
      handleSubmit(submitHandler)();
    }, 1200);
    const subscription = watch((_) => {
      submitDeb();
    });
    return () => subscription.unsubscribe();
  }, [watch, submitHandler, handleSubmit]);

  return (
    <SidebarSectionWrap
      title="Manage Rules"
      leftIcon={<ArrowBack />}
      onLeftIconClick={onClickBack}
    >
      <FormField label="Cropper Type">
        <Controller
          name="cropper_type"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              margin="dense"
              id="type"
              fullWidth
              select
              hiddenLabel
              size="small"
              variant="filled"
              value={`${field.value}`}
            >
              {CROPPER_TYPE.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </FormField>
      <FormField label="Height">
        <TextField
          {...register("height")}
          margin="dense"
          id="type"
          fullWidth
          hiddenLabel
          size="small"
          variant="filled"
          type="number"
        />
      </FormField>
      <FormField label="Width">
        <TextField
          {...register("width")}
          margin="dense"
          id="type"
          fullWidth
          hiddenLabel
          size="small"
          variant="filled"
          type="number"
        />
      </FormField>
      <FormField label="Field Label">
        <TextField
          {...register("field_label")}
          fullWidth
          variant="filled"
          size="small"
        />
      </FormField>
      <FormField label="Read Only">
        <Controller
          name="read_only"
          control={control}
          render={({ field }) => {
            return (
              <Checkbox
                size="small"
                sx={{ width: "fit-content" }}
                checked={field.value ?? false}
                onChange={(_, checked) => field.onChange(checked)}
              />
            );
          }}
        />
      </FormField>
    </SidebarSectionWrap>
  );
};

export default ImageSetting;
