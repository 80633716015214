import Add from "@mui/icons-material/Add";
import ChatOutlined from "@mui/icons-material/ChatOutlined";
import { LoadingButton } from "@mui/lab";
import { Stack, styled, Typography } from "@mui/material";
import useCreateThread from "queries/copilot/useCreateThread";
import useThreads from "queries/copilot/useThreads";
import { FC } from "react";
import ThreadItem from "./ThreadItem";

const Container = styled(Stack)({
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "26px",
  paddingBottom: "26px",
});

type ThreadListProps = {
  agent: Agent;
  onThreadClick(thread: CopilotThread): void;
};

const ThreadList: FC<ThreadListProps> = (props) => {
  const { agent, onThreadClick } = props;

  const {
    data: threads,
    fetchNextPage,
    hasNextPage,
    isFetching,
    refetch,
  } = useThreads(agent.slug);
  const { mutateAsync: createThread, isLoading } = useCreateThread();

  const handleCreateThread = async () => {
    await createThread({
      agent_slug: agent.slug,
    });

    refetch();
  };

  return (
    <Container direction="column" gap={3}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1}>
          <ChatOutlined />
          <Typography
            fontSize={15}
            fontWeight={500}
            maxWidth={200}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {agent.agent_name} Chats
          </Typography>
        </Stack>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          variant="contained"
          startIcon={<Add />}
          sx={{ height: "30px" }}
          onClick={handleCreateThread}
        >
          New Chat
        </LoadingButton>
      </Stack>
      <Stack direction="column" gap={1}>
        {threads?.pages.map((page, index) =>
          page.data.map((thread) => (
            <ThreadItem
              key={thread.id}
              agent={agent}
              thread={thread}
              onClick={() => onThreadClick(thread)}
            />
          ))
        )}
      </Stack>
      <LoadingButton
        variant="outlined"
        onClick={() => fetchNextPage()}
        disabled={!hasNextPage}
        fullWidth
        loading={isFetching}
      >
        Load More
      </LoadingButton>
    </Container>
  );
};

export default ThreadList;
