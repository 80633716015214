import {
  Avatar,
  ImageList,
  ImageListItem,
  Link as MuiLink,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import ChatMessageContent from "components/FloatingChatWindow/ChatWindow/ChatMessageContent";
import { S3_CLOUD_FRONT_URL } from "configs/AppConfig";
import useCurrentUser from "hooks/useCurrentUser";
import { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { getNameInitial } from "utils";

const List = styled("ul")({
  listStyle: "none",
});

const Container = styled(Stack)({
  padding: "16px",
  borderBottom: "1px solid #EDEDED",
});

type ChatMessageItemProps = {
  data: CopilotMessage;
};

const ChatMessageItem: FC<ChatMessageItemProps> = (props) => {
  const { data } = props;

  const user = useCurrentUser();

  const { name, image, initials } = useMemo(() => {
    if (data.sender === "user") {
      const name = `${user.first_name ?? ""} ${user.last_name ?? ""}`.trim();
      return {
        name,
        image: user.image?.url,
        initials: getNameInitial(name),
      };
    }

    const name = "Assistant";

    return {
      name,
      image: "https://mui.com/static/images/avatar/1.jpg",
      initials: getNameInitial(name),
    };
  }, [user, data]);

  const { imageFiles, dataFiles } = useMemo(() => {
    if (!data.attachments) {
      return { imageFiles: [], dataFiles: [] };
    }

    return data.attachments?.reduce<
      Required<{
        imageFiles?: CopilotMessage["attachments"];
        dataFiles?: CopilotMessage["attachments"];
      }>
    >(
      (acc, cur) => {
        const ext = cur.filename.split(".").pop()?.toLowerCase();
        const isImage = ["jpg", "jpeg", "png", "gif"].includes(ext || "");

        if (isImage) {
          acc.imageFiles.push(cur);
        } else {
          acc.dataFiles.push(cur);
        }

        return acc;
      },
      { imageFiles: [], dataFiles: [] }
    );
  }, [data.attachments]);

  return (
    <Container gap={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Avatar
          src={image}
          variant="rounded"
          sx={{
            width: "32px",
            height: "32px",
            background: "#00619A",
            color: "#ffffff",
          }}
        >
          {initials}
        </Avatar>
        <Typography fontWeight={500} fontSize={14}>
          {name}
        </Typography>
      </Stack>
      {/* <Typography>{data.value}</Typography> */}
      <ChatMessageContent value={data.value} />
      {dataFiles.length > 0 && (
        <List>
          {dataFiles.map((file) => (
            <li key={file.fileId}>
              <MuiLink
                component={Link}
                to={`${S3_CLOUD_FRONT_URL}/${file.s3Key}`}
                target="_blank"
              >
                {file.filename}
              </MuiLink>
            </li>
          ))}
        </List>
      )}
      {imageFiles.length > 0 && (
        <ImageList cols={2}>
          {imageFiles.map((image) => (
            <ImageListItem key={image.fileId}>
              <img
                src={`${S3_CLOUD_FRONT_URL}/${image.s3Key}`}
                alt={image.filename}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </Container>
  );
};

export default ChatMessageItem;
