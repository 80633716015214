import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type Path = string | number;

type State = {
  rightWidgetsPath: Path[];
  middleWidgetsPath: Path[];
};

type Actions = {
  setRightWidgetsPath(path: Path[]): void;
  pushRightWidgetsPath(...path: Path[]): void;
  popRightWidgetsPath(): void;
  setMiddleWidgetsPath(path: Path[]): void;
  pushMiddleWidgetsPath(...path: Path[]): void;
  popMiddleWidgetsPath(): void;
};

export const useGuiComponentPathsBase = create(
  devtools(
    immer<State & Actions>((set) => ({
      rightWidgetsPath: [],
      middleWidgetsPath: [],
      setRightWidgetsPath(path) {
        set((state) => {
          state.rightWidgetsPath = path;
        });
      },
      pushRightWidgetsPath(...path) {
        set((state) => {
          state.rightWidgetsPath.push(...path);
        });
      },
      popRightWidgetsPath() {
        set((state) => {
          state.rightWidgetsPath.pop();
        });
      },
      setMiddleWidgetsPath(path) {
        set((state) => {
          state.middleWidgetsPath = path;
        });
      },
      pushMiddleWidgetsPath(...path) {
        set((state) => {
          state.middleWidgetsPath.push(...path);
        });
      },
      popMiddleWidgetsPath() {
        set((state) => {
          state.middleWidgetsPath.pop();
        });
      },
    }))
  )
);

const useGuiComponentPaths = createSelectorHooks(useGuiComponentPathsBase);

export default useGuiComponentPaths;
