import Audiotrack from "@mui/icons-material/Audiotrack";
import { CustomCellRendererProps } from "ag-grid-react";
import DefaultTextCellDisplay from "components/DatasetTable/colDefs/DefaultTextCellDisplay";
import { FC, memo } from "react";

const AudioCellRenderer: FC<CustomCellRendererProps> = memo((props) => (
  <DefaultTextCellDisplay
    data={props.data}
    text={props.value?.name}
    icon={<Audiotrack />}
  />
));

export default AudioCellRenderer;
