import { FusionActionForm } from "components/FusionAction/FusionActionEditor";
import Model from "models";

class GuiModal extends Model<GfGui> {
  constructor() {
    super("/gf-gui", "gui-fusion");
  }

  async createFusion(data: {
    gui_slug: string;
    main_tab_id: string;
    sub_tab_id: string;
  }) {
    return this.sendRequest<Fusion>("/fusion", "POST", data);
  }

  async createReviewerAction(
    guiSlug: string,
    data: FusionActionForm & {
      tab_slug: string;
      path?: string;
    }
  ) {
    return this.sendRequest<GfGui>(`/reviewer-action/${guiSlug}`, "POST", data);
  }

  async createDatasetAction(
    guiSlug: string,
    data: FusionActionForm & {
      tab_slug: string;
      path?: string;
    }
  ) {
    return this.sendRequest<GfGui>(`/dataset-action/${guiSlug}`, "POST", data);
  }

  async createGuiAction(
    guiSlug: string,
    data: FusionActionForm & {
      tab_slug: string;
      path?: string;
    }
  ) {
    return this.sendRequest<GfGui>(`/gui-action/${guiSlug}`, "POST", data);
  }

  async createWidgetAction(
    guiSlug: string,
    data: FusionActionForm & {
      widget_slug: string;
      path: "associated_actions" | "dataset_row_actions";
    }
  ) {
    return this.sendRequest<GfGui>(`/widget-action/${guiSlug}`, "POST", data);
  }
}

const model = new GuiModal();

export default model;
