import { FusionActionForm } from "components/FusionAction/FusionActionEditor";
import Model from "models";

class GuiDashboardWidgetV2Modal extends Model<DashboardWidget> {
  constructor() {
    super("/gf-dashboard-widget/v2", "gui-fusion");
  }

  async createWidgetAction(
    widgetSlug: string,
    data: FusionActionForm & {
      tab_slug: string;
      path?: string;
      gui_slug: string;
    }
  ) {
    return this.sendRequest<FusionAction>(
      `/widget-action/${widgetSlug}`,
      "POST",
      data
    );
  }
}

const model = new GuiDashboardWidgetV2Modal();

export default model;
