import CloseOutlined from "@mui/icons-material/CloseOutlined";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import Spin from "components/Spin";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DocumentElementType } from "enums/Form";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { FC, useEffect, useMemo } from "react";
import { Control, FieldValues, FormProvider, useForm } from "react-hook-form";
import useGuiParamsStore from "store/stores/gui-prams";

dayjs.extend(utc);

const DrawerStyled = styled(Drawer)(({ theme }) => ({}));

const DrawerContent = styled(Box)(({ theme }) => ({
  width: "420px",
  height: "100%",
  background: theme.palette.background.LeftNavBody,

  ".animation-parent": {
    width: "100%",
  },
}));

type GroupCardProps = {
  params: GuiParams[];
  title: string;
  control: Control<FieldValues, any>;
};

const GroupCard: FC<GroupCardProps> = (props) => {
  const { params = [], title, control } = props;

  const sortedParams = useMemo(() => {
    return params.sort((a, b) =>
      dayjs.utc(a.created_at).isBefore(dayjs.utc(b.created_at)) ? -1 : 1
    );
  }, [params]);

  const paramGroups = useMemo(() => {
    const groups: GuiParams[][] = [];

    let currentGroup: GuiParams[] = [];

    for (const param of sortedParams) {
      if (param.type === "divider") {
        if (currentGroup.length > 0) {
          groups.push(currentGroup);
          currentGroup = [];
        }
      } else {
        currentGroup.push(param);
      }
    }

    if (currentGroup.length > 0) {
      groups.push(currentGroup);
    }

    return groups;
  }, [sortedParams]);

  return (
    <Box>
      <Box
        p={2}
        sx={{ background: (theme) => theme.palette.background.ListItem }}
        borderRadius="4px 4px 0 0"
      >
        <Typography fontSize={14} fontWeight={400}>
          {title}
        </Typography>
      </Box>
      <Divider sx={{ borderColor: "transparent" }} />
      {paramGroups.map((group, index) => (
        <>
          <Stack
            key={index}
            p={2}
            gap={2}
            sx={{
              background: (theme) => theme.palette.background.ListItem,
              borderRadius:
                index === paramGroups.length - 1 ? "0 0 4px 4px" : undefined,
            }}
          >
            {group.map((param) =>
              param.type === "divider" ? (
                <Divider
                  sx={{
                    borderColor: (theme) =>
                      theme.palette.background.LeftNavBody,
                  }}
                />
              ) : (
                <FormField
                  key={param.param_slug}
                  label={param.title}
                  type={param.type as DocumentElementType}
                  fieldExtras={{ field: { list_items: param.list_items } }}
                  formControl={{ control, name: param.param_slug }}
                />
              )
            )}
          </Stack>
          {index < paramGroups.length - 1 && (
            <Divider sx={{ borderColor: "transparent" }} />
          )}
        </>
      ))}
    </Box>
  );
};

type GuiFiltersViewProps = {
  guiSlug?: string;
  onClose(): void;
} & DrawerProps;

const GuiFiltersView: FC<GuiFiltersViewProps> = (props) => {
  const { guiSlug, onClose, ...drawerProps } = props;

  const guiParamValues = useGuiParamsStore.useGuiParamValues();
  const mergeGuiParamValues = useGuiParamsStore.useMergeGuiParamValues();
  const rerenderGui = useGuiParamsStore.useRerenderGui();

  const form = useForm();

  const { data: params = [], isFetching: isFetchingParams } = useListItems({
    modelName: ApiModels.GuiParams,
    requestOptions: { query: { param_group_slug: guiSlug } },
    queryKey: [ApiModels.GuiParams, guiSlug],
    queryOptions: {
      enabled: !!guiSlug,
    },
  });

  const { data: groups = [], isFetching: isFetchingGroups } = useListItems({
    modelName: ApiModels.GuiParamGroup,
    requestOptions: { query: { gui_slug: guiSlug } },
    queryKey: [ApiModels.GuiParamGroup, guiSlug],
    queryOptions: {
      enabled: !!guiSlug,
    },
  });

  const paramGroups = useMemo(() => {
    return groups.reduce<
      { groupTitle: string; groupSlug: string; groupParams: GuiParams[] }[]
    >((acc, group) => {
      acc.push({
        groupSlug: group.slug,
        groupTitle: group.title,
        groupParams: params.filter((param) =>
          param.slug.startsWith(group.slug)
        ),
      });

      return acc;
    }, []);
  }, [params, groups]);

  useEffect(() => {
    form.reset(guiParamValues);
  }, [guiParamValues]);

  const handleSubmit = (data: Record<string, unknown>) => {
    mergeGuiParamValues(data);
    rerenderGui();
    onClose();
  };

  const isLoading = isFetchingGroups || isFetchingParams;

  return (
    <DrawerStyled
      anchor="right"
      onClose={onClose}
      title="Filter Designer"
      ModalProps={{
        keepMounted: false,
      }}
      keepMounted={false}
      variant="temporary"
      {...drawerProps}
    >
      <DrawerContent>
        <Scrollbar>
          <Stack gap={2} alignItems="flex-start" p={2}>
            <IconButton onClick={onClose} sx={{ p: 0 }}>
              <CloseOutlined fontSize="small" />
            </IconButton>
            <Typography fontSize={16}>Filter View</Typography>
            <Spin spinning={isLoading} sx={{ width: "100%" }}>
              <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                  <Stack gap={1}>
                    {paramGroups.map((group) => (
                      <GroupCard
                        key={group.groupSlug}
                        title={group.groupTitle}
                        params={group.groupParams}
                        control={form.control}
                      />
                    ))}
                  </Stack>
                  <Stack
                    direction="row"
                    gap={2}
                    justifyContent="center"
                    sx={{ mt: 2 }}
                  >
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                      Cancel
                    </Button>
                  </Stack>
                </form>
              </FormProvider>
            </Spin>
          </Stack>
        </Scrollbar>
      </DrawerContent>
    </DrawerStyled>
  );
};

export default GuiFiltersView;
