import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";

export const getGuiTabWidgetsQueryKey = (tabSlug?: string) => [
  ApiModels.GuiDashboardWidgetV2,
  tabSlug,
];

const useGuiTabWidgets = (tabSlug?: string) => {
  return useListItems({
    modelName: ApiModels.GuiDashboardWidgetV2,
    requestOptions: {
      query: {
        parent_tab_id: tabSlug,
      },
    },
    queryOptions: {
      enabled: !!tabSlug,
    },
    queryKey: getGuiTabWidgetsQueryKey(tabSlug),
  });
};

export default useGuiTabWidgets;
