import { Theme } from "@mui/material";
import {
  AgAxisGridLineOptions,
  AgAxisLineOptions,
  AgBaseAxisLabelOptions,
  AgChartOptions,
} from "ag-charts-community";

export const findIncludedTabPath = (
  tabs: IncludeTabs[],
  id: string
): string[] => {
  const path: string[] = [];

  for (const [index, tab] of tabs.entries()) {
    if (tab.id === id) {
      path.push(`${index}`);
      break;
    } else {
      const includedPaths = findIncludedTabPath(tab.additional_tabs ?? [], id);
      if (includedPaths.length > 0) {
        path.push(`${index}`, "additional_tabs", ...includedPaths);
        break;
      }
    }
  }

  return path;
};

export const findTabPath = (tabs: GuiTab[], id: string) => {
  const path: string[] = [];

  for (const [index, tab] of tabs.entries()) {
    if (tab.slug === id) {
      path.push(`${index}`);
      break;
    } else if (tab.tab_type === "record_list") {
      const tabPaths = findIncludedTabPath(tab.included_tabs ?? [], id);
      if (tabPaths.length > 0) {
        path.push(`${index}`, "included_tabs", ...tabPaths);
        break;
      }

      const sidebarPaths = findIncludedTabPath(
        tab.included_sidebar_widgets ?? [],
        id
      );
      if (sidebarPaths.length > 0) {
        path.push(`${index}`, "included_sidebar_widgets", ...sidebarPaths);
        break;
      }
    }
  }

  return path.join(".");
};

export const parseDashboardTabFilters = (
  filters: unknown[],
  searchParams: URLSearchParams
) => {
  return filters.reduce<Record<string, unknown>>((acc, cur: any) => {
    if (
      cur.use_param_as_default === "yes" &&
      searchParams.has(cur.field_title)
    ) {
      acc[cur.field_title] = searchParams.get(cur.field_title);
    } else {
      acc[cur.field_title] = cur.default_value || undefined;
    }

    return acc;
  }, {});
};

export const getDefaultChartOptions = (
  theme: Theme
): Pick<
  AgChartOptions,
  "title" | "subtitle" | "background" | "legend" | "padding"
> & {
  axis: {
    label?: AgBaseAxisLabelOptions;
    line?: AgAxisLineOptions;
    gridLine?: AgAxisGridLineOptions;
  };
} => {
  const fontFamily = "Inter, Source Sans Pro, sans-serif";

  return {
    title: {
      fontFamily,
      fontSize: 15,
      fontWeight: 500,
      color: theme.palette.background.ChartTitle,
    },
    subtitle: {
      fontFamily,
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.background.ChartSubtitle,
    },
    background: {
      visible: false,
    },
    legend: {
      position: "bottom",
      spacing: 20,
      item: {
        marker: {
          shape: "circle",
        },
        label: {
          fontFamily,
          fontSize: 10,
          fontWeight: 500,
          color: theme.palette.background.ChartLegendLabel,
        },
      },
    },
    padding: {
      bottom: 20,
      top: 20,
      left: 30,
      right: 30,
    },
    axis: {
      label: {
        fontFamily,
        fontSize: 12.5,
        fontWeight: 400,
        color: theme.palette.background.ChartAxisLabel,
      },
      line: {
        enabled: false,
      },
      gridLine: {
        style: [
          {
            stroke: theme.palette.background.ChartGridLine,
          },
        ],
      },
    },
  };
};
