import { Box, Stack, SxProps, Typography, styled } from "@mui/material";
import IOSSwitch from "components/IOSSwitch";
import { FC, useEffect, useState } from "react";

const Container = styled(Box)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.background.GF20,
  paddingTop: "8px",
  paddingBottom: "8px",
  paddingLeft: "16px",
  paddingRight: "16px",
  borderRadius: "4px",
  width: "100%",
}));

type SwitchBarProps = {
  value?: boolean;
  label?: string;
  sx?: SxProps;
  onChange?(value: boolean): void;
};

const SwitchBar: FC<SwitchBarProps> = (props) => {
  const { value, label, sx, onChange } = props;

  const [checked, setChecked] = useState<boolean>(value ?? false);

  useEffect(() => {
    setChecked(value ?? false);
  }, [value]);

  const handleChange = (change: boolean) => {
    setChecked(change);
    onChange?.(change);
  };

  return (
    <Container sx={sx}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize={15}>{label}</Typography>
        <IOSSwitch
          checked={checked}
          onChange={(_, change) => handleChange(change)}
        />
      </Stack>
    </Container>
  );
};

export default SwitchBar;
