import { zodResolver } from "@hookform/resolvers/zod";
import AddOutlined from "@mui/icons-material/AddOutlined";
import RadioButtonChecked from "@mui/icons-material/RadioButtonChecked";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import FormField, { FormFieldProps } from "components/FormField";
import Scrollbar from "components/Scrollbar";
import { DocumentElementType } from "enums/Form";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const FILTER_TYPE_OPTIONS = [
  {
    label: "Select List",
    value: DocumentElementType.Select,
  },
  {
    label: "Number",
    value: DocumentElementType.Number,
  },
  {
    label: "Text Field",
    value: DocumentElementType.TextField,
  },
  {
    label: "Date Time",
    value: DocumentElementType.Date,
  },
  {
    label: "Divider",
    value: "divider",
  },
];

const DialogWrap = styled(Dialog)({
  ".MuiDialog-paper": {
    width: "100%",
  },

  ".color-picker": {
    marginTop: "8px",
  },
});

type ListItemsProps = {
  items: { label: string; value: string }[];
  onChange(items: { label: string; value: string }[]): void;
};

const ListItems: React.FC<ListItemsProps> = (props) => {
  const { items, onChange } = props;

  const [open, setOpen] = useState(false);
  const [modalValues, setModalValues] =
    useState<Partial<{ label: string; value: string }>>();
  const [mode, setMode] = useState<"add" | "edit">("add");

  const handleSave = () => {
    if (!modalValues?.label || !modalValues?.value) {
      return;
    }
    if (mode === "add") {
      onChange([...items, modalValues as { label: string; value: string }]);
    } else {
      onChange(
        items.map((item) =>
          item.value === modalValues.value ? { ...item, ...modalValues } : item
        )
      );
    }

    setOpen(false);
    setModalValues(undefined);
  };

  const updateValues = (values: Partial<{ label: string; value: string }>) => {
    setModalValues((prev) => ({ ...prev, ...values }));
  };

  return (
    <Box>
      <Stack gap={1}>
        <Stack gap={1}>
          {items.map((item) => (
            <Stack
              key={item.value}
              direction="row"
              gap={1}
              alignItems="center"
              width="100%"
              borderRadius="6px"
              padding="4px"
              sx={{
                background: (theme) => theme.palette.background.ListItem,
                cursor: "pointer",
              }}
              onClick={() => {
                setModalValues(item);
                setMode("edit");
                setOpen(true);
              }}
            >
              <Stack
                width="36px"
                height="36px"
                alignItems="center"
                justifyContent="center"
                sx={{
                  background: (theme) => theme.palette.background.ListItemIcon,
                }}
              >
                <RadioButtonChecked fontSize="small" />
              </Stack>
              <Stack direction="column">
                <Typography fontSize={14} color="text.primary">
                  {item.label}
                </Typography>
                <Typography
                  fontSize={13}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  color="text.secondary"
                  width="200px"
                >
                  {item.value}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Button
          variant="outlined"
          sx={{
            color: (theme) => theme.palette.text.secondary,
            borderColor: (theme) => theme.palette.other?.outlinedBorder,
            fontWeight: 400,
            fontSize: 14,
          }}
          fullWidth
          onClick={() => {
            setMode("add");
            setOpen(true);
          }}
          startIcon={<AddOutlined fontSize="small" />}
        >
          Add List Item
        </Button>
      </Stack>
      <DialogWrap open={open} onClose={() => setOpen(false)}>
        <DialogTitle>List Item</DialogTitle>
        <Scrollbar className="form-scroller">
          <DialogContent>
            <Stack gap={2}>
              <FormField label="Item Label">
                <TextField
                  onChange={(e) => updateValues({ label: e.target.value })}
                  autoFocus
                  margin="dense"
                  id="title"
                  type="text"
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="outlined"
                  value={modalValues?.label}
                />
              </FormField>
              <FormField label="Item Value">
                <TextField
                  onChange={(e) => updateValues({ value: e.target.value })}
                  autoFocus
                  margin="dense"
                  id="title"
                  type="text"
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="outlined"
                  value={modalValues?.value}
                />
              </FormField>
            </Stack>
          </DialogContent>
        </Scrollbar>
        <DialogActions>
          <Button onClick={handleSave} variant="contained">
            Submit
          </Button>
        </DialogActions>
      </DialogWrap>
    </Box>
  );
};

type RenderDefaultFieldProps = {
  type?: string;
  listItems?: { label: string; value: string }[];
} & Omit<FormFieldProps, "type">;

const RenderDefaultField: FC<RenderDefaultFieldProps> = (props) => {
  const { type, listItems, ...formFieldProps } = props;

  switch (type) {
    case DocumentElementType.Number:
      return (
        <FormField type={DocumentElementType.Number} {...formFieldProps} />
      );
    case DocumentElementType.Date:
      return <FormField type={DocumentElementType.Date} {...formFieldProps} />;
    case DocumentElementType.Select:
      return (
        <FormField
          type={DocumentElementType.Select}
          fieldExtras={{ field: { list_items: listItems } }}
          {...formFieldProps}
        />
      );
    default:
      return (
        <FormField type={DocumentElementType.TextField} {...formFieldProps} />
      );
  }
};

const formSchema = z.object({
  type: z.string(),
  title: z.string(),
  param_slug: z.string(),
  description: z.string().optional(),
  default_value: z.unknown().optional(),
  list_items: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
      })
    )
    .optional(),
});

type FormType = z.infer<typeof formSchema>;

type GroupFieldEditorProps = {
  param?: GuiParams;
  group?: GuiParamGroup;
  onCancelClick(): void;
};

const GroupFieldEditor: FC<GroupFieldEditorProps> = (props) => {
  const { param, group, onCancelClick } = props;

  const queryClient = useQueryClient();

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: param?.type ?? DocumentElementType.TextField,
      title: param?.title ?? "",
      param_slug: param?.param_slug ?? "",
      description: param?.description ?? "",
      list_items: param?.list_items ?? [],
    },
  });

  const { mutateAsync: updateParam, isLoading: isUpdating } = useUpdateItem({
    modelName: ApiModels.GuiParams,
  });
  const { mutateAsync: createParam, isLoading: iseCreating } = useCreateItem({
    modelName: ApiModels.GuiParams,
  });

  const type = form.watch("type");
  const listItems = form.watch("list_items");

  useEffect(() => {
    if (param) {
      form.setValue("type", param.type ?? DocumentElementType.TextField);
      form.setValue("title", param.title ?? "");
      form.setValue("param_slug", param.param_slug ?? "");
      form.setValue("description", param.description ?? "");
      form.setValue("default_value", param.default_value);
      form.setValue("list_items", param.list_items ?? []);
    }
  }, [param]);

  const handleSubmit = async (data: FormType) => {
    if (param?.slug) {
      await updateParam({ slug: param.slug, data });
    } else if (group?.slug) {
      await createParam({
        ...data,
        slug: data.param_slug,
        param_group_slug: group.slug,
      });
    }

    queryClient.refetchQueries({
      queryKey: [ApiModels.GuiParams, group?.slug],
    });
    onCancelClick();
  };

  const isLoading = isUpdating || iseCreating;
  const isEditMode = !!param;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit, (errors) =>
          console.log(errors)
        )}
      >
        <Stack gap={2}>
          <Typography>Filter Designer Detail</Typography>
          <FormField
            label="Filter Type"
            type={DocumentElementType.Select}
            fieldExtras={{
              field: { list_items: FILTER_TYPE_OPTIONS },
              disabled: isEditMode,
            }}
            formControl={{ control: form.control, name: "type" }}
          />
          <FormField
            label="Title"
            type={DocumentElementType.TextField}
            formControl={{ control: form.control, name: "title" }}
          />
          <FormField
            label="Slug"
            type={DocumentElementType.TextField}
            fieldExtras={{ disabled: isEditMode, field: {} }}
            formControl={{ control: form.control, name: "param_slug" }}
          />
          {type === DocumentElementType.Select && (
            <FormField
              label="List Items"
              formControl={{ control: form.control, name: "list_items" }}
            >
              {({ value, onChange }) => (
                <ListItems
                  items={value as { label: string; value: string }[]}
                  onChange={(v) =>
                    onChange?.(v as { label: string; value: string }[])
                  }
                />
              )}
            </FormField>
          )}
          <FormField
            label="Description"
            type={DocumentElementType.TextArea}
            formControl={{ control: form.control, name: "description" }}
          />
          <RenderDefaultField
            label="Default Value"
            type={type}
            listItems={listItems}
          />
          <Stack direction="row" gap={2} sx={{ mx: "auto", mt: 2 }}>
            <LoadingButton
              loading={isLoading}
              disabled={isLoading}
              variant="contained"
              type="submit"
            >
              Save
            </LoadingButton>
            <Button variant="outlined" onClick={onCancelClick}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default GroupFieldEditor;
