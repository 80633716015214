import { CustomCellRendererProps } from "ag-grid-react";
import Cell from "components/DatasetTable/Cell";
import IOSSwitch from "components/IOSSwitch";
import { FC, memo } from "react";

const BooleanCellRenderer: FC<CustomCellRendererProps> = memo((props) => (
  <Cell sx={{ justifyContent: "center" }} isLoading={!props.data}>
    {props.data != null ? (
      <IOSSwitch
        checked={props.value}
        // sx={{
        //   ".Mui-checked+.MuiSwitch-track": {
        //     backgroundColor: (theme) => theme.palette.text.primary,
        //   },
        //   ".MuiSwitch-thumb": {
        //     backgroundColor: (theme) => theme.palette.text.secondary,
        //   },
        // }}
      />
    ) : (
      <></>
    )}
  </Cell>
));

export default BooleanCellRenderer;
