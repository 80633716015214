import {
  AddToQueue,
  DeleteOutlined,
  DriveFileRenameOutline,
  EditOutlined,
} from "@mui/icons-material";
import { Avatar, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import AddFolder from "assets/icons/AddFolder";
import AddFolderModel from "components/AddFolderModal";
import IconButtonWithTooltip from "components/IconButtonWithTooltip";
import { ListingItem } from "components/layout-components/listing-layout/Listing";
import RenameModel from "components/share-components/RenameModel";
import Icon from "components/util-components/Icon";
import useAccountSlug from "hooks/useAccountSlug";
import useAppNavigate from "hooks/useAppNavigate";
import useQuery from "hooks/useQuery";
import useRouteToApiModel from "hooks/useRouteToApiModel";
import ListingLayout from "layouts/ListingLayout";
import moment from "moment";
import { ApiModels } from "queries/apiModelMapping";
import useFolders from "queries/useFolders";
import useListItems from "queries/useListItems";
import useUpdateItem from "queries/useUpdateItem";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useListingLayout } from "store/stores/listingLayout";
import { useSystemLayoutStore } from "store/stores/systemLayout";
import { sortItems } from "utils";
import useDeleteItem from "../../../queries/useDeleteItem";
import AddGuiModalModal from "./AddGuiModal";

const Guis = () => {
  const { folder_id: folderId } = useQuery<{ folder_id?: string }>();

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [folderModalOpen, setFolderModalOpen] = useState(false);

  const routeModule = useRouteToApiModel();
  const accountSlug = useAccountSlug();
  const navigate = useAppNavigate();

  const { data: guis } = useListItems({
    modelName: ApiModels.Gui,
    requestOptions:{ query:{ minimal: true }}
  });
  const { mutate: deleteGui } = useDeleteItem({ modelName: ApiModels.Gui });

  const { mutate: deleteFolder } = useDeleteItem({
    modelName: ApiModels.Folder,
  });
  const { data: folders } = useFolders();
  const { mutate: updateFolder } = useUpdateItem({
    modelName: ApiModels.Folder,
    listQueryKey: [ApiModels.Folder, routeModule],
    mutationOptions: {
      mutationKey: [ApiModels.Folder, routeModule],
    },
  });
  const setSearchTags = useListingLayout.useSetSearchTags();
  const onRename = useSystemLayoutStore.useOnRename?.();
  const setBreadcrumbs = useListingLayout.useSetBreadcrumbs();
  const searchTags = useListingLayout.useSearchTags();
  const sortValue = useListingLayout.useSortValue();
  const setSortValue = useListingLayout.useSetSortValue();
  const setSortOptions = useListingLayout.useSetSortOptions();
  const setCount = useListingLayout.useSetCount();

  useEffect(() => {
    setSortOptions([
      {
        label: "Last Updated",
        value: "last_updated",
      },
      {
        label: "Last Created",
        value: "last_created",
      },
      {
        label: "Name",
        value: "name",
      },
    ]);

    setSortValue("last_updated");
  }, []);

  useEffect(() => {
    let breadcrumbs: { id: string; name: string; url?: string }[] = [
      { id: "root", name: "Guis", url: "/gui-module" },
    ];

    const addSearchResults = () => {
      if (searchTags.length > 0) {
        breadcrumbs.push({
          id: "search_results",
          name: "Search Results",
        });
      }
    };

    if (folders?.length && folderId) {
      const folder = folders.find((f) => f.slug === folderId);
      const parentFolder = folders.find(
        (f) => f.slug === folder?.parent_folder_id
      );

      if (folder) {
        if (parentFolder) {
          breadcrumbs.push({
            id: parentFolder.slug,
            name: parentFolder.name,
            url: `/gui-module?folder_id=${parentFolder.slug}`,
          });
        }
        breadcrumbs.push({
          id: folder.slug,
          name: folder.name,
          url: `/gui-module?folder_id=${folder.slug}`,
        });
        addSearchResults();
      } else {
        addSearchResults();
      }
    } else {
      addSearchResults();
    }

    setBreadcrumbs(breadcrumbs);
  }, [folderId, folders, searchTags.length, setBreadcrumbs]);

  const sortData = useCallback(
    (guiItems: GfGui[], folderItems: Folder[]) => {
      let sortedGuiItems = guiItems;
      let sortedFolderItems = folderItems;
      const sortOptions = {
        key: "",
        isDateType: false,
      };

      if (sortValue === "last_updated") {
        sortOptions.key = "updated_at";
        sortOptions.isDateType = true;
      } else if (sortValue === "last_created") {
        sortOptions.key = "created_at";
        sortOptions.isDateType = true;
      } else if (sortValue === "name") {
        sortOptions.key = "name";
        sortOptions.isDateType = false;
      }

      if (sortOptions.key) {
        sortedGuiItems = sortItems(
          sortedGuiItems,
          sortOptions.key as keyof GfGui,
          true,
          sortOptions.isDateType
        );
        sortedFolderItems = sortItems(
          sortedFolderItems,
          sortOptions.key as keyof Folder,
          true,
          sortOptions.isDateType
        );
      }

      return { guiItems: sortedGuiItems, folderItems: sortedFolderItems };
    },
    [sortValue]
  );

  const data = useMemo(() => {
    let filteredGuis: GfGui[] = guis || [];
    let filteredFolders: Folder[] = folders || [];

    // Perform search filtering across all guis and folders
    if (searchTags.length > 0) {
      const searchTagLowered = searchTags.map((tag) => tag.toLowerCase());

      filteredGuis = filteredGuis.filter((gui) =>
        searchTagLowered.some((keyword) =>
          gui.name.toLowerCase().includes(keyword)
        )
      );

      filteredFolders = filteredFolders.filter((folder) =>
        searchTagLowered.some((keyword) =>
          folder.name.toLowerCase().includes(keyword)
        )
      );
    } else {
      // If no search tags, maintain the folder structure
      if (folderId) {
        const folder = folders?.find((folder) => folder.slug === folderId);
        filteredGuis = filteredGuis.filter((f) =>
          folder?.childs.some((c) => c.id === f.id && c.slug === f.slug)
        );
        filteredFolders = filteredFolders.filter(
          (f) => f.parent_folder_id === folderId
        );
      } else {
        filteredGuis = filteredGuis.filter(
          (f) =>
            !folders?.some((folder) =>
              folder.childs.find((c) => c.id === f.id && c.slug === f.slug)
            )
        );
        filteredFolders = filteredFolders.filter((f) => !f.parent_folder_id);
      }
    }

    const sortedData = sortData(filteredGuis, filteredFolders);
    filteredGuis = sortedData.guiItems;
    filteredFolders = sortedData.folderItems;

    let finalList: ListingItem[] =
      filteredGuis.map((gui) => ({
        type: "grid-item",
        item: {
          ...gui,
          iconComp: <Icon iconName={gui.icon} />,
          imageUrl: "/assets/images/fusion-flow.png",
          lastUpdated: moment.utc(gui.updated_at || gui.created_at).fromNow(),
          link: `/${accountSlug}/gui-module/${gui.slug}`,
          title: (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                variant="rounded"
                sx={{
                  width: "32px",
                  height: "32px",
                  color: "#fff",
                  background: (theme) =>
                    !gui.icon ? "transparent" : theme.palette.background.GF10,
                }}
              >
                <Icon iconName={gui.icon} fontSize="small" />
              </Avatar>
              <Typography>{gui.name}</Typography>
            </Stack>
          ),
        },
      })) || [];

    const folderList: ListingItem[] =
      filteredFolders.map((folder) => ({
        type: "folder",
        folder: {
          ...folder,
          link: `/${accountSlug}/gui-module?folder_id=${folder.slug}`,
          title: (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                variant="rounded"
                sx={{
                  width: "32px",
                  height: "32px",
                  color: "#fff",
                  background: (theme) => theme.palette.background.GF10,
                }}
              >
                <Icon iconName="Folder" fontSize="small" />
              </Avatar>
              <Typography>{folder.name}</Typography>
            </Stack>
          ),
          lastUpdated: moment
            .utc(folder.updated_at || folder.created_at)
            .fromNow(),
        },
      })) || [];

    finalList.unshift(...folderList);

    return finalList;
  }, [folderId, folders, guis, sortData, searchTags, accountSlug]);

  useEffect(() => {
    setCount(data.length);
  }, [data]);

  const handleFolderClick = (folder: Folder) => {
    setSearchTags([]);
    // const searchParams = getSearchParams();
    // searchParams.set("folder_id", `${folder.slug}`);
    // navigate({ search: `?${searchParams.toString()}` });
  };

  const handleFolderDrop = (folderSlug: string, itemSlug: string) => {
    const folder = folders?.find((f) => f.slug === folderSlug);
    if (!folder || !accountSlug) {
      return;
    }

    const itemFolder = folders?.find((f) =>
      f.childs.some((c) => c.slug === itemSlug && c.id === accountSlug)
    );

    updateFolder({
      slug: folder.slug,
      data: {
        childs: (folder.childs || []).concat({
          id: accountSlug,
          slug: itemSlug,
        }),
      },
    });

    if (itemFolder) {
      updateFolder({
        slug: itemFolder.slug,
        data: {
          childs: itemFolder.childs.filter((c) => c.slug !== itemSlug),
        },
      });
    }
  };

  return (
    <React.Fragment>
      <ListingLayout
        data={data}
        idKey="slug"
        tableColumns={[
          {
            label: "Workspace Name",
            fieldKey: "title",
            folderKey: "title",
          },
          {
            label: "Last Updated",
            fieldKey: "lastUpdated",
            folderKey: "lastUpdated",
          },
        ]}
        topBarProps={{
          extra: (
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButtonWithTooltip
                size="small"
                tooltipProps={{ title: "Add Workspace" }}
                onClick={() => setAddModalOpen(true)}
              >
                <AddToQueue />
              </IconButtonWithTooltip>
              <IconButtonWithTooltip
                size="small"
                tooltipProps={{ title: "Add Folder" }}
                onClick={() => setFolderModalOpen(true)}
              >
                <AddFolder />
              </IconButtonWithTooltip>
            </Stack>
          ),
        }}
        actions={[
          {
            title: "Open",
            icon: <EditOutlined />,
            onClick(id) {
              const gui = guis?.find((gui) => gui.slug === id);
              if (gui) {
                navigate(`/gui-module/${gui.slug}`);
              }
            },
          },
          {
            title: "Rename",
            icon: <DriveFileRenameOutline />,
            onClick(id) {
              const gui = guis?.find((gui) => gui.slug === id);
              if (gui) {
                onRename?.({
                  title: gui.name,
                  key: gui.slug,
                  model: ApiModels.Gui,
                });
              }
            },
          },
          {
            title: "Delete",
            icon: <DeleteOutlined />,
            onClick(id) {
              deleteGui({ slug: id });
            },
          },
        ]}
        folderActions={[
          {
            title: "Open",
            icon: <EditOutlined />,
            onClick(folder) {
              handleFolderClick(folder);
            },
          },
          {
            title: "Rename",
            icon: <DriveFileRenameOutline />,
            onClick(folder) {
              onRename?.({
                title: folder.name,
                key: folder.slug,
                model: ApiModels.Folder,
                updateItemOptions: {
                  listQueryKey: [ApiModels.Folder, routeModule],
                  mutationOptions: {
                    mutationKey: [ApiModels.Folder, routeModule],
                  },
                },
              });
            },
          },
          {
            title: "Delete",
            icon: <DeleteOutlined />,
            onClick(folder) {
              deleteFolder({ slug: folder.slug });
            },
          },
        ]}
        gridKeyMap={{
          id: "slug",
          icon: "iconComp",
          imageUrl: "imageUrl",
          title: "name",
          subtitle: "lastUpdated",
          link: "link",
        }}
        onFolderClick={handleFolderClick}
        onOpenClick={(id) => {
          const gui = guis?.find((gui) => gui.slug === id);
          if (gui) {
            navigate(`/gui-module/${gui.slug}`);
          }
        }}
        onMediaClick={(id) => {
          const gui = guis?.find((gui) => gui.slug === id);
          if (gui) {
            navigate(`/gui-module/${gui.slug}`);
          }
        }}
        onItemDrop={handleFolderDrop}
      />
      <AddGuiModalModal
        open={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        onSubmit={(data) => {}}
      />
      <AddFolderModel
        open={folderModalOpen}
        parentFolderId={folderId}
        onClose={() => setFolderModalOpen(false)}
      />
      <RenameModel module={ApiModels.Folder} title="Rename" />
    </React.Fragment>
  );
};

export default Guis;
