import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  styled,
} from "@mui/material";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import { DateType, DocumentElementType } from "enums/Form";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const Container = styled(Stack)({
  maxWidth: "600px",
  width: "100%",
});

const formSchema = z.object({
  title: z.string(),
  file: z.object({
    name: z.string(),
    url: z.string(),
    size: z.number(),
    type: z.string(),
  }),
  tag: z.string().optional(),
  icon: z.string().optional(),
  expiration_date: z.string(),
});

type AddAgentDocumentFormType = z.infer<typeof formSchema>;

type AddAgentDocumentProps = {
  agent: Agent;
  onClose?(): void;
  onCreated?(): void;
} & DialogProps;

const AddAgentDocument: FC<AddAgentDocumentProps> = (props) => {
  const { agent, onClose, onCreated, ...dialogProps } = props;

  const form = useForm<AddAgentDocumentFormType>({
    resolver: zodResolver(formSchema),
  });

  const { control, handleSubmit } = form;

  const { mutateAsync: createAgentDocument, isLoading } = useCreateItem({
    modelName: ApiModels.SSAgentDocument,
  });

  useEffect(() => {
    return () => {
      form.reset();
    };
  }, [dialogProps.open]);

  const submitHandler = async (values: AddAgentDocumentFormType) => {
    await createAgentDocument({
      ...values,
      agent_slug: agent.slug,
    });

    onClose?.();
    onCreated?.();
  };

  return (
    <Dialog
      onClose={(e, r) => {
        console.log(e, r);
      }}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Add Document</DialogTitle>
      <Scrollbar className="form-scroller">
        <DialogContent>
          <FormProvider {...form}>
            <Stack alignItems="center">
              <Container gap={2}>
                <FormField
                  label="Title"
                  type={DocumentElementType.TextField}
                  formControl={{ control, name: "title" }}
                />
                <FormField
                  label="File"
                  type={DocumentElementType.File}
                  formControl={{ control, name: "file" }}
                  fieldExtras={{ field: { display_type: "v2" } }}
                />
                <FormField
                  label="Tag"
                  type={DocumentElementType.TextField}
                  formControl={{ control, name: "tag" }}
                />
                <FormField
                  label="Icon"
                  type={DocumentElementType.Icon}
                  formControl={{ control, name: "icon" }}
                />
                <FormField
                  label="Expiration"
                  type={DocumentElementType.Date}
                  formControl={{ control, name: "expiration_date" }}
                  fieldExtras={{ field: { date_type: DateType.DateOnly } }}
                />
              </Container>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Scrollbar>
      <DialogActions>
        <LoadingButton onClick={onClose}>Cancel</LoadingButton>
        <LoadingButton
          onClick={handleSubmit(submitHandler, (error) => {
            console.log(error);
          })}
          variant="contained"
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddAgentDocument;
