import { FC } from "react";
import AgentDocuments from "views/app-view/ss-agent/TabContent/AgentDocuments";
import AgentSkills from "views/app-view/ss-agent/TabContent/AgentSkills";
import AgentSettings from "./AgentSettings";

type TabContentProps = {
  tab: string;
  agent: Agent;
};

const TabContent: FC<TabContentProps> = (props) => {
  const { tab, agent } = props;

  switch (tab) {
    case "settings":
      return <AgentSettings agent={agent} />;
    case "skills":
      return <AgentSkills agent={agent} />;
    case "documents":
      return <AgentDocuments agent={agent} />;

    default:
      return <></>;
  }
};

export default TabContent;
