import Model from "models";

class AgentSkillModel extends Model<AgentSkill> {
  constructor() {
    super("/agent-skill", "gui-fusion");
  }
}

const model = new AgentSkillModel();

export default model;
