import Model from "models";

class SSAgentSkillAsscModel extends Model<AgentSkillAssc> {
  constructor() {
    super("/ss-agent-skill-assc", "gui-fusion");
  }
}

const model = new SSAgentSkillAsscModel();

export default model;
