import Model from "models";

class SSAgentModel extends Model<Agent> {
  constructor() {
    super("/ss-agent", "gui-fusion");
  }
}

const model = new SSAgentModel();

export default model;
