import { ColDef } from "ag-grid-community";
import DefaultMultiSelectEditor from "components/DatasetTable/colDefs/DefaultMultiSelectEditor";
import DefaultTagRenderer from "components/DatasetTable/colDefs/DefaultTagRenderer";

const getMultiSelectEditorDefs = (
  field: DataField,
  options?: { disableColumnFilters?: boolean }
): ColDef => ({
  cellRenderer: DefaultTagRenderer,
  cellEditor: DefaultMultiSelectEditor,
  cellEditorParams: {
    values: field.list_items ?? [],
  },
});

export default getMultiSelectEditorDefs;
