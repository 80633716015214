import { useQuery } from "@tanstack/react-query";
import AccountUser from "models/AccountUser";

const useUser = (userSlug?: string) => {
  return useQuery({
    queryKey: ["user", userSlug],
    queryFn: async () => {
      const response = await AccountUser.get(userSlug!);
      return response.data;
    },
    enabled: !!userSlug,
  });
};

export default useUser;
