import { useQuery } from "@tanstack/react-query";
import Copilot from "models/Copilot";

export const getThreadAttachmentQueryKey = (threadSlug?: string) => [
  "copilot",
  "threads",
  threadSlug,
  "attachments",
];

const useThreadAttachments = (threadSlug?: string) => {
  return useQuery({
    queryKey: getThreadAttachmentQueryKey(threadSlug),
    queryFn: async () => {
      const response = await Copilot.listThreadAttachments(threadSlug!);
      return response.data;
    },
    enabled: !!threadSlug,
  });
};

export default useThreadAttachments;
