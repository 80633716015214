import Add from "@mui/icons-material/Add";
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined";
import Close from "@mui/icons-material/Close";
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";
import Settings from "@mui/icons-material/Settings";
import {
  Button,
  Chip,
  IconButton,
  Stack,
  styled,
  Tooltip,
} from "@mui/material";
import FusionActionsDropdown from "components/FusionAction/FusionActionsDropdown";
import Icon from "components/util-components/Icon";
import { ActionType } from "enums/gui";
import useAccountSlug from "hooks/useAccountSlug";
import useCurrentUser from "hooks/useCurrentUser";
import FusionModel from "models/Fusion";
import { useSnackbar } from "notistack";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { FC, useMemo } from "react";
import useFusionAction from "store/stores/fusion-action";
import { useGuiDashboardV2Store } from "store/stores/gui-dashboard-v2";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import useGuiParamsStore from "store/stores/gui-prams";

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: "4px",
  color: theme.palette.text.secondary,
  fontWeight: 400,
  fontSize: "14px",
  borderColor: theme.palette.gfGrey.Divider,
  height: "32px",

  "&:hover": {
    background: theme.palette.background.GF10,
    borderColor: theme.palette.background.GF20,
  },
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  borderRadius: "0px",
  color: theme.palette.text.secondary,
  border: `1px solid ${theme.palette.gfGrey.Divider}`,
  background: "transparent",
  height: "32px",
  width: "32px",
}));

type ActionBarProps = {
  guiSlug?: string;
  associatedActions?: FusionAction[];
  onAddWidget(): void;
  onFiltersClick(): void;
};

const ActionBar: FC<ActionBarProps> = (props) => {
  const {
    guiSlug,
    onAddWidget,
    onFiltersClick,
    associatedActions = [],
  } = props;

  const user = useCurrentUser();
  const accountSlug = useAccountSlug();

  const { enqueueSnackbar } = useSnackbar();

  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();
  const setWidget = useGuiDashboardV2Store.useSetWidget();
  const guiParamValues = useGuiParamsStore.useGuiParamValues();
  const setGuiParamValues = useGuiParamsStore.useSetGuiParamValues();
  const rerenderGui = useGuiParamsStore.useRerenderGui();
  const setActionFormOpen = useFusionAction.useSetActionFormOpen();
  const setFormAction = useFusionAction.useSetFormAction();

  const { data: params = [] } = useListItems({
    modelName: ApiModels.GuiParams,
    requestOptions: { query: { param_group_slug: guiSlug } },
    queryKey: [ApiModels.GuiParams, guiSlug],
    queryOptions: {
      enabled: !!guiSlug,
    },
  });

  const tags = useMemo(() => {
    return params
      .filter((param) => guiParamValues[param.param_slug] != null)
      .map((param) => ({
        label: param.title,
        value: guiParamValues[param.param_slug],
        slug: param.param_slug,
      }));
  }, [guiParamValues, params]);

  const pinnedActions = useMemo(() => {
    return associatedActions.filter((action) => action.action_pinned);
  }, [associatedActions]);

  const handleActionClick = (action: FusionAction) => {
    if (action.action_type === ActionType.OpenForm) {
      setFormAction(action);
      setActionFormOpen(true);
    } else {
      enqueueSnackbar({ variant: "success", message: "Fusion Action Called!" });
      FusionModel.runFusion(action.fusion_slug, {
        user_id: user.slug,
        account_id: accountSlug!,
        popup_variables: {
          popup_variables: {},
        },
      }).catch(() => {
        enqueueSnackbar({ variant: "error", message: "Fusion Action Failed!" });
      });
    }
  };

  return (
    <Stack
      sx={{ marginBottom: "36px" }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <ActionButton
          variant="outlined"
          startIcon={<CalendarTodayOutlined fontSize="small" />}
          onClick={onFiltersClick}
        >
          Selected Dates
        </ActionButton>
        <ActionButton
          variant="outlined"
          startIcon={<FilterAltOutlined fontSize="small" />}
          onClick={onFiltersClick}
        >
          Filters
        </ActionButton>
        <Stack direction="row" alignItems="center" gap={1}>
          {tags.map((tag) => (
            <Chip
              label={`${tag.label}: ${tag.value}`}
              size="small"
              color="primary"
              sx={{ height: "27px" }}
              key={`${tag.label}: ${tag.value}`}
              deleteIcon={<Close fontSize="small" />}
              onDelete={() => {
                setGuiParamValues({ ...guiParamValues, [tag.slug]: undefined });
                setTimeout(() => {
                  rerenderGui();
                }, Infinity - 1);
              }}
            />
          ))}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <Stack direction="row" alignItems="center" gap="6px">
          {pinnedActions.map((action) => (
            <Tooltip
              title={`${action.action_title}${
                action.action_hotkey ? ` (${action.action_hotkey})` : ""
              }`}
              key={action.id}
            >
              <IconButtonStyled onClick={() => handleActionClick(action)}>
                <Icon iconName={action.action_icon} fontSize="small" />
              </IconButtonStyled>
            </Tooltip>
          ))}
        </Stack>
        <FusionActionsDropdown
          actions={associatedActions}
          editable={editModeEnabled}
          extraMenuItems={
            editModeEnabled
              ? [
                  {
                    title: "Add Widget",
                    id: "add_widget",
                    icon: <Add />,
                    onClick() {
                      setWidget(null);
                      onAddWidget();
                    },
                  },
                ]
              : []
          }
        >
          <IconButtonStyled>
            <Settings fontSize="small" />
          </IconButtonStyled>
        </FusionActionsDropdown>
      </Stack>
    </Stack>
  );
};

export default ActionBar;
