import InsertDriveFileOutlined from "@mui/icons-material/InsertDriveFileOutlined";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import ActionMenu from "components/ActionMenu";
import { ApiModels } from "queries/apiModelMapping";
import useThreadAgent from "queries/copilot/useThreadAgent";
import useListItems from "queries/useListItems";
import { FC } from "react";
import useAgentChatStore from "store/stores/agent-chat";
import { Item } from "views/app-view/agent-chat/RightBar/DetailsWidget/SessionDetails";

const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.background.CardBackground,
  borderRadius: "6px",
}));

const ItemContainer = styled(Stack)({
  padding: "16px",
});

type TaskItemProps = {
  jobTask: JobTask;
  onViewLogsClick(task: JobTask): void;
};

const TaskItem: FC<TaskItemProps> = (props) => {
  const { jobTask, onViewLogsClick } = props;

  const agent = useThreadAgent(jobTask.task_assigned_agent);

  return (
    <Container>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Avatar
            src={
              agent?.image?.url ?? "https://mui.com/static/images/avatar/1.jpg"
            }
            variant="circular"
            sx={{ width: "24px", height: "24px" }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.text.primary,
              fontWeight: "500",
              fontSize: "15px",
            }}
          >
            {agent?.agent_name ?? "Copilot"}
          </Typography>
        </Stack>
        <ActionMenu
          menuItems={[
            {
              label: "View Logs",
              value: "view_logs",
              icon: <InsertDriveFileOutlined />,
            },
          ]}
          onItemClick={(value) => {
            if (value === "view_logs") {
              onViewLogsClick(jobTask);
            }
          }}
        >
          <IconButton size="small" sx={{ p: 0 }}>
            <MoreHoriz fontSize="small" />
          </IconButton>
        </ActionMenu>
      </Stack>
      <Divider
        sx={{
          borderColor: (theme) => theme.palette.background.ListWidgetItem,
        }}
      />
      <ItemContainer>
        <Item title="Task Name" value={jobTask.slug} />
        <Item title="Task Status" value={jobTask.task_status} />
        <Item
          title="Total Scratchpads"
          value={jobTask.task_scratchpads.length ?? 0}
        />
      </ItemContainer>
    </Container>
  );
};

type TaskListProps = {
  job?: Job;
  onViewLogsClick(task: JobTask): void;
};

const TaskList: FC<TaskListProps> = (props) => {
  const { job, onViewLogsClick } = props;

  const thread = useAgentChatStore.useSelectedThread();

  const { data: jobTasks = [] } = useListItems({
    modelName: ApiModels.JobTask,
    requestOptions: { query: { job_slug: job?.slug, thread_id: thread?.id } },
    queryKey: [ApiModels.JobTask, job?.slug, thread?.id],
  });

  return (
    <Stack direction="column" gap={2} mt={2}>
      {jobTasks.map((jobTask) => (
        <TaskItem jobTask={jobTask} onViewLogsClick={onViewLogsClick} />
      ))}
    </Stack>
  );
};

export default TaskList;
