import { ColDef } from "ag-grid-community";
import DefaultListCellTooltip from "components/DatasetTable/colDefs/DefaultListCellTooltip";
import UserCellEditor from "components/DatasetTable/colDefs/user/UserCellEditor";
import UserCellRenderer, {
  getAvatar,
} from "components/DatasetTable/colDefs/user/UserCellRenderer";

const getUserColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => {
  return {
    ...defaults,
    cellRenderer: UserCellRenderer,
    cellRendererParams: {
      field,
    },
    cellEditor: UserCellEditor,
    cellEditorPopup: true,
    cellEditorPopupPosition: "under",
    cellEditorParams: {
      field,
    },
    tooltipField: field.multi_user ? field.slug : undefined,
    tooltipComponent: DefaultListCellTooltip,
    tooltipComponentParams: {
      getListData(value: string, { users = [] }: { users: User[] }) {
        const user = users.find((u) => u.slug === value);
        const name = `${user?.first_name} ${user?.last_name}`;
        return {
          text: name,
          icon: getAvatar(name, user?.image?.url),
        };
      },
    },
  };
};

export default getUserColDef;
