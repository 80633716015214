import { Chip } from "@mui/material";
import { ColDef } from "ag-grid-community";
import DefaultListCellTooltip from "components/DatasetTable/colDefs/DefaultListCellTooltip";
import getMultiSelectEditorDefs from "components/DatasetTable/colDefs/getMultiSelectEditorDefs";
import getSingleSelectEditorDefs from "components/DatasetTable/colDefs/getSingleSelectEditorDefs";
import { SelectTypes } from "enums/Form";

const getSelectColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => {
  if (field.list_default_display_type === SelectTypes.Single) {
    return {
      ...defaults,
      ...getSingleSelectEditorDefs(field, options),
    };
  }

  return {
    ...defaults,
    ...getMultiSelectEditorDefs(field, options),
    tooltipField: field.slug,
    tooltipComponent: DefaultListCellTooltip,
    tooltipComponentParams: {
      getListData(value: string) {
        const option = field.list_items?.find((item) => item.value === value);

        return {
          text: (
            <Chip
              size="small"
              label={option?.label}
              sx={{ bgcolor: option?.color }}
            />
          ),
        };
      },
    },
  };
};

export default getSelectColDef;
