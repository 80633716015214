import ArrowBack from "@mui/icons-material/ArrowBack";
import ChatBubbleOutlineOutlined from "@mui/icons-material/ChatBubbleOutlineOutlined";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import TabStyled from "components/TabStyled";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import useUser from "hooks/useUser";
import InnerPageLayout from "layouts/inner-app-layout";
import { FC, useEffect } from "react";
import useAgentChatStore from "store/stores/agent-chat";
import ChatSection from "views/app-view/agent-chat/MiddleSection/ChatSection";

dayjs.extend(utc);

const Container = styled(Box)(({ theme }) => ({
  paddingTop: "26px",
  height: "100%",

  "#chat-messages-container": {
    margin: 0,
  },

  "#agent-chat-input-field": {
    paddingLeft: "20px",
    paddingRight: "20px",
  },

  ".rc-scrollbar, .layout-container": {
    height: "100%",
  },

  ".heading-wrap": {
    justifyContent: "space-between",
  },

  ".layout-container": {
    height: "calc(100% - 44px)",

    ".center-box": {
      height: "calc(100% - 30px)",
    },
  },

  ".tabs-container": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    padding: 0,

    ".MuiTabs-root": {
      width: "100%",

      ".MuiTabs-scroller": {
        width: "100%",

        ".MuiTabs-flexContainer": {
          borderBottom: `1px solid ${theme.palette.gfGrey.Divider}`,
          width: "100%",
          paddingLeft: "20px",
          paddingRight: "20px",
        },

        ".MuiTabs-indicator": {
          bottom: "2px",
          height: "2px",
        },
      },
    },
  },

  "#user-chat-message-item, #agent-chat-message-item": {
    padding: "20px",
    marginTop: 0,
  },

  "#user-chat-message-item": {
    background: theme.palette.background.GF5,
    flexDirection: "row",
  },

  "#agent-chat-message-item-content": {
    maxWidth: "100%",
  },

  "#agent-chat-message-item-bubble": {
    background: "transparent",
    padding: 0,
  },
}));

type ChatViewProps = {
  thread: CopilotThread;
  onBack(): void;
};

const ChatView: FC<ChatViewProps> = (props) => {
  const { thread, onBack } = props;

  const setSelectedThread = useAgentChatStore.useSetSelectedThread();

  const { data: user } = useUser(thread.user_slug);

  useEffect(() => {
    setSelectedThread(thread);
  }, [setSelectedThread, thread]);

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        paddingLeft="20px"
        paddingRight="20px"
        mb={3}
      >
        <IconButton onClick={onBack} sx={{ padding: 0 }}>
          <ArrowBack sx={{ fontSize: 20 }} />
        </IconButton>
        <Divider orientation="vertical" flexItem sx={{ height: "16px" }} />
        <Typography
          fontSize={13}
          fontWeight={300}
          maxWidth={300}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          color="text.primary"
        >
          {user?.first_name ?? ""} {user?.last_name ?? ""} -&nbsp;
          {dayjs.utc(thread.created_at).format("MM/DD/YY h:mm A")}
        </Typography>
      </Stack>
      <InnerPageLayout
        hideHeader
        value="chat"
        tabList={[
          {
            label: <TabStyled title="Chat" />,
            value: "chat",
            icon: <ChatBubbleOutlineOutlined fontSize="small" />,
          },
          {
            label: "",
            icon: <Divider orientation="vertical" variant="middle" />,
            disabled: true,
          },
        ]}
      >
        <Box
          position="relative"
          height="100%"
          width="100%"
          paddingBottom="85px"
        >
          <ChatSection showAvatarForUser />
        </Box>
      </InnerPageLayout>
    </Container>
  );
};

export default ChatView;
