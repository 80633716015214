import ChatOutlined from "@mui/icons-material/ChatOutlined";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import KeyboardDoubleArrowRightOutlined from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import StarBorderPurple500Outlined from "@mui/icons-material/StarBorderPurple500Outlined";
import {
  Box,
  Divider,
  ListItem,
  Stack,
  styled,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FillStartIcon } from "assets/icons";
import { FC } from "react";
import { useSystemLayoutStore } from "store/stores/systemLayout";
import { BaseListStyled } from "../SideNav/index.styles";

const TopBarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "50px",
  top: "48px",
  position: "fixed",
  background: theme.palette.background.PageBackground,

  ".MuiTabs-root": {
    ".MuiButtonBase-root": {
      color: theme.palette.text.tab_inactive,
      ".tab-label": {
        color: theme.palette.text.tab_inactive,
        fontWeight: 400,
        fontSize: "14px",
      },

      "&.Mui-selected, &.Mui-selected .tab-label": {
        color: theme.palette.text.primary,
      },
    },
  },
}));

type TopBarProps = {};

const TopBar: FC<TopBarProps> = () => {
  const theme = useTheme();

  const topBarProps = useSystemLayoutStore.useTopBarProps();
  const selectedTopBarTab = useSystemLayoutStore.useSelectedTopBarTab();
  const setSelectedTopBarTab = useSystemLayoutStore.useSetSelectedTopBarTab();

  return (
    <TopBarContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        padding="10px"
        width="100%"
        height="100%"
      >
        <Stack direction="row" alignItems="center" gap={10}>
          <Stack
            direction="row"
            alignItems="center"
            gap="12px"
            className="data_set_title"
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                color: (theme) => theme.palette.text.primary,
                ">svg": {
                  width: "32px",
                  height: "32px",
                },
              }}
            >
              {topBarProps.icon}
            </Stack>
            <Typography
              variant="h6"
              fontSize={20}
              sx={{ ">*": { fontSize: "inherit" } }}
            >
              {topBarProps.title}
            </Typography>
            <Tooltip title="Favorite" arrow>
              <Box
                sx={{
                  padding: "3px 3px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  "&:hover": {
                    background: theme.palette.background.GF10,
                  },
                }}
              >
                {!true ? (
                  <StarBorderPurple500Outlined
                    className="selected"
                    sx={{
                      color: theme.palette.text.secondary,
                      width: "18px",
                      height: "18px",
                    }}
                  />
                ) : (
                  <FillStartIcon
                    sx={{
                      color: theme.palette.text.secondary,
                      width: "18px",
                      height: "18px",
                    }}
                  />
                )}
              </Box>
            </Tooltip>
          </Stack>

          {topBarProps.tabs && (
            <Tabs
              value={selectedTopBarTab}
              onChange={(_, value) => setSelectedTopBarTab(value)}
              TabIndicatorProps={{ sx: { display: "none" } }}
            >
              {topBarProps.tabs.map((tab) => (
                <Tab
                  label={
                    <Stack direction="row" alignItems="center">
                      <Typography className="tab-label">{tab.label}</Typography>
                      {tab.rightIcon}
                    </Stack>
                  }
                  value={tab.value}
                  icon={tab.icon}
                  iconPosition="start"
                />
              ))}
              {topBarProps.tabBarExtra}
            </Tabs>
          )}
        </Stack>

        <Stack direction="row" spacing={4} alignItems="center">
          <BaseListStyled>
            {topBarProps.extra?.left}
            <ListItem className="top-bar-list-item">
              <KeyboardDoubleArrowRightOutlined
                sx={{ color: theme.palette.text.secondary }}
              />
            </ListItem>
            <ListItem className="top-bar-list-item">
              <Tooltip title="Help" arrow>
                <HelpOutlineOutlined
                  sx={{ color: theme.palette.text.secondary }}
                />
              </Tooltip>
            </ListItem>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ height: "24px" }}
            />
            <ListItem className="top-bar-list-item">
              <Tooltip title="Chat" arrow>
                <ChatOutlined sx={{ color: theme.palette.text.secondary }} />
              </Tooltip>
            </ListItem>
            {topBarProps.extra?.right}
          </BaseListStyled>
        </Stack>
      </Stack>
    </TopBarContainer>
  );
};

export default TopBar;
