import { useTheme } from "@mui/material";
import { AgChartOptions, AgLineSeriesOptions } from "ag-charts-community";
import { AgCharts } from "ag-charts-react";
import { getDefaultChartOptions } from "helpers/gui";
import isEmpty from "lodash/isEmpty";
import { FC, useEffect, useState } from "react";

type LineChartWidgetProps = {
  data?: Record<string, unknown>;
};

const LineChartWidget: FC<LineChartWidgetProps> = (props) => {
  const { data: widgetData } = props;

  const theme = useTheme();

  const {
    title: chartTitle,
    subtitle: chartSubtitle,
    axis,
    ...defaultChartOptions
  } = getDefaultChartOptions(theme);

  const [chartOptions, setChartOptions] = useState<AgChartOptions>({
    ...defaultChartOptions,
    axes: [
      {
        type: "category",
        label: axis.label,
        line: axis.line,
        gridLine: axis.gridLine,
      },
    ],
  });

  useEffect(() => {
    if (!isEmpty(widgetData)) {
      setChartOptions({
        title: {
          text: widgetData.title as string,
          ...chartTitle,
        },
        subtitle: {
          text: widgetData.subtitle as string,
          ...chartSubtitle,
        },
        data: (widgetData.data as Array<Record<string, unknown>>) ?? [],
        series:
          (widgetData.series as Omit<AgLineSeriesOptions, "type">[])?.map(
            (s) => {
              return {
                type: "line",
                ...s,
              };
            }
          ) ?? [],
        axes: [
          {
            type: (widgetData.axes as Record<string, unknown>)?.xType as
              | "category"
              | "number"
              | "time",
            position: "bottom",
            ...axis,
          },
          {
            type: (widgetData.axes as Record<string, unknown>)?.yType as
              | "category"
              | "number"
              | "time",
            position: "left",
            ...axis,
          },
        ],
        ...defaultChartOptions,
      });
    }
  }, [widgetData]);

  return (
    <AgCharts
      style={{ height: "100%", width: "100%" }}
      options={chartOptions}
    />
  );
};

export default LineChartWidget;
