import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import {
  Box,
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Scrollbar from "components/Scrollbar";
import React, { FC } from "react";
import useSSAgent from "store/stores/ss-agent";
import AgentDocumentSideBar from "views/app-view/ss-agent/EditDrawer/AgentDocumentSidebar";
import AgentSkillSideBar from "views/app-view/ss-agent/EditDrawer/AgentSkillSideBar";

const ScrollbarContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "420px",
  height: "100vh",
  background: theme.palette.background.InnerPageLayout,
}));

const Container = styled(Stack)(({ theme }) => {
  return {
    position: "relative",
    width: "100%",
    height: "100%",
    padding: "16px",

    ".tabs-container": {
      padding: "0 !important",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",

      ".MuiTabs-root": {
        width: "100%",

        ".MuiTabs-scroller": {
          width: "100%",

          ".MuiTabs-flexContainer": {
            borderBottom: `1px solid ${theme.palette.gfGrey.Divider}`,
            width: "100%",
          },

          ".MuiTabs-indicator": {
            bottom: "2px",
            height: "2px",
          },
        },
      },
    },
  };
});

const DrawerContent: FC = () => {
  const editDrawer = useSSAgent.useEditDrawer();
  const setEditDrawer = useSSAgent.useSetEditDrawer();

  return (
    <ScrollbarContainer>
      <Scrollbar>
        <Container direction="column" gap={2} alignItems="flex-start">
          {editDrawer.enabled && (
            <>
              <IconButton
                size="small"
                onClick={() => {
                  setEditDrawer({
                    enabled: false,
                  });
                }}
                sx={{ ml: "-8px" }}
              >
                <ArrowBackOutlined fontSize="small" />
              </IconButton>
              <Typography>
                Edit Agent {editDrawer.type === "skill" ? "Skill" : "Document"}
              </Typography>
              {editDrawer.type === "skill" && (
                <AgentSkillSideBar agentSkill={editDrawer.skill} />
              )}
              {editDrawer.type === "document" && (
                <AgentDocumentSideBar agentDocument={editDrawer.document} />
              )}
            </>
          )}
        </Container>
      </Scrollbar>
    </ScrollbarContainer>
  );
};

type EditDrawerProps = DrawerProps;

const EditDrawer: React.FC<EditDrawerProps> = (props) => {
  const editDrawer = useSSAgent.useEditDrawer();
  const setEditDrawer = useSSAgent.useSetEditDrawer();

  return (
    <Drawer
      anchor="right"
      open={editDrawer.enabled}
      onClose={() => {
        setEditDrawer({
          enabled: false,
        });
      }}
      {...props}
    >
      <DrawerContent />
    </Drawer>
  );
};

export default EditDrawer;
