import {
  ArrowDropDown,
  CloseOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import Scrollbar from "components/Scrollbar";
import { SYSTEM_NODE_APP, WIDGET_START_NODE_MODULE } from "constants/Fusion";
import { ParameterType } from "enums/3pApp";
import { DocumentElementType } from "enums/Form";
import { FusionType, SystemModuleType } from "enums/Fusion";
import { GuiType } from "enums/gui";
import get from "lodash/get";
import isArray from "lodash/isArray";
import { ApiModels } from "queries/apiModelMapping";
import useAuthenticate from "queries/auth/useAuthenticate";
import useActionFields from "queries/formDesign/useActionFields";
import useFusion from "queries/fusion/useFusion";
import useFusionWebhooks from "queries/fusion/useFusionWebhooks";
import useGFMLFunctionGroups from "queries/fusion/useGFMLFunctionGroups";
import { useGuiParams } from "queries/guiParams/useGuiParams";
import useGetItem from "queries/useGetItem";
import useListItems from "queries/useListItems";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useFusionFlowStore } from "store/stores/fusion-flow";
import { getIncomingOperators, isWidgetFusion } from "utils";

// const colors = ["#ABB5C0", "#4496BF", "#E9E9EB", "#79B837"];

const StyledTab = styled(Tabs)(({ theme }) => ({
  minHeight: "30px",
  padding: "10px 0",

  ".MuiTabScrollButton-root": {
    marginTop: "-4px",
  },

  ".MuiTab-root": {
    padding: "0 0 5px",
    minHeight: "27px",
    marginRight: "20px",
    minWidth: "inherit",
    fontSize: "16px",
    lineHeight: "20px",
    // fontFamily: "Source Sans Pro, sans-serif",

    "&.Mui-selected": {
      color: theme.palette.text.primary,
    },
  },
}));

const ModalHeader = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.GF7,
}));

const FunctionPanel: React.FC<{
  groups: GFMLFunctionSubGroups[];
  onTagClick: (func: GFMLFunction) => void;
}> = (props) => {
  const { groups, onTagClick } = props;
  const theme = useTheme();
  return (
    <Stack gap={1} className="popover-tags-wrap">
      {groups?.map((group, idx) => {
        const color = theme.palette.orange.GFOrange;
        return group.function_sub_group_name ? (
          <Stack gap={0.75}>
            <Typography variant="body2">
              {group.function_sub_group_name}
            </Typography>
            <div className="popover-tags">
              {group.functions.map((func) => {
                return (
                  <Chip
                    key={func.slug}
                    component="div"
                    // draggable={true}
                    sx={{
                      backgroundColor: color,
                      borderRadius: "4px",
                      fontSize: "14px",
                      height: "24px",
                      mr: 1,
                      mb: 1,
                      ".MuiChip-label": { py: 0, px: "6px" },
                    }}
                    // onDragStart={(e) => {
                    // 	e.dataTransfer.setData(
                    // 		"text/plain",
                    // 		JSON.stringify({
                    // 			type: MappableTagType.Function,
                    // 			data: { ...func, function_color: color },
                    // 		})
                    // 	);
                    // }}
                    onClick={() =>
                      onTagClick({ ...func, function_color: color })
                    }
                    label={func.function_button_title || func.function_title}
                    size="small"
                  />
                );
              })}
            </div>
          </Stack>
        ) : null;
      })}
    </Stack>
  );
};

const OperatorPanel: React.FC<{
  interfaceParams: MappableParameter[];
  depth?: number;
  slugPrefix?: string;
  labelPrefix?: string;
  operatorStack?: MappableParameter[];
  onTagClick?(param: Record<string, any>): void;
}> = (props) => {
  const theme = useTheme();

  const {
    interfaceParams = [],
    depth = 0,
    slugPrefix,
    labelPrefix,
    operatorStack = [],
    onTagClick,
  } = props;
  const [showChild, setShowChild] = useState<string[]>(
    interfaceParams.map((param) => `${param.name}`)
  );

  return (
    <>
      {interfaceParams?.map((param) => {
        const collapsed = !showChild.includes(`${param.name}`);
        return (
          <Box key={param.name} sx={{ paddingLeft: 5 * depth }}>
            <Stack spacing={1} direction="row" sx={{ my: "4px" }}>
              <Chip
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "4px",
                  fontSize: "14px",
                  height: "24px",
                  ".MuiChip-label": { py: 0, px: "6px" },
                }}
                onClick={() =>
                  onTagClick?.({
                    ...param,
                    color: theme.palette.primary.main,
                    slug: `${slugPrefix ? slugPrefix + "." : ""}${param.name}`,
                    tagLabel: `${labelPrefix ? labelPrefix + "." : ""}${
                      param.label
                    }`,
                    operatorStack: [...operatorStack, param],
                  })
                }
                label={`${param.label || param.name}${
                  param.type === ParameterType.Array ? " [ ] " : ""
                }`}
              />
              {[ParameterType.Collection, ParameterType.Array].includes(
                param.type as ParameterType
              ) && param.spec?.length > 0 ? (
                <ArrowDropDown
                  onClick={() => {
                    if (!collapsed) {
                      setShowChild(
                        showChild.filter((name) => name !== param.name)
                      );
                    } else {
                      setShowChild([...showChild, `${param.name}`]);
                    }
                  }}
                  rotate={!collapsed ? 180 : 0}
                />
              ) : null}
            </Stack>
            {!collapsed &&
            [ParameterType.Collection, ParameterType.Array].includes(
              param.type as ParameterType
            ) ? (
              <OperatorPanel
                interfaceParams={(isArray(param.spec)
                  ? param.spec
                  : [param.spec]
                ).filter(Boolean)}
                depth={depth + 1}
                slugPrefix={`${slugPrefix ? slugPrefix + "." : ""}${
                  param.name
                }${param.type === ParameterType.Array ? "[]" : ""}`}
                labelPrefix={`${labelPrefix ? labelPrefix + "." : ""}${
                  param.label
                }${param.type === ParameterType.Array ? "[]" : ""}`}
                onTagClick={onTagClick}
                operatorStack={[...operatorStack, param]}
              />
            ) : null}
          </Box>
        );
      })}
    </>
  );
};

export const getCollectionSpecs = (
  interfaces: MappableParameter[],
  field: string
) => {
  let result = [...interfaces];

  if (field.indexOf(".") < 0) {
    result.push({
      name: field,
      type: ParameterType.Text,
      label: `${field.charAt(0).toUpperCase()}${field.slice(1)}`,
    });

    return result;
  }

  const [root, ...rest] = field.split(".");
  const remainingKeys = rest.join(".");
  let rootIdx = result.findIndex((r) => r.name === root);

  if (rootIdx < 0) {
    result.push({
      name: root,
      type: ParameterType.Text,
      label: `${root.charAt(0).toUpperCase()}${root.slice(1)}`,
    });
    rootIdx = result.length - 1;
  }
  const rootValue = result[rootIdx];
  rootValue.type = ParameterType.Collection;
  rootValue.spec = getCollectionSpecs(
    (rootValue.spec || []) as MappableParameter[],
    remainingKeys
  );

  return result;
};

export const getCollectionSpec = (field: DataField) => {
  if (field.type === DocumentElementType.Location) {
    return {
      name: field?.slug,
      label: field?.title,
      type: ParameterType.Collection,
      spec: [
        {
          name: "lat",
          type: ParameterType.Number,
          label: "Latitude",
        },
        {
          name: "lng",
          type: ParameterType.Number,
          label: "Longitude",
        },
      ],
    };
  }

  return {
    name: field?.slug,
    label: field?.title,
    ...(field.children && field.children.length > 0
      ? {
          type: ParameterType.Collection,
          spec: mapDocumentFieldsToInterface(field.children),
        }
      : {
          type: ParameterType.Text,
        }),
  };
};

export const mapDocumentFieldsToInterface = (fields: DataField[] = []) => {
  let interfaces: MappableParameter[] = [];
  fields.forEach((field) => {
    interfaces.push(getCollectionSpec(field));
  });
  return interfaces;
};

export const mapFieldsToInterface = (fields: string[]) => {
  let interfaces: MappableParameter[] = [];
  fields.forEach((field) => {
    interfaces = getCollectionSpecs(interfaces, field);
  });
  return interfaces;
};

export const getSystemModuleInterfaces = (
  fusion: Fusion,
  documents: DatasetDesign[],
  operator?: Partial<FusionOperator>,
  webhooks: FusionWebhook[] = []
) => {
  switch (operator?.app_module) {
    case SystemModuleType.AskQuestion:
      return [
        {
          name: "user_input",
          type: ParameterType.Text,
          label: "User Response",
        },
      ];
    case SystemModuleType.UpdateDisplay: {
      if (operator.operator_input_settings?.execution_type === "sync") {
        if (operator.operator_input_settings?.display_type === "fusion") {
          if (operator.operator_input_settings?.fusion_type === "open_fusion") {
            return [
              {
                name: "automation_flow",
                type: ParameterType.Collection,
                label: "Automation Flow",
                spec: [
                  {
                    name: "steps",
                    type: ParameterType.Array,
                    label: "Steps",
                    spec: [
                      {
                        name: "step_id",
                        type: ParameterType.Text,
                        label: "Step ID",
                      },
                      {
                        name: "operator_name",
                        type: ParameterType.Text,
                        label: "Operator Name",
                      },
                      {
                        name: "operator_type",
                        type: ParameterType.Text,
                        label: "Operator Type",
                      },
                      {
                        name: "parent_step",
                        type: ParameterType.Text,
                        label: "Parent Step",
                      },
                      {
                        name: "conditions",
                        type: ParameterType.Array,
                        label: "Conditions",
                        spec: [
                          {
                            name: "rule_name",
                            type: ParameterType.Text,
                            label: "Rule Name",
                          },
                          {
                            name: "condition_sets",
                            type: ParameterType.Array,
                            label: "Condition Sets",
                            spec: [
                              {
                                name: "lhs",
                                type: ParameterType.Text,
                                label: "LHS",
                              },
                              {
                                name: "rhs",
                                type: ParameterType.Text,
                                label: "RHS",
                              },
                              {
                                name: "comparison_type",
                                type: ParameterType.Text,
                                label: "Comparison Type",
                              },
                            ],
                          },
                        ],
                      },
                      {
                        name: "input_slots",
                        type: ParameterType.Collection,
                        label: "Input Slots",
                      },
                    ],
                  },
                ],
              },
            ];
          } else if (
            operator.operator_input_settings?.fusion_type === "create_fusion"
          ) {
            return [
              {
                name: "fusion_slug",
                type: ParameterType.Text,
                label: "Fusion Slug",
              },
            ];
          }
        }

        return [
          {
            name: "html",
            type: ParameterType.Text,
            label: "HTML",
          },
          {
            name: "css",
            type: ParameterType.Text,
            label: "CSS",
          },
          {
            name: "js",
            type: ParameterType.Text,
            label: "JS",
          },
        ];
      }

      return [];
    }
    case SystemModuleType.TriggerFusion:
      return [
        {
          name: "session_slug",
          type: ParameterType.Text,
          label: "Session ID",
        },
      ];
    case SystemModuleType.TranscriptionJobTrigger:
      return [
        {
          name: "jobName",
          type: ParameterType.Text,
          label: "Job Name",
        },
        {
          name: "accountId",
          type: ParameterType.Text,
          label: "Account Id",
        },
        {
          name: "results",
          type: ParameterType.Collection,
          label: "Results",
          spec: [
            {
              name: "transcripts",
              type: ParameterType.Array,
              label: "Transcripts",
              spec: [
                {
                  name: "transcript",
                  type: ParameterType.Text,
                  label: "Transcript",
                },
              ],
            },
            {
              name: "speaker_labels",
              type: ParameterType.Collection,
              label: "Speaker Labels",
              spec: [
                {
                  name: "channel_label",
                  type: ParameterType.Text,
                  label: "Channel Label",
                },
                {
                  name: "speakers",
                  type: ParameterType.UInteger,
                  label: "Speakers",
                },
                {
                  name: "segments",
                  type: ParameterType.Array,
                  label: "Segments",
                  spec: [
                    {
                      name: "start_time",
                      type: ParameterType.Text,
                      label: "Start Time",
                    },
                    {
                      name: "speaker_label",
                      type: ParameterType.Text,
                      label: "Speaker Label",
                    },
                    {
                      name: "end_time",
                      type: ParameterType.Text,
                      label: "End Time",
                    },
                    {
                      name: "items",
                      type: ParameterType.Array,
                      label: "Items",
                      spec: [
                        {
                          name: "start_time",
                          type: ParameterType.Text,
                          label: "Start Time",
                        },
                        {
                          name: "speaker_label",
                          type: ParameterType.Text,
                          label: "Speaker Label",
                        },
                        {
                          name: "end_time",
                          type: ParameterType.Text,
                          label: "End Time",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              name: "items",
              type: ParameterType.Array,
              label: "Items",
              spec: [
                {
                  name: "start_time",
                  type: ParameterType.Text,
                  label: "Start Time",
                },
                {
                  name: "speaker_label",
                  type: ParameterType.Text,
                  label: "Speaker Label",
                },
                {
                  name: "end_time",
                  type: ParameterType.Text,
                  label: "End Time",
                },
                {
                  name: "alternatives",
                  type: ParameterType.Array,
                  label: "Alternatives",
                  spec: [
                    {
                      name: "confidence",
                      type: ParameterType.Text,
                      label: "Confidence",
                    },
                    {
                      name: "content",
                      type: ParameterType.Text,
                      label: "Content",
                    },
                  ],
                },
                {
                  name: "type",
                  type: ParameterType.Text,
                  label: "Type",
                },
              ],
            },
          ],
        },
        {
          name: "status",
          type: ParameterType.Text,
          label: "Status",
        },
      ];
    case SystemModuleType.CreateTranscriptionJob:
      return [
        {
          name: "transcriptionJobName",
          type: ParameterType.Text,
          label: "Transcription Job Name",
        },
        {
          name: "transcriptionJobStatus",
          type: ParameterType.Select,
          label: "Transcription Job Status",
        },
      ];
    case SystemModuleType.SplitAudio:
      return [
        {
          name: "job_id",
          type: ParameterType.Text,
          label: "Job ID",
        },
        {
          name: "job_status",
          type: ParameterType.Text,
          label: "Status",
        },
      ];
    case SystemModuleType.GetTemporaryS3Link:
      return [
        {
          name: "url",
          type: ParameterType.Text,
          label: "S3 Link",
        },
      ];
    case SystemModuleType.CollectSlots:
      return (
        operator.operator_input_settings?.slots?.map(
          (slot: { name: string; slug: string }) => ({
            name: slot.slug,
            type: ParameterType.Text,
            label: slot.name,
          })
        ) || []
      );
    case SystemModuleType.Search3PList:
    case SystemModuleType.GetNextTask:
      return [
        {
          name: "data",
          type: ParameterType.Array,
          label: "Data",
        },
      ];
    case SystemModuleType.DocumentCreate:
    case SystemModuleType.DocumentDelete:
    case SystemModuleType.DocumentEdit:
      const documentKey = `${
        SystemModuleType.DocumentCreate.split("_")[1]
      }_automation` as keyof DocumentDesign;
      const connectedDocument = documents.find(
        (document) =>
          (document[documentKey] as unknown as DocumentFusion)?.fusion_slug ===
          fusion.slug
      );
      return [
        {
          name: "data",
          type: ParameterType.Collection,
          label: "Data",
          spec: [
            {
              name: "slug",
              type: ParameterType.Text,
              label: "ID",
            },
            ...mapDocumentFieldsToInterface(connectedDocument?.fields.fields),
          ],
        },
      ];
    case SystemModuleType.Import:
    case SystemModuleType.Event:
      const inputVars = fusion?.fusion_fields?.fields || [];
      return [
        {
          name: "data",
          type: ParameterType.Collection,
          label: "Data",
          spec: inputVars.map((v) => ({
            name: v.slug,
            type: ParameterType.Text,
            label: v.title,
          })),
        },
      ];
    case SystemModuleType.Read:
      return [
        {
          name: "data",
          type: ParameterType.Array,
          label: "Items",
          spec: [
            {
              name: "id",
              type: ParameterType.Text,
              label: "ID",
            },
            ...mapFieldsToInterface(
              operator.operator_input_settings?.fields || []
            ),
          ],
        },
        {
          name: "total_records",
          type: ParameterType.Integer,
          label: "Records Count",
        },
      ];
    case SystemModuleType.SocialMediaAutomation:
      return [
        {
          name: "data",
          type: ParameterType.Array,
          label: "Data",
        },
        {
          name: "total_records",
          type: ParameterType.Integer,
          label: "Records Count",
        },
      ];
    case SystemModuleType.Create:
    case SystemModuleType.Update:
      return [
        {
          name: "data",
          type: ParameterType.Collection,
          label: "Data",
          spec: [
            {
              name: "id",
              type: ParameterType.Text,
              label: "ID",
            },
            {
              name: "title",
              type: ParameterType.Text,
              label: "Record Title",
            },
          ],
        },
      ];
    case SystemModuleType.AddTag:
      return [
        {
          name: "data",
          type: ParameterType.Collection,
          label: "Data",
          spec: [
            {
              name: "slug",
              type: ParameterType.Text,
              label: "ID",
            },
            {
              name: "tag_value",
              type: ParameterType.Text,
              label: "Tag",
            },
          ],
        },
      ];
    case SystemModuleType.ReadOne:
      return [
        {
          name: "data",
          type: ParameterType.Collection,
          label: "Data",
          spec: [
            {
              name: "id",
              type: ParameterType.Text,
              label: "ID",
            },
            ...mapFieldsToInterface(
              operator.operator_input_settings?.fields || []
            ),
          ],
        },
      ];
    case SystemModuleType.Webhook:
      let moduleInterfaces: MappableParameter[] = [];
      const webhook = webhooks.find(
        (w) =>
          w.slug === operator.operator_input_settings?.fusion_connection_slug
      );
      const dataSpec = !!webhook?.json_passthrough
        ? undefined
        : webhook?.data_structure?.specifications;
      moduleInterfaces = [
        {
          name: "data",
          type: ParameterType.Collection,
          label: "Data",
          spec: dataSpec,
        },
      ];
      if (webhook?.get_request_headers) {
        moduleInterfaces.push({
          name: "request_headers",
          type: ParameterType.Array,
          label: "Request Headers",
        });
      }
      if (webhook?.get_request_http_method) {
        moduleInterfaces.push({
          name: "request_method",
          type: ParameterType.Text,
          label: "Request Method",
        });
      }
      return moduleInterfaces;
    case SystemModuleType.RestClient:
    case SystemModuleType.GraphQLClient:
      return [
        {
          name: "data",
          type: ParameterType.Collection,
          label: "Response Data",
        },
      ];
    case SystemModuleType.GetVariable:
    case SystemModuleType.SetVariable:
      return operator.operator_input_settings?.name
        ? [
            {
              name: operator.operator_input_settings?.name,
              type: ParameterType.Text,
              label: operator.operator_input_settings?.name,
            },
          ]
        : [];
    case SystemModuleType.GetMultipleVariables:
      return (
        operator.operator_input_settings?.variables.map(
          (v: { value: string }) => ({
            name: v.value,
            type: ParameterType.Text,
            label: v.value,
          })
        ) || []
      );
    case SystemModuleType.SetMultipleVariables:
      return (
        operator.operator_input_settings?.variables?.map(
          (v: { name: string }) => ({
            name: v.name,
            type: ParameterType.Text,
            label: v.name,
          })
        ) || []
      );
    case SystemModuleType.ArrayIterator:
      return [
        {
          name: "value",
          type: ParameterType.Text,
          label: "Value",
        },
        {
          name: "index",
          type: ParameterType.Text,
          label: "Item Index",
        },
        {
          name: "array_size",
          type: ParameterType.Text,
          label: "Items Count",
        },
      ];
    case SystemModuleType.Loop:
      return [
        {
          name: "item",
          type: ParameterType.Text,
          label: "Item",
        },
        {
          name: "index",
          type: ParameterType.Text,
          label: "Item Index",
        },
        {
          name: "total_records",
          type: ParameterType.Text,
          label: "Array Size",
        },
      ];
    case SystemModuleType.LoopWhile:
      return [
        {
          name: "index",
          type: ParameterType.Text,
          label: "Item Index",
        },
      ];
    case SystemModuleType.MapChartData:
      return [
        {
          name: "labels",
          type: ParameterType.Array,
          label: "Labels",
        },
        {
          name: "data",
          type: ParameterType.Collection,
          label: "Data",
          spec:
            operator.operator_input_settings?.keys.map((v: string) => ({
              name: v,
              type: ParameterType.Text,
              label: v,
            })) || [],
        },
      ];
    case SystemModuleType.ArrayAggregator:
      return [
        {
          name: "array",
          type: ParameterType.Array,
          label: "Array",
        },
      ];
    case SystemModuleType.ZipS3FilesFromDatasets: {
      return [
        {
          name: "Public URL",
          type: ParameterType.Text,
          label: "public_url",
        },
        {
          name: "Private URL",
          type: ParameterType.Text,
          label: "private_url",
        },
      ];
    }
    case SystemModuleType.ListSQLQuery: {
      return [
        {
          name: "data",
          label: "data",
          type: ParameterType.Array,
          spec: [
            {
              name: "slug",
              label: "ID",
              type: ParameterType.Text,
            },
            {
              name: "query_slug",
              label: "Slug",
              type: ParameterType.Text,
            },
            {
              name: "name",
              label: "Name",
              type: ParameterType.Text,
            },
            {
              name: "description",
              label: "Description",
              type: ParameterType.Text,
            },
            {
              name: "value",
              label: "Value",
              type: ParameterType.Text,
            },
          ],
        },
      ];
    }
    case SystemModuleType.GetSQLQuery: {
      return [
        {
          name: "data",
          label: "data",
          type: ParameterType.Collection,
          spec: [
            {
              name: "slug",
              label: "ID",
              type: ParameterType.Text,
            },
            {
              name: "query_slug",
              label: "Slug",
              type: ParameterType.Text,
            },
            {
              name: "name",
              label: "Name",
              type: ParameterType.Text,
            },
            {
              name: "description",
              label: "Description",
              type: ParameterType.Text,
            },
            {
              name: "value",
              label: "Value",
              type: ParameterType.Text,
            },
          ],
        },
      ];
    }
    case SystemModuleType.RunSQLQuery: {
      return [
        {
          name: "data",
          label: "data",
          type: ParameterType.Text,
        },
      ];
    }
    case SystemModuleType.ListDatasetDesigns: {
      return [
        {
          name: "data",
          label: "data",
          type: ParameterType.Array,
          spec: [
            {
              name: "slug",
              label: "ID",
              type: ParameterType.Text,
            },
            {
              name: "dataset_slug",
              label: "Slug",
              type: ParameterType.Text,
            },
            {
              name: "name",
              label: "Name",
              type: ParameterType.Text,
            },
            {
              name: "description",
              label: "Description",
              type: ParameterType.Text,
            },
            {
              name: "color",
              label: "Color",
              type: ParameterType.Text,
            },
            {
              name: "icon",
              label: "Icon",
              type: ParameterType.Text,
            },
          ],
        },
      ];
    }
    case SystemModuleType.GetDatasetDesignFields: {
      return [
        {
          name: "data",
          label: "Data",
          type: ParameterType.Array,
          spec: [
            {
              name: "id",
              label: "ID",
              type: ParameterType.Text,
            },
            {
              name: "slug",
              label: "Slug",
              type: ParameterType.Text,
            },
            {
              name: "title",
              label: "Title",
              type: ParameterType.Text,
            },
            {
              name: "type",
              label: "Type",
              type: ParameterType.Text,
            },
            {
              name: "tooltip",
              label: "Tooltip",
              type: ParameterType.Text,
            },
            {
              name: "default_value",
              label: "Default Value",
              type: ParameterType.Text,
            },
          ],
        },
      ];
    }
    case SystemModuleType.UpdateGui: {
      return [];
    }
    case SystemModuleType.CreateSQLQuery:
    case SystemModuleType.CreateDatasetDesign:
    case SystemModuleType.CreateGui:
    case SystemModuleType.CreateGuiTab:
    case SystemModuleType.CreateDashboardWidget: {
      return [
        {
          name: "id",
          label: "ID",
          type: ParameterType.Text,
        },
      ];
    }
    case SystemModuleType.CreateScratchpad: {
      return [
        {
          name: "data",
          label: "Data",
          type: ParameterType.Collection,
          spec: [
            {
              name: "slug",
              label: "Slug",
              type: ParameterType.Text,
            },
            {
              name: "title",
              label: "Title",
              type: ParameterType.Text,
            },
            {
              name: "description",
              label: "Description",
              type: ParameterType.Text,
            },
          ],
        },
      ];
    }
    case SystemModuleType.AddFileToScratchpad: {
      return [
        {
          name: "data",
          label: "Data",
          type: ParameterType.Collection,
          spec: [
            {
              name: "filePath",
              label: "File Path",
              type: ParameterType.Text,
            },
          ],
        },
      ];
    }
    case SystemModuleType.UpdateScratchpad: {
      return [
        {
          name: "data",
          label: "Data",
          type: ParameterType.Collection,
          spec: [
            {
              name: "html",
              label: "HTML",
              type: ParameterType.Text,
            },
            {
              name: "css",
              label: "CSS",
              type: ParameterType.Text,
            },
            {
              name: "js",
              label: "JS",
              type: ParameterType.Text,
            },
          ],
        },
      ];
    }
    case SystemModuleType.CreateJob:
      return [
        {
          name: "data",
          label: "Data",
          type: ParameterType.Collection,
          spec: [
            {
              name: "slug",
              label: "Slug",
              type: ParameterType.Text,
            },
            {
              name: "title",
              label: "Title",
              type: ParameterType.Text,
            },
            {
              name: "description",
              label: "Description",
              type: ParameterType.Text,
            },
            {
              name: "metadata",
              label: "Metadata",
              type: ParameterType.Collection,
            },
            {
              name: "date_created",
              label: "Date Created",
              type: ParameterType.Text,
            },
            {
              name: "date_completed",
              label: "Date Completed",
              type: ParameterType.Text,
            },
            {
              name: "status",
              label: "Status",
              type: ParameterType.Text,
            },
          ],
        },
      ];
    case SystemModuleType.UpdateJob:
      return [
        {
          name: "data",
          label: "Data",
          type: ParameterType.Collection,
          spec: [
            {
              name: "title",
              label: "Title",
              type: ParameterType.Text,
            },
            {
              name: "description",
              label: "Description",
              type: ParameterType.Text,
            },
            {
              name: "metadata",
              label: "Metadata",
              type: ParameterType.Collection,
            },
            {
              name: "status",
              label: "Status",
              type: ParameterType.Text,
            },
          ],
        },
      ];
    case SystemModuleType.CreateJobTask:
      return [
        {
          name: "slug",
          label: "Slug",
          type: ParameterType.Text,
        },
        {
          name: "task_status",
          label: "Task Slug",
          type: ParameterType.Text,
        },
        {
          name: "task_assigned_agent",
          label: "Task Assigned Agent",
          type: ParameterType.Text,
        },
        {
          name: "task_assigned_agent_session",
          label: "Task Assigned Agent Session",
          type: ParameterType.Text,
        },
        {
          name: "metadata",
          label: "Task Metadata",
          type: ParameterType.Collection,
        },
        {
          name: "task_scratchpads",
          label: "Task Scratchpads",
          type: ParameterType.Array,
        },
      ];
    case SystemModuleType.UpdateJobTask:
      return [
        {
          name: "task_status",
          label: "Task Slug",
          type: ParameterType.Text,
        },
        {
          name: "metadata",
          label: "Task Metadata",
          type: ParameterType.Collection,
        },
        {
          name: "task_scratchpads",
          label: "Task Scratchpads",
          type: ParameterType.Array,
        },
      ];
    default:
      return [];
  }
};

const OperatorModule: React.FC<{
  operator: FusionOperator;
  onTagClick?(param: Record<string, any>): void;
}> = (props) => {
  const { operator, onTagClick } = props;

  const allModules = useFusionFlowStore.useAllModules();
  const { data: authData } = useAuthenticate();
  const { data: webhooks } = useFusionWebhooks(
    operator.app === SYSTEM_NODE_APP ? SYSTEM_NODE_APP : operator.app_module,
    authData?.user?.slug
  );
  const fusion = useFusionFlowStore.useFusionDraft();
  const { data: documents } = useListItems({ modelName: "dataset-design" });

  const [interfaces, setInterfaces] = useState<MappableParameter[]>([]);

  useEffect(() => {
    if (
      operator.app === SYSTEM_NODE_APP &&
      operator.app_module !== WIDGET_START_NODE_MODULE
    ) {
      setInterfaces(
        getSystemModuleInterfaces(
          fusion as any,
          documents || [],
          fusion?.flow?.nodes?.find(
            (op) => op.data.operator_slug === operator.operator_slug
          )?.data,
          webhooks
        )
      );
    } else {
      const m = allModules?.find(
        (module) => module.slug === operator.app_module
      );
      setInterfaces((m?.interface || []) as MappableParameter[]);
    }
  }, [allModules, documents, fusion, fusion?.flow?.nodes, operator, webhooks]);

  const splits = operator.operator_slug.split("_");
  splits.pop();
  const slugPrefix = splits.join("_");

  return interfaces?.length > 0 ? (
    <>
      <Typography variant="body2">{operator.operator_title}</Typography>
      <OperatorPanel
        interfaceParams={interfaces}
        slugPrefix={slugPrefix}
        labelPrefix={operator.operator_title}
        onTagClick={onTagClick}
      />
    </>
  ) : null;
};

const isWidgetActionPopulateFusion = (type: string) => {
  return [
    "data-list-widget-create-action-form-submit",
    "data-list-widget-edit-action-form-submit",
    "data-list-widget-create-action-form-populate",
    "data-list-widget-edit-action-form-populate",
  ].includes(type);
};

type WidgetActionFusionFieldsProps = {
  fusion: Partial<Fusion>;
  onTagClick?(param: Record<string, any>): void;
};

const WidgetActionFusionFields: React.FC<WidgetActionFusionFieldsProps> = (
  props
) => {
  const { fusion, onTagClick } = props;

  const { data: widget } = useGetItem({
    modelName: ApiModels.GuiDashboardWidget,
    slug: fusion.widget_action_form_data?.widget_slug,
  });

  const actionForms = React.useMemo(() => {
    if (fusion.fusion_type?.startsWith("data-list-widget-create")) {
      return widget?.create_forms || [];
    }

    if (fusion.fusion_type?.startsWith("data-list-widget-edit")) {
      return widget?.edit_forms || [];
    }

    return [];
  }, [widget, fusion]);

  const fields = React.useMemo(() => {
    return (
      actionForms.find((f) => f.id === fusion.widget_action_form_data?.form_id)
        ?.form_fields?.fields || []
    );
  }, [actionForms, fusion]);

  const interfaces = React.useMemo(() => {
    return [
      ...(fusion.fusion_type?.startsWith("data-list-widget-edit")
        ? [
            {
              name: "id",
              label: "ID",
              type: ParameterType.Number,
            },
          ]
        : []),
      ...fields.map((f) => ({
        name: f.slug,
        label: f.title,
        type: ParameterType.Text,
      })),
    ];
  }, [fields]);

  return (
    <>
      <Typography variant="body2">Variable Popup</Typography>
      <OperatorPanel
        interfaceParams={interfaces}
        slugPrefix="chart_inputs"
        labelPrefix="Chart Inputs"
        onTagClick={onTagClick}
      />
    </>
  );
};

type ImportFusionFieldsProps = {
  fusion: Partial<Fusion>;
  onTagClick?(param: Record<string, any>): void;
};

const ImportFusionFields: React.FC<ImportFusionFieldsProps> = (props) => {
  const { fusion, onTagClick } = props;

  const [interfaces, setInterfaces] = useState<MappableParameter[]>([]);

  useEffect(() => {
    if (fusion.fusion_type !== FusionType.Import) {
      return;
    }
    const fusionMeta = fusion?.meta_data;
    if (fusionMeta?.import_type === "image") {
      setInterfaces([
        {
          label: "Image URL",
          name: "image_url",
          type: ParameterType.Text,
        },
        {
          label: "Image Name",
          name: "image_filename",
          type: ParameterType.Text,
        },
        {
          label: "Image Data",
          name: "image_file",
          type: ParameterType.Text,
        },
      ]);
    } else if (fusionMeta?.import_type === "word_doc") {
      setInterfaces([
        {
          label: "Doc URL",
          name: "doc_url",
          type: ParameterType.Text,
        },
        {
          label: "Doc Name",
          name: "doc_filename",
          type: ParameterType.Text,
        },
        {
          label: "Doc Data",
          name: "doc_file",
          type: ParameterType.Text,
        },
      ]);
    } else if (fusionMeta?.import_type === "csv") {
      setInterfaces([
        {
          name: "data",
          label: "Data",
          type: ParameterType.Collection,
          spec:
            (fusionMeta.record_keys as string[]).map((key) => ({
              label: key,
              name: key,
              type: ParameterType.Text,
            })) || [],
        },
      ]);
    } else if (fusionMeta?.import_type === "audio") {
      setInterfaces([
        {
          label: "Audio URL",
          name: "audio_url",
          type: ParameterType.Text,
        },
        {
          label: "Audio Name",
          name: "audio_filename",
          type: ParameterType.Text,
        },
        {
          label: "Audio Data",
          name: "audio_file",
          type: ParameterType.Text,
        },
      ]);
    } else if (fusionMeta?.import_type === "video") {
      setInterfaces([
        {
          label: "Video URL",
          name: "video_url",
          type: ParameterType.Text,
        },
        {
          label: "Video Name",
          name: "video_filename",
          type: ParameterType.Text,
        },
        {
          label: "Video Data",
          name: "video_file",
          type: ParameterType.Text,
        },
      ]);
    }
  }, [fusion]);

  return (
    <>
      <Typography variant="body2">Popup Variables</Typography>
      <OperatorPanel
        interfaceParams={interfaces}
        slugPrefix="popup_variables"
        labelPrefix="Popup Variables"
        onTagClick={onTagClick}
      />
    </>
  );
};

type SkillFusionFieldsProps = {
  fusion: Partial<Fusion>;
  onTagClick?(param: Record<string, any>): void;
};

const SkillFusionFields: React.FC<SkillFusionFieldsProps> = (props) => {
  const { fusion, onTagClick } = props;

  const skillUserVars = fusion?.skill_user_fields?.fields || [];

  const skillUserVarsInterfaces = skillUserVars.map((variable) => ({
    name: variable.slug!,
    type: variable.type!,
    label: variable.title!,
  }));

  const skillSessionVars = fusion?.skill_session_fields?.fields || [];

  const skillSessionVarsInterfaces = skillSessionVars.map((variable) => ({
    name: variable.slug!,
    type: variable.type!,
    label: variable.title!,
  }));

  return (
    <>
      <Typography variant="body2">Popup Variables</Typography>
      <OperatorPanel
        interfaceParams={[
          {
            name: "skill_id",
            type: ParameterType.Text,
            label: "Skill ID",
          },
          {
            name: "skill_session_id",
            type: ParameterType.Text,
            label: "Skill Session ID",
          },
        ]}
        slugPrefix="popup_variables"
        labelPrefix="Popup Variables"
        onTagClick={onTagClick}
      />
      <Typography variant="body2">Skill User Fields</Typography>
      <OperatorPanel
        interfaceParams={skillUserVarsInterfaces}
        slugPrefix="skill_user_variables"
        labelPrefix="Skill User Variables"
        onTagClick={onTagClick}
      />
      <Typography variant="body2">Skill Session Fields</Typography>
      <OperatorPanel
        interfaceParams={skillSessionVarsInterfaces}
        slugPrefix="skill_session_variables"
        labelPrefix="Skill Session Variables"
        onTagClick={onTagClick}
      />
    </>
  );
};

type DataListGuiFieldsProps = {
  guiSlug: string;
  guiTabId: string;
  datasetTabId: string;
  onTagClick?(param: Record<string, any>): void;
};

const DataListGuiFields: React.FC<DataListGuiFieldsProps> = (props) => {
  const { guiTabId, datasetTabId, onTagClick } = props;

  const { data: guiTab } = useGetItem({
    modelName: ApiModels.GuiTab,
    slug: guiTabId,
  });

  const datasetTab = React.useMemo(() => {
    if (guiTab?.tab_type !== "record_list") {
      return;
    }

    const datasetTab = guiTab.included_tabs?.find(
      (tab) => tab.id === datasetTabId
    );
    if (!datasetTab) {
      return;
    }

    return datasetTab;
  }, [guiTab, datasetTabId]);

  const { data: datasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: datasetTab?.dataset_to_include,
  });

  const interfaces = React.useMemo(() => {
    if (!datasetTab || !datasetDesign) {
      return [];
    }

    if (datasetTab.record_type === "chat_view") {
      return [
        {
          name: "message",
          label: "Message",
          type: ParameterType.Text,
        },
        {
          name: "sender_name",
          label: "Sender Name",
          type: ParameterType.Text,
        },
        {
          name: "sender_id",
          label: "Sender ID",
          type: ParameterType.Text,
        },
        {
          name: "parent_id",
          label: "Parent ID",
          type: ParameterType.Text,
        },
      ];
    }

    const fieldsToInclude = datasetDesign.fields?.fields?.filter((field) =>
      datasetTab.included_fields.includes(field.id)
    );

    return mapDocumentFieldsToInterface(fieldsToInclude);
  }, [datasetDesign, datasetTab]);

  return (
    <>
      <Typography variant="body2">Popup Variables</Typography>
      <OperatorPanel
        interfaceParams={interfaces}
        slugPrefix="popup_variables"
        labelPrefix="Popup Variables"
        onTagClick={onTagClick}
      />
    </>
  );
};

type FusionActionFieldsProps = {
  guiSlug: string;
  metaData?: any;
  onTagClick?(param: Record<string, any>): void;
};

const FusionActionFields: React.FC<FusionActionFieldsProps> = (props) => {
  const { metaData, onTagClick } = props;

  const { data: guiTab } = useGetItem({
    modelName: ApiModels.GuiTab,
    slug: metaData?.tab_id,
  });

  const { data: actionFields = [] } = useActionFields(metaData?.action_slug);

  let datasetDesignSlug =
    guiTab?.tab_type === GuiType.Reviewer ||
    guiTab?.tab_type === GuiType.RecordList
      ? guiTab?.dataset_design_slug
      : undefined;

  if (metaData.index_key === "included_tabs") {
    let parentTab = (guiTab as any)?.[metaData?.index_key];
    const selectedTab = get(
      parentTab,
      metaData.path?.slice(0, -1)
    ) as IncludeTabs;
    datasetDesignSlug = selectedTab?.dataset_to_include;
  }

  const { data: datasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: datasetDesignSlug,
  });

  const interfaces = React.useMemo(() => {
    if (!guiTab || !datasetDesign) {
      return [{ name: "data", label: "Data", type: ParameterType.Collection }];
    }

    if (metaData.index_key === "included_tabs") {
      let parentTab = (guiTab as any)?.[metaData?.index_key];
      const selectedTab = get(
        parentTab,
        metaData.path?.slice(0, -1)
      ) as IncludeTabs;
      const fieldsToInclude = datasetDesign.fields?.fields?.filter((field) =>
        selectedTab.included_fields?.includes(field.id)
      );
      return [
        { name: "id", label: "ID", type: ParameterType.Text },
        ...mapDocumentFieldsToInterface(fieldsToInclude),
      ];
    }

    const fieldsToInclude =
      guiTab?.tab_type === GuiType.Reviewer ||
      guiTab?.tab_type === GuiType.RecordList
        ? datasetDesign.fields?.fields?.filter((field) =>
            guiTab.form_fields?.includes(field.id)
          )
        : [];

    return [
      { name: "id", label: "ID", type: ParameterType.Text },
      ...mapDocumentFieldsToInterface(fieldsToInclude),
    ];
  }, [datasetDesign, guiTab, metaData.index_key, metaData.path]);

  const actionDataFields = useMemo(() => {
    return actionFields.map((field) => ({
      ...field,
      slug: field.field_slug,
      ...field.metadata,
    }));
  }, [actionFields]);

  return (
    <>
      {actionDataFields.length > 0 && (
        <>
          <Typography variant="body2">Form Data</Typography>
          <OperatorPanel
            interfaceParams={mapDocumentFieldsToInterface(actionDataFields)}
            slugPrefix="form_data"
            labelPrefix="Form Data"
            onTagClick={onTagClick}
          />
        </>
      )}
      <Typography variant="body2">Popup Variables</Typography>
      <OperatorPanel
        interfaceParams={interfaces}
        slugPrefix="popup_variables"
        labelPrefix="Popup Variables"
        onTagClick={onTagClick}
      />
    </>
  );
};

type SessionVariableFieldsProps = {
  prevOperators: FusionOperator[];
  onTagClick?(param: Record<string, any>): void;
};

const SessionVariableFields: React.FC<SessionVariableFieldsProps> = (props) => {
  const { prevOperators, onTagClick } = props;

  const interfaces = useMemo(
    () =>
      prevOperators.reduce<MappableParameter[]>((acc, operator) => {
        if (
          operator.app_module === SystemModuleType.SetVariable &&
          operator.operator_input_settings?.name
        ) {
          acc.push({
            label: operator.operator_input_settings?.name,
            name: operator.operator_input_settings?.name,
            type: ParameterType.Text,
          });
        }

        if (
          operator.app_module === SystemModuleType.SetMultipleVariables &&
          operator.operator_input_settings?.variables
        ) {
          const variables = operator.operator_input_settings?.variables as {
            name: string;
            value: string;
          }[];
          variables.forEach((variable) => {
            acc.push({
              label: variable.name,
              name: variable.name,
              type: ParameterType.Text,
            });
          });
        }

        return acc;
      }, []),
    [prevOperators]
  );

  return (
    <>
      <Typography variant="body2">Session Variables</Typography>
      <OperatorPanel
        interfaceParams={interfaces}
        slugPrefix="session_variables"
        labelPrefix="Session Variables"
        onTagClick={onTagClick}
      />
    </>
  );
};

type GuiParamsFieldsProps = {
  fusion?: Fusion;
  onTagClick?(param: Record<string, any>): void;
};

const GuiParamsFields: FC<GuiParamsFieldsProps> = (props) => {
  const { meta_data = {} } = props.fusion ?? {};
  const guiSlug = meta_data?.gui_slug as string | undefined;

  const { data: guiParams } = useGuiParams(guiSlug);

  const interfaces = useMemo(
    () =>
      guiParams
        ?.filter((param) => param.is_active)
        .map<MappableParameter>((param) => ({
          type: param.type,
          required: param.is_required,
          name: param.param_slug,
          label: param.title,
        })) ?? [],
    [guiParams]
  );

  return interfaces.length > 0 ? (
    <>
      <Typography variant="body2">Gui Params</Typography>
      <OperatorPanel
        interfaceParams={interfaces}
        slugPrefix="gui_params"
        labelPrefix="Gui Params"
        onTagClick={props.onTagClick}
      />
    </>
  ) : (
    <></>
  );
};

type FiltersFieldsProps = {
  fusion?: Fusion;
  onTagClick?(param: Record<string, any>): void;
};

const FiltersFields: FC<FiltersFieldsProps> = (props) => {
  const interfaces = [
    {
      type: ParameterType.Collection,
      name: "filters",
      label: "Filters",
    },
  ];

  return interfaces.length > 0 ? (
    <>
      <Typography variant="body2">Filters</Typography>
      <OperatorPanel
        interfaceParams={interfaces}
        slugPrefix=""
        labelPrefix=""
        onTagClick={props.onTagClick}
      />
    </>
  ) : (
    <></>
  );
};

type DataListWidgetPaginationProps = {
  fusion?: Fusion;
  onTagClick?(param: Record<string, any>): void;
};

const DataListWidgetPagination: FC<DataListWidgetPaginationProps> = (props) => {
  const interfaces = [
    {
      type: ParameterType.Text,
      name: "page_size",
      label: "Page Size",
    },
    {
      type: ParameterType.Text,
      name: "page",
      label: "Page Number",
    },
    {
      type: ParameterType.Text,
      name: "offset",
      label: "Offset",
    },
  ];

  return interfaces.length > 0 ? (
    <>
      <Typography variant="body2">Pagination</Typography>
      <OperatorPanel
        interfaceParams={interfaces}
        slugPrefix=""
        labelPrefix=""
        onTagClick={props.onTagClick}
      />
    </>
  ) : (
    <></>
  );
};

type UserParamsFieldProps = {
  onTagClick?(param: Record<string, any>): void;
};

const UserParamsField: FC<UserParamsFieldProps> = (props) => {
  const interfaces = [
    {
      type: ParameterType.Collection,
      name: "user",
      label: "User",
      spec: [
        {
          type: ParameterType.Text,
          name: "slug",
          label: "ID",
        },
        {
          type: ParameterType.Text,
          name: "first_name",
          label: "First Name",
        },
        {
          type: ParameterType.Text,
          name: "last_name",
          label: "Last Name",
        },
        {
          type: ParameterType.Text,
          name: "email",
          label: "Email",
        },
        {
          type: ParameterType.Text,
          name: "phone",
          label: "Phone",
        },
      ],
    },
  ];

  return (
    <>
      <Typography variant="body2">User Data</Typography>
      <OperatorPanel
        interfaceParams={interfaces}
        slugPrefix=""
        labelPrefix=""
        onTagClick={props.onTagClick}
      />
    </>
  );
};

type WidgetEditFieldsProps = {
  fusion?: Fusion;
  onTagClick?(param: Record<string, any>): void;
};

const WidgetEditFields: FC<WidgetEditFieldsProps> = (props) => {
  const interfaces = [
    {
      type: ParameterType.Text,
      name: "gui_slug",
      label: "Gui ID",
    },
    {
      type: ParameterType.Text,
      name: "tab_slug",
      label: "Tab ID",
    },
    {
      type: ParameterType.Text,
      name: "widget_slug",
      label: "Widget ID",
    },
    {
      type: ParameterType.Text,
      name: "widget_type",
      label: "Widget Type",
    },
    {
      type: ParameterType.Text,
      name: "old_value",
      label: "Old Value",
    },
    {
      type: ParameterType.Text,
      name: "new_value",
      label: "New Value",
    },
    {
      type: ParameterType.Text,
      name: "row_index",
      label: "Row Index",
    },
  ];

  return (
    <>
      <Typography variant="body2">Widget Data</Typography>
      <OperatorPanel
        interfaceParams={interfaces}
        slugPrefix=""
        labelPrefix=""
        onTagClick={props.onTagClick}
      />
    </>
  );
};

type AgentFusionFieldsProps = {
  fusion?: Fusion;
  onTagClick?(param: Record<string, any>): void;
};

const AgentFusionFields: FC<AgentFusionFieldsProps> = (props) => {
  const interfaces = [
    {
      type: ParameterType.Text,
      name: "agent_id",
      label: "Agent ID",
    },
    {
      type: ParameterType.Text,
      name: "thread_id",
      label: "Thread ID",
    },
    {
      type: ParameterType.Text,
      name: "message",
      label: "Message",
    },
    {
      type: ParameterType.Text,
      name: "open_ai_thread_id",
      label: "Open AIThread ID",
    },
  ];

  return (
    <>
      <Typography variant="body2">Agent Chat Data</Typography>
      <OperatorPanel
        interfaceParams={interfaces}
        slugPrefix="agent_chat_data"
        labelPrefix="Agent Chat Data"
        onTagClick={props.onTagClick}
      />
    </>
  );
};

const OperatorsTab: React.FC<{
  onTagClick?(param: Record<string, any>): void;
  prevOperators: FusionOperator[];
}> = (props) => {
  const { fusionSlug } = useParams<{ fusionSlug: string }>();
  const { onTagClick, prevOperators } = props;
  const { data: fusion } = useFusion(fusionSlug);

  const inputVars = fusion?.fusion_fields?.fields || [];

  const inputVarsInterfaces = inputVars.map((variable) => ({
    name: variable.slug,
    type: variable.type,
    label: variable.title,
  }));

  return (
    <div className="operators-container">
      <UserParamsField
        onTagClick={(param) => onTagClick?.({ ...param, prefix: "" })}
      />
      {fusion?.fusion_type === FusionType.WidgetEdit && (
        <WidgetEditFields
          fusion={fusion as Fusion}
          onTagClick={(param) => onTagClick?.({ ...param, prefix: "" })}
        />
      )}
      {(fusion?.fusion_type === FusionType.AgentPromptFusion ||
        fusion?.fusion_type === FusionType.AgentPreprocessFusion) && (
        <AgentFusionFields
          fusion={fusion as Fusion}
          onTagClick={(param) =>
            onTagClick?.({ ...param, prefix: "Agent Chat Data" })
          }
        />
      )}
      {fusion?.fusion_type === FusionType.GuiPageLoad && (
        <GuiParamsFields
          fusion={fusion as Fusion}
          onTagClick={(param) =>
            onTagClick?.({ ...param, prefix: "Gui Params" })
          }
        />
      )}
      {fusion?.fusion_type === FusionType.WidgetData && (
        <>
          DataListWidgetPagination
          <DataListWidgetPagination
            fusion={fusion as Fusion}
            onTagClick={(param) => onTagClick?.({ ...param, prefix: "" })}
          />
          <FiltersFields
            fusion={fusion as Fusion}
            onTagClick={(param) => onTagClick?.({ ...param, prefix: "" })}
          />
          <GuiParamsFields
            fusion={fusion as Fusion}
            onTagClick={(param) =>
              onTagClick?.({ ...param, prefix: "Gui Params" })
            }
          />
        </>
      )}
      <SessionVariableFields
        prevOperators={prevOperators}
        onTagClick={(param) =>
          onTagClick?.({ ...param, prefix: "Session Variables" })
        }
      />
      {isWidgetFusion(fusion?.fusion_type || "") ? (
        <>
          <Typography variant="body2">Widget Filters</Typography>
          <OperatorPanel
            interfaceParams={[
              {
                name: "selected_tab",
                label: "Selected Tab",
                type: ParameterType.Text,
              },
            ]}
            slugPrefix="chart_inputs"
            labelPrefix="Chat Inputs"
            onTagClick={(param) =>
              onTagClick?.({ ...param, prefix: "Widget Filter" })
            }
          />
        </>
      ) : null}
      {isWidgetActionPopulateFusion(fusion?.fusion_type || "") ? (
        <WidgetActionFusionFields
          fusion={fusion as Fusion}
          onTagClick={(param) =>
            onTagClick?.({ ...param, prefix: "Variable Popup" })
          }
        />
      ) : null}
      {fusion?.fusion_type === FusionType.Import ? (
        <ImportFusionFields
          fusion={fusion as Fusion}
          onTagClick={(param) =>
            onTagClick?.({ ...param, prefix: "Popup Variables" })
          }
        />
      ) : null}
      {fusion?.fusion_type === FusionType.Skills ? (
        <SkillFusionFields
          fusion={fusion as Fusion}
          onTagClick={(param) =>
            onTagClick?.({ ...param, prefix: "Popup Variables" })
          }
        />
      ) : null}
      {fusion?.fusion_type === FusionType.DataListGuiCreateFormSubmit ? (
        <DataListGuiFields
          guiSlug={(fusion.meta_data?.gui_slug as string) || ""}
          guiTabId={(fusion.meta_data?.main_tab_id as string) || ""}
          datasetTabId={(fusion.meta_data?.sub_tab_id as string) || ""}
          onTagClick={(param) =>
            onTagClick?.({ ...param, prefix: "Popup Variables" })
          }
        />
      ) : null}
      {fusion?.fusion_type === FusionType.FusionAction ? (
        <>
          <GuiParamsFields
            fusion={fusion as Fusion}
            onTagClick={(param) =>
              onTagClick?.({ ...param, prefix: "Gui Params" })
            }
          />
          <FusionActionFields
            guiSlug={(fusion.meta_data?.gui_slug as string) || ""}
            metaData={fusion.meta_data}
            onTagClick={(param) =>
              onTagClick?.({ ...param, prefix: "Popup Variables" })
            }
          />
        </>
      ) : null}
      {inputVars.length > 0 ? (
        <>
          <Typography variant="body2">Fusion Input Variables</Typography>
          <OperatorPanel
            interfaceParams={inputVarsInterfaces}
            slugPrefix="sessionInitVars"
            labelPrefix="Session Init Vars"
            onTagClick={(param) =>
              onTagClick?.({ ...param, prefix: "Fusion Input Variables" })
            }
          />
        </>
      ) : null}
      {prevOperators?.map((operator) => {
        return (
          <OperatorModule
            key={operator.operator_slug}
            operator={operator}
            onTagClick={(param) =>
              onTagClick?.({ ...param, prefix: operator.operator_title })
            }
          />
        );
      })}
    </div>
  );
};

const ParamMapper: React.FC<{
  onClose?(): void;
  onTagClick: (func: GFMLFunction) => void;
  onOperatorClick?(param: MappableParameter): void;
}> = (props) => {
  const { onClose, onTagClick, onOperatorClick } = props;

  const { data: functionGroups } = useGFMLFunctionGroups();
  const selectedNode = useFusionFlowStore.useSelectedNode();
  const selectedEdge = useFusionFlowStore.useSelectedEdge();
  const fusionDraft = useFusionFlowStore.useFusionDraft();
  const [prevOperators, setPrevOperators] = useState<FusionOperator[]>([]);
  const [value, setValue] = useState(1);

  useEffect(() => {
    let operator: Partial<FusionOperator> | undefined;
    if (selectedNode) {
      operator = selectedNode?.data;
    } else if (selectedEdge) {
      operator = fusionDraft?.flow?.nodes?.find(
        (n) => n.id === selectedEdge.target
      )?.data;
    }
    const incoming = getIncomingOperators(
      operator,
      fusionDraft?.fusion_operators
    );
    if (selectedNode?.data.app_module === "chart-node") {
      const leafNodes =
        fusionDraft?.fusion_operators?.filter(
          (op) =>
            !fusionDraft?.fusion_operators?.find(
              (o) => o.parent_operator_slug === op.operator_slug
            )
        ) || [];
      incoming.push(...leafNodes);
    }
    setPrevOperators(incoming);
  }, [selectedNode, selectedEdge, fusionDraft]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <ModalHeader
        direction="row"
        justifyContent="space-between"
        px={2.5}
        alignItems="center"
      >
        <StyledTab value={value} onChange={handleChange}>
          <Tab label="Operators" value={1} disableRipple />
          <Tab label="Functions" value={2} disableRipple />
        </StyledTab>
        <IconButton size="small" onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </ModalHeader>
      <Scrollbar autoHeight autoHeightMax={400}>
        <Box sx={{ minHeight: "300px" }}>
          {value === 2 && (
            <Stack p={2.5} gap={2.5}>
              {functionGroups?.map((group) => {
                return group.function_group_name ? (
                  <Stack gap={1}>
                    <Stack direction="row" gap={1.25} alignItems="center">
                      <SettingsOutlined />
                      <Typography variant="body2">
                        {group.function_group_name}
                      </Typography>{" "}
                    </Stack>
                    <FunctionPanel
                      groups={group.function_group_sub_groups}
                      onTagClick={onTagClick}
                    />
                  </Stack>
                ) : null;
              })}
            </Stack>
          )}
          {value === 1 && (
            <Stack p={2.5} gap={2.5}>
              <OperatorsTab
                prevOperators={prevOperators}
                onTagClick={onOperatorClick}
              />
            </Stack>
          )}
        </Box>
      </Scrollbar>
    </Box>
  );
};

export default ParamMapper;
