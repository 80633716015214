import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import isArray from "lodash/isArray";
import { ApiModelDataTypes, ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

type FolderRenameModelProps = {
  folder: Folder | undefined;
  isOpen: boolean;
  onClose: () => void;
};

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
});

const FolderRenameModel: FC<FolderRenameModelProps> = (props) => {
  const { folder, isOpen, onClose } = props;

  const queryClient = useQueryClient();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<Folder>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });

  const { mutate: updateFolder, isLoading } = useUpdateItem({
    modelName: ApiModels.Folder,
    queryKey: [ApiModels.Folder, ApiModels.DatasetDesign],
    mutationOptions: {
      onSuccess: (_, { slug, data }) => {
        const defaultQueryKey = [ApiModels.Folder, ApiModels.DatasetDesign];
        queryClient.setQueriesData(
          [defaultQueryKey],
          (oldData: ApiModelDataTypes[typeof ApiModels.Folder][] = []) => {
            if (isArray(oldData)) {
              return oldData.map((item) =>
                item.slug === slug ? { ...item, ...data } : item
              );
            }
            return oldData;
          }
        );
      },
    },
  });

  const closeHandler = () => {
    onClose();
  };
  const submitHandler = (data: Folder) => {
    if (folder?.slug)
      updateFolder(
        { slug: folder.slug, data },
        {
          onSuccess: () => {
            closeHandler();
          },
        }
      );
  };

  React.useEffect(() => {
    reset(folder);
  }, [folder, reset]);

  return (
    <Dialog open={isOpen} disableEscapeKeyDown scroll="body">
      <DialogTitle>Rename</DialogTitle>
      <Scrollbar className="form-scroller">
        <DialogContent>
          <Box component="form">
            <FormField
              label="Folder Name"
              error={dirtyFields.name ? errors.name : undefined}
            >
              <TextField
                {...register("name")}
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
              />
            </FormField>
          </Box>
        </DialogContent>
      </Scrollbar>
      <DialogActions>
        <Button onClick={closeHandler} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSubmit(submitHandler)}
          variant="contained"
          loading={isLoading}
          type="submit"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default FolderRenameModel;
