import { Box, Stack, styled } from "@mui/material";
import Scrollbar from "components/Scrollbar";
import { FC } from "react";
import InputAttachment from "views/app-view/agent-chat/MiddleSection/InputAttachment";

const AttachmentContainer = styled(Box)({
  marginLeft: "56px",
  marginRight: "56px",
});

export type AttachmentFile = {
  name: string;
  size: number;
  type: string;
  id: string;
  url?: string;
  status: "uploading" | "done" | "error";
  file?: File;
  oai_file_id?: string;
};

type InputAttachmentsProps = {
  files: AttachmentFile[];
  onRemove(id: string): void;
};

const InputAttachments: FC<InputAttachmentsProps> = (props) => {
  const { files, onRemove } = props;
  return (
    <AttachmentContainer
      height={files.length > 0 ? "56px" : "0px"}
      marginTop={files.length > 0 ? "8px" : "0px"}
    >
      <Scrollbar>
        <Stack direction="row" gap={1.5} flexWrap="nowrap">
          {files.map((file) => (
            <InputAttachment key={file.id} file={file} onRemove={onRemove} />
          ))}
        </Stack>
      </Scrollbar>
    </AttachmentContainer>
  );
};

export default InputAttachments;
