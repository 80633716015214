import { useMutation } from "@tanstack/react-query";
import Copilot from "models/Copilot";

const useCreateMessage = () => {
  return useMutation({
    mutationKey: ["copilot", "threads", "messages", "post"],
    mutationFn: async ({
      threadSlug,
      data,
    }: {
      threadSlug: string;
      data: { message: string; files?: any[] };
    }) => {
      return (await Copilot.createMessage(threadSlug, data)).data;
    },
  });
};

export default useCreateMessage;
