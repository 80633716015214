import { Stack } from "@mui/material";
import FormField from "components/FormField";
import { DocumentElementType } from "enums/Form";
import { FC } from "react";

type JobFormProps = {
  job?: Job;
};

const JobForm: FC<JobFormProps> = (props) => {
  const { job } = props;

  return (
    <Stack
      direction="column"
      gap={2}
      mt={2}
      sx={{ ".form-field-header": { mb: 1 } }}
    >
      <FormField
        type={DocumentElementType.TextField}
        label="Title"
        fieldExtras={{ disabled: true, variant: "outlined", field: {} }}
        value={job?.title}
      />
      <FormField
        type={DocumentElementType.TextArea}
        label="Description"
        fieldExtras={{ disabled: true, variant: "outlined", field: {} }}
        value={job?.description}
      />
      <FormField
        type={DocumentElementType.TextArea}
        label="Metadata"
        fieldExtras={{ disabled: true, variant: "outlined", field: {} }}
        value={JSON.stringify(job?.metadata ?? {})}
      />
      <FormField
        type={DocumentElementType.TextField}
        label="Date Created"
        fieldExtras={{ disabled: true, variant: "outlined", field: {} }}
        value={job?.date_created}
      />
      <FormField
        type={DocumentElementType.TextField}
        label="Date Completed"
        fieldExtras={{ disabled: true, variant: "outlined", field: {} }}
        value={job?.date_completed}
      />
      <FormField
        type={DocumentElementType.TextField}
        label="Status"
        fieldExtras={{ disabled: true, variant: "outlined", field: {} }}
        value={job?.status}
      />
    </Stack>
  );
};

export default JobForm;
