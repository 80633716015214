import { CustomCellRendererProps } from "ag-grid-react";
import Cell from "components/DatasetTable/Cell";
import Icon from "components/util-components/Icon";
import { FC, memo } from "react";

const IconCellRenderer: FC<CustomCellRendererProps> = memo((props) => (
  <Cell isLoading={!props.data}>
    <Icon iconName={props.value} />
  </Cell>
));

export default IconCellRenderer;
