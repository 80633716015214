import { Button } from "@mui/material";
import useAccountSlug from "hooks/useAccountSlug";
import React from "react";
import { Link } from "react-router-dom";

type FusionLinkButtonProps = {
  title: React.ReactNode;
  fusionSlug: string;
};

const FusionLinkButton: React.FC<FusionLinkButtonProps> = (props) => {
  const { title, fusionSlug } = props;
  const accountSlug = useAccountSlug();

  return (
    <Link
      to={`/${accountSlug}/fusion/flow-designer/${fusionSlug}`}
      target="_blank"
    >
      <Button variant="outlined" fullWidth>
        {title}
      </Button>
    </Link>
  );
};

export default FusionLinkButton;
