import { AddCircleOutline, MoreHoriz } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import { IDatasource } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionMenu from "components/ActionMenu";
import { DatasetTableWithFields } from "components/DatasetTable";
import { DocumentElementType } from "enums";
import useOpenClose from "hooks/useOpenClose";
import AgentUserModel from "models/AgentUser";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import useDeleteItem from "queries/useDeleteItem";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import AgentAccessEditDrawer from "views/app-view/agent/TabContent/AgentAccess/AgentAccessEditDrawer";
import AddAgentUserModal from "./AddAgentUserModal";

type AgentAccessProps = {
  agent: Agent;
};

const AgentAccess: FC<AgentAccessProps> = (props) => {
  const { agent } = props;

  const [isUserModalOpen, openUserModal, closeUserModal] = useOpenClose();
  const [isEditorOpen, openEditor, closeEditor] = useOpenClose();

  const cursor = useRef<string>();
  const gridRef = useRef<AgGridReact>(null);

  const [selectedAccess, setSelectedAccess] = useState<AgentUser>();

  const { mutateAsync: deleteAgentUser } = useDeleteItem({
    modelName: ApiModels.AgentUser,
  });

  const { mutateAsync: createAgentUser } = useCreateItem({
    modelName: ApiModels.AgentUser,
  });

  const getDataSource = useCallback(
    (): IDatasource => ({
      async getRows(params) {
        console.log(params.context);
        const response = await AgentUserModel.list({
          query: { agent_slug: agent.slug, cursor: cursor.current },
        });
        cursor.current = response.cursor as string;
        params.successCallback(
          (response.data ?? []).map((u: any) => ({
            ...u,
            name: `${u.first_name ?? ""} ${u.last_name ?? ""}`.trim(),
            enabled: u.association ? 1 : 0,
          })),
          !response.cursor ? params.startRow + response.data.length : undefined
        );
      },
    }),
    [agent.slug]
  );

  const datasource = useMemo<IDatasource>(() => {
    return getDataSource();
  }, [getDataSource]);

  const fields = useMemo<DataField[]>(
    () => [
      {
        id: v4(),
        slug: "name",
        title: "Name",
        type: DocumentElementType.TextArea,
      },
      {
        id: v4(),
        slug: "enabled",
        title: "Access",
        type: DocumentElementType.Boolean,
        editable: true,
      },
    ],
    []
  );

  const refreshGrid = () => {
    gridRef.current?.api.paginationGoToFirstPage();
    cursor.current = undefined;
    gridRef.current?.api.refreshInfiniteCache();
  };

  return (
    <Box sx={{ height: "400px" }}>
      <DatasetTableWithFields
        ref={gridRef}
        fields={fields}
        datasource={datasource}
        editable={false}
        handleUpdate={(event) => {
          if (event.colDef.field === "enabled" && event.valueChanged) {
            if (event.newValue) {
              createAgentUser({
                agent_slug: agent.slug,
                user_slug: event.data.slug,
                enabled: true,
              });
            } else {
              deleteAgentUser({
                slug: `${agent.slug}:${event.data.slug}`,
              });
            }
          }
        }}
        defaultTitleColumn={false}
        suppressMovableColumns
        paginationAutoPageSize={false}
        paginationPageSizeSelector={false}
        paginationPageSize={10}
        getRowStyle={(params) => {
          return params.data ? { opacity: 1 } : { opacity: 0 };
        }}
        blockLoadDebounceMillis={500}
        autoSizeStrategy={{
          type: "fitGridWidth",
        }}
        actionsColumn={{
          enabled: true,
          getHeader: () => (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <IconButton onClick={openUserModal}>
                <AddCircleOutline />
              </IconButton>
            </Stack>
          ),
          getRowAction(data) {
            return (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <ActionMenu
                  menuItems={[]}
                  containerSx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MoreHoriz />
                </ActionMenu>
              </Stack>
            );
          },
        }}
      />
      <AddAgentUserModal
        open={isUserModalOpen}
        onClose={closeUserModal}
        agent={agent}
        onCreated={() => {
          refreshGrid();
        }}
      />
      <AgentAccessEditDrawer
        open={isEditorOpen}
        onClose={() => {
          closeEditor();
          setSelectedAccess(undefined);
        }}
        agentUser={selectedAccess}
        onSubmitDone={() => {
          refreshGrid();
        }}
      />
    </Box>
  );
};

export default AgentAccess;
