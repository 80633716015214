import { ScheduleOutlined } from "@mui/icons-material";
import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { Box, IconButton, Stack, styled, Typography } from "@mui/material";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { FC } from "react";

const Container = styled(Box)(({ theme }) => ({
  padding: "16px",
}));

type TaskLogsProps = {
  task?: JobTask;
  job?: Job;
  onBack(): void;
};

const TaskLogs: FC<TaskLogsProps> = (props) => {
  const { task, job, onBack } = props;

  const { data: taskLogs = [] } = useListItems({
    modelName: ApiModels.JobTaskLog,
    requestOptions: { query: { task_slug: task?.slug, job_slug: job?.slug } },
    queryKey: [ApiModels.JobTaskLog, task?.slug, job?.slug],
  });

  return (
    <Container>
      <IconButton onClick={onBack} size="small" sx={{ p: 0 }}>
        <ArrowBackOutlined fontSize="small" />
      </IconButton>
      <Typography
        fontSize={16}
        fontWeight={400}
        lineHeight="28px"
        color="text.primary"
        my={1}
      >
        {task?.slug} Log
      </Typography>
      <Stack direction="column" gap={1} mt={2}>
        {taskLogs.map((log) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              p: 2,
              background: (theme) => theme.palette.background.CardBackground,
              borderRadius: "4px",
            }}
          >
            <Stack direction="row" gap={2}>
              <Stack direction="row" alignItems="center" gap={1}>
                <ScheduleOutlined fontSize="small" />
                <Typography fontWeight={400} fontSize={12}>
                  8h
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap={1}>
                <DeleteOutline fontSize="small" />
                <Typography fontWeight={300} fontSize={13}>
                  {log.message}
                </Typography>
              </Stack>
            </Stack>
            <IconButton size="small" sx={{ p: 0, borderRadius: "2px" }}>
              <MoreHoriz fontSize="small" />
            </IconButton>
          </Stack>
        ))}
      </Stack>
    </Container>
  );
};

export default TaskLogs;
