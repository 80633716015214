import ModeCommentOutlined from "@mui/icons-material/ModeCommentOutlined";
import { IconButton } from "@mui/material";
import { FC } from "react";
import useGFChatWindow from "store/stores/gf-chat-window";

const GFChatButton: FC = () => {
  const setIsOpen = useGFChatWindow.useSetIsOpen();

  return (
    <IconButton onClick={() => setIsOpen(true)}>
      <ModeCommentOutlined sx={{ color: "#fff" }} />
    </IconButton>
  );
};

export default GFChatButton;
