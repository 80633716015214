import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { LexicalCommand, LexicalEditor } from "lexical";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type State = {
  activeField: string | null;
};

type Actions = {
  setActiveField(name: string | null, editor?: LexicalEditor): void;
  dispatchEditorCommand<T = unknown>(
    name: string,
    command: LexicalCommand<T>,
    payload: T
  ): void;
};

const fieldEditorMap = new Map<string, LexicalEditor>();

const useLexicalTagFieldBase = create(
  devtools(
    immer<State & Actions>((set) => ({
      activeField: null,
      setActiveField(name, editor) {
        set((state) => {
          if (state.activeField === name) {
            // state.activeField = null;
            // if (name) {
            // 	fieldEditorMap.delete(name);
            // }
          } else {
            state.activeField = name;
            if (name && editor) {
              fieldEditorMap.set(name, editor);
            }
          }
        });
      },
      dispatchEditorCommand(name, command, payload) {
        const editor = fieldEditorMap.get(name);
        if (editor) {
          editor.dispatchCommand(command, payload);
        }
      },
    }))
  )
);

export const useLexicalTagField = createSelectorHooks(useLexicalTagFieldBase);
