import MoreVert from "@mui/icons-material/MoreVert";
import PlaceOutlined from "@mui/icons-material/PlaceOutlined";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import ChattyStack from "assets/icons/ChattyStack";
import Scrollbar from "components/Scrollbar";
import TabStyled from "components/TabStyled";
import useAccountSlug from "hooks/useAccountSlug";
import useAppNavigate from "hooks/useAppNavigate";
import InnerPageLayout from "layouts/inner-app-layout";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useSystemLayoutStore from "store";
import useSSAgent from "store/stores/ss-agent";
import AddAgentModal from "views/app-view/ss-agent/AddAgentModal";
import EditDrawer from "views/app-view/ss-agent/EditDrawer";
import Sidebar from "views/app-view/ss-agent/Sidebar";
import TabContent from "views/app-view/ss-agent/TabContent";

const TabContentContainer = styled(Box)({
  padding: "30px",
});

const CenterBox = styled(Box)(({ theme }) => {
  return {
    background: theme.palette.background.InnerPageLayout,
    borderRadius: "6px 6px 0 0",
    position: "relative",
    flexGrow: "1",
    flexBasis: "0",
    minWidth: "0",
    marginRight: "8px",

    ".rc-scrollbar, .layout-container": {
      height: "100%",
    },

    ".heading-wrap": {
      justifyContent: "space-between",
    },

    ".tabs-container": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",

      ".MuiTabs-root": {
        width: "100%",

        ".MuiTabs-scroller": {
          width: "100%",

          ".MuiTabs-flexContainer": {
            borderBottom: `1px solid ${theme.palette.gfGrey.Divider}`,
            width: "100%",
          },

          ".MuiTabs-indicator": {
            bottom: "2px",
            height: "2px",
          },
        },
      },
    },
  };
});

const AddModal = () => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  useSystemLayoutStore.useSetButtonProps()({
    onClick: () => setAddModalOpen(true),
  });

  return (
    <AddAgentModal
      open={addModalOpen}
      onClose={() => setAddModalOpen(false)}
      onSubmit={(data) => {}}
    />
  );
};

const SSAgent: React.FC = () => {
  const { agentSlug } = useParams<{ agentSlug: string }>();
  const appNavigate = useAppNavigate();
  const accountSlug = useAccountSlug();

  const [searchParams] = useSearchParams();

  const { data: agents = [] } = useListItems({ modelName: ApiModels.SSAgent });

  const setEditDrawer = useSSAgent.useSetEditDrawer();
  const setDisableFolders = useSystemLayoutStore.useSetDisableFolders();
  const setTopBarProps = useSystemLayoutStore.useSetTopBarProps();
  const setSelectedTopBarTab = useSystemLayoutStore.useSetSelectedTopBarTab();
  const setMenu = useSystemLayoutStore.useSetMenu();
  const menuItemProps = useSystemLayoutStore.useSetItemProps();
  const setButtonOptions = useSystemLayoutStore.useSetButtonOptions();
  const setShowActionButtons = useSystemLayoutStore.useSetShowActionButtons();
  const setEnableSideNavSearch =
    useSystemLayoutStore.useSetEnableSideNavSearch();
  const setShowSideNavTopSelector =
    useSystemLayoutStore.useSetShowSideNavTopSelector();

  const [selectedTab, setSelectedTab] = useState(
    searchParams.get("t") || "settings"
  );

  useEffect(() => {
    setDisableFolders(true);
    setTopBarProps({
      title: (
        <Typography fontWeight={600}>
          Chatty
          <Box component="span" fontWeight={200}>
            Stack
          </Box>
        </Typography>
      ),
      icon: <ChattyStack />,
      tabs: [
        {
          label: "Agents",
          value: "agents",
          icon: <PlaceOutlined />,
        },
      ],
    });
    setSelectedTopBarTab("agents");
    setShowSideNavTopSelector(false);
    setShowActionButtons(true);
    setButtonOptions({ addFolder: false, addItem: true });
    setEnableSideNavSearch(true);
  }, [
    setButtonOptions,
    setDisableFolders,
    setEnableSideNavSearch,
    setSelectedTopBarTab,
    setShowActionButtons,
    setShowSideNavTopSelector,
    setTopBarProps,
  ]);

  useEffect(() => {
    menuItemProps({
      onClick: (item) => {
        appNavigate(`/ss-agent/${item.key}`);
      },
      isActive: (item) => item.key === agentSlug,
    });
  }, [agentSlug, accountSlug, menuItemProps, appNavigate]);

  useEffect(() => {
    setMenu(
      agents.map((agent) => ({
        key: agent.slug,
        title: agent.agent_name,
        icon: agent.image?.url,
      }))
    );

    if (!agentSlug && agents.length > 0) {
      appNavigate(`/ss-agent/${agents[0].slug}`);
    }
  }, [agentSlug, agents, appNavigate, setMenu]);

  const selectedAgent = useMemo(
    () => agents.find((agent) => agent.slug === agentSlug),
    [agentSlug, agents]
  );

  return (
    <Stack direction="row" height="100%" sx={{ ml: 1 }}>
      <CenterBox>
        <InnerPageLayout
          title={selectedAgent?.agent_name}
          onChange={(_, value) => {
            setEditDrawer({ enabled: false });
            setSelectedTab(value);
          }}
          value={selectedTab}
          extra={
            <IconButton>
              <MoreVert />
            </IconButton>
          }
          tabList={[
            {
              label: <TabStyled title="Settings" />,
              value: "settings",
            },
            {
              label: "",
              icon: <Divider orientation="vertical" variant="middle" />,
              disabled: true,
            },
            {
              label: <TabStyled title="Skills" />,
              value: "skills",
            },
            {
              label: "",
              icon: <Divider orientation="vertical" variant="middle" />,
              disabled: true,
            },
            {
              label: <TabStyled title="Documents" />,
              value: "documents",
            },
          ]}
        >
          <Scrollbar>
            <TabContentContainer>
              {selectedAgent && (
                <TabContent tab={selectedTab} agent={selectedAgent} />
              )}
            </TabContentContainer>
          </Scrollbar>
        </InnerPageLayout>
      </CenterBox>
      <Sidebar agent={selectedAgent} />
      <EditDrawer />
      <AddModal />
    </Stack>
  );
};

export default SSAgent;
