import { Box, TextField } from "@mui/material";
import IOSSwitch from "components/IOSSwitch";
import LexicalTagField from "components/LexicalTagField";
import { forwardRef, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { BaseParamFieldProps } from "views/app-view/flow-designer/components/NodeEditorFields";

type MixedTagFieldProps = {
  onChange?(value: string): void;
} & BaseParamFieldProps;

export type MixedTagFieldRef = { setValue: (v: string) => void };

const MixedTagField = forwardRef<
  MixedTagFieldRef | undefined,
  MixedTagFieldProps
>((props, ref) => {
  const { control, parentNamePath, field: formField } = props;

  const name = useMemo(
    () =>
      parentNamePath ? `${parentNamePath}.${formField.name}` : formField.name,
    [parentNamePath, formField.name]
  );

  const [mapped, setMapped] = useState(true);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box sx={{ position: "relative" }}>
          <IOSSwitch
            sx={{ position: "absolute", top: -28, right: 0 }}
            onChange={(e) => {
              setMapped(e.target.checked);
            }}
            checked={mapped}
          />
          {mapped ? (
            <LexicalTagField
              name={name}
              defaultValue={field.value}
              onChange={field.onChange}
            />
          ) : (
            <TextField
              {...field}
              variant="filled"
              size="small"
              fullWidth
              rows={2}
              multiline={formField.multiline}
            />
          )}
        </Box>
      )}
    />
  );
});

export default MixedTagField;
