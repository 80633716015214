import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as EditPenIconSvg } from "../svg/penEditIcon.svg";

const EditPenIcon: React.FC<React.ComponentProps<typeof SvgIcon>> = (props) => {
  return (
    <SvgIcon
      inheritViewBox
      {...props}
      component={EditPenIconSvg}
      className="plus-icon"
    />
  );
};

export default EditPenIcon;
