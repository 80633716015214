import {
  AddCircleOutline,
  DeleteOutline,
  EditOutlined,
  MoreHoriz,
} from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import { IDatasource } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionMenu from "components/ActionMenu";
import { DatasetTableWithFields } from "components/DatasetTable";
import { DocumentElementType } from "enums";
import useOpenClose from "hooks/useOpenClose";
import AgentDatasetRightModel from "models/AgentDatasetRight";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import useDeleteItem from "queries/useDeleteItem";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import AgentDatasetRightEditDrawer from "views/app-view/agent/TabContent/AgentDataset/AgentDatasetRightEditDrawer";
import AddAgentDatasetRightModal from "./AddAgentDatasetRightModal";

type AgentDatasetRightProps = {
  agent: Agent;
};

const AgentDatasetRight: FC<AgentDatasetRightProps> = (props) => {
  const { agent } = props;

  const [isUserModalOpen, openUserModal, closeUserModal] = useOpenClose();
  const [isEditorOpen, openEditor, closeEditor] = useOpenClose();

  const cursor = useRef<string>();
  const gridRef = useRef<AgGridReact>(null);

  const [selectedDatasetRight, setSelectedDatasetRight] =
    useState<AgentDatasetRight>();

  const { mutateAsync: createAgentDatasetRight } = useCreateItem({
    modelName: ApiModels.AgentDatasetRight,
  });

  const { mutateAsync: updateAgentDatasetRight } = useUpdateItem({
    modelName: ApiModels.AgentDatasetRight,
  });

  const { mutateAsync: deleteAgentDatasetRight } = useDeleteItem({
    modelName: ApiModels.AgentDatasetRight,
  });

  const getDataSource = useCallback(
    (): IDatasource => ({
      async getRows(params) {
        console.log(params.context);
        const response = await AgentDatasetRightModel.list({
          query: { agent_slug: agent.slug, cursor: cursor.current },
        });
        cursor.current = response.cursor as string;
        params.successCallback(
          (response.data ?? []).map((d: any) => ({
            ...d,
            enable_read: d.agent_rights?.enable_read ? 1 : 0,
            enable_write: d.agent_rights?.enable_write ? 1 : 0,
            enable_delete: d.agent_rights?.enable_delete ? 1 : 0,
          })),
          !response.cursor ? params.startRow + response.data.length : undefined
        );
      },
    }),
    [agent.slug]
  );

  const datasource = useMemo<IDatasource>(() => {
    return getDataSource();
  }, [getDataSource]);

  const fields = useMemo<DataField[]>(
    () => [
      {
        id: v4(),
        slug: "name",
        title: "Name",
        type: DocumentElementType.TextField,
      },
      {
        id: v4(),
        slug: "description",
        title: "Description",
        type: DocumentElementType.TextArea,
      },
      {
        id: v4(),
        slug: "enable_read",
        title: "Enable Read",
        type: DocumentElementType.Boolean,
        editable: true,
      },
      {
        id: v4(),
        slug: "enable_write",
        title: "Enable Write",
        type: DocumentElementType.Boolean,
        editable: true,
      },
      {
        id: v4(),
        slug: "enable_delete",
        title: "Enable Delete",
        type: DocumentElementType.Boolean,
        editable: true,
      },
    ],
    []
  );

  const handleDelete = async (slug: string) => {
    await deleteAgentDatasetRight({ slug });
    refreshGrid();
  };

  const refreshGrid = () => {
    gridRef.current?.api.paginationGoToFirstPage();
    cursor.current = undefined;
    gridRef.current?.api.refreshInfiniteCache();
  };

  return (
    <Box sx={{ height: "400px" }}>
      <DatasetTableWithFields
        ref={gridRef}
        fields={fields}
        datasource={datasource}
        editable={false}
        handleUpdate={async (event) => {
          if (event.data.agent_rights) {
            await updateAgentDatasetRight({
              slug: `${agent.slug}:${event.data.slug}`,
              data: {
                enable_read: Boolean(event.data.enable_read),
                enable_write: Boolean(event.data.enable_write),
                enable_delete: Boolean(event.data.enable_delete),
              },
            });
          } else {
            await createAgentDatasetRight({
              agent_slug: agent.slug,
              dataset_design_slug: event.data.slug,
              enable_read: Boolean(event.data.enable_read),
              enable_write: Boolean(event.data.enable_write),
              enable_delete: Boolean(event.data.enable_delete),
            });
          }
        }}
        defaultTitleColumn={false}
        suppressMovableColumns
        paginationAutoPageSize={false}
        paginationPageSizeSelector={false}
        paginationPageSize={10}
        getRowStyle={(params) => {
          return params.data ? { opacity: 1 } : { opacity: 0 };
        }}
        blockLoadDebounceMillis={500}
        autoSizeStrategy={{
          type: "fitGridWidth",
        }}
        actionsColumn={{
          enabled: true,
          getHeader: () => (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <IconButton onClick={openUserModal}>
                <AddCircleOutline />
              </IconButton>
            </Stack>
          ),
          getRowAction(data) {
            return (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <ActionMenu
                  menuItems={[
                    {
                      label: "Edit",
                      value: "edit",
                      icon: <EditOutlined />,
                    },
                    {
                      label: "Delete",
                      value: "delete",
                      icon: <DeleteOutline />,
                    },
                  ]}
                  onItemClick={(value) => {
                    if (value === "delete") {
                      handleDelete(`${agent.slug}:${data.slug}`);
                    } else if (value === "edit") {
                      setSelectedDatasetRight(data as AgentDatasetRight);
                      openEditor();
                    }
                  }}
                  containerSx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MoreHoriz />
                </ActionMenu>
              </Stack>
            );
          },
        }}
      />
      <AddAgentDatasetRightModal
        open={isUserModalOpen}
        onClose={closeUserModal}
        agent={agent}
        onCreated={() => {
          refreshGrid();
        }}
      />
      <AgentDatasetRightEditDrawer
        open={isEditorOpen}
        onClose={() => {
          closeEditor();
          setSelectedDatasetRight(undefined);
        }}
        agentDatasetRight={selectedDatasetRight}
        onSubmitDone={() => {
          refreshGrid();
        }}
      />
    </Box>
  );
};

export default AgentDatasetRight;
