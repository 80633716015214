import Model from "models";
import queryString from "query-string";

class Copilot {
  private model = new Model("/copilot", "public-1");

  listThreads(query?: Record<string, string>) {
    return this.model.sendRequest<CopilotThread[]>(
      `/threads${query ? "?" + queryString.stringify(query) : ""}`,
      "GET"
    );
  }

  listThreadAttachments(threadSlug: string) {
    return this.model.sendRequest<CopilotThreadAttachment[]>(
      `/threads/${threadSlug}/attachments`,
      "GET"
    );
  }

  listMessages(threadSlug: string, query?: Record<string, string>) {
    return this.model.sendRequest<CopilotMessage[]>(
      `/threads/${threadSlug}/messages${
        query ? "?" + queryString.stringify(query) : ""
      }`,
      "GET"
    );
  }

  createThread(data: Partial<CopilotThread>) {
    return this.model.sendRequest<CopilotThread>("/threads", "POST", data);
  }

  createMessage(threadSlug: string, data: { message: string }) {
    return this.model.sendRequest<CopilotMessage>(
      `/threads/${threadSlug}/messages`,
      "POST",
      data
    );
  }
}

export default new Copilot();
