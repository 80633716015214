import Model from "models";

class GuiDocumentModel extends Model<GuiDocument> {
  constructor() {
    super("/gui-document", "gui-fusion");
  }

  createPage(documentSlug: string, data: Record<string, unknown>) {
    return this.sendRequest(`/${documentSlug}/page`, "POST", data);
  }
}

const model = new GuiDocumentModel();

export default model;
