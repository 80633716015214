import { MouseSensor } from "@dnd-kit/core";
import { isDraggable } from ".";

class GFMouseSensor extends MouseSensor {
  static activators: typeof MouseSensor.activators = [
    {
      eventName: "onMouseDown",
      handler({ nativeEvent }) {
        return isDraggable(nativeEvent.target as HTMLElement);
      },
    },
  ];
}

export default GFMouseSensor;
