import { CustomCellRendererProps } from "ag-grid-react";
import { Code } from "assets/icons";
import Cell from "components/DatasetTable/Cell";
import { FC, memo } from "react";

const CodeCellRenderer: FC<CustomCellRendererProps> = memo((props) => (
  <Cell
    sx={{ justifyContent: "center", color: "#a5a5a7" }}
    isLoading={!props.data}
  >
    <Code />
  </Cell>
));

export default CodeCellRenderer;
