import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Stack,
  SxProps,
} from "@mui/material";
import React, {
  forwardRef,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";

export type ActionMenuProps = {
  menuItems?: {
    label: React.ReactNode;
    value: string;
    type?: "divider";
    icon?: React.ReactNode;
    iconContainerProps?: ListItemIconProps;
    disabled?: boolean;
  }[];
  minWidth?: number;
  containerSx?: SxProps;
  dropdownArrow?: boolean;
  onItemClick?(value: string): void;
  disabled?: boolean;
} & Omit<MenuProps, "open">;

export type ActionMenuRef = {
  onClose(): void;
};

const ActionMenu = forwardRef<unknown, PropsWithChildren<ActionMenuProps>>(
  (props, ref) => {
    const {
      menuItems = [],
      children,
      onItemClick,
      containerSx,
      dropdownArrow = false,
      disabled,
      minWidth = 200,
      ...menuProps
    } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMoreClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        e?.stopPropagation();
        setAnchorEl(null);
      },
      []
    );

    useImperativeHandle(
      ref,
      () => ({
        onClose: handleMenuClose,
      }),
      [handleMenuClose]
    );

    return (
      <Box
        className="action-menu"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (menuItems.length > 0 && !disabled) {
            handleMoreClick(e);
          }
        }}
        sx={containerSx}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ height: "100%" }}
        >
          {children}
          {dropdownArrow && (
            <KeyboardArrowDownOutlined
              sx={{
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.1s ease",
              }}
            />
          )}
        </Stack>
        {menuItems.length > 0 ? (
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            slotProps={{
              paper: {
                sx: {
                  borderRadius: "6px",
                },
              },
            }}
            MenuListProps={{
              sx: {
                background: (theme) => theme.palette.background.GFPaper,
                border: (theme) => `1px solid ${theme.palette.gfGrey.Divider}`,
                borderRadius: "6px",
                minWidth: `${minWidth}px`,
                px: 1,

                ".MuiMenuItem-root": {
                  borderRadius: "6px",
                  transition: "background 0.2s ease",

                  "&:hover": {
                    background: (theme) => theme.palette.background.Hover,
                  },
                },
              },
            }}
            {...menuProps}
          >
            {menuItems.map((item) =>
              item.type === "divider" ? (
                <Divider />
              ) : (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  onClick={(e) => {
                    e.stopPropagation();
                    onItemClick?.(item.value);
                    setAnchorEl(null);
                  }}
                  disabled={item.disabled}
                >
                  <ListItemIcon
                    className="action-menu__icon"
                    {...item.iconContainerProps}
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    className="action-menu__label"
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                  >
                    {item.label}
                  </ListItemText>
                </MenuItem>
              )
            )}
          </Menu>
        ) : null}
      </Box>
    );
  }
);

export default ActionMenu;
