import { ColDef } from "ag-grid-community";
import IconCellEditor from "./IconCellEditor";
import IconCellRenderer from "./IconCellRenderer";

const getIconColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => {
  return {
    ...defaults,
    cellRenderer: IconCellRenderer,
    cellEditor: IconCellEditor,
  };
};

export default getIconColDef;
