import AddOutlined from "@mui/icons-material/AddOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import TextFields from "@mui/icons-material/TextFields";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ActionMenu from "components/ActionMenu";
import IOSSwitch from "components/IOSSwitch";
import Icon from "components/util-components/Icon";
import useOpenClose from "hooks/useOpenClose";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import useDeleteItem from "queries/useDeleteItem";
import useListInfiniteItems from "queries/useListInfiniteItems";
import { FC } from "react";
import useSSAgent from "store/stores/ss-agent";
import AddAgentSkillModal from "views/app-view/ss-agent/TabContent/AgentSkills/AddAgentSkillModal";

type AgentSkillsProps = {
  agent: Agent;
};

const AgentSkills: FC<AgentSkillsProps> = (props) => {
  const { agent } = props;

  const theme = useTheme();

  const setEditDrawer = useSSAgent.useSetEditDrawer();

  const [isSkillModalOpen, openSkillModal, closeSkillModal] = useOpenClose();

  const {
    data: agentSkills,
    hasNextPage,
    fetchNextPage,
    isFetching,
    refetch,
  } = useListInfiniteItems({
    modelName: ApiModels.SSAgentSkill,
    queryKey: [ApiModels.SSAgentSkill, agent.slug],
    requestOptions: {
      query: {
        agent_slug: agent.slug,
      },
    },
  });

  const { mutateAsync: createAgentSkillAssc } = useCreateItem({
    modelName: ApiModels.SSAgentSkillAssc,
  });

  const { mutateAsync: deleteAgentSkillAssc } = useDeleteItem({
    modelName: ApiModels.SSAgentSkillAssc,
  });

  return (
    <Stack
      direction="column"
      mb={2}
      gap={4}
      alignItems="flex-start"
      width="100%"
    >
      <Button
        variant="contained"
        startIcon={<AddOutlined />}
        onClick={openSkillModal}
      >
        New Skill
      </Button>
      <Stack direction="column" gap={1} width="100%">
        {agentSkills?.pages.map((page) =>
          page.data.map((skill) => (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              border="1px solid"
              borderColor={theme.palette.background.GF20}
              borderRadius="4px"
              height="50px"
              p={1}
            >
              <Stack direction="row" alignItems="center" gap={2}>
                {skill.meta_data?.icon ? (
                  <Icon iconName={skill.meta_data?.icon as string} />
                ) : (
                  <TextFields />
                )}
                <Stack direction="column" alignItems="flex-start">
                  <Typography fontSize={14} fontWeight={400}>
                    {skill.title}
                  </Typography>
                  <Typography
                    fontSize={12}
                    fontWeight={300}
                    color={theme.palette.background.GF60}
                    maxWidth="200px"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {skill.description}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" height="100%" alignItems="center" gap={2}>
                <IOSSwitch
                  checked={!!skill.association}
                  onChange={async (_, checked) => {
                    if (checked) {
                      await createAgentSkillAssc({
                        agent_slug: agent.slug,
                        skill_slug: skill.slug,
                      });
                    } else {
                      await deleteAgentSkillAssc({
                        slug: `${agent.slug}:${skill.slug}`,
                      });
                    }

                    await refetch();
                  }}
                />
                <Divider orientation="vertical" flexItem />
                <ActionMenu
                  menuItems={[
                    {
                      label: "Edit",
                      icon: <EditOutlined />,
                      value: "edit",
                    },
                  ]}
                  onItemClick={(value) => {
                    if (value === "edit") {
                      setEditDrawer({
                        enabled: true,
                        type: "skill",
                        skill,
                      });
                    }
                  }}
                >
                  <IconButton sx={{ p: 0 }}>
                    <MoreHoriz />
                  </IconButton>
                </ActionMenu>
              </Stack>
            </Stack>
          ))
        )}
        <LoadingButton
          variant="outlined"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage}
          fullWidth
          loading={isFetching}
        >
          Load More
        </LoadingButton>
      </Stack>
      <AddAgentSkillModal
        open={isSkillModalOpen}
        onClose={closeSkillModal}
        agent={agent}
        onCreated={() => {
          refetch();
        }}
      />
    </Stack>
  );
};

export default AgentSkills;
