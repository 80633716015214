import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { cloneDeep } from "lodash";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

let onEditorOpenCallback: (state: State) => void = () => {};

type EditorPath = { id: string; name: string };

type State = {
  editorOpen: boolean;
  editorPath: EditorPath[];
  additionalTabPath: string[];
};

type Actions = {
  setEditorOpen(open: boolean): void;
  setEditorPath(path: EditorPath[]): void;
  pushEditorPath(path: EditorPath): void;
  popEditorPath(): void;
  registerOnEditorOpenCallback(func: (state: State) => void): void;
  setAdditionalTabPath(path: string[]): void;
};

export const useGuiTabEditorBase = create(
  devtools(
    immer<State & Actions>((set) => ({
      editorOpen: false,
      editorPath: [],
      additionalTabPath: [],
      onEditorOpenCallback() {},
      setEditorOpen(open) {
        set((state) => {
          state.editorOpen = open;
          const currentState: State = cloneDeep({
            editorOpen: state.editorOpen,
            editorPath: state.editorPath,
            additionalTabPath: state.additionalTabPath,
          });
          onEditorOpenCallback(currentState);
        });
      },
      registerOnEditorOpenCallback(func) {
        onEditorOpenCallback = func;
      },
      setEditorPath(path) {
        set((state) => {
          state.editorPath = path;
        });
      },
      pushEditorPath(path) {
        set((state) => {
          state.editorPath.push(path);
        });
      },
      popEditorPath() {
        set((state) => {
          state.editorPath.pop();
        });
      },
      setAdditionalTabPath(path) {
        set((state) => {
          state.additionalTabPath = path;
        });
      },
    }))
  )
);

const useGuiTabEditor = createSelectorHooks(useGuiTabEditorBase);

export default useGuiTabEditor;
