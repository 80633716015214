import { QueryFunctionContext, useInfiniteQuery } from "@tanstack/react-query";
import FusionModel from "models/Fusion";

const getSessionExecutionFlow = async (context: QueryFunctionContext) => {
  const { queryKey, pageParam } = context;

  const sessionSlug = queryKey[1] as string;

  return FusionModel.getSessionExecutionFlow(sessionSlug, pageParam);
};

export const useExecutionFlow = (sessionSlug?: string) => {
  return useInfiniteQuery({
    queryKey: ["execution-flow", sessionSlug],
    queryFn: getSessionExecutionFlow,
    getNextPageParam: (lastPage) => lastPage.cursor,
    enabled: !!sessionSlug,
  });
};
