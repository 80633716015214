import { Box, styled } from "@mui/material";
import Scrollbar from "components/Scrollbar";
import { FC } from "react";
import useAgentChatStore from "store/stores/agent-chat";
import ItemsListing from "./ItemsListing";
import TopBar from "./TopBar";

const Container = styled(Box)<{ collapsed: boolean }>(
  ({ theme, collapsed }) => ({
    height: "100%",
    background: theme.palette.background.LeftNavBody,
    borderTopRightRadius: "6px",
    maxWidth: "260px",
    width: collapsed ? "0px" : "100%",
    overflow: "hidden",
    transition: "width 0.2s ease",
  })
);

const ScrollbarContainer = styled(Box)({
  height: "calc(100% - 72px)",
});

type LeftBarProps = {};

const LeftBar: FC<LeftBarProps> = (props) => {
  const leftBarCollapsed = useAgentChatStore.useLeftBarCollapsed();

  return (
    <Container collapsed={leftBarCollapsed}>
      <TopBar />
      <ScrollbarContainer>
        <Scrollbar>
          <ItemsListing />
        </Scrollbar>
      </ScrollbarContainer>
    </Container>
  );
};

export default LeftBar;
