import AddOutlined from "@mui/icons-material/AddOutlined";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import InsertLinkOutlined from "@mui/icons-material/InsertLinkOutlined";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import ShareOutlined from "@mui/icons-material/ShareOutlined";
import { IconButton, Stack } from "@mui/material";
import { IDatasource } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionMenu from "components/ActionMenu";
import { DatasetTableWithFields } from "components/DatasetTable";
import { DatasetTableContainer } from "components/DatasetTable/DatasetTableContainer";
import { DocumentElementType } from "enums/Form";
import useCurrentUser from "hooks/useCurrentUser";
import useOpenClose from "hooks/useOpenClose";
import FusionModel from "models/Fusion";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useSystemLayoutStore from "store";
import CreateConnectionDrawer from "views/app-view/connections-management/CreateConnectionDrawer";
import TransferOwnerShipDrawer from "views/app-view/connections-management/TransferOwnerShipDrawer";

const fields: DataField[] = [
  {
    id: "connection_name",
    slug: "connection_name",
    title: "Connection Name",
    type: DocumentElementType.TextField,
  },
  {
    id: "connection_type",
    slug: "connection_type",
    title: "Connection Type",
    type: "3p-app",
  },
  {
    id: "created_at",
    slug: "created_at",
    title: "Created At",
    type: DocumentElementType.TextField,
  },
  {
    id: "owner_slug",
    slug: "owner_slug",
    title: "Owner",
    type: DocumentElementType.User,
    render_user_name: true,
  },
  {
    id: "permissions",
    slug: "permissions",
    title: "Permissions",
    type: DocumentElementType.TextField,
  },
];

const ConnectionsManagement: React.FC = () => {
  const [isOpen, open, close] = useOpenClose(false);
  const [
    isTransferOwnerShipOpen,
    openTransferOwnerShip,
    closeTransferOwnerShip,
  ] = useOpenClose(false);

  const [selectedConnection, setSelectedConnection] = useState<string>();

  const user = useCurrentUser();

  const setTopBarProps = useSystemLayoutStore.useSetTopBarProps();

  const cursor = useRef<string>();
  const gridRef = useRef<AgGridReact>(null);

  useEffect(() => {
    setTopBarProps({
      title: "Connection Management",
      icon: <InsertLinkOutlined />,
    });
  }, []);

  const getDataSource = useCallback(
    (): IDatasource => ({
      async getRows(params) {
        const response = await FusionModel.listUserConnections(user.slug, {
          cursor: cursor.current,
          limit: "10",
        });
        cursor.current = response.cursor as string;
        params.successCallback(
          (response.data ?? []).map((u) => ({
            ...u,
            ...u.connection_data,
            connection_type: u.connection_data?.app_slug,
            owner_slug: u.owner_slug,
          })),
          !response.cursor ? params.startRow + response.data.length : undefined
        );
      },
    }),
    [user.slug]
  );

  const datasource = useMemo<IDatasource>(() => {
    return getDataSource();
  }, [getDataSource]);

  const handleDelete = useCallback(
    async (connectionSlug: string) => {
      await FusionModel.deleteFusionConnection(connectionSlug);
      gridRef.current?.api.refreshInfiniteCache();
    },
    [gridRef]
  );

  const handleShare = useCallback(
    async (connectionSlug: string) => {
      await FusionModel.shareConnection(connectionSlug, "org");
      gridRef.current?.api.refreshInfiniteCache();
    },
    [gridRef]
  );

  return (
    <>
      <DatasetTableContainer
        height="100%"
        sx={{
          ".ag-pinned-right-cols-container": {
            ".ag-cell": {
              padding: 0,
            },
          },
        }}
      >
        <DatasetTableWithFields
          ref={gridRef}
          fields={fields}
          datasource={datasource}
          editable={false}
          defaultTitleColumn={false}
          disableRowDrag
          disableRowSelection
          disableColumnFilters
          suppressMovableColumns
          paginationAutoPageSize={false}
          paginationPageSizeSelector={false}
          paginationPageSize={10}
          getRowStyle={(params) => {
            return params.data ? { opacity: 1 } : { opacity: 0 };
          }}
          blockLoadDebounceMillis={500}
          autoSizeStrategy={{
            type: "fitGridWidth",
          }}
          actionsColumn={{
            enabled: true,
            getHeader: () => (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <IconButton size="small" onClick={open}>
                  <AddOutlined fontSize="small" />
                </IconButton>
              </Stack>
            ),
            getRowAction(data) {
              return (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: "100%", width: "100%" }}
                >
                  <ActionMenu
                    menuItems={[
                      {
                        label: "Share with org",
                        value: "share",
                        icon: <ShareOutlined />,
                        disabled: data?.owner_slug !== user.slug,
                      },
                      {
                        label: "Transfer Ownership",
                        value: "transfer",
                        icon: <ShareOutlined />,
                        disabled: data?.owner_slug !== user.slug,
                      },
                      {
                        label: "Delete",
                        value: "delete",
                        icon: <DeleteOutlined />,
                        // disabled: data?.owner_slug !== user.slug,
                      },
                    ]}
                    onItemClick={(value) => {
                      if (value === "transfer") {
                        setSelectedConnection(data.connection_slug as string);
                        openTransferOwnerShip();
                      } else if (value === "delete") {
                        handleDelete(data.connection_slug as string);
                      } else if (value === "share") {
                        handleShare(data.connection_slug as string);
                      }
                    }}
                  >
                    <IconButton size="small">
                      <MoreHoriz fontSize="small" />
                    </IconButton>
                  </ActionMenu>
                </Stack>
              );
            },
          }}
        />
      </DatasetTableContainer>
      <CreateConnectionDrawer
        open={isOpen}
        onClose={close}
        onConnectionCreated={() => {
          gridRef.current?.api.refreshInfiniteCache();
        }}
      />
      <TransferOwnerShipDrawer
        connectionSlug={selectedConnection}
        open={isTransferOwnerShipOpen}
        onClose={() => {
          closeTransferOwnerShip();
          setSelectedConnection(undefined);
        }}
        onDone={() => {
          gridRef.current?.api.refreshInfiniteCache();
        }}
      />
    </>
  );
};

export default ConnectionsManagement;
