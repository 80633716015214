import CheckCircle from "@mui/icons-material/CheckCircle";
import { Stack, Typography, styled } from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { FC, ReactNode } from "react";
import useAgentChatStore from "store/stores/agent-chat";

dayjs.extend(duration);
dayjs.extend(relativeTime);

function calculateTimeDifference(pastTimestamp?: string) {
  const now = dayjs();
  const past = dayjs(pastTimestamp);
  const diffInMilliseconds = now.diff(past);

  const durationObj = dayjs.duration(diffInMilliseconds);

  const hours = String(durationObj.hours()).padStart(2, "0");
  const minutes = String(durationObj.minutes()).padStart(2, "0");
  const seconds = String(durationObj.seconds()).padStart(2, "0");

  return `${hours} : ${minutes} : ${seconds}`;
}

const Container = styled(Stack)({
  padding: "16px",
});

type ItemProps = {
  title: ReactNode;
  value: ReactNode;
  icon?: ReactNode;
};

export const Item: FC<ItemProps> = (props) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography
        fontSize={13}
        lineHeight="36px"
        fontWeight={400}
        color="text.secondary"
      >
        {props.title}
      </Typography>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          fontSize={13}
          lineHeight="36px"
          fontWeight={500}
          color="text.primary"
        >
          {props.value}
        </Typography>
        {props.icon}
      </Stack>
    </Stack>
  );
};

type SessionDetailsProps = {};

const SessionDetails: FC<SessionDetailsProps> = (props) => {
  const thread = useAgentChatStore.useSelectedThread();
  const startedAt = dayjs(thread?.created_at).format("LLL");

  const duration = calculateTimeDifference(thread?.created_at);

  return (
    <Container>
      <Item title="Started" value={`${startedAt} local time`} />
      <Item title="Duration" value={duration} />
      <Item
        title="Status"
        value="Complete"
        icon={<CheckCircle sx={{ color: "#5a9230" }} />}
      />
    </Container>
  );
};

export default SessionDetails;
