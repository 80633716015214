import { CustomCellEditorProps } from "ag-grid-react";
import ThreePAppPicker from "components/ThreePAppPicker";
import { FC, memo } from "react";

const ThreePAppCellEditor: FC<CustomCellEditorProps> = memo((props) => (
  <ThreePAppPicker
    value={props.value}
    onChange={(v) => props.onValueChange?.(v)}
  />
));

export default ThreePAppCellEditor;
