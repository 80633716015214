import {
  DeleteOutline,
  EditOutlined,
  LocalOfferOutlined,
} from "@mui/icons-material";
import { Box, Stack, styled, useTheme } from "@mui/material";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { SortableList } from "components/SortableList";
import Spin from "components/Spin";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import React, { useEffect, useState } from "react";
import ProfileCard from "stories/CompoundComponent/ProfileCard/ProfileCard";
import { confirmDelete } from "utils/confirmDelete";

export type FusionActionsProps = {
  skill: AgentSkill;
  onAddClick(): void;
  onEditClick(action: unknown): void;
  onSubmitDone?(): void;
};

const BoxIcon = styled(Box)(({ theme }) => {
  return {
    width: "20px",
    color: theme.palette.background.GF70,

    svg: {
      maxWidth: "100%",
      height: "20px",
      width: "auto",
      display: "block",
      margin: "auto",

      path: {
        fill: "currentColor",
      },
    },
  };
});

const ParamsList: React.FC<FusionActionsProps> = (props) => {
  const { onAddClick, onEditClick, onSubmitDone, skill } = props;

  const [parameters, setParameters] = useState<AgentSkillParameter[]>([]);

  const theme = useTheme();

  const { mutateAsync: updateAgentSkill } = useUpdateItem({
    modelName: ApiModels.AgentSkill,
  });

  useEffect(() => {
    setParameters(skill.parameters ?? []);
  }, [skill]);

  const handleDelete = (id: string) => {
    confirmDelete().then(async (result) => {
      if (result.isConfirmed) {
        const newParams = parameters.filter((p) => p.id !== id);
        await updateAgentSkill({
          slug: skill.slug,
          data: {
            parameters: newParams,
          },
        });
        setParameters(newParams);
        onSubmitDone?.();
      }
    });
  };
  return (
    <SidebarSectionWrap
      title="Custom Parameter"
      sx={{
        ".gf-header-list-item": {
          py: 1,
          px: 2,
        },
      }}
      onRightIconClick={onAddClick}
    >
      <Spin spinning={false}>
        <SortableList
          items={parameters ?? []}
          // onChange={(updatedItems) => {
          //   onSort(updatedItems);
          // }}
          renderItem={(item) => {
            return (
              <SortableList.Item id={item.id} handle>
                <ProfileCard
                  AvatarImage={
                    <BoxIcon>
                      <LocalOfferOutlined />
                    </BoxIcon>
                  }
                  options={{
                    draggable: false,
                    switcher: false,
                  }}
                  rightIcon={
                    <Stack direction="row" spacing={1}>
                      <Box className="edit-icon">
                        <DeleteOutline
                          sx={{ color: "grey.500" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(item.id);
                          }}
                        />
                      </Box>
                      <Box className="edit-icon">
                        <EditOutlined sx={{ color: "grey.500" }} />
                      </Box>
                    </Stack>
                  }
                  title={item.name}
                  subTitle={item.description}
                  sx={{
                    "&:hover": {
                      background: theme.palette.background.GFRightNavForeground,
                    },
                  }}
                  onClick={() => {
                    onEditClick(item);
                  }}
                />
              </SortableList.Item>
            );
          }}
        />
      </Spin>
    </SidebarSectionWrap>
  );
};

export default ParamsList;
