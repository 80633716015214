import GridViewOutlined from "@mui/icons-material/GridViewOutlined";
import { styled } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ActionEditor from "components/FusionAction/ActionEditor";
import { FusionActionForm } from "components/FusionAction/FusionActionEditor";
import GfDrawer from "components/GfDrawer";
import { cloneDeep, get, set } from "lodash";
import GuiModel from "models/Gui";
import { ApiModels } from "queries/apiModelMapping";
import { getGuiTabWidgetsQueryKey } from "queries/gui/useGuiTabWidgets";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useCallback } from "react";
import useFusionAction from "store/stores/fusion-action";
import { normalizeObjectForAPI } from "utils";

const DrawerBox = styled(GfDrawer)(({ theme }) => ({
  ".MuiDrawer-paper ": {
    ".MuiPaper-root ": {
      background: `${theme.palette.background.GFTopNav} !important`,
      boxShadow: "none",

      ".MuiCard-root": {
        background: `${theme.palette.background.GFRightNavForeground} !important`,
        transition: "all 0.4s ease",

        "&:hover": {
          background: `${theme.palette.background.GF20} !important`,
        },
      },
    },

    ".drawer-head": {
      background: theme.palette.background.GFTopNav,
      padding: "15px 20px",
      borderBottom: `1px solid ${theme.palette.other?.divider}`,

      ".drawer-icon-holder": {
        width: "auto",
        height: "auto",
        background: "none",
      },

      ".drawer-title-holder": {
        padding: "0 0 0 11px",
      },
    },

    ".draggable-handle": {
      padding: "0",
    },
  },
}));

type ActionEditorDrawerProps = {
  guiSlug?: string;
  guiTab?: GuiTab;
  guiAction?: boolean;
};

const ActionEditorDrawer: FC<ActionEditorDrawerProps> = (props) => {
  const { guiSlug, guiTab, guiAction } = props;

  const queryClient = useQueryClient();

  const open = useFusionAction.useEditorOpen();
  const setOpen = useFusionAction.useSetActionEditorOpen();
  const action = useFusionAction.useEditorAction();
  const widgetActionType = useFusionAction.useWidgetActionType();
  const widget = useFusionAction.useWidget();
  const setWidget = useFusionAction.useSetWidget();
  const setAction = useFusionAction.useSetEditorAction();
  const editActionPathPrefix = useFusionAction.useEditActionPathPrefix();
  const setEditActionPathPrefix = useFusionAction.useSetEditActionPathPrefix();

  const actionsPath = editActionPathPrefix
    ? `${editActionPathPrefix}.associated_actions`
    : "associated_actions";

  const { mutateAsync: updateGuiTab, isLoading: isUpdatingGuiTab } =
    useUpdateItem({
      modelName: ApiModels.GuiTab,
    });

  const { mutateAsync: updateWidget, isLoading: isUpdatingWidget } =
    useUpdateItem({
      modelName: ApiModels.GuiDashboardWidgetV2,
    });

  const {
    mutateAsync: createDatasetAction,
    isLoading: isCreatingDatasetAction,
  } = useMutation({
    mutationFn({
      guiSlug,
      tabSlug,
      values,
      path,
    }: {
      guiSlug: string;
      tabSlug: string;
      path: string;
      values: FusionActionForm;
    }) {
      return GuiModel.createDatasetAction(guiSlug, {
        action_title: values.action_title,
        action_icon: values.action_icon,
        action_type: values.action_type,
        action_pinned: values.action_pinned,
        action_hotkey: values.action_hotkey,
        action_starter_form_dataset: values.action_starter_form_dataset,
        tab_slug: tabSlug,
        path,
      });
    },
  });

  const { mutateAsync: createGuiAction, isLoading: isCreatingGuiAction } =
    useMutation({
      mutationFn({
        guiSlug,
        tabSlug,
        values,
        path,
      }: {
        guiSlug: string;
        tabSlug: string;
        path: string;
        values: FusionActionForm;
      }) {
        return GuiModel.createGuiAction(guiSlug, {
          action_title: values.action_title,
          action_icon: values.action_icon,
          action_type: values.action_type,
          action_pinned: values.action_pinned,
          action_hotkey: values.action_hotkey,
          action_starter_form_dataset: values.action_starter_form_dataset,
          tab_slug: tabSlug,
          path,
        });
      },
    });

  const { mutateAsync: createWidgetAction, isLoading: isCreatingWidgetAction } =
    useMutation({
      mutationFn({
        guiSlug,
        widgetSlug,
        values,
        path,
      }: {
        guiSlug: string;
        widgetSlug: string;
        path: "associated_actions" | "dataset_row_actions";
        values: FusionActionForm;
      }) {
        return GuiModel.createWidgetAction(guiSlug, {
          action_title: values.action_title,
          action_icon: values.action_icon,
          action_type: values.action_type,
          action_pinned: values.action_pinned,
          action_hotkey: values.action_hotkey,
          action_starter_form_dataset: values.action_starter_form_dataset,
          widget_slug: widgetSlug,
          path,
        });
      },
    });

  const spinning =
    isUpdatingGuiTab ||
    isCreatingDatasetAction ||
    isCreatingGuiAction ||
    isCreatingWidgetAction ||
    isUpdatingWidget;

  const handleClose = useCallback(() => {
    setOpen(false);
    setAction(undefined);
    setEditActionPathPrefix("");
    setWidget(undefined);
  }, [setAction, setEditActionPathPrefix, setOpen, setWidget]);

  const handleActionEditorSubmit = useCallback(
    async (values: FusionActionForm) => {
      if (guiSlug) {
        if (action) {
          if (widget) {
            const key =
              widgetActionType === "dataset"
                ? "dataset_row_actions"
                : "associated_actions";
            const actions = (widget[key] ?? []).map((a) =>
              a.id === action.id ? { ...a, ...values } : a
            );

            await updateWidget({
              slug: widget.slug,
              data: {
                [key]: actions,
              },
            });
            queryClient.refetchQueries({
              queryKey: getGuiTabWidgetsQueryKey(guiTab?.slug),
            });
          } else {
            const tab = (cloneDeep(guiTab) ?? {}) as GuiTab;
            const actions: FusionAction[] = get(tab, actionsPath) ?? [];
            set(
              tab,
              actionsPath,
              actions.map((a) => (a.id === action.id ? { ...a, ...values } : a))
            );

            await updateGuiTab({
              slug: guiSlug,
              data: normalizeObjectForAPI({ ...guiTab, ...tab }, [
                "gui_slug",
                "parent_tab_slug",
                "tab_type",
              ]),
            });
          }
        } else if (guiAction) {
          if (widget) {
            await createWidgetAction({
              guiSlug,
              widgetSlug: widget.slug,
              values,
              path:
                widgetActionType === "dataset"
                  ? "dataset_row_actions"
                  : "associated_actions",
            });
            queryClient.refetchQueries({
              queryKey: getGuiTabWidgetsQueryKey(guiTab?.slug),
            });
          } else {
            await createGuiAction({
              guiSlug,
              tabSlug: guiTab!.slug,
              values,
              path: editActionPathPrefix,
            });
          }
        } else {
          await createDatasetAction({
            guiSlug,
            tabSlug: guiTab!.slug,
            values,
            path: editActionPathPrefix,
          });
        }

        queryClient.refetchQueries({
          queryKey: [ApiModels.GuiTab, guiSlug],
        });
      }
      handleClose();
    },
    [
      guiSlug,
      handleClose,
      action,
      guiAction,
      queryClient,
      widget,
      widgetActionType,
      updateWidget,
      guiTab,
      actionsPath,
      updateGuiTab,
      createWidgetAction,
      createGuiAction,
      editActionPathPrefix,
      createDatasetAction,
    ]
  );

  return (
    <DrawerBox
      anchor="right"
      open={open}
      width="420px"
      title="Fusion Action Editor"
      icon={<GridViewOutlined />}
      onClose={handleClose}
    >
      <ActionEditor
        loading={spinning}
        onSubmit={handleActionEditorSubmit}
        onCancel={handleClose}
      />
    </DrawerBox>
  );
};

export default ActionEditorDrawer;
