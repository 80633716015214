import { Box, styled } from "@mui/material";
import axios from "axios";
import DatasetTable from "components/DatasetTable";
import S3Model from "models/S3";
import { FC, useEffect, useRef, useState } from "react";
import useAgentChatStore from "store/stores/agent-chat";

const Container = styled(Box)({
  height: "calc(100% - 57px)",

  ".ag-root-wrapper": {
    borderRadius: 0,
  },
});

type ScratchpadDisplayProps = {};

const ScratchpadDisplay: FC<ScratchpadDisplayProps> = (props) => {
  const selectedScratchpad = useAgentChatStore.useSelectedScratchpad();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [iframeContent, setIframeContent] = useState<string>("");

  useEffect(() => {
    if (selectedScratchpad?.type === "dataset") {
    } else {
      const fetchFiles = async () => {
        let html = "";
        try {
          if (selectedScratchpad?.default_html_path) {
            const response = await S3Model.getDownloadUrl({
              key: selectedScratchpad.default_html_path,
              bucket: "pre-gf-core-prod-media-bucket",
            });

            const url = response?.data?.url;
            if (url) {
              const htmlResponse = await axios.get(url);
              html = htmlResponse.data;
            }
          }

          if (selectedScratchpad?.default_css_path) {
            const response = await S3Model.getDownloadUrl({
              key: selectedScratchpad.default_css_path,
              bucket: "pre-gf-core-prod-media-bucket",
            });

            const url = response?.data?.url;
            if (url) {
              html = html.replace(
                '<link rel="stylesheet" href="styles.css">',
                `<link rel="stylesheet" href="${url}">`
              );
            }
          }

          if (selectedScratchpad?.default_js_path) {
            const response = await S3Model.getDownloadUrl({
              key: selectedScratchpad.default_js_path,
              bucket: "pre-gf-core-prod-media-bucket",
            });

            const url = response?.data?.url;
            if (url) {
              html = html.replace(
                '<script src="scripts.js"></script>',
                `<script src="${url}"></script>`
              );
            }
          }

          setIframeContent(html);
        } catch (error) {
          console.error("Error fetching files from S3:", error);
        }
      };

      fetchFiles();
    }

    return () => {
      setIframeContent("");
    };
  }, [selectedScratchpad]);

  useEffect(() => {
    if (iframeRef.current && iframeContent) {
      const iframeDoc =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow?.document;
      iframeDoc?.open();
      iframeDoc?.write(iframeContent);
      iframeDoc?.close();
    }
  }, [iframeContent]);

  return (
    <Container>
      {selectedScratchpad?.type === "dataset" &&
      selectedScratchpad.dataset_design_slug ? (
        <DatasetTable
          datasetDesignSlug={selectedScratchpad.dataset_design_slug}
          columnsToShow={selectedScratchpad.metadata?.fields}
          limit={selectedScratchpad.metadata?.limit || 20}
          conditionSets={
            selectedScratchpad.metadata?.condition_sets as unknown[]
          }
          editable={false}
          defaultTitleColumn={false}
          suppressMovableColumns
          paginationPageSize={20}
          paginationAutoPageSize={false}
          paginationPageSizeSelector={[10, 20, 50, 100]}
          blockLoadDebounceMillis={500}
          autoSizeStrategy={{
            type: "fitGridWidth",
          }}
          getRowStyle={(params) => {
            return params.data ? { opacity: 1 } : { opacity: 0 };
          }}
          disableRowSelection
          disableRowDrag
        />
      ) : (
        <iframe
          ref={iframeRef}
          style={{ width: "100%", height: "100vh", border: "none" }}
          title="S3 Content"
        />
      )}
    </Container>
  );
};

export default ScratchpadDisplay;
