import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { Box, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import classNames from "classnames";
import { SortableList } from "components/SortableList";
import { Handle } from "components/drag-drop/components";
import { DraggableItemProps } from "components/drag-drop/components/Item/Item";
import Icon from "components/util-components/Icon";
import { Icons } from "constants/index";
import {
  MouseEvent,
  MouseEventHandler,
  forwardRef,
  memo,
  useEffect,
  useState,
} from "react";
import { useSystemLayoutStore } from "store/stores/systemLayout";
import { ListItemButtonWrap, ListItemStyle } from "../index.styles";

type MenuInfo = {
  title: string;
  key: string;
  icon: Icons;
  children?: MenuInfo[];
};

type ListItemProps = {
  className?: string;
  listItemClassName?: string;
  hideDragHandler?: boolean;
  item: { title: string; key: string; icon: Icons };
  enableContextMenu?: boolean;
  enableItemOptionMenu?: boolean;

  onItemMenuClick?: (_: MouseEvent<HTMLDivElement>, extra?: MenuInfo) => void;
} & Partial<DraggableItemProps>;

const open = true;

const ListItem = memo(
  forwardRef<HTMLLIElement, ListItemProps>((props, ref) => {
    const {
      item,
      onItemMenuClick,
      hideDragHandler = false,
      className,
      listItemClassName,
      enableContextMenu = true,
      enableItemOptionMenu = false,
    } = props;

    const theme = useTheme();
    const [contextOpen, setContextOpen] = useState(false);
    const { onClick: onItemClick, isActive } =
      useSystemLayoutStore.useItemProps?.() || {};
    const handleClick = () => {
      onItemClick?.(item);
    };
    const handleItemMenuClick: MouseEventHandler<HTMLLIElement> = (e) => {
      onItemMenuClick?.(e as any, item);
      setContextOpen(true);
    };

    useEffect(() => {
      /// listen for click on .MuiModal-backdrop
      const handleClick = (e: any) => {
        const className = e.target?.className;
        if (
          typeof className === "string" &&
          (className.includes("MuiModal-backdrop") ||
            className.includes("MuiList-root"))
        ) {
          setContextOpen(false);
        }
      };
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, []);

    return (
      <Box className={className}>
        <ListItemStyle
          className={listItemClassName}
          disablePadding
          onClick={handleClick}
          sx={{
            zIndex: 10000000000,
            borderRadius: "6px",
            transition: "all 0.2s ease",
            "&:hover": {
              background: theme.palette.background.Hover,
            },
          }}
          ref={ref}
          isActive={isActive?.(item)}
          // {...rest}
          id={item.key}
          onContextMenu={
            enableContextMenu ? (e) => handleItemMenuClick(e) : undefined
          }
        >
          {!hideDragHandler && (
            <SortableList.DragHandle>
              <Handle />
            </SortableList.DragHandle>
          )}
          <ListItemButtonWrap
            sx={{
              minHeight: "32px",
              justifyContent: "initial",
              px: 2,
            }}
            disableRipple={true}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 1.5,
                justifyContent: "center",
              }}
            >
              <Icon
                iconName={item.icon || "Menu"}
                sx={{ width: "16px", height: "auto" }}
                key={item.icon}
              />
            </ListItemIcon>
            <ListItemText
              primary={item.title}
              sx={{ opacity: open ? 1 : 0, m: 0 }}
            />
            {enableItemOptionMenu && (
              <Box
                className={classNames("more_icon", { is_open: contextOpen })}
                onClick={(e: any) => handleItemMenuClick(e)}
              >
                <MoreHoriz
                  fontSize="medium"
                  sx={{ color: theme.palette.text.secondary }}
                />
              </Box>
            )}
          </ListItemButtonWrap>
        </ListItemStyle>
      </Box>
    );
  })
);

export default ListItem;
