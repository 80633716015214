import { Checkbox, Rating, Slider, TextField } from "@mui/material";
import AccountUserDataProvider from "components/DataProviders/AccountUserDataProvider";
import UserSelector from "components/Form/UserSelector";
import CheckboxField from "components/FormField/FieldRenderer/CheckboxField";
import CodeField from "components/FormField/FieldRenderer/CodeField";
import DateField from "components/FormField/FieldRenderer/DateField";
import GuiDocumentField from "components/FormField/FieldRenderer/GuiDocumentField";
import IconField from "components/FormField/FieldRenderer/IconField";
import RadioField from "components/FormField/FieldRenderer/RadioField";
import SelectField from "components/FormField/FieldRenderer/SelectField";
import UploadField from "components/FormField/FieldRenderer/UploadField";
import { DocumentElementType, FormFieldType } from "enums/Form";
import { FC, HTMLInputTypeAttribute } from "react";

const getTextFieldType = (
  type?: DocumentElementType | FormFieldType
): HTMLInputTypeAttribute => {
  switch (type) {
    case DocumentElementType.Number:
      return "number";

    default:
      return "text";
  }
};

type FieldRendererProps = {
  type?: DocumentElementType | FormFieldType;
  fieldExtras?: Record<string, unknown>;
  value?: any;
  onChange?(value: unknown): void;
};

const FieldRenderer: FC<FieldRendererProps> = (props) => {
  const { type, value, fieldExtras, onChange } = props;

  switch (type) {
    case DocumentElementType.Icon:
      return <IconField value={value} onChange={onChange} />;
    case DocumentElementType.Boolean:
      return (
        <Checkbox
          checked={!!value}
          onChange={(_, checked) => onChange?.(checked ? 1 : 0)}
          sx={{ width: "fit-content", p: 0 }}
        />
      );
    case DocumentElementType.Checkbox:
      return (
        <CheckboxField
          value={value}
          onChange={onChange}
          field={fieldExtras?.field as DataField}
        />
      );
    case DocumentElementType.Radio:
      return (
        <RadioField
          value={value}
          onChange={onChange}
          field={fieldExtras?.field as DataField}
        />
      );
    case DocumentElementType.Select:
      return (
        <SelectField
          value={value ?? ""}
          onChange={onChange}
          field={fieldExtras?.field as DataField}
          disabled={fieldExtras?.disabled as boolean}
        />
      );
    case FormFieldType.GuiDocument:
      return (
        <GuiDocumentField
          value={value ?? ""}
          onChange={onChange}
          field={fieldExtras?.field as DataField}
        />
      );
    case DocumentElementType.CodeEditor:
      return <CodeField value={value} onChange={onChange} />;
    case DocumentElementType.Progress:
      return (
        <Slider
          valueLabelDisplay="auto"
          value={value || 0}
          onChange={(_, value) => onChange?.(value)}
          sx={{
            mt: 2,
          }}
          slotProps={{
            thumb: {
              style: {
                width: "16px",
                height: "16px",
              },
            },
          }}
        />
      );
    case DocumentElementType.Rating:
      return (
        <Rating
          value={value}
          onChange={(_, value) => onChange?.(value)}
          precision={0.5}
          size="large"
        />
      );
    case DocumentElementType.User:
      return (
        <AccountUserDataProvider>
          <UserSelector
            actionButtons={false}
            multi={(fieldExtras?.field as DataField)?.multi_user ?? false}
            value={value}
            onChange={(changed: string | string[]) => onChange?.(changed)}
          />
        </AccountUserDataProvider>
      );
    case DocumentElementType.Date:
      return (
        <DateField
          field={fieldExtras?.field as DataField}
          value={value}
          onChange={onChange}
        />
      );
    case DocumentElementType.Image:
    case DocumentElementType.File:
    case DocumentElementType.AudioVideo:
      return (
        <UploadField
          field={fieldExtras?.field as DataField}
          value={value}
          onChange={onChange}
          disabled={fieldExtras?.disabled as boolean}
        />
      );
    default:
      return (
        <TextField
          value={value ?? ""}
          onChange={(e) => {
            if (type === DocumentElementType.Number) {
              onChange?.((e.target as HTMLInputElement).valueAsNumber);
              return;
            }
            onChange?.(e.target.value);
          }}
          variant="outlined"
          size="small"
          hiddenLabel
          fullWidth
          type={getTextFieldType(type)}
          {...(type === DocumentElementType.TextArea
            ? {
                multiline: true,
                rows: 2,
              }
            : {})}
          sx={{
            pointerEvents: fieldExtras?.disabled ? "none" : "all",
            ...(fieldExtras?.sx ?? {}),
          }}
          {...fieldExtras}
        />
      );
  }
};

export default FieldRenderer;
