import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import { IconButton, Stack, Typography } from "@mui/material";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { FC } from "react";

type TaskScratchpadsProps = {
  job?: Job;
};

const TaskScratchpads: FC<TaskScratchpadsProps> = (props) => {
  const { job } = props;

  const { data: jobTasks = [] } = useListItems({
    modelName: ApiModels.JobTask,
    requestOptions: { query: { job_slug: job?.slug } },
    queryKey: [ApiModels.JobTask, job?.slug],
  });

  return (
    <Stack direction="column" gap={1} mt={2}>
      {jobTasks.map((task) =>
        task.task_scratchpads.map((scratchpad) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              background: (theme) => theme.palette.background.CardBackground,
              py: 1,
              px: 2,
              borderRadius: "4px",
            }}
          >
            <Typography fontWeight={500} fontSize={15} lineHeight="36px">
              {scratchpad}
            </Typography>
            <IconButton size="small" sx={{ p: 0 }}>
              <VisibilityOutlined fontSize="small" />
            </IconButton>
          </Stack>
        ))
      )}
    </Stack>
  );
};

export default TaskScratchpads;
