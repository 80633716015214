import FormField from "components/FormField";
import IconPickerField from "components/IconPicker";
import { AllIconPickerIcons } from "constants/index";
import { ParameterType } from "enums/3pApp";
import { ApiModels } from "queries/apiModelMapping";
import useFolders from "queries/useFolders";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { ParamField } from "views/app-view/flow-designer/components/NodeEditorFields";

type CreateDatasetDesignFieldsProps = {};

const CreateDatasetDesignFields: FC<CreateDatasetDesignFieldsProps> = (
  props
) => {
  const { control } = useFormContext();

  const { data: folders = [] } = useFolders({}, ApiModels.DatasetDesign);

  const folderOptions = useMemo<LabeledValue[]>(
    () => folders.map((f) => ({ label: f.name, value: f.slug })),
    [folders]
  );

  return (
    <>
      <ParamField
        mappable
        field={{
          name: "name",
          label: "Dataset Design Name",
          type: ParameterType.Text,
        }}
      />
      <ParamField
        mappable
        field={{
          name: "slug",
          label: "Dataset Design Slug",
          type: ParameterType.Text,
        }}
      />
      <ParamField
        mappable
        field={{
          name: "folder",
          label: "Folder",
          type: ParameterType.Select,
          options: folderOptions,
        }}
      />
      <ParamField
        mappable
        field={{
          name: "color",
          label: "Dataset Design Color",
          type: ParameterType.Color,
        }}
      />
      <FormField
        label="Dataset Design Icon"
        formControl={{ control, name: "icon" }}
        collapsible
        sx={{ mb: 2 }}
      >
        {({ value, onChange }) => (
          <IconPickerField
            icons={AllIconPickerIcons}
            value={value as string}
            onChange={(iconSlug) => onChange?.(iconSlug)}
            placement="left"
            textFieldProps={{
              fullWidth: true,
              variant: "filled",
              size: "small",
              hiddenLabel: true,
            }}
          />
        )}
      </FormField>
      <ParamField
        mappable
        field={{
          name: "description",
          label: "Dataset Design Description",
          type: ParameterType.Text,
          multiline: true,
        }}
      />
    </>
  );
};

export default CreateDatasetDesignFields;
