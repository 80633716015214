import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as ProfileIconSvg } from "../svg/profileIcon.svg";

const ProfileIcon: React.FC<React.ComponentProps<typeof SvgIcon>> = (props) => {
  return (
    <SvgIcon
      inheritViewBox
      {...props}
      component={ProfileIconSvg}
      className="plus-icon"
    />
  );
};

export default ProfileIcon;
