import {
  DeleteOutline,
  EditOutlined,
  LocalOfferOutlined,
} from "@mui/icons-material";
import { Box, Stack, styled, useTheme } from "@mui/material";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { SortableList } from "components/SortableList";
import Spin from "components/Spin";
import { queryClient } from "queries";
import { ApiModels } from "queries/apiModelMapping";
import { useGuiParams } from "queries/guiParams/useGuiParams";
import useDeleteItem from "queries/useDeleteItem";
import React from "react";
import ProfileCard from "stories/CompoundComponent/ProfileCard/ProfileCard";
import { confirmDelete } from "utils/confirmDelete";
export type FusionActionsProps = {
  gui: GfGui;
  onAddClick(): void;
  onEditClick(action: GuiParams): void;
};
const getLastPart = (str: string) => {
  let parts = str.split(":");
  return parts[parts.length - 1];
};

const BoxIcon = styled(Box)(({ theme }) => {
  return {
    width: "20px",
    color: theme.palette.background.GF70,

    svg: {
      maxWidth: "100%",
      height: "20px",
      width: "auto",
      display: "block",
      margin: "auto",

      path: {
        fill: "currentColor",
      },
    },
  };
});
const ParamsList: React.FC<FusionActionsProps> = (props) => {
  const { gui, onAddClick, onEditClick } = props;
  const { data: params, isLoading } = useGuiParams(gui.slug);
  const { mutate: deleteParam } = useDeleteItem({
    modelName: ApiModels.GuiParams,
  });
  const theme = useTheme();

  const handleDelete = (slug: string) => {
    confirmDelete().then(async (result) => {
      if (result.isConfirmed) {
        await deleteParam(
          { slug: slug! },
          {
            onSuccess: () => {
              queryClient.refetchQueries([ApiModels.GuiParams, gui.slug]);
            },
          }
        );
      }
    });
  };
  return (
    <SidebarSectionWrap title="Custom Parameter" onRightIconClick={onAddClick}>
      <Spin spinning={isLoading}>
        <SortableList
          items={params || []}
          // onChange={(updatedItems) => {
          //   onSort(updatedItems);
          // }}
          renderItem={(item) => {
            return (
              <SortableList.Item id={item.id} handle>
                <ProfileCard
                  AvatarImage={
                    <BoxIcon>
                      <LocalOfferOutlined />
                    </BoxIcon>
                  }
                  options={{
                    draggable: false,
                    switcher: false,
                  }}
                  rightIcon={
                    <Stack direction="row" spacing={1}>
                      <Box className="edit-icon">
                        <DeleteOutline
                          sx={{ color: "grey.500" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(item.slug);
                          }}
                        />
                      </Box>
                      <Box className="edit-icon">
                        <EditOutlined sx={{ color: "grey.500" }} />
                      </Box>
                    </Stack>
                  }
                  title={item.title}
                  subTitle={getLastPart(item.slug)}
                  sx={{
                    "&:hover": {
                      background: theme.palette.background.GFRightNavForeground,
                    },
                  }}
                  onClick={() => {
                    onEditClick(item);
                  }}
                />
              </SortableList.Item>
            );
          }}
        />
      </Spin>
    </SidebarSectionWrap>
  );
};

export default ParamsList;
