import Model from "models";

class SqlQueryModel extends Model<SqlQuery> {
  constructor() {
    super("/sql-query", "public-1");
  }
}

const model = new SqlQueryModel();

export default model;
