import MoreVert from "@mui/icons-material/MoreVert";
import { Box, Stack, Typography, styled } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { DynamicFieldProps } from "components/Form/DynamicEditFields";
import DynamicEditForm, {
  DynamicEditFormProps,
  FormRefAttribute,
} from "components/Form/DynamicEditForm";
import { transformFieldsOptions } from "components/Form/helper";
import FusionActionsDropdown from "components/FusionAction/FusionActionsDropdown";
import { TopBarIconButton } from "components/IconButtonWithTooltip";
import Scrollbar from "components/Scrollbar";
import { DocumentElementType } from "enums/Form";
import { cloneDeep, find } from "lodash";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import React, { useEffect, useMemo, useRef } from "react";
import { useDatasetStore } from "store/stores/dataset";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import RightSidebarMain from "./components/RightSidebarMain";

export const BoxHeader = styled(Stack)(({ theme }) => ({
  gap: "24px",
  marginBottom: "48px",

  [`${theme.breakpoints.down("sm")}`]: {
    gap: "8px",
    margin: "-11px -15px 20px",
    padding: "11px 15px 11px",
    background: theme.palette.common.blackshades["30p"],
    borderBottom: `1px solid ${theme.palette.background.GFOutlineNav}`,
  },
}));

const generalFields: DataFieldMapped[] = [
  { type: "label", name: "label", label: "General Setting" },
  {
    type: DocumentElementType.TextField,
    name: "title",
    label: "Title",
    required: true,
  },
];

type EditDatasetProps = {
  onFormEvent?: DynamicEditFormProps["onFormEvent"];
  data?: any;
  guiSlug: string;
  datasetDesignSlug?: string;
  datasetSlug?: string;
  includedFieldIds?: string[];
  tabMetadata?: IncludeTabs;
  index?: number;
  showHeader?: boolean;
  allowAddingNewWidget?: boolean;
  isRightWidget?: boolean;
};

const EditDataset: React.FC<EditDatasetProps> = (props) => {
  const {
    onFormEvent,
    datasetDesignSlug,
    datasetSlug,
    includedFieldIds,
    tabMetadata,
    index,
    showHeader = true,
    guiSlug,
    allowAddingNewWidget,
    isRightWidget = true,
  } = props;

  const queryClient = useQueryClient();
  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();

  const defaultData = useMemo(() => {
    const defaultList = queryClient.getQueryData([
      [ApiModels.Dataset, { dataset_type_slug: datasetDesignSlug, title: "" }],
    ]) as unknown as DatasetDesign[];

    return find(defaultList, { slug: datasetSlug });
  }, [datasetDesignSlug, datasetSlug, queryClient]) as unknown as Dataset;

  const formRef = useRef<FormRefAttribute | undefined>();
  const { data: datasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: datasetDesignSlug,
  });

  const { data: dataset = defaultData } = useGetItem({
    modelName: ApiModels.Dataset,
    slug: datasetSlug,
    requestOptions: { path: datasetDesignSlug },
    // queryOptions: { enabled: false },

    queryKey: [datasetDesignSlug, datasetSlug],
  });
  const { mutate: updateDataset } = useUpdateItem({
    modelName: ApiModels.Dataset,
    requestOptions: { path: datasetDesignSlug },
  });
  const pushDatasetDraft = useDatasetStore.usePushDatasetDraft();

  const initialValueSet = useRef(false);
  const allowNetworkRequest = useRef(false);

  const submitHandler = (data: Record<string, any>) => {
    if (allowNetworkRequest.current && datasetSlug)
      updateDataset(
        { slug: datasetSlug, data: data },
        {
          onSuccess: () => {},
          onError: () => {
            //TODO:HandleError
          },
        }
      );
  };

  const includedFields = useMemo(() => {
    if (includedFieldIds?.length === 0) return [];

    if (datasetDesign?.fields?.fields?.length) {
      const includedGuiFields =
        datasetDesign?.fields?.fields
          .filter((f) => includedFieldIds?.includes(f.id!))
          .sort((a, b) => {
            const indexA = includedFieldIds?.indexOf(a.id) || 0;
            const indexB = includedFieldIds?.indexOf(b.id) || 0;
            return indexA - indexB;
          })
          ?.map((f) => {
            const field = cloneDeep(f);
            field.list_filter = tabMetadata?.field_metadata?.[f.id];
            return field;
          }) || [];

      return includedGuiFields;
    }
    return [];
  }, [datasetDesign?.fields?.fields, includedFieldIds]);

  const fields = useMemo(() => {
    let fields = [...generalFields];
    if (includedFields?.length) {
      fields = fields.concat(
        transformFieldsOptions(includedFields, {
          prefixName: "fields",
        })
      );
    }
    return fields;
  }, [includedFields]);

  useEffect(() => {
    initialValueSet.current = false;
  }, [datasetSlug]);

  useEffect(() => {
    if (dataset && datasetDesign?.fields?.fields) {
      pushDatasetDraft(
        {
          data: dataset,
          fields: datasetDesign?.fields?.fields,
        },
        false
      );
    }
  }, [dataset, datasetDesign?.fields?.fields, pushDatasetDraft]);

  useEffect(() => {
    if (formRef.current?.reset && dataset && !initialValueSet.current) {
      formRef.current?.reset(dataset);
      initialValueSet.current = true;
      setTimeout(() => {
        allowNetworkRequest.current = true;
      }, 1000);
    }
  }, [formRef, dataset]);

  return (
    <Scrollbar className="saiban">
      {showHeader && (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mx: 7, mt: 2, maxWidth: "600px" }}
        >
          <Typography fontSize={18} fontWeight={600}>
            {tabMetadata?.name}
          </Typography>
          <FusionActionsDropdown
            actions={tabMetadata?.associated_actions ?? []}
            editable={editModeEnabled}
            pathPrefix={`included_tabs.${index}`}
            dataset={dataset}
          >
            <TopBarIconButton size="small">
              <MoreVert />
            </TopBarIconButton>
          </FusionActionsDropdown>
        </Stack>
      )}
      {dataset && (
        <DynamicEditForm
          name={"fields"}
          fields={fields as DynamicFieldProps[]}
          ref={formRef}
          defaultValues={{}}
          onSubmit={submitHandler}
          onFormEvent={onFormEvent}
          disableTableActions={{ add: false, edit: true, remove: false }}
          inlineLabels
          formMetadata={{ field_metadata: tabMetadata?.field_metadata }}
        />
      )}
      <Box sx={{ maxWidth: "600px", ml: 7 }} className="saiban-box">
        <RightSidebarMain
          includedTabs={tabMetadata?.additional_tabs || []}
          datasetDesignSlug={datasetDesignSlug!}
          datasetSlug={datasetSlug!}
          guiSlug={guiSlug}
          allowAddingNewWidget={allowAddingNewWidget}
          isRightWidget={isRightWidget}
        />
      </Box>
    </Scrollbar>
  );
};

export default EditDataset;
