import { Box, styled } from "@mui/material";

type DatasetTableContainerProps = {
  backgroundColors?: {
    scrollbarButton?: string;
    scrollbarTrack?: string;
    scrollbarThumb?: string;
    tableBody?: string;
    tableHeader?: string;
    tableDivider?: string;
  };
  colors?: {
    list?: string;
    textAreaInput?: string;
    cell?: string;
    numberFieldInput?: string;
    pickerFieldWrapper?: string;
    headerCell?: string;
  };
};

export const DatasetTableContainer = styled(Box)<DatasetTableContainerProps>(
  ({ theme, backgroundColors = {}, colors = {} }) => ({
    ".ag-root ::-webkit-scrollbar": {
      width: "10px",
      height: "10px",
    },
    ".ag-root ::-webkit-scrollbar-button": {
      background:
        backgroundColors.scrollbarButton ||
        theme.palette.background.ScrollbarButton,
      display: "none",
    },
    ".ag-root ::-webkit-scrollbar-track-piece": {
      background:
        backgroundColors.scrollbarTrack ||
        theme.palette.background.ScrollbarTrack,
    },
    ".ag-root ::-webkit-scrollbar-thumb": {
      background:
        backgroundColors.scrollbarThumb ||
        theme.palette.background.ScrollbarThumb,
    },

    ".ag-root-wrapper": {
      borderRadius: 0,
      border: "none",

      ".ag-popup": {
        ".ag-list": {
          background:
            backgroundColors.tableBody || theme.palette.background.TableBody,
          color: colors.list || theme.palette.text.primary,
        },

        ".ag-large-text": {
          borderColor:
            backgroundColors.tableHeader ||
            theme.palette.background.TableHeader,
          background:
            backgroundColors.tableHeader ||
            theme.palette.background.TableHeader,

          ".ag-large-text-input": {
            background:
              backgroundColors.tableBody || theme.palette.background.TableBody,

            ".ag-input-field-input.ag-text-area-input": {
              background:
                backgroundColors.tableBody ||
                theme.palette.background.TableBody,
              color: colors.textAreaInput || theme.palette.text.primary,
              borderColor:
                backgroundColors.tableHeader ||
                theme.palette.background.TableHeader,
            },
          },
        },
      },

      ".ag-root": {
        ".ag-body-vertical-scroll": {
          width: "10px !important",
          minWidth: "10px !important",

          ".ag-body-vertical-scroll-viewport": {
            width: "10px !important",
            minWidth: "10px !important",

            ".ag-body-vertical-scroll-container": {
              width: "10px !important",
              minWidth: "10px !important",
            },
          },
        },

        ".ag-body-horizontal-scroll": {
          height: "10px !important",
          minHeight: "10px !important",

          ".ag-body-horizontal-scroll-viewport": {
            height: "10px !important",
            minHeight: "10px !important",

            ".ag-body-horizontal-scroll-container": {
              height: "10px !important",
              minHeight: "10px !important",
            },
          },

          ".ag-horizontal-right-spacer": {
            borderLeft: "none",
          },
        },
      },

      ".ag-action-cell": {
        whiteSpace: "normal",
        textOverflow: "unset",
        overflow: "hidden",
        // padding: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },

      ".ag-pinned-right-header ": {
        ".ag-header-row": {
          width: "100% !important",

          ".ag-header-cell": {
            width: "100% !important",
          },
        },
      },

      ".ag-action-header": {
        ".ag-header-cell-comp-wrapper": {
          justifyContent: "center",
        },
      },

      ".ag-body": {
        background:
          backgroundColors.tableBody || theme.palette.background.TableBody,
      },

      ".ag-row": {
        borderBottomColor:
          backgroundColors.tableDivider ||
          theme.palette.background.TableDivider,
        background:
          backgroundColors.tableBody || theme.palette.background.TableBody,

        ".ag-cell": {
          borderRight: `1px solid ${
            backgroundColors.tableDivider ||
            theme.palette.background.TableDivider
          }`,
          color: colors.cell || theme.palette.text.primary,

          "&.ag-cell-last": {
            borderRight: "none",
          },

          ".ag-cell-edit-wrapper": {
            ".ag-cell-editor": {
              ".ag-input-wrapper": {
                ".ag-input-field-input": {
                  "&.ag-text-field-input, &.ag-number-field-input": {
                    background:
                      backgroundColors.tableBody ||
                      theme.palette.background.TableBody,
                    color:
                      colors.numberFieldInput || theme.palette.text.primary,
                  },
                },
              },

              ".ag-picker-field-wrapper": {
                background:
                  backgroundColors.tableBody ||
                  theme.palette.background.TableBody,
                color: colors.pickerFieldWrapper || theme.palette.text.primary,
              },
            },
          },

          "&.ag-column-last": {
            borderRight: "none",
            padding: 0,
          },
        },
      },

      ".ag-paging-panel": {
        background:
          backgroundColors.tableHeader || theme.palette.background.TableHeader,
        color: theme.palette.text.secondary,

        ".ag-picker-field-wrapper": {
          background: theme.palette.background.TableBody,
          color: theme.palette.text.secondary,
        },

        ".ag-icon": {
          color: theme.palette.text.secondary,
        },
      },

      ".ag-header": {
        background:
          backgroundColors.tableHeader || theme.palette.background.TableHeader,
        borderBottom: `1px solid ${
          backgroundColors.tableDivider || theme.palette.background.TableDivider
        }`,
        borderTop: `1px solid ${
          backgroundColors.tableDivider || theme.palette.background.TableDivider
        }`,

        ".ag-header-cell": {
          borderRight: `1px solid ${
            backgroundColors.tableDivider ||
            theme.palette.background.TableDivider
          }`,

          "&.ag-column-last": {
            borderRight: "none",
            padding: 0,
          },
        },

        ".ag-header-cell-resize": {
          "&:after": {
            top: 0,
            height: "100%",
            backgroundColor:
              backgroundColors.tableDivider ||
              theme.palette.background.TableDivider,
            width: "1px",
            left: "3px",
          },
        },

        ".ag-header-cell-text": {
          color: colors.headerCell || theme.palette.text.secondary,
        },

        ".ag-header-cell-label": {
          justifyContent: "center",
        },

        ".ag-header-cell-last": {
          borderRight: "none",
        },
      },
    },
  })
);
