import { TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import DatasetDataProvider from "components/DataProviders/DatasetDataProvider";
import { ListSource, SelectTypes } from "enums/Form";
import { isArray } from "lodash";
import debounce from "lodash/debounce";
import { FC, useCallback, useMemo } from "react";

type SelectFieldProps = {
  field: DataField;
  value?: string | string[];
  disabled?: boolean;
  onChange?(value: string | string[]): void;
};

const defaultFilter = createFilterOptions({
  ignoreCase: true,
  trim: true,
  matchFrom: "any",
  stringify: (option) => (option as LabeledValue).label,
});

const SelectBox: FC<
  SelectFieldProps & {
    options?: LabeledValue[];
    loading?: boolean;
    onSearchHandler?(search: string): void;
    fetchNextPage?(): void;
  }
> = (props) => {
  const {
    options,
    onChange,
    onSearchHandler,
    loading,
    fetchNextPage,
    field,
    disabled,
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scrollHandler = useCallback(
    debounce((event: any) => {
      const { scrollTop, clientHeight, scrollHeight } = event.target;

      if (scrollHeight - scrollTop <= clientHeight + 10 && !loading) {
        // Near the bottom, load more

        fetchNextPage?.();
      }
    }, 1000),
    [fetchNextPage, loading]
  ); // debounce of 1 second

  const value = useMemo(() => {
    if (props.value) return props.value;

    if (field.default_value) return field.default_value;

    if (props.field.list_default_display_type === SelectTypes.Multiple) {
      return [];
    } else {
      return "";
    }
  }, [props.field.list_default_display_type, props.value, field.default_value]);

  return (
    <Autocomplete
      value={value}
      disabled={disabled}
      size="small"
      sx={{
        minHeight: "40px",
      }}
      freeSolo={field.free_solo}
      options={options ?? []}
      renderInput={(params) => {
        return <TextField {...params} variant="outlined" />;
      }}
      // isOptionEqualToValue={(option: any, value) => {
      //   const match = option.value?.toString() === value?.toString();
      //   return match;
      // }}
      onChange={(e, option: any) => {
        onChange?.(
          isArray(option)
            ? option.map((op) =>
                typeof op === "string" ? op : op?.value ?? ""
              )
            : typeof option === "string"
            ? option
            : option?.value || ""
        );
      }}
      filterOptions={(options, state) => {
        if (onSearchHandler) onSearchHandler(state.inputValue);

        const filtered = defaultFilter(options, state);
        const { inputValue } = state;
        const isExisting = (options as LabeledValue[]).some(
          (option) => inputValue === option.value
        );

        if (inputValue !== "" && !isExisting) {
          filtered.push({
            value: inputValue,
            label: `Add "${inputValue}"`,
            dynamic: true,
          });
        }

        return filtered;
      }}
      renderOption={(props, option: any) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      getOptionLabel={(option: any) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          const label = options?.find((op) => op.value === option)?.label;
          return label || option;
        }

        if (option.dynamic) {
          return option.value;
        }

        // Regular option
        return option.label;
      }}
      loading={loading}
      ListboxProps={{
        onScroll: scrollHandler,
      }}
      multiple={props.field.list_default_display_type === SelectTypes.Multiple}
    />
  );
};

const SelectField: FC<SelectFieldProps> = (props) => {
  const {
    list_source: type,
    associated_document: datasetDesignSlug,
    associated_document_label_field: labelField,
  } = props.field;
  if (type === ListSource.RecordAssociation) {
    <DatasetDataProvider
      datasetDesignSlug={datasetDesignSlug!}
      fetchSelectedValueInList={true}
      keyBinding={{
        label: labelField,
        value: "id",
      }}
    >
      <SelectBox {...props} />
    </DatasetDataProvider>;
  }

  return (
    <SelectBox {...props} options={props.field.list_items as LabeledValue[]} />
  );
};

export default SelectField;
