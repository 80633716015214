import { json } from "@codemirror/lang-json";
import EditOutlined from "@mui/icons-material/EditOutlined";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import { Box, Stack, Typography } from "@mui/material";
import { CustomTooltipProps } from "ag-grid-react";
import CodeEditor from "components/CodeEditor";
import { parseValueForCodeEditor } from "helpers";
import { FC, memo, useMemo } from "react";

type CodeCellTooltipProps = {
  field: DataField;
} & CustomTooltipProps;

const CodeCellTooltip: FC<CodeCellTooltipProps> = memo((props) => {
  const { field } = props;

  const value = useMemo(() => {
    return parseValueForCodeEditor(props.value);
  }, [props.value]);

  return (
    <Box
      sx={{
        width: "350px",
        maxHeight: "350px",
        borderRadius: 1,
        overflow: "hidden",
        ".cm-editor": {
          border: "none !important",
          borderRadius: "0 !important",
          height: "100%",
        },
      }}
    >
      <Stack
        sx={{ height: "32px", bgcolor: "#29292e", width: "100%" }}
        justifyContent="space-between"
        alignItems="stretch"
        direction="row"
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ bgcolor: "#3e3e42", paddingX: 2 }}
        >
          <InsertDriveFile fontSize="small" />
          <Typography variant="body2">data.json</Typography>
        </Stack>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ bgcolor: "#3e3e42", width: "32px" }}
          onClick={() => {
            if (props.rowIndex != null) {
              props.api.startEditingCell({
                rowIndex: props.rowIndex,
                colKey: field.slug,
              });
              props.hideTooltipCallback?.();
            }
          }}
        >
          <EditOutlined fontSize="small" />
        </Stack>
      </Stack>
      <CodeEditor
        style={{
          height: "318px",
        }}
        value={value}
        extensions={[json()]}
        readOnly
        basicSetup={{
          lineNumbers: true,
          syntaxHighlighting: true,
          foldGutter: true,
        }}
      />
    </Box>
  );
});

export default CodeCellTooltip;
