import { LoadingButton } from "@mui/lab";
import { ApiModels } from "queries/apiModelMapping";
import useDeleteItem from "queries/useDeleteItem";
import { FC } from "react";
import { useGuiDashboardV2Store } from "store/stores/gui-dashboard-v2";
import { confirmDelete } from "utils/confirmDelete";

type SettingsTabProps = {};

const SettingsTab: FC<SettingsTabProps> = (props) => {
  const widget = useGuiDashboardV2Store.useWidget();

  const { mutateAsync: deleteWidget, isLoading } = useDeleteItem({
    modelName: ApiModels.GuiDashboardWidgetV2,
  });

  const handleDelete = () => {
    confirmDelete().then(async (result) => {
      if (!result.isConfirmed) return;
      if (widget) {
        await deleteWidget({ slug: widget.slug });
      }
    });
  };

  return (
    <LoadingButton
      loading={isLoading}
      variant="outlined"
      color="error"
      size="small"
      fullWidth
      onClick={handleDelete}
    >
      Delete Widget
    </LoadingButton>
  );
};

export default SettingsTab;
