import { Box, Button, Stack } from "@mui/material";
import { DynamicFieldProps } from "components/Form/DynamicCreateFields";
import DynamicCreateForm, {
  FormRefAttribute,
} from "components/Form/DynamicCreateForm";
import { DocumentElementType, ListSource } from "enums/Form";
import { GuiType } from "enums/gui";
import { isEmpty } from "lodash";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ExtendedFields from "views/app-view/gui-workspace/components/TabEditorDrawer/ExtendedFields";
import { FusionActionsProps } from "views/app-view/gui-workspace/components/TabEditorDrawer/ExtendedFields/FusionActions";

const TAB_EDIT_FIELDS: DynamicFieldProps[] = [
  {
    label: "Tab Name",
    name: "tab_name",
    type: DocumentElementType.TextField,
    required: true,
  },
];

type TabEditorFieldsProps = {
  tab: GuiTab;
  viewFilters: unknown[];
  onEditDatalistSettings(): void;
  onSubmit(values: Partial<GuiTab>): void;
  onClose(): void;
  onAddStatusClick(): void;
  onEditStatusClick(status: WorkflowStatus): void;
  onAddFilterRuleClick(): void;
  onEditFilterRuleClick(status: FilterRule): void;
  onEditFieldToInclude(): void;
  onAddActionClick: FusionActionsProps["onAddClick"];
  onEditActionClick: FusionActionsProps["onEditClick"];
  onEditFilters(): void;
};

const TabEditorFields: React.FC<TabEditorFieldsProps> = (props) => {
  const {
    tab,
    viewFilters,
    onClose,
    onSubmit,
    onEditDatalistSettings,
    onAddStatusClick,
    onEditStatusClick,
    onAddFilterRuleClick,
    onEditFilterRuleClick,
    onEditFieldToInclude,
    onAddActionClick,
    onEditActionClick,
    onEditFilters,
  } = props;

  const [statusesToInclude, setStatusesToInclude] = useState<WorkflowStatus[]>(
    []
  );
  const [filterRules, setFilterRules] = useState<FilterRule[]>([]);
  const [datasetDesignSlug, setDatasetDesignSlug] = useState<string>();
  const [reviewerActions, setReviewerActions] = useState<FusionAction[]>([]);
  const [datasetActions, setDatasetActions] = useState<FusionAction[]>([]);

  const formRef = useRef<FormRefAttribute | undefined>();

  const { data: datasetDesigns } = useListItems({
    modelName: ApiModels.DatasetDesign,
  });

  const editFields = useMemo(() => {
    if (
      tab?.tab_type === GuiType.RecordList ||
      tab?.tab_type === GuiType.Reviewer
    ) {
      return [
        ...TAB_EDIT_FIELDS,
        {
          label: "Dataset Design",
          name: "dataset_design_slug",
          type: DocumentElementType.Select,
          list_source: ListSource.Hardcoded,
          options: datasetDesigns?.map((d) => ({
            label: d.name,
            value: d.slug,
          })),
          required: true,
        },
      ];
    } else if (tab?.tab_type === GuiType.WorkflowBoard) {
      return [
        ...TAB_EDIT_FIELDS,
        {
          label: "Dataset Design",
          name: "dataset_design_slug",
          type: DocumentElementType.Select,
          list_source: ListSource.Hardcoded,
          options: datasetDesigns?.map((d) => ({
            label: d.name,
            value: d.slug,
          })),
          disabled: true,
        },
        {
          label: "Status Field",
          name: "status_field",
          type: DocumentElementType.TextField,
          disabled: true,
        },
      ];
    }

    return TAB_EDIT_FIELDS;
  }, [tab?.tab_type, datasetDesigns]);

  useEffect(() => {
    if (!isEmpty(tab)) {
      setTimeout(() => {
        const initialValues: Record<string, unknown> = {
          tab_name: tab.tab_name,
        };

        if (
          tab.tab_type === GuiType.RecordList ||
          tab.tab_type === GuiType.Reviewer
        ) {
          initialValues.dataset_design_slug = tab.dataset_design_slug;
          setDatasetDesignSlug(tab.dataset_design_slug);
        }

        if (tab.tab_type === GuiType.Reviewer) {
          setReviewerActions(tab.associated_actions ?? []);
        }

        if (tab.tab_type === GuiType.RecordList) {
          setDatasetActions(tab.associated_actions ?? []);
        }

        if (tab.tab_type === GuiType.WorkflowBoard) {
          initialValues.dataset_design_slug = tab.dataset_design_slug;
          initialValues.status_field = tab.status_field;
          setStatusesToInclude(tab.statuses_to_include ?? []);
          setFilterRules(tab.filter_rules ?? []);
        }
        formRef.current?.reset(initialValues);
      }, 1000);
    }
  }, [tab]);

  const handleSubmit = () => {
    const values = formRef.current?.getValues();

    if (!values) {
      return;
    }

    if (tab.tab_type === GuiType.WorkflowBoard) {
      values.statuses_to_include = statusesToInclude;
      values.filter_rules = filterRules;
    } else if (tab.tab_type === GuiType.Reviewer) {
      values.associated_actions = reviewerActions;
    } else if (tab.tab_type === GuiType.RecordList) {
      values.associated_actions = datasetActions;
    }

    onSubmit(values as Partial<GuiTab>);
  };

  return (
    <Box>
      <Box sx={{ mt: "16px" }}>
        <DynamicCreateForm
          name="fields"
          fields={editFields}
          ref={formRef}
          labelCol={false}
          submitButton={false}
          allFieldsFullWidth
          onChange={(value, info) => {
            const { name, type } = info;

            if (
              name === "dataset_design_slug" &&
              type === "change" &&
              value[name]
            ) {
              setDatasetDesignSlug(value[name] as string);
            }
          }}
        />
      </Box>
      <Box
        sx={{
          px: 2,
        }}
      >
        <ExtendedFields
          tab={tab}
          statusesToInclude={statusesToInclude}
          filterRules={filterRules}
          reviewerActions={reviewerActions}
          datasetActions={datasetActions}
          viewFilters={viewFilters}
          onEditFilters={onEditFilters}
          setStatusesToInclude={setStatusesToInclude}
          setFilterRules={setFilterRules}
          onDatalistSettingsClick={onEditDatalistSettings}
          onAddFilterRuleClick={onAddFilterRuleClick}
          onAddStatusClick={onAddStatusClick}
          onEditFilterRuleClick={onEditFilterRuleClick}
          onEditStatusClick={onEditStatusClick}
          onEditFieldToInclude={onEditFieldToInclude}
          datasetDesignSlug={datasetDesignSlug ?? ""}
          onAddActionClick={onAddActionClick}
          onEditActionClick={onEditActionClick}
          onDeleteActionClick={(action) => {
            if (tab.tab_type === GuiType.Reviewer) {
              setReviewerActions((actions) =>
                actions.filter((a) => a.id !== action.id)
              );
            } else if (tab.tab_type === GuiType.RecordList) {
              setDatasetActions((actions) =>
                actions.filter((a) => a.id !== action.id)
              );
            }
          }}
          onSortAction={(actions) => {
            if (tab.tab_type === GuiType.Reviewer) {
              setReviewerActions(actions);
            } else if (tab.tab_type === GuiType.RecordList) {
              setDatasetActions(actions);
            }
          }}
        />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1.5}
        >
          <Button
            variant="outlined"
            color="inherit"
            sx={{
              border: "none",
              color: "#fff",
              background: (theme) => theme.palette.background.GF10,
            }}
            onClick={onClose}
            fullWidth
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              borderColor: "#fff",
              boxShadow: "none",
            }}
            fullWidth
          >
            Save Changes
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default TabEditorFields;
