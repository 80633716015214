import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { Box } from "@mui/material";
import Spin from "components/Spin";
import debounce from "lodash/debounce";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useMemo, useRef } from "react";
import {
  default as RGL,
  default as ReactGridLayout,
  WidthProvider,
} from "react-grid-layout";
import { useGuiDashboardV2Store } from "store/stores/gui-dashboard-v2";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import ActionBar from "./ActionBar";
import DashboardWidget from "./DashboardWidget";
import ResizeHandle from "./ResizeHandle";

type DashboardEditorProps = {
  guiSlug?: string;
  onAddWidget(): void;
  onFiltersClick(): void;
};

const DashboardEditor: FC<DashboardEditorProps> = (props) => {
  const { guiSlug, onAddWidget, onFiltersClick } = props;

  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();
  const tab = useGuiDashboardStore.useGuiTabDraft();
  const widgets = useGuiDashboardV2Store.useWidgets();
  const layout = useGuiDashboardV2Store.useLayout();
  const setLayout = useGuiDashboardV2Store.useSetLayout();

  const gridRef = useRef<ReactGridLayout>(null);

  const { mutateAsync: updateTab } = useUpdateItem({
    modelName: ApiModels.GuiTab,
  });

  const updateTabDebounced = useMemo(
    () => debounce(updateTab, 2000),
    [updateTab]
  );

  const gridLayout = useMemo(() => {
    return layout?.map((l) =>
      editModeEnabled
        ? {
            ...l,
            static: false,
            isDraggable: true,
            isResizable: true,
          }
        : {
            ...l,
            static: false,
            isDraggable: false,
            isResizable: false,
          }
    );
  }, [editModeEnabled, layout]);

  const children = useMemo(() => {
    return gridLayout?.map((layout) => {
      const widget = widgets?.find((w) => w.slug === layout.i);
      if (widget) {
        return <DashboardWidget widget={widget} key={widget.slug} />;
      } else {
        return (
          <Box key={layout.i}>
            <Spin spinning sx={{ width: "100%", height: "100%" }}></Spin>
          </Box>
        );
      }
    });
  }, [widgets, editModeEnabled, gridLayout]);

  const ReactGridLayout = useMemo(() => WidthProvider(RGL), []);

  return (
    <Box sx={{ padding: "16px 16px 44px" }}>
      <ActionBar
        guiSlug={guiSlug}
        associatedActions={tab?.associated_actions}
        onAddWidget={onAddWidget}
        onFiltersClick={onFiltersClick}
      />
      {gridLayout && widgets && (
        <ReactGridLayout
          ref={gridRef}
          layout={gridLayout}
          cols={24}
          rowHeight={10}
          containerPadding={[0, 0]}
          margin={[0, 0]}
          verticalCompact
          onResizeStop={(change) => {
            if (!change.some((l) => l.h <= 1 && l.w <= 1)) {
              setLayout(change);
              if (tab?.slug) {
                updateTabDebounced({
                  slug: tab.slug,
                  data: {
                    layout: change.map((c) => ({
                      i: c.i,
                      x: c.x,
                      y: c.y,
                      w: c.w,
                      h: c.h,
                      minW: c.minW,
                      maxW: c.maxW,
                      minH: c.minH,
                      maxH: c.maxH,
                    })),
                  },
                });
              }
            }
          }}
          onDragStop={(change) => {
            if (!change.some((l) => l.h <= 1 && l.w <= 1)) {
              setLayout(change);
              if (tab?.slug) {
                updateTabDebounced({
                  slug: tab.slug,
                  data: {
                    layout: change.map((c) => ({
                      i: c.i,
                      x: c.x,
                      y: c.y,
                      w: c.w,
                      h: c.h,
                      minW: c.minW,
                      maxW: c.maxW,
                      minH: c.minH,
                      maxH: c.maxH,
                    })),
                  },
                });
              }
            }
          }}
          resizeHandle={<ResizeHandle />}
          draggableCancel=".non-draggable-widget-area, .ag-body, .ag-body-horizontal-scroll, .ag-header-row-column-filter, .ag-popup, .ag-paging-page-summary-panel, .MuiMenu-root, .ag-paging-panel, .ag-action-header, .action-header-cell"
        >
          {children}
        </ReactGridLayout>
      )}
    </Box>
  );
};

export default DashboardEditor;
