import Model from "models";

class SSAgentSkillModel extends Model<AgentSkill> {
  constructor() {
    super("/ss-agent-skill", "gui-fusion");
  }
}

const model = new SSAgentSkillModel();

export default model;
