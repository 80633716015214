import AnimationLayout, { Config } from "layouts/AnimationLayout";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { getSearchParams } from "utils";
import { v4 } from "uuid";
import AddShortCodeForm, {
  AddShortCodeFormType,
} from "views/app-view/sql-query/AddShortCodeForm";
import ShortCodesListing from "views/app-view/sql-query/ShortCodesListing";

type RightSideBarProps = {
  shortCodes: ShortCode[];
};

const RightSideBar: FC<RightSideBarProps> = (props) => {
  const { shortCodes } = props;

  const { querySlug } = useParams<{ querySlug: string }>();

  const { mutateAsync: updateSqlQuery } = useUpdateItem({
    modelName: ApiModels.SqlQuery,
  });

  const [selectedShortCode, setSelectedShortCode] = useState<ShortCode>();

  const handleShortCodeSubmit = useCallback(
    async (data: AddShortCodeFormType) => {
      if (querySlug) {
        if (selectedShortCode) {
          await updateSqlQuery({
            slug: querySlug,
            data: {
              short_codes: shortCodes.map((code) =>
                code.slug === selectedShortCode.slug
                  ? { ...code, ...data }
                  : code
              ),
            },
          }).catch(() => {});
        } else {
          await updateSqlQuery({
            slug: querySlug,
            data: {
              short_codes: [...shortCodes, { ...data, slug: v4() }],
            },
          }).catch(() => {});
        }
      }
    },
    [selectedShortCode, querySlug, shortCodes, updateSqlQuery]
  );

  const getComponents: Config["getComponents"] = useCallback(
    (gotoComponent, goBack) => {
      return {
        main: (
          <ShortCodesListing
            onAdd={() => {
              gotoComponent({ name: "add-short-code", id: "add-short-code" });
            }}
            onFieldClick={(code) => {
              setSelectedShortCode(code);
              gotoComponent({ name: "add-short-code", id: code.slug });
            }}
            shortCodes={shortCodes}
          />
        ),
        "add-short-code": (
          <AddShortCodeForm
            shortCode={selectedShortCode}
            onBackClick={() => {
              goBack();
            }}
            onSubmit={async (data) => {
              await handleShortCodeSubmit(data);
              goBack();
            }}
          />
        ),
      };
    },
    [handleShortCodeSubmit, selectedShortCode, shortCodes]
  );

  return (
    <AnimationLayout
      config={{
        getComponents,
        initialComponent: getSearchParams().get("s_name") || "main",
      }}
      urlQueryKey="s"
    />
  );
};

export default RightSideBar;
