import { useInfiniteQuery } from "@tanstack/react-query";
import Copilot from "models/Copilot";

const useThreads = (agentSlug?: string) => {
  return useInfiniteQuery({
    queryKey: ["copilot", "threads", agentSlug],
    queryFn: async ({ pageParam }) => {
      return await Copilot.listThreads({ ...pageParam, agent_slug: agentSlug });
    },
    getNextPageParam(lastPage) {
      return lastPage.cursor;
    },
    enabled: agentSlug !== undefined ? !!agentSlug : true,
  });
};

export default useThreads;
