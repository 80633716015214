import {
  ArrowDropDown,
  ArrowDropUp,
  DescriptionOutlined,
  ErrorOutlineOutlined,
  ImageOutlined,
  InsertDriveFileOutlined,
  VideoFileOutlined,
} from "@mui/icons-material";
import Delete from "@mui/icons-material/Delete";
import FileUpload from "@mui/icons-material/FileUpload";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import Visibility from "@mui/icons-material/Visibility";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Avatar,
  Box,
  Button,
  Card,
  IconButton,
  Stack,
  styled,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Upload } from "assets/icons";
import accept from "attr-accept";
import axios, { AxiosProgressEvent, CancelTokenSource } from "axios";
import ActionMenu from "components/ActionMenu";
import Cropper, { CropperData } from "components/Cropper";
import Spin from "components/Spin";
import { MEDIA_BUCKET, S3_CLOUD_FRONT_URL } from "configs/AppConfig";
import { CropperType } from "enums/gui";
import { AnimatePresence, motion } from "framer-motion";
import useOpenClose from "hooks/useOpenClose";
import { isArray, last } from "lodash";
import DatasetModel from "models/Dataset";
import S3Model from "models/S3";
import { useSnackbar } from "notistack";
import { queryClient } from "queries";
import { ApiModels } from "queries/apiModelMapping";
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import { useStore } from "store";
import { useDatasetStore } from "store/stores/dataset";
import { v4 } from "uuid";
import Lightbox, { Slide } from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import { RcBox } from "./FileUploader.style";
import UploadedItems, { UploadedItemsProps } from "./UploadedItems";

const CancelTokens = new Map<string, CancelTokenSource>();

type FileUploaderProps = {
  title?: string;
  extra?: {
    icon?: React.ReactElement | ReactNode;
    subtitle?: string | ReactElement;
    description?: string | ReactElement;
  };
  sxProps?: SxProps;
  uploadPathPrefix?: string;
} & DropzoneOptions &
  (
    | {
        single: true;
        files?: FileMeta;
        onChange?: (_: FileMeta) => void;
      }
    | { single?: false; files?: FileMeta[]; onChange?: (_: FileMeta[]) => void }
  );

const FileUploaderWrap = styled(Card)(({ theme }) => ({
  background: "none !important",
}));

const ViewButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: "14px",
  color: theme.palette.background.GF60,
  height: "40px",
  borderColor: theme.palette.action.selected,

  "&:hover": {
    color: theme.palette.background.GF80,
    borderColor: theme.palette.background.GF60,
    background: "none",
  },

  ".MuiButton-startIcon": {
    marginRight: "6px",
    width: "24px",

    svg: {
      width: "100%",
      height: "auto",
    },
  },
}));

const pageSize = 5;

export function isImage(file: { name?: string; type?: string }): boolean {
  // If type is available, use the accept function
  if (file.type) {
    return accept({ name: file.name, type: file.type }, "image/*");
  }

  // If type is not available, check based on file extension
  if (file.name) {
    const ext = file.name.split(".").pop()?.toLowerCase();
    return ["jpg", "jpeg", "png", "gif"].includes(ext || ""); // Add other allowed extensions if needed
  }

  return false;
}

function isVideo(file: { name?: string; type?: string }): boolean {
  // If type is available, use the accept function
  if (file.type) {
    return accept({ name: file.name, type: file.type }, "video/*");
  }

  // If type is not available, check based on file extension
  if (file.name) {
    const ext = file.name.split(".").pop()?.toLowerCase();
    return ["mp4", "avi", "mov", "mkv", "flv", "wmv"].includes(ext || ""); // Add other allowed video extensions if needed
  }

  return false;
}
function isAudio(file: { name?: string; type?: string }): boolean {
  // If type is available, use the accept function
  if (file.type) {
    return accept({ name: file.name, type: file.type }, "audio/*");
  }

  // If type is not available, check based on file extension
  if (file.name) {
    const ext = file.name.split(".").pop()?.toLowerCase();
    return ["mp3", "wav", "m4a", "ogg", "flac", "aac"].includes(ext || ""); // Add other allowed audio extensions if needed
  }

  return false;
}

export const CropDatasetImage = ({
  datasetSlug,
  field,
  metadeta,
  data,
  datasetDesignSlug,
  refetch,
  onClose,
  onRestoreHanlder,
  ...rest
}: Omit<React.ComponentProps<typeof Cropper>, "image"> & {
  data: any;
  datasetSlug: string;
  datasetDesignSlug: string;
  field: DataField;
  metadeta: { cropper_type: CropperType; width?: number; height?: number };
  refetch?: any;
  onRestoreHanlder?: (_: any) => any;
}) => {
  const [useOrignalImage, setUseOrignalImage] = useState(false);
  const { mutate: processImage, isLoading } = useMutation({
    mutationFn: async ({ data }: any) => {
      await DatasetModel.processImage(data);
    },
    onSuccess: (_) => {
      console.log("success");
    },
  });
  const imageUrl = data?.url as string;
  const orignalUrl = data?.orignal_url as string;

  const handleClose = () => {
    setUseOrignalImage(false);
    onClose?.();
  };
  const handleSave = (d: CropperData) => {
    if (datasetSlug)
      processImage(
        {
          data: {
            datasetSlug: datasetSlug,
            datasetDesignSlug: datasetDesignSlug,
            value: {
              ...(data || {}),
              url: useOrignalImage ? orignalUrl : imageUrl,
            },
            field,
            ...d,
          },
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries([ApiModels.Dataset, datasetSlug]);
            handleClose();
          },
          onError: () => {
            //TODO:HandleError
          },
        }
      );
  };
  const restoreImage = () => {
    onRestoreHanlder?.([{ ...data, url: data.orignal_url }]);
    handleClose();
    // setUseOrignalImage(true);
  };

  if (!datasetSlug) return null;

  return (
    <Cropper
      {...rest}
      onClose={handleClose}
      image={useOrignalImage ? orignalUrl : imageUrl}
      onSave={handleSave}
      isLoading={isLoading}
      crop_type={metadeta?.cropper_type as any}
      width={metadeta?.width}
      height={metadeta?.height}
      extra={
        orignalUrl ? (
          <Button onClick={() => restoreImage()}>Restore</Button>
        ) : null
      }
    />
  );
};
const FileUploader: React.FC<
  React.PropsWithChildren<FileUploaderProps> & {
    cropperProps?: {
      cropper_type: CropperType;
      width?: number;
      height?: number;
    };
    fieldId?: string;
    fieldSlug?: string;
    displayType?: string;
  }
> = ({
  title = "",
  files,
  onChange: handleChange,
  children,
  extra = {},
  single,
  sxProps = {},
  uploadPathPrefix,
  cropperProps,
  fieldId,
  fieldSlug,
  displayType,
  ...rest
}) => {
  const theme = useTheme();
  const selectedAccount = useStore((state) => state.selectedAccount);
  const accountId = selectedAccount?.slug;
  const [uploadedFiles, setUploadedFiles] = useState<FileMeta[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const filesRef = useRef(uploadedFiles);
  const [open, onOpen, onClose] = useOpenClose();
  const [currentIndex, setCurrentIndex] = useState<number>();

  // Cropper Related Fields
  const datasetDrafts = useDatasetStore.useDatasetDrafts();
  const [openCropper, onCroperOpen, onCroperClose] = useOpenClose();
  // End Cropper Related Fields

  useEffect(() => {
    if (files)
      setUploadedFiles(
        single ? (files ? (isArray(files) ? files : [files]) : []) : files || []
      );
  }, [files, single]);

  useEffect(() => {
    filesRef.current = uploadedFiles;
  }, [filesRef, uploadedFiles]);

  const onChange = (files: FileMeta[]) => {
    if (single) {
      handleChange?.(files[0]);
    } else {
      handleChange?.(files);
    }
  };
  const beforeStart = useCallback((file: File, options: FileMeta) => {
    setUploadedFiles((files) => [
      {
        name: file.name,
        type: file.type,
        status: "start",
        ...options,
        originalFile: file,
        size: file.size,
      },
      ...files,
    ]);
  }, []);

  const handleProgress = (
    progressEvent: AxiosProgressEvent,
    file: FileMeta
  ) => {
    file.uploadingProgress = Math.floor(
      (progressEvent.loaded * 100) / (progressEvent?.total || 1)
    );
    file.status = "uploading";

    setUploadedFiles((files) =>
      files.map((f) => (f.id !== file.id ? f : { ...f, ...file }))
    );
  };
  const onComplete = (file: FileMeta) => {
    file.uploadingProgress = 100;
    file.status = "completed";

    setUploadedFiles((files) =>
      files.map((f) => (f.id !== file.id ? f : { ...f, ...file }))
    );

    const newFiles = filesRef.current.map((f) =>
      f.id !== file.id ? f : { ...f, ...file }
    );
    onChange?.(newFiles);
  };
  const onError = (file: FileMeta) => {
    file.uploadingProgress = 0;
    file.status = "error";

    setUploadedFiles((files) =>
      files.map((f) => (f.id !== file.id ? f : { ...f, ...file }))
    );
  };
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        await Promise.all(
          acceptedFiles.map(async (file) => {
            const id = v4();
            const nameChunks = file.name.split(".");
            const extension = nameChunks.pop();
            const name = nameChunks.join(".");
            const filename = `${name}-${id}.${extension}`;
            const key = `${accountId}/${
              uploadPathPrefix || "uploads"
            }/${filename}`;
            beforeStart(file, { id });

            const cancelToken = axios.CancelToken.source();

            const response = await S3Model.getUploadUrl({
              key,
              bucket: MEDIA_BUCKET,
            });

            const url = response.data.url;

            if (!url) {
              throw new Error("Failed to get upload url");
            }

            axios
              .put(url, file, {
                onUploadProgress: (PE) => handleProgress(PE, { id }),
                cancelToken: cancelToken.token,
              })
              .then(() => {
                const url = `${S3_CLOUD_FRONT_URL}/${key}`;
                onComplete({
                  id,
                  url,
                  // presign_url: presignedData.cloudfront_url,
                });
              })
              .catch((er) => {
                console.error(er);
                onError({ id });
              });

            // upload(
            //   {
            //     file,
            //     filename,
            //     pathPrefix: uploadPathPrefix || undefined,
            //   },
            //   {
            //     onUploadProgress: (PE) => handleProgress(PE, { id }),
            //     cancelToken: cancelToken.token,
            //   }
            // )
            //   .then(({ presignedData }) => {
            //     const url = `${S3_CLOUD_FRONT_URL}/${accountId}/${
            //       uploadPathPrefix || "uploads"
            //     }/${key}`;
            //     onComplete({
            //       id,
            //       url,
            //       presign_url: presignedData.cloudfront_url,
            //     });
            //   })
            //   .catch((er) => {
            //     console.error(er);
            //     onError({ id });
            //   });
            CancelTokens.set(id, cancelToken);
          })
        );
      } catch (e) {
        console.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId]
  );
  const onDropRejected = useCallback(
    async (acceptedFiles: FileRejection[]) => {
      acceptedFiles.forEach((file) => {
        file.errors.forEach((error) =>
          enqueueSnackbar(error.message, { variant: "error" })
        );
      });
    },
    [accountId]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...rest,
    onDrop,
    onDropRejected,
  });

  const handleDelete = (id: string) => {
    CancelTokens.get(id)?.cancel();
    setUploadedFiles((files) => files.filter((file) => file.id !== id));

    const newFiles = filesRef.current.filter((file) => file.id !== id);
    onChange?.(newFiles);
  };
  const handleLoadMore = () => {
    setPage(page + 1);
  };
  const handlkeShowLess = () => {
    setPage(1);
  };
  const paginatedItems = useMemo(() => {
    return uploadedFiles.slice(0, page * pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, uploadedFiles]);

  const mediaSlide = useMemo(() => {
    const media: Slide[] = [];
    uploadedFiles.forEach((uf) => {
      if (isImage(uf)) {
        media.push({ type: "image", src: uf.url!, alt: uf.name });
      } else if (isVideo(uf) || isAudio(uf)) {
        media.push({
          type: "video",
          sources: [
            {
              src: uf.url!,
              type: uf.type!,
            },
          ],
        });
      }
      // => false
    });
    return media;
  }, [uploadedFiles]);

  if (displayType === "v2") {
    return (
      <Box
        border="1px solid"
        borderColor={theme.palette.background.GF20}
        borderRadius="4px"
        width="100%"
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          height="59px"
          sx={{
            background: theme.palette.text.secondary_shades?.["4p"],
            cursor: "pointer",
          }}
          {...(uploadedFiles.length > 0
            ? {
                borderBottom: "1px solid",
                borderColor: theme.palette.background.GF20,
              }
            : {})}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Stack direction="row" alignItems="center" gap={1}>
            <FileUpload fontSize="small" />
            <Typography fontSize="13px" fontWeight={400}>
              Click to browse or drop file here
            </Typography>
          </Stack>
        </Stack>
        {uploadedFiles.length > 0 && (
          <Stack direction="column" gap={1} p={2}>
            {uploadedFiles.map((file, index) => {
              return (
                <Stack
                  key={file.id}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  p={1}
                  border="1px solid"
                  borderColor={
                    file.status === "error"
                      ? "#ff0000"
                      : theme.palette.background.GF20
                  }
                  borderRadius="4px"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    gap={1}
                  >
                    <Spin
                      spinning={["uploading", "start"].includes(
                        file.status || ""
                      )}
                      iconProps={{ size: 24, sx: { color: "white" } }}
                    >
                      {file.status === "error" ? (
                        <Avatar
                          variant="rounded"
                          sx={{
                            background:
                              theme.palette.text.secondary_shades?.["4p"],
                          }}
                        >
                          <ErrorOutlineOutlined sx={{ color: "#ff0000" }} />
                        </Avatar>
                      ) : file.status === "completed" || !file.status ? (
                        <Avatar src={file.url} variant="rounded">
                          <FileUpload />
                        </Avatar>
                      ) : file.originalFile ? (
                        <Avatar
                          src={URL.createObjectURL(file.originalFile)}
                          variant="rounded"
                        />
                      ) : (
                        <Avatar
                          variant="rounded"
                          sx={{
                            background:
                              theme.palette.text.secondary_shades?.["4p"],
                          }}
                        >
                          <FileUpload />
                        </Avatar>
                      )}
                    </Spin>
                    <Stack
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="center"
                    >
                      <Typography
                        fontSize="15px"
                        fontWeight={400}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        maxWidth="300px"
                      >
                        {file.name}
                      </Typography>
                      <Typography
                        fontSize="12px"
                        fontWeight={400}
                        color="text.secondary"
                      >
                        {file.status === "error"
                          ? "Failed to upload"
                          : ((file.size || 1000) / 1000)
                              .toString()
                              .concat("KB")}
                      </Typography>
                    </Stack>
                  </Stack>
                  <ActionMenu
                    menuItems={[
                      {
                        label: "View",
                        value: "view",
                        icon: <Visibility fontSize="small" />,
                        disabled: file.status !== "completed",
                      },
                      {
                        label: "Remove",
                        value: "remove",
                        icon: <Delete fontSize="small" />,
                      },
                    ]}
                    onItemClick={(value) => {
                      if (value === "remove") {
                        handleDelete(file.id);
                      } else if (value === "view") {
                        setCurrentIndex(index);
                        onOpen();
                      }
                    }}
                  >
                    <IconButton size="small">
                      <MoreHoriz fontSize="small" />
                    </IconButton>
                  </ActionMenu>
                </Stack>
              );
            })}
          </Stack>
        )}
      </Box>
    );
  }
  return (
    <FileUploaderWrap
      sx={{
        background: theme.palette.background.GFRightNavBackground,
        padding: "20px",
        ...sxProps,
      }}
    >
      <Stack direction={"column"} spacing={2}>
        {title && (
          <Typography component="div" variant="subtitle1">
            {title}
          </Typography>
        )}
        <Stack spacing={2.5}>
          <RcBox
            className={`file-picker-box ${isDragActive ? "dragging" : ""}`}
          >
            <Stack
              className="uploader-box"
              // divider={<Divider orientation="vertical" flexItem />}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {children || (
                <React.Fragment>
                  <IconButton
                    color="inherit"
                    aria-label="upload picture"
                    component="label"
                    disableRipple
                    className="btn-holder"
                  >
                    {extra.icon || <Upload />}
                  </IconButton>

                  <Typography
                    component="div"
                    variant="subtitle1"
                    className="heading"
                  >
                    {extra.subtitle || (
                      <React.Fragment>
                        Click to browse or drop here
                      </React.Fragment>
                    )}
                  </Typography>
                  <Typography
                    component="div"
                    variant="body2"
                    className="description-text"
                  >
                    {extra.description || (
                      <React.Fragment>
                        jpg, png, svg or gif{" "}
                        {rest.maxSize ? (
                          <React.Fragment>
                            (max {rest.maxSize / (1024 * 1024)}
                            mb)
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    )}
                  </Typography>
                </React.Fragment>
              )}
            </Stack>
          </RcBox>
          {paginatedItems.length > 0 && (
            <Stack spacing={1.25}>
              <AnimatePresence>
                {paginatedItems.map((file, index) => {
                  let leftIcon = <InsertDriveFileOutlined />;
                  if (isImage(file)) {
                    if (file.url) {
                      leftIcon = (
                        <img
                          src={file.presign_url || file.url}
                          alt="thumbnail"
                        />
                      );
                    } else {
                      leftIcon = <ImageOutlined />;
                    }
                  } else if (isVideo(file)) {
                    leftIcon = <VideoFileOutlined />;
                  } else if (
                    accept({ name: file.name || file.url, type: file.type }, [
                      ".pdf",
                      ".doc",
                      ".docx",
                      ".txt",
                      ".rtf",
                      ".odt",
                      ".wpd",
                      ".ppt",
                      ".pptx",
                      ".xls",
                      ".xlsx",
                      ".csv",
                      ".ods",
                      ".xml",
                      ".html",
                      ".md",
                    ])
                  ) {
                    leftIcon = <DescriptionOutlined />;
                  }

                  return (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      //exit={{ opacity: 0 }}
                      transition={{
                        duration: 0.6,
                        type: "tween",
                        ease: "linear",
                      }}
                      key={file.id}
                    >
                      <UploadedItems
                        key={file.id}
                        id={file.id}
                        name={file.name}
                        url={file.presign_url || file.url}
                        type={file.type}
                        leftIcon={leftIcon}
                        size={((file.size || 1000) / 1000)
                          .toString()
                          .concat("KB")}
                        options={{
                          edit: false,
                          progress: file.status === "uploading",
                          view: isImage(file) || isAudio(file) || isVideo(file),
                          crop:
                            cropperProps?.cropper_type &&
                            cropperProps?.cropper_type !== CropperType.None,
                        }}
                        onDelete={handleDelete}
                        progress={file.uploadingProgress}
                        status={file.status as UploadedItemsProps["status"]}
                        onView={(id: string | undefined) => {
                          setCurrentIndex(index);
                          onOpen();
                        }}
                        onCrop={(id: string) => {
                          // setField(file.field);
                          // setDatasetRecord(file.dataset);
                          onCroperOpen();
                        }}
                      />
                    </motion.div>
                  );
                })}
              </AnimatePresence>
              {paginatedItems.length < uploadedFiles.length && (
                <ViewButton
                  fullWidth
                  variant="outlined"
                  size="large"
                  disableRipple
                  startIcon={<ArrowDropDown />}
                  onClick={handleLoadMore}
                >
                  View more
                </ViewButton>
              )}
              {page > 1 && paginatedItems.length === uploadedFiles.length && (
                <ViewButton
                  fullWidth
                  variant="outlined"
                  size="large"
                  disableRipple
                  startIcon={<ArrowDropUp />}
                  onClick={handlkeShowLess}
                >
                  View Less
                </ViewButton>
              )}
            </Stack>
          )}
          {mediaSlide.length > 0 && (
            <Lightbox
              index={currentIndex}
              plugins={[Video]}
              open={open}
              close={onClose}
              slides={mediaSlide}
              carousel={{ finite: true }}
            />
          )}

          {cropperProps?.cropper_type &&
            cropperProps.cropper_type !== CropperType.None &&
            fieldSlug && (
              <CropDatasetImage
                open={openCropper}
                datasetSlug={last(datasetDrafts)?.data?.slug! as string}
                datasetDesignSlug={
                  last(datasetDrafts)?.data?.dataset_type_slug! as string
                }
                data={files}
                onClose={onCroperClose}
                field={{ slug: fieldSlug, id: fieldId } as any}
                metadeta={cropperProps as any}
                onRestoreHanlder={onChange}
                // refetch={refetch}
              />
            )}
        </Stack>
      </Stack>
    </FileUploaderWrap>
  );
};
export default FileUploader;
