import InsertDriveFileOutlined from "@mui/icons-material/InsertDriveFileOutlined";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import TaskAltOutlined from "@mui/icons-material/TaskAltOutlined";
import { Avatar, Chip, Stack, Typography, styled } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { FC } from "react";

dayjs.extend(utc);

const Container = styled(Stack)({
  padding: "16px",
  borderBottom: "1px solid #EDEDED",
});

type ChatSessionItemProps = {
  data: CopilotThread;
  onClick(): void;
};

const ChatSessionItem: FC<ChatSessionItemProps> = (props) => {
  const { data, onClick } = props;

  return (
    <Container gap={1} onClick={onClick}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1}>
          <Avatar
            src="https://mui.com/static/images/avatar/1.jpg"
            variant="rounded"
            sx={{ width: "32px", height: "32px" }}
          />
          <Typography fontWeight={500} fontSize={14}>
            {dayjs.utc(data.created_at).format("MMM DD, YYYY @ h:mm A")}{" "}
            {/* Oct 10, 2024 @ 8:20 PM */}
          </Typography>
        </Stack>
        <MoreHoriz sx={{ color: "#8d8c8e" }} />
      </Stack>
      <Typography
        overflow="hidden"
        textOverflow="clip"
        whiteSpace="nowrap"
        sx={{
          position: "relative",
          ":before": {
            content: "''",
            position: "absolute",
            right: 0,
            top: 0,
            width: "48px",
            height: "24px",
            background:
              "linear-gradient(270deg, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 100%)",
          },
        }}
      >
        {data.summary}
      </Typography>
      <Stack direction="row" alignItems="center" gap={1}>
        <Chip
          sx={{ color: "#000000", background: "#EDEDED", borderRadius: "4px" }}
          icon={
            <InsertDriveFileOutlined
              sx={{ color: "#000000 !important", fontSize: "16px !important" }}
            />
          }
          label="12 Attachments"
          size="small"
        />
        <Chip
          sx={{ color: "#000000", background: "#EDEDED", borderRadius: "4px" }}
          icon={
            <TaskAltOutlined
              sx={{ color: "#000000 !important", fontSize: "16px !important" }}
            />
          }
          label="4 Tasks"
          size="small"
        />
      </Stack>
    </Container>
  );
};

export default ChatSessionItem;
