import { NoteAddOutlined } from "@mui/icons-material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import Scrollbar from "components/Scrollbar";
import { FC, useMemo, useState } from "react";
import useGuiDocumentStore from "store/stores/gui-document";
import useLexicalStore from "store/stores/lexical";

const Container = styled(Box)(({ theme }) => ({
  height: "100%",
  background: "#18181f",
  maxWidth: "260px",
  width: "100%",
  overflow: "hidden",
}));

const ListContainer = styled(Box)({
  padding: "8px 16px",
});

const PageItem = ({
  page,
  onPageClick,
}: {
  page: {
    id: string;
    s3_key: string;
    title?: string | undefined;
  };
  onPageClick(id: string): void;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const selectedPageId = useGuiDocumentStore.useSelectedPageId();
  const pageHeadings = useGuiDocumentStore.usePageHeadings();
  const scrollToHeading = useLexicalStore.useScrollToHeading();

  const currentPageHeadings = useMemo(
    () => (page ? pageHeadings[page.id] ?? [] : []),
    [pageHeadings, page]
  );

  return (
    <Collapse in={isCollapsed} collapsedSize={22}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          key={page.id}
          fontSize={15}
          fontWeight={600}
          lineHeight="22px"
          color={selectedPageId === page.id ? "#ffffff" : "#a3a3a5"}
          sx={{ cursor: "pointer" }}
          onClick={() => onPageClick(page.id)}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          maxWidth="150px"
        >
          {page.title}
        </Typography>
        {currentPageHeadings.length > 0 && (
          <IconButton
            sx={{ p: 0 }}
            onClick={() => setIsCollapsed((prev) => !prev)}
          >
            <ArrowDropDown
              sx={{ transform: isCollapsed ? "rotate(0deg)" : "rotate(90deg)" }}
            />
          </IconButton>
        )}
      </Stack>
      <Stack
        direction="column"
        gap={1}
        sx={{ pl: 2, borderLeft: "1px solid #46464c" }}
      >
        {currentPageHeadings.map((heading, index) => (
          <Typography
            key={`heading-${heading}-${index}`}
            fontSize={15}
            fontWeight={600}
            lineHeight="22px"
            color={selectedPageId === page.id ? "#ffffff" : "#a3a3a5"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              scrollToHeading?.(heading.key);
            }}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            maxWidth="150px"
          >
            {heading.text}
          </Typography>
        ))}
      </Stack>
    </Collapse>
  );
};

type DocNavigationProps = {
  guiDocument?: GuiDocument;
};

const DocNavigation: FC<DocNavigationProps> = (props) => {
  const { guiDocument } = props;
  const setIsOpen = useGuiDocumentStore.useSetIsAddPageModalOpen();
  const setSelectedPageId = useGuiDocumentStore.useSetSelectedPageId();

  const handlePageClick = (id: string) => {
    setSelectedPageId(id);
  };

  return (
    <Container>
      <Scrollbar>
        <ListContainer>
          <Stack direction="column" gap={2}>
            {guiDocument?.pages?.map((p) => (
              <PageItem page={p} onPageClick={handlePageClick} />
            ))}
          </Stack>
          <Button
            startIcon={<NoteAddOutlined />}
            onClick={() => setIsOpen(true)}
            variant="text"
            color="inherit"
            sx={{ p: 0, mt: 2, height: "auto" }}
          >
            New Page
          </Button>
        </ListContainer>
      </Scrollbar>
    </Container>
  );
};

export default DocNavigation;
