import ChatBubbleOutlineOutlined from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { Box, Stack, styled } from "@mui/material";
import { FC, useEffect } from "react";
import { useSystemLayoutStore } from "store/stores/systemLayout";
import LeftBar from "./LeftBar";
import MiddleSection from "./MiddleSection";
import RightBar from "./RightBar";

const Container = styled(Box)({
  height: "100%",
});

type AgentChatProps = {};

const AgentChat: FC<AgentChatProps> = () => {
  const setTopBarProps = useSystemLayoutStore.useSetTopBarProps();

  useEffect(() => {
    setTopBarProps({
      title: "ChattyStack",
      icon: <ChatBubbleOutlineOutlined />,
    });
  }, [setTopBarProps]);

  return (
    <Container>
      <Stack direction="row" sx={{ height: "100%" }}>
        <LeftBar />
        <MiddleSection />
        <RightBar />
      </Stack>
    </Container>
  );
};

export default AgentChat;
