import { Slider, Typography, useTheme } from "@mui/material";
import { CustomCellEditorProps } from "ag-grid-react";
import Cell from "components/DatasetTable/Cell";
import { FC, memo } from "react";

const ProgressCellEditor: FC<CustomCellEditorProps> = memo((props) => {
  const theme = useTheme();

  return (
    <Cell
      isLoading={!props.data}
      sx={{
        paddingX: 2,
        justifyContent: "space-between",
        gap: 2,
        background: theme.palette.background.TableBody,
        color: theme.palette.text.primary,
      }}
    >
      <Slider
        sx={{ width: "100%" }}
        value={props.value}
        onChange={(_, value) => {
          props.onValueChange(value);
        }}
      />
      <Typography variant="body2">% {props.value}</Typography>
    </Cell>
  );
});

export default ProgressCellEditor;
