import { ColDef } from "ag-grid-community";
import { NumericTypes } from "constants/index";

const getNumberColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => {
  const defs: ColDef = {
    ...defaults,
    cellEditor: "agNumberCellEditor",
    filter: options?.disableColumnFilters ? false : "agNumberColumnFilter",
    cellEditorParams: {
      showStepperButtons: true,
    },
  };

  if (
    [NumericTypes.DECIMAL, NumericTypes.DOUBLE, NumericTypes.FLOAT].includes(
      field.number_type ?? ""
    )
  ) {
    defs.cellEditorParams = {
      precision: 2,
      step: 0.25,
    };
  }

  return defs;
};

export default getNumberColDef;
