import { zodResolver } from "@hookform/resolvers/zod";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { LoadingButton } from "@mui/lab";
import { Button, Divider, Stack, styled } from "@mui/material";
import FormField, { FormFieldProps } from "components/FormField";
import PopupFieldWithForm from "components/FormField/PopupField";
import SwitchBar from "components/SwitchBar";
import { DocumentElementType } from "enums/Form";
import useAccountSlug from "hooks/useAccountSlug";
import useAppNavigate from "hooks/useAppNavigate";
import { ApiModels } from "queries/apiModelMapping";
import useDeleteItem from "queries/useDeleteItem";
import useUpdateItem from "queries/useUpdateItem";
import { FC, RefObject, useEffect, useRef } from "react";
import { Control, FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { confirmDelete } from "utils/confirmDelete";
import { z } from "zod";

const Container = styled(Stack)({
  maxWidth: "600px",
  marginBottom: "16px",
  // overflow: "auto",
});

type PopupFormFieldProps = {
  commonFieldProps?: Partial<Omit<FormFieldProps, "formControl">>;
  triggerFieldProps?: Partial<Omit<FormFieldProps, "formControl">>;
  contentFieldProps?: Partial<Omit<FormFieldProps, "formControl">>;
  formControl: {
    control: Control<any>;
    name: string;
  };
  button: RefObject<HTMLButtonElement>;
  disabled?: boolean;
};

export const PopupFormField: FC<PopupFormFieldProps> = (props) => {
  const {
    formControl,
    button,
    commonFieldProps = {},
    triggerFieldProps = {},
    contentFieldProps = {},
    disabled,
  } = props;

  return (
    <PopupFieldWithForm
      trigger={
        <FormField
          {...commonFieldProps}
          {...triggerFieldProps}
          formControl={formControl}
        />
      }
      name={formControl.name}
      onSubmit={() => button.current?.click()}
      popoverProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      }}
      disabled={disabled}
    >
      <FormField
        {...commonFieldProps}
        {...contentFieldProps}
        formControl={formControl}
      />
    </PopupFieldWithForm>
  );
};

const formSchema = z.object({
  agent_name: z.string(),
  agent_slug: z.string(),
  description: z.string().optional(),
  image: z
    .object({
      name: z.string(),
      url: z.string(),
      size: z.number(),
      type: z.string(),
    })
    .optional(),
  instructions: z.string().optional(),
  machine_description: z.string().optional(),
  enable_prompt_fusion: z.boolean().default(false),
  enable_preprocess_fusion: z.boolean().default(false),
  is_active: z.number(),
});

type AgentFormType = z.infer<typeof formSchema>;

type AgentSettingsProps = {
  agent?: Agent;
};

const AgentSettings: FC<AgentSettingsProps> = (props) => {
  const { agent } = props;

  const accountSlug = useAccountSlug();
  const navigate = useAppNavigate();

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const { mutateAsync: updateAgent } = useUpdateItem({
    modelName: ApiModels.SSAgent,
  });
  const { mutateAsync: deleteAgent, isLoading: isDeleting } = useDeleteItem({
    modelName: ApiModels.SSAgent,
  });

  const form = useForm<AgentFormType>({
    resolver: zodResolver(formSchema),
  });

  const { reset, control, handleSubmit } = form;

  useEffect(() => {
    if (agent) {
      reset(agent);
    }
  }, [agent, reset]);

  const onSubmit = async (data: AgentFormType) => {
    if (!agent) {
      return;
    }

    await updateAgent({ slug: agent.slug, data });
  };

  const handleDelete = () => {
    confirmDelete().then(async (result) => {
      if (!result.isConfirmed) return;

      if (agent) {
        await deleteAgent({ slug: agent.slug });
        navigate("/ss-agent");
      }
    });
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit, (error) => {
          console.log(error);
        })}
      >
        <Container gap={2}>
          <PopupFormField
            commonFieldProps={{
              label: "Agent Name",
              type: DocumentElementType.TextField,
            }}
            triggerFieldProps={{
              fieldExtras: { field: {}, disabled: true },
            }}
            formControl={{ control, name: "agent_name" }}
            button={submitButtonRef}
          />
          <PopupFormField
            commonFieldProps={{
              label: "Agent Description",
              type: DocumentElementType.TextArea,
            }}
            triggerFieldProps={{
              fieldExtras: { field: {}, disabled: true },
            }}
            formControl={{ control, name: "description" }}
            button={submitButtonRef}
          />
          <PopupFormField
            commonFieldProps={{
              label: "Agent Slug",
              type: DocumentElementType.TextField,
            }}
            triggerFieldProps={{
              fieldExtras: { field: {}, disabled: true },
            }}
            formControl={{ control, name: "agent_slug" }}
            button={submitButtonRef}
            disabled
          />
          <FormField
            label="Image File"
            type={DocumentElementType.Image}
            formControl={{ name: "image", control }}
            fieldExtras={{ field: { display_type: "v2" } }}
            onChange={(value) => {
              console.log(value);
            }}
          />
          <Divider />
          <PopupFormField
            commonFieldProps={{
              label: "Instructions For Agent",
              type: DocumentElementType.TextArea,
            }}
            triggerFieldProps={{
              fieldExtras: { field: {}, disabled: true },
            }}
            formControl={{ control, name: "instructions" }}
            button={submitButtonRef}
          />
          <PopupFormField
            commonFieldProps={{
              label: "Machine Description",
              type: DocumentElementType.TextArea,
            }}
            triggerFieldProps={{
              fieldExtras: { field: {}, disabled: true },
            }}
            formControl={{ control, name: "machine_description" }}
            button={submitButtonRef}
          />
          <Divider />
          <FormField formControl={{ name: "enable_prompt_fusion", control }}>
            {({ value, onChange }) => (
              <Stack direction="row" alignItems="center" gap={1}>
                <SwitchBar
                  sx={{ flex: 1 }}
                  value={value as boolean}
                  onChange={(v) => {
                    onChange?.(v);
                    setTimeout(() => {
                      submitButtonRef.current?.click();
                    }, Infinity - 1);
                  }}
                  label="Enable Init Fusion"
                />
                <Link
                  to={
                    agent?.prompt_fusion
                      ? `/${accountSlug}/fusion/flow-designer/${agent.prompt_fusion}`
                      : "#"
                  }
                  target="_blank"
                >
                  <Button
                    sx={{
                      width: "148px",
                      color: "#ffffff",
                      fontWeight: 400,
                      fontSize: "15px",
                    }}
                    variant="contained"
                  >
                    Link to Fusion
                  </Button>
                </Link>
              </Stack>
            )}
          </FormField>
          <FormField
            formControl={{ name: "enable_preprocess_fusion", control }}
          >
            {({ value, onChange }) => (
              <Stack direction="row" alignItems="center" gap={1}>
                <SwitchBar
                  sx={{ flex: 1 }}
                  value={value as boolean}
                  onChange={(v) => {
                    onChange?.(v);
                    setTimeout(() => {
                      submitButtonRef.current?.click();
                    }, Infinity - 1);
                  }}
                  label="Enable Preprocess Fusion"
                />
                <Link
                  to={
                    agent?.preprocess_fusion
                      ? `/${accountSlug}/fusion/flow-designer/${agent.preprocess_fusion}`
                      : "#"
                  }
                  target="_blank"
                >
                  <Button
                    sx={{
                      width: "148px",
                      color: "#ffffff",
                      fontWeight: 400,
                      fontSize: "15px",
                    }}
                    variant="contained"
                  >
                    Link to Fusion
                  </Button>
                </Link>
              </Stack>
            )}
          </FormField>
          <FormField formControl={{ name: "is_active", control }}>
            {({ value, onChange }) => (
              <SwitchBar
                label="Agent is Active"
                value={value as boolean}
                onChange={(v) => {
                  onChange?.(v ? 1 : 0);
                  setTimeout(() => {
                    submitButtonRef.current?.click();
                  }, Infinity - 1);
                }}
              />
            )}
          </FormField>
          <LoadingButton
            variant="outlined"
            color="error"
            startIcon={<DeleteOutline />}
            onClick={handleDelete}
            loading={isDeleting}
          >
            Delete Agent
          </LoadingButton>
        </Container>
        <Button ref={submitButtonRef} type="submit" sx={{ display: "none" }} />
      </form>
    </FormProvider>
  );
};

export default AgentSettings;
