import { Avatar, Box, Typography, styled } from "@mui/material";
import Scrollbar from "components/Scrollbar";
import TabStyled from "components/TabStyled";
import useAccountSlug from "hooks/useAccountSlug";
import useAppNavigate from "hooks/useAppNavigate";
import InnerPageLayout from "layouts/inner-app-layout";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useSystemLayoutStore from "store";
import { getNameInitial } from "utils";
import AddAgentModal from "./AddAgentModal";
import TabContent from "./TabContent";

const Container = styled(Box)({
  height: "100%",

  ".heading-icon": {
    marginRight: 0,
  },

  ".heading-card": {
    paddingBottom: "0 !important",
  },

  ".layout-container": {
    padding: 0,

    ".header-container": {
      paddingTop: "40px",
      paddingLeft: "40px",
    },

    ".tabs-container": {
      paddingLeft: "40px",
      paddingRight: "40px",
    },

    ".center-box": {
      paddingLeft: "40px",
      paddingRight: "40px",
      height: "calc(100vh - 144px)",
    },
  },
});

const AddModal = () => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  useSystemLayoutStore.useSetButtonProps()({
    onClick: () => setAddModalOpen(true),
  });

  return (
    <AddAgentModal
      open={addModalOpen}
      onClose={() => setAddModalOpen(false)}
      onSubmit={(data) => {}}
    />
  );
};

const Agent: React.FC = () => {
  const { agentSlug } = useParams<{ agentSlug: string }>();
  const appNavigate = useAppNavigate();
  const accountSlug = useAccountSlug();
  const [searchParams] = useSearchParams();

  const setMenu = useSystemLayoutStore.useSetMenu();
  const menuItemProps = useSystemLayoutStore.useSetItemProps();
  const setDisableFolders = useSystemLayoutStore.useSetDisableFolders();
  const setButtonOptions = useSystemLayoutStore.useSetButtonOptions();
  const setShowActionButtons = useSystemLayoutStore.useSetShowActionButtons();
  const setEnableSideNavSearch =
    useSystemLayoutStore.useSetEnableSideNavSearch();
  const setShowSideNavTopSelector =
    useSystemLayoutStore.useSetShowSideNavTopSelector();

  const [value, setValue] = useState(Number(searchParams.get("t")) || 0);

  const { data: agents = [] } = useListItems({ modelName: ApiModels.Agent });

  useEffect(() => {
    setDisableFolders(true);
    setShowSideNavTopSelector(false);
    setShowActionButtons(true);
    setButtonOptions({ addFolder: false, addItem: true });
    setEnableSideNavSearch(true);
  }, [
    setDisableFolders,
    setShowActionButtons,
    setShowSideNavTopSelector,
    setButtonOptions,
    setEnableSideNavSearch,
  ]);

  useEffect(() => {
    menuItemProps({
      onClick: (item) => {
        appNavigate(`/agent/${item.key}`);
      },
      isActive: (item) => item.key === agentSlug,
    });
  }, [agentSlug, accountSlug, menuItemProps, appNavigate]);

  useEffect(() => {
    setMenu(
      agents.map((agent) => ({
        key: agent.slug,
        title: agent.agent_name,
        icon: agent.image?.url,
      }))
    );

    if (!agentSlug && agents.length > 0) {
      appNavigate(`/agent/${agents[0].slug}`);
    }
  }, [agentSlug, agents, appNavigate, setMenu]);

  const selectedAgent = useMemo(
    () => agents.find((agent) => agent.slug === agentSlug),
    [agentSlug, agents]
  );

  return (
    <>
      {selectedAgent && (
        <Container>
          <InnerPageLayout
            sx={{ padding: "40px", height: "100%" }}
            title={
              <Typography fontSize={18} fontWeight={600}>
                {selectedAgent.agent_name}
              </Typography>
            }
            icon={
              <Avatar
                src={selectedAgent.image?.url}
                sx={{ width: "24px", height: "24px" }}
              >
                {getNameInitial(selectedAgent.agent_name)}
              </Avatar>
            }
            tabList={[
              {
                label: <TabStyled title="Settings" />,
              },
              {
                label: <TabStyled title="Skills" />,
              },
              {
                label: <TabStyled title="Documents" />,
              },
              {
                label: <TabStyled title="Access" />,
              },
              {
                label: <TabStyled title="Dataset" />,
              },
            ]}
            value={value}
            onChange={(_, tab) => setValue(tab)}
            defaultValue={0}
          >
            <Scrollbar>
              <TabContent tabIndex={value} agent={selectedAgent} />
            </Scrollbar>
          </InnerPageLayout>
        </Container>
      )}
      <AddModal />
    </>
  );
};

export default Agent;
