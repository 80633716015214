import { Rating, useTheme } from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";
import Cell from "components/DatasetTable/Cell";
import { FC, memo } from "react";

const RatingCellRenderer: FC<CustomCellRendererProps> = memo((props) => {
  const theme = useTheme();

  return (
    <Cell isLoading={!props.data}>
      <Rating
        value={props.value}
        precision={0.5}
        readOnly
        sx={{
          ".MuiRating-iconFilled": { color: theme.palette.text.primary },
          ".MuiRating-iconEmpty": { color: theme.palette.text.secondary },
        }}
      />
    </Cell>
  );
});

export default RatingCellRenderer;
