const getSingleSelectEditorDefs = (
  field: DataField,
  options?: { disableColumnFilters?: boolean }
) => ({
  cellEditor: "agSelectCellEditor",
  cellEditorParams: {
    values: (field.list_items ?? [])
      .map((value) => value.value)
      .filter(Boolean),
  },
});

export default getSingleSelectEditorDefs;
