import { zodResolver } from "@hookform/resolvers/zod";
import GridViewOutlined from "@mui/icons-material/GridViewOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, Button, DrawerProps, Stack, styled } from "@mui/material";
import FormField from "components/FormField";
import GfDrawer from "components/GfDrawer";
import { DocumentElementType } from "enums";
import { DateType } from "enums/Form";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const Container = styled(Box)({
  padding: "16px",
});

const formSchema = z.object({
  title: z.string(),
  tag: z.string().optional(),
  expiration_date: z.string().optional(),
});

type EditAgentSkillFormType = z.infer<typeof formSchema>;

type AgentDocumentEditDrawerProps = {
  agentDocument?: AgentDocument;
  onSubmitDone?(): void;
} & DrawerProps;

const AgentDocumentEditDrawer: FC<AgentDocumentEditDrawerProps> = (props) => {
  const { open, onClose, agentDocument, onSubmitDone, ...drawerProps } = props;

  const form = useForm<EditAgentSkillFormType>({
    defaultValues: agentDocument,
    resolver: zodResolver(formSchema),
  });

  const { control, handleSubmit, setValue } = form;

  const { mutateAsync: updateDocument, isLoading } = useUpdateItem({
    modelName: ApiModels.Document,
  });

  useEffect(() => {
    if (open && agentDocument) {
      setValue("title", agentDocument.title);
      setValue("tag", agentDocument.tag);
      setValue("expiration_date", agentDocument.expiration_date);
    }
  }, [open, setValue, agentDocument]);

  const onSubmit = async (data: EditAgentSkillFormType) => {
    if (agentDocument) {
      await updateDocument({
        slug: agentDocument.slug,
        data,
      });
      onClose?.({}, "escapeKeyDown");
      onSubmitDone?.();
    }
  };

  return (
    <GfDrawer
      anchor="right"
      open={open}
      width="420px"
      title="Edit Agent Skill"
      icon={<GridViewOutlined />}
      onClose={onClose}
      {...drawerProps}
    >
      <Container>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField
              label="Title"
              type={DocumentElementType.TextField}
              formControl={{ control, name: "title" }}
            />
            <FormField
              label="Tag"
              type={DocumentElementType.TextField}
              formControl={{ control, name: "tag" }}
            />
            <FormField
              label="Expiration"
              type={DocumentElementType.Date}
              formControl={{ control, name: "expiration_date" }}
              fieldExtras={{ field: { date_type: DateType.DateOnly } }}
            />
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1.5}
              sx={{ mt: 2 }}
            >
              <Button
                variant="outlined"
                color="inherit"
                sx={{
                  border: "none",
                  color: "#fff",
                  background: (theme) => theme.palette.background.GF10,
                }}
                onClick={(e) => onClose?.(e, "escapeKeyDown")}
                fullWidth
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  borderColor: "#fff",
                  boxShadow: "none",
                }}
                fullWidth
                loading={isLoading}
              >
                Save Changes
              </LoadingButton>
            </Stack>
          </form>
        </FormProvider>
      </Container>
    </GfDrawer>
  );
};

export default AgentDocumentEditDrawer;
