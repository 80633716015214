import {
  AddCircleOutline,
  DeleteOutline,
  EditOutlined,
  MoreHoriz,
} from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import { IDatasource } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionMenu from "components/ActionMenu";
import { DatasetTableWithFields } from "components/DatasetTable";
import { DateType, DocumentElementType } from "enums/Form";
import useOpenClose from "hooks/useOpenClose";
import DocumentModel from "models/Document";
import { ApiModels } from "queries/apiModelMapping";
import useDeleteItem from "queries/useDeleteItem";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import AgentDocumentEditDrawer from "views/app-view/agent/TabContent/AgentDocuments/AgentDocumentEditDrawer";
import AddAgentDocument from "./AddAgentDocument";

type AgentDocumentsProps = {
  agent: Agent;
};

const AgentDocuments: FC<AgentDocumentsProps> = (props) => {
  const { agent } = props;

  const [isDocumentModalOpen, openDocumentModal, closeDocumentModal] =
    useOpenClose();
  const [isEditorOpen, openEditor, closeEditor] = useOpenClose();

  const cursor = useRef<string>();
  const gridRef = useRef<AgGridReact>(null);

  const [selectedDocument, setSelectedDocument] = useState<AgentDocument>();

  const { mutateAsync: deleteAgentDocument } = useDeleteItem({
    modelName: ApiModels.Document,
  });

  const getDataSource = useCallback(
    (): IDatasource => ({
      async getRows(params) {
        console.log(params.context);
        const response = await DocumentModel.list({
          query: { agent_slug: agent.slug, cursor: cursor.current },
        });
        cursor.current = response.cursor as string;
        params.successCallback(
          response.data ?? [],
          !response.cursor ? params.startRow + response.data.length : undefined
        );
      },
    }),
    [agent.slug]
  );

  const datasource = useMemo<IDatasource>(() => {
    return getDataSource();
  }, [getDataSource]);

  const fields = useMemo<DataField[]>(
    () => [
      {
        id: v4(),
        slug: "title",
        title: "Document Title",
        type: DocumentElementType.TextField,
      },
      {
        id: v4(),
        slug: "file",
        title: "Document File",
        type: DocumentElementType.File,
      },
      {
        id: v4(),
        slug: "tag",
        title: "Document Tag",
        type: DocumentElementType.TextField,
      },
      {
        id: v4(),
        slug: "expiration_date",
        title: "Document Expiration",
        type: DocumentElementType.Date,
        date_type: DateType.DateOnly,
      },
    ],
    []
  );

  const handleDelete = async (slug: string) => {
    await deleteAgentDocument({ slug });
    refreshGrid();
  };

  const refreshGrid = () => {
    gridRef.current?.api.paginationGoToFirstPage();
    cursor.current = undefined;
    gridRef.current?.api.refreshInfiniteCache();
  };

  return (
    <Box sx={{ height: "400px" }}>
      <DatasetTableWithFields
        ref={gridRef}
        fields={fields}
        datasource={datasource}
        handleUpdate={() => {}}
        editable={false}
        defaultTitleColumn={false}
        suppressMovableColumns
        paginationAutoPageSize={false}
        paginationPageSizeSelector={false}
        paginationPageSize={10}
        getRowStyle={(params) => {
          return params.data ? { opacity: 1 } : { opacity: 0 };
        }}
        blockLoadDebounceMillis={500}
        autoSizeStrategy={{
          type: "fitGridWidth",
        }}
        actionsColumn={{
          enabled: true,
          getHeader: () => (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <IconButton onClick={openDocumentModal}>
                <AddCircleOutline />
              </IconButton>
            </Stack>
          ),
          getRowAction(data) {
            return (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <ActionMenu
                  menuItems={[
                    {
                      label: "Edit",
                      value: "edit",
                      icon: <EditOutlined />,
                    },
                    {
                      label: "Delete",
                      value: "delete",
                      icon: <DeleteOutline />,
                    },
                  ]}
                  containerSx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onItemClick={(value) => {
                    if (value === "delete") {
                      handleDelete(data.slug as string);
                    } else if (value === "edit") {
                      setSelectedDocument(data as AgentDocument);
                      openEditor();
                    }
                  }}
                >
                  <MoreHoriz />
                </ActionMenu>
              </Stack>
            );
          },
        }}
      />
      <AddAgentDocument
        open={isDocumentModalOpen}
        onClose={closeDocumentModal}
        agent={agent}
        onCreated={() => {
          refreshGrid();
        }}
      />
      <AgentDocumentEditDrawer
        open={isEditorOpen}
        onClose={() => {
          closeEditor();
          setSelectedDocument(undefined);
        }}
        agentDocument={selectedDocument}
        onSubmitDone={() => {
          refreshGrid();
        }}
      />
    </Box>
  );
};

export default AgentDocuments;
