import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SchemaOutlinedIcon from "@mui/icons-material/SchemaOutlined";
import { ListItem, styled } from "@mui/material";
import AppBarComp, { AppBarProps } from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import classNames from "classnames";
import SubNavMenu from "components/SubNavmenu";
import useAccountSlug from "hooks/useAccountSlug";
import useCurrentUserMenu from "hooks/useCurrentUserMenu";
// import ArrowBack from "assets/icons/ArrowBack";
import { ReactNode, useMemo } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Styles from "./style";
export type SystemAppBarProps = AppBarProps & {
  title?: ReactNode;
  DropDown?: React.ReactNode | React.ReactElement;
  leftIcon?: React.ReactNode | React.ReactElement;
  centeredComponent?: React.ReactNode | React.ReactElement;
  onLeftIconClick?: React.MouseEventHandler<HTMLButtonElement>;
  rightComponent?: React.ReactNode;
  leftMenuClasses?: string;
  navMenu?: boolean;
  showRightMenu?: boolean;
};

const AppBarWrap = styled(AppBarComp)(({ theme }) => ({
  background: theme.palette.primary.main,

 
  ".MuiToolbar-root": {
    minHeight: "48px !important",
    paddingLeft: "8px",
    paddingRight: "12px",
  },

  ".arrow-back": {
    "&:hover": {
      opacity: "0.6",
    },
  },
  ".list-item a": {
    padding: "7px",
  },
  ".MuiSvgIcon-root": {
    height: "20px",
    width: "20px",
  },
  ".full-width": {
    width: "100%",
  },

  ".menu-left": {
    ".right-column": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
      marginLeft: "0",

      ".MuiStack-root": {
        width: "100%",
        justifyContent: "space-between",
      },
    },
  },
}));

const SystemAppBar = (props: SystemAppBarProps) => {
  const {
    title = "syler.ai",
    DropDown,
    leftIcon,
    //  = (
    //   <ArrowBack
    //     sx={{
    //       width: "24px",
    //       height: "24px",
    //       padding: "4px",
    //       marginRight: "12px",
    //     }}
    //   />
    // ),
    centeredComponent,
    onLeftIconClick,
    rightComponent,
    leftMenuClasses,
    navMenu = true,
    showRightMenu = false,
    ...rest
  } = props;
  const userMenu = useCurrentUserMenu();

  const userMenuList = useMemo(() => {
    return (
      userMenu
        ?.sort((a, b) => a.sort_order - b.sort_order)
        ?.map((item) => ({
          title: item.label,
          path: `gui-module-public/${item.gui_to_link_id}`,
          icon: item.icon,
        })) || []
    );
  }, [userMenu]);

  const navigate = useNavigate();

  const selectedAccount = useAccountSlug();

  return (
    <AppBarWrap position="fixed" {...rest}>
      <Toolbar>
        <Stack
          className={classNames("full-width", leftMenuClasses)}
          direction="row"
          justifyContent="space-between"
          spacing={2}
          alignItems="center"
        >
          <Stack
            className="left-column"
            direction="row"
            alignItems="center"
            spacing={2}
          >
            {/* {leftIcon && (
              <IconButton
                className="arrow-back"
                size="small"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                disableRipple
                sx={{ p: "0" }}
                onClick={
                  onLeftIconClick ??
                  (() => {
                    selectedAccount && navigate(`/${selectedAccount}/app`);
                  })
                }
              >
                {leftIcon}
              </IconButton>
            )} */}
            {/* {title && <AlertTitle sx={{ mb: "0" }}>{title}</AlertTitle>} */}
            {/* <AccountSelector /> */}
            {navMenu && <SubNavMenu routes={userMenuList} topbar={true} />}
          </Stack>
          <Stack
            className="center-column"
            direction="row"
            display={{ xs: "none", md: "flex" }}
            justifyContent="center"
          >
            {centeredComponent}
          </Stack>
          <Stack
            className="right-column"
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            {showRightMenu && (
              <Stack direction="row" spacing={2}>
                <Styles.UserMenuListStyled>
                  <ListItem>
                    <NavLink to={`/`}>
                      <KeyboardDoubleArrowRightOutlinedIcon />
                    </NavLink>
                  </ListItem>
                  <ListItem>
                    <NavLink to={`/`}>
                      <FolderOutlinedIcon />
                    </NavLink>
                  </ListItem>
                  <ListItem>
                    <NavLink to={`/`}>
                      <PeopleAltOutlinedIcon />
                    </NavLink>
                  </ListItem>
                  <ListItem>
                    <NavLink to={`/`}>
                      <SchemaOutlinedIcon />
                    </NavLink>
                  </ListItem>
                  <ListItem>
                    <NavLink to={`/`}>
                      <AccountBoxOutlinedIcon />
                    </NavLink>
                  </ListItem>
                </Styles.UserMenuListStyled>

                <Styles.BaseListStyled>
                  <ListItem>
                    <NavLink to={`/`}>
                      <ChatOutlinedIcon />
                    </NavLink>
                  </ListItem>
                  <ListItem>
                    <NavLink to={`/`}>
                      <BookmarkBorderOutlinedIcon />
                    </NavLink>
                  </ListItem>
                  <ListItem>
                    <NavLink to={`/`}>
                      <NotificationsNoneOutlinedIcon />
                    </NavLink>
                  </ListItem>
                </Styles.BaseListStyled>
              </Stack>
            )}
            {rightComponent}
            {DropDown}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBarWrap>
  );
};

export default SystemAppBar;
