import PlaylistAdd from "@mui/icons-material/PlaylistAdd";
import { Box, Button, styled, Tooltip } from "@mui/material";
import { PlusIcon } from "assets/icons";
import React, { PropsWithChildren } from "react";
import GFHeaderWithIcons from "stories/CompoundComponent/Header/Header/Header";

const SideBarBox = styled(Box)(({ theme }) => {
  return {};
});
export const AddButton = styled(Button)(({ theme }) => ({
  color: theme.palette.background.GF80,
  padding: "6px",
  minWidth: "inherit",

  "&:hover": {
    border: theme.palette.background.GF10,
    background: theme.palette.background.GF10,
  },

  svg: {
    width: "23px",
    height: "auto",

    [`${theme.breakpoints.only("sm")},${theme.breakpoints.only("md")}`]: {
      width: "18px",
    },
  },
}));

const SidebarSection: React.FC<
  PropsWithChildren<{
    title?: string;
    rightIcon?: false | React.ReactElement;
    leftIcon?: false | React.ReactElement;
    onRightIconClick?(): void;
    onLeftIconClick?(): void;
    className?: string;
  }>
> = (props) => {
  const {
    title,
    children,
    rightIcon,
    onRightIconClick,
    leftIcon,
    onLeftIconClick,
    className,
  } = props;

  const rightIconComponent = React.useMemo(() => {
    if (rightIcon === false) {
      return <></>;
    }

    return (
      <Box sx={{ lineHeight: 0 }} onClick={onRightIconClick}>
        {rightIcon || (
          <Tooltip arrow  title="Add a field">
          <AddButton
            className="add_item_btn"
            variant="outlined"
            color="secondary"
            sx={{ borderColor: (theme) => theme.palette.background.GF10 }}
            fullWidth
          >
            <PlusIcon sx={{ color: "#ffffff" }} />
          </AddButton>
          </Tooltip>
        )}
      </Box>
    );
  }, [rightIcon, onRightIconClick]);

  const leftIconComponent = React.useMemo(() => {
    if (leftIcon === false) {
      return <></>;
    }

    return (
      <Box sx={{ lineHeight: 0 }} onClick={onLeftIconClick}>
        {leftIcon || <PlaylistAdd />}
      </Box>
    );
  }, [leftIcon, onLeftIconClick]);

  return (
    <SideBarBox className={className}>
      <GFHeaderWithIcons
        leftIcon={leftIconComponent}
        title={title}
        rightIcon={rightIconComponent}
      />
      {children}
    </SideBarBox>
  );
};

export const SidebarSectionWrap = styled(SidebarSection)(({ theme }) => {
  return {
    "&.data_set_right_side, &.associated_fields_details": {
      ".MuiTypography-root": {
        fontWeight: "400",
      },
    },
    ".MuiCard-root:hover": {
      background: `${theme.palette.background.GFRightNavForeground} !important`,

      ".edit-icon": {
        opacity: "1",
        visibility: "visible",
      },
    },

    ".record-item": {
      transition: "all 0.4s ease",

      "&:hover ": {
        background: theme.palette.background.GF20,

        ".edit-icon": {
          opacity: "1",
          visibility: "visible",
        },
      },
    },

    ".edit-icon": {
      width: "16px",
      height: "16px",
      color: theme.palette.background.GF60,
      transition: "all 0.4s ease",
      opacity: "0",
      visibility: "hidden",
      cursor: "pointer",

      "&:hover": {
        color: theme.palette.text.primary,
      },

      svg: {
        width: "100%",
        height: "auto",
        display: "block",
        color: "currentColor",
      },
    },
  };
});

export default SidebarSection;
