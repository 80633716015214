import Settings from "@mui/icons-material/Settings";
import { Button, ListItem, styled } from "@mui/material";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: "4px",
  color: theme.palette.text.secondary,
  fontWeight: 400,
  fontSize: "14px",
  borderColor: theme.palette.gfGrey.Divider,
  height: "32px",
  width: "125px",

  "&.MuiButton-outlined:hover": {
    background: theme.palette.background.GF10,
    borderColor: theme.palette.background.GF20,
  },
}));

const EditModeButton = () => {
  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();
  const setEditModeEnabled = useGuiDashboardStore.useSetEditModeEnabled();

  return (
    <ListItem sx={{ ml: 1, pr: "0px !important" }}>
      <ActionButton
        variant={editModeEnabled ? "contained" : "outlined"}
        startIcon={<Settings sx={{ width: "18px", height: "18px" }} />}
        onClick={() => setEditModeEnabled(!editModeEnabled)}
        sx={{
          fontSize: "12px",
          padding: "0px",
          ".MuiButton-startIcon": { marginRight: "4px" },
        }}
      >
        Edit Mode {editModeEnabled ? "On" : "Off"}
      </ActionButton>
    </ListItem>
  );
};

export default EditModeButton;
