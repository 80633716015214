import { ColDef } from "ag-grid-community";
import DatasetDesignCellEditor from "./DatasetDesignCellEditor";
import DatasetDesignCellRenderer from "./DatasetDesignCellRenderer";

const getDatasetDesignColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => {
  return {
    ...defaults,
    cellRenderer: DatasetDesignCellRenderer,
    cellEditor: DatasetDesignCellEditor,
  };
};

export default getDatasetDesignColDef;
