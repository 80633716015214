import Model from "models";

class JobTaskModel extends Model<JobTask> {
  constructor() {
    super("/job-task", "public-1");
  }
}

const model = new JobTaskModel();
export default model;
