import { CustomCellRendererProps } from "ag-grid-react";
import Cell from "components/DatasetTable/Cell";
import use3pApps from "queries/3p-app/use3pApps";
import { FC, memo } from "react";

const ThreePAppCellRenderer: FC<CustomCellRendererProps> = memo((props) => {
  const { data: apps = [] } = use3pApps();

  return (
    <Cell isLoading={!props.data}>
      {apps.find((app) => app.slug === props.value)?.app_label ?? ""}
    </Cell>
  );
});

export default ThreePAppCellRenderer;
