import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import {
  Avatar,
  Box,
  ImageList,
  ImageListItem,
  Link as MuiLink,
  Stack,
  styled,
} from "@mui/material";
import ChatMessageContent from "components/FloatingChatWindow/ChatWindow/ChatMessageContent";
import { S3_CLOUD_FRONT_URL } from "configs/AppConfig";
import { FC, useMemo } from "react";
import { Link } from "react-router-dom";

const Container = styled(Stack)({
  marginTop: "20px",
});

const List = styled("ul")({
  listStyle: "none",
});

const MessageBubble = styled(Box)<{ type: "agent" | "user" }>(
  ({ type, theme }) =>
    type === "user"
      ? {
          background: theme.palette.background.ChatMessageBubble ?? "#2a2c30",
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingLeft: "24px",
          paddingRight: "24px",
          borderRadius: "24px",
        }
      : {
          background: "transparent",
        }
);

type MessageItemProps = {
  type: "agent" | "user";
  message: string;
  attachments: CopilotMessage["attachments"];
  showAvatarForUser?: boolean;
};

const MessageItem: FC<MessageItemProps> = (props) => {
  const { type, message, attachments, showAvatarForUser } = props;

  const { imageFiles, dataFiles } = useMemo(() => {
    if (!attachments) {
      return { imageFiles: [], dataFiles: [] };
    }

    return attachments?.reduce<
      Required<{
        imageFiles?: CopilotMessage["attachments"];
        dataFiles?: CopilotMessage["attachments"];
      }>
    >(
      (acc, cur) => {
        const ext = cur.filename.split(".").pop()?.toLowerCase();
        const isImage = ["jpg", "jpeg", "png", "gif"].includes(ext || "");

        if (isImage) {
          acc.imageFiles.push(cur);
        } else {
          acc.dataFiles.push(cur);
        }

        return acc;
      },
      { imageFiles: [], dataFiles: [] }
    );
  }, [attachments]);

  return (
    <Container
      direction={type === "agent" ? "row" : "row-reverse"}
      justifyContent="flex-start"
      alignItems="flex-start"
      gap={2}
      id={
        type === "agent" ? "agent-chat-message-item" : "user-chat-message-item"
      }
    >
      {(type === "agent" || showAvatarForUser) && (
        <Avatar
          variant="circular"
          sx={{
            width: "32px",
            height: "32px",
            background: (theme) => theme.palette.primary.main,
            color: "#ffffff",
          }}
          id="agent-chat-message-item-avatar"
        >
          <SettingsOutlined />
        </Avatar>
      )}
      <Stack
        direction="column"
        gap={1}
        maxWidth="70%"
        id="agent-chat-message-item-content"
      >
        <MessageBubble type={type} id="agent-chat-message-item-bubble">
          <ChatMessageContent value={message} />
        </MessageBubble>
        {dataFiles.length > 0 && (
          <List id="agent-chat-message-item-list">
            {dataFiles.map((file) => (
              <li key={file.fileId}>
                <MuiLink
                  component={Link}
                  to={`${S3_CLOUD_FRONT_URL}/${file.s3Key}`}
                  target="_blank"
                >
                  {file.filename}
                </MuiLink>
              </li>
            ))}
          </List>
        )}
        {imageFiles.length > 0 && (
          <ImageList cols={2} id="agent-chat-message-item-image-list">
            {imageFiles.map((image) => (
              <ImageListItem key={image.fileId}>
                <img
                  src={`${S3_CLOUD_FRONT_URL}/${image.s3Key}`}
                  alt={image.filename}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </Stack>
    </Container>
  );
};

export default MessageItem;
