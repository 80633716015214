import { Box, Stack, styled } from "@mui/material";
import Scrollbar from "components/Scrollbar";
import Spin from "components/Spin";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import { useSystemLayoutStore } from "store/stores/systemLayout";
import { normalizeObjectForAPI } from "utils";
import DashboardEditor from "./components/DashboardEditor";
import WidgetEditor from "./components/WidgetEditor";

const CenterBox = styled(Box)({
  flexGrow: "1",
  flexBasis: "0",
  minWidth: "0",
  height: "calc(100vh - 114px)",

  ".heading-card": {
    margin: "0",
    padding: "0 0 9px !important",

    ".heading-wrap": {
      margin: "0 0 10px",
    },
  },
});

const GuiTabUpdate: React.FC = () => {
  const guiTabDraft = useGuiDashboardStore.useGuiTabDraft();
  const { mutate: updateGuiTab } = useUpdateItem({
    modelName: ApiModels.GuiTab,
  });

  useEffect(() => {
    if (guiTabDraft?.pushUpdate) {
      updateGuiTab({
        slug: guiTabDraft.slug!,
        data: normalizeObjectForAPI(guiTabDraft, ["pushUpdate"]),
      });
    }
  }, [guiTabDraft, updateGuiTab]);

  return null;
};

type GFDashboardProps = {
  guiTab: GuiTab;
  editable?: boolean;
};

const GuiDashboard: React.FC<GFDashboardProps> = (props) => {
  const { guiTab, editable = true } = props;

  const { slug } = useParams<{ slug: string }>();

  const setShowSideNavTopSelector =
    useSystemLayoutStore.useSetShowSideNavTopSelector();
  const setEnableSideNavSearch =
    useSystemLayoutStore.useSetEnableSideNavSearch();
  const setShowActionButtons = useSystemLayoutStore.useSetShowActionButtons();

  const setEditModeEnabled = useGuiDashboardStore.useSetEditModeEnabled();
  const setGuiTabDraft = useGuiDashboardStore.useSetGuiTabDraft();
  const setWidgetDraft = useGuiDashboardStore.useSetWidgetDraft();
  const isWidgetEditorOpen = useGuiDashboardStore.useIsWidgetEditorOpen();
  const openWidgetEditor = useGuiDashboardStore.useOpenWidgetEditor();
  const closeWidgetEditor = useGuiDashboardStore.useCloseWidgetEditor();

  const { data: gui } = useGetItem({ modelName: ApiModels.Gui, slug });

  useEffect(() => {
    setShowSideNavTopSelector(false);
    setEnableSideNavSearch(false);
    setShowActionButtons(false);
    // onChangeMode({ target: { value: "blue" } });
    // setAppBarProps({
    //   rightComponent: <EditModeSwitch />,
    // });
    setEditModeEnabled(editable);
  }, []);

  useEffect(() => {
    if (guiTab) {
      setGuiTabDraft(guiTab);
    }
  }, [guiTab, setGuiTabDraft]);

  // useEffect(() => {
  //   const tab = getSearchParams().get("t");
  //   if (tab) {
  //     setSelectedTab(Number(tab));
  //   } else {
  //     setSelectedTab(0);
  //   }
  // }, []);

  const handleAddWidgetClick = (rowId: string, col: number) => {
    if (gui && guiTab) {
      setWidgetDraft({
        parent_gui_id: gui?.slug,
        parent_tab_id: guiTab.id,
        row_id: rowId,
        row_column: col,
      });
      openWidgetEditor();
    }
  };

  const handleCloseWidgetEditor = () => {
    closeWidgetEditor();
    setWidgetDraft(null);
  };

  return (
    <Spin spinning={false} sx={{ height: "100%" }}>
      <GuiTabUpdate />
      <Stack direction="row">
        <CenterBox>
          <Scrollbar>
            <DashboardEditor onAddWidgetClick={handleAddWidgetClick} />
          </Scrollbar>
        </CenterBox>
        <WidgetEditor
          open={isWidgetEditorOpen}
          onClose={handleCloseWidgetEditor}
        />
      </Stack>
    </Spin>
  );
};

export default GuiDashboard;
