import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";

const useActionFields = (actionSlug?: string) => {
  return useListItems({
    modelName: ApiModels.FormDesign,
    requestOptions: { query: { actionSlug } },
    queryKey: [ApiModels.FormDesign, actionSlug],
    queryOptions: {
      enabled: !!actionSlug,
    },
  });
};

export default useActionFields;
