import { LinearProgress, Typography } from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";
import Cell from "components/DatasetTable/Cell";
import { FC, memo } from "react";

const ProgressCellRenderer: FC<CustomCellRendererProps> = memo((props) => (
  <Cell sx={{ justifyContent: "space-between", gap: 2 }} isLoading={!props.data}>
    <LinearProgress
      sx={{ width: "100%" }}
      variant="determinate"
      value={props.value}
    />
    <Typography variant="body2">% {props.value}</Typography>
  </Cell>
));

export default ProgressCellRenderer;
