import EditOutlined from "@mui/icons-material/EditOutlined";
import { IconButton, Stack, Typography } from "@mui/material";
import ActionMenu, {
  ActionMenuProps,
  ActionMenuRef,
} from "components/ActionMenu";
import Icon from "components/util-components/Icon";
import { ActionType } from "enums/gui";
import useAccountSlug from "hooks/useAccountSlug";
import useCurrentUser from "hooks/useCurrentUser";
import FusionModel from "models/Fusion";
import { useSnackbar } from "notistack";
import {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import useFusionAction from "store/stores/fusion-action";
import { v4 } from "uuid";

type FusionActionsDropdownProps = PropsWithChildren<{
  actions: FusionAction[];
  widget?: DashboardWidget;
  widgetActionType?: "dataset" | "widget";
  pathPrefix?: string;
  editable?: boolean;
  dataset?: Dataset;
  extraMenuItems?: {
    id: string;
    title: ReactNode;
    icon?: ReactNode;
    onClick?(): void;
  }[];
  onActionClick?(action: FusionAction): void;
  onEditClick?(action: FusionAction): void;
  onAddClick?(): void;
  onBeforeActionClick?(action: FusionAction): void;
}>;

const FusionActionsDropdown: FC<FusionActionsDropdownProps> = (props) => {
  const {
    actions,
    editable = false,
    dataset,
    pathPrefix = "",
    children,
    extraMenuItems = [],
    widget,
    widgetActionType,
    onEditClick,
    onBeforeActionClick,
  } = props;

  const user = useCurrentUser();
  const accountSlug = useAccountSlug();

  const { enqueueSnackbar } = useSnackbar();

  const setActionEditorOpen = useFusionAction.useSetActionEditorOpen();
  const setEditorAction = useFusionAction.useSetEditorAction();
  const setEditActionPathPrefix = useFusionAction.useSetEditActionPathPrefix();
  const setActionFormOpen = useFusionAction.useSetActionFormOpen();
  const setFormAction = useFusionAction.useSetFormAction();
  const setWidget = useFusionAction.useSetWidget();
  const setWidgetActionType = useFusionAction.useSetWidgetActionType();
  const actionData = useFusionAction.useActionData();

  const actionMenuRef = useRef<ActionMenuRef>(null);
  const addActionClickRef = useRef<(action: FusionAction) => void>();

  const handleAddClick = () => {
    if (props.onAddClick) return props.onAddClick();

    if (widget) {
      setWidget(widget);
    }
    if (widgetActionType) {
      setWidgetActionType(widgetActionType);
    }
    setEditActionPathPrefix(pathPrefix);
    setActionEditorOpen(true);
  };

  const handleEditClick = useCallback(
    (action: FusionAction) => {
      if (onEditClick) return onEditClick(action);

      if (widget) {
        setWidget(widget);
      }
      if (widgetActionType) {
        setWidgetActionType(widgetActionType);
      }
      setEditActionPathPrefix(pathPrefix);
      setEditorAction(action);
      setActionEditorOpen(true);
    },
    [
      pathPrefix,
      onEditClick,
      setActionEditorOpen,
      setEditActionPathPrefix,
      setEditorAction,
      setWidget,
      setWidgetActionType,
      widget,
      widgetActionType,
    ]
  );

  const handleActionClick = useCallback(
    (action: FusionAction) => {
      if (props.onActionClick) return props.onActionClick(action);

      if (action.action_type === ActionType.OpenForm) {
        setFormAction(action);
        setActionFormOpen(true);
      } else {
        enqueueSnackbar({
          variant: "success",
          message: "Fusion Action Called!",
        });
        FusionModel.runFusion(action.fusion_slug, {
          user_id: user.slug,
          account_id: accountSlug!,
          popup_variables: {
            popup_variables: {
              ...(dataset ?? {}),
              ...(dataset?.fields || {}),
              fields: undefined,
              data: actionData,
            },
          },
        }).catch(() => {
          enqueueSnackbar({
            variant: "error",
            message: "Fusion Action Failed!",
          });
        });
      }
    },
    [
      accountSlug,
      actionData,
      dataset,
      enqueueSnackbar,
      props,
      setActionFormOpen,
      setFormAction,
      user.slug,
    ]
  );

  useEffect(() => {
    addActionClickRef.current = handleActionClick;
  }, [handleActionClick]);

  const menuItems: ActionMenuProps["menuItems"] = useMemo(() => {
    const items: ActionMenuProps["menuItems"] = extraMenuItems.map((item) => ({
      label: item.title,
      value: item.id,
      icon: item.icon,
    }));

    if (editable) {
      if (items.length > 0) {
        items.unshift({
          type: "divider",
          value: v4(),
          label: "Divider",
        });
      }
      items.unshift({
        label: "Add New Action",
        value: "add-new-action",
        icon: <EditOutlined fontSize="small" />,
      });
    }

    if (actions.length > 0) {
      if (items.length > 0) {
        items.unshift({
          type: "divider",
          value: v4(),
          label: "Divider",
        });
      }
      items.unshift(
        ...actions.map((action) => ({
          label: (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>
                {action.action_title}{" "}
                {action.action_hotkey ? `(${action.action_hotkey})` : ""}
              </Typography>
              {editable && (
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleEditClick(action);

                    actionMenuRef.current?.onClose();
                  }}
                  sx={{
                    padding: "0px",
                    ml: 2,
                    position: "absolute",
                    top: "3px",
                    right: "16px",
                    width: "30px",
                    height: "30px",
                  }}
                >
                  <EditOutlined fontSize="small" />
                </IconButton>
              )}
            </Stack>
          ),
          value: action.id,
          icon: <Icon iconName={action.action_icon} fontSize="small" />,
        }))
      );
    }

    return items;
  }, [actions, editable, extraMenuItems, handleEditClick]);

  return (
    <ActionMenu
      ref={actionMenuRef}
      menuItems={menuItems}
      minWidth={300}
      onItemClick={(value) => {
        if (value === "add-new-action") {
          handleAddClick();
        }

        const menuItem = extraMenuItems.find((m) => m.id === value);

        if (menuItem) {
          menuItem.onClick?.();
          return;
        }

        const action = actions.find((a) => a.id === value);
        if (action) {
          onBeforeActionClick?.(action);
          setTimeout(() => {
            addActionClickRef.current?.(action);
          }, Infinity - 1);
        }
      }}
    >
      {children}
    </ActionMenu>
  );
};

export default FusionActionsDropdown;
