import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";

export const useGuiTabs = (guiSlug?: string) => {
  return useListItems({
    modelName: ApiModels.GuiTab,
    requestOptions: {
      query: {
        gui: guiSlug,
      },
    },
    queryOptions: {
      enabled: !!guiSlug,
    },
    queryKey: [ApiModels.GuiTab, guiSlug],
  });
};
