import FormField from "components/FormField";
import IconPickerField from "components/IconPicker";
import { AllIconPickerIcons } from "constants/index";
import { ParameterType } from "enums/3pApp";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { ParamField } from "views/app-view/flow-designer/components/NodeEditorFields";

type CreateGuiFieldsProps = {};

const CreateGuiFields: FC<CreateGuiFieldsProps> = (props) => {
  const { control } = useFormContext();

  return (
    <>
      <ParamField
        mappable
        field={{
          name: "name",
          label: "Workspace Name",
          type: ParameterType.Text,
        }}
      />
      <FormField
        label="Workspace Icon"
        formControl={{ control, name: "icon" }}
        collapsible
        sx={{ mb: 2 }}
      >
        {({ value, onChange }) => (
          <IconPickerField
            icons={AllIconPickerIcons}
            value={value as string}
            onChange={(iconSlug) => onChange?.(iconSlug)}
            placement="left"
            textFieldProps={{
              fullWidth: true,
              variant: "filled",
              size: "small",
              hiddenLabel: true,
            }}
          />
        )}
      </FormField>
      <ParamField
        mappable
        field={{
          name: "description",
          label: "Workspace Description",
          type: ParameterType.Text,
          multiline: true,
        }}
      />
    </>
  );
};

export default CreateGuiFields;
