import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type State = {
  editorOpen: boolean;
  editorAction: FusionAction | undefined;
  actionFormOpen: boolean;
  formAction: FusionAction | undefined;
  editActionPathPrefix: string;
  widgetActionType: "dataset" | "widget";
  widget: DashboardWidget | undefined | null;
  actionData: Record<string, unknown>;
};

type Actions = {
  setActionEditorOpen(open: boolean): void;
  setEditorAction(action?: FusionAction): void;
  setEditActionPathPrefix(prefix: string): void;
  setActionFormOpen(open: boolean): void;
  setFormAction(action?: FusionAction): void;
  setWidgetActionType(type: "dataset" | "widget"): void;
  setWidget(widget?: DashboardWidget | null): void;
  setActionData(data: Record<string, unknown>): void;
};

export const useFusionActionBase = create(
  devtools(
    immer<State & Actions>((set) => ({
      editorOpen: false,
      editorAction: undefined,
      actionFormOpen: false,
      formAction: undefined,
      editActionPathPrefix: "",
      widgetActionType: "dataset",
      widget: undefined,
      actionData: {},
      setActionData(data) {
        set((state) => {
          state.actionData = data;
        });
      },
      setWidget(widget) {
        set((state) => {
          state.widget = widget;
        });
      },
      setWidgetActionType(type) {
        set((state) => {
          state.widgetActionType = type;
        });
      },
      setActionEditorOpen(open) {
        set((state) => {
          state.editorOpen = open;
        });
      },
      setEditorAction(action) {
        set((state) => {
          state.editorAction = action;
        });
      },
      setEditActionPathPrefix(prefix) {
        set((state) => {
          state.editActionPathPrefix = prefix;
        });
      },
      setActionFormOpen(open) {
        set((state) => {
          state.actionFormOpen = open;
        });
      },
      setFormAction(action) {
        set((state) => {
          state.formAction = action;
        });
      },
    }))
  )
);

const useFusionAction = createSelectorHooks(useFusionActionBase);

export default useFusionAction;
