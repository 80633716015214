import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import { Box, LinearProgress, Stack } from "@mui/material";
import IconButtonWithTooltip from "components/IconButtonWithTooltip";
import * as React from "react";
import ProfileCard from "stories/CompoundComponent/ProfileCard/ProfileCard";
interface Props {
  progress?: number;
  title?: string;
  actions?: boolean;
  status?: FusionFlowSession["session_status"];
  onPlay?: () => void;
  onPause?: () => void;
  onStop?: () => void;
}

const RunFusion: React.FC<Props> = (props) => {
  const {
    progress,
    title,
    actions = true,
    status,
    onPause,
    onPlay,
    onStop,
  } = props;
  return (
    <Box>
      <Stack>
        <ProfileCard
          options={{ draggable: false, switcher: false }}
          title={title || "Run"}
          rightIcon={
            actions ? (
              <Stack direction={"row"} alignItems="center">
                <IconButtonWithTooltip
                  sx={{ background: "transparent" }}
                  tooltipProps={{ title: "Stop" }}
                  onClick={onStop}
                  disabled={status !== "Running" && status !== "Paused"}
                >
                  <StopCircleOutlinedIcon />
                </IconButtonWithTooltip>
                <IconButtonWithTooltip
                  sx={{ background: "transparent" }}
                  tooltipProps={{ title: "Start" }}
                  onClick={onPlay}
                  disabled={status !== "Paused"}
                >
                  <PlayCircleFilledWhiteOutlinedIcon />
                </IconButtonWithTooltip>
                <IconButtonWithTooltip
                  sx={{ background: "transparent" }}
                  tooltipProps={{ title: "Pause" }}
                  onClick={onPause}
                  disabled={status !== "Running"}
                >
                  <PauseCircleOutlineOutlinedIcon />
                </IconButtonWithTooltip>
              </Stack>
            ) : (
              <Box sx={{ height: 40 }} />
            )
          }
        />
        <LinearProgress
          color="inherit"
          variant="determinate"
          value={progress}
        />
      </Stack>
      {/* {runningTestData.map((ele) => {
        return (
          <Box sx={{ mb: 1.5 }}>
            <ProfileCard
              options={{ draggable: false, switcher: false }}
              title={`${ele.title}: ${''} ${ele.value}`}
              AvatarImage={<IconButton>{ele.icon}</IconButton>}
            />
          </Box>
        );
      })} */}
    </Box>
  );
};
export default RunFusion;
