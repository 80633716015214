import { Box, styled, useMediaQuery } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import { useQueryClient } from "@tanstack/react-query";
import ActionEditorDrawer from "components/FusionAction/ActionEditorDrawer";
import ActionFormModal from "components/FusionAction/ActionFormModal";
import SideNav from "components/layout-components/system-layout/SideNav";
import RenameModel from "components/share-components/RenameModel";
import { DocumentElementType } from "enums/Form";
import { ActionType, GuiType } from "enums/gui";
import useAccountSlug from "hooks/useAccountSlug";
import useAppNavigate from "hooks/useAppNavigate";
import useCurrentUser from "hooks/useCurrentUser";
import useQuery from "hooks/useQuery";
import AnimationLayout, {
  AnimationLayoutRef,
  Config,
  TransitionComponent,
} from "layouts/AnimationLayout";
import { isArray } from "lodash";
import last from "lodash/last";
import FusionModel from "models/Fusion";
import { ApiModels } from "queries/apiModelMapping";
import { useGuiTabs } from "queries/gui/useGuiTabs";
import useGetItem from "queries/useGetItem";
import useListInfiniteItems from "queries/useListInfiniteItems";
import React, {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import useSystemLayoutStore from "store";
import { DatasetDraft, useDatasetStore } from "store/stores/dataset";
import useGuiParamsStore from "store/stores/gui-prams";
import { useSystemLayoutBaseStore } from "store/stores/systemLayout";
import { getSearchParams } from "utils";
import ActionFormModalLegacy from "views/app-view/gui-dataset/components/ActionFormModal";
import AddDatasetModel from "./AddDatasetModal";
import EditTableFields from "./EditTableFields";
import MiddleComponent from "./MiddleComponet";
import RightSidebar from "./components/RightSidebar";
const RightSideBox = styled(Box)(({ theme }) => {
  const navStyle = (theme as any).navStyle;
  const topNavHeight = (theme as any).topNavHeight;

  let defultValue = 60;

  if (navStyle === "top") defultValue = defultValue + topNavHeight;

  return {
    width: "420px",
    height: "100%",
    padding: "160px 0 0",
    borderLeft: "1px solid #35373A",

    ".side-menu-active &": {
      height: "calc(100vh - 60px)",
    },
  };
});

const CenterBox = styled(Box)(({ theme }) => ({
  flexGrow: "1",
  flexBasis: "0",
  minWidth: "0",
  height: "100%",
  borderLeft: `1px solid rgba(53, 55, 58, 1)`,
  background: "#1e1e23",

  ".side-menu-active &": {
    height: "calc(100vh - 60px)",
  },

  ".rc-scrollbar": {
    height: "100%",
  },
}));

export const generateDatasetTransitionHistoryAndDrafts = (
  currentCompId: string | null,
  fieldValues: Record<string, unknown>,
  fields: DataField[],
  parentKey = "fields"
) => {
  if (!currentCompId) return { transitionHistory: [], datasetDrafts: [] };
  const transitionHistory: TransitionComponent[] = [];
  const datasetDrafts: DatasetDraft[] = [];

  const recordListFields = fields.filter(
    (f) => f.type === DocumentElementType.RecordList
  );
  for (const f of recordListFields) {
    const fieldKey = f.slug;
    const completeKey = parentKey ? `${parentKey}.${fieldKey}` : fieldKey;
    const dataValues = fieldValues[fieldKey];
    if (isArray(dataValues)) {
      for (const d of dataValues) {
        if (d._id === currentCompId) {
          transitionHistory.push({
            name: "table-fields-form",
            id: d._id,
          });

          datasetDrafts.push({
            field: completeKey,
            data: d,
            fields: f.fields,
          });

          return { transitionHistory, datasetDrafts };
        }

        if (f.fields?.length) {
          const nextHistoryAndDrafts =
            generateDatasetTransitionHistoryAndDrafts(
              currentCompId,
              d,
              f.fields,
              fieldKey
            );
          if (nextHistoryAndDrafts.transitionHistory.length) {
            transitionHistory.push(
              {
                name: "table-fields-form",
                id: d._id,
              },
              ...nextHistoryAndDrafts.transitionHistory
            );
            datasetDrafts.push(
              {
                field: completeKey,
                data: d,
                fields: f.fields,
              },
              ...nextHistoryAndDrafts.datasetDrafts
            );
            return { transitionHistory, datasetDrafts };
          }
        }
      }
    }
  }

  return { transitionHistory, datasetDrafts };
};

const AddModal = ({
  includedFieldIds,
  datasetDesignSlug,
}: {
  includedFieldIds: string[];
  datasetDesignSlug: string;
}) => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  useSystemLayoutStore.useSetButtonProps()({
    onClick: () => setAddModalOpen(true),
  });

  return (
    <AddDatasetModel
      open={addModalOpen}
      onClose={() => setAddModalOpen(false)}
      onSubmit={(data) => {}}
      includedFieldIds={includedFieldIds}
      datasetDesignSlug={datasetDesignSlug}
    />
  );
};

type GuiDatasetProps = {
  publicAccess?: boolean;
  guiTabEditorRef: RefObject<AnimationLayoutRef>;
  guiTab?: GuiTab;
};

const GuiDataset: React.FC<GuiDatasetProps> = (props) => {
  const { publicAccess = false, guiTabEditorRef } = props;

  const queryParams = useQuery<any>();
  const accountSlug = useAccountSlug();
  const user = useCurrentUser();
  const appNavigate = useAppNavigate();
  const theme = useTheme();
  const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const queryClient = useQueryClient();

  const [isActionFormModalOpen, setIsActionFormModalOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState<FusionAction>();

  const guiParamValues = useGuiParamsStore.useGuiParamValues();
  const goToRightView = useSystemLayoutStore.useGoToRightView();
  const setButtonOptions = useSystemLayoutStore.useSetButtonOptions();
  const setShowSideNavTopSelector =
    useSystemLayoutStore.useSetShowSideNavTopSelector();
  const searchValue = useSystemLayoutStore.useSearchValue?.();
  const datasetDrafts = useDatasetStore.useDatasetDrafts();
  const setDatasetDrafts = useDatasetStore.useSetDatasetDrafts();
  const pushDatasetDraft = useDatasetStore.usePushDatasetDraft();
  const updateDatasetDraft = useDatasetStore.useUpdateDatasetDraft();
  const mergeDatasetDraftTail = useDatasetStore.useMergeDatasetDraftTail();
  const deleteDatasetDraftAtIndex =
    useDatasetStore.useDeleteDatasetDraftAtIndex();

  const setMenu = useSystemLayoutStore.useSetMenu();
  const menuItemProps = useSystemLayoutStore.useSetItemProps();

  const selectedTab = useSystemLayoutStore.useSelectedTopBarTab();
  const { slug: guiSlug, datasetSlug } =
    useParams<{
      slug: string;
      datasetSlug: string;
    }>();

  const { data: gui } = useGetItem({
    modelName: ApiModels.Gui,
    slug: guiSlug,
  });
  const { data: guiTabs = [] } = useGuiTabs(gui?.slug);
  const guiTab = useMemo(() => {
    if (selectedTab != null && guiTabs) {
      return guiTabs[Number(selectedTab)];
    }
  }, [guiTabs, selectedTab]);

  const datasetDesignSlug =
    guiTab?.tab_type === "record_list" ? guiTab.dataset_design_slug : undefined;

  const {
    data: datasetResult,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetched,
  } = useListInfiniteItems({
    modelName: ApiModels.Dataset,
    requestOptions: {
      query: {
        dataset_type_slug: datasetDesignSlug,
        gui_slug: guiSlug,
        title: searchValue,
        tab_slug: guiTab?.slug,
        condition_filter_value: queryParams,
        ...guiParamValues,
      },
      path: `list/${datasetDesignSlug}`,
    },
    queryKey: [
      ApiModels.Dataset,
      {
        dataset_type_slug: datasetDesignSlug,
        gui_slug: guiSlug,
        title: searchValue,
        tab_slug: guiTab?.slug,
        queryParams,
        ...guiParamValues,
      },
    ],
    queryOptions: {
      enabled: !!guiTab?.slug && !!guiSlug && !!datasetDesignSlug,
    },
  });
  const datasets = useMemo(() => {
    const paginatedData: Dataset[] = [];
    datasetResult?.pages?.forEach((group) => {
      group?.data?.forEach((data) => {
        paginatedData.push(data);
      });
    });
    return paginatedData;
  }, [datasetResult]);

  useEffect(() => {
    useSystemLayoutBaseStore.getState().setLoadmoreCallback(() => {
      // console.log(
      //   "load more----------------------------------------------------------------------------"
      // );
      if (hasNextPage && !isFetching) {
        fetchNextPage();
      }
    });
  }, [hasNextPage, fetchNextPage, isFetching]);

  const { data: datasetDesign } = useGetItem({
    modelName: "dataset-design",
    slug: datasetDesignSlug,
  });

  const { data: dataset } = useGetItem({
    modelName: ApiModels.Dataset,
    slug: datasetSlug,
    requestOptions: { path: datasetDesignSlug },
    queryKey: [ApiModels.Dataset, { datasetDesignSlug, datasetSlug }],
  });

  const [initialComponent, setInitialComponent] = useState<string>("");
  const layoutRef = useRef<AnimationLayoutRef>(null);
  const historyIsSet = useRef(false);

  useEffect(() => {
    // setEditModeEnabled(true);
    setShowSideNavTopSelector(false);
  }, []);

  useEffect(() => {
    if (
      !datasetSlug &&
      datasets &&
      datasets?.length > 0 &&
      selectedTab != null
    ) {
      if (publicAccess) {
        appNavigate(
          `/gui-module-public/${guiSlug}/${selectedTab}/${datasets[0]?.slug}?t=0`,
          { replace: true }
        );
      } else {
        appNavigate(
          `/gui-module/${guiSlug}/${selectedTab}/${datasets[0]?.slug}?t=0`,
          { replace: true }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    datasets,
    datasetSlug,
    appNavigate,
    guiSlug,
    datasetDesignSlug,
    selectedTab,
  ]);

  useEffect(() => {
    useSystemLayoutBaseStore.getState().setLayout("search");
    useSystemLayoutBaseStore.getState().setCustomSearch(true);

    setButtonOptions({ addFolder: false, addItem: true });
    return () => {
      useSystemLayoutBaseStore.getState().setLayout("default");
      useSystemLayoutBaseStore.getState().setCustomSearch(false);
      useSystemLayoutBaseStore.getState().setShowActionButtons(true);

      setButtonOptions({ addFolder: true, addItem: true });
    };
  }, [setButtonOptions]);

  useEffect(() => {
    menuItemProps({
      onClick: (item) => {
        layoutRef.current?.reset();
        goToRightView();
        const data = queryClient.getQueryData<Dataset[]>([
          [ApiModels.Dataset, { dataset_type_slug: datasetDesignSlug }],
        ]);
        const designItem = data?.find((d) => d.slug === item.key);
        if (designItem) {
          queryClient.setQueryData([ApiModels.Dataset, item.key], designItem);
        }

        if (publicAccess) {
          appNavigate(
            `/gui-module-public/${guiSlug}/${selectedTab}/${item.key}?t=0`
          );
        } else {
          appNavigate(`/gui-module/${guiSlug}/${selectedTab}/${item.key}?t=0`);
        }
      },
      isActive: (item) => {
        return item.key.toString() === datasetSlug;
      },
    });
    setInitialComponent(getInitialComponent());
  }, [appNavigate, datasetDesignSlug, datasetSlug, guiSlug, selectedTab]);

  useEffect(() => {
    if (datasets) {
      setMenu(
        datasets.map((design) => ({
          title: design.title,
          key: design.slug,
          icon: "Dataset",
        }))
      );
    }
  }, [datasets, isFetched, setMenu]);

  useEffect(() => {
    return () => {
      useSystemLayoutBaseStore.getState().setMenu([]);
    };
  }, []);

  useLayoutEffect(() => {
    if (!historyIsSet.current && !xlScreen) {
      const c = getSearchParams().get("c");
      if (datasetDesign && layoutRef.current) {
        const transitionHistory: TransitionComponent[] = xlScreen
          ? []
          : [{ name: "main", id: "main" }];
        const drafts: DatasetDraft[] = [];
        if (dataset && datasetSlug) {
          drafts.push({ data: dataset, fields: datasetDesign.fields.fields });
          transitionHistory.push({
            name: "edit-dataset-form",
            id: dataset.slug,
          });
          const { transitionHistory: tHistory, datasetDrafts: dDrafts } =
            generateDatasetTransitionHistoryAndDrafts(
              c,
              dataset.fields,
              datasetDesign.fields.fields || []
            );
          transitionHistory.push(...tHistory);
          drafts.push(...dDrafts);
        }
        layoutRef.current.setTransitionHistory(transitionHistory);
        setDatasetDrafts(drafts);

        if (datasetSlug && dataset) {
          historyIsSet.current = true;
        }

        if (datasetDesignSlug && datasetDesign && !datasetSlug) {
          historyIsSet.current = true;
        }
      }
    }
  }, [
    datasetDesign,
    dataset,
    xlScreen,
    datasetSlug,
    setDatasetDrafts,
    datasetDesignSlug,
  ]);

  const gotoDataset = useCallback(
    (datasetId: number) => {
      if (!guiSlug || selectedTab == null) {
        return;
      }

      if (publicAccess) {
        appNavigate(
          `/gui-module-public/${guiSlug}/${selectedTab}/${datasetId}?t=0`
        );
      } else {
        appNavigate(`/gui-module/${guiSlug}/${selectedTab}/${datasetId}?t=0`);
      }
    },
    [appNavigate, guiSlug, publicAccess, selectedTab]
  );

  const getCurrentDatasetIndex = useCallback(() => {
    const currentDatasetId = Number(datasetSlug);
    const currentDatasetIndex =
      datasets?.findIndex((dataset) => dataset.id === currentDatasetId) ?? -1;
    return currentDatasetIndex;
  }, [datasetSlug, datasets]);

  const handleNextClick = useCallback(() => {
    if (!datasets || datasets.length === 0) {
      return;
    }
    const currentDatasetIndex = getCurrentDatasetIndex();

    if (currentDatasetIndex === -1) {
      return;
    }

    const nextDatasetIndex = currentDatasetIndex + 1;

    const nextDataset =
      nextDatasetIndex >= datasets.length
        ? datasets[0]
        : datasets[nextDatasetIndex];

    gotoDataset(nextDataset.id);
  }, [datasets, getCurrentDatasetIndex, gotoDataset]);

  const handlePreviousClick = useCallback(() => {
    if (!datasets || datasets.length === 0) {
      return;
    }
    const currentDatasetIndex = getCurrentDatasetIndex();

    if (currentDatasetIndex === -1) {
      return;
    }

    const previousDatasetIndex = currentDatasetIndex - 1;

    const previousDataset =
      previousDatasetIndex < 0
        ? datasets[datasets.length - 1]
        : datasets[previousDatasetIndex];

    gotoDataset(previousDataset.id);
  }, [datasets, getCurrentDatasetIndex, gotoDataset]);

  const handleActionClick = useCallback(
    (action: FusionAction) => {
      if (dataset && accountSlug && user?.slug) {
        if (action.action_type === ActionType.OpenForm) {
          setSelectedAction(action);
          setIsActionFormModalOpen(true);
        } else {
          FusionModel.runFusion(action.fusion_slug, {
            user_id: user.slug,
            account_id: accountSlug,
            popup_variables: {
              popup_variables: {
                ...dataset,
                ...(dataset.fields || {}),
                fields: undefined,
              },
            },
          }).catch(() => {});

          handleNextClick();
        }
      }
    },
    [accountSlug, dataset, handleNextClick, user?.slug]
  );

  const getInitialComponent = () => {
    if (!xlScreen) {
      return getSearchParams().get("c_name") || "main";
    }

    return "main";
  };

  const getComponentMiddleComponent: Config["getComponents"] =
    React.useCallback(
      (gotoComponent, goBack) => {
        return {
          main: (
            <MiddleComponent
              data={last(datasetDrafts)?.data}
              onFormEvent={(event) => {
                if (event.name === "table-delete") {
                  deleteDatasetDraftAtIndex(event.data.index, event.field);
                } else if (event.name === "table-add") {
                } else if (event.name === "table-edit") {
                  pushDatasetDraft({
                    field: event.field,
                    data: event.data.data as Record<string, unknown>,
                  });
                  gotoComponent({
                    name: "table-fields-form",
                    id: event.data.data._id,
                  });
                } else if (event.name === "table-add-complete") {
                  pushDatasetDraft({
                    field: event.field,
                    data: event.data.data,
                  });
                  mergeDatasetDraftTail();
                } else if (event.name === "table-edit-complete") {
                  pushDatasetDraft({
                    field: event.field,
                    data: event.data.data,
                  });
                  mergeDatasetDraftTail();
                }
              }}
              onNextClick={handleNextClick}
              onPreviousClick={handlePreviousClick}
              onActionClick={handleActionClick}
              guiTabEditorRef={guiTabEditorRef}
            />
          ),
          "table-fields-form": (
            <EditTableFields
              data={last(datasetDrafts)?.data}
              fields={last(datasetDrafts)?.fields || []}
              onBackClick={() => {
                mergeDatasetDraftTail();
                goBack();
              }}
              onFormEvent={(event) => {
                if (event.name === "table-delete") {
                  deleteDatasetDraftAtIndex(event.data.index, event.field);
                } else if (event.name === "table-add") {
                } else if (event.name === "table-edit") {
                  pushDatasetDraft({
                    field: event.field,
                    data: event.data.data as Record<string, unknown>,
                  });
                  gotoComponent({
                    name: "table-fields-form",
                    id: event.data.data._id,
                  });
                } else if (event.name === "table-add-complete") {
                  pushDatasetDraft({
                    field: event.field,
                    data: event.data.data,
                  });
                  mergeDatasetDraftTail();
                } else if (event.name === "table-edit-complete") {
                  pushDatasetDraft({
                    field: event.field,
                    data: event.data.data,
                  });
                  mergeDatasetDraftTail();
                }
              }}
              onSubmit={(data) => {
                const field = last(datasetDrafts)?.field;
                if (field) {
                  updateDatasetDraft(field, data);
                }
              }}
              disableTableActions={{ add: false, edit: true, remove: false }}
            />
          ),
        };
      },
      [
        datasetDrafts,
        handleNextClick,
        handlePreviousClick,
        handleActionClick,
        guiTabEditorRef,
        deleteDatasetDraftAtIndex,
        pushDatasetDraft,
        mergeDatasetDraftTail,
        updateDatasetDraft,
      ]
    );

  // useEffect(() => { // in gui there are mulitple dataset design  this effect is update all teh dataset again single dataset design
  //   if (datasetSlug && datasetDraft.pushUpdate && datasetDraft.draft) {
  //     debouncedUpdateDataset({ slug: datasetSlug, data: datasetDraft.draft });
  //   }
  // }, [datasetDraft, datasetSlug, debouncedUpdateDataset]);

  const formFields =
    guiTab?.tab_type === "record_list" ? guiTab.form_fields || [] : [];

  const datasetActions =
    guiTab?.tab_type === GuiType.RecordList
      ? guiTab.associated_actions ?? []
      : [];

  return (
    <React.Fragment>
      {datasetDesign?.slug && (
        <Stack direction="row" overflow="hidden" height="100%">
          {/* {!publicAccess && ( */}
          <Box>
            <SideNav />
          </Box>
          {/* )} */}
          {dataset?.slug && (
            <React.Fragment>
              <CenterBox className="center-box">
                {/* <Scrollbar> */}
                <Box sx={{ height: "100%" }}>
                  {initialComponent && (
                    <AnimationLayout
                      ref={layoutRef}
                      config={{
                        getComponents: getComponentMiddleComponent,
                        initialComponent,
                      }}
                    />
                  )}
                </Box>
                {/* </Scrollbar> */}
              </CenterBox>
              {xlScreen && (
                <RightSideBox
                  sx={{
                    background: "#1a1c20",
                  }}
                >
                  <RightSidebar
                    gui={gui!}
                    actions={datasetActions}
                    onNextClick={handleNextClick}
                    onPreviousClick={handlePreviousClick}
                    onActionClick={handleActionClick}
                  />
                </RightSideBox>
              )}
            </React.Fragment>
          )}
        </Stack>
      )}

      <ActionFormModalLegacy
        open={isActionFormModalOpen}
        action={selectedAction}
        onSubmit={() => {
          setIsActionFormModalOpen(false);
          setSelectedAction(undefined);
          handleNextClick();
        }}
        onClose={() => {
          setIsActionFormModalOpen(false);
          setSelectedAction(undefined);
        }}
      />

      <AddModal
        includedFieldIds={formFields}
        datasetDesignSlug={datasetDesignSlug!}
      />
      <RenameModel module={ApiModels.DatasetDesign} title="Dataset Design" />
      <ActionEditorDrawer guiSlug={guiSlug} guiTab={guiTab} />
      <ActionFormModal />
    </React.Fragment>
  );
};

export default GuiDataset;
