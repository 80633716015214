import { DndContext, DragEndEvent, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { Box } from "@mui/material";
import { GFMouseSensor, GFTouchSensor } from "components/DndSensors";
import { FC } from "react";
import useGFChatWindow from "store/stores/gf-chat-window";
import ChatDraggableContainer from "./ChatDraggableContainer";

const DraggableContainer: FC = () => {
  const position = useGFChatWindow.usePosition();
  const setPosition = useGFChatWindow.useSetPosition();

  const mouseSensor = useSensor(GFMouseSensor);
  const touchSensor = useSensor(GFTouchSensor);

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragEnd = (event: DragEndEvent) => {
    setPosition(({ left, top }) => {
      return {
        left: left + event.delta.x,
        top: top + event.delta.y,
      };
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: (theme) => theme.zIndex.appBar + 1,
        pointerEvents: "none",
      }}
    >
      <DndContext
        sensors={sensors}
        modifiers={[restrictToWindowEdges]}
        onDragEnd={handleDragEnd}
      >
        <ChatDraggableContainer top={position.top} left={position.left} />
      </DndContext>
    </Box>
  );
};

const FloatingChatWindow: FC = () => {
  const isOpen = useGFChatWindow.useIsOpen();

  if (!isOpen) {
    return null;
  }

  return <DraggableContainer />;
};

export default FloatingChatWindow;
