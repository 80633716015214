import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { HashtagNode } from "@lexical/hashtag";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { MarkNode } from "@lexical/mark";
import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { FC } from "react";
import ValueChangePlugin from "./ValueChangePlugin";
// import { CodeNode } from "./CodeNode";

const editorConfig: InitialConfigType = {
  namespace: "ChatMessageContent",
  editable: false,
  onError(error) {
    throw error;
  },
  nodes: [
    CodeNode,
    CodeHighlightNode,
    HashtagNode,
    AutoLinkNode,
    LinkNode,
    ListItemNode,
    ListNode,
    MarkNode,
    HeadingNode,
    QuoteNode,
    TableCellNode,
    TableNode,
    TableRowNode,
  ],
};

type ChatMessageContentProps = {
  value?: string;
};

const ChatMessageContent: FC<ChatMessageContentProps> = (props) => {
  const { value } = props;

  if (!value) {
    return <></>;
  }

  return (
    <LexicalComposer
      initialConfig={{
        ...editorConfig,
        editorState: () => $convertFromMarkdownString(value, TRANSFORMERS),
      }}
    >
      <RichTextPlugin
        contentEditable={<ContentEditable />}
        placeholder={<div></div>}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <ValueChangePlugin value={value ?? ""} />
    </LexicalComposer>
  );
};

export default ChatMessageContent;
