import { Avatar, AvatarGroup } from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";
import Cell from "components/DatasetTable/Cell";
import { FC, memo } from "react";
import { getNameInitial } from "utils";

const DISPLAY_LIMIT = 4;

const getAvatar = (name: string, image?: string) => (
  <Avatar
    alt={name}
    src={image}
    sx={{
      width: 28,
      height: 28,
      fontSize: 16,
    }}
    variant="rounded"
  >
    {getNameInitial(name)}
  </Avatar>
);

type ImageCellRendererProps = {
  field: DataField;
} & CustomCellRendererProps;

const ImageCellRenderer: FC<ImageCellRendererProps> = memo((props) => {
  const { field } = props;

  const images: { name: string; url: string }[] = field.multi
    ? props.value ?? []
    : props.value
    ? [props.value]
    : [];

  const surplus = images.length - DISPLAY_LIMIT;

  return (
    <Cell
      isLoading={!props.data}
      sx={{
        justifyContent: "space-between",
      }}
    >
      <AvatarGroup
        sx={{
          ".MuiAvatar-root": {
            height: 28,
            width: 28,
            fontSize: 16,
            marginLeft: "4px",
            border: "none",
          },
        }}
        max={DISPLAY_LIMIT}
      >
        {images
          .slice(0, DISPLAY_LIMIT)
          ?.map((image) => getAvatar(image.name, image.url))}
      </AvatarGroup>
      {surplus > 0 && (
        <Avatar
          alt="surplus"
          sx={{
            width: 24,
            height: 24,
            fontSize: 14,
          }}
          variant="rounded"
        >
          {surplus > 5 ? "+5" : surplus}
        </Avatar>
      )}
    </Cell>
  );
});

export default ImageCellRenderer;
