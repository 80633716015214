import Model from "models";

class GuiTabModel extends Model<GuiTab> {
  constructor() {
    super("/gf-gui/tab", "gui-fusion");
  }

  async duplicate(
    data: {
      tabSlug: string;
    } & (
      | {
          type: "tab";
          tabIndex: number;
        }
      | {
          type: "widget";
          widgetPath: (string | number)[];
        }
    )
  ) {
    this.sendRequest("/duplicate", "POST", data);
  }
}

export default new GuiTabModel();
