import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { useMemo } from "react";

const useThreadAgent = (agentSlug?: string) => {
  const { data: agents = [] } = useListItems({ modelName: ApiModels.Agent });

  return useMemo(
    () => agents.find((a) => a.slug === agentSlug),
    [agents, agentSlug]
  );
};

export default useThreadAgent;
