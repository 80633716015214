import DeleteOutline from "@mui/icons-material/DeleteOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import MoreVert from "@mui/icons-material/MoreVert";
import { Box, IconButton, Stack, Typography, styled } from "@mui/material";
import FusionActionsDropdown from "components/FusionAction/FusionActionsDropdown";
import Icon from "components/util-components/Icon";
import { FC } from "react";
import { useGuiDashboardV2Store } from "store/stores/gui-dashboard-v2";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";

const Container = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.DashboardWidgetBackground,
  border: `1px solid ${theme.palette.gfGrey.Divider}`,
  borderRadius: "6px",
  height: "100%",
  padding: "10px",
  width: "100%",
  overflow: "hidden",
}));

const ActionMenuContainer = styled(Box)({
  display: "flex",
  flexDirection: "row-reverse",
});

const ContentContainer = styled(Stack)({
  marginLeft: "10px",
  marginRight: "10px",
  height: "100%",
});

type StatsWidgetProps = {
  widget: DashboardWidget<"stat">;
  data?: Record<string, unknown>;
  onDelete(): void;
};

const StatsWidget: FC<StatsWidgetProps> = (props) => {
  const { widget, data, onDelete } = props;

  const setWidget = useGuiDashboardV2Store.useSetWidget();
  const setIsWidgetEditorOpen =
    useGuiDashboardV2Store.useSetIsWidgetEditorOpen();
  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();

  return (
    <Container direction="column" justifyContent="start">
      <ActionMenuContainer>
        <FusionActionsDropdown
          widget={widget}
          widgetActionType="widget"
          actions={widget.associated_actions ?? []}
          editable={editModeEnabled}
          extraMenuItems={
            editModeEnabled
              ? [
                  {
                    title: "Edit",
                    id: "edit",
                    icon: <EditOutlined />,
                    onClick() {
                      setWidget(widget);
                      setIsWidgetEditorOpen(true);
                    },
                  },
                  {
                    title: "Delete",
                    id: "delete",
                    icon: <DeleteOutline />,
                    onClick() {
                      onDelete();
                    },
                  },
                ]
              : []
          }
        >
          <IconButton className="non-draggable-widget-area" sx={{ padding: 0 }}>
            <MoreVert sx={{ color: "#a2a2a6" }} />
          </IconButton>
        </FusionActionsDropdown>
      </ActionMenuContainer>
      <ContentContainer
        direction="column"
        justifyContent="start"
        alignItems="start"
        gap={1}
      >
        <Icon
          iconName={widget.widget_data?.icon ?? "AccountBalanceWallet"}
          sx={{ width: "48px", height: "48px", color: "#a2a2a6" }}
        />
        <Typography fontSize="36px" fontWeight="600">
          {(data?.value as string) ??
            widget.widget_data?.value ??
            "$120,246.23"}
        </Typography>
        <Typography fontSize="16px" fontWeight="400">
          {(data?.title as string) ?? widget.title ?? "Current Balance"}
        </Typography>
      </ContentContainer>
    </Container>
  );
};

export default StatsWidget;
