import { ApiModels } from "queries/apiModelMapping";
import { useGuiTabs } from "queries/gui/useGuiTabs";
import useGetItem from "queries/useGetItem";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSystemLayoutStore from "store";

const useDatasetGuiTabData = () => {
  const selectedTab = useSystemLayoutStore.useSelectedTopBarTab();
  const { slug: guiSlug } =
    useParams<{
      slug: string;
      datasetSlug: string;
    }>();

  const { data: gui } = useGetItem({
    modelName: ApiModels.Gui,
    slug: guiSlug,
  });
  const { data: guiTabs = [] } = useGuiTabs(gui?.slug);
  const guiTab = useMemo(() => {
    if (selectedTab != null && guiTabs) {
      return guiTabs[Number(selectedTab)];
    }
  }, [guiTabs, selectedTab]);

  return guiTab;
};

export default useDatasetGuiTabData;
