import AddOutlined from "@mui/icons-material/AddOutlined";
import ListRounded from "@mui/icons-material/ListRounded";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import FormField from "components/FormField";
import Spin from "components/Spin";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DocumentElementType } from "enums/Form";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import useListItems from "queries/useListItems";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useEffect, useMemo, useState } from "react";

dayjs.extend(utc);

type GroupEditorProps = {
  group?: GuiParamGroup;
  guiSlug?: string;
  onAddClick(): void;
  onEditClick(param: GuiParams): void;
  onCancelClick(): void;
};

const GroupEditor: FC<GroupEditorProps> = (props) => {
  const { group, guiSlug, onAddClick, onEditClick, onCancelClick } = props;

  const queryClient = useQueryClient();

  const [title, seTitle] = useState("");

  const { data: params = [], isFetching } = useListItems({
    modelName: ApiModels.GuiParams,
    requestOptions: { query: { param_group_slug: group?.slug } },
    queryKey: [ApiModels.GuiParams, group?.slug],
    queryOptions: {
      enabled: !!group?.slug,
    },
  });
  const { mutateAsync: updateGroup, isLoading: isUpdating } = useUpdateItem({
    modelName: ApiModels.GuiParamGroup,
  });
  const { mutateAsync: createGroup, isLoading: isCreating } = useCreateItem({
    modelName: ApiModels.GuiParamGroup,
  });

  useEffect(() => {
    if (group?.title) {
      seTitle(group.title);
    }
  }, [group?.title]);

  const sortedParams = useMemo(() => {
    return params.sort((a, b) =>
      dayjs.utc(a.created_at).isBefore(dayjs.utc(b.created_at)) ? -1 : 1
    );
  }, [params]);

  const handleSave = async () => {
    if (group?.slug) {
      await updateGroup({ slug: group?.slug, data: { title } });
    } else if (guiSlug) {
      await createGroup({ title, gui_slug: guiSlug });
    }

    queryClient.refetchQueries({
      queryKey: [ApiModels.GuiParamGroup, guiSlug],
    });

    onCancelClick();
  };

  const isLoading = isUpdating || isCreating;

  return (
    <Stack gap={2}>
      <Typography>Filter Group Name</Typography>
      <FormField
        label="Title"
        type={DocumentElementType.TextField}
        value={title}
        onChange={(value) => seTitle(value as string)}
      />
      <Stack direction="column" gap={2} width="100%">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography fontSize={16}>Filter Group Fields</Typography>
          <IconButton
            sx={{
              background: (theme) => theme.palette.primary.main,
              color: "white",
              width: "24px",
              height: "24px",
              borderRadius: "4px",
            }}
            onClick={onAddClick}
          >
            <AddOutlined />
          </IconButton>
        </Stack>
        <Spin spinning={isFetching}>
          <Stack gap={1}>
            {sortedParams.map((param) => (
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                width="100%"
                borderRadius="6px"
                padding="4px"
                sx={{
                  background: (theme) => theme.palette.background.ListItem,
                  cursor: "pointer",
                }}
                onClick={() => onEditClick(param)}
              >
                <Stack
                  width="36px"
                  height="36px"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    background: (theme) =>
                      theme.palette.background.ListItemIcon,
                  }}
                >
                  <ListRounded />
                </Stack>
                <Stack direction="column">
                  <Typography fontSize={14} color="text.primary">
                    {param.title}
                  </Typography>
                  <Typography
                    fontSize={13}
                    color="text.secondary"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    width="200px"
                  >
                    {param.param_slug}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Spin>
      </Stack>
      <Stack direction="row" gap={2} sx={{ mx: "auto", mt: 2 }}>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          variant="contained"
          onClick={handleSave}
        >
          Save
        </LoadingButton>
        <Button variant="outlined" onClick={onCancelClick}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

export default GroupEditor;
