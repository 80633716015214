import { AnimationLayoutRef } from "layouts/AnimationLayout";
import { RefObject, createContext, createRef, useState } from "react";

export type TranscriptionContextType = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  selectedTab: { value: string } | null;
  setSelectedTab: (value: { value: string } | null) => void;
  ref: RefObject<AnimationLayoutRef>;
  selectedFusionAction?: FusionAction;
  setSelectedFusionAction: (action: FusionAction | undefined) => void;
  isOpenRenameModal: boolean;
  setIsOpenRenameModal: (value: boolean) => void;
};

const ref = createRef<AnimationLayoutRef>();

export const GuiTabEditorContext = createContext<TranscriptionContextType>({
  open() {},
  close() {},
  isOpen: false,
  selectedTab: null,
  setSelectedTab(value) {},
  ref,
  setSelectedFusionAction(action) {},
  isOpenRenameModal: false,
  setIsOpenRenameModal(value) {},
});

const GuiTabEditorProvider = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRenameModal, setIsOpenRenameModal] = useState(false);
  const [selectedTab, setSelectedTab] =
    useState<{ value: string } | null>(null);
  const [selectedFusionAction, setSelectedFusionAction] =
    useState<FusionAction>();

  return (
    <GuiTabEditorContext.Provider
      value={{
        isOpenRenameModal,
        setIsOpenRenameModal,
        isOpen,
        selectedTab,
        setSelectedTab,
        selectedFusionAction,
        setSelectedFusionAction,
        open: () => {
          setIsOpen(true);
        },
        close: () => {
          setIsOpen(false);
        },
        ref,
      }}
    >
      {children}
    </GuiTabEditorContext.Provider>
  );
};

export default GuiTabEditorProvider;
