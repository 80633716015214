import { zodResolver } from "@hookform/resolvers/zod";
import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, Button, CardActions, styled } from "@mui/material";
import FormField from "components/FormField";
import SidebarSection from "components/RightSidebar";
import { DocumentElementType } from "enums/Form";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const AddNewFieldContainer = styled(Box)(({ theme }) => {
  return {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    height: "100%",
    // width: "420px",

    [`${theme.breakpoints.down("sm")}`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },

    ".MuiFormLabel-root": {
      fontSize: "12px",
      lineHeight: "1",
      fontWeight: "600",
      color: theme.palette.background.GF80,
      marginBottom: "8px",
      textTransform: "uppercase",
    },

    ".MuiFormControl-root": {
      ".MuiFormControl-root": {
        marginBottom: "0",
        marginTop: "0",
      },
    },

    ".MuiListItem-root , .MuiBox-root": {
      paddingLeft: "0",
      paddingRight: "0",
    },
  };
});

const formSchema = z.object({
  title: z.string().min(1, "Title is required"),
  description: z.string().optional(),
  default_value: z.string().optional(),
});

export type AddShortCodeFormType = z.infer<typeof formSchema>;

type AddShortCodeFormProps = {
  shortCode?: ShortCode;
  onBackClick(): void;
  onSubmit(data: AddShortCodeFormType): Promise<void>;
};

const AddShortCodeForm: FC<AddShortCodeFormProps> = (props) => {
  const { shortCode, onBackClick } = props;

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    control,
  } = useForm<AddShortCodeFormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (shortCode) {
      setValue("title", shortCode.title ?? "");
      setValue("description", shortCode.description ?? "");
      setValue("default_value", shortCode.default_value ?? "");
    }
  }, [setValue, shortCode]);

  const onSubmit = async (data: AddShortCodeFormType) => {
    setLoading(true);
    await props.onSubmit(data);
    setLoading(false);
  };

  return (
    <AddNewFieldContainer>
      <SidebarSection
        title="Add Short Code"
        rightIcon={false}
        leftIcon={<ArrowBackOutlined />}
        onLeftIconClick={() => onBackClick()}
      >
        <FormField
          label="Title"
          error={dirtyFields.title ? errors.title : undefined}
          type={DocumentElementType.TextField}
          formControl={{
            control,
            name: "title",
          }}
        />
        <FormField
          label="Description"
          error={dirtyFields.description ? errors.description : undefined}
          type={DocumentElementType.TextArea}
          formControl={{
            control,
            name: "description",
          }}
        />
        <FormField
          label="Default Value"
          error={dirtyFields.default_value ? errors.default_value : undefined}
          type={DocumentElementType.TextField}
          formControl={{
            control,
            name: "default_value",
          }}
        />
      </SidebarSection>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => onBackClick()}
        >
          Back
        </Button>
        <LoadingButton
          loading={loading}
          color="inherit"
          sx={{
            bgcolor: (theme) => theme.palette.primary.main,
          }}
          onClick={handleSubmit(onSubmit, (e) => {
            console.error(e);
          })}
        >
          Save
        </LoadingButton>
      </CardActions>
    </AddNewFieldContainer>
  );
};

export default AddShortCodeForm;
