import { InitialConfigType } from "@lexical/react/LexicalComposer";
import { TagNode } from "components/LexicalTagField/TagNode";

const editorConfig: InitialConfigType = {
  namespace: "MixedTagField",
  theme: {
    ltr: "ltr",
    rtl: "rtl",
    placeholder: "editor-placeholder",
    paragraph: "editor-paragraph",
  },
  onError(error) {
    throw error;
  },
  nodes: [TagNode],
};

export default editorConfig;
