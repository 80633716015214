import { Box } from "@mui/material";
import { FC } from "react";
import ChatMessages from "./ChatMessages";
import InputField from "./InputField";

type ChatSectionProps = {
  showAvatarForUser?: boolean;
};

const ChatSection: FC<ChatSectionProps> = (props) => {
  const { showAvatarForUser } = props;

  return (
    <>
      <Box height="100%">
        <ChatMessages showAvatarForUser={showAvatarForUser} />
      </Box>
      <Box position="absolute" bottom={0} width="100%">
        <InputField />
      </Box>
    </>
  );
};

export default ChatSection;
