import { DragIndicatorOutlined } from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import Scrollbar from "components/Scrollbar";
import { BaseItem, SortableList } from "components/SortableList";
import Icon from "components/util-components/Icon";
import { FC, ReactNode } from "react";

type DataItem = BaseItem & { label?: ReactNode; icon?: ReactNode };

type ListWidgetProps<T extends DataItem = DataItem> = {
  data: T[];
  itemActions?: (field: T, index: number) => ReactNode;
  onItemClick?(item: T): void;
  onSortChange?(items: T[]): void;
};

const ITEM_HEIGHT = 36;
const MAX_HEIGHT = 300;

const ListWidget: FC<ListWidgetProps> = (props) => {
  const { data = [], itemActions, onItemClick, onSortChange } = props;
  return (
    <Stack
      gap={1}
      sx={{
        background: "#29292e",
        borderRadius: "3px",
        height: `${Math.min(MAX_HEIGHT, data.length * ITEM_HEIGHT + 16)}px`,
        maxHeight: "300px",
      }}
    >
      <Scrollbar>
        <SortableList
          items={data}
          onChange={onSortChange}
          renderList={({ children }) => (
            <List sx={{ mx: 1 }} dense>
              {children}
            </List>
          )}
          renderItem={(field, index) => (
            <SortableList.Item id={field.id} key={field.id} handle>
              <ListItem
                sx={{
                  ".MuiListItemSecondaryAction-root": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                }}
                secondaryAction={itemActions?.(field, index)}
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  onClick={() => onItemClick?.(field)}
                  sx={{ px: 0, borderRadius: "4px" }}
                  dense
                >
                  <SortableList.DragHandle>
                    <ListItemIcon
                      sx={{ minWidth: "auto", mr: 1, outline: "none" }}
                    >
                      <DragIndicatorOutlined
                        sx={{ color: "#78787b", fontSize: "16px" }}
                      />
                    </ListItemIcon>
                  </SortableList.DragHandle>
                  <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                    {typeof field.icon === "string" ? (
                      <Icon iconName={field.icon} fontSize="small" />
                    ) : (
                      field.icon
                    )}
                  </ListItemIcon>
                  <ListItemText
                    sx={{ fontSize: "15px", fontWeight: 400 }}
                    primary={field.label}
                  />
                </ListItemButton>
              </ListItem>
            </SortableList.Item>
          )}
        />
      </Scrollbar>
    </Stack>
  );
};

export default ListWidget;
