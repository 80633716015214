import { ColDef } from "ag-grid-community";
import ThreePAppCellEditor from "./ThreePAppCellEditor";
import ThreePAppCellRenderer from "./ThreePAppCellRenderer";

const get3pAppColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => {
  return {
    ...defaults,
    cellRenderer: ThreePAppCellRenderer,
    cellEditor: ThreePAppCellEditor,
  };
};

export default get3pAppColDef;
