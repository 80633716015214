import { ColDef } from "ag-grid-community";
import ProgressCellEditor from "components/DatasetTable/colDefs/progress/ProgressCellEditor";
import ProgressCellRenderer from "components/DatasetTable/colDefs/progress/ProgressCellRenderer";

const getProgressColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => {
  return {
    ...defaults,
    cellRenderer: ProgressCellRenderer,
    cellEditor: ProgressCellEditor,
  };
};

export default getProgressColDef;
