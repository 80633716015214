import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  styled,
} from "@mui/material";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import { DocumentElementType, ListSource } from "enums/Form";
import { ApiModels } from "queries/apiModelMapping";
import useCreateThread from "queries/copilot/useCreateThread";
import useListItems from "queries/useListItems";
import { FC, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const Container = styled(Stack)({
  maxWidth: "600px",
  width: "100%",
});

const formSchema = z.object({
  agent_slug: z.string(),
});

type AddAgentThreadFormType = z.infer<typeof formSchema>;

type AddAgentThreadModalProps = {
  onClose?(): void;
  onCreated?(): void;
} & DialogProps;

const AddAgentThreadModal: FC<AddAgentThreadModalProps> = (props) => {
  const { onClose, onCreated, ...dialogProps } = props;

  const form = useForm<AddAgentThreadFormType>({
    resolver: zodResolver(formSchema),
  });

  const { control, handleSubmit } = form;

  const { mutateAsync: createThread, isLoading } = useCreateThread();
  const { data: agents = [] } = useListItems({ modelName: ApiModels.Agent });

  const agentOptions = useMemo(
    () => agents.map((a) => ({ label: a.agent_name, value: a.slug })),
    [agents]
  );

  const submitHandler = async (values: AddAgentThreadFormType) => {
    console.log(values, agentOptions);
    await createThread({
      agent_slug: values.agent_slug,
    });

    onClose?.();
    onCreated?.();
  };

  return (
    <Dialog
      onClose={(e, r) => {
        console.log(e, r);
      }}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Add Agent User</DialogTitle>
      <Scrollbar className="form-scroller">
        <DialogContent>
          <FormProvider {...form}>
            <Stack alignItems="center">
              <Container gap={2}>
                <FormField
                  label="Agent"
                  type={DocumentElementType.Select}
                  formControl={{ control, name: "agent_slug" }}
                  fieldExtras={{
                    field: {
                      list_source: ListSource.Hardcoded,
                      list_items: agentOptions,
                    },
                  }}
                />
              </Container>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Scrollbar>
      <DialogActions>
        <LoadingButton onClick={onClose}>Cancel</LoadingButton>
        <LoadingButton
          onClick={handleSubmit(submitHandler, (error) => {
            console.log(error);
          })}
          variant="contained"
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddAgentThreadModal;
