import { ColDef } from "ag-grid-community";
import getSingleSelectEditorDefs from "components/DatasetTable/colDefs/getSingleSelectEditorDefs";

const getRadioColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => ({
  ...defaults,
  ...getSingleSelectEditorDefs(field, options),
});

export default getRadioColDef;
