import { ColDef } from "ag-grid-community";
import get3pAppColDef from "components/DatasetTable/colDefs/3p-app";
import DefaultTextCellRenderer from "components/DatasetTable/colDefs/DefaultTextCellRenderer";
import getAudioColDef from "components/DatasetTable/colDefs/audio";
import getBooleanColDef from "components/DatasetTable/colDefs/boolean";
import getCheckColDef from "components/DatasetTable/colDefs/checkbox";
import getCodeColDef from "components/DatasetTable/colDefs/code";
import getDatasetDesignColDef from "components/DatasetTable/colDefs/dataset-design";
import getFileColDef from "components/DatasetTable/colDefs/file";
import getIconColDef from "components/DatasetTable/colDefs/icon";
import getImageColDef from "components/DatasetTable/colDefs/image";
import getNumberColDef from "components/DatasetTable/colDefs/number";
import getProgressColDef from "components/DatasetTable/colDefs/progress";
import getRadioColDef from "components/DatasetTable/colDefs/radio";
import getRatingColDef from "components/DatasetTable/colDefs/rating";
import getSelectColDef from "components/DatasetTable/colDefs/select";
import getTextAreaColDef from "components/DatasetTable/colDefs/textArea";
import getUserColDef from "components/DatasetTable/colDefs/user";
import getVideoColDef from "components/DatasetTable/colDefs/video";
import { DocumentElementType } from "enums";

const getColDef = (
  field: DataField,
  options?: { disableColumnFilters?: boolean }
): ColDef => {
  const { disableColumnFilters } = options ?? {};
  const defs: ColDef = {
    cellEditor: "agTextCellEditor",
    cellEditorParams: {},
    valueFormatter: (cell) =>
      typeof cell.value === "object" ? JSON.stringify(cell.value) : cell.value,
    filter: disableColumnFilters ? false : "agTextColumnFilter",
    filterParams: {},
    editable: true,
    cellRenderer: DefaultTextCellRenderer,
  };

  switch (field.type) {
    case DocumentElementType.Boolean:
      return getBooleanColDef(field, defs, options);
    case DocumentElementType.Rating:
      return getRatingColDef(field, defs, options);
    case DocumentElementType.Progress:
      return getProgressColDef(field, defs, options);
    case DocumentElementType.File:
      return getFileColDef(field, defs, options);
    case DocumentElementType.AudioVideo:
      return field.file_type !== "audio"
        ? getVideoColDef(field, defs, options)
        : getAudioColDef(field, defs, options);
    case DocumentElementType.TextArea:
      return getTextAreaColDef(field, defs, options);
    case DocumentElementType.Number:
      return getNumberColDef(field, defs, options);
    case DocumentElementType.Radio:
      return getRadioColDef(field, defs, options);
    case DocumentElementType.Select:
      return getSelectColDef(field, defs, options);
    case DocumentElementType.Checkbox:
      return getCheckColDef(field, defs, options);
    case DocumentElementType.CodeEditor:
      return getCodeColDef(field, defs, options);
    case DocumentElementType.Image:
      return getImageColDef(field, defs, options);
    case DocumentElementType.User:
      return getUserColDef(field, defs, options);
    case "icon":
      return getIconColDef(field, defs, options);
    case "dataset-design":
      return getDatasetDesignColDef(field, defs, options);
    case "3p-app":
      return get3pAppColDef(field, defs, options);
    default:
      return defs;
  }
};

export default getColDef;
