import Model from "models";

class AccountMetadata {
  private model = new Model<any>("", "user-mng");

  async get(slug: string) {
    return await this.model.get(slug);
  }

  async getDatasetSize() {
    return await this.model.sendRequest<StorageUsage>(`/storage/datasets`, "GET");
  }

  async getDetailDatasetSize() {
    return await this.model.sendRequest<DatasetsSize[]>(`/storage/datasets/detail`, "GET");
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AccountMetadata();
