import ChatOutlined from "@mui/icons-material/ChatOutlined";
import { Box, Stack, styled, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import useUser from "hooks/useUser";
import { FC } from "react";

dayjs.extend(utc);

const Container = styled(Stack)(({ theme }) => ({
  cursor: "pointer",
  height: "58px",
  width: "100%",
  background: theme.palette.background.CardBackground,
  borderRadius: "4px",
  alignItems: "center",
  gap: "8px",
  padding: "0 10px",
}));

const Icon = styled(Box)(({ theme }) => ({
  background: theme.palette.background.GF5,
  borderRadius: "100%",
  width: "38px",
  height: "38px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

type ThreadItemProps = {
  agent: Agent;
  thread: CopilotThread;
  onClick(): void;
};

const ThreadItem: FC<ThreadItemProps> = (props) => {
  const { agent, thread, onClick } = props;

  const theme = useTheme();

  const { data: user } = useUser(thread.user_slug);

  return (
    <Container direction="row" onClick={onClick}>
      <Icon>
        <ChatOutlined />
      </Icon>
      <Stack direction="column" justifyContent="flex-start">
        <Typography
          fontSize={14}
          fontWeight={400}
          color={theme.palette.background.GF50}
          maxWidth={300}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {agent.agent_name} - {user?.first_name ?? ""} {user?.last_name ?? ""}
        </Typography>
        <Typography
          fontSize={13}
          fontWeight={300}
          color={theme.palette.text.primary}
          maxWidth={300}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          Eliana Hanson -&nbsp;
          {dayjs.utc(thread.created_at).format("MM/DD/YY h:mm A")}
        </Typography>
      </Stack>
    </Container>
  );
};

export default ThreadItem;
