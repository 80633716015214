import { Avatar } from "@mui/material";
import { ColDef } from "ag-grid-community";
import DefaultFileCellEditor from "components/DatasetTable/colDefs/DefaultFileCellEditor";
import DefaultListCellTooltip from "components/DatasetTable/colDefs/DefaultListCellTooltip";
import ImageCellRenderer from "components/DatasetTable/colDefs/image/ImageCellRenderer";

const getImageColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => {
  return {
    ...defaults,
    cellRenderer: ImageCellRenderer,
    cellRendererParams: {
      field,
    },
    cellEditor: DefaultFileCellEditor,
    cellEditorPopup: true,
    cellEditorPopupPosition: "under",
    cellEditorParams: {
      field,
    },
    tooltipField: field.multi ? field.slug : undefined,
    tooltipComponent: DefaultListCellTooltip,
    tooltipComponentParams: {
      getListData(value: FileMeta) {
        return {
          text: value.name,
          icon: (
            <Avatar sx={{ width: "24px", height: "24px" }} src={value.url} />
          ),
        };
      },
    },
  };
};

export default getImageColDef;
