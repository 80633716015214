import { zodResolver } from "@hookform/resolvers/zod";
import PlaylistAdd from "@mui/icons-material/PlaylistAdd";
import { LoadingButton } from "@mui/lab";
import { Button, DrawerProps, Stack, styled } from "@mui/material";
import FormField from "components/FormField";
import GfDrawer from "components/GfDrawer";
import { DocumentElementType } from "enums/Form";
import useCurrentUser from "hooks/useCurrentUser";
import FusionModel from "models/Fusion";
import use3pApps from "queries/3p-app/use3pApps";
import useListAppConnections from "queries/3p-app/useListAppConnections";
import { FC, useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "utils";
import { ParamField } from "views/app-view/flow-designer/components/NodeEditorFields";
import { z } from "zod";

const DrawerStyled = styled(GfDrawer)(({ theme }) => ({}));

const formSchema = z.object({
  app_slug: z.string(),
  connection_slug: z.string(),
  connection_name: z.string(),
  connection_data: z.record(z.unknown()).optional(),
});

type FormType = z.infer<typeof formSchema>;

type CreateConnectionDrawerProps = {
  onClose(): void;
  onConnectionCreated?(): void;
} & DrawerProps;

const CreateConnectionDrawer: FC<CreateConnectionDrawerProps> = (props) => {
  const { onClose: propsOnClose, onConnectionCreated, ...drawerProps } = props;

  const user = useCurrentUser();

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
  });

  const [loading, setLoading] = useState(false);

  const appSlug = form.watch("app_slug");
  const connectionSlug = form.watch("connection_slug");

  const { data: apps = [] } = use3pApps();
  const app = apps.find((a) => a.slug === appSlug);

  const { data: connections = [] } = useListAppConnections(app?.slug, {
    is_global: `${app?.id?.startsWith("3p:global")}`,
  });
  const connection = connections.find((c) => c.slug === connectionSlug);

  const appOptions = useMemo(() => {
    return apps.map((app) => ({ label: app.app_label, value: app.slug }));
  }, [apps]);

  const connectionOptions = useMemo(() => {
    return connections.map((connection) => ({
      label: connection.label,
      value: connection.slug,
    }));
  }, [connections]);

  const onClose = useCallback(() => {
    propsOnClose();
    form.reset();
  }, [propsOnClose]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = async (data: FormType) => {
    const { connection_name, connection_data = {} } = data;

    if (!app || !connection || !user) {
      return;
    }

    setLoading(true);

    if (connection?.type === "oauth2") {
      const data = {
        app_id: app.id,
        app_slug: app.slug,
        app_connection_slug: connection.slug,
        parameters: connection_data,
        oauth_action: "authorize",
        user_slug: user.slug,
        connection_name,
      };
      setLocalStorage("fusion:oauth2", {
        ...data,
        connectionType: connection?.type,
      });

      const { data: res } = await FusionModel.createConnection(data);

      if (res.auth_url) {
        const popup = window.open(
          res.auth_url,
          "_blank",
          "width=600, height=800"
        );
        const interval = setInterval(() => {
          const authData = getLocalStorage("fusion:oauth2") as {
            query: { code: string };
          };
          if (authData?.query?.code) {
            clearInterval(interval);
            FusionModel.createConnection({
              ...data,
              oauth_action: "token",
              parameters: {
                ...data.parameters,
                query: authData.query,
                code: authData.query?.code,
              },
            }).then((conRes) => {
              onConnectionCreated?.();
              onClose();
              setLoading(false);
            });
            removeLocalStorage("fusion:oauth2");
          } else if (popup?.closed) {
            clearInterval(interval);
            setLoading(false);
          }
        }, 1000);
      }
    } else {
      FusionModel.createConnection({
        app_id: app.id,
        app_slug: app.slug,
        app_connection_slug: connection.slug,
        parameters: connection_data,
        user_slug: user.slug,
        connection_name,
      }).then((res) => {
        onClose();
        onConnectionCreated?.();
        setLoading(false);
      });
    }
  };

  return (
    <DrawerStyled
      anchor="right"
      onClose={handleClose}
      title="Create Connection"
      icon={<PlaylistAdd />}
      width="420px"
      defaultTabKey={0}
      ModalProps={{
        keepMounted: false,
      }}
      keepMounted={false}
      variant="temporary"
      sx={{
        ".MuiPaper-root": {
          height: "100%",
        },
      }}
      {...drawerProps}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Stack m={2} gap={2}>
            <FormField
              label="App"
              type={DocumentElementType.Select}
              fieldExtras={{
                field: { list_items: appOptions },
              }}
              formControl={{ control: form.control, name: "app_slug" }}
            />
            <FormField
              label="Connection"
              type={DocumentElementType.Select}
              fieldExtras={{
                field: { list_items: connectionOptions },
              }}
              formControl={{ control: form.control, name: "connection_slug" }}
            />
            {connection && (
              <>
                <FormField
                  label="Connection Name"
                  type={DocumentElementType.TextField}
                  formControl={{
                    control: form.control,
                    name: "connection_name",
                  }}
                />
                {connection.app_parameters?.map((param) => (
                  <ParamField
                    field={param}
                    parentNamePath="connection_data"
                    key={param.name}
                    mappable={false}
                    fieldComponentProps={{ variant: "outlined" }}
                  />
                ))}
                <Stack direction="row" alignItems="center" gap={2} mx="auto">
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    type="button"
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={loading}
                    disabled={loading}
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              </>
            )}
          </Stack>
        </form>
      </FormProvider>
    </DrawerStyled>
  );
};

export default CreateConnectionDrawer;
