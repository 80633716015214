import GridViewOutlined from "@mui/icons-material/GridViewOutlined";
import { Box, DrawerProps, styled } from "@mui/material";
import GfDrawer from "components/GfDrawer";
import Scrollbar from "components/Scrollbar";
import AnimationLayout, { Config } from "layouts/AnimationLayout";
import { FC, useCallback, useEffect, useState } from "react";
import ParamEditor from "views/app-view/agent/TabContent/AgentSkills/AgentSkillEditDrawer/ParamEditor";
import SkillForm from "./SkillForm";

const ScrollbarContainer = styled(Box)({
  height: "calc(100vh - 60px)",
});

type AgentSkillEditDrawerProps = {
  skill?: AgentSkill;
  onSubmitDone?(): void;
} & DrawerProps;

const AgentSkillEditDrawer: FC<AgentSkillEditDrawerProps> = (props) => {
  const { open, onClose, onSubmitDone, ...drawerProps } = props;

  const [skill, setSkill] = useState<AgentSkill>();

  const [selectedParam, setSelectedParam] = useState<AgentSkillParameter>();

  useEffect(() => {
    setSkill(props.skill);
  }, [props.skill]);

  const getComponents: Config["getComponents"] = useCallback(
    (gotoComponent, goBack) => ({
      main: (
        <SkillForm
          onClose={() => onClose?.({}, "escapeKeyDown")}
          skill={skill!}
          onSubmitDone={() => onSubmitDone?.()}
          onAddParam={() => {
            gotoComponent({ name: "add-param", id: "add-param" });
          }}
          onEditParam={(param) => {
            setSelectedParam(param);
            gotoComponent({ name: "add-param", id: param.id });
          }}
        />
      ),
      "add-param": (
        <ParamEditor
          param={selectedParam}
          skill={skill!}
          onClose={() => {
            goBack();
            onSubmitDone?.();
          }}
          updateParamCallback={(params) => {
            setSkill((prev) =>
              prev ? { ...prev, parameters: params } : undefined
            );
          }}
        />
      ),
    }),
    [onClose, onSubmitDone, skill, selectedParam]
  );

  return (
    <GfDrawer
      anchor="right"
      open={open}
      width="420px"
      title="Edit Agent Skill"
      icon={<GridViewOutlined />}
      onClose={onClose}
      {...drawerProps}
    >
      <ScrollbarContainer>
        <Scrollbar>
          {skill && (
            <AnimationLayout
              config={{
                getComponents,
                initialComponent: "main",
              }}
              urlQueryKey="s"
            />
          )}
        </Scrollbar>
      </ScrollbarContainer>
    </GfDrawer>
  );
};

export default AgentSkillEditDrawer;
