import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type EditDrawer =
  | {
      enabled: true;
      type: "skill";
      skill?: AgentSkill;
    }
  | {
      enabled: true;
      type: "document";
      document?: AgentDocument;
    }
  | {
      enabled: false;
    };

type State = {
  editDrawer: EditDrawer;
};

type Actions = {
  setEditDrawer: (value: EditDrawer) => void;
};

export const useSSAgentBase = create(
  devtools(
    immer<State & Actions>((set) => ({
      editDrawer: {
        enabled: false,
      },
      setEditDrawer(value) {
        set((state) => {
          state.editDrawer = value;
        });
      },
    }))
  )
);

const useSSAgent = createSelectorHooks(useSSAgentBase);

export default useSSAgent;
