import { UniqueIdentifier } from "@dnd-kit/core";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useMutation } from "@tanstack/react-query";
import Scrollbar from "components/Scrollbar";
import SearchInput from "components/SearchInput";
import SimpleInputWrapper from "components/SimpleInputWrapper";
import Fuse from "fuse.js";
import useRouteToApiModel from "hooks/useRouteToApiModel";
import debounce from "lodash/debounce";
import DatasetDesignModel from "models/DatasetDesign";
import FolderModel from "models/Folder";
import { useSnackbar } from "notistack";
import { queryClient } from "queries";
import { ApiModels } from "queries/apiModelMapping";
import useDeleteItem from "queries/useDeleteItem";
import useFolders from "queries/useFolders";
import * as React from "react";
import { useSystemLayoutStore } from "store/stores/systemLayout";
import Swal from "sweetalert2";
import { exportData, readFile } from "utils";

import { Stack, Typography } from '@mui/material';
import { EditIcon, ExportIcon, ImportIcon } from 'assets/icons';
import ButtonAdd from "./ButtonAdd";
import FolderRenameModel from "./FolderRenameModel";
import SideNavItemsListing, { SortItemType } from "./ItemsListing";
import { BoxWrap, Drawer, DrawerOverlay } from "./index.styles";

const options = {
  // isCaseSensitive: false,
  // includeScore: false,
  shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  threshold: 0.3,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ["title"],
};

type SideNavProps = {
  disableDrawer?: boolean;
};

const SideNavV2: React.FC<SideNavProps> = (props) => {
  const itemsLoading = useSystemLayoutStore.useSideNavItemsLoading();
  const rawMenu = useSystemLayoutStore.useMenu();
  const layout = useSystemLayoutStore.useLayout();
  const disableFolders = useSystemLayoutStore.useDisableFolders();
  const showActionButtons = useSystemLayoutStore.useShowActionButtons();
  const buttonOptions = useSystemLayoutStore.useButtonOptions();
  const enableSideNavSearch = useSystemLayoutStore.useEnableSideNavSearch();

  const onRename = useSystemLayoutStore.useOnRename?.();
  const { enqueueSnackbar } = useSnackbar();
  const [itemContextMenu, setItemContextMenu] =
    React.useState<{
      mouseX: number;
      mouseY: number;
      item: AppItem;
    } | null>(null);
  const [containerContextMenu, setContainerContextMenu] =
    React.useState<{
      mouseX: number;
      mouseY: number;
      item: Folder;
    } | null>(null);
  const [isRenameModelOpen, setRenameModelOpen] = React.useState(false);
  const [selectedFolder, setSelectedFolder] = React.useState<Folder | null>();
  const routeModule = useRouteToApiModel();
  const { data: folders } = useFolders();
  const fuseRef = React.useRef(new Fuse(rawMenu, options));
  const searchValue = useSystemLayoutStore.useSearchValue?.();
  const setSearchValue = useSystemLayoutStore.useSetSearch?.();
  const useCustomSearch = useSystemLayoutStore.useCustomSearch?.();
  const [sortItems, setSortItems] = React.useState<SortItemType>([]);
  const { mutate: updateItemSort } = useMutation({
    mutationFn: async ({ data }: any) => {
      await FolderModel.sort(data);
    },
    onSuccess: (_) => {
      console.log("success");
    },
  });

  const { mutate: deleteItem } = useDeleteItem({
    modelName: routeModule! as any,
  });

  React.useEffect(() => {
    fuseRef.current = new Fuse(rawMenu, options);
  }, [rawMenu]);

  const menu = React.useMemo(() => {
    if (!searchValue?.length) return rawMenu;
    if (useCustomSearch) return rawMenu;
    return fuseRef.current.search(searchValue)?.map((i) => i.item);
  }, [searchValue, rawMenu]);

  const { sortItemFolderMap, sortItemMap } = React.useMemo(() => {
    const sortItems: SortItemType = [];

    const sortItemFolderMap = new Map();
    const sortItemMap = new Map();
    /// When in Search view no need to drag and drop
    if (layout === "search" || !searchValue.length) {
      menu.forEach((m) => {
        sortItemMap.set(m.key, m);
      });
      folders
        ?.sort((a, b) => a.sort_order - b.sort_order)
        .forEach((folder) => {
          const children: typeof sortItems[0]["children"] = [];

          folder.childs.forEach((child) => {
            if (child?.slug && sortItemMap.get(child.slug))
              children.push({
                id: child.slug,
                data: { id: child.id, slug: child.slug },
              });
          });

          sortItems.push({
            id: folder.slug,
            children,
          });
          sortItemFolderMap.set(folder.slug, folder);
        });
    }
    setSortItems(sortItems);
    return { sortItemFolderMap, sortItemMap };
  }, [layout, searchValue.length, folders, menu]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const containerSortingHandler = (containers: SortItemType) => {
    setSortItems(containers);
    const data = containers.map((ctr) => {
      return {
        id: ctr.id,
      };
    });

    updateItemSort({ data: { data, resource: routeModule } });
  };
  // Interesting problem. if someone else creates a item and someone else sorted it. it's gonna make it disable in folder listing
  const itemSortingHandler = (
    items: typeof sortItems[0]["children"],
    containerId: UniqueIdentifier
  ) => {
    setSortItems((prev) =>
      prev.map((ct) => {
        if (ct.id === containerId) {
          return { ...ct, children: items };
        }
        return ct;
      })
    );
    const data = [
      {
        id: containerId,
        childs: items.map((itkey) => {
          return {
            id: itkey.data.id,
            slug: itkey.id,
          };
        }),
      },
    ];

    updateItemSort({ data: { data, resource: routeModule } });
  };

  const handleItemContextMenu = React.useCallback(
    (event: React.MouseEvent, extra: AppItem) => {
      event.preventDefault();
      event.stopPropagation();
      setItemContextMenu(
        itemContextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
              item: extra,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null
      );
    },
    [itemContextMenu]
  );
  const handleContainerContextMenu = React.useCallback(
    (event: React.MouseEvent, id: string) => {
      event.preventDefault();
      setContainerContextMenu(
        containerContextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
              item: sortItemFolderMap.get(id),
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null
      );
    },
    [containerContextMenu, sortItemFolderMap]
  );
  const handleItemContextMenuClose = () => {
    setItemContextMenu(null);
  };
  const handleRenameItem = () => {
    onRename?.({
      title: itemContextMenu?.item.title!,
      key: itemContextMenu?.item.key!,
    });
    handleItemContextMenuClose();
  };

  const handleContainerContextMenuClose = () => {
    setContainerContextMenu(null);
  };
  const handleRenameContainer = () => {
    setSelectedFolder(containerContextMenu?.item);
    setRenameModelOpen(true);
    handleContainerContextMenuClose();
  };

  const handleRenameModelClose = () => {
    setSelectedFolder(null);
    setRenameModelOpen(false);
  };
  const ItemContextMenuOpen = itemContextMenu !== null;
  const containerContextMenuOpen = containerContextMenu !== null;

  const handleOnDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteItem({ slug: itemContextMenu?.item?.key! });
      }
    });

    handleItemContextMenuClose();
  };

  const content = (
    <>
      <BoxWrap className="new-design">
        {enableSideNavSearch && (
          <Box sx={{ py: 1.375, px: 1.75 }} className="search_bar">
            <SearchInput
              id="outlined-adornment-password"
              placeholder="Search..."
              size="small"
              sx={{ m: 0 }}
              onChange={debounce((e) => {
                handleSearch(e.target.value);
              }, 300)}
            />
            {buttonOptions.addItem && <ButtonAdd />}
          </Box>
        )}
        <Scrollbar className='sidenav-scroller' autoHide={false}>
          <SideNavItemsListing
            className="storting_list_pad"
            listContainerClassName="dataset_sort_item"
            listItemClassName="new_dataset_sort_item"
            enableContextMenu={false}
            enableItemOptionMenu
            layout={layout}
            searchValue={searchValue}
            disableFolders={disableFolders}
            menu={menu}
            itemsLoading={itemsLoading}
            sortItems={sortItems}
            sortItemFolderMap={sortItemFolderMap}
            sortItemMap={sortItemMap}
            containerSortingHandler={containerSortingHandler}
            handleContainerContextMenu={handleContainerContextMenu}
            handleItemContextMenu={handleItemContextMenu}
            itemSortingHandler={itemSortingHandler}
          />
        </Scrollbar>
      </BoxWrap>
      {/* {showActionButtons && (
        <BottomSection className="bottom-btns">
          {buttonOptions.addFolder && <ButtonAddFolder />}
          {buttonOptions.addItem && <ButtonAdd />}
        </BottomSection>
      )} */}
    </>
  );
  const exportDataset = async () => {
    handleItemContextMenuClose();
    enqueueSnackbar("Exporting Dataset", { variant: "info" });
    const { data } = await DatasetDesignModel.get(itemContextMenu?.item?.key!);
    exportData(JSON.stringify(data));
  };

  const importDataset: React.InputHTMLAttributes<HTMLInputElement>["onChange"] =
    async (e) => {
      handleContainerContextMenuClose();
      if (e.target?.files?.length === 0) return;
      enqueueSnackbar("Importing dataset", { variant: "info" });
      const data = await readFile(e.target.files?.[0]!);
      const dataset = JSON.parse(data as string) as unknown as
        | DatasetDesign
        | DatasetDesign[];
      try {
        if (Array.isArray(dataset)) {
          await DatasetDesignModel.importBatch({
            data: dataset,
            folder: containerContextMenu?.item?.slug!,
          });
        } else if (dataset.slug) {
          await DatasetDesignModel.import({
            data: dataset,
            folder: containerContextMenu?.item?.slug!,
          });
        }
        queryClient.refetchQueries([ApiModels.Folder, ApiModels.DatasetDesign]);
        queryClient.refetchQueries([ApiModels.DatasetDesign]);
        enqueueSnackbar("dataset successfully imported", {
          variant: "success",
        });
      } catch (e) {
        console.error(e);
        enqueueSnackbar("failed importing", { variant: "error" });
      }
    };
  const exportDatasets = async () => {
    handleContainerContextMenuClose();
    enqueueSnackbar("Exporting Dataset", { variant: "info" });
    const { data } = await DatasetDesignModel.exportFolderDatasetDesign(
      containerContextMenu?.item?.slug!
    );
    exportData(JSON.stringify(data));
  };

  return (
    <Box>
      <FolderRenameModel
        folder={selectedFolder!}
        isOpen={isRenameModelOpen}
        onClose={handleRenameModelClose}
      />

      {/*Start Menu Item Context Menu */}
      <Menu
        open={ItemContextMenuOpen}
        onClose={handleItemContextMenuClose}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "6px",
            },
          },
        }}
        MenuListProps={{
          sx: {
            background: (theme) => theme.palette.background.GFPaper,
            border: (theme) => `1px solid ${theme.palette.gfGrey.Divider}`,
            borderRadius: "6px",
            minWidth: "200px",
            px: 1,

            ".MuiMenuItem-root": {
              borderRadius: "6px",
              transition: "background 0.2s ease",

              "&:hover": {
                background: (theme) => theme.palette.background.Hover,
              },
            },
          },
        }}
        anchorReference="anchorPosition"
        anchorPosition={
          ItemContextMenuOpen
            ? { top: itemContextMenu.mouseY, left: itemContextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleRenameItem}>Rename</MenuItem>
        <MenuItem onClick={handleOnDelete}>Delete</MenuItem>
        {routeModule === "dataset-design" && (
          <MenuItem onClick={exportDataset}>Export</MenuItem>
        )}
      </Menu>
      {/*End Menu Item Context Menu */}

      {/*Start container Menu Context Menu */}
      <Menu
        open={containerContextMenuOpen}
        onClose={handleContainerContextMenuClose}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "6px",
            },
          },
        }}
        MenuListProps={{
          sx: {
            background: (theme) => theme.palette.background.GFPaper,
            border: (theme) => `1px solid ${theme.palette.gfGrey.Divider}`,
            borderRadius: "6px",
            minWidth: "240px",
            px: 1,
            ".MuiMenuItem-root": {
              borderRadius: "6px",
              transition: "background 0.2s ease",

              "&:hover": {
                background: (theme) => theme.palette.background.Hover,
              },
            },
          },
        }}
        anchorReference="anchorPosition"
        anchorPosition={
          containerContextMenuOpen
            ? {
                top: containerContextMenu.mouseY,
                left: containerContextMenu.mouseX,
              }
            : undefined
        }
      >
        <MenuItem className="flex" onClick={handleRenameContainer}>
          <Stack direction="row" alignItems={"center"} gap="8px">
            <EditIcon />
            <Typography  fontSize={"13px"} >Rename folder</Typography>
          </Stack>{" "}
        </MenuItem>
        {routeModule === "dataset-design" && (
          <MenuItem onClick={exportDatasets}>
            <Stack direction="row" alignItems={"center"} gap="8px">
              <ExportIcon />
              <Typography  fontSize={"13px"} >Export All Datasets</Typography>
            </Stack>
          </MenuItem>
        )}
        {routeModule === "dataset-design" && (
          <MenuItem>
            <SimpleInputWrapper
              onChange={importDataset}
              accept="json"
              multiple={false}
            >
              <Stack direction="row" alignItems={"center"} gap="8px">
                {" "}
                <ImportIcon />
                <Typography fontSize={"13px"}>
                  Import
                </Typography>
              </Stack>
            </SimpleInputWrapper>
          </MenuItem>
        )}
      </Menu>
      {/*End container Menu Context Menu */}

      {props.disableDrawer ? (
        content
      ) : (
        <Drawer
          variant="permanent"
          open
          PaperProps={{
            className: "side_nav_drawer dataset_design_drawer",
            sx: {
              zIndex: (theme) => theme.zIndex.appBar - 1,
              background: (theme) => theme.palette.background.LeftNavBody,
              top: "98px !important",
              width: "235px",

              "&.dataset_design_drawer": {
                boxShadow: "none",
                borderTopRightRadius: "6px",
              },
            },
          }}
        >
          {content}
          <DrawerOverlay className="overlay-holder"></DrawerOverlay>
        </Drawer>
      )}
    </Box>
  );
};

export default SideNavV2;
