import { FC, memo } from "react";
import ChatSection from "./ChatSection";

type TabSelectorProps = {
  tabIndex: number;
};

const TabSelector: FC<TabSelectorProps> = memo(
  (props) => {
    const { tabIndex } = props;

    switch (tabIndex) {
      case 0:
        return <ChatSection />;
      default:
        return <></>;
    }
  },
  (prev, next) => prev.tabIndex === next.tabIndex
);

export default TabSelector;
