import { ColDef } from "ag-grid-community";
import DefaultFileCellEditor from "components/DatasetTable/colDefs/DefaultFileCellEditor";
import FileCellRenderer from "components/DatasetTable/colDefs/file/FileCellRenderer";

const getFileColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => {
  return {
    ...defaults,
    cellRenderer: FileCellRenderer,
    cellEditor: DefaultFileCellEditor,
    cellEditorPopup: true,
    cellEditorPopupPosition: "under",
    cellEditorParams: {
      field,
    },
  };
};

export default getFileColDef;
