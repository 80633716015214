import { ColDef } from "ag-grid-community";
import CodeCellEditor from "components/DatasetTable/colDefs/code/CodeCellEditor";
import CodeCellRenderer from "components/DatasetTable/colDefs/code/CodeCellRenderer";
import CodeCellTooltip from "components/DatasetTable/colDefs/code/CodeCellTooltip";

const getCodeColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => ({
  ...defaults,
  tooltipField: field.slug,
  cellRenderer: CodeCellRenderer,
  cellEditor: CodeCellEditor,
  tooltipComponent: CodeCellTooltip,
  tooltipComponentParams: {
    field,
  },
});

export default getCodeColDef;
