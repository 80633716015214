import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import { Avatar, Stack, Typography, styled } from "@mui/material";
import MessageLoading from "assets/icons/MessageLoading";
import { FC } from "react";

const Container = styled(Stack)({
  marginTop: "20px",
});

type ChatMessageItemLoadingProps = {
  statusMessage?: string;
};

const ChatMessageItemLoading: FC<ChatMessageItemLoadingProps> = (props) => {
  const { statusMessage } = props;

  return (
    <Container>
      <Stack direction="row" alignItems="center" gap={2}>
        <Avatar
          variant="circular"
          sx={{
            width: "32px",
            height: "32px",
            background: (theme) => theme.palette.primary.main,
            color: "#ffffff",
          }}
        >
          <SettingsOutlined />
        </Avatar>
        <Typography fontWeight={500} fontSize={14}>
          Assistant
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1} marginLeft="48px">
        <MessageLoading
          sx={{ color: (theme) => theme.palette.primary.main }}
          fontSize="large"
        />
        <Typography fontSize={14}>{statusMessage}</Typography>
      </Stack>
    </Container>
  );
};

export default ChatMessageItemLoading;
