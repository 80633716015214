import { Popper } from "@mui/material";
import { INSERT_TAG_COMMAND } from "components/LexicalTagField/AddTagPlugin";
import { MappableTagType } from "enums/Fusion";
import { FC, useEffect, useState } from "react";
import { useLexicalTagField } from "store/stores/lexical-tag-field";
import ParamMapper from "views/app-view/flow-designer/components/ParamFields/ParamMapper";

type ParamMapperPopoverProps = {};

const ParamMapperPopover: FC<ParamMapperPopoverProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const activeField = useLexicalTagField.useActiveField();
  const setActiveField = useLexicalTagField.useSetActiveField();
  const dispatchCommand = useLexicalTagField.useDispatchEditorCommand();

  useEffect(() => {
    if (activeField) {
      const element = document.getElementById(activeField);
      setAnchorEl(element);
    } else {
      setAnchorEl(null);
    }
  }, [activeField]);

  const open = Boolean(anchorEl);

  return (
    <Popper
      id="param-mapper-popper"
      open={open}
      anchorEl={anchorEl}
      placement="left"
      sx={{
        zIndex: (theme) => theme.zIndex.tooltip,
        width: 450,
        padding: 0,
        maxWidth: "none",
        maxHeight: "800px",
        backgroundColor: (theme) =>
          theme.palette.background.GFRightNavBackground,
        overflow: "hidden",
      }}
    >
      <ParamMapper
        onTagClick={(func) => {
          if (activeField) {
            dispatchCommand(activeField, INSERT_TAG_COMMAND, {
              type: MappableTagType.Function,
              data: func,
            });
          }
        }}
        onOperatorClick={(param) => {
          if (activeField) {
            dispatchCommand(activeField, INSERT_TAG_COMMAND, {
              type: MappableTagType.Variable,
              data: param,
            });
          }
        }}
        onClose={() => setActiveField(null)}
      />
    </Popper>
  );
};

export default ParamMapperPopover;
