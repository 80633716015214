import { Box, Stack, styled } from "@mui/material";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import useSocket from "hooks/useSocket";
import cloneDeep from "lodash/cloneDeep";
import useMessages from "queries/copilot/useMessages";
import { FC, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import useGFChatWindow from "store/stores/gf-chat-window";
import ChatInputField from "./ChatInputField";
import ChatMessageItem from "./ChatMessageItem";
import ChatMessageItemLoading from "./ChatMessageItemLoading";

const ChatMessagesContainer = styled(Stack)({
  height: "100%",
  color: "#000000",
});

const InfiniteScrollContainer = styled(Box)({
  overflow: "auto",
  display: "flex",
  flexDirection: "column-reverse",

  /* width */
  "::-webkit-scrollbar": {
    width: "10px",
  },

  /* Track */
  "::-webkit-scrollbar-track": {
    background: "transparent",
  },

  /* Handle */
  "::-webkit-scrollbar-thumb": {
    background: "#00619A66",
    borderRadius: "4px",
    backgroundClip: "padding-box",
    border: "2px solid #ffffff",
  },

  /* Handle on hover */
  "::-webkit-scrollbar-thumb:hover": {
    background: "#00619A66",
  },
});

const ChatMessages: FC = () => {
  const queryClient = useQueryClient();

  const { subscribe, unsubscribe } = useSocket();

  const activeChat = useGFChatWindow.useActiveChat();
  const showLoadingMessage = useGFChatWindow.useShowLoadingMessage();
  const setShowLoadingMessage = useGFChatWindow.useSetShowLoadingMessage();

  const [streamingMessage, setStreamingMessage] = useState("");
  const [currentRunId, setCurrentRunId] = useState<string>();

  const { data, refetch } = useMessages(activeChat);

  const messages = useMemo(
    () =>
      data?.pages.reduce<CopilotMessage[]>((acc, cur) => {
        acc.push(...cur.data);

        return acc;
      }, []) ?? [],
    [data]
  );

  useEffect(() => {
    subscribe(
      "copilot_response",
      "copilot_response",
      (response: SocketResponse) => {
        const data = response.data as {
          eventName: string;
          eventData: Record<string, any>;
          threadSlug: string;
        };

        if (data.threadSlug !== activeChat) {
          return;
        }
        if (showLoadingMessage && data.eventName === "thread.message.delta") {
          setShowLoadingMessage(false);
        }

        if (data.eventName === "thread.run.created") {
          setCurrentRunId(data.eventData.id);
        }
        if (data.eventName === "thread.message.delta") {
          try {
            let text = "";
            data.eventData.delta.content.forEach((c: any) => {
              if (c.type === "text") {
                const value = c.text.value;
                if (value) {
                  text += value;
                }
              }
            });

            setStreamingMessage((prev) => `${prev}${text}`);
          } catch (e) {
            console.log(e);
          }
        } else if (data.eventName === "message_created") {
          setStreamingMessage("");
          queryClient.setQueryData<InfiniteData<ApiResponse<CopilotMessage[]>>>(
            ["copilot", "threads", activeChat, "messages"],
            (oldData) => {
              const prev = cloneDeep(oldData);
              if (!prev) {
                return prev;
              }

              prev.pages.at(-1)?.data.unshift(data.eventData as CopilotMessage);

              return prev;
            }
          );
        } else if (data.eventName === "thread.run.completed") {
          setCurrentRunId(undefined);
        }
      }
    );

    return () => {
      unsubscribe("copilot_response", "copilot_response");
    };
  }, [
    subscribe,
    unsubscribe,
    setShowLoadingMessage,
    streamingMessage,
    showLoadingMessage,
    queryClient,
    activeChat,
  ]);

  return (
    <ChatMessagesContainer>
      <InfiniteScrollContainer id="chat-messages-container">
        <InfiniteScroll
          dataLength={
            messages.length +
            (showLoadingMessage ? 1 : 0) +
            (!!streamingMessage ? 1 : 0)
          }
          next={() => {}}
          style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
          inverse={true}
          hasMore={true}
          loader={<h4>Loading...</h4>}
          scrollableTarget="chat-messages-container"
        >
          {showLoadingMessage && <ChatMessageItemLoading />}
          {streamingMessage && (
            <ChatMessageItem
              data={{
                value: streamingMessage,
                sender: "assistant",
                id: "",
                slug: "",
              }}
            />
          )}
          {messages.map((message) => (
            <ChatMessageItem data={message} key={message.slug} />
          ))}
        </InfiniteScroll>
      </InfiniteScrollContainer>
      <ChatInputField />
    </ChatMessagesContainer>
  );
};

export default ChatMessages;
