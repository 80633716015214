import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as DeleteIconSvg } from "../svg/deleteIcon2.svg";

const DeleteIcon: React.FC<React.ComponentProps<typeof SvgIcon>> = (props) => {
  return (
    <SvgIcon
      inheritViewBox
      {...props}
      component={DeleteIconSvg}
      className="plus-icon"
    />
  );
};

export default DeleteIcon;
