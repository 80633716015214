import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot } from "lexical";
import { FC, useEffect } from "react";

type ValueChangePluginProps = {
  value: string;
};

const ValueChangePlugin: FC<ValueChangePluginProps> = (props) => {
  const { value } = props;

  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (editor) {
      editor.update(() => {
        $getRoot().clear();

        $convertFromMarkdownString(value, TRANSFORMERS);
        // const lines = value.split("\n");
        // let isCodeBlock = false;
        // let codeText = "";
        // let code: CodeNode | undefined;
        // let language = "";

        // let paragraph = $createParagraphNode();
        // $getRoot().append(paragraph);

        // for (const [index, line] of lines.entries()) {
        //   if (line.startsWith("```")) {
        //     isCodeBlock = !isCodeBlock;
        //     if (!isCodeBlock) {
        //       code = undefined;
        //       language = "";
        //       paragraph = $createParagraphNode();
        //       $getRoot().append(paragraph);
        //     } else {
        //       language = line.slice(3);
        //     }
        //     continue;
        //   }

        //   if (isCodeBlock) {
        //     codeText += `${line}\n`;
        //     if (!code) {
        //       const codeNode = $createCodeNode(`${codeText}`, language);
        //       $getRoot().append(codeNode);
        //       code = codeNode;
        //     } else {
        //       const codeNode = $createCodeNode(`${codeText}`, language);
        //       code.replace(codeNode);
        //       code = codeNode;
        //       // code?.updateCode(`${codeText}`);
        //     }
        //   } else {
        //     if (index !== 0 && index !== lines.length - 1) {
        //       const lineBreak = $createLineBreakNode();
        //       paragraph.append(lineBreak);
        //     }
        //     const text = $createTextNode(line);
        //     paragraph.append(text);
        //   }
        // }
      });
    }
  }, [editor, value]);

  return null;
};

export default ValueChangePlugin;
