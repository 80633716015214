import { ColDef } from "ag-grid-community";
import BooleanCellEditor from "components/DatasetTable/colDefs/boolean/BooleanCellEditor";
import BooleanCellRenderer from "components/DatasetTable/colDefs/boolean/BooleanCellRenderer";

const getBooleanColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => {
  return {
    ...defaults,
    valueGetter: (params) => {
      return params.data?.[field.slug] === 0 ? false : true;
    },
    cellRenderer: BooleanCellRenderer,
    cellEditor: BooleanCellEditor,
  };
};

export default getBooleanColDef;
