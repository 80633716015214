import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  styled,
} from "@mui/material";
import FormField from "components/FormField";
import IconPickerField from "components/IconPicker";
import Scrollbar from "components/Scrollbar";
import { AllIconPickerIcons } from "constants/index";
import { DocumentElementType } from "enums/Form";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const Container = styled(Stack)({
  maxWidth: "600px",
  width: "100%",
});

const formSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  instruction: z.string().optional(),
  icon: z.string().optional(),
  tag: z.string().optional(),
  agent_skill_slug: z.string(),
});

type AddAgentSkillFormType = z.infer<typeof formSchema>;

type AddAgentSkillModalProps = {
  agent: Agent;
  onClose?(): void;
  onCreated?(): void;
} & DialogProps;

const AddAgentSkillModal: FC<AddAgentSkillModalProps> = (props) => {
  const { agent, onClose, onCreated, ...dialogProps } = props;

  const form = useForm<AddAgentSkillFormType>({
    resolver: zodResolver(formSchema),
  });

  const { control, handleSubmit } = form;

  const { mutateAsync: createAgentSkill, isLoading } = useCreateItem({
    modelName: ApiModels.SSAgentSkill,
  });

  useEffect(() => {
    return () => {
      form.reset();
    };
  }, [props.open]);

  const submitHandler = async (values: AddAgentSkillFormType) => {
    const { icon, ...data } = values;
    await createAgentSkill({
      ...data,
      meta_data: { icon },
      agent_slug: agent.slug,
    });

    onClose?.();
    onCreated?.();
  };

  return (
    <Dialog
      onClose={(e, r) => {
        console.log(e, r);
      }}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Add Agent Skill</DialogTitle>
      <Scrollbar className="form-scroller">
        <DialogContent>
          <FormProvider {...form}>
            <Stack alignItems="center">
              <Container gap={2}>
                <FormField
                  label="Skill Name"
                  type={DocumentElementType.TextField}
                  formControl={{ control, name: "title" }}
                />
                <FormField
                  label="Skill Slug"
                  type={DocumentElementType.TextField}
                  formControl={{ control, name: "agent_skill_slug" }}
                />
                <FormField
                  label="Skill Description"
                  type={DocumentElementType.TextArea}
                  formControl={{ control, name: "description" }}
                />
                <FormField
                  label="Skill Instruction"
                  type={DocumentElementType.TextArea}
                  formControl={{ control, name: "instruction" }}
                />
                <FormField
                  label="Skill Tag"
                  type={DocumentElementType.TextField}
                  formControl={{ control, name: "tag" }}
                />
                <FormField
                  label="Skill Icon"
                  formControl={{ control, name: "icon" }}
                >
                  {({ value, onChange }) => (
                    <IconPickerField
                      icons={AllIconPickerIcons}
                      value={value as string}
                      onChange={onChange}
                      textFieldProps={{ fullWidth: true, variant: "filled" }}
                    />
                  )}
                </FormField>
              </Container>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Scrollbar>
      <DialogActions>
        <LoadingButton onClick={onClose}>Cancel</LoadingButton>
        <LoadingButton
          onClick={handleSubmit(submitHandler, (error) => {
            console.log(error);
          })}
          variant="contained"
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddAgentSkillModal;
