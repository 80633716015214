import ExpandLess from "@mui/icons-material/ExpandLess";
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { ReactNode, useState } from "react";

const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.background.CardBackground,
  borderRadius: "6px",
  padding: "8px",
}));

const ItemContainer = styled(Box)(({ theme }) => ({
  padding: "4px",
  borderRadius: "6px",
  background: theme.palette.background.ListWidgetItem,
}));

type ListWidgetProps<T> = {
  title?: string;
  items?: T[];
  itemRenderer?(item: T): ReactNode;
};

const ListWidget = <T,>(props: ListWidgetProps<T>) => {
  const { items = [], title, itemRenderer } = props;

  const [collapsed, setCollapsed] = useState(true);

  return (
    <Collapse in={collapsed} collapsedSize={40} sx={{ borderRadius: "6px" }}>
      <Container>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "30px", px: 1, mb: 1 }}
        >
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontWeight: "500 !important",
              fontSize: "13px !important",
            }}
          >
            {title}
          </Typography>
          <IconButton
            sx={{
              p: 0,
              transform: collapsed ? undefined : "rotate(180deg)",
              transition: "transform 0.2s ease",
            }}
            size="small"
            onClick={() => setCollapsed(!collapsed)}
          >
            <ExpandLess fontSize="small" />
          </IconButton>
        </Stack>
        <Stack gap={1}>
          {items.map((item) => (
            <ItemContainer>{itemRenderer?.(item)}</ItemContainer>
          ))}
        </Stack>
      </Container>
    </Collapse>
  );
};

export default ListWidget;
