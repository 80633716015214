import { Typography } from "@mui/material";
import Cell from "components/DatasetTable/Cell";
import { FC, ReactNode } from "react";

type DefaultTextCellDisplayProps = {
  text?: string;
  icon?: ReactNode;
  data?: unknown;
};

const DefaultTextCellDisplay: FC<DefaultTextCellDisplayProps> = (props) => {
  return (
    <Cell
      sx={{
        gap: 1,
      }}
      isLoading={!props.data}
    >
      {props.icon}
      <Typography
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        variant="body2"
      >
        {props.text}
      </Typography>
    </Cell>
  );
};

export default DefaultTextCellDisplay;
