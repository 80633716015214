import { UniqueIdentifier } from "@dnd-kit/core/dist/types";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import { ArrowBack } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { MultipleContainers } from "components/drag-drop/MultipleContainer";
import { DraggableItemProps } from "components/drag-drop/components/Item/Item";
import { GuiType } from "enums/gui";
import { ApiModels } from "queries/apiModelMapping";
import { useGuiTabs } from "queries/gui/useGuiTabs";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import React, { CSSProperties, useMemo } from "react";
import useSystemLayoutStore from "store";
import { normalizeObjectForAPI } from "utils";
import DragFieldItem from "./DragFieldItem";
const ListItemStyle = styled(Box)(() => ({
  marginBottom: 10,
}));

const FieldsWrapper = styled(Box)(({ theme }) => {
  return {
    margin: "0 0 6px",

    ".title": {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "400",
      margin: "0 0 16px",
      textTransform: "capitalize",
    },
  };
});

const FieldsWrap = styled(Stack)(({ theme }) => {
  return {
    ".draggable-handle": {
      color: theme.palette.background.GF20,

      "&:hover": {
        background: "none",
        color: theme.palette.background.GF80,
      },

      svg: {
        color: "currentColor",
        fill: "currentColor",
      },
    },
  };
});

const FieldsSearchSelection: React.FC<{
  gui: GfGui;
  indexKey: "form_fields" | "search_fields";
  onClickBack: () => void;
}> = ({ gui, onClickBack, indexKey = "search_fields" }) => {
  const selectedTab = useSystemLayoutStore.useSelectedTopBarTab();

  const { data: guiTabs = [] } = useGuiTabs(gui?.slug);

  const guiTab = useMemo(() => {
    if (selectedTab != null && guiTabs) {
      return guiTabs?.[Number(selectedTab)];
    }
  }, [guiTabs, selectedTab]);

  const datasetDesignSlug =
    guiTab?.tab_type === GuiType.RecordList ||
    guiTab?.tab_type === GuiType.Reviewer
      ? guiTab.dataset_design_slug
      : undefined;

  const includedFields = useMemo(() => {
    if (guiTab?.tab_type === GuiType.Reviewer) {
      return indexKey === "form_fields" ? guiTab.form_fields || [] : [];
    }

    return guiTab?.tab_type === GuiType.RecordList
      ? guiTab?.[indexKey] || []
      : [];
  }, [guiTab, indexKey]);

  const { data: datasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: datasetDesignSlug,
    queryOptions: { enabled: false },
  });
  const { mutate: updateGuiTab } = useUpdateItem({
    modelName: ApiModels.GuiTab,
  });

  const { fieldsMap, items } = useMemo(() => {
    const excludedFields: string[] = [];
    const fieldsMap = new Map<string, DataField>();
    datasetDesign?.fields?.fields?.forEach((field) => {
      fieldsMap.set(field.id, field);
      if (!includedFields.includes(field.id)) {
        excludedFields.push(field.id);
      }
    });

    return {
      items: { included: includedFields, excluded: excludedFields },
      fieldsMap,
    };
  }, [datasetDesign, includedFields]);

  const onSortEndHandler = (
    _: UniqueIdentifier[],
    items: Record<UniqueIdentifier, UniqueIdentifier[]>
  ) => {
    if (guiTab) {
      updateGuiTab(
        {
          slug: guiTab.slug,
          data: normalizeObjectForAPI(
            {
              ...guiTab,
              [indexKey]: items.included,
            },
            ["gui_slug", "parent_tab_slug", "tab_type"]
          ),
        },
        {
          onSuccess: () => {
            console.log("AccountUser edit success");
          },
        }
      );
    }
  };

  return (
    <React.Fragment>
      <SidebarSectionWrap
        title="Back to Dataset"
        rightIcon={false}
        leftIcon={<ArrowBack />}
        onLeftIconClick={onClickBack}
      >
        <MultipleContainers
          items={items}
          minimal
          vertical={true}
          modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
          style={{ padding: 0 }}
          renderContainer={(prps: any) => {
            return (
              <FieldsWrapper>
                <Box className="title">{prps.id}</Box>
                <FieldsWrap ref={prps.ref} sx={{ minHeight: 60 }}>
                  {prps.children}
                </FieldsWrap>
              </FieldsWrapper>
            );
          }}
          renderItem={(prps: DraggableItemProps & { ref: any }) => {
            const item = fieldsMap.get(prps.value as unknown as string);
            if (!item) return null;

            const {
              transition,
              handleProps,
              listeners,
              transform,
              index,
              color,
              dragging,
              style,
              ref,
              ...rest
            } = prps;

            const styles: CSSProperties = {
              opacity: dragging ? 0.4 : undefined,
              transform: CSS.Translate.toString(transform as any),
              transition: transition as any,
            };
            return (
              <ListItemStyle
                style={styles as CSSProperties}
                id={item.id}
                ref={ref}
              >
                <DragFieldItem
                  item={item}
                  listeners={listeners}
                  handleProps={handleProps}
                />
              </ListItemStyle>
            );
          }}
          onSortEnd={onSortEndHandler as any}
        />
      </SidebarSectionWrap>
    </React.Fragment>
  );
};

export default FieldsSearchSelection;
