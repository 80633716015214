import { TextField, createFilterOptions } from "@mui/material";
import GfAutoComplete from "components/FormField/GfAutoComplete";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { FC, useMemo } from "react";

const defaultFilter = createFilterOptions({
  ignoreCase: true,
  trim: true,
  matchFrom: "any",
  stringify: (option) => (option as LabeledValue).label,
  limit: 10,
});

type DatasetDesignPickerProps = {
  value: string;
  allowNoneSelection?: boolean;
  disabled?: boolean;
  onChange(value: string): void;
};

const DatasetDesignPicker: FC<DatasetDesignPickerProps> = (props) => {
  const { value, onChange, allowNoneSelection, disabled } = props;

  const { data: datasetDesigns, isLoading: loading } = useListItems({
    modelName: ApiModels.DatasetDesign,
  });

  const options: LabeledValue[] = useMemo(() => {
    const designOptions =
      datasetDesigns?.map((design) => ({
        label: design.name,
        value: design.slug,
      })) ?? [];

    if (allowNoneSelection) {
      designOptions.unshift({
        label: "None",
        value: "none",
      });
    }

    return designOptions;
  }, [datasetDesigns, allowNoneSelection]);

  return (
    <GfAutoComplete
      value={value ?? ""}
      size="small"
      sx={{
        minHeight: "40px",
      }}
      options={options}
      renderInput={(params) => <TextField {...params} />}
      onChange={(e, option) => {
        console.log("🚀 ~ option:", option);
        const value = (option as LabeledValue)?.value;
        if (value) {
          onChange(value);
        }
      }}
      filterOptions={defaultFilter}
      renderOption={(props, option: any) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      getOptionLabel={(option) => {
        return options?.find((op) => op.value === option)?.label ?? "";
      }}
      loading={loading}
      disabled={disabled}
    />
  );
};

export default DatasetDesignPicker;
