import { useInfiniteQuery } from "@tanstack/react-query";
import Copilot from "models/Copilot";

export const getAgentChatMessagesQueryKey = (threadSlug?: string | null) => [
  "copilot",
  "threads",
  threadSlug,
  "messages",
];

const useMessages = (threadSlug?: string | null) => {
  return useInfiniteQuery({
    queryKey: getAgentChatMessagesQueryKey(threadSlug),
    queryFn: async ({ pageParam }) => {
      return await Copilot.listMessages(threadSlug!, pageParam);
    },
    getNextPageParam(lastPage) {
      return lastPage.cursor;
    },
    enabled: !!threadSlug,
  });
};

export default useMessages;
