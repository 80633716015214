import {
  Avatar,
  AvatarGroup,
  Button,
  Stack,
  styled,
  TooltipProps,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SearchInput from "components/Inputs/SearchInput";
import Scrollbar from "components/Scrollbar";
import { find } from "lodash";
import { FC, useCallback, useMemo, useState } from "react";
import ProfileCard from "stories/CompoundComponent/ProfileCard/ProfileCard";

const ToolTipBox = styled("div")(({ theme }) => ({
  padding: "16px",
  background: "#1e1e23",

  ".search-field": {
    marginBottom: "16px",
    background: theme.palette.common.blackshades?.["12p"],

    ".MuiInputBase-input": {
      height: "40px",
    },
  },

  ".MuiAvatarGroup-root": {
    justifyContent: "flex-end",
    marginBottom: "15px",
  },
}));

const ScrollWrapper = styled("div")(({ theme }) => ({
  margin: "0 -15px 0 0",

  ".rc-scrollbar": {
    padding: "0 15px 0 0",
  },
}));

type UserOption = {
  id: string;
  title?: string;
  subtitle?: string;
  image?: string | { name: string; url: string };
};

type Props = {
  options?: UserOption[];
  placeholder?: string;
  actionButtons?: boolean;
  tooltipProps?: Partial<TooltipProps>;
  disabled?: boolean;
  children?(params: {
    selectedValues: UserOption[];
    onClose(): void;
  }): React.ReactNode;
} & (
  | {
      value: string[];
      multi: true;
      onChange(value: string[]): void;
    }
  | {
      value: string;
      multi?: false;
      onChange(value: string): void;
    }
);

const UserSelector: FC<Props> = (props) => {
  const {
    value,
    multi = false,
    options = [],
    actionButtons = true,
    disabled,
  } = props;
  const [searchValue, setSearchValue] = useState<string>("");
  const theme = useTheme();

  const handleClick = (item: any) => {
    if (disabled) return;
    if (props.multi) {
      const newValue = Array.isArray(props.value) ? props.value : [props.value];
      const alreadySelected = newValue.includes(item.id);
      if (alreadySelected) {
        const afterRemoving = newValue.filter((v) => v !== item.id);
        return props.onChange(afterRemoving);
      }

      props.onChange([...newValue, item.id]);
    } else {
      props.onChange(item.id);
    }
  };

  const onSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const filterData = useMemo(() => {
    if (searchValue?.length > 0) {
      return options.filter((option) => option.title?.startsWith(searchValue));
    }
    return options;
  }, [searchValue, options]);

  const selectOptions = useMemo(() => {
    if (Array.isArray(value)) {
      return options.filter((opt) => value.includes(opt.id));
    }
    return [find(options, { id: value })];
  }, [options, value]);

  const checkIsSelect = useCallback(
    (id: string) => {
      if (multi) return value?.includes(id);
      return id === value;
    },
    [multi, value]
  );

  return (
    <ToolTipBox>
      <Typography variant="body1" component="div" mb={2}>
        User list
      </Typography>

      {!!selectOptions?.length && (
        <AvatarGroup max={4}>
          {selectOptions?.map((item) => (
            <Avatar
              src={
                typeof item?.image === "string" ? item?.image : item?.image?.url
              }
              alt={item?.title}
            >
              {item?.title?.[0]?.toLocaleUpperCase()}
            </Avatar>
          ))}
        </AvatarGroup>
      )}
      <SearchInput onChange={onSearch} />
      {!!options.length && (
        <ScrollWrapper>
          <Scrollbar autoHeight autoHeightMax={240}>
            <Stack gap={0.75}>
              {filterData?.map((item) => {
                return (
                  <ProfileCard
                    selected={checkIsSelect(item.id)}
                    onClick={() => handleClick(item)}
                    subTitle={item.subtitle}
                    options={{ draggable: false, switcher: false }}
                    title={item.title!}
                    AvatarImage={
                      <Avatar
                        src={
                          typeof item?.image === "string"
                            ? item?.image
                            : item?.image?.url
                        }
                      >
                        {item?.title?.[0]?.toLocaleUpperCase()}
                      </Avatar>
                    }
                    // rightIcon={
                    //   <LensIcon
                    //     sx={{
                    //       width: "10px",
                    //       height: "10px",
                    //       color: theme.palette.success.main,
                    //     }}
                    //   />
                    // }
                  />
                );
              })}
            </Stack>
          </Scrollbar>
        </ScrollWrapper>
      )}
      {actionButtons && (
        <Stack direction="row" justifyContent="center" pt={2.5} gap={1.75}>
          <Button
            size="small"
            color="inherit"
            sx={{
              bgcolor: theme.palette.background.GF10,
              color: theme.palette.background.GF50,
            }}
          >
            Cancel
          </Button>
          <Button type="submit" size="small" variant="contained">
            Save
          </Button>
        </Stack>
      )}
    </ToolTipBox>
  );
};

export default UserSelector;
