import { Box, Button, Stack, styled } from "@mui/material";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import Icon from "components/util-components/Icon";
import { TransitionComponent } from "layouts/AnimationLayout";
import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import useGuiComponentPaths from "store/stores/gui-component-paths";
import useGuiTabEditor from "store/stores/gui-tab-editor";
import { ImporterUploadsInternal } from "views/app-view/upload-design/ImporterUploads";
import DatasetWidget from "./DatasetWidgets";

const DatasetSidebarContainerWrap = styled(Box)(({ theme }) => {
  return {
    paddingTop: 20,
    marginBottom: 20,

    [`${theme.breakpoints.down("sm")}`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
});

const DesignUploaderWrapper = styled(Box)(({ theme }) => ({
  "&>div": {
    padding: "16px",
    background: "#2F2F36",
    borderRadius: "8px",
    h4: { fontSize: "18px" },
    ".heading-card p p": { fontSize: "14px" },
    ".uploader>div": { padding: "5px" },
  },
}));
// first and 4th column to show array;
const columnsToShow = [true, false, false, true];
const RightSidebarMain: React.FC<{
  datasetDesignSlug: string;
  datasetSlug: string;
  includedTabs?: IncludeTabs[];
  showActions?: boolean;
  actions?: FusionAction[];
  selectedImport?: string;
  guiSlug?: string;
  allowAddingNewWidget?: boolean;
  isRightWidget?: boolean;
  activeTab?: IncludeTabs;
  onEditDataset?: (_: Dataset, w: IncludeTabs, index: number) => void;
  onNextClick?(): void;
  onPreviousClick?(): void;
  onActionClick?(action: FusionAction): void;
}> = ({
  onEditDataset,
  datasetDesignSlug,
  datasetSlug,
  includedTabs = [],
  actions,
  onActionClick,
  onNextClick,
  onPreviousClick,
  showActions,
  selectedImport,
  guiSlug,
  allowAddingNewWidget,
  isRightWidget = true,
  activeTab,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setTabEditorOpen = useGuiTabEditor.useSetEditorOpen();
  const setAdditionalTabPath = useGuiTabEditor.useSetAdditionalTabPath();
  const rightWidgetPaths = useGuiComponentPaths.useRightWidgetsPath();
  const setEditorPath = useGuiTabEditor.useSetEditorPath();

  const handleAddWidget = useCallback(() => {
    if (rightWidgetPaths.length > 0 && activeTab) {
      searchParams.set("selectedTabId", "new-tab");
      setSearchParams(searchParams);
      const additionalPath = [
        ...rightWidgetPaths.map((p) => `${p}`),
        "additional_tabs",
      ];
      console.log("🚀 ~ handleAddWidget ~ additionalPath:", additionalPath);
      setAdditionalTabPath(additionalPath.slice(1));
      const compHistory: TransitionComponent[] = [];
      for (let i = 0; i < additionalPath.length; i += 2) {
        const propName = additionalPath[i];
        const propIndex = Number(additionalPath[i + 1]);
        if (propName && !isNaN(propIndex)) {
          if (propName === "included_sidebar_widgets") {
            compHistory.push({ name: "main", id: "main" });
          } else {
            compHistory.push({
              name: "additional-widget",
              id: activeTab.id,
            });
          }
        }
      }
      compHistory.push({
        name: "additional-widget",
        id: "additional-widget",
      });
      console.log("🚀 ~ handleAddWidget ~ compHistory:", compHistory);
      setEditorPath(compHistory);
      setTabEditorOpen(true);
    } else {
      searchParams.set("selectedTabId", "new-tab");
      setSearchParams(searchParams);
      setAdditionalTabPath([]);
      setEditorPath([
        {
          id: "main",
          name: "main",
        },
        {
          id: "additional-widget",
          name: "additional-widget",
        },
      ]);
      setTabEditorOpen(true);
    }
  }, [
    rightWidgetPaths,
    activeTab,
    searchParams,
    setSearchParams,
    setAdditionalTabPath,
    setEditorPath,
    setTabEditorOpen,
  ]);

  return (
    <React.Fragment>
      {selectedImport && (
        <SidebarSectionWrap title={"Design Importer"} rightIcon={false}>
          <DesignUploaderWrapper>
            <ImporterUploadsInternal
              slug={selectedImport}
              options={{ showColumnIndex: columnsToShow }}
            />
          </DesignUploaderWrapper>
        </SidebarSectionWrap>
      )}
      {allowAddingNewWidget && (
        <Button
          size="small"
          fullWidth
          sx={{
            p: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#d4d4d5",
            background: "#29292e",
            mt: 2,
          }}
          onClick={handleAddWidget}
        >
          Add new Widget
        </Button>
      )}
      <Stack sx={{ mt: 2 }} direction="column" gap={2}>
        {includedTabs?.map((tab, index) => (
          <DatasetWidget
            datasetDesignSlug={datasetDesignSlug}
            datasetSlug={datasetSlug}
            selectedTabData={tab}
            onEdit={(d) => {
              onEditDataset?.(d, tab, index);
            }}
            guiSlug={guiSlug}
            index={index}
            isRightWidget={isRightWidget}
          />
        ))}
      </Stack>
      {showActions && (
        <DatasetSidebarContainerWrap>
          <Stack direction="column" spacing="20px">
            <Stack
              direction="row"
              spacing={2}
              sx={{ p: 2, background: "#2F2F36", borderRadius: "6px" }}
            >
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={onPreviousClick}
              >
                Previous
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={onNextClick}
              >
                Next
              </Button>
            </Stack>
            {!!actions?.length && (
              <Stack
                direction="column"
                spacing={2}
                sx={{ p: 2, background: "#2F2F36", borderRadius: "6px" }}
              >
                {actions?.map((action) => (
                  <Button
                    key={action.id}
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<Icon iconName={action.action_icon} />}
                    onClick={() => onActionClick?.(action)}
                  >
                    {action.action_title}
                  </Button>
                ))}
              </Stack>
            )}
          </Stack>
        </DatasetSidebarContainerWrap>
      )}
    </React.Fragment>
  );
};

export default RightSidebarMain;
