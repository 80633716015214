import { useQuery } from "@tanstack/react-query";
import OpenAI from "models/OpenAI";

const useOpenAIModels = () => {
  return useQuery({
    queryKey: ["openai", "models"],
    queryFn: async () => {
      const response = await OpenAI.listModels();
      return response.data;
    },
  });
};

export default useOpenAIModels;
