import { AddOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import IconButtonWithTooltip from "components/IconButtonWithTooltip";
import useOpenClose from "hooks/useOpenClose";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import { FC, useCallback } from "react";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import AddGuiTabModal, { AddGuiTabFormType } from "./AddGuiTabModal";

type AddTabButtonProps = {
  guiSlug: string;
};

const AddTabButton: FC<AddTabButtonProps> = (props) => {
  const { guiSlug } = props;
  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();
  const [isAddModalOpen, openAddModal, closeAddModal] = useOpenClose();

  const queryClient = useQueryClient();

  const { mutate: createGuiTab } = useCreateItem({
    modelName: ApiModels.GuiTab,
  });

  const handleAddTab = useCallback(
    (data: AddGuiTabFormType) => {
      if (guiSlug) {
        const guiTab = { ...data, gui_slug: guiSlug } as GuiTab;
        if (guiTab.tab_type === "dashboard") {
          guiTab.tab_rows = [];
        }
        return new Promise<void>((resolve, reject) => {
          createGuiTab(guiTab, {
            onSuccess() {
              queryClient.refetchQueries({
                queryKey: [ApiModels.GuiTab, guiSlug],
              });
              resolve();
            },
            onError() {
              reject();
            },
          });
        });
      } else {
        return Promise.reject();
      }
    },
    [createGuiTab, guiSlug, queryClient]
  );

  if (!editModeEnabled) {
    return <></>;
  }

  return (
    <Stack direction="row" alignItems="center">
      <IconButtonWithTooltip
        variant="outlined"
        size="small"
        tooltipProps={{ title: "Add Tab" }}
        onClick={(e) => {
          e.stopPropagation();
          openAddModal();
        }}
      >
        <AddOutlined fontSize="small" />
      </IconButtonWithTooltip>
      <AddGuiTabModal
        open={isAddModalOpen}
        onClose={() => closeAddModal()}
        onSubmit={handleAddTab}
      />
    </Stack>
  );
};

export default AddTabButton;
