import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import AddFolder from "assets/icons/AddFolder";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import useOpenClose from "hooks/useOpenClose";
import useRouteToApiModel from "hooks/useRouteToApiModel";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { AddButton } from "./index.styles";

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
});

const ButtonAddFolder = () => {
  const [isOpen, onOpen, onClose] = useOpenClose();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<Folder>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });

  const routeModel = useRouteToApiModel();
  const { mutate: createFolder } = useCreateItem({
    modelName: ApiModels.Folder,
    queryKey: [ApiModels.Folder, routeModel],
  });

  const closeHandler = () => {
    onClose();
    reset();
  };
  const submitHandler = (data: Folder) => {
    createFolder(
      { ...data, resource: routeModel, sort_order: 10000000000 },
      {
        onSuccess: () => {
          closeHandler();
        },
      }
    );
  };
  return (
    <>
      <AddButton
        fullWidth
        variant="outlined"
        color="inherit"
        sx={{ borderColor: (theme) => theme.palette.background.GF10 }}
        onClick={onOpen}
      >
        <AddFolder />
      </AddButton>
      <Dialog open={isOpen} disableEscapeKeyDown scroll="body">
        <DialogTitle>Add Folder</DialogTitle>
        <Scrollbar className="form-scroller">
          <DialogContent>
            <Box component="form">
              <FormField
                label="Folder Name"
                error={dirtyFields.name ? errors.name : undefined}
              >
                <TextField
                  {...register("name")}
                  autoFocus
                  margin="dense"
                  id="name"
                  type="text"
                  fullWidth
                />
              </FormField>
            </Box>
          </DialogContent>
        </Scrollbar>
        <DialogActions>
          <Button onClick={closeHandler}>Cancel</Button>
          <Button onClick={handleSubmit(submitHandler)} variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ButtonAddFolder;
