import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  styled,
} from "@mui/material";
import DatasetDesignPicker from "components/DatasetDesignPicker";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import { DocumentElementType } from "enums/Form";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const Container = styled(Stack)({
  maxWidth: "600px",
  width: "100%",
});

const formSchema = z.object({
  dataset_design_slug: z.string(),
  description: z.string().optional(),
  enable_read: z.number().default(0),
  enable_write: z.number().default(0),
  enable_delete: z.number().default(0),
});

type AddAgentDatasetRightFormType = z.infer<typeof formSchema>;

type AddAgentDatasetRightModalProps = {
  agent: Agent;
  onClose?(): void;
  onCreated?(): void;
} & DialogProps;

const AddAgentDatasetRightModal: FC<AddAgentDatasetRightModalProps> = (
  props
) => {
  const { agent, onClose, onCreated, ...dialogProps } = props;

  const form = useForm<AddAgentDatasetRightFormType>({
    resolver: zodResolver(formSchema),
  });

  const { control, handleSubmit } = form;

  const { mutateAsync: createAgentDatasetRight, isLoading } = useCreateItem({
    modelName: ApiModels.AgentDatasetRight,
  });

  const submitHandler = async (values: AddAgentDatasetRightFormType) => {
    await createAgentDatasetRight({
      ...values,
      enable_read: Boolean(values.enable_read),
      enable_write: Boolean(values.enable_write),
      enable_delete: Boolean(values.enable_delete),
      agent_slug: agent.slug,
    });

    onClose?.();
    onCreated?.();
  };

  return (
    <Dialog
      onClose={(e, r) => {
        console.log(e, r);
      }}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Add Dataset Right</DialogTitle>
      <Scrollbar className="form-scroller">
        <DialogContent>
          <FormProvider {...form}>
            <Stack alignItems="center">
              <Container gap={2}>
                <FormField
                  label="Dataset Design"
                  formControl={{ control, name: "dataset_design_slug" }}
                >
                  {({ value, onChange }) => (
                    <DatasetDesignPicker
                      value={value as string}
                      onChange={(v) => onChange?.(v as string)}
                    />
                  )}
                </FormField>
                <FormField
                  label="Description"
                  type={DocumentElementType.TextArea}
                  formControl={{ control, name: "description" }}
                />
                <FormField
                  label="Enable Read"
                  type={DocumentElementType.Boolean}
                  formControl={{ control, name: "enable_read" }}
                />
                <FormField
                  label="Enable Write"
                  type={DocumentElementType.Boolean}
                  formControl={{ control, name: "enable_write" }}
                />
                <FormField
                  label="Enable Delete"
                  type={DocumentElementType.Boolean}
                  formControl={{ control, name: "enable_delete" }}
                />
              </Container>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Scrollbar>
      <DialogActions>
        <LoadingButton onClick={onClose}>Cancel</LoadingButton>
        <LoadingButton
          onClick={handleSubmit(submitHandler, (error) => {
            console.log(error);
          })}
          variant="contained"
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddAgentDatasetRightModal;
