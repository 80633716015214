import { ParameterType } from "enums/3pApp";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { FC, useMemo } from "react";
import { ParamField } from "views/app-view/flow-designer/components/NodeEditorFields";

type GetDatasetDesignFieldsFieldsProps = {};

const GetDatasetDesignFieldsFields: FC<GetDatasetDesignFieldsFieldsProps> = (
  props
) => {
  const { data: datasetDesigns = [] } = useListItems({
    modelName: ApiModels.DatasetDesign,
  });

  const datasetDesignsOptions = useMemo(
    () =>
      datasetDesigns.map((design) => ({
        label: design.name,
        value: design.slug,
      })),
    [datasetDesigns]
  );

  return (
    <>
      <ParamField
        mappable
        field={{
          name: "dataset_design_slug",
          label: "Dataset Design",
          type: ParameterType.Select,
          options: datasetDesignsOptions,
        }}
      />
    </>
  );
};

export default GetDatasetDesignFieldsFields;
