import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as BackArrowIconSvg } from "../svg/back-arrow.svg";

const BackArrowIcon: React.FC<React.ComponentProps<typeof SvgIcon>> = (props) => {
  return (
    <SvgIcon
      inheritViewBox
      {...props}
      component={BackArrowIconSvg}
      className="back-arrow-icon"
    />
  );
};

export default BackArrowIcon;
