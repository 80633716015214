import { ParameterType } from "enums/3pApp";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { FC, useMemo } from "react";
import { ParamField } from "../index";

const RunSqlQueryFields: FC = (props) => {
  const { data: sqlQueries = [] } = useListItems({
    modelName: ApiModels.SqlQuery,
  });

  const queryOptions = useMemo(
    () => sqlQueries.map((query) => ({ label: query.name, value: query.slug })),
    [sqlQueries]
  );

  return (
    <ParamField
      mappable
      field={{
        name: "query_slug",
        label: "Query",
        type: ParameterType.Select,
        options: queryOptions,
      }}
    />
  );
};

export default RunSqlQueryFields;
