import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddAssociatedField from "components/AddAssociatedField";
import FusionActionEditor, {
  FusionActionForm,
} from "components/FusionAction/FusionActionEditor";
import GfDrawer from "components/GfDrawer";
import Scrollbar from "components/Scrollbar";
import useQuery from "hooks/useQuery";
import AnimationLayout, {
  AnimationLayoutRef,
  Config,
} from "layouts/AnimationLayout";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import GuiModel from "models/Gui";
import { queryClient } from "queries";
import { ApiModels } from "queries/apiModelMapping";
import { useGuiTabs } from "queries/gui/useGuiTabs";
import useCreateItem from "queries/useCreateItem";
import useDeleteItem from "queries/useDeleteItem";
import useListItems from "queries/useListItems";
import useUpdateItem from "queries/useUpdateItem";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import useSystemLayoutStore from "store";
import { getSearchParams, normalizeObjectForAPI } from "utils";
import { v4 } from "uuid";
import FieldFilterRules from "./FieldFilterRules";
import {
  default as FieldsSearchSelection,
  default as FieldsSelection,
} from "./FieldsSelection";
import ImageSetting from "./ImageSetting";
import RecordFilterRules from "./RecordFilterRules";
import TabCreator from "./TabCreator";
import GuiSidenavMain from "./WidgetMain";

const DrawerBox = styled(GfDrawer)(({ theme }) => ({
  ".MuiDrawer-paper ": {
    ".MuiPaper-root ": {
      background: `${theme.palette.background.GFTopNav} !important`,
      boxShadow: "none",

      ".MuiCard-root": {
        background: `${theme.palette.background.GFRightNavForeground} !important`,
        transition: "all 0.4s ease",

        "&:hover": {
          background: `${theme.palette.background.GF20} !important`,
        },
      },
    },

    ".drawer-head": {
      background: theme.palette.background.GFTopNav,
      padding: "15px 20px",
      borderBottom: `1px solid ${theme.palette.other?.divider}`,

      ".drawer-icon-holder": {
        width: "auto",
        height: "auto",
        background: "none",
      },

      ".drawer-title-holder": {
        padding: "0 0 0 11px",
      },
    },

    ".draggable-handle": {
      padding: "0",
    },
  },
}));

const DatasetSidebarContainerWrap = styled(Box)(({ theme }) => {
  return {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    marginBottom: 20,

    [`${theme.breakpoints.down("sm")}`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
});

const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 60px)`,
    overflow: "hidden",
  };
});

const getInitialComponent = () => {
  return getSearchParams().get("s_name") || "main";
};

type GuiRightSideNavProps = {
  gui: GfGui;
  open: boolean;
  layoutRef: RefObject<AnimationLayoutRef>;
  onClose(): void;
};

export type GuiRightSideNavRef = {
  setAdditionalTabPath: Dispatch<SetStateAction<string[]>>;
};

const GuiRightSideNav = forwardRef<GuiRightSideNavRef, GuiRightSideNavProps>(
  (props, ref) => {
    const { open, onClose, gui, layoutRef } = props;

    const [searchParams, setSearchParams] = useSearchParams();

    const [initialComponent, setInitialComponent] = useState<string>("");
    const [additionalTabPath, setAdditionalTabPath] = useState<string[]>([]);

    const [selectedField, setSelectedField] = useState<DataField>();
    const [selectedActionField, setSelectedActionField] =
      useState<DataField & { db_slug: string }>();
    const [selectedFusionAction, setSelectedFusionAction] =
      useState<FusionAction>();

    const { data: datasetDesigns } = useListItems({
      modelName: ApiModels.DatasetDesign,
      queryOptions: { enabled: false },
    });
    const { data: guiTabs = [] } = useGuiTabs(gui?.slug);
    const { mutateAsync: deleteFormDesign } = useDeleteItem({
      modelName: ApiModels.FormDesign,
    });
    const { mutateAsync: updateFormDesign } = useUpdateItem({
      modelName: ApiModels.FormDesign,
    });

    const { mutateAsync: createFormDesign } = useCreateItem({
      modelName: ApiModels.FormDesign,
    });

    const selectedTab = useSystemLayoutStore.useSelectedTopBarTab();

    const guiTab = useMemo(() => {
      if (selectedTab != null && guiTabs) {
        return guiTabs[Number(selectedTab)];
      }
    }, [guiTabs, selectedTab]);

    const datasetDesignSlug =
      guiTab?.tab_type === "record_list"
        ? guiTab.dataset_design_slug
        : undefined;

    const datasetDesignsMap = useMemo(() => {
      const datasetMap = new Map<string, DatasetDesign>();
      datasetDesigns?.forEach((dd) => {
        datasetMap.set(dd.slug, dd);
      });
      return datasetMap;
    }, [datasetDesigns]);

    const { mutate: updateGuiTab } = useUpdateItem({
      modelName: ApiModels.GuiTab,
    });

    const { selectedTabId } = useQuery<{ selectedTabId: string }>();

    const handleFusionActionSubmit = useCallback(
      async (
        values: FusionActionForm,
        {
          indexKey,
          path,
        }: {
          indexKey: string;
          path: string[];
        }
      ) => {
        if (guiTab?.slug && selectedTabId) {
          const tab = cloneDeep(guiTab) ?? {};
          let parentTab = (tab as any)[indexKey as keyof typeof tab];
          const selectedTab = get(parentTab, path.slice(0, -1)) as IncludeTabs;
          if (!selectedTab) {
            return;
          }
          if (selectedFusionAction) {
            set(tab, [indexKey, ...path.slice(0, -1)], {
              ...selectedTab,
              associated_actions:
                selectedTab.associated_actions?.map((action) =>
                  action.id === selectedFusionAction.id
                    ? { ...action, ...values }
                    : action
                ) ?? [],
            });

            await new Promise<void>((resolve, reject) => {
              updateGuiTab(
                {
                  slug: guiTab?.slug,
                  data: normalizeObjectForAPI({ ...guiTab, ...tab }, [
                    "gui_slug",
                    "parent_tab_slug",
                    "tab_type",
                  ]),
                },
                {
                  onSuccess: () => {
                    resolve();
                  },
                }
              );
            });
          } else {
            GuiModel.createDatasetAction(gui?.slug, {
              action_title: values.action_title,
              action_icon: values.action_icon,
              action_type: values.action_type,
              action_pinned: values.action_pinned,
              action_hotkey: values.action_hotkey,
              action_starter_form_dataset: values.action_starter_form_dataset,
              tab_slug: tab.slug,
              path: `${indexKey}.${path.slice(0, -1).join(".")}`,
            })
              .then(() => {
                queryClient.refetchQueries({
                  queryKey: [ApiModels.GuiTab, gui.slug],
                });
              })
              .catch(() => {});
          }
        }

        queryClient.refetchQueries({
          queryKey: [ApiModels.GuiTab, gui.slug],
        });
      },
      [gui.slug, guiTab, selectedFusionAction, selectedTabId, updateGuiTab]
    );

    const handleFieldDelete = useCallback(
      async (slug: string) => {
        await deleteFormDesign({ slug });
        queryClient.refetchQueries({
          queryKey: [ApiModels.FormDesign, selectedFusionAction?.id],
        });
      },
      [deleteFormDesign, selectedFusionAction?.id]
    );

    const handleFieldUpdate = useCallback(
      async (updates: Partial<DataField>) => {
        if (!selectedActionField) {
          return;
        }

        const { title, default_value, tooltip, id, slug, ...rest } = updates;

        const metadata = rest as Record<string, unknown>;

        if (metadata.db_slug) {
          delete metadata.db_slug;
        }
        if (metadata.field_slug) {
          delete metadata.field_slug;
        }

        await updateFormDesign({
          slug: selectedActionField?.db_slug,
          data: {
            title,
            default_value,
            tooltip,
            metadata,
          },
        });
        queryClient.refetchQueries({
          queryKey: [ApiModels.FormDesign, selectedFusionAction?.id],
        });
      },
      [selectedActionField, updateFormDesign, selectedFusionAction?.id]
    );

    const handleFieldCreate = useCallback(
      async (updates: Partial<DataField>) => {
        const { title, default_value, tooltip, type, id, slug, ...rest } =
          updates;

        const metadata = rest as Record<string, unknown>;

        if (metadata.db_slug) {
          delete metadata.db_slug;
        }
        if (metadata.field_slug) {
          delete metadata.field_slug;
        }

        await createFormDesign({
          field_slug: slug,
          title,
          type,
          default_value,
          tooltip,
          metadata,
          action_slug: selectedFusionAction?.id,
        });

        queryClient.refetchQueries({
          queryKey: [ApiModels.FormDesign, selectedFusionAction?.id],
        });
      },
      [createFormDesign, selectedFusionAction?.id]
    );

    const getComponentMiddleComponent: Config["getComponents"] = useCallback(
      (gotoComponent, goBack) => {
        return {
          main: (
            <GuiSidenavMain
              gui={gui}
              onEditRules={() => {
                gotoComponent({ name: "rules", id: "rules" });
              }}
              onEditSeachFields={() => {
                gotoComponent({
                  name: "field-selection-search",
                  id: "field-selection-search",
                });
              }}
              onEditFieldToInclude={() => {
                gotoComponent({
                  name: "field-selection-form",
                  id: "field-selection-form",
                });
              }}
              onNewTabAddition={() => {
                searchParams.set("selectedTabId", "new-tab");
                setSearchParams(searchParams);
                setAdditionalTabPath([]);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-tabs",
                    id: "additional-tabs",
                  });
                }, 0);
              }}
              onEditAddition={(item: IncludeTabs, index: number) => {
                searchParams.set("selectedTabId", item.id);
                setSearchParams(searchParams);
                setAdditionalTabPath([index?.toString(), "additional_tabs"]);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-tabs",
                    id: "additional-tabs",
                  });
                }, 0);
              }}
              onNewWidget={() => {
                searchParams.set("selectedTabId", "new-tab");
                setSearchParams(searchParams);
                setAdditionalTabPath([]);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-widget",
                    id: "additional-widget",
                  });
                }, 0);
              }}
              onEditWidget={(item: IncludeTabs, index: number) => {
                searchParams.set("selectedTabId", item.id);
                setSearchParams(searchParams);
                setAdditionalTabPath([index?.toString(), "additional_tabs"]);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-widget",
                    id: "additional-widget",
                  });
                }, 0);
              }}
            />
          ),
          "field-selection-search": (
            <FieldsSearchSelection
              gui={gui}
              indexKey={"search_fields"}
              onClickBack={() => goBack()}
            />
          ),
          "field-selection-form": (
            <FieldsSelection
              gui={gui}
              indexKey={"form_fields"}
              onClickBack={() => goBack()}
              onEditFilterRules={(field) => {
                setSelectedField(field);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-general-field-filters",
                    id: "additional-general-field-filters",
                  });
                }, 0);
              }}
              onEditImageSetting={(field) => {
                setSelectedField(field);
                setTimeout(() => {
                  gotoComponent({
                    name: "image-setting-general",
                    id: "image-setting-general",
                  });
                }, 0);
              }}
            />
          ),
          "additional-tabs": (
            <TabCreator
              gui={gui}
              type="middle_tab"
              onClickBack={() => {
                const currentId = searchParams.get("selectedTabId");
                if (additionalTabPath.length > 0) {
                  if (guiTab?.tab_type === "record_list") {
                    const parentTab = get(
                      guiTab.included_tabs,
                      additionalTabPath.slice(0, -1)
                    ) as IncludeTabs;
                    searchParams.set("selectedTabId", parentTab.id);
                    setSearchParams(searchParams);
                    goBack();
                    if (currentId !== "new-tab") {
                      setAdditionalTabPath((pa) => {
                        const newpath = pa.slice(0, -2);
                        return newpath;
                      });
                    }
                  }
                } else {
                  searchParams.delete("selectedTabId");
                  setSearchParams(searchParams);
                  setTimeout(goBack, 0);
                  setAdditionalTabPath([]);
                }
              }}
              indexKey="included_tabs"
              datasetDesigns={datasetDesigns}
              datasetDesignsMap={datasetDesignsMap}
              path={additionalTabPath}
              onEditFilterRules={(field) => {
                setSelectedField(field);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-tabs-field-filters",
                    id: "additional-tabs-field-filters",
                  });
                }, 0);
              }}
              allowAdditionalTabs
              onAddWidget={() => {
                searchParams.set("selectedTabId", "new-tab");
                setSearchParams(searchParams);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-tabs",
                    id: v4(),
                  });
                }, 0);
              }}
              onEditWidget={(item: IncludeTabs, index: number) => {
                searchParams.set("selectedTabId", item.id);
                setSearchParams(searchParams);
                setAdditionalTabPath((path) => [
                  ...path,
                  index?.toString(),
                  "additional_tabs",
                ]);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-tabs",
                    id: item.id,
                  });
                }, 0);
              }}
              allowTabActions
              onAddActionClick={() => {
                setSelectedFusionAction(undefined);
                gotoComponent({
                  name: "fusion-action-editor",
                  id: "fusion-action-editor",
                });
              }}
              onEditActionClick={(action) => {
                setSelectedFusionAction(action);
                gotoComponent({
                  id: action.id,
                  name: "fusion-action-editor",
                });
              }}
              onEditRules={() => {
                gotoComponent({ name: "tab-rules", id: "tab-rules" });
              }}
              onEditImageSetting={(field) => {
                setSelectedField(field);
                setTimeout(() => {
                  gotoComponent({
                    name: "image-setting-tabs",
                    id: "image-setting-tabs",
                  });
                }, 0);
              }}
            />
          ),
          "fusion-action-editor": (
            <FusionActionEditor
              action={selectedFusionAction}
              onSubmit={async (values) => {
                await handleFusionActionSubmit(values, {
                  path: additionalTabPath,
                  indexKey: "included_tabs",
                }).then(() => {
                  goBack();
                  setSelectedFusionAction(undefined);
                });
              }}
              onCancel={() => {
                goBack();
                setSelectedFusionAction(undefined);
              }}
              onAddField={() => {
                gotoComponent({
                  name: "add-action-field-form",
                  id: "add-action-field-form",
                });
              }}
              onEditField={(field) => {
                setSelectedActionField({
                  ...field,
                  slug: field.field_slug,
                  db_slug: field.slug,
                  ...field.metadata,
                });
                gotoComponent({
                  name: "add-action-field-form",
                  id: field.slug,
                });
              }}
              onDeleteField={handleFieldDelete}
            />
          ),
          "fusion-action-editor-sidebar": (
            <FusionActionEditor
              action={selectedFusionAction}
              onSubmit={async (values) => {
                await handleFusionActionSubmit(values, {
                  path: additionalTabPath,
                  indexKey: "included_sidebar_widgets",
                }).then(() => {
                  goBack();
                  setSelectedFusionAction(undefined);
                });
              }}
              onCancel={() => {
                goBack();
                setSelectedFusionAction(undefined);
              }}
              onAddField={() => {
                gotoComponent({
                  name: "add-action-field-form",
                  id: "add-action-field-form",
                });
              }}
              onEditField={(field) => {
                setSelectedActionField({
                  ...field,
                  slug: field.field_slug,
                  db_slug: field.slug,
                  ...field.metadata,
                });
                gotoComponent({
                  name: "add-action-field-form",
                  id: field.slug,
                });
              }}
              onDeleteField={handleFieldDelete}
            />
          ),
          "additional-widget": (
            <TabCreator
              gui={gui}
              onClickBack={() => {
                const currentId = searchParams.get("selectedTabId");
                if (additionalTabPath.length > 0) {
                  if (guiTab?.tab_type === "record_list") {
                    const parentTab = get(
                      guiTab.included_sidebar_widgets,
                      additionalTabPath.slice(0, -1)
                    ) as IncludeTabs;
                    searchParams.set("selectedTabId", parentTab.id);
                    setSearchParams(searchParams);
                    setTimeout(goBack, 0);
                    if (currentId !== "new-tab") {
                      setAdditionalTabPath((pa) => {
                        const newpath = pa.slice(0, -2);
                        return newpath;
                      });
                    }
                  }
                } else {
                  searchParams.delete("selectedTabId");
                  setSearchParams(searchParams);
                  setTimeout(goBack, 0);
                  setAdditionalTabPath([]);
                }
              }}
              indexKey={"included_sidebar_widgets"}
              onAddWidget={() => {
                searchParams.set("selectedTabId", "new-tab");
                setSearchParams(searchParams);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-widget",
                    id: v4(),
                  });
                }, 0);
              }}
              onEditWidget={(item: IncludeTabs, index: number) => {
                searchParams.set("selectedTabId", item.id);
                setSearchParams(searchParams);
                setAdditionalTabPath((path) => [
                  ...path,
                  index?.toString(),
                  "additional_tabs",
                ]);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-widget",
                    id: item.id,
                  });
                }, 0);
              }}
              allowAdditionalTabs={true}
              path={additionalTabPath}
              datasetDesigns={datasetDesigns}
              datasetDesignsMap={datasetDesignsMap}
              onEditFilterRules={(field) => {
                setSelectedField(field);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-widget-field-filters",
                    id: "additional-widget-field-filters",
                  });
                }, 0);
              }}
              onEditImageSetting={(field) => {
                setSelectedField(field);
                setTimeout(() => {
                  gotoComponent({
                    name: "image-setting-widget",
                    id: "image-setting-widget",
                  });
                }, 0);
              }}
              onEditRules={() => {
                gotoComponent({ name: "widget-rules", id: "widget-rules" });
              }}
              allowTabActions
              onAddActionClick={() => {
                setSelectedFusionAction(undefined);
                gotoComponent({
                  name: "fusion-action-editor-sidebar",
                  id: "fusion-action-editor-sidebar",
                });
              }}
              onEditActionClick={(action) => {
                setSelectedFusionAction(action);
                gotoComponent({
                  id: action.id,
                  name: "fusion-action-editor-sidebar",
                });
              }}
            />
          ),
          rules: (
            <RecordFilterRules
              gui={gui}
              guiTab={guiTab!}
              onClickBack={() => goBack()}
              dynamicRHS
            />
          ),
          "tab-rules": (
            <RecordFilterRules
              gui={gui}
              guiTab={guiTab!}
              onClickBack={() => goBack()}
              indexKey="included_tabs"
              path={additionalTabPath}
              dynamicRHS
            />
          ),
          "widget-rules": (
            <RecordFilterRules
              gui={gui}
              guiTab={guiTab!}
              onClickBack={() => goBack()}
              indexKey={"included_sidebar_widgets"}
              path={additionalTabPath}
              dynamicRHS
            />
          ),
          "additional-general-field-filters": (
            <FieldFilterRules
              gui={gui}
              onClickBack={() => goBack()}
              indexKey="general"
              path={additionalTabPath}
              selectedField={selectedField!}
            />
          ),
          "additional-tabs-field-filters": (
            <FieldFilterRules
              gui={gui}
              onClickBack={() => goBack()}
              indexKey="included_tabs"
              path={additionalTabPath}
              selectedField={selectedField!}
            />
          ),
          "additional-widget-field-filters": (
            <FieldFilterRules
              gui={gui}
              onClickBack={() => goBack()}
              indexKey="included_sidebar_widgets"
              path={additionalTabPath}
              selectedField={selectedField!}
            />
          ),
          "image-setting-general": (
            <ImageSetting
              gui={gui}
              onClickBack={() => goBack()}
              indexKey="general"
              path={additionalTabPath}
              selectedField={selectedField!}
            />
          ),
          "image-setting-tabs": (
            <ImageSetting
              gui={gui}
              onClickBack={() => goBack()}
              indexKey="included_tabs"
              path={additionalTabPath}
              selectedField={selectedField!}
            />
          ),
          "image-setting-widget": (
            <ImageSetting
              gui={gui}
              onClickBack={() => goBack()}
              indexKey="included_sidebar_widgets"
              path={additionalTabPath}
              selectedField={selectedField!}
            />
          ),
          "add-action-field-form": (
            <AddAssociatedField
              onBackClick={() => {
                goBack();
                setSelectedActionField(undefined);
              }}
              onSubmit={async (data) => {
                if (selectedField) {
                  await handleFieldUpdate(data);
                } else {
                  await handleFieldCreate(data);
                }

                goBack();
              }}
              dataField={selectedActionField}
              allowDefault
            />
          ),
        };
      },
      [
        additionalTabPath,
        datasetDesigns,
        datasetDesignsMap,
        gui,
        guiTab,
        handleFieldCreate,
        handleFieldDelete,
        handleFieldUpdate,
        handleFusionActionSubmit,
        searchParams,
        selectedActionField,
        selectedField,
        selectedFusionAction,
        setSearchParams,
      ]
    );

    useEffect(() => {
      setInitialComponent(getInitialComponent());
    }, []);

    const handleClose = () => {
      queryClient.refetchQueries([ApiModels.Dataset, datasetDesignSlug]);
      const t = searchParams.get("t");
      const params: Record<string, string> = {};
      if (t) {
        params.t = t;
      }
      setSearchParams(params);
      onClose();
    };

    useImperativeHandle(ref, () => ({
      setAdditionalTabPath,
    }));

    return (
      <DrawerBox
        anchor={"right"}
        open={open}
        width="420px"
        title="Widget Editor"
        icon={<GridViewOutlinedIcon />}
        onClose={handleClose}
      >
        <ScrollbarParent>
          <Scrollbar>
            <DatasetSidebarContainerWrap>
              {initialComponent && (
                <AnimationLayout
                  ref={layoutRef}
                  config={{
                    getComponents: getComponentMiddleComponent,
                    initialComponent,
                  }}
                />
              )}
            </DatasetSidebarContainerWrap>
          </Scrollbar>
        </ScrollbarParent>
      </DrawerBox>
    );
  }
);

export default GuiRightSideNav;
