import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import { DocumentElementType, FormFieldType, ListSource } from "enums/Form";
import { GuiType } from "enums/gui";
import useAppConfig from "hooks/useAppConfig";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

const DOCUMENT_TYPE_OPTIONS = [
  {
    label: "Create New",
    value: "create_new",
  },
  {
    label: "Choose Existing",
    value: "choose_existing",
  },
];

const formSchema = z.object({
  tab_name: z.string().min(1, { message: "Name is required" }),
  tab_type: z.enum([
    GuiType.Dashboard,
    GuiType.DashboardV2,
    GuiType.RecordList,
    GuiType.WorkflowBoard,
    GuiType.Reviewer,
    GuiType.GuiDoc,
  ]),
  document_type: z.enum(["create_new", "choose_existing"]).optional(),
  document_slug: z.string().optional(),
  dataset_design_slug: z.string().optional(),
  status_field: z.string().optional(),
});

export type AddGuiTabFormType = z.infer<typeof formSchema>;

type Props = {
  value?: AddGuiTabFormType;
  onSubmit(data: AddGuiTabFormType): Promise<void>;
  onClose: () => void;
} & Omit<DialogProps, "onSubmit">;

const AddGuiTabModal: React.FC<Props> = (props) => {
  const { onClose, onSubmit, value, ...dialogProps } = props;
  const appConfig = useAppConfig();
  const [isLoading, setIsLoading] = useState(false);

  const { data: datasetDesigns } = useListItems({
    modelName: ApiModels.DatasetDesign,
  });

  const form = useForm<AddGuiTabFormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
    watch,
  } = form;

  useEffect(() => {
    if (value && dialogProps.open) {
      reset(value);
    }

    return () => {
      reset({});
    };
  }, [value, dialogProps.open]);

  const submitHandler = (data: AddGuiTabFormType) => {
    setIsLoading(true);
    onSubmit(data)
      .then(() => {
        reset();
        onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    onClose?.();
    reset();
  };

  const tabType = watch("tab_type");
  const datasetDesignSlug = watch("dataset_design_slug");
  const documentType = watch("document_type");

  const selectedDatasetDesign = datasetDesigns?.find(
    (d) => d.slug === datasetDesignSlug
  );

  return (
    <Dialog
      onClose={handleClose}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Add Gui Tab</DialogTitle>

      <Scrollbar className="form-scroller">
        <DialogContent>
          <Stack gap="10px" component="form">
            <FormField
              label="Tab Title"
              error={dirtyFields.tab_name ? errors.tab_name : undefined}
            >
              <TextField
                {...register("tab_name")}
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                hiddenLabel
                size="small"
                variant="filled"
              />
            </FormField>
            <FormField
              label="Type"
              error={dirtyFields.tab_type ? errors.tab_type : undefined}
            >
              <Controller
                control={control}
                name="tab_type"
                render={({ field }) => (
                  <Select
                    {...field}
                    variant="filled"
                    size="small"
                    fullWidth
                    margin="dense"
                    id="type"
                  >
                    {appConfig.gui_type_dashboard && (
                      <MenuItem value={GuiType.Dashboard}>Dashboard</MenuItem>
                    )}
                    {appConfig.gui_type_dashboard && (
                      <MenuItem value={GuiType.DashboardV2}>
                        Dashboard V2
                      </MenuItem>
                    )}
                    {appConfig.gui_type_record_list && (
                      <MenuItem value={GuiType.RecordList}>
                        Record List
                      </MenuItem>
                    )}
                    {appConfig.gui_type_workflow_board && (
                      <MenuItem value={GuiType.WorkflowBoard}>
                        Workflow Board
                      </MenuItem>
                    )}
                    {appConfig.gui_type_reviewer && (
                      <MenuItem value={GuiType.Reviewer}>Reviewer</MenuItem>
                    )}
                    <MenuItem value={GuiType.GuiDoc}>Gui Document</MenuItem>
                  </Select>
                )}
              />
            </FormField>
            {tabType === GuiType.GuiDoc && (
              <>
                <FormField
                  label="Document Type"
                  error={
                    dirtyFields.document_type ? errors.document_type : undefined
                  }
                  type={DocumentElementType.Select}
                  formControl={{ control, name: "document_type" }}
                  fieldExtras={{
                    field: {
                      list_source: ListSource.Hardcoded,
                      list_items: DOCUMENT_TYPE_OPTIONS,
                    },
                  }}
                />
                {documentType === "choose_existing" && (
                  <FormField
                    label="Document"
                    error={
                      dirtyFields.document_slug
                        ? errors.document_slug
                        : undefined
                    }
                    type={FormFieldType.GuiDocument}
                    formControl={{ control, name: "document_slug" }}
                  />
                )}
              </>
            )}
            {[
              GuiType.RecordList,
              GuiType.WorkflowBoard,
              GuiType.Reviewer,
            ].includes(tabType) && (
              <FormField label="Dataset Design">
                <TextField
                  {...register("dataset_design_slug")}
                  margin="dense"
                  id="dataset_design_slug"
                  type="text"
                  fullWidth
                  select
                  hiddenLabel
                  size="small"
                  variant="filled"
                >
                  {datasetDesigns?.map((opt) => (
                    <MenuItem key={opt.slug} value={opt.slug}>
                      {opt.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormField>
            )}
            {tabType === GuiType.WorkflowBoard && !!selectedDatasetDesign && (
              <FormField label="Status Field">
                <TextField
                  {...register("status_field")}
                  margin="dense"
                  id="status_field"
                  type="text"
                  fullWidth
                  select
                  hiddenLabel
                  size="small"
                  variant="filled"
                >
                  {selectedDatasetDesign.fields.fields?.map((field) => (
                    <MenuItem key={field.slug} value={field.slug}>
                      {field.title}
                    </MenuItem>
                  ))}
                </TextField>
              </FormField>
            )}
          </Stack>
        </DialogContent>
      </Scrollbar>

      <DialogActions>
        <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
        <LoadingButton
          onClick={handleSubmit(submitHandler, (errors) => {
            console.log(errors);
          })}
          variant="contained"
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddGuiTabModal;
