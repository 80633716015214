import "ag-grid-community/styles/ag-grid.min.css";
import "ag-grid-community/styles/ag-theme-quartz.min.css";
import "./styles.css";

import { Box } from "@mui/material";
import {
  CellEditingStoppedEvent,
  ColDef,
  IDatasource,
} from "ag-grid-community";
import {
  AgGridReact,
  AgGridReactProps,
  CustomCellRendererProps,
} from "ag-grid-react";
import Cell from "components/DatasetTable/Cell";
import DefaultTextCellRenderer from "components/DatasetTable/colDefs/DefaultTextCellRenderer";
import { DocumentElementType } from "enums";
import DatasetModel from "models/Dataset";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import { ReactNode, forwardRef, useMemo } from "react";
import getColDef from "./colDefs";

type DatasetTableProps = {
  datasetDesignSlug: string;
  editable?: boolean;
  defaultTitleColumn?: boolean;
  limit?: number;
  orderBy?: {
    field?: string;
    order?: "asc" | "desc";
  };
  columnsToShow?: string[];
  disableRowSelection?: boolean;
  disableRowDrag?: boolean;
  conditionSets?: unknown[];
  disableColumnFilters?: boolean;
  minColumnWidth?: number;
  actionsColumn?: {
    enabled: boolean;
    getHeader?: () => ReactNode;
    getRowAction?: (data: Record<string, unknown>) => ReactNode;
  };
} & AgGridReactProps;

const getDataSource = (
  datasetDesignSlug: string,
  limit?: number,
  includedFields?: string[],
  filter?: unknown
): IDatasource => ({
  async getRows(params) {
    try {
      const dataLimit = limit ?? 10;
      const result = await DatasetModel.list({
        path: `list/${datasetDesignSlug}`,
        query: {
          dataset_type_slug: datasetDesignSlug,
          included_fields: includedFields ? includedFields.join(",") : "all",
          limit: dataLimit,
          offset: params.startRow,
          filter,
        },
      });
      params.successCallback(
        result.data,
        result.data.length < dataLimit
          ? params.endRow - result.data.length
          : undefined
      );
    } catch (error) {
      params.failCallback();
    }
  },
});

const getIfEditable = (field: DataField, globalEditable: boolean) => {
  return field.editable ?? globalEditable;
};

type DatasetTableWithFieldsProps = {
  fields: DataField[];
  handleUpdate?: (event: CellEditingStoppedEvent) => void;
  editable?: boolean;
  defaultTitleColumn?: boolean;
  disableRowSelection?: boolean;
  disableRowDrag?: boolean;
  actionsColumn?: {
    enabled: boolean;
    getHeader?: () => ReactNode;
    getRowAction?: (data: Record<string, unknown>) => ReactNode;
  };
  minColumnWidth?: number;
  disableColumnFilters?: boolean;
} & AgGridReactProps;

export const DatasetTableWithFields = forwardRef<
  AgGridReact,
  DatasetTableWithFieldsProps
>((props, ref) => {
  const {
    fields,
    datasource,
    handleUpdate,
    editable = true,
    defaultTitleColumn = true,
    actionsColumn,
    disableRowDrag,
    disableRowSelection,
    disableColumnFilters,
    minColumnWidth,
    ...gridProps
  } = props;
  const columnDefs = useMemo<ColDef[]>(() => {
    if (!fields.length) {
      return [];
    }

    const defs = fields
      .filter((field) => field.type !== DocumentElementType.Label)
      .map<ColDef>((field, index) => ({
        field: field.slug,
        colId: field.slug,
        headerName: field.title,
        floatingFilter: disableColumnFilters ? false : true,
        suppressHeaderMenuButton: true,
        minWidth: minColumnWidth,
        resizable: false,
        minHeight: "36px",
        maxHeight: "36px",
        flex: 1,
        ...getColDef(field, { disableColumnFilters }),
        editable: getIfEditable(field, editable),
        ...(index === fields.length - 1
          ? { cellClass: "ag-cell-last", headerClass: "ag-header-cell-last" }
          : {}),
      }));

    const rowSelectionParams = disableRowSelection
      ? {}
      : {
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionCurrentPageOnly: true,
          headerCheckboxSelectionFilteredOnly: true,
        };
    if (defaultTitleColumn) {
      defs.unshift({
        field: "title",
        headerName: "Title",
        editable,
        filter: disableColumnFilters ? false : true,
        floatingFilter: disableColumnFilters ? false : true,
        suppressHeaderMenuButton: true,
        minWidth: minColumnWidth,
        ...rowSelectionParams,
        rowDrag: !disableRowDrag,
        cellRenderer: DefaultTextCellRenderer,
      });
    } else if (defs[0]) {
      defs[0] = {
        ...defs[0],
        ...rowSelectionParams,
        rowDrag: !disableRowDrag,
      };
    }

    if (actionsColumn?.enabled) {
      const { getHeader, getRowAction } = actionsColumn;

      defs.push({
        field: "action",
        headerComponent: getHeader,
        filter: false,
        floatingFilter: false,
        pinned: "right",
        resizable: false,
        width: 36,
        // maxWidth: 48,
        cellClass: "ag-action-cell",
        headerClass: "ag-action-header",
        cellRenderer: (props: CustomCellRendererProps) => (
          <Cell isLoading={!props.data}>{getRowAction?.(props.data)}</Cell>
        ),
      });
    }

    return defs;
  }, [
    fields,
    disableRowSelection,
    defaultTitleColumn,
    actionsColumn,
    disableColumnFilters,
    minColumnWidth,
    editable,
    disableRowDrag,
  ]);

  return (
    <Box
      className="ag-theme-quartz-dark"
      sx={{ height: "100%", ".ag-icon-grip": { transform: "rotate(90deg)" } }}
    >
      <AgGridReact
        ref={ref}
        columnDefs={columnDefs}
        rowModelType="infinite"
        datasource={datasource}
        cacheBlockSize={10}
        rowHeight={36}
        onGridReady={(params) => {
          params?.api?.setHeaderHeight(36);
        }}
        pagination={true}
        paginationAutoPageSize={true}
        getRowId={(params) => {
          return params.data.id;
        }}
        tooltipShowDelay={1000}
        tooltipHideDelay={1000}
        tooltipInteraction
        suppressRowClickSelection
        reactiveCustomComponents
        onCellEditingStopped={handleUpdate}
        {...gridProps}
      />
    </Box>
  );
});

const DatasetTable = forwardRef<AgGridReact, DatasetTableProps>(
  (props, ref) => {
    const {
      datasetDesignSlug,
      editable,
      defaultTitleColumn,
      orderBy,
      limit,
      columnsToShow,
      conditionSets,
      ...gridProps
    } = props;

    const { data: datasetDesign } = useGetItem({
      modelName: ApiModels.DatasetDesign,
      slug: datasetDesignSlug,
    });

    const { mutate: updateDataset } = useUpdateItem({
      modelName: ApiModels.Dataset,
      requestOptions: { path: datasetDesignSlug },
    });

    const handleUpdate = (event: CellEditingStoppedEvent) => {
      if (!event.valueChanged || event.data.id == null || !event.colDef.field) {
        return;
      }

      updateDataset({
        slug: event.data.id,
        data: {
          dataset_type_slug: datasetDesignSlug,
          fields: {
            [event.colDef.field]: event.newValue,
          },
        },
      });
    };

    const datasource = useMemo<IDatasource>(() => {
      return getDataSource(datasetDesignSlug, limit, columnsToShow, {
        conditions: conditionSets,
      });
    }, [datasetDesignSlug, limit, columnsToShow, conditionSets]);

    const fields = useMemo(() => {
      if (!columnsToShow) {
        return datasetDesign?.fields?.fields ?? [];
      }

      return (datasetDesign?.fields?.fields ?? []).filter((field) =>
        columnsToShow.includes(field.slug)
      );
    }, [datasetDesign?.fields?.fields, columnsToShow]);

    return fields && fields.length > 0 ? (
      <DatasetTableWithFields
        key={fields.length}
        ref={ref}
        {...gridProps}
        cacheBlockSize={limit ?? 10}
        defaultTitleColumn={defaultTitleColumn}
        editable={editable}
        fields={fields}
        datasource={datasource}
        handleUpdate={handleUpdate}
      />
    ) : (
      <></>
    );
  }
);

export default DatasetTable;
