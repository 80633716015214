import { Box, Stack, Typography, styled } from "@mui/material";
import DynamicEditForm, {
  FormRefAttribute,
} from "components/Form/DynamicEditForm";
import Scrollbar from "components/Scrollbar";
import { DocumentElementType } from "enums/Form";
import useAccountSlug from "hooks/useAccountSlug";
import useAppNavigate from "hooks/useAppNavigate";
import InnerPageLayout from "layouts/inner-app-layout";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import useUpdateItem from "queries/useUpdateItem";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useSystemLayoutStore from "store";
import AddSqlQueryModal from "views/app-view/sql-query/AddSqlQueryModal";
import RightSideBar from "views/app-view/sql-query/RightSideBar";

const CenterBox = styled(Box)(({ theme }) => ({
  flexGrow: "1",
  flexBasis: "0",
  minWidth: "0",
  height: "calc(100vh - 60px)",

  ".side-menu-active &": {
    height: "100vh",
  },
}));

const RightSideBox = styled(Box)(({ theme }) => ({
  width: "420px",
  height: "calc(100vh - 60px)",

  ".side-menu-active &": {
    height: "100vh",
  },
}));

const AddModal = () => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  useSystemLayoutStore.useSetButtonProps()({
    onClick: () => setAddModalOpen(true),
  });

  return (
    <AddSqlQueryModal
      open={addModalOpen}
      onClose={() => setAddModalOpen(false)}
      onSubmit={(data) => {}}
    />
  );
};

const fields = [
  {
    id: "label",
    name: "label",
    label: "General Settings",
    type: DocumentElementType.Label,
  },
  {
    id: "name",
    name: "name",
    label: "Name",
    type: DocumentElementType.TextField,
  },
  {
    id: "query_slug",
    name: "query_slug",
    label: "Slug",
    type: DocumentElementType.TextField,
    disabled: true,
  },
  {
    id: "value",
    name: "value",
    label: "Query Value",
    type: DocumentElementType.TextField,
  },
  {
    id: "description",
    name: "description",
    label: "Description",
    type: DocumentElementType.TextArea,
  },
];

const SqlQuery: React.FC = () => {
  const { querySlug } = useParams<{ querySlug: string }>();
  const appNavigate = useAppNavigate();
  const accountSlug = useAccountSlug();

  const formRef = useRef<FormRefAttribute | undefined>();

  const { data: sqlQueries = [] } = useListItems({
    modelName: ApiModels.SqlQuery,
  });

  const { mutateAsync: updateSqlQuery } = useUpdateItem({
    modelName: ApiModels.SqlQuery,
  });

  const setMenu = useSystemLayoutStore.useSetMenu();
  const menuItemProps = useSystemLayoutStore.useSetItemProps();
  const setDisableFolders = useSystemLayoutStore.useSetDisableFolders();
  const setButtonOptions = useSystemLayoutStore.useSetButtonOptions();
  const setShowActionButtons = useSystemLayoutStore.useSetShowActionButtons();
  const setShowSideNavTopSelector =
    useSystemLayoutStore.useSetShowSideNavTopSelector();

  useEffect(() => {
    setDisableFolders(true);
    setShowSideNavTopSelector(false);
    setShowActionButtons(true);
    setButtonOptions({ addFolder: false, addItem: true });
  }, [
    setDisableFolders,
    setShowActionButtons,
    setShowSideNavTopSelector,
    setButtonOptions,
  ]);

  useEffect(() => {
    menuItemProps({
      onClick: (item) => {
        console.log("🚀 ~ useEffect ~ item:", item);
        appNavigate(`/sql-query-module/${item.key}`);
      },
      isActive: (item) => item.key === querySlug,
    });
  }, [querySlug, accountSlug, menuItemProps, appNavigate]);

  useEffect(() => {
    setMenu(
      sqlQueries?.map((query) => ({
        key: query.slug,
        title: query.name,
      })) || []
    );

    if (!querySlug && sqlQueries && sqlQueries?.length > 0) {
      appNavigate(`/sql-query-module/${sqlQueries[0].slug}`);
    }
  }, [appNavigate, setMenu, querySlug, sqlQueries]);

  const selectedQuery = useMemo(
    () => sqlQueries?.find((query) => query.slug === querySlug),
    [querySlug, sqlQueries]
  );

  const submitHandler = (data: Record<string, any>) => {
    if (querySlug) {
      updateSqlQuery({
        slug: querySlug,
        data: {
          name: data.name,
          value: data.value,
          description: data.description,
        },
      });
    }
  };

  return (
    <>
      {selectedQuery && (
        <Stack direction="row">
          <CenterBox>
            <Scrollbar>
              <InnerPageLayout
                sx={{ padding: "40px" }}
                title={selectedQuery?.name}
                subtitle={
                  <Typography variant="body1" color="text.secondary">
                    Description:{" "}
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="text.primary"
                    >
                      <>{selectedQuery?.description}</>
                    </Typography>
                  </Typography>
                }
              >
                <DynamicEditForm
                  key={selectedQuery.slug}
                  name={"fields"}
                  fields={fields}
                  ref={formRef}
                  defaultValues={selectedQuery}
                  onSubmit={submitHandler}
                />
              </InnerPageLayout>
            </Scrollbar>
          </CenterBox>

          <RightSideBox
            sx={{
              background: (theme) =>
                theme.palette.background.GFRightNavBackground,
            }}
          >
            <RightSideBar shortCodes={selectedQuery.short_codes ?? []} />
          </RightSideBox>
        </Stack>
      )}
      <AddModal />
    </>
  );
};

export default SqlQuery;
