import { $isHeadingNode } from "@lexical/rich-text";
import { Box, styled } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import LexicalPlayground from "components/LexicalPlayground";
import Spin from "components/Spin";
import { MEDIA_BUCKET } from "configs/AppConfig";
import { $getRoot, ElementNode, LexicalNode } from "lexical";
import debounce from "lodash/debounce";
import S3Model from "models/S3";
import { FC, useEffect, useMemo, useState } from "react";
import useGuiDocumentStore from "store/stores/gui-document";

const saveGuiDocument = async (fileKey: string, content: string) => {
  const { data } = await S3Model.getUploadUrl({
    bucket: MEDIA_BUCKET,
    key: fileKey,
  });
  const uploadUrl = data.url;
  if (uploadUrl) {
    axios.put(uploadUrl, content);
  }
};

const Container = styled(Box)({
  height: "100%",
  width: "100%",
});

type DocEditorProps = {
  guiTab?: GuiTab;
  guiDocument?: GuiDocument;
};

const DocEditor: FC<DocEditorProps> = (props) => {
  const { guiDocument } = props;

  const selectedPageId = useGuiDocumentStore.useSelectedPageId();
  const setSelectedPageId = useGuiDocumentStore.useSetSelectedPageId();
  const addPageContent = useGuiDocumentStore.useAddPageContent();
  const addPageHeadings = useGuiDocumentStore.useAddPageHeadings();

  // const { send } = useSocket();

  const { mutate } = useMutation({
    mutationKey: ["gui-document", "content", "updated"],
    mutationFn: async (data: { fileKey: string; content: string }) => {
      await saveGuiDocument(data.fileKey, data.content);
    },
  });

  const [editorState, setEditorState] = useState<Record<string, unknown>>();
  const [loading, setLoading] = useState(false);

  const saveDoc = useMemo(() => debounce(mutate, 5000), [mutate]);

  const page = useMemo(
    () => guiDocument?.pages?.find((p) => p.id === selectedPageId),
    [guiDocument, selectedPageId]
  );

  // useEffect(() => {
  //   return () => {
  //     saveDoc.flush();
  //   };
  // }, [saveDoc]);

  useEffect(() => {
    if (guiDocument?.pages?.[0]?.id && selectedPageId == null) {
      setSelectedPageId(guiDocument.pages[0].id);
    }

    return () => {
      setSelectedPageId(null);
    };
  }, [guiDocument]);

  // useEffect(() => {
  //   const loadDocument = async () => {
  //     if (!guiDocument || !page) {
  //       return;
  //     }

  //     const { data } = await S3Model.getDownloadUrl({
  //       bucket: MEDIA_BUCKET,
  //       key: page.s3_key,
  //     });

  //     const downloadUrl = data.url;

  //     if (!downloadUrl) {
  //       return;
  //     }

  //     const response = await axios.get(downloadUrl);
  //     addPageContent(page.id, response.data);
  //     setEditorState(response.data || undefined);
  //   };

  //   setLoading(true);
  //   loadDocument().finally(() => {
  //     setLoading(false);
  //   });
  // }, [guiDocument, page]);

  // useEffect(() => {
  //   setEditorState(undefined);
  // }, [selectedPageId]);

  // const initialFocus = useRef(true);

  return (
    <Container>
      {selectedPageId && (
        <Spin spinning={loading}>
          <LexicalPlayground
            collaborationId={selectedPageId}
            // state={editorState}
            onChange={(editorState, editor, tags) => {
              // if (!initialFocus.current) {
              //   const isEditorEmpty = editorState.read(() => {
              //     const root = $getRoot();
              //     const isEmpty =
              //       (root.getFirstChild() as ElementNode)?.isEmpty() &&
              //       root.getChildrenSize() === 1;

              //     return isEmpty;
              //   });

              //   if (isEditorEmpty) {
              //     send(
              //       JSON.stringify({
              //         type: "collaboration-empty-doc",
              //         data: selectedPageId,
              //       })
              //     );
              //   }
              // } else {
              //   initialFocus.current = false;
              // }

              editor.update(() => {
                if (page?.s3_key && !loading) {
                  try {
                    saveDoc({
                      fileKey: page.s3_key,
                      content: JSON.stringify(editorState.toJSON()),
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }

                const root = $getRoot();
                const h1Nodes: { key: string; text: string }[] = [];

                const findH1Nodes = (node: LexicalNode) => {
                  if ($isHeadingNode(node) && node.getTag() === "h1") {
                    h1Nodes.push({
                      key: node.getKey(),
                      text: node.getTextContent(),
                    });
                  }

                  if (node instanceof ElementNode) {
                    node.getChildren().forEach(findH1Nodes);
                  }
                };

                root.getChildren().forEach(findH1Nodes);

                if (page?.id) {
                  addPageHeadings(page.id, h1Nodes);
                }
              });
            }}
          />
        </Spin>
      )}
    </Container>
  );
};

export default DocEditor;
