import {
  Box,
  debounce,
  Stack,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import InnerPageLayout from "layouts/inner-app-layout";

import { zodResolver } from "@hookform/resolvers/zod";
import GenericIcon from "components/util-components/Icon";
import { PhoneNumberRegex } from "constants/index";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import isAccountOwner from "utils/isAccountOwner";
import { z } from "zod";
import BasicComponent from "./BasicComponent";
import Permission from "./Permission";
import SidebarSetting from "./Sidebar";

const InnerAppLayout = styled(Box)(({ theme }) => {
  return {
    ".heading-card": {
      padding: "0",
    },
  };
});

const TabStyle = ({ title }: { title: string }) => {
  return (
    <Stack direction="row" spacing={1}>
      <Typography className="tab-text" sx={{ color: "#fff" }}>
        {title}
      </Typography>
    </Stack>
  );
};

const formSchema = z.object({
  first_name: z.string().optional(),
  last_name: z.string().optional(),
  account_user_type: z.string().optional(),
  seat_type_id: z.string().optional(),
  email: z.string().email("Please enter valid email"),
  phone: z
    .string()
    .regex(PhoneNumberRegex, "Please enter a valid phone number"),
  image: z.any().optional(),
  mailing_address: z
    .object({
      address1: z.string().optional(),
      address2: z.string().optional(),
      city: z.string().optional(),
      state: z.string().optional(),
      zip: z.string().optional(),
    })
    .optional(),
  permissions: z
    .object({
      co_pilot_access: z.boolean().optional(),
      jobs_access: z.boolean().optional(),
      dataset_access: z.boolean().optional(),
      workflow_access: z.boolean().optional(),
      import_template_access: z.boolean().optional(),
      dashboards_access: z.boolean().optional(),
      media_access: z.boolean().optional(),
      icon_access: z.boolean().optional(),
      user_access: z.boolean().optional(),
      sylar_access: z.boolean().optional(),
      apps_access: z.boolean().optional(),
      vector_embeds_access: z.boolean().optional(),
      fine_tune_models_access: z.boolean().optional(),
      skills_access: z.boolean().optional(),
      adventure_access: z.boolean().optional(),
      billing_module: z.boolean().optional(),
    })
    .optional(),
});
export type FormType = z.infer<typeof formSchema>;
type props = { onNoteEdit: (_: UniversalNote) => void };
const MiddleComponent = ({ onNoteEdit }: props) => {
  const [searchParams] = useSearchParams();
  const { slug: accountUserSlug } = useParams<{ slug: string }>();
  const [value, setValue] = useState(Number(searchParams.get("t")) || 0);
  const theme = useTheme();
  const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const { data: accountUser } = useGetItem({
    modelName: ApiModels.AccountUser,
    slug: accountUserSlug,
  });
  const { mutate: updateAccountUser } = useUpdateItem({
    modelName: ApiModels.AccountUser,
    mutationOptions: {
      mutationKey: [ApiModels.AccountUser],
    },
  });

  const form = useForm<Partial<FormType>>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: accountUser,
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    reset,
    watch,
  } = form;
  const initialValueSet = useRef(false);
  const allowNetworkRequest = useRef(false);

  const tabLists = useMemo(() => {
    const tabs = [
      {
        label: <TabStyle title="General" />,
      },
    ];
    if (!isAccountOwner(accountUser?.subscription?.account_user_type)) {
      tabs.push({
        label: <TabStyle title="Permissions" />,
      });
    }
    if (!xlScreen)
      tabs.push({
        label: <TabStyle title="Setting" />,
      });
    return tabs;
  }, [accountUser?.subscription?.account_user_type, xlScreen]);

  useEffect(() => {
    if (xlScreen) {
      setValue((prev) => {
        //Change this right bar index or last index
        if (prev === tabLists.length) return 0;
        return prev;
      });
    }
  }, [xlScreen]);

  const submitHandler = useCallback(
    (
      data: Partial<User & { account_user_type: string; seat_user_id: string }>
    ) => {
      if (accountUserSlug && allowNetworkRequest.current) {
        updateAccountUser(
          { slug: accountUserSlug, data },
          {
            onSuccess: () => {
              console.log("inner success");
            },
          }
        );
      }
    },
    [accountUserSlug, updateAccountUser]
  );
  useEffect(() => {
    initialValueSet.current = false;
  }, [accountUserSlug]);
  useEffect(() => {
    if (accountUser && !initialValueSet.current) {
      reset({
        ...accountUser,
        seat_type_id: accountUser?.subscription?.seat_type_id,
        permissions: accountUser?.subscription?.permissions,
      });
      initialValueSet.current = true;
      setTimeout(() => {
        allowNetworkRequest.current = true;
      }, 1000);
    }
  }, [reset, accountUser]);

  useEffect(() => {
    const submitDeb = debounce(() => {
      handleSubmit(submitHandler)();
    }, 600);
    const subscription = watch((value, { name }) => {
      switch (true) {
        case name === "seat_type_id":
        case name?.startsWith("permissions"):
          submitDeb();
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, submitHandler, handleSubmit]);

  const handleChange = (_: any, tab: number) => {
    setValue(tab);
  };

  if (!accountUser) return null;
  return (
    <InnerAppLayout>
      <InnerPageLayout
        sx={{ padding: "40px" }}
        icon={
          (accountUser?.image?.url && (
            <GenericIcon
              sx={{
                width: "40px",
                height: "40px",
                color: "text.secondary",
              }}
              iconName={accountUser?.image.url as any}
              key={accountUser?.image.url}
            />
          )) as any
        }
        title={`${accountUser?.first_name} ${accountUser?.last_name}`}
        onChange={handleChange}
        subtitle={
          <Typography variant="body1" color="text.secondary">
            Title:{" "}
            <Typography
              component="span"
              variant="subtitle1"
              color="text.primary"
            >
              Gui Fusion CEO
            </Typography>
          </Typography>
        }
        tabList={tabLists}
        value={value}
        defaultValue={0}
      >
        {value === 0 && (
          <FormProvider {...form}>
            <form
              onSubmit={handleSubmit((data) => {
                delete data.seat_type_id;
                submitHandler(data);
              })}
            >
              <BasicComponent
                register={register}
                errors={errors}
                control={control}
                hideSeatTypeField={isAccountOwner(
                  accountUser?.subscription?.account_user_type
                )}
              />
            </form>
          </FormProvider>
        )}
        {value === 1 &&
          !isAccountOwner(accountUser?.subscription?.account_user_type) && (
            <Permission register={register} control={control} />
          )}

        {value === tabLists.length - 1 && !xlScreen && (
          <Box>
            <SidebarSetting disableScrollbar={true} />
          </Box>
        )}
      </InnerPageLayout>
    </InnerAppLayout>
  );
};

export default MiddleComponent;
