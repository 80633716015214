import Model from "models";

class S3Model {
  private model = new Model("/s3-presigner", "public-1");

  getUploadUrl(data: { key: string; bucket: string; expiresIn?: number }) {
    return this.model.sendRequest<{ url: string }>("", "POST", {
      type: "put",
      ...data,
    });
  }

  getDownloadUrl(data: { key: string; bucket: string; expiresIn?: number }) {
    return this.model.sendRequest<{ url: string }>("", "POST", {
      type: "get",
      ...data,
    });
  }
}

export default new S3Model();
