import { Box, Stack, styled } from "@mui/material";
import { DynamicEditFormProps } from "components/Form/DynamicEditForm";
import { GuiType } from "enums/gui";
import { ApiModels } from "queries/apiModelMapping";
import { useGuiTabs } from "queries/gui/useGuiTabs";
import useGetItem from "queries/useGetItem";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSystemLayoutStore from "store";
import EditDataset from "./EditDateset";

type MiddleComponentProps = {
  onFormEvent?: DynamicEditFormProps["onFormEvent"];
  data?: any;
  datasetDesignSlug: string;
};

const EditBox = styled(Box)(({ theme }) => {
  return {
    padding: "24px",

    [`${theme.breakpoints.down("sm")}`]: {
      padding: "11px 15px",
    },
  };
});

export const BoxHeader = styled(Stack)(({ theme }) => ({
  gap: "24px",
  marginBottom: "48px",

  [`${theme.breakpoints.down("sm")}`]: {
    gap: "8px",
    margin: "-11px -15px 20px",
    padding: "11px 15px 11px",
    background: theme.palette.common.blackshades["30p"],
    borderBottom: `1px solid ${theme.palette.background.GFOutlineNav}`,
  },
}));

const MiddleComponent: React.FC<MiddleComponentProps> = (props) => {
  const { onFormEvent, data, datasetDesignSlug } = props;

  const { datasetSlug, slug: guiSlug } =
    useParams<{
      slug: string;
      datasetSlug?: string;
    }>();

  const { data: gui } = useGetItem({
    modelName: ApiModels.Gui,
    slug: guiSlug,
    queryOptions: { enabled: !!guiSlug },
  });
  const { data: guiTabs = [] } = useGuiTabs(gui?.slug);

  const selectedGuiTab = useSystemLayoutStore.useSelectedTopBarTab();

  const guiTab = useMemo(() => {
    if (selectedGuiTab != null && guiTabs) {
      return guiTabs?.[Number(selectedGuiTab)];
    }
  }, [guiTabs, selectedGuiTab]);

  const includedFieldIds =
    guiTab?.tab_type === GuiType.RecordList ||
    guiTab?.tab_type === GuiType.Reviewer
      ? guiTab?.form_fields || []
      : [];

  return (
    <EditBox>
      <EditDataset
        onFormEvent={onFormEvent}
        data={data}
        datasetDesignSlug={datasetDesignSlug}
        datasetSlug={datasetSlug}
        guiSlug={guiSlug!}
        includedFieldIds={includedFieldIds}
      />
    </EditBox>
  );
};

export default MiddleComponent;
