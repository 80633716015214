import { zodResolver } from "@hookform/resolvers/zod";
import GridViewOutlined from "@mui/icons-material/GridViewOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, Button, DrawerProps, Stack, styled } from "@mui/material";
import DatasetDesignPicker from "components/DatasetDesignPicker";
import FormField from "components/FormField";
import GfDrawer from "components/GfDrawer";
import { DocumentElementType } from "enums";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const Container = styled(Box)({
  padding: "16px",
});

const formSchema = z.object({
  description: z.string().optional(),
  enable_read: z.number().default(0),
  enable_write: z.number().default(0),
  enable_delete: z.number().default(0),
});

type EditAgentAccessFormType = z.infer<typeof formSchema>;

type AgentDatasetRightEditDrawerProps = {
  agentDatasetRight?: AgentDatasetRight;
  onSubmitDone?(): void;
} & DrawerProps;

const AgentDatasetRightEditDrawer: FC<AgentDatasetRightEditDrawerProps> = (
  props
) => {
  const { open, onClose, agentDatasetRight, onSubmitDone, ...drawerProps } =
    props;

  const form = useForm<EditAgentAccessFormType>({
    resolver: zodResolver(formSchema),
  });

  const { control, handleSubmit, setValue } = form;

  const { mutateAsync: updateAgentDatasetRight, isLoading } = useUpdateItem({
    modelName: ApiModels.AgentDatasetRight,
  });

  useEffect(() => {
    if (open && agentDatasetRight) {
      setValue("description", agentDatasetRight.description);
      setValue("enable_read", agentDatasetRight.enable_read ? 1 : 0);
      setValue("enable_write", agentDatasetRight.enable_write ? 1 : 0);
      setValue("enable_delete", agentDatasetRight.enable_delete ? 1 : 0);
    }
  }, [open, setValue, agentDatasetRight]);

  const onSubmit = async (data: EditAgentAccessFormType) => {
    if (agentDatasetRight) {
      await updateAgentDatasetRight({
        slug: agentDatasetRight.slug,
        data: {
          enable_read: Boolean(data.enable_read),
          enable_write: Boolean(data.enable_write),
          enable_delete: Boolean(data.enable_delete),
        },
      });
      onClose?.({}, "escapeKeyDown");
      onSubmitDone?.();
    }
  };

  return (
    <GfDrawer
      anchor="right"
      open={open}
      width="420px"
      title="Edit Agent Dataset Right"
      icon={<GridViewOutlined />}
      onClose={onClose}
      {...drawerProps}
    >
      <Container>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField label="Dataset Design" value={agentDatasetRight?.slug}>
              {({ value, onChange }) => (
                <DatasetDesignPicker
                  value={value as string}
                  onChange={(v) => onChange?.(v as string)}
                  disabled
                />
              )}
            </FormField>
            <FormField
              label="Enable Read"
              type={DocumentElementType.Boolean}
              formControl={{ control, name: "enable_read" }}
            />
            <FormField
              label="Enable Write"
              type={DocumentElementType.Boolean}
              formControl={{ control, name: "enable_write" }}
            />
            <FormField
              label="Enable Delete"
              type={DocumentElementType.Boolean}
              formControl={{ control, name: "enable_delete" }}
            />
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1.5}
              sx={{ mt: 2 }}
            >
              <Button
                variant="outlined"
                color="inherit"
                sx={{
                  border: "none",
                  color: "#fff",
                  background: (theme) => theme.palette.background.GF10,
                }}
                onClick={(e) => onClose?.(e, "escapeKeyDown")}
                fullWidth
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  borderColor: "#fff",
                  boxShadow: "none",
                }}
                fullWidth
                loading={isLoading}
              >
                Save Changes
              </LoadingButton>
            </Stack>
          </form>
        </FormProvider>
      </Container>
    </GfDrawer>
  );
};

export default AgentDatasetRightEditDrawer;
