import CloseOutlined from "@mui/icons-material/CloseOutlined";
import {
  Box,
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import Scrollbar from "components/Scrollbar";
import AnimationLayout, { Config } from "layouts/AnimationLayout";
import { FC, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import GroupEditor from "./GroupEditor";
import GroupFieldEditor from "./GroupFieldEditor";
import GroupListing from "./GroupListing";

const DrawerStyled = styled(Drawer)(({ theme }) => ({}));

const DrawerContent = styled(Box)(({ theme }) => ({
  width: "420px",
  height: "100%",
  background: theme.palette.background.LeftNavBody,

  ".animation-parent": {
    width: "100%",
  },
}));

type GuiFiltersProps = {
  guiSlug?: string;
  onClose(): void;
} & DrawerProps;

const GuiFilters: FC<GuiFiltersProps> = (props) => {
  const { guiSlug, onClose, ...drawerProps } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedGroup, setSelectedGroup] = useState<GuiParamGroup>();
  const [selectedParam, setSelectedParam] = useState<GuiParams>();

  const handleClose = useCallback(() => {
    setSelectedGroup(undefined);
    setSelectedParam(undefined);
    onClose();

    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete("c");
    updatedParams.delete("c_name");

    setSearchParams(updatedParams);
  }, [onClose, searchParams, setSearchParams]);

  const getComponents: Config["getComponents"] = useCallback(
    (gotoComponent, goBack) => ({
      main: (
        <GroupListing
          guiSlug={guiSlug}
          onAddClick={() => {
            gotoComponent({ id: "group-editor", name: "group-editor" });
          }}
          onEditClick={(group) => {
            setSelectedGroup(group);
            gotoComponent({ id: group.slug, name: "group-editor" });
          }}
        />
      ),
      "group-editor": (
        <GroupEditor
          guiSlug={guiSlug}
          group={selectedGroup}
          onAddClick={() => {
            gotoComponent({
              id: "group-field-editor",
              name: "group-field-editor",
            });
          }}
          onEditClick={(param) => {
            setSelectedParam(param);
            gotoComponent({
              id: param.slug,
              name: "group-field-editor",
            });
          }}
          onCancelClick={() => {
            setSelectedGroup(undefined);
            goBack();
          }}
        />
      ),
      "group-field-editor": (
        <GroupFieldEditor
          param={selectedParam}
          group={selectedGroup}
          onCancelClick={() => {
            setSelectedParam(undefined);
            goBack();
          }}
        />
      ),
    }),
    [guiSlug, selectedGroup, selectedParam]
  );

  return (
    <DrawerStyled
      anchor="right"
      onClose={handleClose}
      title="Filter Designer"
      ModalProps={{
        keepMounted: false,
      }}
      keepMounted={false}
      variant="temporary"
      {...drawerProps}
    >
      <DrawerContent>
        <Scrollbar>
          <Stack gap={2} alignItems="flex-start" p={2}>
            <IconButton onClick={handleClose} sx={{ p: 0 }}>
              <CloseOutlined fontSize="small" />
            </IconButton>
            <AnimationLayout
              config={{
                getComponents,
                initialComponent: "main",
              }}
            />
          </Stack>
        </Scrollbar>
      </DrawerContent>
    </DrawerStyled>
  );
};

export default GuiFilters;
