import { Settings } from "@mui/icons-material";
import { Box, DrawerProps, styled } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import AddAssociatedField from "components/AddAssociatedField";
import FusionActionEditor, {
  FusionActionForm,
} from "components/FusionAction/FusionActionEditor";
import GfDrawer from "components/GfDrawer";
import Scrollbar from "components/Scrollbar";
import Spin from "components/Spin";
import { GuiType } from "enums/gui";
import useGuiTabEditorController from "hooks/useGuiTabEditorController";
import AnimationLayout, {
  AnimationLayoutRef,
  Config,
} from "layouts/AnimationLayout";
import GuiModel from "models/Gui";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import useDeleteItem from "queries/useDeleteItem";
import useUpdateItem from "queries/useUpdateItem";
import React, { RefObject, useCallback, useState } from "react";
import { v4 } from "uuid";
import FieldsSelection from "views/app-view/gui-dataset/components/FieldsSearchSelection";
import RecordFilterRules from "views/app-view/gui-dataset/components/RecordFilterRules";
import AddStatusToInclude, {
  AddStatusFormType,
} from "views/app-view/gui-workspace/components/TabEditorDrawer/AddStatusToInclude";
import FilterRuleEditor, {
  AddFilterRuleFormType,
} from "views/app-view/gui-workspace/components/TabEditorDrawer/FilterRuleEditor";
import TabEditorFields from "views/app-view/gui-workspace/components/TabEditorDrawer/TabEditorFields";

const DrawerBox = styled(GfDrawer)(({ theme }) => ({
  ".MuiDrawer-paper ": {
    ".MuiPaper-root ": {
      background: `${theme.palette.background.GFTopNav} !important`,
      boxShadow: "none",

      ".MuiCard-root": {
        background: `${theme.palette.background.GFRightNavForeground} !important`,
        transition: "all 0.4s ease",

        "&:hover": {
          background: `${theme.palette.background.GF20} !important`,
        },
      },
    },

    ".drawer-head": {
      background: theme.palette.background.GFTopNav,
      padding: "15px 20px",
      borderBottom: `1px solid ${theme.palette.other?.divider}`,

      ".drawer-icon-holder": {
        width: "24px",
        height: "24px",
        background: "none",
      },

      ".drawer-title-holder": {
        padding: "0 0 0 11px",
      },
    },

    ".draggable-handle": {
      padding: "0",
    },
  },
}));

const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 60px)`,
    overflow: "hidden",
  };
});

type TabEditDrawerProps = {
  tab?: GuiTab;
  gui?: GfGui;
  onSubmit(tab: Partial<GuiTab>): Promise<void>;
  onEditDatalistSettings(): void;
  guiTabEditorRef: RefObject<AnimationLayoutRef>;
} & Omit<DrawerProps, "onSubmit">;

const TabEditDrawer: React.FC<TabEditDrawerProps> = (props) => {
  const {
    tab,
    gui,
    open,
    onClose,
    onSubmit,
    onEditDatalistSettings,
    guiTabEditorRef,
  } = props;
  const queryClient = useQueryClient();
  const [selectedField, setSelectedField] =
    useState<DataField & { db_slug: string }>();
  const [selectedStatus, setSelectedStatus] = useState<WorkflowStatus>();
  const [selectedFilterRule, setSelectedFilterRule] = useState<FilterRule>();
  const { selectedFusionAction, setSelectedFusionAction } =
    useGuiTabEditorController();

  const { mutateAsync: deleteFormDesign, isLoading: isDeletingFormDesign } =
    useDeleteItem({
      modelName: ApiModels.FormDesign,
    });
  const { mutateAsync: updateFormDesign, isLoading: isUpdatingFormDesign } =
    useUpdateItem({
      modelName: ApiModels.FormDesign,
    });

  const { mutateAsync: createFormDesign, isLoading: isCreatingFormDesign } =
    useCreateItem({
      modelName: ApiModels.FormDesign,
    });

  const onUpdateTabStatus = useCallback(
    (status: AddStatusFormType) => {
      if (tab?.tab_type === "workflow_board") {
        if (!selectedStatus) {
          onSubmit({
            ...tab,
            statuses_to_include: [
              ...(tab.statuses_to_include || []),
              { ...status, id: v4() },
            ],
          });
        } else {
          onSubmit({
            ...tab,
            statuses_to_include:
              tab.statuses_to_include?.map((s) =>
                s.id === selectedStatus.id ? { ...s, ...status } : s
              ) || [],
          });
          setSelectedStatus(undefined);
        }
      }
    },
    [onSubmit, selectedStatus, tab]
  );

  const onUpdateTabFilterRule = useCallback(
    (rule: AddFilterRuleFormType) => {
      if (tab?.tab_type === "workflow_board") {
        if (!selectedFilterRule) {
          onSubmit({
            ...tab,
            filter_rules: [...(tab.filter_rules || []), { ...rule, id: v4() }],
          });
        } else {
          onSubmit({
            ...tab,
            filter_rules:
              tab.filter_rules?.map((r) =>
                r.id === selectedFilterRule.id ? { ...r, ...rule } : r
              ) || [],
          });
          setSelectedStatus(undefined);
        }
      }
    },
    [onSubmit, selectedFilterRule, tab]
  );

  const handleFusionActionSubmit = useCallback(
    async (values: FusionActionForm) => {
      return new Promise<void>((resolve, reject) => {
        if (tab?.tab_type === GuiType.Reviewer) {
          if (!selectedFusionAction) {
            if (gui?.slug) {
              GuiModel.createReviewerAction(gui?.slug, {
                action_title: values.action_title,
                action_icon: values.action_icon,
                action_type: values.action_type,
                action_pinned: values.action_pinned,
                action_hotkey: values.action_hotkey,
                action_starter_form_dataset: values.action_starter_form_dataset,
                tab_slug: tab.slug,
              })
                .then(() => {
                  queryClient.refetchQueries([ApiModels.GuiTab, gui.slug]);
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            } else {
              reject();
            }
          } else {
            onSubmit({
              ...tab,
              associated_actions:
                tab.associated_actions?.map((action) =>
                  action.id === selectedFusionAction.id
                    ? { ...action, ...values }
                    : action
                ) || [],
            })
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          }
        } else if (tab?.tab_type === GuiType.RecordList) {
          if (!selectedFusionAction) {
            if (gui?.slug) {
              GuiModel.createDatasetAction(gui?.slug, {
                action_title: values.action_title,
                action_icon: values.action_icon,
                action_type: values.action_type,
                action_pinned: values.action_pinned,
                action_hotkey: values.action_hotkey,
                action_starter_form_dataset: values.action_starter_form_dataset,
                tab_slug: tab.slug,
              })
                .then(() => {
                  queryClient.refetchQueries({
                    queryKey: [ApiModels.GuiTab, gui.slug],
                  });
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            } else {
              reject();
            }
          } else {
            onSubmit({
              ...tab,
              associated_actions:
                tab.associated_actions?.map((action) =>
                  action.id === selectedFusionAction.id
                    ? { ...action, ...values }
                    : action
                ) || [],
            })
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          }
        }
      }).then(() => {
        queryClient.refetchQueries({
          queryKey: [ApiModels.GuiTab, tab?.gui_slug],
        });
      });
    },
    [gui?.slug, onSubmit, queryClient, selectedFusionAction, tab]
  );

  const handleFieldDelete = useCallback(
    async (slug: string) => {
      await deleteFormDesign(
        { slug },
        {
          onSuccess() {
            queryClient.refetchQueries({
              queryKey: [ApiModels.FormDesign, selectedFusionAction?.id],
            });
          },
        }
      );
    },
    [deleteFormDesign, queryClient, selectedFusionAction?.id]
  );

  const handleFieldUpdate = useCallback(
    async (updates: Partial<DataField>) => {
      if (!selectedField) {
        return;
      }

      const { title, default_value, tooltip, id, slug, ...rest } = updates;

      const metadata = rest as Record<string, unknown>;

      if (metadata.db_slug) {
        delete metadata.db_slug;
      }
      if (metadata.field_slug) {
        delete metadata.field_slug;
      }

      await updateFormDesign(
        {
          slug: selectedField?.db_slug,
          data: {
            title,
            default_value,
            tooltip,
            metadata,
          },
        },
        {
          onSuccess() {
            queryClient.refetchQueries({
              queryKey: [ApiModels.FormDesign, selectedFusionAction?.id],
            });
          },
        }
      );
    },
    [selectedField, updateFormDesign, queryClient, selectedFusionAction?.id]
  );

  const handleFieldCreate = useCallback(
    async (updates: Partial<DataField>) => {
      const { title, default_value, tooltip, type, id, slug, ...rest } =
        updates;

      const metadata = rest as Record<string, unknown>;

      if (metadata.db_slug) {
        delete metadata.db_slug;
      }
      if (metadata.field_slug) {
        delete metadata.field_slug;
      }

      await createFormDesign(
        {
          field_slug: slug,
          title,
          type,
          default_value,
          tooltip,
          metadata,
          action_slug: selectedFusionAction?.id,
        },
        {
          onSuccess() {
            queryClient.refetchQueries({
              queryKey: [ApiModels.FormDesign, selectedFusionAction?.id],
            });
          },
        }
      );
    },
    [createFormDesign, selectedFusionAction?.id, queryClient]
  );

  const getComponents: Config["getComponents"] = useCallback(
    (gotoComponent, goBack) => {
      return {
        main: (
          <TabEditorFields
            tab={tab!}
            viewFilters={tab?.filter_settings?.view_filters?.[tab!.slug] ?? []}
            onClose={() => onClose?.({}, "backdropClick")}
            onSubmit={(values) => {
              onSubmit(values);
              onClose?.({}, "backdropClick");
            }}
            onEditDatalistSettings={onEditDatalistSettings}
            onAddStatusClick={() => {
              gotoComponent({
                id: "status-to-include-editor",
                name: "status-to-include-editor",
              });
            }}
            onEditStatusClick={(item) => {
              setSelectedStatus(item);
              gotoComponent({
                id: item.id,
                name: "status-to-include-editor",
              });
            }}
            onAddFilterRuleClick={() => {
              gotoComponent({
                id: "filter-rule-editor",
                name: "filter-rule-editor",
              });
            }}
            onEditFilterRuleClick={(item) => {
              setSelectedFilterRule(item);
              gotoComponent({
                id: item.id,
                name: "filter-rule-editor",
              });
            }}
            onEditFieldToInclude={() => {
              gotoComponent({
                name: "field-selection-form",
                id: "field-selection-form",
              });
            }}
            onAddActionClick={() => {
              setSelectedFusionAction(undefined);
              gotoComponent({
                name: "reviewer-action-editor",
                id: "reviewer-action-editor",
              });
            }}
            onEditActionClick={(action) => {
              setSelectedFusionAction(action);
              gotoComponent({
                id: action.id,
                name: "reviewer-action-editor",
              });
            }}
            onEditFilters={() => {
              gotoComponent({
                name: "rules-editor",
                id: "rules-editor",
              });
            }}
          />
        ),
        "status-to-include-editor": (
          <AddStatusToInclude
            datasetDesignSlug={
              tab?.tab_type === "workflow_board" ? tab?.dataset_design_slug : ""
            }
            statusFieldSlug={
              tab?.tab_type === "workflow_board" ? tab?.status_field : ""
            }
            onBackClick={() => {
              goBack();
              setSelectedStatus(undefined);
            }}
            status={selectedStatus}
            onSubmit={(values) => {
              goBack();
              onUpdateTabStatus(values);
            }}
          />
        ),
        "filter-rule-editor": (
          <FilterRuleEditor
            onBackClick={() => {
              goBack();
              setSelectedFilterRule(undefined);
            }}
            rule={selectedFilterRule}
            onSubmit={(values) => {
              goBack();
              onUpdateTabFilterRule(values);
            }}
          />
        ),
        "field-selection-form": gui ? (
          <Box sx={{ p: 2 }}>
            <FieldsSelection
              gui={gui}
              indexKey="form_fields"
              onClickBack={() => goBack()}
            />
          </Box>
        ) : (
          <></>
        ),
        "reviewer-action-editor": (
          <FusionActionEditor
            action={selectedFusionAction}
            onSubmit={async (values) => {
              await handleFusionActionSubmit(values).then(() => {
                goBack();
                setSelectedFusionAction(undefined);
              });
            }}
            onCancel={() => {
              goBack();
              setSelectedFusionAction(undefined);
            }}
            onAddField={() => {
              gotoComponent({
                name: "add-action-field-form",
                id: "add-action-field-form",
              });
            }}
            onEditField={(field) => {
              setSelectedField({
                ...field,
                slug: field.field_slug,
                db_slug: field.slug,
                ...field.metadata,
              });
              gotoComponent({
                name: "add-action-field-form",
                id: field.slug,
              });
            }}
            onDeleteField={handleFieldDelete}
          />
        ),
        "add-action-field-form": (
          <AddAssociatedField
            onBackClick={() => {
              goBack();
              setSelectedField(undefined);
            }}
            onSubmit={async (data) => {
              if (selectedField) {
                await handleFieldUpdate(data);
              } else {
                await handleFieldCreate(data);
              }

              goBack();
            }}
            dataField={selectedField}
            allowDefault
          />
        ),
        "rules-editor": (
          <Box sx={{ p: 2 }}>
            <RecordFilterRules
              gui={gui!}
              guiTab={tab!}
              onClickBack={() => goBack()}
              dynamicRHS
            />
          </Box>
        ),
      };
    },
    [
      tab,
      onEditDatalistSettings,
      selectedStatus,
      selectedFilterRule,
      gui,
      selectedFusionAction,
      handleFieldDelete,
      selectedField,
      onClose,
      onSubmit,
      setSelectedFusionAction,
      onUpdateTabStatus,
      onUpdateTabFilterRule,
      handleFusionActionSubmit,
      handleFieldUpdate,
      handleFieldCreate,
    ]
  );

  return (
    <DrawerBox
      anchor={"right"}
      open={open}
      width="420px"
      title="Tab Settings"
      icon={<Settings />}
      onClose={onClose}
      closeIcon={false}
    >
      <Spin
        spinning={
          isDeletingFormDesign || isUpdatingFormDesign || isCreatingFormDesign
        }
      >
        <ScrollbarParent>
          <Scrollbar>
            {tab && (
              <AnimationLayout
                urlQueryKey="s"
                ref={guiTabEditorRef}
                enableScrollbar={false}
                config={{
                  initialComponent: "main",
                  getComponents: getComponents,
                }}
              />
            )}
          </Scrollbar>
        </ScrollbarParent>
      </Spin>
    </DrawerBox>
  );
};

export default TabEditDrawer;
