import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  SubTitle,
  Title,
  Tooltip,
} from "chart.js";
import { DASHBOARD_WIDGET_TYPE } from "constants/gui";
import { FC, memo } from "react";
import useSystemLayoutStore from "store";
import BarChartWidget from "./BarChartWidget";
import DoughnutChartWidget from "./DoughnutChartWidget";
import LineChartWidget from "./LineChartWidget";
import WidgetWrapper from "./WidgetWrapper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  SubTitle,
  Tooltip,
  Legend
);

type ChartWidgetProps = {
  type: "bar" | "line" | "pie";
  widget: DashboardWidget<"bar" | "line" | "pie">;
  data?: Record<string, unknown>;
  onDelete(): void;
};

const Chart: FC<ChartWidgetProps> = (props) => {
  const { type, data } = props;

  switch (type) {
    case DASHBOARD_WIDGET_TYPE.BAR:
      return <BarChartWidget data={data} />;
    case DASHBOARD_WIDGET_TYPE.LINE:
      return <LineChartWidget data={data} />;
    case DASHBOARD_WIDGET_TYPE.PIE:
      return <DoughnutChartWidget data={data} />;

    default:
      return <></>;
  }
};

const ChartWidget: FC<ChartWidgetProps> = memo(
  (props) => {
    const activeTheme = useSystemLayoutStore.useActiveTheme();
    return (
      <WidgetWrapper
        sx={{
          height: "calc(100% - 40px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        widget={props.widget}
        onDelete={props.onDelete}
      >
        <Chart key={`${activeTheme}-${props.widget.slug}`} {...props} />
      </WidgetWrapper>
    );
  },
  (prev, next) => prev.data === next.data && prev.widget === next.widget
);

export default ChartWidget;
