import {
  Collapse,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  IconButton,
  Stack,
} from "@mui/material";
import LabelArrowIcon from "assets/icons/LabelArrowIcon";
import FieldRenderer from "components/FormField/FieldRenderer";
import { DocumentElementType, FormFieldType } from "enums/Form";
import { FC, ReactNode, useState } from "react";
import {
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
} from "react-hook-form";

export type FormFieldProps = {
  label?: string;
  labelProps?: FormLabelProps;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<{}>>;
  type?: DocumentElementType | FormFieldType;
  fieldExtras?: {
    field: Partial<DataField>;
    [key: string]: unknown;
  };
  value?: unknown;
  extra?: ReactNode;
  collapsible?: boolean;
  formControl?: {
    control: Control<any>;
    name: string;
  };
  children?:
    | ReactNode
    | ((params: {
        value?: unknown;
        onChange?(value: unknown): void;
      }) => ReactNode);
  onChange?(value: unknown): void;
} & Omit<FormControlProps, "error" | "children">;

const FormFieldContent: FC<FormFieldProps> = (props) => {
  const {
    label,
    labelProps,
    error,
    children,
    type = DocumentElementType.TextField,
    fieldExtras,
    value,
    onChange,
    extra,
    collapsible = false,
    formControl,
    ...formControlProps
  } = props;

  const [collapsed, setCollapsed] = useState(false);

  return (
    <FormControl fullWidth error={!!error} {...formControlProps}>
      <Collapse in={!collapsed} collapsedSize={20}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="form-field-header"
          mb={1}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            {collapsible && (
              <IconButton
                sx={{ p: 0 }}
                onClick={() => setCollapsed((prev) => !prev)}
                className="btn-collapse"
              >
                <LabelArrowIcon
                  sx={{
                    fontSize: 16,
                    transform: collapsed ? "rotate(180deg)" : undefined,
                    transition: "transform",
                  }}
                />
              </IconButton>
            )}
            <FormLabel
              {...labelProps}
              sx={{
                fontWeight: "200",
                fontSize: "14px",
                ...(labelProps?.sx ?? {}),
              }}
            >
              {label}
            </FormLabel>
          </Stack>
          {extra}
        </Stack>
        {children ? (
          typeof children === "function" ? (
            children({ value, onChange })
          ) : (
            children
          )
        ) : (
          <FieldRenderer
            type={type}
            value={value}
            onChange={onChange}
            fieldExtras={fieldExtras}
          />
        )}
        {!!error && <FormHelperText>{error?.message}</FormHelperText>}
      </Collapse>
    </FormControl>
  );
};

const FormField: FC<FormFieldProps> = (props) => {
  const { formControl } = props;

  if (formControl) {
    return (
      <Controller
        name={formControl.name}
        control={formControl.control}
        render={({ field }) => {
          return (
            <FormFieldContent
              {...props}
              value={field.value}
              onChange={field.onChange}
            />
          );
        }}
      />
    );
  }

  return <FormFieldContent {...props} />;
};

export default FormField;
