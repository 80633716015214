import { Box, styled } from "@mui/material";
import Scrollbar from "components/Scrollbar";
import { SOCKET_URL } from "configs/AppConfig";
import { SocketState } from "enums";
import useSocket from "hooks/useSocket";
import SystemAppBar from "module/Appbar";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSocketStore } from "store/stores/socket";
import { useSystemLayoutStore } from "store/stores/systemLayout";

const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 60px)`,
    overflow: "hidden",
    marginTop: "60px",
  };
});

const AppLayout = () => {
  const { connect, disconnect } = useSocket();
  const socketState = useSocketStore.useState();

  const appBarProps = useSystemLayoutStore.useAppBarProps();

  useEffect(() => {
    if (socketState !== SocketState.Open) {
      connect(SOCKET_URL!);
      return () => {
        disconnect();
      };
    }
  }, []);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {/* <SideNav /> */}
        <Box sx={{ flexGrow: 1 }}>
          <SystemAppBar
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            {...appBarProps}
          />
          <ScrollbarParent>
            <Scrollbar>
              <Box component="main" sx={{ p: 4 }}>
                <Outlet />
              </Box>
            </Scrollbar>
          </ScrollbarParent>
        </Box>
      </Box>
    </>
  );
};

export default AppLayout;
