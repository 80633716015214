import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBack } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import DatasetDesignPicker from "components/DatasetDesignPicker";
import FieldListItem from "components/FieldListItem";
import FormField from "components/FormField";
import FusionLinkButton from "components/FusionLinkButton";
import IconPickerField from "components/IconPicker";
import SidebarSection from "components/RightSidebar";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import ShortcutSelector from "components/ShortcutSelector";
import Spin from "components/Spin";
import { AllIconPickerIcons } from "constants/index";
import { ActionType } from "enums/gui";
import useActionFields from "queries/formDesign/useActionFields";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

const ACTION_TYPE_OPTIONS = [
  {
    label: "Run Fusion",
    value: ActionType.RunFusion,
  },
  {
    label: "Open Form",
    value: ActionType.OpenForm,
  },
];

type ActionFieldsProps = {
  fields: FormDesign[];
  onAdd?(): void;
  onEdit?(formDesign: FormDesign): void;
  onDelete?(slug: string): void;
};

const ActionFields: FC<ActionFieldsProps> = (props) => {
  const { fields, onAdd, onEdit, onDelete } = props;

  return (
    <SidebarSectionWrap title="Associated Fields" onRightIconClick={onAdd}>
      <Stack gap={1}>
        {fields.map((field) => (
          <FieldListItem
            title={field.title}
            subtitle={field.field_slug}
            type={field.type}
            onClick={() => onEdit?.(field)}
            onDeleteClick={() => onDelete?.(field.slug)}
          />
        ))}
      </Stack>
    </SidebarSectionWrap>
  );
};

type FusionActionEditorProps = {
  action?: FusionAction;
  onCancel(): void;
  onSubmit(action: FusionActionForm): Promise<void>;
  onAddField?(): void;
  onEditField?(formDesign: FormDesign): void;
  onDeleteField?(slug: string): void;
};

const formSchema = z.object({
  action_title: z.string(),
  action_type: z
    .enum([ActionType.OpenForm, ActionType.RunFusion])
    .default(ActionType.RunFusion),
  action_icon: z.string().optional(),
  action_pinned: z.boolean().optional().default(true),
  action_hotkey: z.string().optional(),
  action_starter_form_dataset: z.string().default("none"),
});

export type FusionActionForm = z.infer<typeof formSchema>;

const FusionActionEditor: FC<FusionActionEditorProps> = (props) => {
  const { action, onCancel, onAddField, onDeleteField, onEditField } = props;

  const [loading, setLoading] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
    watch,
  } = useForm<FusionActionForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      action_type: ActionType.RunFusion,
      action_starter_form_dataset: "none",
      action_pinned: false,
    },
  });

  const actionType = watch("action_type");
  const isEditMode = !!action;

  const { data: actionFields = [], isFetching: isActionFieldsLoading } =
    useActionFields(action?.id);

  useEffect(() => {
    if (action) {
      reset({
        action_title: action.action_title,
        action_icon: action.action_icon,
        action_type: action.action_type as ActionType,
        action_pinned: action.action_pinned,
        action_hotkey: action.action_hotkey,
        action_starter_form_dataset: action.action_starter_form_dataset,
      });
    }
  }, [action, reset]);

  const onSubmit = async (data: FusionActionForm) => {
    setLoading(true);
    try {
      await props.onSubmit(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ px: 2 }}>
      <form
        onSubmit={handleSubmit(onSubmit, (errors) => {
          console.log("🚀 ~ <formonSubmit={handleSubmit ~ errors:", errors);
        })}
      >
        <SidebarSection
          title="Add Action"
          leftIcon={<ArrowBack />}
          rightIcon={false}
          onLeftIconClick={onCancel}
        >
          <FormField
            label="Action Title"
            error={dirtyFields.action_title ? errors.action_title : undefined}
          >
            <TextField
              {...register("action_title")}
              autoFocus
              margin="dense"
              id="action_title"
              type="text"
              hiddenLabel
              size="small"
              variant="filled"
              fullWidth
            />
          </FormField>
          <FormField
            label="Action Icon"
            error={dirtyFields.action_icon ? errors.action_icon : undefined}
          >
            <Controller
              name="action_icon"
              control={control}
              render={({ field }) => (
                <IconPickerField
                  {...field}
                  icons={AllIconPickerIcons}
                  compact={true}
                  placement="left"
                />
              )}
            />
          </FormField>
          <FormField
            label="Pinned"
            error={dirtyFields.action_pinned ? errors.action_pinned : undefined}
          >
            <Controller
              control={control}
              name="action_pinned"
              render={({ field }) => {
                return (
                  <Checkbox
                    {...field}
                    checked={field.value ?? false}
                    onChange={(_, checked) => field.onChange(checked)}
                    sx={{
                      width: "fit-content",
                    }}
                  />
                );
              }}
            />
          </FormField>
          <FormField
            label="Action Hotkey"
            error={dirtyFields.action_hotkey ? errors.action_hotkey : undefined}
          >
            <Controller
              control={control}
              name="action_hotkey"
              render={({ field }) => {
                return (
                  <ShortcutSelector
                    value={field.value}
                    onSelect={(key) => field.onChange(key)}
                  />
                );
              }}
            />
          </FormField>
          {action?.fusion_slug && (
            <Box sx={{ mt: 2 }}>
              <FusionLinkButton
                title="Edit Fusion Flow"
                fusionSlug={action.fusion_slug}
              />
            </Box>
          )}
          <FormField
            label="Action Type"
            error={dirtyFields.action_type ? errors.action_type : undefined}
          >
            <Controller
              name="action_type"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    margin="dense"
                    id="action_type"
                    fullWidth
                    select
                    hiddenLabel
                    size="small"
                    variant="filled"
                    disabled={isEditMode}
                  >
                    {ACTION_TYPE_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                );
              }}
            />
          </FormField>
          {actionType === ActionType.OpenForm && (
            <FormField
              label="Base Starter Form On"
              error={
                dirtyFields.action_starter_form_dataset
                  ? errors.action_starter_form_dataset
                  : undefined
              }
            >
              <Controller
                control={control}
                name="action_starter_form_dataset"
                render={({ field }) => (
                  <DatasetDesignPicker
                    value={field.value}
                    onChange={field.onChange}
                    disabled={isEditMode}
                    allowNoneSelection
                  />
                )}
              />
            </FormField>
          )}
        </SidebarSection>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1.5}
          sx={{ mt: 2 }}
        >
          <Button
            variant="outlined"
            color="inherit"
            sx={{
              border: "none",
              color: "#fff",
              background: (theme) => theme.palette.background.GF10,
            }}
            onClick={onCancel}
            fullWidth
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{
              borderColor: "#fff",
              boxShadow: "none",
            }}
            fullWidth
            loading={loading}
          >
            Save Changes
          </LoadingButton>
        </Stack>
        {isEditMode && (
          <Spin spinning={isActionFieldsLoading}>
            <ActionFields
              fields={actionFields}
              onAdd={onAddField}
              onDelete={onDeleteField}
              onEdit={onEditField}
            />
          </Spin>
        )}
      </form>
    </Box>
  );
};

export default FusionActionEditor;
