import { Box, Stack, styled } from "@mui/material";
import { GuiType } from "enums/gui";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import { FC } from "react";
import AddPageModal from "./AddPageModal";
import DocEditor from "./DocEditor";
import DocNavigation from "./DocNavigation";

const Container = styled(Box)({
  height: "calc(100vh - 60px)",
});

type GuiDocumentProps = {
  guiTab?: GuiTab;
};

const GuiDocument: FC<GuiDocumentProps> = (props) => {
  const { guiTab } = props;

  const { data: guiDocument, refetch } = useGetItem({
    modelName: ApiModels.GuiDocument,
    slug:
      guiTab?.tab_type === GuiType.GuiDoc ? guiTab.document_slug : undefined,
  });

  return (
    <Container>
      <Stack direction="row" sx={{ height: "100%" }}>
        <DocNavigation guiDocument={guiDocument} />
        <DocEditor guiTab={guiTab} guiDocument={guiDocument} />
      </Stack>
      <AddPageModal guiDocument={guiDocument} onPageCreated={() => refetch()} />
    </Container>
  );
};

export default GuiDocument;
