import { useTheme } from "@mui/material";
import { AgChartOptions, AgDonutSeriesOptions } from "ag-charts-community";
import { AgCharts } from "ag-charts-react";
import { getDefaultChartOptions } from "helpers/gui";
import isEmpty from "lodash/isEmpty";
import { FC, useEffect, useState } from "react";

type DoughnutChartWidgetProps = {
  data?: Record<string, unknown>;
};

const DoughnutChartWidget: FC<DoughnutChartWidgetProps> = (props) => {
  const { data: widgetData } = props;

  const theme = useTheme();

  const {
    title: chartTitle,
    subtitle: chartSubtitle,
    axis,
    ...defaultChartOptions
  } = getDefaultChartOptions(theme);

  const [chartOptions, setChartOptions] =
    useState<AgChartOptions>(defaultChartOptions);

  useEffect(() => {
    if (!isEmpty(widgetData)) {
      setChartOptions({
        title: {
          text: widgetData.title as string,
          ...chartTitle,
        },
        subtitle: {
          text: widgetData.subtitle as string,
          ...chartSubtitle,
        },
        data: (widgetData.data as Array<Record<string, unknown>>) ?? [],
        series:
          (widgetData.series as Omit<AgDonutSeriesOptions, "type">[])?.map(
            (s, index) => {
              return {
                type: "donut",
                ...s,
                innerRadiusRatio: Number(s.innerRadiusRatio),
                outerRadiusRatio: Number(s.outerRadiusRatio),
                // showInLegend: index === 0,
                title: {
                  text: s.title as string,
                  showInLegend: true,
                },
              };
            }
          ) ?? [],
        ...defaultChartOptions,
      });
    }
  }, [widgetData]);

  return (
    <AgCharts
      style={{ height: "100%", width: "100%" }}
      options={chartOptions}
    />
  );
};

export default DoughnutChartWidget;
