import TextField from "@mui/material/TextField";
import { FC, KeyboardEventHandler, useEffect, useState } from "react";

type ShortcutSelectorProps = {
  value?: string;
  onSelect?(hotkey: string): void;
};

const ShortcutSelector: FC<ShortcutSelectorProps> = (props) => {
  const { value = "", onSelect } = props;

  const [shortcut, setShortcut] = useState(value);

  useEffect(() => {
    setShortcut(value);
  }, [value]);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    // Prevent default to stop any browser shortcuts
    event.preventDefault();
    event.stopPropagation();

    // Construct shortcut string (e.g., "Ctrl+Shift+Z")
    let keys = [];
    if (event.ctrlKey) keys.push("ctrl");
    if (event.shiftKey) keys.push("shift");
    if (event.altKey) keys.push("alt");
    keys.push(event.key);
    const shortcutStr = keys.join("+");

    setShortcut(shortcutStr);
    onSelect?.(shortcutStr);
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      value={shortcut}
      onKeyDown={handleKeyDown}
      placeholder="Focus here and press any keyboard shortcut"
    />
  );
};

export default ShortcutSelector;
