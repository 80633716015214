import { UseDraggableArguments, useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import React, { PropsWithChildren } from "react";
import { cloneElement } from "utils";

type DraggableProps = {
  id: string;
  dataId?: string;
  draggableOptions?: UseDraggableArguments;
  handle?: boolean;
  [key: string]: any;
};

const Draggable: React.FC<PropsWithChildren<DraggableProps>> = (props) => {
  const {
    id,
    children,
    dataId,
    draggableOptions = {},
    handle,
    ...rest
  } = props;

  const { attributes, listeners, setNodeRef, isDragging, transform } =
    useDraggable({
      id,
      data: { id: dataId ?? id },
      ...draggableOptions,
    });

  return cloneElement(children, {
    ...rest,
    ref: setNodeRef,
    style: {
      opacity: isDragging ? 0.5 : 1,
      ...rest.style,
      transform: CSS.Translate.toString(transform),
      zIndex: isDragging ? 100 : 0,
    },
    ...attributes,
    ...(handle ? {} : listeners),
  });
};

export default Draggable;
