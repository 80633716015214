import Model from "models";

class JobTaskLogModel extends Model<JobTaskLog> {
  constructor() {
    super("/job-task-log", "public-1");
  }
}

const model = new JobTaskLogModel();
export default model;
