export const parseValueForCodeEditor = (value: any, checkParse = true) => {
  if (typeof value !== "string") {
    try {
      return JSON.stringify(value, null, 2);
    } catch (e) {
      return "{}";
    }
  } else if (checkParse) {
    try {
      JSON.parse(value);
      return value;
    } catch (e) {
      return "{}";
    }
  }

  return value;
};
