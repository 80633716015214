import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

import { InfoOutlined, StorageOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { User } from "assets/icons";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import Scrollbar from "components/Scrollbar";
import Spin from "components/Spin";
import dayjs from "dayjs";
import useOpenClose from "hooks/useOpenClose";
import AnimationLayout, {
  AnimationLayoutRef,
  Config,
} from "layouts/AnimationLayout";
import { isArray } from "lodash";
import Account from "models/Account";
import AccountMetadata from "models/AccountMetadata";
import Card from "models/Card";
import { queryClient } from "queries";
import { ApiModels } from "queries/apiModelMapping";
import useDeleteItem from "queries/useDeleteItem";
import useListItems from "queries/useListItems";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useStore } from "store";
import InfoList from "stories/CompoundComponent/AccountInfoCard/AccountInfoCard/AccountInfoCard";
import Swal from "sweetalert2";
import { getSearchParams } from "utils";
import { confirmDelete } from "utils/confirmDelete";
import AddCard from "./AddCard";
import DetailDatasetUsage from "./DetailDatasetUsage";
import RequestCreditModal from "./RequestCreditModal";

const getInitialComponent = () => {
  return getSearchParams().get("s_name") || "main";
};

type Props = {};
const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 60px)`,
    overflow: "hidden",

    ".side-menu-active &": {
      height: "100vh",
    },
  };
});
const RightBarWrapper = styled(Stack)(({ theme }) => ({
  padding: "23px 20px",
  gap: "24px",
}));

const InfoListWrapper = styled(InfoList)(({ theme }) => ({
  padding: "13px 25px 24px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",

  "&:hover": {
    background: theme.palette.background.GF10,
  },

  ".MuiStack-root ": {
    padding: "0",
    width: "100%",
  },

  ".MuiTypography-subtitle1": {
    fontSize: "18px",
    lineHeight: "40px",
  },

  ".MuiList-root": {
    padding: "0",
    display: "flex",
    flexDirection: "column",
    gap: "13px",
  },

  ".MuiListItem-root": {
    padding: "0",
    color: theme.palette.text.secondary,

    ".MuiTypography-body2": {
      fontSize: "15px",
      lineHeight: "18px",
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
    },

    ".text-primary": {
      color: theme.palette.text.primary,
      margin: "0 0 0 5px",
    },

    h6: {
      lineHeight: "1",
      width: "20px",
      height: "20px",

      svg: {
        maxWidth: "20px",
        height: "auto",
      },
    },

    ".MuiStack-root": {
      alignItems: "center",
    },

    ".price": {
      display: "inline-block",
      verticalAlign: "middle",
      fontSize: "18px",
      lineHeight: "1",
      padding: "2px 9px",
      color: theme.palette.text.primary,
      background: theme.palette.primary.main,
      borderRadius: "6px",
      margin: "0 0 0 9px",
    },
    ".center-align": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "6px",
    },
    ".size": {
      display: "flex",
      alignItems: "center",
    },
    ".MuiButtonBase-root:not(.btn-text)": {
      display: "block",
      width: "100%",
      boxShadow: "none",
      borderRadius: "4px",
      margin: "6px 0 0",
      background: theme.palette.background.GF10,
    },

    ".btn-text": {
      padding: "3px 7px",
      margin: "0 0 0 6px",
      fontSize: "13px",
      lineHeight: "17px",
      color: theme.palette.text.primary,
      height: "auto",

      "&:hover": {
        background: "none",
      },
    },
  },
}));

const PaymentBoxWrapper = styled(Box)(({ theme }) => ({
  padding: "13px 25px 24px",
  background: theme.palette.background.GFRightNavForeground,
  borderRadius: "6px",

  ".box-wrapper": {
    gap: "16px",
  },

  ".title": {
    fontSize: "18px",
    lineHeight: "40px",
    fontWeight: "600",
    margin: "0 0 9px",
    display: "block",
  },

  ".heading": {
    fontSize: "14px",
    lineHeight: "18px",
    display: "block",
  },
}));

const PaymentMethodWrapper = styled(Stack)(({ theme }) => ({
  gap: "7px",

  "&.primary": {
    ".heading": {
      color: "#039BE5",
    },
    ".payment-method": {
      background: "rgba(3, 155, 229, 0.40)",
    },
  },
}));

const PaymentMethodFrame = styled(Stack)(({ theme }) => ({
  gap: "16px",
}));
const PaymentMethod = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.GF5,
  borderRadius: "4px",
  padding: "11px 12px 12px 15px",
  gap: "7px",
  fontSize: "15px",
  lineHeight: "22px",

  ".card-holder-name": {
    fontSize: "15px",
    lineHeight: "18px",
  },

  ".card-number ": {
    gap: "8px",
  },

  ".has-space": {
    letterSpacing: "6px",
  },
}));

const CardIcons = {
  "American Express": "american-express.svg",
  "Diners Club": "diners.svg",
  Discover: "discover.svg",
  "Eftpos Australia": "eftpos.svg",
  JCB: "jcb.svg",
  MasterCard: "mastercard.svg",
  UnionPay: "unionpay.svg",
  Visa: "visa.svg",
  Unknown: "credit-card.svg",
};
const RightbarMain = ({
  updateSubscription,
}: {
  updateSubscription: () => void;
}) => {
  const { data: cards } = useListItems({ modelName: ApiModels.Card });
  const [open, onOpen, onClose] = useOpenClose();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedCard, setSelectedCard] = React.useState<Card>();
  const { data: packages } = useListItems({ modelName: ApiModels.Package });
  const [
    openDatasetUsageModal,
    onDatasetUsageModalOpen,
    onDatasetUsageModalClose,
  ] = useOpenClose();
  const { data: accountMetadata, isLoading: accountMetadataLoading } = useQuery(
    {
      queryKey: ["accountMetadata"],
      queryFn: async () => {
        const res = await AccountMetadata.getDatasetSize();
        return res.data;
      },
    }
  );
  const selectedAccount = useStore.useSelectedAccount?.();
  const [selectedCreditType, setSelectedCreditType] =
    useState<PackageCreditSetting>();

  const { data: creditTypes, isLoading: isCreditLoading } = useListItems({
    modelName: ApiModels.CreditType,
    requestOptions: {
      query: {
        package_slug: selectedAccount?.account_package_id,
      },
    },
    queryOptions: {
      enabled: !!selectedAccount?.account_package_id,
    },
    queryKey: [ApiModels.CreditType, selectedAccount?.account_package_id],
  });

  const selectedPackage = useMemo(() => {
    if (selectedAccount?.account_package_id && packages?.length)
      return packages?.find(
        (p) => p.slug === selectedAccount?.account_package_id
      );
  }, [selectedAccount?.account_package_id, packages]);

  const { mutate: deleteCard } = useDeleteItem({
    modelName: ApiModels.Card,
    dataKey: "id",
  });
  const { mutate: makePrimary } = useMutation<void, unknown, { slug: string }>({
    mutationFn: async ({ slug }) => {
      await Card?.makePrimary(slug);
    },
    onSuccess: (_, { slug }) => {
      queryClient.setQueryData(
        [ApiModels.Card],
        (oldData: Card[] | undefined) => {
          if (isArray(oldData)) {
            return oldData.map((item) => {
              if (item.id === slug) {
                return { ...item, primary: true };
              }
              return { ...item, primary: false };
            });
          }
        }
      );
    },
  });

  const openDropDown = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    card: Card
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedCard(card);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCard(undefined);
  };
  const { primary, others } = useMemo(() => {
    let primary: Card | undefined;
    const others = cards?.filter((card) => {
      if (card.primary) {
        primary = card;
        return false;
      }
      return true;
    });
    return { primary, others };
  }, [cards]);

  const handleDeleteCard = async () => {
    if (selectedCard?.id!) {
      confirmDelete().then(async (result) => {
        if (result.isConfirmed) {
          await deleteCard(
            { slug: selectedCard?.id! },
            {
              onSuccess: () => {
                handleClose();
              },
            }
          );
        }
      });
    }
  };
  const handleMakePrimary = async () => {
    if (selectedCard?.id!) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to make this card primary?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      });
      await makePrimary(
        { slug: selectedCard?.id! },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    }
  };

  const nextRenewalDate = useMemo(() => {
    // Get the current date
    if (!selectedAccount?.created_at) return;
    const currentDate = dayjs();

    // Get the user's sign-up date
    const signUpDate = dayjs(selectedAccount?.created_at); // Replace with the actual sign-up date

    // Calculate the number of months between the current date and the sign-up date
    const monthsToAdd = currentDate.diff(signUpDate, "months") + 1;
    const nextRenewalDate = signUpDate.add(monthsToAdd, "months");
    return nextRenewalDate.format("MMM DD, YYYY");
  }, [selectedAccount?.created_at]);

  return (
    <React.Fragment>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openDropDown}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleMakePrimary}>Make Primary</MenuItem>
        <MenuItem onClick={handleDeleteCard}>Remove</MenuItem>
      </Menu>
      <RightBarWrapper>
        <Spin spinning={isCreditLoading}>
          <InfoListWrapper
            title="Credits"
            data={creditTypes
              ?.map((creditype) => {
                return {
                  icon: <PaidOutlinedIcon />,
                  title: (
                    <Box className="title-holder">
                      {creditype.name}:
                      <span className="price">
                        {creditype.credits_available}
                      </span>
                      <Button
                        className="btn-text"
                        variant={"text"}
                        onClick={() => setSelectedCreditType(creditype)}
                      >
                        + Add Credit
                      </Button>
                    </Box>
                  ),
                };
              })
              ?.concat([
                {
                  icon: <CalendarTodayOutlinedIcon />,
                  title: (
                    <Box className="title-holder">
                      Next credits added:{" "}
                      <span className="text-primary">{nextRenewalDate}</span>
                    </Box>
                  ),
                },
              ])}
          />
        </Spin>
        {selectedPackage && (
          <PackageDetail
            pkg={selectedPackage}
            onClick={() => updateSubscription()}
          />
        )}

        <PaymentBoxWrapper>
          <strong className="title">Cards on File</strong>
          <Stack className="box-wrapper">
            {primary?.last4 && (
              <PaymentMethodWrapper className="primary">
                <span className="heading">Primary</span>
                <PaymentMethodFrame>
                  <PaymentMethod className="payment-method">
                    <Box className="card-holder-name">{primary.name}</Box>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      className="cards-detail"
                    >
                      <Stack
                        className="card-number"
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Box className="card-code has-space">••••</Box>
                        <Box className="card-code has-space">••••</Box>
                        <Box className="card-code has-space">••••</Box>
                        <Box className="card-code">{primary.last4}</Box>
                      </Stack>
                      <Box className="card-image">
                        <img
                          src={`/assets/images/${CardIcons[primary.brand]}`}
                          srcSet={`/assets/images/${CardIcons[primary.brand]}`}
                          loading="lazy"
                          alt={primary.brand}
                          width={35}
                        />
                      </Box>
                    </Stack>
                  </PaymentMethod>
                </PaymentMethodFrame>
              </PaymentMethodWrapper>
            )}
            <PaymentMethodWrapper className="primary">
              <span className="heading">Others</span>
              {others?.map((card) => {
                return (
                  <PaymentMethodFrame position={"relative"}>
                    <PaymentMethod className="payment-method">
                      <Box>
                        <Box className="card-holder-name">{card.name}</Box>
                        <IconButton
                          onClick={(e) => handleClick(e, card)}
                          sx={{ position: "absolute", right: 10, top: 0 }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </Box>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        className="cards-detail"
                      >
                        <Stack
                          className="card-number"
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Box className="card-code has-space">••••</Box>
                          <Box className="card-code has-space">••••</Box>
                          <Box className="card-code has-space">••••</Box>
                          <Box className="card-code">{card.last4}</Box>
                        </Stack>
                        <Box className="card-image">
                          <img
                            src={`/assets/images/${CardIcons[card.brand]}`}
                            srcSet={`/assets/images/${CardIcons[card.brand]}`}
                            loading="lazy"
                            alt={card.brand}
                            width={35}
                          />
                        </Box>
                      </Stack>
                    </PaymentMethod>
                  </PaymentMethodFrame>
                );
              })}
            </PaymentMethodWrapper>
          </Stack>
          <Box textAlign={"center"} mt={2}>
            <Button variant="contained" onClick={onOpen}>
              Add New
            </Button>
          </Box>
        </PaymentBoxWrapper>

        <Spin spinning={accountMetadataLoading}>
          <InfoListWrapper
            title="General Information"
            data={[
              {
                icon: <StorageOutlined />,
                title: (
                  <Box className="title-holder size">
                    Datasets:
                    {accountMetadata?.dataset && (
                      <span className="price center-align">
                        {accountMetadata?.dataset / (1024 * 1024)} MB
                      </span>
                    )}
                    <span
                      className="center-align"
                      onClick={onDatasetUsageModalOpen}
                    >
                      <InfoOutlined />
                    </span>
                  </Box>
                ),
              },
            ]}
          />
        </Spin>
      </RightBarWrapper>

      <AddCard open={open} onClose={onClose} />
      <RequestCreditModal
        open={Boolean(selectedCreditType?.slug)}
        onClose={() => setSelectedCreditType(undefined)}
        creditType={selectedCreditType!}
        packageId={selectedAccount?.account_package_id!}
      />
      <DetailDatasetUsage
        open={openDatasetUsageModal}
        onClose={onDatasetUsageModalClose}
      />
    </React.Fragment>
  );
};

const PackageDetail = ({
  pkg,
  onClick,
  buttonTitle,
  showAvalibleCredit,
}: {
  pkg: Package;
  onClick: (pkg: Package) => any;
  buttonTitle?: string;
  showAvalibleCredit?: boolean;
}) => {
  const { data: creditTypes, isLoading: isCreditLoading } = useListItems({
    modelName: ApiModels.CreditType,
    requestOptions: {
      query: {
        package_slug: pkg?.slug,
      },
    },
    queryOptions: {
      enabled: !!pkg?.slug,
    },
    queryKey: [ApiModels.CreditType, pkg?.slug],
  });
  const { data: seatTypes, isLoading: isSeatLoading } = useListItems({
    modelName: ApiModels.SeatType,
    requestOptions: {
      query: {
        package_slug: pkg.slug,
      },
    },
    queryOptions: {
      enabled: !!pkg.slug,
    },
    queryKey: [ApiModels.SeatType, pkg?.slug],
  });
  const selectedPackageInfo = useMemo(() => {
    if (!pkg?.slug) return [];
    const middleArray: any = [];

    creditTypes?.forEach((creditType) => {
      middleArray.push({
        icon: <PermIdentityIcon />,
        title: (
          <Box className="title-holder">
            {creditType.name}:
            <span className="text-primary">
              {showAvalibleCredit
                ? creditType.credits_available
                : creditType.monthly_qty}
            </span>
          </Box>
        ),
      });
    });
    seatTypes?.forEach((seatType) => {
      middleArray.push({
        icon: <PaidOutlinedIcon />,
        title: (
          <Box className="title-holder">
            {seatType.name}:
            <span className="text-primary">{seatType.monthly_qty}</span>
          </Box>
        ),
      });
    });
    return [
      {
        icon: <User />,
        title: (
          <Box className="title-holder">
            Monthly Quatity:
            <span className="text-primary">{pkg.monthly_qty}</span>
          </Box>
        ),
      },
      ...middleArray,

      {
        title: (
          <Button
            size="small"
            variant="contained"
            onClick={() => onClick?.(pkg)}
          >
            {buttonTitle || "Update Subscription"}
          </Button>
        ),
      },
    ];
  }, [pkg, creditTypes, seatTypes, buttonTitle, onClick]);

  return (
    <Spin spinning={isCreditLoading || isSeatLoading}>
      <InfoListWrapper title={pkg.name} data={selectedPackageInfo} />
    </Spin>
  );
};

const PackageList = ({ goBack }: { goBack: () => void }) => {
  const { data: packages } = useListItems({ modelName: ApiModels.Package });
  const selectedAccount = useStore.useSelectedAccount?.();

  const { mutate: updatePackage, isLoading } = useMutation({
    mutationFn: async (slug: string) => {
      return await Account.changePackage({ account_package_id: slug });
    },
    onSuccess: (_, slug) => {
      useStore.setState((pres) => {
        return {
          ...pres,
          selectedAccount: {
            ...(pres.selectedAccount as AppAccount),
            account_package_id: slug,
          },
        };
      });
    },
  });

  const selectedPackageId = selectedAccount?.account_package_id;
  const handleUpdatePackage = async (pkg: Package) => {
    if (pkg.slug !== selectedAccount?.account_package_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to change you subscription",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await updatePackage(pkg.slug, {
            onSuccess: () => {
              Swal.fire("Changed!", "", "success");
            },
          });
        }
      });
    }
  };
  return (
    <React.Fragment>
      <RightBarWrapper mt={0}>
        <SidebarSectionWrap
          title="Subscriptions"
          rightIcon={false}
          leftIcon={<ArrowBackOutlinedIcon />}
          onLeftIconClick={goBack}
          sx={{ mt: -2 }}
        >
          {packages?.map((p) => (
            <PackageDetail
              pkg={p}
              buttonTitle={
                selectedPackageId === p.slug
                  ? "Current Package"
                  : "Update Package"
              }
              onClick={handleUpdatePackage}
            />
          ))}
        </SidebarSectionWrap>
      </RightBarWrapper>
    </React.Fragment>
  );
};

const Rightbar = (props: Props) => {
  const layoutRef = useRef<AnimationLayoutRef>(null);
  const [initialComponent, setInitialComponent] = useState<string>("");

  const getComponent: Config["getComponents"] = React.useCallback(
    (gotoComponent, goBack) => {
      return {
        main: (
          <RightbarMain
            updateSubscription={() => {
              gotoComponent({ name: "packages", id: "packages" });
            }}
          />
        ),
        packages: <PackageList goBack={goBack} />,
      };
    },
    []
  );
  useEffect(() => {
    setInitialComponent(getInitialComponent());
  }, []);
  return (
    <React.Fragment>
      <ScrollbarParent>
        <Scrollbar>
          {initialComponent && (
            <AnimationLayout
              ref={layoutRef}
              config={{
                getComponents: getComponent,
                initialComponent,
              }}
            />
          )}
        </Scrollbar>
      </ScrollbarParent>
    </React.Fragment>
  );
};
export default Rightbar;
