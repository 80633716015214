import { useDraggable } from "@dnd-kit/core";
import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import { IconButton, Stack, Typography, styled } from "@mui/material";
import ChatWindowCollapse from "assets/icons/ChatWindowCollapse";
import { NO_DRAG_CLASS_NAME } from "constants/index";
import { FC } from "react";
import useGFChatWindow from "store/stores/gf-chat-window";

const HeaderContainer = styled(Stack)<{ isDragging?: boolean }>((props) => ({
  height: "45px",
  background: "#00619A",
  paddingLeft: "16px",
  paddingRight: "16px",
  cursor: props.isDragging ? "grabbing" : "grab",
}));

type HeaderProps = {
  draggableId: string;
  collapsed: boolean;
  onCollapseIconClick(): void;
  onBackClick(): void;
};

const Header: FC<HeaderProps> = (props) => {
  const { draggableId, collapsed, onCollapseIconClick, onBackClick } = props;

  const setIsOpen = useGFChatWindow.useSetIsOpen();
  const activeView = useGFChatWindow.useActiveView();

  const { listeners, isDragging } = useDraggable({
    id: draggableId,
    data: { id: draggableId },
  });

  return (
    <HeaderContainer
      {...listeners}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      isDragging={isDragging}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        {activeView === "chat-list" ? (
          <SettingsOutlined fontSize="small" />
        ) : (
          <IconButton
            size="small"
            className={NO_DRAG_CLASS_NAME}
            onClick={onBackClick}
          >
            <ArrowBackOutlined sx={{ color: "#fff" }} fontSize="small" />
          </IconButton>
        )}
        <Typography>
          {activeView === "chat-list" ? "Session List" : "Messages"}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <IconButton
          size="small"
          className={NO_DRAG_CLASS_NAME}
          onClick={onCollapseIconClick}
        >
          <ChatWindowCollapse
            sx={{
              color: "#fff",
              transform: collapsed ? "rotate(180deg)" : undefined,
            }}
            fontSize="small"
          />
        </IconButton>
        <IconButton
          size="small"
          className={NO_DRAG_CLASS_NAME}
          onClick={() => setIsOpen(false)}
        >
          <CloseOutlined sx={{ color: "#fff" }} fontSize="small" />
        </IconButton>
      </Stack>
    </HeaderContainer>
  );
};

export default Header;
