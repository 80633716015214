import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { IconButton, useTheme } from "@mui/material";
import { CellEditingStoppedEvent, IDatasource } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { DatasetTableWithFields } from "components/DatasetTable";
import { DatasetTableContainer } from "components/DatasetTable/DatasetTableContainer";
import FusionActionsDropdown from "components/FusionAction/FusionActionsDropdown";
import { GuiType } from "enums/gui";
import { parseDashboardTabFilters } from "helpers/gui";
import useAccountSlug from "hooks/useAccountSlug";
import useCurrentUser from "hooks/useCurrentUser";
import FusionModel from "models/Fusion";
import useActionFields from "queries/formDesign/useActionFields";
import {
  FC,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";
import useFusionAction from "store/stores/fusion-action";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import useGuiParamsStore from "store/stores/gui-prams";
import WidgetWrapper from "./WidgetWrapper";

type DataListWidgetProps = {
  widget: DashboardWidget<"data-list">;
  data?: Record<string, unknown>;
  onDelete(): void;
  setWidgetLoading(loading: boolean): void;
};

// const datasetDesignSlug = "0:62e1fd9a-9684-4a44-9049-5ad03e210596";

const DataListWidget: FC<DataListWidgetProps> = (props) => {
  const { widget, data, onDelete, setWidgetLoading } = props;

  const theme = useTheme();

  const [searchParams] = useSearchParams();
  const accountSlug = useAccountSlug();
  const user = useCurrentUser();

  const { data: actionFields } = useActionFields(widget.slug);

  const tab = useGuiDashboardStore.useGuiTabDraft();
  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();
  const guiParams = useGuiParamsStore.useGuiParamValues();
  const setActionData = useFusionAction.useSetActionData();

  const loadingRef = useRef<Pick<DataListWidgetProps, "setWidgetLoading">>();
  const dataList = useRef<unknown[]>([]);
  const gridRef = useRef<AgGridReact>(null);
  const paginationDataFunctionRef = useRef<(data: unknown[]) => void>();
  const guiParamsRef = useRef(guiParams);

  useImperativeHandle(
    loadingRef,
    () => ({
      setWidgetLoading,
    }),
    [setWidgetLoading]
  );

  useEffect(() => {
    guiParamsRef.current = guiParams;
  }, [guiParams]);

  useEffect(() => {
    // setDataList((prev) => [...prev, ...((data?.data_rows ?? []) as unknown[])]);
    paginationDataFunctionRef.current?.((data?.data_rows as unknown[]) ?? []);
  }, [data]);

  const handlePaginationChange = useCallback(
    (currentPage: number, pageSize: number, pageOffset: number) => {
      if (widget.associated_fusion_slug && accountSlug) {
        const filters =
          tab?.tab_type === GuiType.DashboardV2 && tab.filters
            ? parseDashboardTabFilters(tab.filters, searchParams)
            : {};

        FusionModel.runFusion(widget.associated_fusion_slug, {
          user_id: user.slug,
          account_id: accountSlug,
          popup_variables: {
            popup_variables: {
              filters,
              page_size: pageSize,
              page: currentPage + 1,
              offset: pageOffset,
              gui_params: guiParamsRef.current ?? {},
            },
            ...(guiParamsRef.current ?? {}),
          },
        });

        loadingRef.current?.setWidgetLoading(true);
      }
    },
    [accountSlug, tab, user.slug, widget.associated_fusion_slug]
  );

  const getDataSource = useCallback(
    (): IDatasource => ({
      async getRows(params) {
        try {
          const currentPage = gridRef.current?.api.paginationGetCurrentPage();
          const pageSize = gridRef.current?.api.paginationGetPageSize();
          const pageOffset = params.startRow;
          if (currentPage != null && pageSize != null) {
            handlePaginationChange(currentPage, pageSize, pageOffset);
            paginationDataFunctionRef.current = (data) => {
              params.successCallback(
                data,
                data.length < pageSize
                  ? data.length === 0 && dataList.current.length === 0
                    ? 0
                    : params.endRow - data.length
                  : undefined
              );

              dataList.current = [
                ...dataList.current,
                ...((data as unknown[]) ?? []),
              ];
            };
          } else {
            throw new Error("pagination error");
          }
        } catch (error) {
          params.failCallback();
        }
      },
    }),
    [handlePaginationChange]
  );

  const datasource = useMemo<IDatasource>(() => {
    return getDataSource();
  }, [getDataSource]);

  const fields = useMemo(
    () =>
      actionFields
        ?.sort((a, b) => {
          return (a.slug.split(":").at(-1) ?? 0) >
            (b.slug.split(":").at(-1) ?? 0)
            ? 1
            : -1;
        })
        .map(
          (field) =>
            ({
              ...field,
              ...(field.metadata ?? {}),
              id: field.slug,
              slug: field.field_slug ?? field.metadata.slug ?? field.slug,
            } as DataField)
        ),
    [actionFields]
  );

  const handleCellUpdate = (event: CellEditingStoppedEvent) => {
    if (widget.edit_fusion_slug && accountSlug) {
      FusionModel.runFusion(widget.edit_fusion_slug, {
        account_id: accountSlug,
        user_id: user.slug,
        popup_variables: {
          popup_variables: {
            gui_slug: widget?.parent_gui_slug,
            tab_slug: widget?.parent_tab_slug,
            widget_slug: widget.slug,
            widget_type: widget.type,
            old_value: event.oldValue,
            new_value: event.newValue,
            row_index: event.rowIndex,
          },
        },
      });
    }
  };

  return (
    <WidgetWrapper widget={widget} onDelete={onDelete}>
      <DatasetTableContainer
        backgroundColors={{
          tableBody: theme.palette.background.DashboardWidgetBackground,
          tableDivider: theme.palette.gfGrey.Divider,
          tableHeader: theme.palette.background.DashboardDatalistWidgetHeader,
        }}
        sx={{
          height: "100%",
          width: "100%",
          ".ag-header": { borderTopWidth: "0px !important" },
        }}
      >
        {fields && (
          <DatasetTableWithFields
            ref={gridRef}
            fields={fields}
            datasource={datasource}
            handleUpdate={handleCellUpdate}
            defaultTitleColumn={false}
            editable
            suppressMovableColumns
            minColumnWidth={200}
            disableRowSelection
            disableRowDrag
            disableColumnFilters
            paginationAutoPageSize={false}
            paginationPageSizeSelector={[10, 20, 50, 100]}
            paginationPageSize={10}
            getRowStyle={(params) => {
              return params.data ? { opacity: 1 } : { opacity: 0 };
            }}
            blockLoadDebounceMillis={500}
            autoSizeStrategy={{
              type: "fitGridWidth",
            }}
            actionsColumn={{
              enabled: true,
              getHeader() {
                return null;
              },
              getRowAction(data) {
                return (
                  <FusionActionsDropdown
                    actions={widget.dataset_row_actions ?? []}
                    widget={widget}
                    widgetActionType="dataset"
                    editable={editModeEnabled}
                    onBeforeActionClick={() => {
                      setActionData({ record: data });
                    }}
                  >
                    <IconButton size="small" sx={{ p: 0, borderRadius: "2px" }}>
                      <MoreHoriz
                        sx={{
                          color: theme.palette.other?.tableRowActionIcon,
                        }}
                        fontSize="small"
                      />
                    </IconButton>
                  </FusionActionsDropdown>
                );
              },
            }}
          />
        )}
      </DatasetTableContainer>
    </WidgetWrapper>
  );
};

export default DataListWidget;
