import useGuiTabEditorController from "hooks/useGuiTabEditorController";
import { AnimationLayoutRef } from "layouts/AnimationLayout";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import { FC, RefObject, useCallback, useEffect, useMemo, useRef } from "react";
import useGuiTabEditor from "store/stores/gui-tab-editor";
import { normalizeObjectForAPI } from "utils";
import RenameGuiTabModal from "views/app-view/gui-workspace/components/RenameGuiTabModal";
import TabEditDrawer from "views/app-view/gui-workspace/components/TabEditorDrawer";
import GuiRightSideNav, {
  GuiRightSideNavRef,
} from "../../gui-dataset/components/GuiRightSideNav";

type TabActionsProps = {
  guiTabs: GuiTab[];
  gui?: GfGui;
  guiTabEditorRef: RefObject<AnimationLayoutRef>;
};

const TabActions: FC<TabActionsProps> = (props) => {
  const { guiTabs, gui, guiTabEditorRef } = props;

  const state = useGuiTabEditorController();
  const {
    isOpenRenameModal,
    setIsOpenRenameModal,
    isOpen: isEditTabDrawerOpen,
    close: closeEditTabDrawer,
    selectedTab,
    setSelectedTab,
  } = state;
  const setAdditionalTabPath = useGuiTabEditor.useSetAdditionalTabPath();
  const setEditorPath = useGuiTabEditor.useSetEditorPath();
  const setTabEditorOpen = useGuiTabEditor.useSetEditorOpen();
  const tabEditorOpen = useGuiTabEditor.useEditorOpen();
  const tabSettingsRef = useRef<GuiRightSideNavRef>(null);
  const layoutRef = useRef<AnimationLayoutRef>(null);
  const registerOnEditorOpenCallback =
    useGuiTabEditor.useRegisterOnEditorOpenCallback();

  const { mutate: updateGuiTab } = useUpdateItem({
    modelName: ApiModels.GuiTab,
  });

  useEffect(() => {
    registerOnEditorOpenCallback((editorState) => {
      setTimeout(() => {
        if (editorState.editorPath.length) {
          layoutRef.current?.setTransitionHistory(
            editorState.editorPath.slice(0, -1)
          );
        }
        const last = editorState.editorPath[editorState.editorPath.length - 1];
        if (last) {
          layoutRef.current?.gotoComponent(last);
        }
        tabSettingsRef.current?.setAdditionalTabPath(
          editorState.additionalTabPath
        );
      }, Infinity - 1);
    });
  }, [registerOnEditorOpenCallback]);

  const guiTab = useMemo(() => {
    return guiTabs.find((tab) => tab.tab_name === selectedTab?.value);
  }, [guiTabs, selectedTab?.value]);

  const handleEditTab = useCallback(
    async (data: Partial<GuiTab>) => {
      if (!guiTab) {
        return;
      }

      return new Promise<void>((resolve, reject) => {
        updateGuiTab(
          {
            slug: guiTab.slug,
            data: normalizeObjectForAPI(data, [
              "gui_slug",
              "parent_tab_slug",
              "tab_type",
            ]),
          },
          {
            onSuccess() {
              resolve();
            },
            onError() {
              reject();
            },
          }
        );
      });
    },
    [guiTab, updateGuiTab]
  );

  return (
    <>
      <TabEditDrawer
        open={isEditTabDrawerOpen}
        tab={guiTab}
        gui={gui}
        guiTabEditorRef={guiTabEditorRef}
        onClose={() => {
          closeEditTabDrawer();
          setSelectedTab(null);
        }}
        onSubmit={async (updates) => {
          if (selectedTab) {
            handleEditTab(updates);
          }
        }}
        onEditDatalistSettings={() => {
          setTabEditorOpen(true);
        }}
      />
      <RenameGuiTabModal
        open={isOpenRenameModal}
        value={{ tab_name: selectedTab?.value ?? "" }}
        onClose={() => {
          setSelectedTab(null);
          setIsOpenRenameModal(false);
        }}
        onSubmit={async (data) => {
          if (selectedTab) {
            handleEditTab(data);
          }
        }}
      />
      {gui && (
        <GuiRightSideNav
          ref={tabSettingsRef}
          gui={gui}
          open={tabEditorOpen}
          onClose={() => {
            setAdditionalTabPath([]);
            setEditorPath([]);
            setTabEditorOpen(false);
          }}
          layoutRef={layoutRef}
        />
      )}
    </>
  );
};

export default TabActions;
