import { ArrowBack } from "@mui/icons-material";
import { Box, styled } from "@mui/material";
import { DynamicEditFormProps } from "components/Form/DynamicEditForm";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import Scrollbar from "components/Scrollbar";
import AnimationLayout, {
  AnimationLayoutRef,
  Config,
} from "layouts/AnimationLayout";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { getSearchParams } from "utils";
import { DatasetEditDrawerForm } from "views/app-view/gui-dataset/DatasetEditDrawer";

const DatasetSidebarContainerWrap = styled(Box)(({ theme }) => {
  return {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    marginBottom: 20,

    [`${theme.breakpoints.down("sm")}`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
});

const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 120px)`,
    overflow: "hidden",

    ".side-menu-active &": {
      height: "calc(100vh - 60px)",
    },
  };
});

const getInitialComponent = () => {
  return getSearchParams().get("t_name") || "main";
};

type AdditionalDatasetDrawerContentProps = {
  onFormEvent?: DynamicEditFormProps["onFormEvent"];
  layoutRef: React.RefObject<AnimationLayoutRef>;
  guiSlug?: string;
  includedFieldIds?: string[];
  includeAll?: boolean;
  datasetDesignSlug?: string;
  datasetSlug?: string;
  includedTabs?: IncludeTabs[];
  onWidgetDatasetEdit?: (
    dataset: Dataset,
    widget: IncludeTabs,
    index: number
  ) => void;
  showWidget?: boolean;
  formMetadata?: IncludeTabs;
};

const AdditionalDatasetDrawerContent: React.FC<AdditionalDatasetDrawerContentProps> =
  (props) => {
    const layoutRef = useRef<AnimationLayoutRef>(null);
    const editFormlayoutRef = useRef<AnimationLayoutRef>(null);
    const [selectedTabData, setSelectedTabData] = useState<
      { dataset: Dataset; widget: IncludeTabs }[]
    >([]);
    const [initialComponent, setInitialComponent] = useState<string>("");

    const lastSelectedElement = useMemo(
      () => selectedTabData?.[(selectedTabData?.length || 0) - 1],
      [selectedTabData]
    );

    const getComponentMiddleComponent: Config["getComponents"] =
      React.useCallback(
        (gotoComponent, goBack) => {
          return {
            main: (
              <DatasetEditDrawerForm
                layoutRef={editFormlayoutRef}
                datasetDesignSlug={props.datasetDesignSlug}
                datasetSlug={props.datasetSlug}
                includedFieldIds={props.includedFieldIds}
                includeAll={props.includeAll}
                includedTabs={props.includedTabs}
                onWidgetDatasetEdit={(dataset, widget) => {
                  setSelectedTabData([{ dataset, widget }]);
                  setTimeout(() => {
                    gotoComponent({
                      name: "edit-dataset-form",
                      id: "edit-dataset-form",
                    });
                  });
                }}
                showWidget={true}
                formMetadata={props?.formMetadata}
              />
            ),
            "edit-dataset-form": (
              <SidebarSectionWrap
                title={"Go back"}
                onLeftIconClick={() => {
                  goBack();
                  setSelectedTabData((data) => {
                    return data.slice(0, -1);
                  });
                }}
                rightIcon={false}
                leftIcon={<ArrowBack />}
              >
                <div>
                  <div id="form-container">
                    <DatasetEditDrawerForm
                      layoutRef={editFormlayoutRef}
                      datasetDesignSlug={
                        lastSelectedElement?.dataset?.dataset_type_slug
                      }
                      datasetSlug={lastSelectedElement?.dataset?.slug}
                      includedFieldIds={
                        lastSelectedElement?.widget?.included_fields || []
                      }
                      includeAll={props.includeAll}
                      includedTabs={
                        lastSelectedElement?.widget?.additional_tabs
                      }
                      onWidgetDatasetEdit={(dataset, widget) => {
                        setSelectedTabData((prev) => {
                          return [...prev, { dataset, widget }];
                        });
                        setTimeout(() => {
                          gotoComponent({
                            name: "edit-dataset-form",
                            id: widget.id,
                          });
                        });
                      }}
                      showWidget={true}
                      formMetadata={lastSelectedElement?.widget}
                      guiSlug={props.guiSlug}
                    />
                  </div>
                </div>
              </SidebarSectionWrap>
            ),
          };
        },
        [
          props.datasetDesignSlug,
          props.datasetSlug,
          props.includedFieldIds,
          props.includeAll,
          props.includedTabs,
          props?.formMetadata,
          props.guiSlug,
          lastSelectedElement?.dataset?.dataset_type_slug,
          lastSelectedElement?.dataset?.slug,
          lastSelectedElement?.widget,
        ]
      );

    useEffect(() => {
      setInitialComponent(getInitialComponent());
    }, []);

    return (
      <Box>
        <ScrollbarParent>
          <Scrollbar>
            <DatasetSidebarContainerWrap>
              {initialComponent && (
                <AnimationLayout
                  ref={layoutRef}
                  config={{
                    getComponents: getComponentMiddleComponent,
                    initialComponent,
                  }}
                />
              )}
            </DatasetSidebarContainerWrap>
          </Scrollbar>
        </ScrollbarParent>
      </Box>
    );
  };

export default AdditionalDatasetDrawerContent;
