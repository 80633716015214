import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import FusionModel from "models/Fusion";

const fetchFusionSessions = async (context: QueryFunctionContext) => {
	const [, fusionSlug] = context.queryKey;
	const sessions: FusionFlowSession[] = [];
	let cursor: ApiResponse<unknown>["cursor"];

	do {
		const res = await FusionModel.getSessions(fusionSlug as string, {
			cursor: JSON.stringify(cursor),
		});
		sessions.push(...res.data);
		cursor = res.cursor;
	} while (cursor);

	return sessions;
};

const useFusionSessions = (fusionSlug?: string) => {
	return useQuery(["fusion-sessions", fusionSlug], fetchFusionSessions, {
		enabled: !!fusionSlug,
	});
};

export default useFusionSessions;
