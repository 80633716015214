import {
  Box,
  Button,
  ButtonProps,
  Card,
  CardActions,
  CardContent,
  Popover,
  PopoverProps,
  styled,
  useTheme,
} from "@mui/material";
import {
  FC,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { v4 } from "uuid";

const DialogCard = styled(Card)(({ theme }) => {
  return {
    background: "none",

    ".MuiCardContent-root ": {
      padding: "0",
    },

    ".MuiCardActions-root": {
      padding: "20px 0 0",

      ".MuiButtonBase-root ": {
        minWidth: "87px",
      },

      ".btn-cancel": {
        background: theme.palette.background.GF10,
        color: theme.palette.background.GF50,
      },
    },
  };
});

type PopupFieldProps = PropsWithChildren<{
  trigger: ReactNode;
  name: string;
  anchorEl?: RefObject<HTMLElement | null>;
  disabled?: boolean;
  submitButtonProps?: ButtonProps;
  popoverProps?: Partial<PopoverProps>;
  onOpen?(): void;
  onCancel?(): void;
  onSubmit?(): void;
}>;

export const PopupField: FC<PopupFieldProps> = (props) => {
  const {
    trigger,
    name,
    disabled,
    onCancel,
    onSubmit,
    children,
    onOpen,
    submitButtonProps = {},
    popoverProps = {},
  } = props;

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const { popoverId, popoverContainerId } = useMemo(
    () => ({
      popoverId: `${name}-${v4()}`,
      popoverContainerId: `${name}-${v4()}`,
    }),
    [name]
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open, name]);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (disabled) {
      return;
    }

    setAnchorEl(props.anchorEl?.current ?? event.currentTarget);
  };

  const handleCancel = () => {
    setAnchorEl(null);
    onCancel?.();
  };

  const handleSubmit = () => {
    setAnchorEl(null);
    onSubmit?.();
  };

  return (
    <Box>
      <Box onClick={handleClick}>{trigger}</Box>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              background: "#1e1e23",
              maxWidth: "inherit",
              width: "470px",
              p: 2,

              [`${theme.breakpoints.down("sm")}`]: {
                width: "375px",
              },
            },
          },
        }}
        {...popoverProps}
      >
        <Box sx={{}} id={popoverContainerId}>
          <DialogCard>
            <CardContent>{children}</CardContent>
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleCancel}
                size="small"
                className="btn-cancel"
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSubmit();
                }}
                {...submitButtonProps}
              >
                Save
              </Button>
            </CardActions>
          </DialogCard>
        </Box>
      </Popover>
    </Box>
  );
};

type PopupFieldWithFormProps = Omit<PopupFieldProps, "onSubmit"> & {
  onSubmit?(value: unknown): void;
};

const PopupFieldWithForm: FC<PopupFieldWithFormProps> = (props) => {
  const { onCancel, onSubmit, ...rest } = props;
  const name = rest.name;

  const revertValueRef = useRef<unknown>();

  const { getValues, setValue } = useFormContext();

  const handleSubmit = () => {
    onSubmit?.(getValues(name));
  };

  const handleCancel = () => {
    setValue(name, revertValueRef.current);
    onCancel?.();
  };

  const handleOpen = () => {
    revertValueRef.current = getValues(name);
  };

  return (
    <PopupField
      {...rest}
      onOpen={handleOpen}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};

export default PopupFieldWithForm;
