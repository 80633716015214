import { zodResolver } from "@hookform/resolvers/zod";
import { PersonOutline } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useQuery from "hooks/useQuery";
import { Copyright } from "layouts/SplashLayout";
import { ApiModels } from "queries/apiModelMapping";
import useSignUp from "queries/auth/useSignUp";
import useGetItem from "queries/useGetItem";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";

const formSchema = z.object({
  first_name: z.string().min(1, "first name is required"),
  last_name: z.string().min(1, "last name is required"),
  email: z.string().min(1, "email is required").email("Email is required"),
  // company_name: z.string().min(1, "company is required"),
  // pass_phrase: z.string().optional(),
  // account_package_id: z.string().min(1, "Name is required"),
  password: z
    .string()
    .regex(
      new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^\\w])[^\\n]{8,}$"
      ),
      "Invalid password format."
    ),
});
type Schema = z.infer<typeof formSchema>;

export default function SignUp() {
  const { mutate: handleSignUp, isLoading } = useSignUp();
  const { pid } = useQuery<{ pid: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from;
  const { data: pkg } = useGetItem({
    modelName: ApiModels.Package,
    slug: pid,
    queryOptions: { enabled: !!pid },
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      // company_name: "",
      password: "",
      // account_package_id: pid,
      // pass_phrase: undefined,
    },
  });
  const onSubmit = (data: Schema) => {
    handleSignUp(data, {
      onSuccess: () => {
        navigate(`/verify?email=${data.email}`, { state: { from } });
      },
    });
  };

  console.log({ errors });

  return (
    <Box>
      <Box textAlign={"center"}>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main", ml: "45%" }}>
          <PersonOutline />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
      </Box>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 1 }}
      >
        <TextField
          {...register("first_name")}
          margin="normal"
          required
          fullWidth
          id="first_name"
          label="First Name"
          name="first_name"
          autoComplete="first_name"
          autoFocus
          error={Boolean(errors?.first_name)}
          helperText={errors?.first_name?.message}
        />
        <TextField
          {...register("last_name")}
          margin="normal"
          required
          fullWidth
          id="last_name"
          label="Last Name"
          name="last_name"
          autoComplete="last_name"
          error={Boolean(errors?.last_name)}
          helperText={errors?.last_name?.message}
        />
        {/* <TextField
          {...register("company_name")}
          margin="normal"
          required
          fullWidth
          id="company_name"
          label="Company Name"
          name="company_name"
          // autoComplete="company_name"

          error={Boolean(errors?.company_name)}
          helperText={errors?.company_name?.message}
        /> */}
        <TextField
          {...register("email")}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          // autoComplete="email"
          type="email"
          error={Boolean(errors?.email)}
          helperText={errors?.email?.message}
        />
        <TextField
          {...register("password")}
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          // autoComplete="current-password"
          error={Boolean(errors?.password)}
          helperText={errors?.password?.message}
        />
        {/* {pkg?.enable_pass && (
          <TextField
            {...register("pass_phrase")}
            margin="normal"
            required
            fullWidth
            label="Pass Phrase"
            error={Boolean(errors?.pass_phrase)}
            helperText={errors?.pass_phrase?.message}
          />
        )} */}

        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={isLoading}
        >
          Sign Up
        </LoadingButton>
        <Grid container>
          <Grid item xs>
            <Link
              to="/login"
              style={{ textDecorationColor: "white", color: "white" }}
              state={{ from }}
            >
              Already have an account?
            </Link>
          </Grid>
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Box>
    </Box>
  );
}
