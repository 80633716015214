import { SendOutlined } from "@mui/icons-material";
import AddCircle from "@mui/icons-material/AddCircle";
import AttachFile from "@mui/icons-material/AttachFile";
import { Box, IconButton, TextField, styled } from "@mui/material";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import ActionMenu from "components/ActionMenu";
import { MEDIA_BUCKET, S3_CLOUD_FRONT_URL } from "configs/AppConfig";
import useAccountSlug from "hooks/useAccountSlug";
import cloneDeep from "lodash/cloneDeep";
import OpenAI from "models/OpenAI";
import S3Model from "models/S3";
import useCreateMessage from "queries/copilot/useCreateMessage";
import { getThreadAttachmentQueryKey } from "queries/copilot/useThreadAttachments";
import {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useRef,
  useState,
} from "react";
import useAgentChatStore from "store/stores/agent-chat";
import { v4 } from "uuid";
import InputAttachments, { AttachmentFile } from "./InputAttachments";

const Container = styled(Box)({
  paddingTop: "16px",
  paddingBottom: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
});

const InputFieldContainer = styled(Box)(({ theme }) => ({
  ".MuiFilledInput-root": {
    border: "none",
    borderRadius: "30px",
    background: theme.palette.background.ChatInput,

    "&.Mui-focused, &:hover": {
      border: "none",
    },
  },
}));

const TextFieldStyled = styled(TextField)({
  input: {
    height: "60px",
    padding: 0,
  },
  fieldset: {
    border: "none",
  },
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type InputFieldProps = {};

const InputField: FC<InputFieldProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const accountSlug = useAccountSlug();
  const queryClient = useQueryClient();

  const setShowLoadingMessage = useAgentChatStore.useSetShowLoadingMessage();
  const showLoadingMessage = useAgentChatStore.useShowLoadingMessage();
  const thread = useAgentChatStore.useSelectedThread();

  const { mutateAsync: createMessage } = useCreateMessage();

  const [inputFiles, setInputFiles] = useState<AttachmentFile[]>([]);

  const handleSend = async () => {
    if (
      !inputRef.current ||
      inputFiles.some((file) => file.status === "uploading")
    ) {
      return;
    }

    const value = inputRef.current?.value;
    if (thread?.id && value) {
      inputRef.current.value = "";
      setInputFiles([]);
      setShowLoadingMessage(`${thread.id}`);

      const { files } = inputFiles.reduce<{
        files: any[];
      }>(
        (acc, cur) => {
          const fileId = cur.oai_file_id;
          if (!fileId) {
            return acc;
          }

          let s3Key = cur.url ? new URL(cur.url).pathname : "";
          if (s3Key.startsWith("/")) s3Key.slice(1);
          const data = {
            s3_key: cur.url ? new URL(cur.url).pathname.slice(1) : "",
            content_type: cur.type,
            file_id: cur.oai_file_id,
            filename: cur.name,
          };

          acc.files.push(data);
          return acc;
        },
        { files: [] }
      );

      const id = v4();
      queryClient.setQueryData<InfiniteData<ApiResponse<CopilotMessage[]>>>(
        ["copilot", "threads", thread?.id, "messages"],
        (oldData) => {
          const prev = cloneDeep(oldData);
          if (!prev) {
            return prev;
          }

          prev.pages.at(-1)?.data.unshift({
            id,
            slug: `${Date.now()}:${v4()}`,
            is_active: 1,
            is_deleted: 0,
            created_at: new Date().toISOString(),
            updated_at: null,
            open_ai_message_id: "",
            sender: "user",
            value,
            attachments: files.map((file) => ({
              s3Key: file.s3_key,
              contentType: file.content_type,
              fileId: file.file_id,
              filename: file.filename,
            })),
          });

          return prev;
        }
      );

      const message = await createMessage({
        threadSlug: `${thread?.id}`,
        data: {
          message: value,
          files,
        },
      });

      if (message) {
        queryClient.refetchQueries({
          queryKey: getThreadAttachmentQueryKey(`${thread.id}`),
        });
        queryClient.setQueryData<InfiniteData<ApiResponse<CopilotMessage[]>>>(
          ["copilot", "threads", thread?.id, "messages"],
          (oldData) => {
            const prev = cloneDeep(oldData);
            if (!prev) {
              return prev;
            }

            const page = prev.pages.at(-1);

            if (!page) {
              return prev;
            }

            page.data = page.data.map((d) => (d.id === id ? message : d));

            return prev;
          }
        );
      }
    }
  };

  const handleKeydown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleFilesUpload = (files: AttachmentFile[]) => {
    files.forEach((file) => {
      const nameChunks = file.name.split(".");
      const ext = nameChunks.pop();
      const name = nameChunks.join(".");
      const s3Key = `${accountSlug}/oai-files/${name}-${v4()}.${ext}`;
      try {
        S3Model.getUploadUrl({
          key: s3Key,
          bucket: MEDIA_BUCKET,
        }).then((response) => {
          const uploadUrl = response.data.url;

          if (uploadUrl) {
            axios
              .put(uploadUrl, file.file, {
                headers: {
                  "Content-Type": file.type,
                  "Content-Disposition": `attachment`,
                },
              })
              .then(() => {
                OpenAI.uploadFileFormS3({
                  key: s3Key,
                  filename: file.name,
                  bucket: MEDIA_BUCKET,
                }).then((aoiResponse) => {
                  setInputFiles((prev) =>
                    prev.map((f) =>
                      f.id === file.id
                        ? {
                            ...f,
                            status: "done",
                            oai_file_id: aoiResponse.data.id,
                            url: `${S3_CLOUD_FRONT_URL}/${s3Key}`,
                          }
                        : f
                    )
                  );
                });
              });
          }
        });
      } catch (error) {
        console.log("🚀 ~ files.forEach ~ error:", error);
        setInputFiles((prev) =>
          prev.map((f) => (f.id === file.id ? { ...f, status: "error" } : f))
        );
      }
    });
  };

  const handleFilesSelect: ChangeEventHandler<HTMLInputElement> = (event) => {
    const files = [...(event.target.files ?? [])];

    if (!files.length) {
      return;
    }

    const addedFiles: AttachmentFile[] = files.map((file) => ({
      name: file.name,
      size: file.size,
      type: file.type,
      status: "uploading",
      id: v4(),
      file,
    }));

    setInputFiles((prev) => [...prev, ...addedFiles]);

    handleFilesUpload(addedFiles);
  };

  const handleRemoveFile = (id: string) => {
    setInputFiles((prev) => prev.filter((file) => file.id !== id));
  };

  return (
    <Container id="agent-chat-input-field">
      <InputFieldContainer>
        <InputAttachments files={inputFiles} onRemove={handleRemoveFile} />
        <TextFieldStyled
          inputRef={inputRef}
          placeholder="Type / for commands"
          variant="filled"
          fullWidth
          disabled={!!showLoadingMessage}
          InputProps={{
            startAdornment: (
              <ActionMenu
                menuItems={[
                  {
                    label: "Attach Files",
                    value: "attach_files",
                    icon: <AttachFile fontSize="small" />,
                  },
                ]}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                onItemClick={(value) => {
                  if (value === "attach_files") {
                    fileInputRef.current?.click();
                  }
                }}
              >
                <IconButton>
                  <AddCircle
                    sx={{
                      color: (theme) =>
                        theme.palette.other?.chatInputIconPrimary,
                      // background: "#ffffff",
                    }}
                  />
                </IconButton>
              </ActionMenu>
            ),
            endAdornment: (
              <IconButton onClick={handleSend}>
                <SendOutlined
                  sx={{
                    color: (theme) =>
                      theme.palette.other?.chatInputIconSecondary,
                  }}
                />
              </IconButton>
            ),
          }}
          onKeyDown={handleKeydown}
        />
        <VisuallyHiddenInput
          type="file"
          ref={fileInputRef}
          onChange={handleFilesSelect}
          multiple
        />
      </InputFieldContainer>
    </Container>
  );
};

export default InputField;
