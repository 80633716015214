import { Box, styled } from "@mui/material";
import ActionEditorDrawer from "components/FusionAction/ActionEditorDrawer";
import ActionFormModal from "components/FusionAction/ActionFormModal";
import Scrollbar from "components/Scrollbar";
import Spin from "components/Spin";
import { DEFAULT_WIDGET_PROPS } from "constants/gui";
import { GuiType } from "enums/gui";
import pick from "lodash/pick";
import useGuiTabWidgets from "queries/gui/useGuiTabWidgets";
import React, { useEffect, useMemo } from "react";
import { useGuiDashboardV2Store } from "store/stores/gui-dashboard-v2";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import useGuiParamsStore from "store/stores/gui-prams";
import { useSystemLayoutStore } from "store/stores/systemLayout";
import DashboardEditor from "./components/DashboardEditor";
import WidgetEditorDrawer from "./components/WidgetEditorDrawer";

const CenterBox = styled(Box)(({ theme }) => ({
  height: "100%",
  margin: "0 8px",
  background: theme.palette.background.LeftNavBody,
  borderRadius: "6px 6px 0 0",

  ".heading-card": {
    margin: "0",
    padding: "0 0 9px !important",

    ".heading-wrap": {
      margin: "0 0 10px",
    },
  },
}));

type GFDashboardProps = {
  guiTab: GuiTab;
  editable?: boolean;
};

const GuiDashboardV2: React.FC<GFDashboardProps> = (props) => {
  const { guiTab, editable } = props;

  const setShowSideNavTopSelector =
    useSystemLayoutStore.useSetShowSideNavTopSelector();
  const setEnableSideNavSearch =
    useSystemLayoutStore.useSetEnableSideNavSearch();
  const setShowActionButtons = useSystemLayoutStore.useSetShowActionButtons();

  const setEditModeEnabled = useGuiDashboardStore.useSetEditModeEnabled();
  const setGuiTabDraft = useGuiDashboardStore.useSetGuiTabDraft();
  const setLayout = useGuiDashboardV2Store.useSetLayout();
  const setWidgets = useGuiDashboardV2Store.useSetWidgets();
  const setWidget = useGuiDashboardV2Store.useSetWidget();
  const isWidgetEditorOpen = useGuiDashboardV2Store.useIsWidgetEditorOpen();
  const setIsWidgetEditorOpen =
    useGuiDashboardV2Store.useSetIsWidgetEditorOpen();
  const setIsFilterViewOpen = useGuiParamsStore.useSetIsFilterViewOpen();
  const paramsUpdateId = useGuiParamsStore.useParamsUpdateId();

  const { data: widgets } = useGuiTabWidgets(guiTab?.slug);

  useEffect(() => {
    setShowSideNavTopSelector(false);
    setEnableSideNavSearch(false);
    setShowActionButtons(false);
    setEditModeEnabled(false);
  }, []);

  useEffect(() => {
    if (widgets) {
      setWidgets(widgets ?? []);
    }

    return () => {
      setWidgets([]);
    };
  }, [setWidgets, widgets]);

  const dashboardLayout = useMemo(() => {
    if (guiTab.tab_type !== GuiType.DashboardV2) {
      return [];
    }

    return guiTab.layout.map((l) => {
      const widget = widgets?.find((w) => w.slug === l.i);
      if (widget) {
        return {
          ...l,
          ...pick(DEFAULT_WIDGET_PROPS[widget.type], ["minW", "minH"]),
        };
      }
      return l;
    });
  }, [widgets, guiTab]);

  useEffect(() => {
    if (guiTab) {
      setGuiTabDraft(guiTab);
    }
  }, [guiTab, setGuiTabDraft, setLayout]);

  useEffect(() => {
    setLayout(dashboardLayout);

    return () => {
      setLayout([]);
    };
  }, [dashboardLayout, setLayout]);

  const handleAddWidget = () => {
    setIsWidgetEditorOpen(true);
  };

  return (
    <Spin spinning={false} sx={{ height: "100%" }}>
      {/* <GuiTabUpdate /> */}
      <CenterBox>
        <Scrollbar>
          <DashboardEditor
            guiSlug={guiTab.gui_slug}
            key={paramsUpdateId}
            onAddWidget={handleAddWidget}
            onFiltersClick={() => {
              setIsFilterViewOpen(true);
            }}
          />
        </Scrollbar>
      </CenterBox>
      <WidgetEditorDrawer
        open={isWidgetEditorOpen}
        onClose={() => {
          setIsWidgetEditorOpen(false);
          setWidget(null);
        }}
      />
      <ActionFormModal />
      <ActionEditorDrawer guiAction guiSlug={guiTab.gui_slug} guiTab={guiTab} />
    </Spin>
  );
};

export default GuiDashboardV2;
