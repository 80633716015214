import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, MenuItem, Select, Typography, styled } from "@mui/material";
import Icon from "components/util-components/Icon";
import { NAV_SELECTOR_TYPE } from "configs/AppConfig";
import { FC } from "react";
import { useSystemLayoutStore } from "store/stores/systemLayout";
import { DrawerHeader } from "./index.styles";

const SelectStyled = styled(Select)({
  width: "100%",
  ".MuiSelect-select": {
    minHeight: "48px !important",
    padding: "6px 12px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
});

const SimpleTypeContainer = styled(Box)({
  width: "100%",
  minHeight: "48px !important",
  padding: "6px 12px",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
});

type SideNavTopSelectorProps = {};

const SideNavTopSelector: FC<SideNavTopSelectorProps> = () => {
  const selectedValue = useSystemLayoutStore.useSideNavSelectorValue();
  const setValue = useSystemLayoutStore.useSetSideNavSelectorValue();
  const options = useSystemLayoutStore.useSideNavSelectorOptions();
  const props = useSystemLayoutStore.useSideNavSelectorProps();
  const type = useSystemLayoutStore.useSideNavSelectorType();

  return (
    <DrawerHeader>
      {type === NAV_SELECTOR_TYPE.SELECT ? (
        <SelectStyled
          onChange={(e) => {
            setValue(options.find((op) => op.key === e.target.value));
            props.onSelect?.(options.find((op) => op.key === e.target.value));
          }}
          value={selectedValue.key}
          size="small"
          className="header-select"
          IconComponent={ExpandMore}
        >
          {options.map((item) => (
            <MenuItem value={item.key} key={item.key} sx={{ gap: "6px" }}>
              {item.icon && (
                <Icon
                  className="header-select-icon"
                  key={item.icon}
                  iconName={item.icon as any}
                  color="primary"
                />
              )}{" "}
              <Box className="header-title">{item.title}</Box>
            </MenuItem>
          ))}
        </SelectStyled>
      ) : (
        <SimpleTypeContainer>
          <Icon iconName={selectedValue.icon} sx={{ marginTop: "5px" }} />
          <Typography>{selectedValue.title}</Typography>
        </SimpleTypeContainer>
      )}
    </DrawerHeader>
  );
};

export default SideNavTopSelector;
