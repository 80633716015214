import { ParameterType } from "enums/3pApp";
import use3pApps from "queries/3p-app/use3pApps";
import useListAppConnections from "queries/3p-app/useListAppConnections";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import ConnectionFields from "views/app-view/flow-designer/components/ConnectionFields";
import { ParamField } from "views/app-view/flow-designer/components/NodeEditorFields";

const CreateConnectionFields: FC = (props) => {
  const { watch } = useFormContext();

  const { data: apps } = use3pApps();
  const appOptions = useMemo(
    () => apps?.map((app) => ({ label: app.app_label, value: app.slug })),
    [apps]
  );

  const appSlug = watch("app_slug");

  const { data: appConnections } = useListAppConnections(appSlug, {
    is_global: `${apps
      ?.find((a) => a.slug === appSlug)
      ?.id?.startsWith("3p:global")}`,
  });

  const appConnectionOptions = useMemo(
    () =>
      appConnections
        ?.filter((appConnection) => appConnection.type !== "oauth2")
        .map((appConnection) => ({
          label: appConnection.label,
          value: appConnection.slug,
        })),
    [appConnections]
  );

  const appConnectionSlug = watch("app_connection");

  const appConnection = useMemo(
    () =>
      appConnections?.find(
        (appConnection) => appConnection.slug === appConnectionSlug
      ),
    [appConnections, appConnectionSlug]
  );

  return (
    <>
      <ParamField
        mappable
        field={{
          name: "app_slug",
          type: ParameterType.Select,
          label: "App",
          options: appOptions,
          nested: [
            {
              name: "app_connection",
              type: ParameterType.Select,
              label: "App Connection",
              options: appConnectionOptions,
            },
          ],
        }}
      />
      {appConnection && (
        <ConnectionFields
          connection={appConnection}
          advancedCheck={{ show: false, formKey: "show_advanced_settings" }}
          mappable
        />
      )}
    </>
  );
};

export default CreateConnectionFields;
