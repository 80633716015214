import { Avatar, Chip } from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";
import Cell from "components/DatasetTable/Cell";
import { FC, memo } from "react";

const DISPLAY_LIMIT = 2;

const DefaultTagRenderer: FC<CustomCellRendererProps> = memo((props) => {
  const options: (LabeledValue & { color?: string })[] =
    props.colDef?.cellEditorParams.values ?? [];
  const value = (props.value as string[] | undefined) ?? [];
  const surplus = value.length - DISPLAY_LIMIT;

  return (
    <Cell isLoading={!props.data} sx={{ justifyContent: "space-between" }}>
      <Cell sx={{ gap: 0.5 }}>
        {value.slice(0, DISPLAY_LIMIT).map((v) => {
          const option = options.find((op) => op.value === v);

          return (
            <Chip
              label={option?.label}
              size="small"
              sx={{ bgcolor: option?.color }}
            />
          );
        })}
      </Cell>
      {surplus > 0 && (
        <Avatar
          alt="surplus"
          sx={{
            width: 24,
            height: 24,
            fontSize: 14,
          }}
          variant="rounded"
        >
          {surplus > 5 ? "+5" : surplus}
        </Avatar>
      )}
    </Cell>
  );
});

export default DefaultTagRenderer;
