import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { FC, useEffect } from "react";
import useLexicalStore from "store/stores/lexical";

type ScrollToHeadingPluginProps = {};

const ScrollToHeadingPlugin: FC<ScrollToHeadingPluginProps> = (props) => {
  const [editor] = useLexicalComposerContext();

  const registerFunction = useLexicalStore.useRegisterScrollToHeadingFunction();

  useEffect(() => {
    if (editor) {
      registerFunction((heading) => {
        console.log(heading);
        editor.update(() => {
          // const root = $getRoot();
          // let targetNode: HeadingNode | null = null;

          // const findHeadingNode = (node: LexicalNode): boolean => {
          //   if ($isHeadingNode(node) && node.getKey() === heading) {
          //     targetNode = node;
          //     return true;
          //   }

          //   if (node instanceof ElementNode) {
          //     for (const childNode of node.getChildren()) {
          //       if (findHeadingNode(childNode as LexicalNode)) {
          //         return true;
          //       }
          //     }
          //   }
          //   return false;
          // };

          // if (root instanceof ElementNode) {
          //   root
          //     .getChildren()
          //     .some((childNode) => findHeadingNode(childNode as LexicalNode));
          // }

          if (heading) {
            const domElement = editor.getElementByKey(heading);
            if (domElement) {
              domElement.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          }
        });
      });
    }
  }, [editor, registerFunction]);

  return null;
};

export default ScrollToHeadingPlugin;
