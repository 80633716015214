import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as ExportIconSvg } from "../svg/export.svg";

const ExportIcon: React.FC<React.ComponentProps<typeof SvgIcon>> = (props) => {
  return (
    <SvgIcon
      inheritViewBox
      {...props}
      component={ExportIconSvg}
      className="plus-icon"
    />
  );
};

export default ExportIcon;
