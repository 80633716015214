import { ParameterType } from "enums/3pApp";
import { GuiType } from "enums/gui";
import useDataFields from "hooks/useDataFields";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { ParamField } from "views/app-view/flow-designer/components/NodeEditorFields";

type CreateGuiTabFieldsProps = {};

const CreateGuiTabFields: FC<CreateGuiTabFieldsProps> = (props) => {
  const { watch } = useFormContext();

  const { data: guis = [] } = useListItems({
    modelName: ApiModels.Gui,
  });

  const guiOptions = useMemo<LabeledValue[]>(
    () => guis.map((gui) => ({ label: gui.name, value: gui.slug })),
    [guis]
  );

  const { data: datasetDesigns = [] } = useListItems({
    modelName: ApiModels.DatasetDesign,
  });

  const datasetDesignOptions = useMemo(
    () => datasetDesigns.map((d) => ({ label: d.name, value: d.slug })),
    [datasetDesigns]
  );

  const datasetDesignSlug = watch("dataset_design_slug");

  const designFields = useDataFields(datasetDesignSlug);

  const designFieldsOptions = useMemo(
    () =>
      designFields.map((field) => ({ label: field.title, value: field.id })),
    [designFields]
  );

  const tabTypeOptions = useMemo(
    () => [
      {
        label: "Dashboard",
        value: GuiType.DashboardV2,
      },
      {
        label: "Record List",
        value: GuiType.RecordList,
        nested: [
          {
            name: "dataset_design_slug",
            label: "Dataset Design",
            type: ParameterType.Select,
            options: datasetDesignOptions,
          },
        ],
      },
      {
        label: "Workflow Board",
        value: GuiType.WorkflowBoard,
        nested: [
          {
            name: "dataset_design_slug",
            label: "Dataset Design",
            type: ParameterType.Select,
            options: datasetDesignOptions,
            nested: [
              {
                name: "status_field",
                label: "Status Field",
                type: ParameterType.Select,
                options: designFieldsOptions,
              },
            ],
          },
        ],
      },
      {
        label: "Reviewer",
        value: GuiType.Reviewer,
        nested: [
          {
            name: "dataset_design_slug",
            label: "Dataset Design",
            type: ParameterType.Select,
            options: datasetDesignOptions,
          },
        ],
      },
    ],
    [datasetDesignOptions, designFieldsOptions]
  );

  return (
    <>
      <ParamField
        mappable
        field={{
          name: "gui_slug",
          label: "Gui",
          type: ParameterType.Select,
          options: guiOptions,
        }}
      />
      <ParamField
        mappable
        field={{ name: "name", label: "Tab Name", type: ParameterType.Text }}
      />
      <ParamField
        mappable
        field={{
          name: "type",
          label: "Tab Type",
          type: ParameterType.Select,
          options: tabTypeOptions,
        }}
      />
    </>
  );
};

export default CreateGuiTabFields;
