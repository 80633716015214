import AccountUserModel from "models/AccountUser";
import AgentModel from "models/Agent";
import AgentDatasetRightModel from "models/AgentDatasetRight";
import AgentSkillModel from "models/AgentSkill";
import AgentSkillAsscModel from "models/AgentSkillAssc";
import AgentUserModel from "models/AgentUser";
import AppConfigModel from "models/AppConfig";
import CardModel from "models/Card";
import CreditTypeModel from "models/CreditType";
import Datacard from "models/Datacard";
import DatacardDesignModel from "models/DatacardDesign";
import DatasetModel from "models/Dataset";
import DatasetDesignModel from "models/DatasetDesign";
import DocumentModel from "models/Document";
import FinetuneKnowledgebaseModel from "models/FinetuneKnowledgebase";
import FinetuneKnowledgebaseTopicModel from "models/FinetuneKnowledgebaseTopic";
import FolderModel from "models/Folder";
import FormDesignModel from "models/FormDesign";
import FusionModel from "models/Fusion";
import GFMLFunctionModel from "models/GFMLFunction";
import GuiModel from "models/Gui";
import GuiDashboardWidgetModel from "models/GuiDashboardWidget";
import GuiDashboardWidgetV2Model from "models/GuiDashboardWidgetV2";
import GuiDocumentModel from "models/GuiDocument";
import GuiParamGroupModel from "models/GuiParamGroup";
import GuiParamsModel from "models/GuiParams";
import GuiTabModel from "models/GuiTab";
import JobModel from "models/Job";
import JobTaskModel from "models/JobTask";
import JobTaskLogModel from "models/JobTaskLog";
import MediaModel from "models/Media";
import PackageModel from "models/Package";
import PortalModel from "models/Portal";
import PresentationModel from "models/Presentation";
import PresentationSlideModel from "models/PresentationSlide";
import PresentationSlideSortModel from "models/PresentationSlideSort";
import SeatTypeModel from "models/SeatType";
import SkillDataModel from "models/SkillData";
import SqlQueryModel from "models/SqlQuery";
import SSAgentModel from "models/SSAgent";
import SSAgentDocumentModel from "models/SSAgentDocument";
import SSAgentSkillModel from "models/SSAgentSkill";
import SSAgentSkillAsscModel from "models/SSAgentSkillAssc";
import TagModel from "models/Tag";
import ThreePAppModel from "models/ThreePApp";
import ThreePAppActionModel from "models/ThreePAppAction";
import ThreePAppConnectionModel from "models/ThreePAppConnection";
import ThreePAppRemoteProcedureModel from "models/ThreePAppRemoteProcedure";
import ThreePAppWebhookModel from "models/ThreePAppWebhook";
import TransationHistroyModel from "models/TransationHistroy";
import UniversalEventModel from "models/UniversalEvent";
import UniversalNotesModel from "models/UniversalNotes";
import UploadDesignModel from "models/UploadDesign";
import UserMenuItemModel from "models/UserMenuItem";
import UserMenuTemplateModel from "models/UserMenuTemplate";
import UserTypeModel from "models/UserType";
import VectorKnowledgebaseModel from "models/VectorKnowledgebase";
import VectorKnowledgebaseTopicModel from "models/VectorKnowledgebaseTopic";

import { Edge, Node, Viewport } from "reactflow";

export const ApiModels = {
  DatasetDesign: "dataset-design",
  Presentation: "presentation",
  PresentationSlide: "presentation-slide",
  PresentationSlideSort: "presentation-slide-sort",
  Dataset: "dataset",
  DatacardDesign: "datacard-design",
  Datacard: "datacard",
  UniversalEvent: "universal-event",
  AccountUser: "account-user",
  UserType: "user-type",
  ThreePApp: "3p-app",
  Fusion: "fusion",
  GFMLFunctions: "gfml-functions",
  Folder: "folder",
  Tag: "tag",
  UniversalNote: "universal-note",
  Gui: "gui",
  GuiParams: "gui-params",
  Portal: "portal",
  GuiDashboard: "gui-dashboard",
  GuiDashboardWidget: "gui-dashboard-widget",
  GuiDashboardWidgetV2: "gui-dashboard-widget-v2",
  VectorKnowledgebase: "vector-knowledgebase",
  VectorKnowledgebaseTopic: "vector-knowledgebase-topic",
  FinetuneKnowledgebase: "finetune-knowledgebase",
  FinetuneKnowledgebaseTopic: "finetune-knowledgebase-topic",
  UploadDesign: "upload-design",
  SkillData: "skill-data",
  Media: "media",
  Card: "card",
  TransationHistory: "transation-history",
  Package: "package",
  CreditType: "credit-type",
  SeatType: "seat-type",
  AppConfig: "app-config",
  UserMenuItem: "user-menu-item",
  UserMenuTemplate: "user-menu-template",
  GuiTab: "gui-tab",
  FormDesign: "form-design",
  SqlQuery: "sql-query",
  SSAgent: "ss-agent",
  SSAgentSkill: "ss-agent-skill",
  SSAgentSkillAssc: "ss-agent-skill-assc",
  SSAgentDocument: "ss-agent-document",
  Agent: "agent",
  AgentSkill: "agent-skill",
  AgentSkillAssc: "agent-skill-assc",
  AgentUser: "agent-user",
  AgentDatasetRight: "agent-dataset-right",
  Document: "document",
  GuiDocument: "gui-document",
  Job: "job",
  JobTask: "job-task",
  JobTaskLog: "job-task-log",
  GuiParamGroup: "gui-param-group",
} as const;

export const ThreePAppSubModels = {
  ThreePAppConnection: "3p-app-connections",
  ThreePAppWebhook: "3p-app-webhooks",
  ThreePAppAction: "3p-app-actions",
  ThreePAppRemoteProcedure: "3p-app-remote-procedure",
} as const;

export const ApiModelMapping = {
  [ApiModels.DatasetDesign]: {
    model: DatasetDesignModel,
  },
  [ApiModels.PresentationSlideSort]: {
    model: PresentationSlideSortModel,
  },
  [ApiModels.PresentationSlide]: {
    model: PresentationSlideModel,
  },
  [ApiModels.Presentation]: {
    model: PresentationModel,
  },
  [ApiModels.Dataset]: {
    model: DatasetModel,
  },
  [ApiModels.DatacardDesign]: {
    model: DatacardDesignModel,
  },
  [ApiModels.Datacard]: {
    model: Datacard,
  },
  [ApiModels.UniversalEvent]: {
    model: UniversalEventModel,
  },
  [ApiModels.AccountUser]: {
    model: AccountUserModel,
  },
  [ApiModels.UserType]: {
    model: UserTypeModel,
  },
  [ApiModels.ThreePApp]: {
    model: ThreePAppModel,
  },
  [ApiModels.Fusion]: {
    model: FusionModel,
  },
  [ApiModels.GFMLFunctions]: {
    model: GFMLFunctionModel,
  },
  [ApiModels.Folder]: {
    model: FolderModel,
  },
  [ApiModels.Tag]: {
    model: TagModel,
  },
  [ApiModels.UniversalNote]: {
    model: UniversalNotesModel,
  },
  [ApiModels.Gui]: {
    model: GuiModel,
  },
  [ApiModels.GuiParams]: {
    model: GuiParamsModel,
  },
  [ApiModels.Portal]: {
    model: PortalModel,
  },
  [ApiModels.GuiDashboardWidget]: {
    model: GuiDashboardWidgetModel,
  },
  [ApiModels.GuiDashboardWidgetV2]: {
    model: GuiDashboardWidgetV2Model,
  },
  [ApiModels.VectorKnowledgebase]: {
    model: VectorKnowledgebaseModel,
  },
  [ApiModels.VectorKnowledgebaseTopic]: {
    model: VectorKnowledgebaseTopicModel,
  },
  [ApiModels.FinetuneKnowledgebase]: {
    model: FinetuneKnowledgebaseModel,
  },
  [ApiModels.FinetuneKnowledgebaseTopic]: {
    model: FinetuneKnowledgebaseTopicModel,
  },
  [ApiModels.UploadDesign]: {
    model: UploadDesignModel,
  },
  [ApiModels.SkillData]: {
    model: SkillDataModel,
  },
  [ApiModels.Media]: {
    model: MediaModel,
  },
  [ApiModels.Card]: {
    model: CardModel,
  },
  [ApiModels.TransationHistory]: {
    model: TransationHistroyModel,
  },
  [ApiModels.Package]: {
    model: PackageModel,
  },
  [ApiModels.CreditType]: {
    model: CreditTypeModel,
  },
  [ApiModels.SeatType]: {
    model: SeatTypeModel,
  },
  [ApiModels.AppConfig]: {
    model: AppConfigModel,
  },
  [ApiModels.UserMenuItem]: {
    model: UserMenuItemModel,
  },
  [ApiModels.UserMenuTemplate]: {
    model: UserMenuTemplateModel,
  },
  [ApiModels.GuiTab]: {
    model: GuiTabModel,
  },
  [ApiModels.FormDesign]: {
    model: FormDesignModel,
  },
  [ApiModels.SqlQuery]: {
    model: SqlQueryModel,
  },
  [ApiModels.Agent]: {
    model: AgentModel,
  },
  [ApiModels.AgentSkill]: {
    model: AgentSkillModel,
  },
  [ApiModels.AgentSkillAssc]: {
    model: AgentSkillAsscModel,
  },
  [ApiModels.AgentUser]: {
    model: AgentUserModel,
  },
  [ApiModels.AgentDatasetRight]: {
    model: AgentDatasetRightModel,
  },
  [ApiModels.Document]: {
    model: DocumentModel,
  },
  [ApiModels.GuiDocument]: {
    model: GuiDocumentModel,
  },
  [ApiModels.Job]: {
    model: JobModel,
  },
  [ApiModels.JobTask]: {
    model: JobTaskModel,
  },
  [ApiModels.JobTaskLog]: {
    model: JobTaskLogModel,
  },
  [ApiModels.GuiParamGroup]: {
    model: GuiParamGroupModel,
  },
  [ApiModels.SSAgent]: {
    model: SSAgentModel,
  },
  [ApiModels.SSAgentSkill]: {
    model: SSAgentSkillModel,
  },
  [ApiModels.SSAgentSkillAssc]: {
    model: SSAgentSkillAsscModel,
  },
  [ApiModels.SSAgentDocument]: {
    model: SSAgentDocumentModel,
  },
} as const;

export const ThreePAppSubModelMapping = {
  [ThreePAppSubModels.ThreePAppConnection]: {
    model: ThreePAppConnectionModel,
  },
  [ThreePAppSubModels.ThreePAppWebhook]: {
    model: ThreePAppWebhookModel,
  },
  [ThreePAppSubModels.ThreePAppAction]: {
    model: ThreePAppActionModel,
  },
  [ThreePAppSubModels.ThreePAppRemoteProcedure]: {
    model: ThreePAppRemoteProcedureModel,
  },
};

export type ApiModelDataTypes = {
  [ApiModels.DatasetDesign]: DatasetDesign;
  [ApiModels.Dataset]: Dataset;
  [ApiModels.Presentation]: Presentation;
  [ApiModels.PresentationSlide]: PresentationSlide;
  [ApiModels.PresentationSlideSort]: PresentationSlideSort[];
  [ApiModels.DatacardDesign]: DatacardDesign;
  [ApiModels.Datacard]: Datacard;
  [ApiModels.UniversalEvent]: UniversalEvent;
  [ApiModels.AccountUser]: User;
  [ApiModels.UserType]: UserType;
  [ApiModels.ThreePApp]: ThreePApp;
  [ApiModels.Fusion]: Fusion<Node<FusionOperator>, Edge, Viewport>;
  [ApiModels.GFMLFunctions]: GFMLFunction;
  [ApiModels.Folder]: Folder;
  [ApiModels.Tag]: UniversalTag;
  [ApiModels.UniversalNote]: UniversalNote;
  [ApiModels.Gui]: GfGui;
  [ApiModels.GuiParams]: GuiParams;
  [ThreePAppSubModels.ThreePAppConnection]: ThreePAppConnection;
  [ThreePAppSubModels.ThreePAppWebhook]: ThreePAppWebhook;
  [ThreePAppSubModels.ThreePAppAction]: ThreePAppAction;
  [ThreePAppSubModels.ThreePAppRemoteProcedure]: ThreePAppRemoteProcedure;
  [ApiModels.Portal]: Portal;
  [ApiModels.GuiDashboardWidget]: GuiDashboardWidget;
  [ApiModels.GuiDashboardWidgetV2]: DashboardWidget;
  [ApiModels.VectorKnowledgebase]: VectorKnowledgebase;
  [ApiModels.VectorKnowledgebaseTopic]: VectorKnowledgebaseTopic;
  [ApiModels.FinetuneKnowledgebase]: FinetuneKnowledgebase;
  [ApiModels.FinetuneKnowledgebaseTopic]: FinetuneKnowledgebaseTopic;
  [ApiModels.UploadDesign]: UploadDesign;
  [ApiModels.SkillData]: SkillData;
  [ApiModels.Media]: Media;
  [ApiModels.Card]: Card;
  [ApiModels.TransationHistory]: TransactionHistory;
  [ApiModels.Package]: Package;
  [ApiModels.CreditType]: PackageCreditSetting;
  [ApiModels.SeatType]: PackageSeatSetting;
  [ApiModels.TransationHistory]: TransactionHistory;
  [ApiModels.AppConfig]: AppConfig;
  [ApiModels.UserMenuItem]: UserMenuItem;
  [ApiModels.UserMenuTemplate]: UserMenuTemplate;
  [ApiModels.GuiTab]: GuiTab;
  [ApiModels.FormDesign]: FormDesign;
  [ApiModels.SqlQuery]: SqlQuery;
  [ApiModels.Agent]: Agent;
  [ApiModels.AgentSkill]: AgentSkill & { agent_slug?: string };
  [ApiModels.AgentSkillAssc]: AgentSkillAssc;
  [ApiModels.AgentUser]: AgentUser;
  [ApiModels.AgentDatasetRight]: AgentDatasetRight;
  [ApiModels.Document]: AgentDocument & { agent_slug?: string };
  [ApiModels.GuiDocument]: GuiDocument;
  [ApiModels.Job]: Job;
  [ApiModels.JobTask]: JobTask;
  [ApiModels.JobTaskLog]: JobTaskLog;
  [ApiModels.GuiParamGroup]: GuiParamGroup;
  [ApiModels.SSAgent]: Agent;
  [ApiModels.SSAgentSkill]: AgentSkill & { agent_slug?: string };
  [ApiModels.SSAgentSkillAssc]: AgentSkillAssc;
  [ApiModels.SSAgentDocument]: AgentDocument & { agent_slug?: string };
};

export type RequestOptions = {
  query?: Record<string, any>;
  path?: string | undefined;
};
