import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Stack,
  styled,
} from "@mui/material";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import SwitchBar from "components/SwitchBar";
import { DocumentElementType, ListSource } from "enums/Form";
import { ApiModels } from "queries/apiModelMapping";
import useOpenAIModels from "queries/openai/useOpenAIModels";
import useCreateItem from "queries/useCreateItem";
import { FC, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const Container = styled(Stack)({
  maxWidth: "600px",
  width: "100%",
});

const formSchema = z.object({
  agent_name: z.string(),
  agent_model: z.string(),
  agent_slug: z.string(),
  description: z.string().default(""),
  image: z
    .object({
      name: z.string(),
      url: z.string(),
      size: z.number(),
      type: z.string(),
    })
    .optional(),
  instructions: z.string().default(""),
  machine_description: z.string().default(""),
  enable_prompt_fusion: z.boolean().default(false),
  enable_preprocess_fusion: z.boolean().default(false),
  is_active: z.number().default(0),
  enable_code_interpreter: z.boolean().default(false),
  enable_file_search: z.boolean().default(false),
});

type AgentFormType = z.infer<typeof formSchema>;

type AddAgentModalProps = {
  onSubmit: (data: AgentFormType) => void;
  onClose: () => void;
} & Omit<DialogProps, "onSubmit">;

const AddAgentModal: FC<AddAgentModalProps> = (props) => {
  const { onSubmit, onClose, ...dialogProps } = props;

  const form = useForm<AgentFormType>({
    defaultValues: {
      enable_prompt_fusion: false,
      enable_preprocess_fusion: false,
      is_active: 0,
      agent_name: "",
      agent_slug: "",
      enable_code_interpreter: false,
      enable_file_search: false,
    },
    resolver: zodResolver(formSchema),
  });

  const { control, handleSubmit } = form;

  const { data: openaiModels = [] } = useOpenAIModels();
  const { mutateAsync: createAgent, isLoading } = useCreateItem({
    modelName: ApiModels.SSAgent,
  });

  const openaiModelOptions = useMemo(
    () => openaiModels.map((model) => ({ label: model.id, value: model.id })),
    [openaiModels]
  );

  const submitHandler = async (data: AgentFormType) => {
    await createAgent(data);

    onClose();
  };

  return (
    <Dialog
      onClose={(e, r) => {
        console.log(e, r);
      }}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Add SS Agent</DialogTitle>
      <Scrollbar className="form-scroller">
        <DialogContent>
          <FormProvider {...form}>
            <Stack alignItems="center">
              <Container gap={2}>
                <FormField
                  label="Agent Name"
                  type={DocumentElementType.TextField}
                  formControl={{ control, name: "agent_name" }}
                />
                <FormField
                  label="Agent Model"
                  type={DocumentElementType.Select}
                  formControl={{ control, name: "agent_model" }}
                  fieldExtras={{
                    field: {
                      list_items: openaiModelOptions,
                      list_source: ListSource.Hardcoded,
                      free_solo: true,
                    },
                  }}
                />
                <FormField
                  label="Agent Description"
                  type={DocumentElementType.TextArea}
                  formControl={{ control, name: "description" }}
                />
                <FormField
                  label="Agent Slug"
                  type={DocumentElementType.TextField}
                  formControl={{ control, name: "agent_slug" }}
                />
                <FormField
                  label="Image File"
                  type={DocumentElementType.Image}
                  formControl={{ name: "image", control }}
                />
                <Divider />
                <FormField
                  label="Instructions For Agent"
                  type={DocumentElementType.TextArea}
                  formControl={{ control, name: "instructions" }}
                />
                <FormField
                  label="Machine Description"
                  type={DocumentElementType.TextArea}
                  formControl={{ control, name: "machine_description" }}
                />
                <Divider />
                <FormField
                  formControl={{ name: "enable_prompt_fusion", control }}
                >
                  {({ value, onChange }) => (
                    <SwitchBar
                      sx={{ flex: 1 }}
                      value={value as boolean}
                      onChange={(v) => onChange?.(v)}
                      label="Enable Init Fusion"
                    />
                  )}
                </FormField>
                <FormField
                  formControl={{ name: "enable_preprocess_fusion", control }}
                >
                  {({ value, onChange }) => (
                    <SwitchBar
                      sx={{ flex: 1 }}
                      value={value as boolean}
                      onChange={(v) => onChange?.(v)}
                      label="Enable Preprocess Fusion"
                    />
                  )}
                </FormField>
                <FormField formControl={{ name: "is_active", control }}>
                  {({ value, onChange }) => (
                    <SwitchBar
                      label="Agent is Active"
                      value={value as boolean}
                      onChange={(v) => onChange?.(v ? 1 : 0)}
                    />
                  )}
                </FormField>
                <FormField
                  formControl={{ name: "enable_code_interpreter", control }}
                >
                  {({ value, onChange }) => (
                    <SwitchBar
                      label="Enable Code Interpreter"
                      value={value as boolean}
                      onChange={(v) => onChange?.(v)}
                    />
                  )}
                </FormField>
                <FormField
                  formControl={{ name: "enable_file_search", control }}
                >
                  {({ value, onChange }) => (
                    <SwitchBar
                      label="Enable File Search"
                      value={value as boolean}
                      onChange={(v) => onChange?.(v)}
                    />
                  )}
                </FormField>
              </Container>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Scrollbar>
      <DialogActions>
        <LoadingButton onClick={onClose}>Cancel</LoadingButton>
        <LoadingButton
          onClick={handleSubmit(submitHandler, (error) => {
            console.log(error);
          })}
          variant="contained"
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddAgentModal;
