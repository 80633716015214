import { ArrowBack } from "@mui/icons-material";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { DocumentElementType } from "enums/Form";
import { GuiType } from "enums/gui";
import { ApiModels } from "queries/apiModelMapping";
import { useGuiTabs } from "queries/gui/useGuiTabs";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import React, { useMemo } from "react";
import useSystemLayoutStore from "store";
import { normalizeObjectForAPI } from "utils";
import IncludedFieldsSelector from "views/app-view/gui-dataset/components/TabCreator/IncludedFieldsSelector";

const FieldsSelection: React.FC<{
  gui: GfGui;
  indexKey: "form_fields" | "search_fields";
  onClickBack: () => void;
  onEditFilterRules?: (fieldId: DataField) => void;
  onEditImageSetting?: (fieldId: DataField) => void;
}> = ({
  gui,
  onClickBack,
  onEditFilterRules,
  onEditImageSetting,
  indexKey = "search_fields",
}) => {
  const selectedTab = useSystemLayoutStore.useSelectedTopBarTab();

  const { data: guiTabs = [] } = useGuiTabs(gui?.slug);

  const guiTab = useMemo(() => {
    if (selectedTab != null && guiTabs) {
      return guiTabs?.[Number(selectedTab)];
    }
  }, [guiTabs, selectedTab]);

  const datasetDesignSlug =
    guiTab?.tab_type === GuiType.RecordList ||
    guiTab?.tab_type === GuiType.Reviewer
      ? guiTab.dataset_design_slug
      : undefined;

  const includedFields = useMemo(() => {
    if (guiTab?.tab_type === GuiType.Reviewer) {
      return indexKey === "form_fields" ? guiTab.form_fields || [] : [];
    }

    return guiTab?.tab_type === GuiType.RecordList
      ? guiTab?.[indexKey] || []
      : [];
  }, [guiTab, indexKey]);

  const { data: datasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: datasetDesignSlug,
    queryOptions: { enabled: false },
  });
  const { mutate: updateGuiTab } = useUpdateItem({
    modelName: ApiModels.GuiTab,
  });

  const handleUpdateFieldsData = (
    items: string[],
    metadata: Record<string, unknown>
  ) => {
    if (guiTab) {
      updateGuiTab(
        {
          slug: guiTab.slug,
          data: normalizeObjectForAPI(
            {
              ...guiTab,
              [indexKey]: items,
              field_metadata: metadata,
            },
            ["gui_slug", "parent_tab_slug", "tab_type"]
          ),
        },
        {
          onSuccess: () => {
            console.log("AccountUser edit success");
          },
        }
      );
    }
  };

  const handleFieldEdit = (d: DataField) => {
    if (d.type === DocumentElementType.Image) {
      onEditImageSetting?.(d);
    } else {
      onEditFilterRules?.(d);
    }
  };

  return (
    <React.Fragment>
      <SidebarSectionWrap
        title="Back to Dataset"
        rightIcon={false}
        leftIcon={<ArrowBack />}
        onLeftIconClick={onClickBack}
      >
        <IncludedFieldsSelector
          fields={datasetDesign?.fields.fields ?? []}
          includedFields={includedFields}
          fieldMetadata={guiTab?.field_metadata}
          onFieldEdit={handleFieldEdit}
          onUpdateData={handleUpdateFieldsData}
        />
      </SidebarSectionWrap>
    </React.Fragment>
  );
};

export default FieldsSelection;
