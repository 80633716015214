import {
  DeleteOutline,
  EditOutlined,
  MoreHoriz,
  SettingsOutlined,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import ActionMenu from "components/ActionMenu";
import useGuiTabEditorController from "hooks/useGuiTabEditorController";
import { ApiModels } from "queries/apiModelMapping";
import useDeleteItem from "queries/useDeleteItem";
import { FC, useCallback } from "react";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";

type TabActionMenuProps = {
  tab: GuiTab;
};

const TabActionMenu: FC<TabActionMenuProps> = (props) => {
  const { tab } = props;

  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();
  const state = useGuiTabEditorController();
  const {
    open: openEditTabDrawer,
    setSelectedTab,
    setIsOpenRenameModal,
  } = state;

  const { mutate: deleteGuiTab } = useDeleteItem({
    modelName: ApiModels.GuiTab,
  });

  const handleDeleteTab = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      deleteGuiTab(
        { slug: tab.slug },
        {
          onSuccess() {
            resolve();
          },
          onError() {
            reject();
          },
        }
      );
    });
  }, [deleteGuiTab, tab]);

  if (!editModeEnabled) {
    return <></>;
  }

  return (
    <>
      <ActionMenu
        menuItems={[
          {
            label: "Rename",
            icon: <EditOutlined />,
            value: "rename",
          },
          {
            label: "Settings",
            icon: <SettingsOutlined />,
            value: "settings",
          },
          {
            label: "Delete",
            icon: <DeleteOutline />,
            value: "delete",
          },
        ]}
        onItemClick={(key) => {
          if (key === "rename") {
            setSelectedTab({ value: tab.tab_name });
            setIsOpenRenameModal(true);
          } else if (key === "delete") {
            handleDeleteTab();
          } else if (key === "settings") {
            setSelectedTab({ value: tab.tab_name });
            openEditTabDrawer();
          }
        }}
      >
        <IconButton size="small">
          <MoreHoriz fontSize="small" />
        </IconButton>
      </ActionMenu>
    </>
  );
};

export default TabActionMenu;
