import { Avatar, AvatarGroup } from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";
import AccountUserDataProvider from "components/DataProviders/AccountUserDataProvider";
import Cell from "components/DatasetTable/Cell";
import { FC, memo } from "react";
import { getNameInitial, stringToColor } from "utils";

const DISPLAY_LIMIT = 4;

export const getAvatar = (name: string, image?: string) => (
  <Avatar
    alt={name}
    src={image}
    sx={{
      bgcolor: stringToColor(name),
      width: 28,
      height: 28,
      fontSize: 16,
    }}
  >
    {getNameInitial(name)}
  </Avatar>
);

type UserCellRendererProps = {
  field: DataField;
} & CustomCellRendererProps;

const UserCellRenderer: FC<UserCellRendererProps> = memo((props) => {
  const { field } = props;

  const value: string[] = field.multi_user
    ? props.value ?? []
    : props.value
    ? [props.value]
    : [];

  const userSlugs = value.filter(Boolean);

  const surplus = userSlugs.length - DISPLAY_LIMIT;

  return (
    <Cell
      sx={{
        justifyContent: "space-between",
      }}
      isLoading={!props.data}
    >
      <AccountUserDataProvider>
        {(users) => {
          const filteredUsers =
            users?.filter((user) => userSlugs.includes(user.slug)) ?? [];
          if (field.render_user_name) {
            return filteredUsers
              .map((user) =>
                `${user?.first_name ?? ""} ${user?.last_name ?? ""}`.trim()
              )
              .join(", ");
          }
          return (
            <>
              <AvatarGroup
                sx={{
                  ".MuiAvatar-root": {
                    height: 28,
                    width: 28,
                    fontSize: 16,
                  },
                }}
                max={DISPLAY_LIMIT}
              >
                {filteredUsers.slice(0, DISPLAY_LIMIT)?.map((currentUser) => {
                  const name = `${currentUser?.first_name} ${currentUser?.last_name}`;

                  return getAvatar(name, currentUser?.image?.url);
                })}
              </AvatarGroup>
              {surplus > 0 && (
                <Avatar
                  alt="surplus"
                  sx={{
                    width: 24,
                    height: 24,
                    fontSize: 14,
                  }}
                  variant="rounded"
                >
                  {surplus > 5 ? "+5" : surplus}
                </Avatar>
              )}
            </>
          );
        }}
      </AccountUserDataProvider>
    </Cell>
  );
});

export default UserCellRenderer;
