import { Box, styled } from "@mui/material";
import Scrollbar from "components/Scrollbar";
import AnimationLayout, { Config } from "layouts/AnimationLayout";
import { FC, useCallback, useState } from "react";
import useAgentChatStore from "store/stores/agent-chat";
import JobDetails from "./JobDetails";
import TaskLogs from "./TaskLogs";
import WidgetList from "./WidgetList";

const Container = styled(Box)(({ theme, hidden }) => ({
  height: "100%",
  background: theme.palette.background.LeftNavBody,
  maxWidth: "420px",
  width: hidden ? "0px" : "100%",
  transition: "width 0.2s ease",
  borderRadius: "6px 0 0 0",
}));

const RightBar: FC = () => {
  const isScratchpadActive = useAgentChatStore.useIsScratchpadActive();
  const [selectedJob, setSelectedJob] = useState<Job>();
  const [selectedTask, setSelectedTask] = useState<JobTask>();

  const getComponents: Config["getComponents"] = useCallback(
    (gotoComponent, goBack) => ({
      main: (
        <WidgetList
          onJobClick={(job) => {
            setSelectedJob(job);
            gotoComponent({ name: "job-details", id: job.slug });
          }}
        />
      ),
      "job-details": (
        <JobDetails
          job={selectedJob}
          onBack={goBack}
          onViewLogsClick={(task) => {
            setSelectedTask(task);
            gotoComponent({ name: "task-logs", id: task.slug });
          }}
        />
      ),
      "task-logs": (
        <TaskLogs job={selectedJob} task={selectedTask} onBack={goBack} />
      ),
    }),
    [selectedJob, selectedTask]
  );

  return (
    <Container hidden={isScratchpadActive}>
      <Scrollbar>
        <AnimationLayout
          config={{
            initialComponent: "main",
            getComponents,
          }}
        />
      </Scrollbar>
    </Container>
  );
};

export default RightBar;
