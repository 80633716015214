import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, Stack, styled } from "@mui/material";
import FormField from "components/FormField";
import { DocumentElementType, ListSource } from "enums/Form";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { v4 } from "uuid";
import { z } from "zod";

const Container = styled(Box)({
  padding: "16px",
});

const typeOptions = [
  {
    label: "String",
    value: "string",
  },
  {
    label: "Number",
    value: "number",
  },
  {
    label: "Boolean",
    value: "boolean",
  },
  {
    label: "Array",
    value: "array",
  },
  {
    label: "Object",
    value: "object",
  },
];

type ParamEditorProps = {
  param?: Record<string, unknown>;
  skill: AgentSkill;
  onClose(): void;
  updateParamCallback?(params: AgentSkillParameter[]): void;
};

const formSchema = z.object({
  type: z.string().default("string"),
  name: z.string(),
  description: z.string().optional().default(""),
  items: z.record(z.string(), z.unknown()).optional(),
  properties: z.record(z.string(), z.unknown()).optional(),
  default_value: z.string().optional(),
  is_required: z.boolean().default(false),
});

type ParamFormType = z.infer<typeof formSchema>;

const ParamEditor: FC<ParamEditorProps> = (props) => {
  const { param, skill, updateParamCallback, onClose } = props;

  const { mutateAsync: updateAgentSkill, isLoading } = useUpdateItem({
    modelName: ApiModels.AgentSkill,
  });

  const form = useForm<ParamFormType>({
    resolver: zodResolver(formSchema),
  });

  const {
    formState: { dirtyFields, errors },
    handleSubmit,
    control,
    reset,
    watch,
  } = form;

  useEffect(() => {
    if (param) {
      reset(param);
    }
  }, [param, reset]);

  const type = watch("type");

  const onSubmit = async (data: ParamFormType) => {
    if (param) {
      const params =
        skill.parameters?.map((parameter) =>
          parameter.id === param.id ? { ...parameter, ...data } : parameter
        ) ?? [];
      await updateAgentSkill({
        slug: skill.slug,
        data: {
          parameters: params,
        },
      });

      updateParamCallback?.(params);
    } else {
      const params = [...(skill.parameters ?? []), { ...data, id: v4() }];
      await updateAgentSkill({
        slug: skill.slug,
        data: {
          parameters: params,
        },
      });

      updateParamCallback?.(params);
    }

    onClose();
  };

  return (
    <Container>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit, (e) => {
            console.log(e);
          })}
        >
          <Stack direction="column" gap={2}>
            <FormField
              label="Parameter Type"
              error={dirtyFields.type ? errors.type : undefined}
              formControl={{ control, name: "type" }}
              type={DocumentElementType.Select}
              fieldExtras={{
                field: {
                  list_source: ListSource.Hardcoded,
                  list_items: typeOptions,
                },
              }}
            />
            {type === "array" && (
              <FormField
                label="Items Schema"
                formControl={{
                  control,
                  name: "items",
                }}
                type={DocumentElementType.CodeEditor}
              />
            )}
            {type === "object" && (
              <FormField
                label="Object Schema"
                formControl={{
                  control,
                  name: "properties",
                }}
                type={DocumentElementType.CodeEditor}
              />
            )}
            <FormField
              label="Parameter Name"
              error={dirtyFields.name ? errors.name : undefined}
              formControl={{ control, name: "name" }}
              type={DocumentElementType.TextField}
            />
            <FormField
              label="Parameter Description"
              error={
                (dirtyFields.description
                  ? errors.description
                  : undefined) as any
              }
              formControl={{ control, name: "description" }}
              type={DocumentElementType.TextArea}
            />
            <FormField
              label="Default Value"
              error={
                dirtyFields.default_value ? errors.default_value : undefined
              }
              formControl={{ control, name: "default_value" }}
              type={DocumentElementType.TextField}
            />
            <FormField
              label="Required"
              error={dirtyFields.is_required ? errors.is_required : undefined}
              formControl={{ control, name: "is_required" }}
            >
              {({ value, onChange }) => (
                <Checkbox
                  checked={!!value}
                  onChange={(_, checked) => onChange?.(checked)}
                  sx={{ width: "fit-content", p: 0 }}
                />
              )}
            </FormField>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1.5}
            sx={{ mt: 2 }}
          >
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                border: "none",
                color: "#fff",
                background: (theme) => theme.palette.background.GF10,
              }}
              onClick={(e) => onClose()}
              fullWidth
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                borderColor: "#fff",
                boxShadow: "none",
              }}
              fullWidth
              loading={isLoading}
            >
              Save Changes
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </Container>
  );
};

export default ParamEditor;
