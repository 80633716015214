import { Cancel } from "@mui/icons-material";
import InsertDriveFileOutlined from "@mui/icons-material/InsertDriveFileOutlined";
import { Avatar, Box, Stack, styled } from "@mui/material";
import Spin from "components/Spin";
import { FC, useMemo } from "react";
import { AttachmentFile } from "./InputAttachments";

const AttachmentContainer = styled(Box)({
  position: "relative",

  ".icon-attachment-remove": {
    opacity: 0,
    cursor: "pointer",
    transition: "opacity 0.1s ease",
  },

  "&:hover": {
    ".icon-attachment-remove": {
      opacity: 1,
    },
  },
});

const AttachmentContent = styled(Box)({});

const FileAttachmentContainer = styled(Box)({
  height: "56px",
  width: "300px",
  background: "#212121",
  border: "1px solid #ffffff1a",
  borderRadius: "4px",
});

type InputAttachmentProps = {
  file: AttachmentFile;
  onRemove(id: string): void;
};

const InputAttachmentSwitch: FC<InputAttachmentProps> = (props) => {
  const { file } = props;

  const type = useMemo(() => {
    return file.type.startsWith("image/") ? "image" : "file";
  }, [file.type]);

  const url = useMemo(() => {
    if (type === "file" || !file.file) {
      return;
    }

    return URL.createObjectURL(file.file);
  }, [file, type]);

  switch (type) {
    case "image":
      return (
        <Avatar
          src={url || "https://mui.com/static/images/avatar/1.jpg"}
          variant="rounded"
          sx={{
            width: "56px",
            height: "56px",
          }}
        />
      );
    case "file":
      return (
        <FileAttachmentContainer>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            height="100%"
            padding="8px"
          >
            <InsertDriveFileOutlined
              sx={{
                height: "32px",
                width: "32px",
                background: "#FF5588",
                color: "#ffffff",
                borderRadius: "4px",
              }}
            />
            <Stack direction="column">
              <Box
                maxWidth="240px"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                fontWeight={600}
                component="div"
              >
                {file.name}
              </Box>
              <Box
                maxWidth="240px"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                color="#9b9b9b"
              >
                File
              </Box>
            </Stack>
          </Stack>
        </FileAttachmentContainer>
      );

    default:
      return <></>;
  }
};

const InputAttachment: FC<InputAttachmentProps> = (props) => {
  const { file, onRemove } = props;

  return (
    <AttachmentContainer>
      <Spin
        spinning={file.status === "uploading"}
        iconProps={{ size: 28, disableShrink: true, sx: { color: "#ffffff" } }}
      >
        <Cancel
          sx={{
            position: "absolute",
            top: "0px",
            right: "-8px",
            fontSize: "16px",
            color: "#000000",
            background: "#ffffff",
            borderRadius: "50%",
            zIndex: 1,
          }}
          className="icon-attachment-remove"
          onClick={() => onRemove(file.id)}
        />
        <AttachmentContent className="attachment-content">
          <InputAttachmentSwitch {...props} />
        </AttachmentContent>
      </Spin>
    </AttachmentContainer>
  );
};

export default InputAttachment;
