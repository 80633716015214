import {
	Box,
	Tooltip,
	TooltipProps,
	Typography,
	styled,
	tooltipClasses,
} from "@mui/material";
import { DecoratorNode, LexicalNode, SerializedLexicalNode } from "lexical";

export type SerializedTagNode = SerializedLexicalNode & {
	id: string;
	text: string;
	tagSlug: string;
	tagType: TagData["type"];
};

export type TagData = {
	type: "fn" | "var" | "fn_close" | "fn_sep";
	label: string;
	slug: string;
	error?: boolean;
};

const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.error.main,
		boxShadow: theme.shadows[1],
		fontSize: 12,
		padding: "0 4px",
	},
	[`& .${tooltipClasses.arrow}`]: {
		backgroundColor: theme.palette.error.main,
	},
}));

export class TagNode extends DecoratorNode<JSX.Element> {
	__id: string;
	__text: string = "";
	__tagSlug: TagData["slug"];
	__tagType: TagData["type"];
	__error: boolean = false;

	static getType(): string {
		return "tag";
	}

	static clone(node: TagNode) {
		return new TagNode(
			node.__id,
			{ label: node.__text, slug: node.__tagSlug, type: node.__tagType },
			node.__key
		);
	}

	constructor(id: string, tagData: TagData, key?: string) {
		super(key);
		this.__id = id;
		this.__text = tagData.label;
		this.__tagSlug = tagData.slug;
		this.__tagType = tagData.type;
		this.__error = tagData.error || false;
	}

	exportJSON(): SerializedTagNode {
		return {
			type: this.getType(),
			version: 1,
			id: this.__id,
			text: this.__text,
			tagSlug: this.__tagSlug,
			tagType: this.__tagType,
		};
	}

	createDOM(): HTMLElement {
		return document.createElement("span");
	}

	updateDOM(): false {
		return false;
	}

	isIsolated() {
		return true;
	}

	isInline() {
		return true;
	}

	isKeyboardSelectable() {
		return true;
	}

	decorate(): JSX.Element {
		return (
			<Box sx={{ px: "2px", display: "inline-block", cursor: "grab" }}>
				<TooltipStyled
					title="Error: Operator Removed"
					disableFocusListener={!this.__error}
					disableHoverListener={!this.__error}
					disableInteractive={!this.__error}
					disableTouchListener={!this.__error}
					placement="top"
					arrow
				>
					<Typography
						sx={{
							background: (theme) =>
								this.__error
									? "transparent"
									: this.__tagType === "var"
									? theme.palette.primary.main
									: theme.palette.orange.GFOrange,
							px: "6px",
							py: 0,
							fontSize: "14px",
							mx: "2px",
							borderRadius: "4px",
							borderWidth: this.__error ? "1px" : undefined,
							borderStyle: this.__error ? "solid" : undefined,
							borderColor: (theme) =>
								this.__error ? theme.palette.error.main : undefined,
						}}
					>
						{this.__text}
					</Typography>
				</TooltipStyled>
			</Box>
		);
	}
}

export function $isTagNode(node: LexicalNode) {
	return node instanceof TagNode;
}

export function $createTagNode(id: string, tag: TagData, key?: string) {
	return new TagNode(id, tag, key);
}
