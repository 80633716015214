import CheckCircle from "@mui/icons-material/CheckCircle";
import { Stack, Typography } from "@mui/material";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { FC } from "react";
import useAgentChatStore from "store/stores/agent-chat";
import ListWidget from "./ListWidget";

type JobListProps = {
  onJobClick(job: Job): void;
};

const JobList: FC<JobListProps> = (props) => {
  const { onJobClick } = props;

  const thread = useAgentChatStore.useSelectedThread();

  const { data: jobs = [] } = useListItems({
    modelName: ApiModels.Job,
    requestOptions: { query: { thread_id: thread?.id } },
    queryKey: [ApiModels.Job, thread?.id],
  });

  return (
    <ListWidget
      title="Jobs"
      items={jobs}
      itemRenderer={(item) => (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          // height="52px"
          px={1}
          pb={0.5}
          onClick={() => onJobClick(item)}
        >
          <Stack direction="column">
            <Typography
              fontSize={13}
              lineHeight="30px"
              color="text.primary"
              fontWeight={500}
            >
              {item.title}
            </Typography>
            <Typography
              fontSize={12}
              lineHeight="20px"
              fontWeight={400}
              color="text.secondary"
            >
              Updated: &nbsp;&nbsp;
              {new Date(item.updated_at ?? item.created_at).toLocaleString(
                "en-us"
              )}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="flex-end" height="50px">
            <Stack direction="row" alignItems="center" gap={1}>
              <CheckCircle sx={{ color: "#5a9230" }} />
              <Typography fontSize={14} fontWeight={400}>
                {item.slug}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    />
  );
};

export default JobList;
