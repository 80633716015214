import { Box, Chip, Stack, Typography, useTheme } from "@mui/material";
import Scrollbar from "components/Scrollbar";
import { FC, useMemo } from "react";
import { mapDocumentFieldsToInterface } from "views/app-view/flow-designer/components/ParamFields/ParamMapper";

type TagListProps = {
  params: MappableParameter[];
  label: string;
  slugPrefix: string;
  labelPrefix: string;
  onTagClick(param: MappableParameter): void;
};

const TagList: FC<TagListProps> = (props) => {
  const { params, label, labelPrefix, slugPrefix, onTagClick } = props;
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {label}
      </Typography>
      <Stack alignItems="flex-start" gap={1}>
        {params.map((param) => (
          <Chip
            key={param.name}
            sx={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: "4px",
              fontSize: "14px",
              height: "24px",
              ".MuiChip-label": { py: 0, px: "6px" },
            }}
            onClick={() =>
              onTagClick?.({
                ...param,
                color: theme.palette.primary.main,
                slug: `${slugPrefix}.${param.name}`,
                tagLabel: `${labelPrefix}.${param.label}`,
                operatorStack: [],
              })
            }
            label={`${param.label || param.name}`}
          />
        ))}
      </Stack>
    </Box>
  );
};

type TagPopperContentProps = {
  onTagClick(param: MappableParameter): void;
  onClose(): void;
  fields?: DataField[];
  guiParams?: GuiParams[];
};

const TagPopperContent: FC<TagPopperContentProps> = (props) => {
  const { fields = [], guiParams = [], onTagClick } = props;

  const interfaceParams = useMemo(
    () => mapDocumentFieldsToInterface(fields),
    [fields]
  );

  const guiPramsInterface = useMemo(
    () =>
      guiParams
        .filter((param) => param.is_active)
        .map<MappableParameter>((param) => ({
          name: param.param_slug,
          type: param.type,
          required: param.is_required,
          label: param.title,
        })),
    [guiParams]
  );

  return (
    <Scrollbar autoHeight autoHeightMax={400}>
      <Box sx={{ minHeight: "300px", p: 2 }}>
        {interfaceParams.length > 0 && (
          <TagList
            params={interfaceParams}
            label="Parent Record Fields"
            slugPrefix="parent_record"
            labelPrefix="Parent Record"
            onTagClick={onTagClick}
          />
        )}
        {guiParams.length > 0 && (
          <TagList
            params={guiPramsInterface}
            label="Gui Params"
            slugPrefix="gui_params"
            labelPrefix="Gui Params"
            onTagClick={onTagClick}
          />
        )}
      </Box>
    </Scrollbar>
  );
};

export default TagPopperContent;
