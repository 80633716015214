import { CustomCellEditorProps } from "ag-grid-react";
import DatasetDesignPicker from "components/DatasetDesignPicker";
import { FC, memo } from "react";

const DatasetDesignCellEditor: FC<CustomCellEditorProps> = memo((props) => (
  <DatasetDesignPicker
    value={props.value}
    onChange={(v) => props.onValueChange?.(v)}
  />
));

export default DatasetDesignCellEditor;
