import {
  ButtonProps,
  IconButton,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import React from "react";

export const TopBarIconButton = styled(IconButton)(({ theme }) => ({
  border: "none",
  borderRadius: "6px",
  height: "30px",
  color: "#fff",
  background: theme.palette.background.GF10,

  "&:hover": {
    background: theme.palette.primary.main,
  },
}));

const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    padding: "0px 8px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
}));

type IconButtonWithTooltipProps = {
  tooltipProps?: Omit<TooltipProps, "children">;
} & ButtonProps;

const IconButtonWithTooltip: React.FC<IconButtonWithTooltipProps> = (props) => {
  const { tooltipProps, ...buttonProps } = props;

  return (
    <TooltipStyled arrow {...tooltipProps} title={tooltipProps?.title || ""}>
      <TopBarIconButton {...buttonProps} />
    </TooltipStyled>
  );
};

export default IconButtonWithTooltip;
