import Close from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import DynamicCreateForm, {
  FormRefAttribute,
} from "components/Form/DynamicCreateForm";
import { transformFieldsOptions } from "components/Form/helper";
import Scrollbar from "components/Scrollbar";
import Spin from "components/Spin";
import { DocumentElementType } from "enums/Form";
import useAccountSlug from "hooks/useAccountSlug";
import useCurrentUser from "hooks/useCurrentUser";
import FusionModel from "models/Fusion";
import { useSnackbar } from "notistack";
import useActionFields from "queries/formDesign/useActionFields";
import { FC, useMemo, useRef } from "react";
import useFusionAction from "store/stores/fusion-action";

type DataFieldExtended = Partial<
  Omit<DataField, "fields"> & { [key: string]: unknown }
>;

const generalFields: DataFieldExtended[] = [
  { type: "label", name: "label", label: "General Setting" },
];

type ActionFormModalProps = {};

const ActionFormModal: FC<ActionFormModalProps> = () => {
  const user = useCurrentUser();
  const accountSlug = useAccountSlug();

  const { enqueueSnackbar } = useSnackbar();

  const formRef = useRef<FormRefAttribute | undefined>();

  const open = useFusionAction.useActionFormOpen();
  const setOpen = useFusionAction.useSetActionFormOpen();
  const action = useFusionAction.useFormAction();
  const setAction = useFusionAction.useSetFormAction();
  const actionData = useFusionAction.useActionData();

  const { data: actionFields = [], isFetching } = useActionFields(action?.id);

  const formFields = useMemo(() => {
    return actionFields.map((field) => ({
      ...field,
      slug: field.field_slug,
      ...field.metadata,
    }));
  }, [actionFields]);

  const { fields, defaultValues } = useMemo(() => {
    if (!open) return { fields: [], defaultValues: {} };

    let defaultValues: Record<string, unknown> = {};
    formFields?.forEach((f) => {
      if (f.type === DocumentElementType.Date && f.use_current) return;
      if (f.default_value) defaultValues[`fields.${f.slug}`] = f.default_value;
    });
    const fields = generalFields.concat(
      ...transformFieldsOptions(formFields, {
        prefixName: "fields",
      })
    );

    return { fields, defaultValues };
  }, [open, formFields]);

  const handleSubmit = (data: Record<string, unknown>) => {
    if (action && accountSlug) {
      enqueueSnackbar({ variant: "success", message: "Fusion Action Called!" });
      FusionModel.runFusion(action.fusion_slug, {
        user_id: user.slug,
        account_id: accountSlug,
        popup_variables: {
          popup_variables: {
            form_data: data?.fields ?? {},
            data: actionData,
          },
        },
      }).catch(() => {
        enqueueSnackbar({ variant: "error", message: "Fusion Action Failed!" });
      });
    }

    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setAction(undefined);
  };

  return (
    <Spin spinning={isFetching}>
      <Dialog
        onClose={(e, r) => {
          handleClose();
        }}
        disableEscapeKeyDown
        scroll="body"
        fullWidth
        maxWidth={false}
        open={open}
      >
        <DialogTitle>
          <Box>{action?.action_title}</Box>
          <Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        <Scrollbar className="form-scroller">
          <DialogContent>
            <Box>
              {fields && (
                <DynamicCreateForm
                  name={"fields"}
                  fields={fields as any}
                  ref={formRef}
                  submitButton={false}
                  defaultValues={defaultValues}
                />
              )}
            </Box>
          </DialogContent>
        </Scrollbar>
        <DialogActions>
          <LoadingButton
            onClick={handleClose}
            startIcon={""}
            loadingPosition="start"
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            onClick={(e) => {
              formRef.current?.handleSubmit(handleSubmit)(e);
            }}
            variant="contained"
            startIcon={""}
            loadingPosition="start"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Spin>
  );
};

export default ActionFormModal;
