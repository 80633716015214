import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, Stack, styled, useTheme } from "@mui/material";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { SortableList } from "components/SortableList";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProfileCard from "stories/CompoundComponent/ProfileCard/ProfileCard";
import Swal from "sweetalert2";

const Container = styled(Box)(({ theme }) => {
  return {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    height: "100%",
    // width: "420px",

    [`${theme.breakpoints.down("sm")}`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
});

const ShortCodesListing: FC<{
  onAdd?(): void;
  onFieldClick?(field: ShortCode): void;
  shortCodes?: ShortCode[];
}> = (props) => {
  const { onAdd, onFieldClick, shortCodes } = props;

  const { querySlug } = useParams<{ querySlug: string }>();

  const [items, setItems] = useState<(ShortCode & { id: string })[]>([]);

  const theme = useTheme();

  const { mutate: updateSqlQuery } = useUpdateItem({
    modelName: ApiModels.SqlQuery,
  });

  useEffect(() => {
    setItems(shortCodes?.map((s) => ({ ...s, id: s.slug })) || []);
  }, [shortCodes]);

  return (
    <Container>
      <SidebarSectionWrap
        title="Short Codes"
        onRightIconClick={() => onAdd?.()}
      >
        <SortableList
          items={items}
          onChange={(updatedItems) => {
            setItems(updatedItems);
            if (querySlug) {
              updateSqlQuery({
                slug: querySlug,
                data: {
                  short_codes: updatedItems.map((i) => ({
                    ...i,
                    id: undefined,
                  })),
                },
              });
            }
          }}
          renderItem={(item) => {
            return (
              <SortableList.Item id={item.id} handle>
                <ProfileCard
                  options={{
                    draggable: true,
                    switcher: false,
                  }}
                  rightIcon={
                    <Stack direction="row" spacing={1}>
                      <Box className="edit-icon">
                        <DeleteOutline
                          sx={{ color: "grey.500" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (querySlug) {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                              }).then(async (result) => {
                                if (result.isConfirmed) {
                                  const filtered = items.filter(
                                    (f) => f.id !== item.id
                                  );
                                  updateSqlQuery({
                                    slug: querySlug,
                                    data: {
                                      short_codes: filtered.map((i) => ({
                                        ...i,
                                        id: undefined,
                                      })),
                                    },
                                  });
                                  setItems(filtered);
                                }
                              });
                            }
                          }}
                        />
                      </Box>
                      <Box className="edit-icon">
                        <EditOutlined sx={{ color: "grey.500" }} />
                      </Box>
                    </Stack>
                  }
                  title={item.title}
                  sx={{
                    background: theme.palette.background.GFRightNavForeground,
                    height: 48,
                    ".MuiTypography-subtitle1": {
                      margin: "0 0 2px",
                    },
                    ".DragHandle": {
                      lineHeight: "1",
                      width: "20px",
                      height: "20px",
                      margin: "0 6px 0 -6px",
                      color: theme.palette.background.GF20,

                      "&:hover": {
                        color: theme.palette.background.GF50,

                        path: {
                          fill: theme.palette.background.GF50,
                        },
                      },

                      path: {
                        fill: theme.palette.background.GF20,
                        transition: "all 0.4s ease",
                      },

                      svg: {
                        width: "100%",
                        height: "auto",
                        display: "block",
                      },
                    },

                    ".card-inner-content": {
                      gap: "2px",
                    },
                  }}
                  onClick={() => onFieldClick?.(item)}
                />
              </SortableList.Item>
            );
          }}
        />
      </SidebarSectionWrap>
    </Container>
  );
};

export default ShortCodesListing;
