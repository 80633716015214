import { Box, Stack, styled } from "@mui/material";
import Draggable from "components/Draggable";
import ChatWindow from "components/FloatingChatWindow/ChatWindow";
import { Resizable } from "re-resizable";
import { FC, useState } from "react";
import useGFChatWindow from "store/stores/gf-chat-window";

const ChatWindowContainer = styled(Box)<{
  top: number;
  left: number;
  height: string;
}>((props) => ({
  width: "fit-content",
  background: "#ffffff",
  pointerEvents: "all",
  position: "relative",
  outline: "none",
  height: props.height,
  top: props.top,
  left: props.left,
}));

const draggableId = "chat-draggable";

const ResizeHandle = () => (
  <Stack
    direction="column"
    alignItems="center"
    sx={{ transform: "rotate(-45deg)" }}
  >
    <Box
      sx={{
        borderBottom: "1px solid #8d8c8e",
        width: "15px",
        height: "3px",
      }}
    />
    <Box
      sx={{
        borderBottom: "1px solid #8d8c8e",
        width: "9px",
        height: "3px",
      }}
    />
    <Box
      sx={{
        borderBottom: "1px solid #8d8c8e",
        width: "5px",
        height: "3px",
      }}
    />
  </Stack>
);

type ChatDraggableContainerProps = {
  left: number;
  top: number;
};

const ChatDraggableContainer: FC<ChatDraggableContainerProps> = (props) => {
  const { left, top } = props;

  const [collapsed, setCollapsed] = useState(false);

  const size = useGFChatWindow.useSize();
  const setSize = useGFChatWindow.useSetSize();

  return (
    <Draggable
      id={draggableId}
      style={{ opacity: 1, borderRadius: "4px", overflow: "hidden" }}
      handle
    >
      <ChatWindowContainer
        top={top}
        left={left}
        height={collapsed ? "45px" : "auto"}
      >
        <Resizable
          className="chat-resizable"
          minWidth={300}
          minHeight={90}
          enable={{ bottomRight: true }}
          size={size}
          handleComponent={{
            bottomRight: <ResizeHandle />,
          }}
          onResizeStop={(e, direction, ref, d) => {
            setSize({
              width: size.width + d.width,
              height: size.height + d.height,
            });
          }}
          handleStyles={{
            bottomRight: {
              zIndex: 1,
              right: "-3px",
              bottom: "-9px",
            },
          }}
        >
          <ChatWindow
            draggableId={draggableId}
            collapsed={collapsed}
            onCollapse={() => setCollapsed((prev) => !prev)}
          />
        </Resizable>
      </ChatWindowContainer>
    </Draggable>
  );
};

export default ChatDraggableContainer;
