import DeleteOutline from "@mui/icons-material/DeleteOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined";
import Menu from "@mui/icons-material/Menu";
import { IconButton, Stack, Typography, styled, useTheme } from "@mui/material";
import ActionMenu from "components/ActionMenu";
import Icon from "components/util-components/Icon";
import { FC } from "react";
import { useGuiDashboardV2Store } from "store/stores/gui-dashboard-v2";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";

const Container = styled(Stack)({
  width: "100%",
  height: "100%",
  paddingRight: "10px",
  paddingLeft: "10px",
});

type DividerWidgetProps = {
  widget: DashboardWidget<"divider">;
  onDelete(): void;
};

const DividerWidget: FC<DividerWidgetProps> = (props) => {
  const { widget, onDelete } = props;

  const theme = useTheme();

  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();
  const setWidget = useGuiDashboardV2Store.useSetWidget();
  const setIsWidgetEditorOpen =
    useGuiDashboardV2Store.useSetIsWidgetEditorOpen();

  return (
    <Container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        border: `1px solid`,
        borderColor: "transparent",
        borderRadius: "6px",
        ...(editModeEnabled
          ? {
              borderColor: theme.palette.gfGrey.Divider,
            }
          : {}),
      }}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <IconButton
          size="small"
          sx={{
            p: 0,
            width: "20px",
            height: "20px",
            ...(editModeEnabled
              ? {
                  opacity: 0,
                  pointerEvents: "none",
                }
              : {}),
          }}
        >
          <KeyboardArrowDownOutlined fontSize="small" />
        </IconButton>
        {widget.widget_data?.icon && (
          <Icon iconName={widget.widget_data.icon} fontSize="small" />
        )}
        <Typography fontSize="16px" fontWeight="500">
          {widget.title || "Divider"}
        </Typography>
      </Stack>
      {editModeEnabled && (
        <ActionMenu
          menuItems={[
            {
              label: "Edit",
              value: "edit",
              icon: <EditOutlined />,
            },
            {
              label: "Delete",
              value: "delete",
              icon: <DeleteOutline />,
            },
          ]}
          disabled={!editModeEnabled}
          onItemClick={(value) => {
            if (value === "edit") {
              setWidget(widget);
              setIsWidgetEditorOpen(true);
            } else if (value === "delete") {
              onDelete();
            }
          }}
        >
          <IconButton
            sx={{
              width: "28px",
              height: "28px",
              borderRadius: "100%",
              border: `1px solid ${theme.palette.gfGrey.Divider}`,
            }}
            className="non-draggable-widget-area"
          >
            <Menu sx={{ fontSize: "14px" }} />
          </IconButton>
        </ActionMenu>
      )}
    </Container>
  );
};

export default DividerWidget;
