import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as ExpandMoreSvg } from "../svg/expand_more.svg";

const ExpandMore: React.FC<React.ComponentProps<typeof SvgIcon>> = (props) => {
  return (
    <SvgIcon
      inheritViewBox
      {...props}
      component={ExpandMoreSvg}
      className="expand-icon"
    />
  );
};

export default ExpandMore;
