import PlaylistAdd from "@mui/icons-material/PlaylistAdd";
import { DrawerProps, styled } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import AddAssociatedField from "components/AddAssociatedField";
import GfDrawer from "components/GfDrawer";
import Spin from "components/Spin";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useCallback } from "react";
import { useGuiDashboardV2Store } from "store/stores/gui-dashboard-v2";

const DrawerStyled = styled(GfDrawer)(({ theme }) => ({
  ".gf-drawer-inner-paper": {
    boxShadow: "none",

    ".drawer-head": {
      background: theme.palette.primary.main,

      ".drawer-icon-holder": {
        background: "none",
        width: "auto",
        height: "auto",
      },

      ".drawer-title-box": {
        marginLeft: "20px",
      },
    },
  },
}));

type DataFieldDrawerProps = {
  onClose(): void;
  field?: DataField & { db_slug: string };
  setSelectedField?(field?: DataField): void;
} & DrawerProps;

const DataFieldDrawer: FC<DataFieldDrawerProps> = (props) => {
  const { onClose, field, setSelectedField, ...drawerProps } = props;

  const queryClient = useQueryClient();

  const widget = useGuiDashboardV2Store.useWidget();

  const { mutateAsync: updateFormDesign, isLoading: isUpdatingFormDesign } =
    useUpdateItem({
      modelName: ApiModels.FormDesign,
    });
  const { mutateAsync: createFormDesign, isLoading: isCreatingFormDesign } =
    useCreateItem({
      modelName: ApiModels.FormDesign,
    });

  const spinning = isUpdatingFormDesign || isCreatingFormDesign;

  const handleFieldUpdate = useCallback(
    async (updates: Partial<DataField>) => {
      if (!field) {
        return;
      }

      const { title, default_value, tooltip, type, id, slug, ...rest } =
        updates;

      const metadata = rest as Record<string, unknown>;

      if (metadata.db_slug) {
        delete metadata.db_slug;
      }
      if (metadata.field_slug) {
        delete metadata.field_slug;
      }

      field?.db_slug &&
        (await updateFormDesign({
          slug: field.db_slug,
          data: {
            title,
            default_value,
            tooltip,
            metadata: { ...(field.metadata ?? {}), ...metadata },
          },
        }));

      queryClient.refetchQueries({
        queryKey: [ApiModels.FormDesign, widget?.slug],
      });
    },
    [widget?.slug, queryClient, field, updateFormDesign]
  );

  const handleFieldCreate = useCallback(
    async (updates: Partial<DataField>) => {
      const { title, default_value, tooltip, type, id, slug, ...rest } =
        updates;

      const metadata = rest as Record<string, unknown>;

      if (metadata.db_slug) {
        delete metadata.db_slug;
      }
      if (metadata.field_slug) {
        delete metadata.field_slug;
      }

      await createFormDesign({
        field_slug: slug,
        title,
        type,
        default_value,
        tooltip,
        metadata,
        action_slug: widget?.slug,
      });

      queryClient.refetchQueries({
        queryKey: [ApiModels.FormDesign, widget?.slug],
      });
    },
    [widget?.slug, createFormDesign, queryClient]
  );

  return (
    <DrawerStyled
      anchor="right"
      onClose={onClose}
      title={`${field ? "Edit" : "Add"} Field`}
      icon={<PlaylistAdd />}
      width="420px"
      ModalProps={{
        keepMounted: false,
      }}
      keepMounted={false}
      variant="temporary"
      {...drawerProps}
    >
      <Spin spinning={spinning}>
        <AddAssociatedField
          onBackClick={() => {
            onClose();
            setSelectedField?.(undefined);
          }}
          onSubmit={async (data) => {
            if (field) {
              await handleFieldUpdate(data);
            } else {
              await handleFieldCreate(data);
            }

            onClose();
          }}
          dataField={field ?? { editable: true }}
          allowDefault
          extraFields={{
            editable: true,
          }}
        />
      </Spin>
    </DrawerStyled>
  );
};

export default DataFieldDrawer;
