import {
  PlayCircleFilledWhiteOutlined,
  Settings,
  Sync,
} from "@mui/icons-material";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import {
  Box,
  Breadcrumbs,
  Button,
  Link as MuiLink,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Mutex } from "async-mutex";
import IOSSwitch from "components/IOSSwitch";
import IconButtonWithTooltip from "components/IconButtonWithTooltip";
import { SocketState } from "enums";
import { FusionType } from "enums/Fusion";
import useAccountSlug from "hooks/useAccountSlug";
import useOpenClose from "hooks/useOpenClose";
import useSocket from "hooks/useSocket";
import { cloneDeep } from "lodash";
import FusionModel from "models/Fusion";
import { useSnackbar } from "notistack";
import use3pApps from "queries/3p-app/use3pApps";
import { ApiModels } from "queries/apiModelMapping";
import useAuthenticate from "queries/auth/useAuthenticate";
import useFusion from "queries/fusion/useFusion";
import useGFMLFunctionGroups from "queries/fusion/useGFMLFunctionGroups";
import useRunFusionSession from "queries/fusion/useRunFusionSession";
import useFolders from "queries/useFolders";
import useUpdateItem from "queries/useUpdateItem";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { useFusionFlowStore } from "store/stores/fusion-flow";
import { useSocketStore } from "store/stores/socket";
import { useSystemLayoutStore } from "store/stores/systemLayout";
import FusionEditDrawer from "views/app-view/fusions/components/FusionEditDrawer";
import FusionFlowDesigner, {
  FlowDesignerRef,
} from "./components/FusionFlowDesigner";
import SessionHistoryDrawer from "./components/SessionHistoryDrawer";
import SessionRunDrawer, {
  SessionRunDrawerRef,
} from "./components/SessionRunDrawer";

const TopBarButton = styled(Button)(({ theme }) => ({
  border: "none",
  color: "#fff",
  background: theme.palette.background.GF10,
}));

const CenterBox = styled(Box)(({ theme }) => ({
  height: "100%",

  ".side-menu-active &": {
    height: "100vh",
  },
}));

const flowEventsMutex = new Mutex();

const FlowDesigner: React.FC = () => {
  const { fusionSlug } = useParams<{ fusionSlug: string }>();

  const { initialize, subscribe, unsubscribe } = useSocket();

  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, open, close] = useOpenClose();
  const navigate = useNavigate();
  const selectedAccount = useAccountSlug();
  const setAppBarProps = useSystemLayoutStore.useSetAppBarProps();
  const socketState = useSocketStore.useState();
  const fusionDraft = useFusionFlowStore.useFusionDraft();
  const setFusionDraft = useFusionFlowStore.useSetFusionDraft();
  const { data } = useAuthenticate();

  const [selectedSession, setSelectedSession] = useState<FusionFlowSession>();
  const [isHistoryDrawerOpen, setIsHistoryDrawerOpen] = useState(false);
  const [isSessionRunDrawerOpen, setIsSessionRunDrawerOpen] = useState(false);

  const { data: folderList } = useFolders();
  const { mutate: runFusionTest } = useRunFusionSession();
  const { mutate: updateFusion } = useUpdateItem({
    modelName: ApiModels.Fusion,
  });
  const queryClient = useQueryClient();

  const sessionRunDrawerRef = useRef<SessionRunDrawerRef>();
  const fusionRef = useRef<Fusion>();
  const flowDesignerRef = useRef<FlowDesignerRef>();
  const sessionSlugRef = useRef(selectedSession?.slug);

  const sessionsQueryKey = useMemo(
    () => ["fusion-sessions", fusionSlug],
    [fusionSlug]
  );

  const { data: fusion, refetch: refetchFusion } = useFusion(fusionSlug);
  use3pApps();
  useGFMLFunctionGroups();

  const userSlug = data?.user?.slug;

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [ApiModels.Fusion, fusionSlug],
    });
  }, []);

  useEffect(() => {
    sessionSlugRef.current = selectedSession?.slug;
  }, [selectedSession]);

  useEffect(() => {
    fusionRef.current = fusionDraft as Fusion;
  }, [fusionDraft]);

  useEffect(() => {
    if (fusionDraft?.fusion_type) {
      setAppBarProps({
        onLeftIconClick: () => {
          navigate(
            `/${selectedAccount}/${
              fusionDraft?.fusion_type === FusionType.Skills
                ? "skill-design-module"
                : "fusion"
            }`
          );
        },
      });
    }
  }, [fusionDraft?.fusion_type, navigate, selectedAccount, setAppBarProps]);

  useEffect(() => {
    if (userSlug && socketState === SocketState.Open) {
      initialize({
        eventType: "init",
        metadata: userSlug,
      });
    }
  }, [userSlug, socketState, initialize]);

  const updateQueryDataForFlowSession = useCallback(
    async (data: { data: FlowSessionEventsWSData }) => {
      const release = await flowEventsMutex.acquire();
      const { events, sessionSlug, creditsConsumed } = data.data;
      if (
        fusionSlug === data.data.fusionSlug &&
        sessionSlugRef.current === sessionSlug
      ) {
        queryClient.setQueryData<FusionFlowSession[]>(
          sessionsQueryKey,
          (prev) => {
            const prevSessions = cloneDeep(prev ?? []);
            const activeSession = prevSessions?.find(
              (session) => session.slug === sessionSlug
            );
            events.forEach((event) => {
              const { type, payload } = event;
              if (activeSession) {
                activeSession.session_data.total_credits_used = creditsConsumed;
              }
              if (type === "session_start") {
                if (!activeSession) {
                  prevSessions.unshift({
                    id: `${payload.accountSlug}:fusion_sessions`,
                    slug: sessionSlug,
                    fusion_slug: fusionSlug,
                    session_data: {
                      session_init_vars: {},
                      session_variables: {},
                      loops: [],
                      error_logs: [],
                      start_time: payload.startTime,
                      skill_responses: {},
                      total_credits_used: creditsConsumed,
                    },
                    session_operators: fusionRef.current?.fusion_operators,
                    session_status: payload.status,
                    fusion_type: fusionRef.current?.fusion_type,
                    account_slug: payload.accountSlug,
                    user_id: payload.userSlug,
                    operator_responses: {},
                    operations_counter:
                      fusionRef.current?.fusion_operators?.reduce<
                        Record<string, unknown>
                      >((acc, cur) => {
                        acc[cur.operator_slug] = { counter: -1 };

                        return acc;
                      }, {}),
                    version: "v2",
                    is_paused: false,
                    is_deleted: false,
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                  } as unknown as FusionFlowSession);
                }
              } else if (type === "push_operation" && activeSession) {
                const operatorOperations =
                  activeSession.operator_responses[payload.operatorSlug];
                if (operatorOperations) {
                  operatorOperations.operations.push(payload.operation);
                } else {
                  activeSession.operator_responses[payload.operatorSlug] = {
                    operations: [payload.operation],
                  };
                }

                sessionRunDrawerRef.current?.pushOperation({
                  operator_slug: payload.operatorSlug,
                  operation_idx: payload.index,
                  session_slug: sessionSlug,
                  operator_execution_slug:
                    payload.operation.operator_execution_slug!,
                });
              } else if (type === "complete_operation" && activeSession) {
                const operatorOperations =
                  activeSession.operator_responses[payload.operatorSlug];
                const operationsCounter =
                  activeSession.operations_counter?.[payload.operatorSlug];
                if (operatorOperations) {
                  operatorOperations.operations.splice(
                    payload.index,
                    1,
                    payload.operation
                  );
                } else {
                  activeSession.operator_responses[payload.operatorSlug] = {
                    operations: [payload.operation],
                  };
                }
                if (operationsCounter?.counter != null) {
                  operationsCounter.counter = payload.operationCounter;
                }

                sessionRunDrawerRef.current?.completeOperation({
                  operator_slug: payload.operatorSlug,
                  operation_idx: payload.index,
                  session_slug: sessionSlug,
                  operator_execution_slug:
                    payload.operation.operator_execution_slug!,
                });
              } else if (type === "session_complete" && activeSession) {
                activeSession.session_status = payload.status;
                activeSession.session_data.finish_time = payload.finishTime;
                queryClient.refetchQueries({ queryKey: sessionsQueryKey });
              } else if (type === "session_error" && activeSession) {
                activeSession.session_data.finish_time = payload.finishTime;
                activeSession.session_status = payload.status;
                activeSession.session_data.error_logs = payload.error as any;
              } else if (type === "session_paused" && activeSession) {
                activeSession.is_paused = true;
                activeSession.session_status = payload.status;
              } else if (type === "session_stopped" && activeSession) {
                activeSession.session_status = payload.status;
                activeSession.session_data.finish_time =
                  new Date().toISOString();
              }
            });
            setSelectedSession(
              prevSessions.find((session) => session.slug === sessionSlug)
            );
            return prevSessions;
          }
        );
      }

      release();
    },
    [fusionSlug, queryClient, sessionsQueryKey]
  );

  useEffect(() => {
    subscribe("flow_session_events", "fusion_flow_test", (data: any) => {
      updateQueryDataForFlowSession(data);
    });

    return () => {
      unsubscribe("flow_session_events", "fusion_flow_test");
    };
  }, [subscribe, unsubscribe, updateQueryDataForFlowSession]);

  const onRunTestClick = () => {
    if (userSlug && fusionDraft) {
      runFusionTest(
        {
          fusion: fusionDraft,
          userSlug,
          version: fusion?.version_id ?? "v1",
        },
        {
          onSuccess(data) {
            const sessionSlug = data.sessionSlug as string;
            sessionSlugRef.current = sessionSlug;
            FusionModel.getSession(sessionSlug).then((res) => {
              setSelectedSession(res.data);
              queryClient.setQueryData<FusionFlowSession[]>(
                sessionsQueryKey,
                (prevSessions = []) => {
                  const sessionIndex =
                    prevSessions?.findIndex(
                      (session) => session.slug === data.sessionSlug
                    ) ?? -1;

                  if (sessionIndex === -1) {
                    prevSessions.unshift(res.data);
                  }

                  return prevSessions;
                }
              );
            });
          },
        }
      );
      setIsSessionRunDrawerOpen(true);
    }
  };

  const handleActiveChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (fusionSlug) {
      updateFusion({
        slug: fusionSlug,
        data: {
          is_active: checked,
        },
      });
      setFusionDraft({ ...fusionDraft, is_active: checked });
    }
  };

  const handleSingleLambdaRunChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (fusionSlug) {
      updateFusion({
        slug: fusionSlug,
        data: {
          run_in_single_lambda: checked,
        },
      });
      setFusionDraft({ ...fusionDraft, run_in_single_lambda: checked });
    }
  };

  const onSaveFusionClick = () => {
    flowDesignerRef.current?.saveFusion();
  };

  const handleMigrateToV2Click = async () => {
    if (fusionSlug) {
      await FusionModel.migrateToV2(fusionSlug)
        .then((response) => {
          enqueueSnackbar({
            message: response.message || "Successfully migrated to v2",
            variant: "success",
          });
          refetchFusion();
        })
        .catch(() => {
          enqueueSnackbar({ message: "Migration Failed", variant: "error" });
        });
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{fusion?.fusion_title}</title>
        <meta name="description" content={fusion?.fusion_description} />
      </Helmet>
      <CenterBox
        sx={{
          background: (theme) => theme.palette.background.ContentArea,
        }}
      >
        <Box
          sx={{
            p: 2,
            position: "absolute",
            width: (theme: any) =>
              theme.navStyle === "left" ? "calc(100% - 50px)" : "100%",
            zIndex: 2,
            borderBottom: "1px solid #28262B",
            background: "#121219",
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Breadcrumbs separator=">">
              <MuiLink
                component={Link}
                underline="hover"
                color="inherit"
                to={`${selectedAccount}/fusion?folder_id=${
                  fusion?.folder_id ?? "root"
                }`}
              >
                {folderList?.find((f) => f.slug === fusion?.folder_id)?.name ??
                  "Fusions"}
              </MuiLink>
              <Typography variant="body1">
                {fusion?.fusion_title} (Version: {fusion?.version_id ?? "v1"})
              </Typography>
            </Breadcrumbs>
            <Stack direction="row-reverse" justifyContent="space-between">
              <Stack direction="row" spacing={1}>
                {fusion?.slug && (
                  <TopBarButton
                    variant="contained"
                    size="small"
                    disableElevation
                    disabled
                    endIcon={
                      <IOSSwitch
                        onChange={handleSingleLambdaRunChange}
                        size="small"
                        color="primary"
                        disabled
                        defaultChecked={!!fusion?.run_in_single_lambda}
                        sx={{
                          ".MuiSwitch-track": {
                            background: (theme) =>
                              theme.palette.background.GF20,
                          },
                        }}
                      />
                    }
                  >
                    Single Lambda
                  </TopBarButton>
                )}
                {fusion?.slug && (
                  <TopBarButton
                    variant="contained"
                    size="small"
                    disableElevation
                    endIcon={
                      <IOSSwitch
                        onChange={handleActiveChange}
                        size="small"
                        color="primary"
                        defaultChecked={!!fusion?.is_active}
                        sx={{
                          ".MuiSwitch-track": {
                            background: (theme) =>
                              theme.palette.background.GF20,
                          },
                        }}
                      />
                    }
                  >
                    ACTIVE
                  </TopBarButton>
                )}
                {fusion?.version_id !== "v2" && (
                  <TopBarButton
                    size="small"
                    variant="contained"
                    endIcon={<Sync />}
                    onClick={handleMigrateToV2Click}
                    disableElevation
                  >
                    Migrate to V2
                  </TopBarButton>
                )}
                <TopBarButton
                  size="small"
                  variant="contained"
                  endIcon={<PlayCircleFilledWhiteOutlined />}
                  onClick={onSaveFusionClick}
                  disableElevation
                >
                  Save Fusion
                </TopBarButton>
                <IconButtonWithTooltip
                  size="small"
                  tooltipProps={{ title: "Fusion Settings" }}
                  onClick={open}
                >
                  <Settings />
                </IconButtonWithTooltip>
                <IconButtonWithTooltip
                  size="small"
                  onClick={onRunTestClick}
                  tooltipProps={{ title: "Run Test" }}
                >
                  <PlayCircleFilledWhiteOutlined />
                </IconButtonWithTooltip>
                <IconButtonWithTooltip
                  size="small"
                  onClick={() => setIsHistoryDrawerOpen(true)}
                  tooltipProps={{ title: "Session History" }}
                >
                  <RestoreOutlinedIcon />
                </IconButtonWithTooltip>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <ReactFlowProvider>
          <FusionFlowDesigner ref={flowDesignerRef} />
        </ReactFlowProvider>
        <SessionHistoryDrawer
          open={isHistoryDrawerOpen}
          onClose={() => {
            if (!isSessionRunDrawerOpen) {
              setIsHistoryDrawerOpen(false);
            }
          }}
          fusionSlug={fusionSlug}
          onSessionSelect={(session) => {
            setSelectedSession(session);
            setIsSessionRunDrawerOpen(true);
          }}
        />
        <SessionRunDrawer
          ref={sessionRunDrawerRef}
          open={isSessionRunDrawerOpen}
          onClose={() => {
            setIsSessionRunDrawerOpen(false);
            setSelectedSession(undefined);
          }}
          fusionSlug={fusionSlug}
          session={selectedSession}
        />
        <FusionEditDrawer open={isOpen} onClose={close} />
      </CenterBox>
    </React.Fragment>
  );
};

export default FlowDesigner;
