import Model from "models";

class JobModel extends Model<Job> {
  constructor() {
    super("/job", "public-1");
  }
}

const model = new JobModel();
export default model;
