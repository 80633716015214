export enum SystemModuleType {
  Create = "create",
  Update = "update",
  Read = "read",
  ReadOne = "read_one",
  Delete = "delete",
  Webhook = "webhook",
  WebhookResponse = "webhook_response",
  Import = "import",
  AddTag = "add_tag",
  RemoveTag = "remove_tag",
  DocumentDelete = "document_delete",
  DocumentEdit = "document_edit",
  DocumentCreate = "document_create",
  ChargePayment = "charge_payment",
  SendMessage = "send_message",
  AddSubscriber = "add_subscriber",
  RemoveSubscriber = "remove_subscriber",
  CloseThread = "close_thread",
  ChatStart = "chat_start_operator",
  RestClient = "rest_api",
  GraphQLClient = "graphql_api",
  SetVariable = "set_variable",
  GetVariable = "get_variable",
  SetMultipleVariables = "set_multiple_variables",
  GetMultipleVariables = "get_multiple_variables",
  ArrayIterator = "array_iterator",
  ArrayAggregator = "array_aggregator",
  Event = "event",
  MapChartData = "map_chart_data",
  SocialMediaAutomation = "social_media_automation",
  GetNextTask = "get_next_task",
  CompleteTask = "complete_task",
  UpdateInputVariables = "update_input_variables",
  ChartNode = "chart-node",
  Loop = "loop",
  LoopEnd = "loop_end",
  TriggerFusion = "trigger_fusion",
  AskQuestion = "ask_question",
  CollectSlots = "collect_slots",
  UpdateDisplay = "update_display",
  ChargeCredit = "charge_credit",
  Search3PList = "search_3p_list",
  AuthenticationRequest3P = "authentication_request_3p",
  UpdateSkillUser = "update_skill_user",
  UpdateSkillSession = "update_skill_session",
  GetTemporaryS3Link = "get_temporary_s3_link",
  SplitAudio = "split_audio",
  TranscriptionJobTrigger = "transcription_job_trigger",
  CreateTranscriptionJob = "create_transcription_job",
  DeleteTranscriptionJob = "delete_transcription_job",
  GetTranscriptionJob = "get_transcription_job",
  ListTranscriptionJobs = "list_transcription_jobs",
  RunPodExtractFaces = "run_pod_extract_faces",
  ActivateDisplay = "activate_display",
  CreateOperator = "create_operator",
  GetAllOperators = "get_all_operators",
  GetOperatorSlots = "get_operator_slots",
  DeleteOperator = "delete_operator",
  UpdateOperatorSlots = "update_operator_slots",
  GetAllPopupVariables = "get_all_popup_variables",
  CreateTable = "create_table",
  AlterTable = "alter_table",
  AddColumn = "add_column",
  AlterColumn = "alter_column",
  DetectIntent = "detect_intent",
  // CreateJob = "create_job",
  ChangeSelectedDisplay = "change_selected_display",
  ExitSkill = "exit_skill",
  ZipS3FilesFromDatasets = "zip_s3_files_from_datasets",
  LoopWhile = "loop_while",
  Sleep = "sleep",
  CreateConnection = "create_connection",
  UpdateChatView = "update_chat_view",
  UpdateGuiParams = "update_gui_params",
  PopulateWidget = "populate_widget",
  ListDatasetDesigns = "list_dataset_designs",
  GetDatasetDesignFields = "get_dataset_design_fields",
  CreateDatasetDesign = "create_dataset_design",
  CreateGui = "create_gui",
  UpdateGui = "update_gui",
  CreateGuiTab = "create_gui_tab",
  CreateDashboardWidget = "create_dashboard_widget",
  CreateSQLQuery = "create_sql_query",
  UpdateSQLQuery = "update_sql_query",
  RemoveSQLQuery = "remove_sql_query",
  GetSQLQuery = "get_sql_query",
  ListSQLQuery = "list_sql_query",
  RunSQLQuery = "run_sql_query",
  SubmitToolOutputsToRun = "submit_tool_outputs_to_run",
  DisplayScratchpad = "display_scratchpad",
  CreateScratchpad = "create_scratchpad",
  UpdateScratchpad = "update_scratchpad",
  RefreshScratchpad = "refresh_scratchpad",
  AddFileToScratchpad = "add_file_to_scratchpad",
  DeleteFileFromScratchpad = "delete_file_from_scratchpad",
  UpdateChatStatus = "update_chat_status",
  CreateJob = "create_job",
  UpdateJob = "update_job",
  CreateJobTask = "create_job_task",
  UpdateJobTask = "update_job_task",
  AddTaskScratchpads = "add_task_scratchpads",
  RemoveTaskScratchpads = "remove_task_scratchpads",
  CreateContactDatasetAssociation = "create_contact_dataset_association",
  CreateThreadDatasetAssociation = "create_thread_dataset_association",
  AgentChatOutput = "agent_chat_output",
}

export enum MappableTagType {
  Function = "function",
  Variable = "variable",
  Separator = "separator",
  ClosingBracket = "closing_bracket",
}

export enum SessionStatus {
  Building = "Building",
  Running = "Running",
  Paused = "Paused",
  Complete = "Complete",
  UserCancelled = "UserCancel",
}

export enum OperatorStatus {
  Pending = "Pending",
  Processing = "Processing",
  Failed = "Failed",
  Complete = "Complete",
}

export enum OperatorLogStatus {
  Warning = "Warning",
  Failed = "Failed",
  Success = "Success",
}

export enum ScheduleType {
  Indefinitely = "indefinitely",
  Once = "once",
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
  Yearly = "yearly",
}

export enum FusionType {
  Core = "core",
  Skills = "skills",
  Import = "import",
  Adventure = "adventure",
  DoubleTalk = "double_talk",
  SalineChat = "saline_chat",
  DataListWidgetCreateActionFormSubmit = "data-list-widget-create-action-form-submit",
  DataListWidgetEditActionFormSubmit = "data-list-widget-edit-action-form-submit",
  DataListWidgetCreateActionFormPopulate = "data-list-widget-create-action-form-populate",
  DataListWidgetEditActionFormPopulate = "data-list-widget-edit-action-form-populate",
  DataListGuiCreateFormSubmit = "data-list-gui-create-form-submit",
  DataList = "data-list",
  FusionAction = "fusion_action",
  GuiPageLoad = "gui_page_load",
  WidgetData = "widget_data",
  WidgetEdit = "widget_edit",
  AgentPromptFusion = "agent_prompt_fusion",
  AgentPreprocessFusion = "agent_preprocess_fusion",
}
