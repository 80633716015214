import { Box, SxProps } from "@mui/material";
import CellLoader from "components/DatasetTable/CellLoader";
import { FC, PropsWithChildren } from "react";

type CellProps = { sx?: SxProps; isLoading?: boolean };

const Cell: FC<PropsWithChildren<CellProps>> = (props) => {
  const { children, sx = {}, isLoading } = props;
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      {isLoading ? <CellLoader /> : children}
    </Box>
  );
};

export default Cell;
