import { AddPhotoAlternate } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { CustomCellEditorProps } from "ag-grid-react";
import AddVideo from "assets/icons/AddVideo";
import { getAcceptedFileTypes } from "components/Form/helper";
import Uploader from "components/Uploader";
import { DocumentElementType } from "enums";
import React, { FC, memo } from "react";

type DefaultFileCellEditorProps = {
  field: DataField;
} & CustomCellEditorProps;

const DefaultFileCellEditor: FC<DefaultFileCellEditorProps> = memo((props) => {
  const { field, value, onValueChange } = props;

  const theme = useTheme();

  return (
    <Uploader
      files={value}
      onChange={(files: FileMeta | FileMeta[]) => {
        onValueChange(files);
      }}
      sxProps={{
        background: `${theme.palette.background.TableHeader} !important`,
        color: theme.palette.text.primary,

        ".file-picker-box": {
          background: theme.palette.background.TableBody,
          color: theme.palette.text.primary,
        },

        ".description-text": {
          color: theme.palette.text.secondary,
        },

        ".uploaded-item": {
          background: theme.palette.background.TableBody,
          color: theme.palette.text.primary,

          ".file-detail": {
            ".file-size": {
              color: theme.palette.text.secondary,
            },
          },
        },
      }}
      accept={{
        [getAcceptedFileTypes(
          DocumentElementType.AudioVideo === field.type
            ? field.file_type ?? "video"
            : field.type
        )]: field.accept ? field.accept?.split(",") : [],
      }}
      single={!field.multi}
      multiple={field.multi ?? false}
      maxFiles={field.multi ? field.max_count : 1}
      maxSize={field.max_size && field.max_size * 1024 * 1024} //Mb to bytes
      extra={{
        icon:
          DocumentElementType.Image === field.type ? (
            <AddPhotoAlternate />
          ) : DocumentElementType.AudioVideo === field.type ? (
            <AddVideo />
          ) : undefined,
        description:
          DocumentElementType.AudioVideo === field.type ? (
            <React.Fragment>
              mp3,mp4,mov,avi,webm
              {field.max_size ? (
                <React.Fragment>
                  (max {field.max_size}
                  mb)
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : DocumentElementType.File === field.type ? (
            <React.Fragment>
              {field.accept || "upload files here"}{" "}
              {field.max_size ? (
                <React.Fragment>
                  (max {field.max_size}
                  mb)
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : undefined,
      }}
      fieldId={field.id}
      fieldSlug={field.slug}
    />
  );
});

export default DefaultFileCellEditor;
