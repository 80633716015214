import { json } from "@codemirror/lang-json";
import CodeEditor from "components/CodeEditor";
import { parseValueForCodeEditor } from "helpers";
import { FC, useMemo } from "react";

type CodeFieldProps = {
  value?: any;
  onChange?(value: any): void;
};

const CodeField: FC<CodeFieldProps> = (props) => {
  const value = useMemo(() => {
    return parseValueForCodeEditor(props.value, false);
  }, [props.value]);

  const handleChange = (editorValue: string) => {
    try {
      const parsed = JSON.parse(editorValue);
      props.onChange?.(parsed);
    } catch (e) {
      props.onChange?.(editorValue);
    }
  };

  return (
    <CodeEditor
      style={{
        height: "100%",
      }}
      value={value}
      extensions={[json()]}
      basicSetup={{
        lineNumbers: true,
        syntaxHighlighting: true,
        foldGutter: true,
      }}
      onChange={handleChange}
    />
  );
};

export default CodeField;
