import { Divider } from "@mui/material";
import TabStyled from "components/TabStyled";
import InnerPageLayout from "layouts/inner-app-layout";
import { FC, useState } from "react";
import AgentSkillDetails from "views/app-view/ss-agent/EditDrawer/AgentSkillSideBar/AgentSkillDetails";
import AgentSkillFusion from "views/app-view/ss-agent/EditDrawer/AgentSkillSideBar/AgentSkillFusion";
import AgentSkillParams from "views/app-view/ss-agent/EditDrawer/AgentSkillSideBar/AgentSkillParams";

type TabContentProps = {
  selectedTab: string;
  agentSkill?: AgentSkill;
};

const TabContent: FC<TabContentProps> = (props) => {
  const { selectedTab, agentSkill } = props;

  switch (selectedTab) {
    case "details":
      return <AgentSkillDetails agentSkill={agentSkill} />;
    case "fusion":
      return <AgentSkillFusion agentSkill={agentSkill} />;
    case "custom-parameters":
      return <AgentSkillParams agentSkill={agentSkill} />;
    default:
      return null;
  }
};

type AgentSkillSideBarProps = {
  agentSkill?: AgentSkill;
};

const AgentSkillSideBar: FC<AgentSkillSideBarProps> = (props) => {
  const { agentSkill } = props;

  const [selectedTab, setSelectedTab] = useState("details");

  return (
    <InnerPageLayout
      hideHeader
      variant="standard"
      value={selectedTab}
      onChange={(_, value) => {
        setSelectedTab(value);
      }}
      updateUrl={false}
      sx={{ width: "100%" }}
      tabList={[
        {
          label: <TabStyled title="Details" />,
          value: "details",
        },
        {
          label: "",
          icon: <Divider orientation="vertical" variant="middle" />,
          disabled: true,
        },
        {
          label: <TabStyled title="Custom Parameters" />,
          value: "custom-parameters",
        },
        {
          label: "",
          icon: <Divider orientation="vertical" variant="middle" />,
          disabled: true,
        },
        {
          label: <TabStyled title="Fusion" />,
          value: "fusion",
        },
      ]}
    >
      <TabContent selectedTab={selectedTab} agentSkill={agentSkill} />
    </InnerPageLayout>
  );
};

export default AgentSkillSideBar;
