import { DescriptionOutlined } from "@mui/icons-material";
import DragIndicatorOutlined from "@mui/icons-material/DragIndicatorOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import Scrollbar from "components/Scrollbar";
import { SortableList } from "components/SortableList";
import cloneDeep from "lodash/cloneDeep";
import { FC, useEffect, useState } from "react";

type ListHeaderProps = {
  itemCount: number;
  checkedCount: number;
  onSelectAllClick(): void;
  onSearchChange(value: string): void;
};

const ListHeader: FC<ListHeaderProps> = (props) => {
  const { itemCount, checkedCount, onSelectAllClick, onSearchChange } = props;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ px: 2, py: 1 }}
    >
      <FormControlLabel
        label="Select All"
        labelPlacement="end"
        slotProps={{
          typography: {
            fontSize: "13px",
            fontWeight: 400,
          },
        }}
        control={
          <Checkbox
            sx={{
              ".MuiSvgIcon-root": {
                width: "24px",
                height: "24px",
              },
            }}
            checked={checkedCount !== 0 && itemCount === checkedCount}
            indeterminate={checkedCount > 0 && itemCount !== checkedCount}
            onChange={onSelectAllClick}
            disableRipple
            icon={
              <Box
                sx={{
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "80%",
                    height: "80%",
                    background: "transparent",
                    border: "2px solid #69696c",
                    borderRadius: "4px",
                  }}
                />
              </Box>
            }
          />
        }
      />
      <TextField
        variant="outlined"
        placeholder="Search"
        onChange={(e) => onSearchChange(e.target.value)}
        sx={{
          background: "#343439",
          borderRadius: "32px",

          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        InputProps={{
          sx: {
            height: "32px",
            px: 0.5,
            py: 0.5,
            fontSize: "14px",
          },
          endAdornment: (
            <SearchOutlined
              sx={{ color: "#a9a9ab", fontSize: "16px", mr: 1 }}
            />
          ),
        }}
      />
    </Stack>
  );
};

type IncludedFieldsSelectorProps = {
  fields: DataField[];
  includedFields: string[];
  fieldMetadata?: Record<string, { sort_index?: number }>;
  onFieldEdit(field: DataField): void;
  onUpdateData(
    includedFields: string[],
    fieldMetadata: Record<string, { sort_index?: number }>
  ): void;
};

const IncludedFieldsSelector: FC<IncludedFieldsSelectorProps> = (props) => {
  const {
    fields = [],
    includedFields,
    fieldMetadata,
    onUpdateData,
    onFieldEdit,
  } = props;

  const [sortedFields, setSortedFields] = useState<DataField[]>([]);
  const [checkedItems, setCheckedItems] = useState<Set<string>>(new Set());
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const sorted = cloneDeep(fields).sort(
      (a, b) =>
        (fieldMetadata?.[a.id]?.sort_index ?? 100000) -
        (fieldMetadata?.[b.id]?.sort_index ?? 100000)
    );

    setSortedFields(sorted);
    setCheckedItems(new Set(includedFields));
  }, [fieldMetadata, fields, includedFields]);

  const handleUpdateData = (sorted: DataField[], checked: string[]) => {
    const updatedMetadata = sorted.reduce<
      Record<string, { sort_index?: number }>
    >((acc, cur, currentIndex) => {
      acc[cur.id] = {
        ...(fieldMetadata?.[cur.id] ?? {}),
        sort_index: currentIndex,
      };

      return acc;
    }, {});
    const sortedChecked = checked.sort(
      (a, b) =>
        (updatedMetadata[a].sort_index ?? 100000) -
        (updatedMetadata[b].sort_index ?? 100000)
    );
    onUpdateData(sortedChecked, updatedMetadata);
  };

  const handleSortChange = (items: DataField[]) => {
    setSortedFields(items);
    handleUpdateData(items, [...checkedItems]);
  };

  const handleCheckedItemsChange = (items: Set<string>) => {
    setCheckedItems(items);
    handleUpdateData(sortedFields, [...items]);
  };

  const handleSelectAll = () => {
    if (checkedItems.size === sortedFields.length) {
      handleCheckedItemsChange(new Set());
    } else {
      handleCheckedItemsChange(new Set(sortedFields.map((field) => field.id)));
    }
  };

  return (
    <Box
      sx={{
        background: "#29292e",
        borderRadius: "4px",
        pb: 0.25,
        color: "#a9a9ab",
      }}
    >
      <ListHeader
        itemCount={sortedFields.length}
        checkedCount={checkedItems.size}
        onSelectAllClick={handleSelectAll}
        onSearchChange={setSearchValue}
      />
      <Stack
        gap={1}
        sx={{
          background: "#1e1e23",
          mx: "2px",
          borderRadius: "2px",
          height: "300px",
        }}
      >
        <Scrollbar>
          <SortableList
            items={sortedFields.filter((field) =>
              field.title.toLowerCase().includes(searchValue.toLowerCase())
            )}
            disabled={!!searchValue}
            onChange={handleSortChange}
            renderList={({ children }) => (
              <List sx={{}} dense>
                {children}
              </List>
            )}
            renderItem={(field, index) => (
              <SortableList.Item id={field.id} key={field.id} handle>
                <ListItem
                  sx={{
                    ".MuiListItemSecondaryAction-root": {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      sx={{ p: 0, mr: 0.5 }}
                      disableRipple
                      onClick={() => onFieldEdit(field)}
                    >
                      <EditOutlined
                        sx={{ color: "#78787b", fontSize: "14px" }}
                      />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton
                    role={undefined}
                    onClick={() => {
                      const set = new Set(checkedItems);
                      if (set.has(field.id)) {
                        set.delete(field.id);
                      } else {
                        set.add(field.id);
                      }
                      handleCheckedItemsChange(set);
                    }}
                    dense
                  >
                    <SortableList.DragHandle>
                      <ListItemIcon
                        sx={{ minWidth: "auto", mr: 1, outline: "none" }}
                      >
                        <DragIndicatorOutlined
                          sx={{ color: "#78787b", fontSize: "16px" }}
                        />
                      </ListItemIcon>
                    </SortableList.DragHandle>
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <Checkbox
                        sx={{
                          padding: 0,
                          width: "20px",
                          height: "20px",
                          margin: 0,
                        }}
                        checked={checkedItems.has(field.id)}
                        icon={
                          <Box
                            sx={{
                              background: "#29292e",
                              width: "100%",
                              height: "100%",
                              borderRadius: "4px",
                            }}
                          />
                        }
                        edge="start"
                        // checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        // inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <DescriptionOutlined
                        sx={{ fontSize: "20px", color: "#78787b" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ fontSize: "15px", fontWeight: 400 }}
                      id={field.slug}
                      primary={field.title}
                    />
                  </ListItemButton>
                </ListItem>
              </SortableList.Item>
            )}
          />
        </Scrollbar>
      </Stack>
    </Box>
  );
};

export default IncludedFieldsSelector;
