import { ColDef } from "ag-grid-community";
import DefaultFileCellEditor from "components/DatasetTable/colDefs/DefaultFileCellEditor";
import VideoCellRenderer from "components/DatasetTable/colDefs/video/VideoCellRenderer";

const getVideoColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => {
  return {
    ...defaults,
    cellRenderer: VideoCellRenderer,
    cellEditor: DefaultFileCellEditor,
    cellEditorPopup: true,
    cellEditorPopupPosition: "under",
    cellEditorParams: {
      field,
    },
  };
};

export default getVideoColDef;
