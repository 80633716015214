import AdUnits from "@mui/icons-material/AdUnits";
import AddOutlined from "@mui/icons-material/AddOutlined";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ContentCopyOutlined from "@mui/icons-material/ContentCopyOutlined";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import {
  Box,
  Stack,
  TabProps,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import ActionMenu from "components/ActionMenu";
import { DynamicEditFormProps } from "components/Form/DynamicEditForm";
import FusionActionsDropdown from "components/FusionAction/FusionActionsDropdown";
import IconButtonWithTooltip from "components/IconButtonWithTooltip";
import RegisterHotKey from "components/RegisterHotKey";
import Scrollbar from "components/Scrollbar";
import Icon from "components/util-components/Icon";
import useGuiTabEditorController from "hooks/useGuiTabEditorController";
import { AnimationLayoutRef } from "layouts/AnimationLayout";
import InnerPageLayout from "layouts/inner-app-layout";
import clone from "lodash/clone";
import set from "lodash/set";
import { useSnackbar } from "notistack";
import { ApiModels } from "queries/apiModelMapping";
import useDuplicateGuiData from "queries/gui/useDuplicateGuiData";
import { useGuiTabs } from "queries/gui/useGuiTabs";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import React, { FC, RefObject, useCallback, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useSystemLayoutStore from "store";
import { useDatasetStore } from "store/stores/dataset";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import useGuiTabEditor from "store/stores/gui-tab-editor";
import { normalizeObjectForAPI } from "utils";
import { confirmDelete } from "utils/confirmDelete";
import { removeArrayItem } from "utils/removeArrayItem";
import ChatView from "views/app-view/gui-dataset/components/ChatView";
import EditDataset from "./EditDateset";
import EditDatasetExtended from "./EditDatesetExtended";
import IFrameView from "./components/IFrameView";

type Props = {
  onFormEvent?: DynamicEditFormProps["onFormEvent"];
  data?: any;
  onNextClick(): void;
  onPreviousClick(): void;
  onActionClick(action: FusionAction): void;
  guiTabEditorRef: RefObject<AnimationLayoutRef>;
};

const InnerAppLayout = styled(Box)(({ theme }) => {
  return {
    ".heading-card": {
      padding: "0",
    },
  };
});

const IconContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  border: "1px solid #4c4d51",
  borderRadius: "50%",
  height: "32px",
  width: "32px",
  color: "#a5a6a8",

  svg: {
    width: "18px",
    height: "18px",
  },

  "&:hover": {
    background: "#35373a",
    cursor: "pointer",
  },
});

const EditBox = styled(Box)(({ theme }) => {
  const navStyle = (theme as any).navStyle;
  const topNavHeight = (theme as any).topNavHeight;
  const sideNavWidth = (theme as any).sideNavWidth;

  let defaultLeft = 221;
  let defaultTop = 150;

  navStyle === "top"
    ? (defaultTop = defaultTop + topNavHeight)
    : (defaultLeft = defaultLeft + sideNavWidth);

  return {
    padding: "160px 0px 0px 0px",
    height: "100%",
    background: "#1e1e23",

    [`${theme.breakpoints.down("sm")}`]: {
      padding: "11px 15px",
    },

    ".MuiTabs-root": {
      position: "fixed",
      left: `${defaultLeft}px`,
      top: `${defaultTop}px`,
      right: "0",
      padding: "0 32px",
      background: "#1F2125",
      borderBottom: `1px solid rgba(53, 55, 58, 1)`,
      height: "60px",
      zIndex: "9",

      ".MuiTabs-flexContainer": {
        height: "100%",
      },

      ".MuiTabs-indicator": {
        height: "2px",
        background: "#fff",
      },
    },

    ".center-box": {
      paddingTop: "0 !important",
    },
  };
});

export const BoxHeader = styled(Stack)(({ theme }) => ({
  gap: "24px",
  marginBottom: "48px",

  [`${theme.breakpoints.down("sm")}`]: {
    gap: "8px",
    margin: "-11px -15px 20px",
    padding: "11px 15px 11px",
    background: theme.palette.common.blackshades["30p"],
    borderBottom: `1px solid ${theme.palette.background.GFOutlineNav}`,
  },
}));

const TabActionIconButton = styled(Box)(({ theme }) => ({
  border: "none",
  borderRadius: "4px",
  height: "18px",
  width: "18px",
  dispaly: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "all 0.4s ease",

  svg: {
    width: "100%",
    height: "auto",
    display: "block",
  },

  "&:hover": {
    background: theme.palette.background.GFOutlineNav,
    color: theme.palette.text.primary,
  },
}));

const TabStyle = ({ title }: { title: string }) => {
  // const theme = useTheme();
  return (
    <Stack direction="row" spacing={1}>
      <Typography className="tab-text" sx={{ color: "#fff" }}>
        {title}
      </Typography>
      {/* <Typography
        sx={{ color: theme.palette.background.GF40 }}
        className="counter"
      >
        4
      </Typography> */}
    </Stack>
  );
};

const RegisterActionsHotKey: FC<{
  actions: FusionAction[];
  onActionClick(action: FusionAction): void;
}> = (props) => {
  const { actions, onActionClick } = props;

  return (
    <>
      {actions.map((action) =>
        action.action_hotkey ? (
          <RegisterHotKey
            hotkey={action.action_hotkey}
            onTrigger={() => onActionClick(action)}
          />
        ) : null
      )}
    </>
  );
};

const ActionsDropdown: FC<{
  tab: GuiTab;
  onActionClick(action: FusionAction): void;
  guiTabEditorRef: RefObject<AnimationLayoutRef>;
}> = (props) => {
  const { tab, onActionClick, guiTabEditorRef } = props;
  const actions =
    tab.tab_type === "record_list" ? tab.associated_actions ?? [] : [];

  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();

  const {
    open: openTabEditor,
    setSelectedTab,
    setSelectedFusionAction,
  } = useGuiTabEditorController();

  return (
    <FusionActionsDropdown
      actions={actions}
      editable={editModeEnabled}
      onActionClick={onActionClick}
      onEditClick={(action) => {
        setSelectedTab({ value: tab.tab_name });
        openTabEditor();
        setSelectedFusionAction(action);
        setTimeout(() => {
          guiTabEditorRef?.current?.reset();
          guiTabEditorRef.current?.gotoComponent({
            name: "reviewer-action-editor",
            id: action.id,
          });
        }, Infinity - 1);
      }}
      onAddClick={() => {
        setSelectedTab({ value: tab.tab_name });
        openTabEditor();
        setTimeout(() => {
          guiTabEditorRef?.current?.reset();
          guiTabEditorRef.current?.gotoComponent({
            name: "reviewer-action-editor",
            id: "reviewer-action-editor",
          });
        }, Infinity - 1);
      }}
    >
      <IconContainer>
        <MoreHoriz />
      </IconContainer>
    </FusionActionsDropdown>
  );
};

const BoxWrapper = styled(Box)(({ theme }) => {
  const navStyle = (theme as any).navStyle;
  const topNavHeight = (theme as any).topNavHeight;
  const sideNavWidth = (theme as any).sideNavWidth;

  let defaultLeft = 221;
  let defaultTop = 50;

  navStyle === "top"
    ? (defaultTop = defaultTop + topNavHeight)
    : (defaultLeft = defaultLeft + sideNavWidth);

  return {
    height: "100px",
    position: "fixed",
    left: `${defaultLeft}px`,
    top: `${defaultTop}px`,
    right: 0,
    background: "#1F2125",
    padding: "0 32px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    zIndex: 9,
  };
});
const MiddleComponent: React.FC<Props> = (props) => {
  const {
    onFormEvent,
    data,
    onNextClick,
    onPreviousClick,
    onActionClick,
    guiTabEditorRef,
  } = props;

  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();
  const { datasetSlug, slug: guiSlug } =
    useParams<{
      slug: string;
      datasetSlug?: string;
    }>();

  const { data: gui } = useGetItem({
    modelName: ApiModels.Gui,
    slug: guiSlug,
    queryOptions: { enabled: !!guiSlug },
  });
  const {
    data: guiTabs = [],
    refetch: refetchGuiTabs,
    isRefetching,
  } = useGuiTabs(gui?.slug);
  const { mutate: updateGuiTab } = useUpdateItem({
    modelName: ApiModels.GuiTab,
  });
  const { mutateAsync: duplicateGuiData } = useDuplicateGuiData();

  const { enqueueSnackbar } = useSnackbar();
  const selectedGuiTab = useSystemLayoutStore.useSelectedTopBarTab();
  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();
  const setTabEditorOpen = useGuiTabEditor.useSetEditorOpen();
  const setEditorPath = useGuiTabEditor.useSetEditorPath();
  const setAdditionalTabPath = useGuiTabEditor.useSetAdditionalTabPath();
  const { setSelectedTab } = useGuiTabEditorController();

  const guiTab = useMemo(() => {
    if (selectedGuiTab != null && guiTabs) {
      return guiTabs[Number(selectedGuiTab)];
    }
  }, [guiTabs, selectedGuiTab]);
  const hideGeneralTab = guiTab?.hide_general_tab;
  const isGuiTabRecordList = guiTab?.tab_type === "record_list";
  const datasetDesignSlug = isGuiTabRecordList
    ? guiTab.dataset_design_slug
    : undefined;

  const [value, setValue] = useState(Number(searchParams.get("t")) || 0);
  const popDatasetDraft = useDatasetStore.usePopDatasetDraft();

  const additionTabs = useMemo(
    () =>
      guiTab?.tab_type === "record_list" ? guiTab?.included_tabs : undefined,
    [guiTab]
  );

  const handleSettingsClick = useCallback(
    (index: number, tab: IncludeTabs) => {
      if (guiTab?.tab_name) {
        setSelectedTab({ value: guiTab.tab_name });
        setEditorPath([
          {
            id: "main",
            name: "main",
          },
          {
            id: tab.id,
            name: "additional-tabs",
          },
        ]);
        setAdditionalTabPath([`${index}`, "additional_tabs"]);
        searchParams.set("selectedTabId", tab.id);
        setSearchParams(searchParams);
        setTabEditorOpen(true);
      }
    },
    [
      guiTab?.tab_name,
      searchParams,
      setAdditionalTabPath,
      setEditorPath,
      setSearchParams,
      setSelectedTab,
      setTabEditorOpen,
    ]
  );

  const handleAddTab = useCallback(() => {
    if (guiTab?.tab_name) {
      setSelectedTab({ value: guiTab.tab_name });
      setEditorPath([
        {
          id: "main",
          name: "main",
        },
        {
          id: "additional-tabs",
          name: "additional-tabs",
        },
      ]);
      setAdditionalTabPath([]);
      searchParams.set("selectedTabId", "new-tab");
      setSearchParams(searchParams);
      setTabEditorOpen(true);
    }
  }, [
    guiTab?.tab_name,
    searchParams,
    setAdditionalTabPath,
    setEditorPath,
    setSearchParams,
    setSelectedTab,
    setTabEditorOpen,
  ]);

  const handleDeleteTab = useCallback(
    async (tabIndex: number) => {
      if (!guiTab?.slug || guiTab?.tab_type !== "record_list") {
        return;
      }
      confirmDelete().then(async (result) => {
        if (!result.isConfirmed || !guiTab?.included_tabs?.length) return;
        const datasetListSetting = clone(guiTab);
        const newTabs = removeArrayItem(
          clone(datasetListSetting?.included_tabs || []),
          tabIndex
        );
        set(datasetListSetting, "included_tabs", newTabs);
        updateGuiTab({
          slug: guiTab.slug,
          data: normalizeObjectForAPI({ ...guiTab, ...datasetListSetting }, [
            "gui_slug",
            "parent_tab_slug",
            "tab_type",
          ]),
        });
      });
    },
    [guiTab, updateGuiTab]
  );

  const handleDuplicateTab = useCallback(
    async (index: number, tab: IncludeTabs) => {
      if (guiTab?.slug && guiSlug) {
        await duplicateGuiData({
          type: "tab",
          tabIndex: index,
          tabSlug: guiTab?.slug,
        });
        await refetchGuiTabs();
        enqueueSnackbar({
          message: "Duplication in progress",
          variant: "success",
        });
      }
    },
    [guiTab?.slug, guiSlug, duplicateGuiData, refetchGuiTabs, enqueueSnackbar]
  );

  const tabLists = useMemo(() => {
    const tabs: TabProps[] = [];

    if (!hideGeneralTab) {
      tabs.push({
        label: <TabStyle title="General" />,
        value: 0,
      });
    }
    if (additionTabs)
      additionTabs.forEach((tab, index) => {
        const value = index + Number(!hideGeneralTab);
        tabs.push({
          label: <TabStyle title={tab.name} />,
          value,
          ...(editModeEnabled
            ? {
                iconPosition: "end",
                icon: (
                  <ActionMenu
                    menuItems={[
                      {
                        label: "Settings",
                        icon: <SettingsOutlined />,
                        value: "settings",
                      },
                      {
                        label: "Duplicate",
                        icon: <ContentCopyOutlined />,
                        value: "duplicate",
                      },
                    ]}
                    disablePortal
                    onItemClick={(key) => {
                      if (key === "delete") {
                        handleDeleteTab(index);
                      } else if (key === "settings") {
                        handleSettingsClick(index, tab);
                      } else if (key === "duplicate") {
                        handleDuplicateTab(index, tab);
                      }
                    }}
                    containerSx={{ ml: 1 }}
                  >
                    <TabActionIconButton>
                      <MoreHoriz fontSize="small" />
                    </TabActionIconButton>
                  </ActionMenu>
                ),
              }
            : {}),
        });
      });
    return tabs;
  }, [
    additionTabs,
    hideGeneralTab,
    editModeEnabled,
    handleSettingsClick,
    handleDeleteTab,
    handleDuplicateTab,
  ]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    popDatasetDraft();
  };

  const getTabDataIndex = (tabIndex: number, hideGeneralTab?: boolean) =>
    tabIndex - Number(!hideGeneralTab);

  const includedFieldIds = isGuiTabRecordList ? guiTab?.form_fields || [] : [];
  const selectedTab = additionTabs?.[getTabDataIndex(value, hideGeneralTab)];
  const tabKey = (!hideGeneralTab && value !== 0) || hideGeneralTab;
  return (
    <EditBox id="middle-component">
      {guiTab?.tab_type === "record_list" && guiTab?.associated_actions && (
        <RegisterActionsHotKey
          actions={guiTab.associated_actions}
          onActionClick={onActionClick}
        />
      )}
      <InnerAppLayout sx={{ height: "100%" }}>
        <InnerPageLayout
          sx={{
            height: "100%",

            ".edit-button-holder": {
              ".MuiIconButton-sizeSmall": {
                width: "28px",
                height: "28px",
                border: "1px solid #28262B",
                borderRadius: "4px",
                background: "none",
              },
            },
          }}
          hideHeader={true}
          onChange={handleChange}
          tabList={tabLists}
          value={value}
          tabsExtra={
            editModeEnabled ? (
              <Box className="edit-button-holder">
                <IconButtonWithTooltip
                  variant="outlined"
                  size="small"
                  tooltipProps={{ title: "Add Tab" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddTab();
                  }}
                >
                  <AddOutlined fontSize="small" />
                </IconButtonWithTooltip>
              </Box>
            ) : null
          }
          {...(guiTab?.tab_type === "record_list"
            ? {
                tabTopComponent: (
                  <BoxWrapper>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Stack direction="row" alignItems="center">
                        <AdUnits fontSize="medium" />
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: 600, ml: 2 }}
                        >
                          Play Button
                        </Typography>
                        <Stack
                          direction="row"
                          sx={{ ml: 16 }}
                          alignItems="center"
                          gap={1}
                        >
                          {guiTab.associated_actions
                            ?.filter((action) => !!action.action_pinned)
                            .map((action) => (
                              <Tooltip
                                title={`${action.action_title}${
                                  action.action_hotkey
                                    ? " (" + action.action_hotkey + ")"
                                    : ""
                                }`}
                              >
                                <IconContainer
                                  onClick={() => onActionClick(action)}
                                >
                                  <Icon iconName={action.action_icon} />
                                </IconContainer>
                              </Tooltip>
                            ))}
                          <ActionsDropdown
                            tab={guiTab}
                            onActionClick={onActionClick}
                            guiTabEditorRef={guiTabEditorRef}
                          />
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <IconContainer onClick={onPreviousClick}>
                          <ChevronLeft />
                        </IconContainer>
                        <IconContainer onClick={onNextClick}>
                          <ChevronRight />
                        </IconContainer>
                      </Stack>
                    </Stack>
                  </BoxWrapper>
                ),
                tabsSx: {
                  // top: "160px !important",

                  ".MuiTabs-flexContainer": {
                    alignItems: "center",
                  },

                  ".action-menu": {
                    ".MuiSvgIcon-root": {
                      color: theme.palette.text.secondary,
                    },
                    ".MuiPopover-paper": {
                      boxShadow: "none",
                      background: theme.palette.background.GFOutlineNav,
                      borderRadius: "6px",
                      overflow: "hidden",
                    },

                    "&__icon": {
                      svg: {
                        maxWidth: "18px",
                        height: "auto",
                        display: "block",
                      },
                    },

                    "&__label": {
                      color: theme.palette.text.primary,
                      fontSize: "14px",
                      lineHeight: "22px",
                    },

                    ".MuiList-root": {
                      padding: "0",
                      minWidth: "123px",

                      ".MuiButtonBase-root": {
                        height: "auto",
                        padding: "9px 13px",
                      },
                    },
                  },
                },
              }
            : {})}
        >
          <Scrollbar>
            {!hideGeneralTab && value === 0 && (
              <EditDataset
                onFormEvent={onFormEvent}
                data={data}
                datasetDesignSlug={datasetDesignSlug}
                datasetSlug={datasetSlug}
                guiSlug={guiSlug!}
                includedFieldIds={includedFieldIds}
                showHeader={false}
                tabMetadata={guiTab as unknown as IncludeTabs}
              />
            )}
            {tabKey && selectedTab?.tab_type === "iframe" && (
              <IFrameView
                selectedTabData={selectedTab}
                datasetSlug={datasetSlug}
                datasetDesignSlug={datasetDesignSlug}
              />
            )}
            {tabKey && selectedTab?.record_type === "chat_view" && (
              <ChatView
                selectedTabData={selectedTab}
                datasetSlug={datasetSlug}
                datasetDesignSlug={datasetDesignSlug}
              />
            )}
            {tabKey &&
              selectedTab?.record_type !== "chat_view" &&
              selectedTab?.tab_type !== "iframe" && (
                <EditDatasetExtended
                  onFormEvent={onFormEvent}
                  data={data}
                  datasetDesignSlug={datasetDesignSlug}
                  datasetSlug={datasetSlug}
                  guiSlug={guiSlug!}
                  selectedTabData={selectedTab}
                  key={value}
                  dataIndex={getTabDataIndex(value, hideGeneralTab)}
                />
              )}
          </Scrollbar>
        </InnerPageLayout>
      </InnerAppLayout>
    </EditBox>
  );
};

export default MiddleComponent;
