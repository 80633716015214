import { AddPhotoAlternate } from "@mui/icons-material";
import AddVideo from "assets/icons/AddVideo";
import { getAcceptedFileTypes } from "components/Form/helper";
import Uploader from "components/Uploader";
import { DocumentElementType } from "enums";
import React, { FC } from "react";

type UploadFieldProps = {
  field?: DataField;
  value?: any;
  disabled?: boolean;
  onChange?(files: FileMeta | FileMeta[]): void;
};

const UploadField: FC<UploadFieldProps> = (props) => {
  const { value, field, onChange, disabled } = props;
  return (
    <Uploader
      files={value}
      onChange={(files: FileMeta | FileMeta[]) => {
        onChange?.(files);
      }}
      sxProps={{ background: "#1e1e23 !important" }}
      accept={{
        [getAcceptedFileTypes(
          DocumentElementType.AudioVideo === field?.type
            ? field.file_type ?? "video"
            : field?.type
        )]: field?.accept ? field.accept?.split(",") : [],
      }}
      single={!field?.multi}
      multiple={field?.multi ?? false}
      maxFiles={field?.multi ? field.max_count : 1}
      maxSize={field?.max_size && field.max_size * 1024 * 1024} //Mb to bytes
      extra={{
        icon:
          DocumentElementType.Image === field?.type ? (
            <AddPhotoAlternate />
          ) : DocumentElementType.AudioVideo === field?.type ? (
            <AddVideo />
          ) : undefined,
        description:
          DocumentElementType.AudioVideo === field?.type ? (
            <React.Fragment>
              mp3,mp4,mov,avi,webm
              {field.max_size ? (
                <React.Fragment>
                  (max {field.max_size}
                  mb)
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : DocumentElementType.File === field?.type ? (
            <React.Fragment>
              {field.accept || "upload files here"}{" "}
              {field.max_size ? (
                <React.Fragment>
                  (max {field.max_size}
                  mb)
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : undefined,
      }}
      fieldId={field?.id}
      fieldSlug={field?.slug}
      displayType={field?.display_type}
      disabled={disabled}
    />
  );
};

export default UploadField;
