import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import { Box, IconButton, styled, Tab, Tabs, Typography } from "@mui/material";
import { FC, SyntheticEvent, useState } from "react";
import TaskScratchpads from "views/app-view/agent-chat/RightBar/JobDetails/TaskScratchpads";
import JobForm from "./JobForm";
import TaskList from "./TaskList";

const Container = styled(Box)(({ theme }) => ({
  padding: "16px",

  ".MuiTabs-flexContainer": {
    borderBottom: `1px solid ${theme.palette.gfGrey.Divider}`,

    ".MuiTab-root.MuiButtonBase-root": {
      fontWeight: 400,
      fontSize: "14px",

      "&.Mui-selected": {
        color: theme.palette.text.secondary,
      },
    },
  },

  ".MuiTabs-flexContainer .MuiTab-root.MuiButtonBase-root:not(:last-child):after":
    {
      content: "''",
      position: "absolute",
      top: "12px",
      right: "0",
      height: "16px",
      borderRight: `1px solid ${theme.palette.gfGrey.Divider}`,
    },
}));

type JobDetailsProps = {
  job?: Job;
  onBack(): void;
  onViewLogsClick(task: JobTask): void;
};

const JobDetails: FC<JobDetailsProps> = (props) => {
  const { job, onBack, onViewLogsClick } = props;
  const [selectedTab, setSelectedTab] = useState("0");

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <Container>
      <IconButton onClick={onBack} size="small" sx={{ p: 0 }}>
        <ArrowBackOutlined fontSize="small" />
      </IconButton>
      <Typography
        fontSize={16}
        fontWeight={400}
        lineHeight="28px"
        color="text.primary"
        my={1}
      >
        {job?.title}
      </Typography>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab value="0" label="Job Details" />
        <Tab value="1" label="Tasks" />
        <Tab value="2" label="Scratchpads" />
      </Tabs>
      {selectedTab === "0" && <JobForm job={job} />}
      {selectedTab === "1" && <TaskList onViewLogsClick={onViewLogsClick} />}
      {selectedTab === "2" && <TaskScratchpads job={job} />}
    </Container>
  );
};

export default JobDetails;
