import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";

export const useGuiParams = (guiSlug?: string) => {
  return useListItems({
    modelName: ApiModels.GuiParams,
    requestOptions: { query: { param_group_slug: guiSlug } },
    queryKey: [ApiModels.GuiParams, guiSlug],
    queryOptions: { enabled: !!guiSlug },
  });
};
