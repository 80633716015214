import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  INSERT_TAG_COMMAND,
  InsertTagPayload,
} from "components/LexicalTagField/AddTagPlugin";
import { TagNode } from "components/LexicalTagField/TagNode";
import { MappableTagType } from "enums/Fusion";
import { COMMAND_PRIORITY_HIGH, DROP_COMMAND } from "lexical";
import { useEffect } from "react";

export default function OnDropPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([TagNode])) {
      throw new Error("AddTagPlugin: TagNode not registered on editor");
    }

    return editor.registerCommand<DragEvent>(
      DROP_COMMAND,
      (event) => {
        event.preventDefault();
        const payload = JSON.parse(
          event.dataTransfer?.getData("text/plain") || "{}"
        ) as InsertTagPayload;
        if (
          [MappableTagType.Function, MappableTagType.Variable].includes(
            payload.type
          )
        ) {
          editor.dispatchCommand(INSERT_TAG_COMMAND, payload);
          return true;
        }

        return false;
      },
      COMMAND_PRIORITY_HIGH
    );
  }, [editor]);

  return null;
}
