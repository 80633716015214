import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { CustomCellRendererProps } from "ag-grid-react";
import DefaultTextCellDisplay from "components/DatasetTable/colDefs/DefaultTextCellDisplay";
import { FC, memo } from "react";

const FileCellRenderer: FC<CustomCellRendererProps> = memo((props) => (
  <DefaultTextCellDisplay
    data={props.data}
    text={props.value?.name}
    icon={<InsertDriveFileIcon />}
  />
));

export default FileCellRenderer;
