import { createTheme, Palette, Theme } from "@mui/material/styles";
import {
  commonPaletteColors,
  commonTypography,
  paletteWithExtraColors,
} from "theme/common";
import { defaultBlackTheme } from "theme/palette";
import shadows, { customShadows } from "theme/shadows";

const defaultPalette = defaultBlackTheme.palette;
export const darkPalette: Palette = {
  ...defaultPalette,
  mode: "dark",

  primary: {
    ...defaultPalette.primary,
    main: "rgba(140, 59, 45, 1)",
    light: "rgba(151, 78, 65, 1)",
    dark: "rgba(112, 47, 36, 1)",
    contrastText: "rgba(255, 255, 255, 0.87)",

    shades: {
      "8p": "rgba(140, 59, 45, 0.08)",
      "12p": "rgba(140, 59, 45, 0.12)",
      "16p": "rgba(140, 59, 45, 0.16)",
      "30p": "rgba(140, 59, 45, 0.3)",
      "50p": "rgba(140, 59, 45, 0.5)",
    },
  },
  secondary: {
    ...defaultPalette.secondary,
    main: "rgba(10, 140, 164, 1)",
    light: "rgba(34, 151, 173, 1)",
    dark: "rgba(10, 140, 164, 1)",
    contrastText: "rgba(184, 184, 184, 0.87)",
    shades: {
      "8p": "rgba(10, 140, 164, 0.08)",
      "12p": "rgba(10, 140, 164, 0.12)",
      "16p": "rgba(10, 140, 164, 0.16)",
      "30p": "rgba(10, 140, 164, 0.3)",
      "50p": "rgba(10, 140, 164, 0.5)",
    },
  },
  error: {
    ...defaultPalette.error,
    main: "rgba(211, 47, 175, 1)",
    light: "rgba(218, 94, 192, 1)",
    dark: "rgba(180, 40, 149, 1)",
    contrastText: "rgba(255, 255, 255, 1)",
    shades: {
      "8p": "rgba(211, 47, 175, 0.08)",
      "12p": "rgba(211, 47, 175, 0.12)",
      "30p": "rgba(211, 47, 175, 0.3)",
      "50p": "rgba(211, 47, 175, 0.5)",
      "160p": "rgba(211, 47, 175, 1)",
      "190p": "rgba(211, 47, 175, 1)",
    },
  },

  warning: {
    ...defaultPalette.warning,
    main: "rgba(161, 146, 5, 1)",
    light: "rgba(180, 169, 63, 1)",
    dark: "rgba(137, 124, 4, 1)",
    contrastText: "rgba(0, 0, 0, 0.87)",
    shades: {
      "8p": "rgba(161, 146, 5, 0.08)",
      "12p": "rgba(161, 146, 5, 0.12)",
      "30p": "rgba(161, 146, 5, 0.3)",
      "50p": "rgba(161, 146, 5, 0.5)",
      "160p": "rgba(161, 146, 5, 1)",
      "190p": "rgba(161, 146, 5, 1)",
    },
  },
  success: {
    ...defaultPalette.success,
    main: "rgba(18, 166, 25, 1)",
    light: "rgba(72, 184, 78, 1)",
    dark: "rgba(15, 141, 21, 1)",
    contrastText: "rgba(0, 0, 0, 0.87)",
    shades: {
      "8p": "rgba(18, 166, 25, 0.08)",
      "12p": "rgba(18, 166, 25, 0.12)",
      "30p": "rgba(18, 166, 25, 0.3)",
      "50p": "rgba(18, 166, 25, 0.5)",
      "160p": "rgba(18, 166, 25, 1)",
      "190p": "rgba(18, 166, 25, 1)",
    },
  },

  info: {
    ...defaultPalette.info,
    main: "rgba(10, 140, 164, 1)",
    light: "rgba(34, 151, 173, 1)",
    dark: "rgba(9, 119, 140, 1)",
    contrastText: "rgba(0, 0, 0, 0.87)",
    shades: {
      "8p": "rgba(10, 140, 164, 0.08)",
      "12p": "rgba(10, 140, 164, 0.12)",
      "30p": "rgba(10, 140, 164, 0.3)",
      "50p": "rgba(10, 140, 164, 0.5)",
      "160p": "rgba(10, 140, 164, 1)",
      "190p": "rgba(10, 140, 164, 1)",
    },
  },
  text: {
    ...defaultPalette.text,
    primary: "rgba(255, 255, 255, 1)",
    secondary: "rgba(205, 205, 206, 1)",
    disabled: "rgba(255, 255, 255, 0.5)",
    tab_inactive: "rgba(137, 137, 141, 1)",
    primary_shades: {
      "4p": "rgba(0, 0, 0, 0.04)",
      "12p": "rgba(255, 255, 255, 0.12)",
      "30p": "rgba(255, 255, 255, 0.3)",
    },
    secondary_shades: {
      "4p": "rgba(255, 255, 255, 0.04)",
      "18p": "rgba(255, 255, 255, 0.18)",
    },
  },

  action: {
    ...defaultPalette.action,
    hover: "rgba(255, 255, 255, 0.08)",
    disabledBackground: "rgba(255, 255, 255, 0.12)",
    focus: "rgba(255, 255, 255, 0.12)",
    selected: "rgba(255, 255, 255, 0.16)",
    disabled: "rgba(255, 255, 255, 0.3)",
    active: "rgba(255, 255, 255, 0.56)",
  },
  background: {
    ...defaultPalette.background,
    default: "rgba(18, 18, 18, 1)",
    paper: "rgba(19, 19, 27, 1)",
    GFBackground: "rgba(29, 26, 38, 1)",
    GFForeground: "rgba(50, 45, 65, 1)",
    GFPaper: "rgba(19, 19, 27, 1)",
    ContentArea: "rgba(19, 19, 27, 1)",
    LeftNavTop: "rgba(24, 24, 28, 1)",
    LeftNavBody: "rgba(30, 30, 35, 1)",
    GFRightNavBackground: "rgba(30, 30, 35, 1)",
    GFRightNavForeground: "rgba(47, 47, 54, 1)",
    GFSearch: "rgba(52, 52, 57, 1)",
    GFOverlay: "rgba(50, 46, 61, 1)",
    GFOutlineNav: "rgba(41, 41, 46, 1)",
    GFCardOutline: "rgba(87, 87, 91, 1)",
    GFCardBG: "rgba(30, 30, 35, 0.4)",
    GF36: "rgba(0, 0, 0, 0.36)",
    GFTopNav: "rgba(30, 30, 30, 1)",
    AvatarOutline: "rgba(90, 90, 96, 1)",
    GF80: "rgba(255, 255, 255, 0.8)",
    GF70: "rgba(255, 255, 255, 0.7)",
    GF60: "rgba(255, 255, 255, 0.6)",
    GF50: "rgba(255, 255, 255, 0.5)",
    GF40: "rgba(255, 255, 255, 0.4)",
    GF30: "rgba(255, 255, 255, 0.3)",
    GF20: "rgba(255, 255, 255, 0.2)",
    GF7: "rgba(255, 255, 255, 0.07)",
    GF5: "rgba(255, 255, 255, 0.05)",
    GF10: "rgba(255, 255, 255, 0.1)",
    Groups: "rgba(97, 97, 101, 1)",
    Setting: "rgba(64, 64, 69, 1)",
    LinearGF: "rgba(29,29,35,1)",
    Dashboard: "rgba(29,26,38,1)",
    // the following 2 are custom created colors need to be updated in each theme
    TableBG: "rgba(31,31,38,1)",
    TableCustomBG: "rgba(89, 89, 95, 1)",
    CardsCustomBG: "rgba(56, 51, 72, 0.56)",
    SubNavHoverBG: "rgba(22, 16, 36, 0.8)",
    presentationActive: "rgba(98, 94, 111, 1)",
    cardsBorder: "rgba(107, 121, 167, 1)",
    cardsBG: "rgba(107, 121, 167, 0.2)",

    InnerPageLayout: "rgba(30, 30, 35, 1)",
    TableHeader: "rgba(52, 52, 57, 1)",
    TableDivider: "rgba(72, 72, 76, 1)",
    TableBody: "rgba(39, 39, 44, 1)",
    PageBackground: "rgba(19, 19, 27, 1)",
    ScrollbarTrack: "rgba(30, 30, 35, 1)",
    ScrollbarThumb: "rgba(85, 89, 93, 1)",
    ScrollbarButton: "rgba(30, 30, 35, 1)",
    ColorPicker: "rgba(52, 52, 57, 1)",
    IconPicker: "rgba(52, 52, 57, 1)",
    Selected: "rgba(75, 75, 79, 1)",
    Hover: "rgba(52, 52, 57, 1)",
    ChatMessageBubble: "rgba(42, 44, 48, 1)",
    ChatInput: "rgba(41, 41, 46, 1)",
    CardBackground: "rgba(41, 41, 46, 1)",
    ListWidgetItem: "rgba(52, 52, 57, 1)",
    ListItem: "rgba(37, 37, 42, 1)",
    ListItemIcon: "rgba(44, 44, 49, 1)",
    DashboardWidgetBackground: "rgba(24, 24, 28, 1)",
    DashboardDatalistWidgetHeader: "rgba(33, 33, 37, 1)",
    ChartGridLine: "rgba(176, 176, 176, 0.13)",
    ChartAxisLabel: "rgba(242, 242, 242, 1)",
    ChartTitle: "rgba(255, 255, 255, 1)",
    ChartSubtitle: "rgba(255, 255, 255, 0.6)",
    ChartLegendLabel: "rgba(255, 255, 255, 1)",
  },

  common: {
    ...defaultPalette.common,
    // Need to confirm shades
    blackshades: {
      "4p": "rgba(0, 0, 0, 0.04)",
      "12p": "rgba(0, 0, 0, 0.12)",
      "30p": "rgba(0, 0, 0, 0.3)",
      "100p": "rgba(0, 0, 0, 1)",
    },
    whiteshades: {
      "8p": "rgba(255, 255, 255, 0.08)",
      "12p": "rgba(255, 255, 255, 0.12)",
      "30p": "rgba(255, 255, 255, 0.3)",
      "100p": "rgba(255, 255, 255, 1)",
    },
  },

  other: {
    divider: "rgba(255, 255, 255, 0.12)",
    outlinedBorder: "rgba(255, 255, 255, 0.23)",
    filledInputBackground: "rgba(255, 255, 255, 0.09)",
    standardInputLine: "rgba(255, 255, 255, 0.42)",
    snackbar: "rgba(50, 50, 50, 1)",
    ratingActive: "rgba(255, 180, 0, 1)",
    focusRingColor: "rgba(153, 200, 255, 1)",
    tableHeaderActionIcon: "rgba(255, 255, 255, 1)",
    tableRowActionIcon: "rgba(255, 255, 255, 1)",
    chatInputIconPrimary: "rgba(150, 150, 152, 1)",
    chatInputIconSecondary: "rgba(169, 160, 171, 1)",
  },
  gfGrey: {
    ...paletteWithExtraColors.gfGrey,
    GF50: "rgba(234, 238, 244, 1)",
    GF75: "rgba(212, 218, 226, 1)",
    GF100: "rgba(217, 219, 223, 1)",
    AvatarOutline: "rgba(90, 90, 96, 1)",
    "GF60%": "rgba(160, 160, 160, 1)",
    "GF40%": "rgba(113, 113, 118, 1)",
    Groups: "rgba(97, 97, 101, 1)",
    "GF50%": "rgba(136, 136, 136, 1)",
    "GF15%": "rgba(255, 255, 255, 0.15)",
    GFBoxer: "rgba(45, 45, 51, 1)",
    GFIcon: "rgba(165, 165, 167, 1)",
    Divider: "rgba(75, 75, 79, 1)",
  },
  deepPurple: {
    ...paletteWithExtraColors.deepPurple,
    GFLightPurple: "rgba(44, 23, 66, 1)",
    GFPurple: "rgba(89, 46, 132, 1)",
  },
  lightGreen: {
    ...paletteWithExtraColors.lightGreen,
    GFGreen: "rgba(124, 169, 53, 1)",
    GFShocking: "rgba(16, 249, 95, 1)",
    GFGreenLight: "rgba(68, 86, 42, 1)",
  },
  orange: {
    ...paletteWithExtraColors.orange,
    GFOrange: "rgba(168, 74, 6, 1)",
    GFOrangeLight: "rgba(92, 55, 27, 1)",
    GFOrangeBright: "rgba(187, 114, 34, 1)",
    Status: "rgba(255, 145, 0, 1)",
  },
  green: {
    ...paletteWithExtraColors.green,
    GFGreen: "rgba(124, 169, 53, 1)",
    GFGreenLight: "rgba(68, 86, 42, 1)",
    GFShocking: "rgba(16, 249, 95, 1)",
  },
  yellow: {
    ...paletteWithExtraColors.yellow,
    GFYellow: "rgba(182, 142, 5, 1)",
    GFYellowLight: "rgba(91, 75, 23, 1)",
  },
  blue: {
    ...paletteWithExtraColors.blue,
    GFBlue: "rgba(56, 54, 133, 1)",
    GFBlueLight: "rgba(40, 40, 74, 1)",
    Dropbox: "rgba(0, 97, 255, 1)",
    LightBlue: "rgba(79, 195, 247, 1)",
    GFDarkBlue: "rgba(41, 112, 139, 1)",
  },
  cyan: {
    ...paletteWithExtraColors.cyan,
    Fusion: "rgba(0, 184, 212, 1)",
  },
  red: {
    ...paletteWithExtraColors.red,
    GFRed: "rgba(107, 32, 19, 1)",
    Error: "rgba(255, 23, 68, 1)",
  },
  purple: {
    ...paletteWithExtraColors.purple,
    GFPurple: "rgba(89, 46, 132, 1)",
    GFPurpleLight: "rgba(44, 23, 66, 1)",
  },
  ...commonPaletteColors,
};
export const darkTheme = createTheme({
  ...defaultBlackTheme,
  palette: darkPalette,
  shadows: shadows.dark as typeof defaultBlackTheme.shadows,
  typography: {
    ...defaultBlackTheme.typography,
    ...commonTypography,
  } as typeof defaultBlackTheme.typography,
  customShadows: customShadows.dark,
}) as Theme;
