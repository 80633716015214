import AddOutlined from "@mui/icons-material/AddOutlined";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import TextFields from "@mui/icons-material/TextFields";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton, Stack, Typography, useTheme } from "@mui/material";
import ActionMenu from "components/ActionMenu";
import useOpenClose from "hooks/useOpenClose";
import { ApiModels } from "queries/apiModelMapping";
import useDeleteItem from "queries/useDeleteItem";
import useListInfiniteItems from "queries/useListInfiniteItems";
import { FC } from "react";
import useSSAgent from "store/stores/ss-agent";
import AddAgentDocument from "views/app-view/ss-agent/TabContent/AgentDocuments/AddAgentDocument";

type AgentDocumentsProps = {
  agent: Agent;
};

const AgentDocuments: FC<AgentDocumentsProps> = (props) => {
  const { agent } = props;

  const theme = useTheme();

  const setEditDrawer = useSSAgent.useSetEditDrawer();

  const [isDocumentModalOpen, openDocumentModal, closeDocumentModal] =
    useOpenClose();

  const {
    data: agentSkills,
    hasNextPage,
    fetchNextPage,
    isFetching,
    refetch,
  } = useListInfiniteItems({
    modelName: ApiModels.SSAgentDocument,
    queryKey: [ApiModels.SSAgentDocument, agent.slug],
    requestOptions: {
      query: {
        agent_slug: agent.slug,
      },
    },
  });

  const { mutateAsync: deleteAgentDocument } = useDeleteItem({
    modelName: ApiModels.SSAgentDocument,
  });

  const handleDelete = async (slug: string) => {
    await deleteAgentDocument({ slug });
    refetch();
  };

  return (
    <Stack
      direction="column"
      mb={2}
      gap={4}
      alignItems="flex-start"
      width="100%"
    >
      <Button
        variant="contained"
        startIcon={<AddOutlined />}
        onClick={openDocumentModal}
      >
        New Document
      </Button>
      <Stack direction="column" gap={1} width="100%">
        {agentSkills?.pages.map((page) =>
          page.data.map((doc) => (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              border="1px solid"
              borderColor={theme.palette.background.GF20}
              borderRadius="4px"
              height="50px"
              p={1}
            >
              <Stack direction="row" alignItems="center" gap={2}>
                <TextFields />
                <Stack direction="column" alignItems="flex-start">
                  <Typography fontSize={14} fontWeight={400}>
                    {doc.title}
                  </Typography>
                  <Typography
                    fontSize={12}
                    fontWeight={300}
                    color={theme.palette.background.GF60}
                    maxWidth="200px"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {doc.tag ?? doc.slug}
                  </Typography>
                </Stack>
              </Stack>
              <ActionMenu
                menuItems={[
                  {
                    label: "Edit",
                    icon: <EditOutlined />,
                    value: "edit",
                  },
                  {
                    label: "Delete",
                    icon: <DeleteOutline />,
                    value: "delete",
                  },
                ]}
                onItemClick={(value) => {
                  if (value === "delete") {
                    handleDelete(doc.slug);
                  } else if (value === "edit") {
                    setEditDrawer({
                      enabled: true,
                      type: "document",
                      document: doc,
                    });
                  }
                }}
              >
                <IconButton sx={{ p: 0 }}>
                  <MoreHoriz />
                </IconButton>
              </ActionMenu>
            </Stack>
          ))
        )}
        <LoadingButton
          variant="outlined"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage}
          fullWidth
          loading={isFetching}
        >
          Load More
        </LoadingButton>
      </Stack>
      <AddAgentDocument
        open={isDocumentModalOpen}
        onClose={closeDocumentModal}
        agent={agent}
        onCreated={() => {
          refetch();
        }}
      />
    </Stack>
  );
};

export default AgentDocuments;
