import { CSS, Transform } from "@dnd-kit/utilities";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { SortableList } from "components/SortableList";
import { ContainerProps, Handle } from "components/drag-drop/components";
import React, {
  FC,
  MouseEvent,
  PropsWithChildren,
  forwardRef,
  memo,
} from "react";

const AccordionStyled = styled(Accordion)({
  boxShadow: "none !important",
  background: "none !important",
  borderRadius: "0 !important",
});

const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
  minHeight: "32px",
  boxShadow: "none",
  flexDirection: "row-reverse",
  padding: "0",
  "&:hover": {
    // ".MuiTypography-root ": {
    //   color: theme.palette.background.GF60,
    // },

    // ".MuiAccordionSummary-expandIconWrapper": {
    //   color: theme.palette.background.GF60,
    // },
  },

  ".draggable-handle ": {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    // right: "50px",
    width: "auto",
    opacity: 0,
  },

  "&.Mui-expanded": {
    minHeight: "32px",

    ".MuiAccordionSummary-content": {
      margin: "5px 0",

      [`${theme.breakpoints.only("sm")},${theme.breakpoints.only("md")}`]: {
        margin: "0",
      },
    },
  },

  ".MuiAccordionSummary-content": {
    margin: "5px 0",
    alignItems: "center",
    justifyContent: "space-around",
    [`${theme.breakpoints.only("sm")},${theme.breakpoints.only("md")}`]: {
      margin: "0",
    },
  },

  ".MuiPaper-root": {
    boxShadow: "none",
  },

  ".MuiAccordionSummary-expandIconWrapper": {
    width: "15px",
    marginRight: "5px",
    marginLeft: "9px",
    transform: "rotate(-90deg)",
    color: theme.palette.background.Groups,

    "&.Mui-expanded": {
      transform: "rotate(0)",
    },

    svg: {
      width: "100%",
      height: "auto",
    },
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  fontWeight: "600",
  color: theme.palette.background.Groups,
  textTransform: "uppercase",
  transition: "all 0.4s ease",
  flex: 1,
}));

type MenuListContainerProps = PropsWithChildren<
  {
    title: string;
    enableContextMenu?: boolean;
    enableItemOptionMenu?: boolean;

    handleContextMenu: (_: MouseEvent<HTMLDivElement>, id: string) => void;
  } & ContainerProps
>;

const MenuListContainer: FC<MenuListContainerProps> = memo(
  forwardRef(
    (
      {
        children,
        title,
        style,
        transform,
        transition,
        id,
        isDragging,
        handleContextMenu,
        enableContextMenu,
        enableItemOptionMenu,
      },
      ref
    ) => {
      const [isExpended, setIsExpended] = React.useState(false);
      const onContextMenuHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleContextMenu?.(e, id as string);
      };
      return (
        <Stack
          gap={1.25}
          sx={{ minWidth: "0" }}
          id={id as string}
          onContextMenu={
            enableContextMenu ? (e) => onContextMenuHandler(e) : undefined
          }
        >
          <Box ref={ref} className="box-holder">
            <AccordionStyled
              expanded={isExpended && !isDragging}
              onChange={() => setIsExpended((ex) => !ex)}
              style={{
                ...style,
                transition,
                transform: CSS.Translate.toString(transform as Transform),
                opacity: isDragging ? 0.5 : undefined,
              }}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummaryStyled
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <SortableList.DragHandle>
                  <Handle />
                </SortableList.DragHandle>
                <Title>{title}</Title>
                {enableItemOptionMenu && (
                  <Box
                    className="parent_more_icon"
                    onClick={onContextMenuHandler}
                  >
                    <MoreHoriz
                      fontSize="medium"
                      sx={{ color: (theme) => theme.palette.text.secondary }}
                    />
                  </Box>
                )}
              </AccordionSummaryStyled>

              <AccordionDetails sx={{ p: 0, marginTop: "10px" }}>
                <List sx={{ p: 0 }}>{children}</List>
              </AccordionDetails>
            </AccordionStyled>
          </Box>
        </Stack>
      );
    }
  )
);

export default MenuListContainer;
