import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { Avatar, IconButton, Stack, Typography, styled } from "@mui/material";
import useAppNavigate from "hooks/useAppNavigate";
import useThreadAgent from "queries/copilot/useThreadAgent";
import { FC } from "react";
import useAgentChatStore from "store/stores/agent-chat";

const Container = styled(Stack)(({ theme }) => ({
  height: "48px",
  cursor: "pointer",
  borderRadius: "4px",
  paddingLeft: "8px",
  paddingRight: "8px",
  marginBottom: "4px",

  ".btn-action": {
    padding: 0,
    opacity: 0,
    pointerEvents: "none",
    transition: "opacity 0.2s ease",
    borderRadius: "2px",
  },

  ".txt-summary": {
    "&:before": {
      content: "''",
      position: "absolute",
      right: 0,
      top: 0,
      width: "48px",
      height: "24px",
      background: `linear-gradient(270deg, ${theme.palette.background.LeftNavBody} 30%, ${theme.palette.background.LeftNavBody} 40%, rgba(255,255,255,0) 100%)`,
    },
  },

  "&:hover": {
    ".btn-action": {
      opacity: 1,
      pointerEvents: "all",
      color: theme.palette.text.secondary,
    },

    background: theme.palette.background.Hover,

    ".txt-title": {
      color: theme.palette.text.primary,
    },

    ".txt-summary": {
      color: theme.palette.text.secondary,

      "&:before": {
        background: `linear-gradient(270deg, ${theme.palette.background.Hover} 30%, ${theme.palette.background.Hover} 40%, rgba(255,255,255,0) 100%)`,
      },
    },
  },

  "&.selected": {
    background: theme.palette.background.Selected,

    ".txt-title": {
      color: theme.palette.text.primary,
    },

    ".txt-summary": {
      color: theme.palette.text.secondary,

      "&:before": {
        background: `linear-gradient(270deg, ${theme.palette.background.Selected} 30%, ${theme.palette.background.Selected} 40%, rgba(255,255,255,0) 100%)`,
      },
    },
  },
}));

type ListItemProps = {
  thread: CopilotThread;
};

const ListItem: FC<ListItemProps> = (props) => {
  const { thread } = props;

  const navigate = useAppNavigate();

  const selectedThread = useAgentChatStore.useSelectedThread();
  const setSelectedThread = useAgentChatStore.useSetSelectedThread();

  const agent = useThreadAgent(thread.agent_slug);

  return (
    <Container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={1}
      className={selectedThread?.id === thread.id ? "selected" : ""}
      onClick={() => {
        setSelectedThread(thread);
        navigate(`/agent-chat/${thread.id}`);
      }}
    >
      <Avatar
        src={agent?.image?.url ?? "https://mui.com/static/images/avatar/1.jpg"}
        variant="circular"
        sx={{ width: "32px", height: "32px" }}
      />
      <Stack direction="column" alignItems="flex-start" flex={1} minWidth={0}>
        <Typography
          fontSize="13px"
          fontWeight={500}
          color="text.primary"
          className="txt-title"
        >
          {agent?.agent_name ?? "Copilot"}
        </Typography>
        <Typography
          overflow="hidden"
          textOverflow="clip"
          whiteSpace="nowrap"
          fontSize="12px"
          fontWeight={400}
          color="text.secondary"
          position="relative"
          className="txt-summary"
          width="100%"
        >
          {thread.summary}
        </Typography>
      </Stack>
      <IconButton className="btn-action">
        <MoreHoriz />
      </IconButton>
    </Container>
  );
};

export default ListItem;
