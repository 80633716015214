import { zodResolver } from "@hookform/resolvers/zod";
import { Settings } from "@mui/icons-material";
import { Box, DrawerProps, styled } from "@mui/material";
import FusionLinkButton from "components/FusionLinkButton";
import GfDrawer from "components/GfDrawer";
import Scrollbar from "components/Scrollbar";
import AnimationLayout, { Config } from "layouts/AnimationLayout";
import { debounce } from "lodash";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import React, { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import { z } from "zod";
import GuiEditForm from "./GuiEditForm";
import AddGuiParams from "./TabEditorDrawer/AddGuiParam";
import EditGuiParams from "./TabEditorDrawer/EditGuiParam";
import ParamsList from "./TabEditorDrawer/ParamsList";

const DrawerBox = styled(GfDrawer)(({ theme }) => ({
  ".MuiDrawer-paper ": {
    ".MuiPaper-root ": {
      background: `${theme.palette.background.GFTopNav} !important`,
      boxShadow: "none",

      ".MuiCard-root": {
        background: `${theme.palette.background.GFRightNavForeground} !important`,
        transition: "all 0.4s ease",

        "&:hover": {
          background: `${theme.palette.background.GF20} !important`,
        },
      },
    },

    ".drawer-head": {
      background: theme.palette.background.GFTopNav,
      padding: "15px 20px",
      borderBottom: `1px solid ${theme.palette.other?.divider}`,

      ".drawer-icon-holder": {
        width: "24px",
        height: "24px",
        background: "none",
      },

      ".drawer-title-holder": {
        padding: "0 0 0 11px",
      },
    },

    ".draggable-handle": {
      padding: "0",
    },
  },
}));

const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 60px)`,
    overflow: "hidden",
  };
});

const formSchema = z.object({
  name: z.string({ required_error: "title is required" }),
  description: z.string({ required_error: "description is required" }),
  color: z.string().optional(),
  icon: z.string().optional(),
});
type FormType = z.infer<typeof formSchema>;

type GuiSettingsDrawerProps = {} & Omit<DrawerProps, "onSubmit">;

const GuiSettingsDrawer: React.FC<GuiSettingsDrawerProps> = (props) => {
  const { open, onClose } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { slug } = useParams<{ slug: string }>();

  const { data: gui } = useGetItem({
    modelName: ApiModels.Gui,
    slug: slug,
  });

  const { mutate: updateGui } = useUpdateItem({
    modelName: ApiModels.Gui,
    mutationOptions: {
      mutationKey: [ApiModels.Gui],
    },
  });

  const form = useForm<Partial<FormType>>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: gui,
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = form;

  const submitHandler = useCallback(
    (data: Partial<GfGui>) => {
      if (slug) {
        updateGui({ slug, data });
      }
    },
    [slug]
  );

  useEffect(() => {
    if (gui) {
      reset(gui);
    }
  }, [reset, gui]);

  useEffect(() => {
    const submitDeb = debounce(() => {
      handleSubmit(submitHandler)();
    }, 600);
    const subscription = watch((_, { name }) => {
      switch (name) {
        case "color":
        case "icon":
          submitDeb();
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, submitHandler, handleSubmit]);

  const getComponents: Config["getComponents"] = useCallback(
    (gotoComponent, goBack) => {
      return {
        main: (
          <React.Fragment>
            <FormProvider {...form}>
              <form onSubmit={handleSubmit(submitHandler)}>
                <GuiEditForm
                  register={register}
                  errors={errors}
                  control={control}
                />
              </form>
            </FormProvider>
            {gui?.page_load_fusion && (
              <Box sx={{ m: 2 }}>
                <FusionLinkButton
                  title="Edit Page Load Fusion"
                  fusionSlug={gui?.page_load_fusion}
                />
              </Box>
            )}
            <Box
              sx={{
                px: 2,
              }}
            >
              <ParamsList
                gui={gui!}
                onAddClick={() => {
                  gotoComponent({
                    id: "add-param",
                    name: "add-param",
                  });
                }}
                onEditClick={(props: GuiParams) => {
                  searchParams.set("paramSlug", props.slug);
                  setSearchParams(searchParams);
                  setTimeout(() => {
                    gotoComponent({
                      id: "edit-param",
                      name: "edit-param",
                    });
                  }, 0);
                }}
              />
            </Box>
          </React.Fragment>
        ),
        "add-param": <AddGuiParams gui={gui!} onBackClick={goBack} />,
        "edit-param": (
          <EditGuiParams
            onBackClick={() => {
              searchParams.delete("paramSlug");
              setSearchParams(searchParams);
              goBack();
            }}
          />
        ),
      };
    },
    [gui, searchParams, setSearchParams]
  );
  return (
    <DrawerBox
      anchor={"right"}
      open={open}
      width="420px"
      title="Gui Settings"
      icon={<Settings />}
      onClose={onClose}
      closeIcon={false}
    >
      <ScrollbarParent>
        <Scrollbar>
          {gui && (
            <AnimationLayout
              urlQueryKey="s"
              enableScrollbar={false}
              config={{
                initialComponent: "main",
                getComponents: getComponents,
              }}
            />
          )}
        </Scrollbar>
      </ScrollbarParent>
    </DrawerBox>
  );
};

export default GuiSettingsDrawer;
