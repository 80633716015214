import ExpandLess from "@mui/icons-material/ExpandLess";
import { Avatar, IconButton, Stack, Typography, styled } from "@mui/material";
import useThreadAgent from "queries/copilot/useThreadAgent";
import { FC } from "react";
import useAgentChatStore from "store/stores/agent-chat";

const Container = styled(Stack)({
  padding: "16px",
});

type AgentDetailsProps = {
  isCollapsed: boolean;
  setIsCollapsed(value: boolean): void;
};

const AgentDetails: FC<AgentDetailsProps> = (props) => {
  const { isCollapsed, setIsCollapsed } = props;
  const thread = useAgentChatStore.useSelectedThread();

  const agent = useThreadAgent(thread?.agent_slug);

  return (
    <Container gap={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" gap={2}>
          <Avatar
            src={
              agent?.image?.url ?? "https://mui.com/static/images/avatar/1.jpg"
            }
            variant="circular"
            sx={{ width: "24px", height: "24px" }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontWeight: "500 !important",
              fontSize: "13px !important",
            }}
          >
            {agent?.agent_name ?? "Copilot"}
          </Typography>
        </Stack>
        <IconButton
          sx={{
            p: 0,
            transform: isCollapsed ? undefined : "rotate(180deg)",
            transition: "transform 0.2s ease",
          }}
          size="small"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <ExpandLess fontSize="small" />
        </IconButton>
      </Stack>
      <Typography
        fontSize={15}
        fontWeight={400}
        sx={{ color: (theme) => theme.palette.text.secondary }}
      >
        {agent?.description}
      </Typography>
    </Container>
  );
};

export default AgentDetails;
