import { ColDef } from "ag-grid-community";

const getTextAreaColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => {
  return {
    ...defaults,
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
  };
};

export default getTextAreaColDef;
