export enum GuiType {
  Dashboard = "dashboard",
  DashboardV2 = "dashboard_v2",
  RecordList = "record_list",
  WorkflowBoard = "workflow_board",
  Reviewer = "reviewer",
  GuiDoc = "gui_doc",
}
export enum CropperType {
  None = "none",
  FixAll = "fix_all",
  FixWidthOnly = "fix_width_only",
  FixHeightOnly = "fix_height_only",
}

export enum ActionType {
  OpenForm = "open_form",
  RunFusion = "run_fusion",
}
