import { Box, styled } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import AddAssociatedField from "components/AddAssociatedField";
import FusionActionEditor, {
  FusionActionForm,
} from "components/FusionAction/FusionActionEditor";
import Scrollbar from "components/Scrollbar";
import Spin from "components/Spin";
import AnimationLayout, { GetComponents } from "layouts/AnimationLayout";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import useDeleteItem from "queries/useDeleteItem";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useCallback, useState } from "react";
import useFusionAction from "store/stores/fusion-action";

const ScrollbarParent = styled(Box)({
  height: `calc(100vh - 60px)`,
  overflow: "hidden",
});

type ActionEditorProps = {
  loading?: boolean;
  onSubmit(action: FusionActionForm): Promise<void>;
  onCancel(): void;
};

const ActionEditor: FC<ActionEditorProps> = (props) => {
  const { loading, onSubmit, onCancel } = props;
  const queryClient = useQueryClient();

  const action = useFusionAction.useEditorAction();

  const { mutateAsync: deleteFormDesign, isLoading: isDeletingFormDesign } =
    useDeleteItem({
      modelName: ApiModels.FormDesign,
    });
  const { mutateAsync: updateFormDesign, isLoading: isUpdatingFormDesign } =
    useUpdateItem({
      modelName: ApiModels.FormDesign,
    });
  const { mutateAsync: createFormDesign, isLoading: isCreatingFormDesign } =
    useCreateItem({
      modelName: ApiModels.FormDesign,
    });

  const spinning =
    isCreatingFormDesign ||
    isDeletingFormDesign ||
    isUpdatingFormDesign ||
    loading;

  const [selectedField, setSelectedField] =
    useState<DataField & { db_slug: string }>();

  const handleClose = useCallback(() => {
    onCancel();
    setSelectedField(undefined);
  }, [onCancel]);

  const handleDeleteField = useCallback(
    async (slug: string) => {
      await deleteFormDesign({ slug });

      queryClient.refetchQueries({
        queryKey: [ApiModels.FormDesign, action?.id],
      });
    },
    [action?.id, deleteFormDesign, queryClient]
  );

  const handleFieldUpdate = useCallback(
    async (updates: Partial<DataField>) => {
      if (!selectedField) {
        return;
      }

      const { title, default_value, tooltip, id, slug, ...rest } = updates;

      const metadata = rest as Record<string, unknown>;

      if (metadata.db_slug) {
        delete metadata.db_slug;
      }
      if (metadata.field_slug) {
        delete metadata.field_slug;
      }

      await updateFormDesign({
        slug: selectedField?.db_slug,
        data: {
          title,
          default_value,
          tooltip,
          metadata,
        },
      });

      queryClient.refetchQueries({
        queryKey: [ApiModels.FormDesign, action?.id],
      });
    },
    [action?.id, queryClient, selectedField, updateFormDesign]
  );

  const handleFieldCreate = useCallback(
    async (updates: Partial<DataField>) => {
      const { title, default_value, tooltip, type, id, slug, ...rest } =
        updates;

      const metadata = rest as Record<string, unknown>;

      if (metadata.db_slug) {
        delete metadata.db_slug;
      }
      if (metadata.field_slug) {
        delete metadata.field_slug;
      }

      await createFormDesign({
        field_slug: slug,
        title,
        type,
        default_value,
        tooltip,
        metadata,
        action_slug: action?.id,
      });

      queryClient.refetchQueries({
        queryKey: [ApiModels.FormDesign, action?.id],
      });
    },
    [action?.id, createFormDesign, queryClient]
  );

  const getComponents: GetComponents = useCallback(
    (gotoComponent, goBack) => ({
      main: (
        <FusionActionEditor
          action={action}
          onSubmit={onSubmit}
          onCancel={handleClose}
          onAddField={() => {
            gotoComponent({
              name: "add-field",
              id: "add-field",
            });
          }}
          onEditField={(field) => {
            setSelectedField({
              ...field,
              slug: field.field_slug,
              db_slug: field.slug,
              ...field.metadata,
            });
            gotoComponent({
              name: "add-field",
              id: field.slug,
            });
          }}
          onDeleteField={handleDeleteField}
        />
      ),
      "add-field": (
        <AddAssociatedField
          onBackClick={() => {
            goBack();
            setSelectedField(undefined);
          }}
          onSubmit={async (data) => {
            if (selectedField) {
              await handleFieldUpdate(data);
            } else {
              await handleFieldCreate(data);
            }

            goBack();
          }}
          dataField={selectedField}
          allowDefault
        />
      ),
    }),
    [
      action,
      handleClose,
      handleDeleteField,
      handleFieldCreate,
      handleFieldUpdate,
      onSubmit,
      selectedField,
    ]
  );

  return (
    <Spin spinning={spinning}>
      <ScrollbarParent>
        <Scrollbar>
          <AnimationLayout
            enableScrollbar={false}
            updateUrl={false}
            config={{
              getComponents,
              initialComponent: "main",
            }}
          />
        </Scrollbar>
      </ScrollbarParent>
    </Spin>
  );
};

export default ActionEditor;
