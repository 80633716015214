import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import { ListItem } from "@mui/material";
import ActionMenu from "components/ActionMenu";
import useOpenClose from "hooks/useOpenClose";
import { FC } from "react";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import useGuiParamsStore from "store/stores/gui-prams";
import GuiSettingsDrawer from "./GuiSettingsDrawer";

type GuiSettingsButtonProps = {};

const GuiSettingsButton: FC<GuiSettingsButtonProps> = (props) => {
  const [
    isGuiSettingsDrawerOpen,
    openGuiSettingsDrawer,
    closeGuiSettingsDrawer,
  ] = useOpenClose();

  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();
  const setIsParamEditorOpen = useGuiParamsStore.useSetIsParamEditorOpen();

  if (!editModeEnabled) {
    return <></>;
  }

  return (
    <>
      <ListItem className="top-bar-list-item">
        <ActionMenu
          menuItems={[
            {
              label: "Gui Settings",
              value: "gui-settings",
              icon: <SettingsOutlined />,
            },
            {
              label: "Filter Editor",
              value: "filter-editor",
              icon: <FilterAltOutlined />,
            },
          ]}
          onItemClick={(value) => {
            if (value === "gui-settings") {
              openGuiSettingsDrawer();
            } else if (value === "filter-editor") {
              setIsParamEditorOpen(true);
            }
          }}
        >
          <SettingsOutlined />
        </ActionMenu>
      </ListItem>
      <GuiSettingsDrawer
        open={isGuiSettingsDrawerOpen}
        onClose={closeGuiSettingsDrawer}
      />
    </>
  );
};

export default GuiSettingsButton;
