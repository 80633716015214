import { RefreshOutlined } from "@mui/icons-material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ContentCopyOutlined from "@mui/icons-material/ContentCopyOutlined";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import MoreVert from "@mui/icons-material/MoreVert";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import ActionMenu from "components/ActionMenu";
import { DynamicEditFormProps } from "components/Form/DynamicEditForm";
import FusionActionsDropdown from "components/FusionAction/FusionActionsDropdown";
import { findTabPath } from "helpers/gui";
import useDatasetGuiTabData from "hooks/useDatasetGuiTabData";
import { TransitionComponent } from "layouts/AnimationLayout";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import { ApiModels } from "queries/apiModelMapping";
import useDuplicateGuiData from "queries/gui/useDuplicateGuiData";
import { useGuiTabs } from "queries/gui/useGuiTabs";
import useListInfiniteItems from "queries/useListInfiniteItems";
import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useSystemLayoutStore from "store";
import useGuiComponentPaths from "store/stores/gui-component-paths";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import useGuiParamsStore from "store/stores/gui-prams";
import useGuiTabEditor from "store/stores/gui-tab-editor";

type DatasetSearchListProps = {
  datasets: Dataset[];
  tab?: IncludeTabs;
  guiSlug?: string;
  isLoading?: boolean;
  onEdit(dataset: Dataset): void;
  onLoadMore(): void;
  onSearch(value: string): void;
};

const DatasetSearchList: FC<DatasetSearchListProps> = (props) => {
  const { datasets, tab, guiSlug, isLoading, onLoadMore, onSearch, onEdit } =
    props;

  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();

  const { data: guiTabs = [] } = useGuiTabs(guiSlug);

  const [searchValue, setSearchValue] = useState("");

  const debouncedOnSearch = useMemo(() => debounce(onSearch, 1000), [onSearch]);

  const tabPath = useMemo(
    () => (tab?.id ? findTabPath(guiTabs, tab.id) : ""),
    [guiTabs, tab?.id]
  );

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    debouncedOnSearch(e.target.value);
  };

  return (
    <Box
      sx={{
        background: "#29292e",
        borderRadius: "4px",
      }}
    >
      <Stack justifyContent="center" alignItems="center" sx={{ px: 3, py: 2 }}>
        <TextField
          variant="outlined"
          placeholder="Search"
          fullWidth
          value={searchValue}
          onChange={handleSearchChange}
          sx={{
            background: "#343439",
            borderRadius: "32px",

            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
          InputProps={{
            sx: {
              height: "32px",
              px: 0.5,
              py: 0.5,
              fontSize: "14px",
            },
          }}
        />
      </Stack>
      <Stack gap={1} sx={{ background: "#1e1e23", mx: "2px", p: 1 }}>
        {datasets.map((dataset) => (
          <Stack
            key={dataset.slug}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              height: "34px",
              px: 1,

              ":hover": {
                borderRadius: "4px",
                background: "#29292e",
              },
            }}
            onClick={() => {
              onEdit(dataset);
            }}
          >
            <Typography
              color="#d4d4d5"
              fontSize={14}
              fontWeight={400}
              lineHeight="34px"
            >
              {dataset.title}
            </Typography>
            <FusionActionsDropdown
              actions={tab?.associated_actions ?? []}
              editable={editModeEnabled}
              pathPrefix={tabPath}
              dataset={dataset}
            >
              <IconButton
                sx={{
                  color: "#47474b",
                  height: "24px",
                  width: "24px",
                  borderRadius: "4px",

                  ":hover": {
                    background: "#4b4b4f",
                    color: "#ffffff",
                  },
                }}
              >
                <MoreHoriz fontSize="medium" />
              </IconButton>
            </FusionActionsDropdown>
          </Stack>
        ))}
      </Stack>
      <LoadingButton
        sx={{
          height: 24,
          p: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#d4d4d5",
        }}
        loading={isLoading}
        fullWidth
        startIcon={<ArrowDropDown />}
        onClick={onLoadMore}
      >
        Load More
      </LoadingButton>
    </Box>
  );
};

type ListDatasetWidgetProps = {
  includedFieldIds?: string[];
  datasetDesignSlug: string;
  datasetQueryFilter: { [key: string]: any };
  onFormEvent?: DynamicEditFormProps["onFormEvent"];
  guiSlug?: string;
  tab?: IncludeTabs;
  onEditHandler: (item?: Record<string, any>) => void;
  index: number;
  isRightWidget?: boolean;
};

const ListDatasetWidget: FC<ListDatasetWidgetProps> = (props) => {
  const {
    onEditHandler,
    datasetDesignSlug: slug,
    datasetQueryFilter,
    tab,
    guiSlug,
    index,
    isRightWidget = true,
  } = props;

  const { datasetSlug } =
    useParams<{
      datasetSlug: string;
    }>();
  const { enqueueSnackbar } = useSnackbar();

  const [searchValue, setSearchValue] = useState("");

  const guiParamValues = useGuiParamsStore.useGuiParamValues();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedGuiTab = useSystemLayoutStore.useSelectedTopBarTab();
  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();
  const rightWidgetPaths = useGuiComponentPaths.useRightWidgetsPath();
  const middleWidgetsPath = useGuiComponentPaths.useMiddleWidgetsPath();
  const setTabEditorOpen = useGuiTabEditor.useSetEditorOpen();
  const setAdditionalTabPath = useGuiTabEditor.useSetAdditionalTabPath();
  const setEditorPath = useGuiTabEditor.useSetEditorPath();
  const parentGuiTab = useDatasetGuiTabData();
  const parentDatasetDesignSlug =
    parentGuiTab?.tab_type === "record_list"
      ? parentGuiTab.dataset_design_slug
      : undefined;

  const { data: guiTabs = [], refetch: refetchGuiTabs } = useGuiTabs(guiSlug);
  const { mutateAsync: duplicateGuiData } = useDuplicateGuiData();

  const guiTab = useMemo(() => {
    if (selectedGuiTab != null && guiTabs) {
      return guiTabs[Number(selectedGuiTab)];
    }
  }, [guiTabs, selectedGuiTab]);
  const hideGeneralTab = guiTab?.hide_general_tab;

  const {
    data: datasetResult,
    fetchNextPage,
    hasNextPage,
    isFetching,
    refetch,
  } = useListInfiniteItems({
    modelName: ApiModels.Dataset,
    requestOptions: {
      query: {
        dataset_type_slug: slug,
        ...datasetQueryFilter,
        title: searchValue,
        parent_dataset_design_slug: parentDatasetDesignSlug,
        parent_dataset_id: datasetSlug,
        ...guiParamValues,
        gui_slug: guiSlug,
      },
      path: `list/${slug}`,
    },
    queryKey: [
      ApiModels.Dataset,
      {
        dataset_type_slug: slug,
        ...datasetQueryFilter,
        title: searchValue,
        parent_dataset_design_slug: parentDatasetDesignSlug,
        parent_dataset_id: datasetSlug,
        ...guiParamValues,
      },
    ],
  });

  const datasets = useMemo(() => {
    const paginatedData: Dataset[] = [];
    datasetResult?.pages.forEach((group) => {
      group?.data?.forEach((data) => {
        paginatedData.push(data);
      });
    });
    return paginatedData;
  }, [datasetResult]);

  const handleEditWidget = useCallback(() => {
    const paths = isRightWidget ? rightWidgetPaths : middleWidgetsPath;
    if (paths.length && tab) {
      searchParams.set("selectedTabId", tab.id);
      setSearchParams(searchParams);
      const additionalPath = [
        ...paths.map((p) => `${p}`),
        "additional_tabs",
        `${index}`,
        "additional_tabs",
      ];
      setAdditionalTabPath(additionalPath.slice(1));
      const compHistory: TransitionComponent[] = [];
      for (let i = 0; i < additionalPath.length; i += 2) {
        const propName = additionalPath[i];
        const propIndex = Number(additionalPath[i + 1]);
        if (propName && !isNaN(propIndex)) {
          if (propName === "included_sidebar_widgets") {
            compHistory.push({ name: "main", id: "main" });
          } else {
            compHistory.push({
              name: "additional-widget",
              id: tab.id,
            });
          }
        }
      }
      setEditorPath(compHistory);
      setTabEditorOpen(true);
    } else if (tab) {
      searchParams.set("selectedTabId", tab.id);
      setSearchParams(searchParams);
      const additionalPath = [`${index}`, "additional_tabs"];
      if (!isRightWidget) {
        const mainTabIndex = Number(searchParams.get("t") ?? "1");
        additionalPath.unshift("additional_tabs");
        additionalPath.unshift(
          hideGeneralTab ? `${mainTabIndex}` : `${mainTabIndex - 1}`
        );
      }
      setAdditionalTabPath(additionalPath);
      setEditorPath([
        {
          id: "main",
          name: "main",
        },
        {
          id: tab.id,
          name: isRightWidget ? "additional-widget" : "additional-tabs",
        },
      ]);
      setTabEditorOpen(true);
    }
  }, [
    hideGeneralTab,
    index,
    isRightWidget,
    middleWidgetsPath,
    rightWidgetPaths,
    searchParams,
    setAdditionalTabPath,
    setEditorPath,
    setSearchParams,
    setTabEditorOpen,
    tab,
  ]);

  const handleDuplicateWidget = useCallback(async () => {
    if (!guiTab?.slug) {
      return;
    }

    const paths = isRightWidget ? rightWidgetPaths : middleWidgetsPath;
    if (rightWidgetPaths.length > 0) {
      const widgetPath = [
        ...paths.map((p) => `${p}`),
        "additional_tabs",
        `${index}`,
      ];
      await duplicateGuiData({
        type: "widget",
        tabSlug: guiTab?.slug,
        widgetPath,
      });
      await refetchGuiTabs();
      enqueueSnackbar({
        message: "Duplication in progress",
        variant: "success",
      });
    } else {
      const widgetPath = [
        isRightWidget ? "included_sidebar_widgets" : "included_tabs",
        `${index}`,
      ];

      await duplicateGuiData({
        type: "widget",
        tabSlug: guiTab?.slug,
        widgetPath,
      });
      await refetchGuiTabs();
      enqueueSnackbar({
        message: "Duplication in progress",
        variant: "success",
      });
    }
  }, [
    duplicateGuiData,
    enqueueSnackbar,
    guiTab?.slug,
    index,
    isRightWidget,
    middleWidgetsPath,
    refetchGuiTabs,
    rightWidgetPaths,
  ]);

  return (
    <Box
      sx={{
        px: 1,
        py: 2,
        background: "#1f2125",
        borderRadius: "4px",
      }}
    >
      <Stack direction="column" gap={2}>
        <Stack direction="row" justifyContent="space-between" sx={{ px: 1 }}>
          <Typography variant="subtitle1" component="div" sx={{ px: 1 }}>
            {tab?.name}
          </Typography>
          <ActionMenu
            menuItems={
              editModeEnabled
                ? [
                    {
                      label: "Edit Widget",
                      icon: <SettingsOutlined />,
                      value: "edit-widget",
                    },
                    {
                      label: "Duplicate Widget",
                      icon: <ContentCopyOutlined />,
                      value: "duplicate-widget",
                    },
                    {
                      label: "Refresh",
                      value: "refresh-widget",
                      icon: <RefreshOutlined />,
                    },
                  ]
                : []
            }
            MenuListProps={{
              sx: {
                background: "#13131b",
                border: "1px solid #363b40",
                borderRadius: "6px",
                width: "300px",
              },
            }}
            disablePortal
            onItemClick={(key) => {
              if (key === "delete") {
                // onDeleteTab();
              } else if (key === "edit-widget") {
                handleEditWidget();
              } else if (key === "duplicate-widget") {
                handleDuplicateWidget();
              } else if (key === "refresh-widget") {
                refetch();
              }
            }}
          >
            <IconButton sx={{ p: 0 }}>
              <MoreVert />
            </IconButton>
          </ActionMenu>
        </Stack>
        <DatasetSearchList
          datasets={datasets}
          onLoadMore={() => {
            if (hasNextPage && !isFetching) {
              fetchNextPage();
            }
          }}
          onSearch={setSearchValue}
          onEdit={onEditHandler}
          tab={tab}
          guiSlug={guiSlug}
          isLoading={isFetching}
        />
      </Stack>
    </Box>
  );
};

export default ListDatasetWidget;
