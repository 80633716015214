import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import Spin from "components/Spin";
import AccountMetadata from "models/AccountMetadata";
import React from "react";

type Props = {
  onClose?: () => void;
} & Omit<DialogProps, "onSubmit">;

const DetailDatasetUsage: React.FC<Props> = (props) => {
  const { onClose, open, ...dialogProps } = props;
  const {
    data: datasetUsageDetail = [],
    isLoading: datasetUsageDetailLoading,
  } = useQuery({
    queryKey: ["datasetMetadata"],
    queryFn: async () => {
      const res = await AccountMetadata.getDetailDatasetSize();
      return res.data;
    },
    enabled: open,
  });
  return (
    <Dialog
      onClose={(e, r) => {
        console.log(e, r);
      }}
      disableEscapeKeyDown
      scroll="body"
      open={open}
      {...dialogProps}
    >
      <DialogTitle>Dataset Usage Detail</DialogTitle>

      <React.Fragment>
        <Spin spinning={datasetUsageDetailLoading}>
          <DialogContent>
            <Box sx={{ mt: 1 }}>
              <Stack gap={2}>
                <Typography>
                  {datasetUsageDetail?.map((item) => (
                    <Typography>
                      {item.name}
                      {item.type !== "dataset" ? ` (${item.type})` : ""}:{" "}
                      {item.table_size / 1024 / 1024} MB
                    </Typography>
                  ))}
                </Typography>
              </Stack>
            </Box>
          </DialogContent>
        </Spin>

        <DialogActions>
          <LoadingButton onClick={onClose}>Cancel</LoadingButton>
          <LoadingButton variant="contained" type={"submit"} onClick={onClose}>
            Ok
          </LoadingButton>
        </DialogActions>
      </React.Fragment>
    </Dialog>
  );
};

export default DetailDatasetUsage;
