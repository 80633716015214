import { useDraggable, useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { BackupOutlined, FolderOutlined, MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Link as MuiLink,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useIsMutating } from "@tanstack/react-query";
import classNames from "classnames";
import useRouteToApiModel from "hooks/useRouteToApiModel";
import moment from "moment";
import { ApiModels } from "queries/apiModelMapping";
import React, { memo, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { v4 } from "uuid";
const GridCardContainer = styled(Card)(({ theme }) => ({
  background: theme.palette.background.GF10,
  border: `1px solid ${theme.palette.background.GF10}`,
  borderRadius: "5px",
  overflow: "hidden",
  position: "relative",

  ".image-holder": {
    padding: "12px",
    background: theme.palette.common.blackshades?.["100p"],

    img: {
      width: "148px",
      height: "auto",
      display: "block",
      margin: "0 auto",
    },
  },

  ".MuiCardContent-root": {
    padding: "8px 8px 9px 17px",
    alignItems: "flex-start",
    justifyContent: "space-between",

    ".drop-text": {
      opacity: 0,
    },
  },

  ".icon": {
    width: "22px",

    "svg, img": {
      width: "100%",
      height: "auto",
      display: "block",
    },
  },

  ".MuiTypography-h5": {
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: "500",
    margin: "0",
  },

  ".MuiTypography-body2": {
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "300",
  },

  ".img-star": {
    width: "15px",
    minWidth: "15px",
    color: theme.palette.background.cardsBorder,

    "svg, img": {
      width: "100%",
      height: "auto",
      display: "block",
      color: "currentColor",
    },
  },

  ".card-actions-hover": {
    height: "56px",
    display: "none",

    "&.on-drag-over": {
      display: "block",
    },
  },

  ".card-actions": {
    height: "56px",

    "&.on-drag-over": {
      display: "none",
    },
  },

  "&:hover": {
    ".card-actions-hover": {
      display: "block",
    },

    ".card-actions": {
      display: "none",
    },
  },
}));

type ActionBarProps = Pick<FolderCardProps, "actions" | "folder"> & {
  isOver: boolean;
};

const ActionBar: React.FC<ActionBarProps> = memo(
  (props) => {
    const { isOver, actions = [], folder } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const menuItems = useMemo(
      () => actions.map((action) => ({ ...action, key: v4() })),
      [actions]
    );

    return (
      <CardActions
        className={classNames({
          "card-actions-hover": true,
          "on-drag-over": isOver,
        })}
        sx={{
          background: "#303337",
          borderTop: "1px solid rgba(255, 255, 255, 0.05)",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{
              background: (theme) => theme.palette.background.GF5,
              borderRadius: 2,
              color: "#fff",
            }}
            disableElevation
          >
            Open
          </Button>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              sx={{
                background: (theme) => theme.palette.background.GF5,
                borderRadius: 2,
                color: "#fff",
              }}
              onClick={(e) => {
                handleMoreClick(e);
              }}
            >
              <MoreHoriz fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
        {menuItems.length > 0 ? (
          <Menu
            id="edit-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            disablePortal
          >
            {menuItems.map((action) => (
              <MenuItem
                key={action.key}
                onClick={() => {
                  setAnchorEl(null);
                  action.onClick?.(folder);
                }}
              >
                {action.icon} {action.title}
              </MenuItem>
            ))}
          </Menu>
        ) : null}
      </CardActions>
    );
  },
  (prev, next) =>
    prev.isOver === next.isOver &&
    prev.actions === next.actions &&
    prev.folder === next.folder
);

type ContentProps = Pick<FolderCardProps, "folder"> & { isOver: boolean };

const Content: React.FC<ContentProps> = memo(
  (props) => {
    const { isOver, folder } = props;

    const timeFromNow = useMemo(
      () => moment.utc(folder.updated_at ?? folder.created_at).fromNow(),
      [folder.updated_at, folder.created_at]
    );

    return (
      <CardActions
        className={classNames({
          "card-actions": true,
          "on-drag-over": isOver,
        })}
        sx={{
          background: "#303337",
          borderTop: "1px solid rgba(255, 255, 255, 0.05)",
          height: "56px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%", padding: "0 10px" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={2.5}
            sx={{ width: "100%" }}
          >
            <FolderOutlined />
            <Stack justifyContent="space-between" sx={{ width: "100%" }}>
              <Typography
                variant="subtitle2"
                sx={{
                  width: "90%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {folder.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {timeFromNow}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardActions>
    );
  },
  (prev, next) => prev.isOver === next.isOver && prev.folder === next.folder
);

type MediaProps = { isMutating: number; isOver: boolean };

const Media: React.FC<MediaProps> = memo(
  (props) => {
    const { isMutating, isOver } = props;
    return (
      <>
        <Box
          sx={{ height: "35px", background: "transparent", margin: "0 0 -2px" }}
        >
          <Box
            sx={{
              height: "15px",
              background: "#242628",
              width: "100px",
              borderRadius: "5px 5px 0 0",
            }}
          ></Box>
          <Box
            sx={{
              height: "20px",
              background: "#242628",
              borderRadius: "0 5px 0 0",
            }}
          ></Box>
        </Box>
        <CardContent
          sx={{
            flex: 1,
            background: "#292B2E",
            borderRadius: "5px 5px 0 0",
            justifyContent: "center !important",
            alignItems: "center !important",
            display: "flex",
            width: "100%",
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            gap={1}
            sx={{
              color: (theme) => theme.palette.background.cardsBorder,
            }}
            className={classNames({ "drop-text": !isOver })}
          >
            <BackupOutlined sx={{ fontSize: "28px" }} />
            <Typography
              sx={{ color: "inherit", fontSize: "14px" }}
              variant="body1"
            >
              Drop files to upload to
            </Typography>
          </Stack>
        </CardContent>
        {isMutating > 0 && <LinearProgress />}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.isMutating === nextProps.isMutating &&
    prevProps.isOver === nextProps.isOver
);

type FolderCardProps = {
  folder: Folder;
  actions?: {
    title?: React.ReactNode;
    icon?: React.ReactNode;
    onClick?(folder: Folder): void;
  }[];
  onClick?(): void;
  overlay?: boolean;
};

const FolderCard: React.FC<FolderCardProps> = (props) => {
  const { folder, overlay, actions = [], onClick } = props;

  const routeModule = useRouteToApiModel();
  const isMutating = useIsMutating({
    mutationKey: [ApiModels.Folder, routeModule],
    predicate: (mutation) => {
      return mutation.options?.variables?.slug === folder.slug;
    },
  });
  const {
    attributes,
    listeners,
    setNodeRef: dragSetNodeRef,
    transform,
    isDragging,
  } = useDraggable({
    id: `folder-card_${folder.slug}`,
    data: {
      id: folder.slug,
    },
  });
  const { setNodeRef, isOver } = useDroppable({
    id: `folder-card_${folder.slug}`,
    data: {
      id: folder.slug,
    },
  });

  const CardContent = (
    <GridCardContainer
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        background: "transparent",
        border: "none",
      }}
      ref={setNodeRef}
    >
      <Media isOver={!!isDragging || isOver} isMutating={isMutating} />
      <ActionBar
        isOver={!!isDragging || isOver}
        actions={actions}
        folder={folder}
      />
      <Content isOver={!!isDragging || isOver} folder={folder} />
    </GridCardContainer>
  );

  const link = (folder as { link?: string }).link ?? "root";

  return (
    <Box
      sx={{ height: "230px", zIndex: 100 }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.();
      }}
      ref={dragSetNodeRef}
      {...attributes}
      {...listeners}
      style={{
        transform: CSS.Translate.toString(transform),
        zIndex: overlay ? 100 : undefined,
        opacity: isDragging ? 0 : overlay ? 0.3 : 1,
      }}
    >
      {link ? (
        <MuiLink component={Link} to={link} underline="none" color="inherit">
          {CardContent}
        </MuiLink>
      ) : (
        CardContent
      )}
    </Box>
  );
};

export default FolderCard;
