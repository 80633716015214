import BarChart from "@mui/icons-material/BarChart";
import { Box, styled } from "@mui/material";
import Icon from "components/util-components/Icon";
import useAppNavigate from "hooks/useAppNavigate";
import useHasAccessToModule from "hooks/useHasAccessToModule";
import { AnimationLayoutRef } from "layouts/AnimationLayout";
import { ApiModels } from "queries/apiModelMapping";
import { useGuiTabs } from "queries/gui/useGuiTabs";
import useGetItem from "queries/useGetItem";
import React, { useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useSystemLayoutStore from "store";
import useGuiParamsStore from "store/stores/gui-prams";
import GuiFilters from "views/app-view/gui-workspace/components/GuiFilters";
import AddTabButton from "./components/AddTabButton";
import EditModeButton from "./components/EditModeButton";
import GuiFiltersView from "./components/GuiFiltersView";
import GuiParamsLoader from "./components/GuiParamLoader";
import GuiSettingsButton from "./components/GuiSettingsButton";
import GuiTabContent from "./components/GuiTabContent";
import TabActionMenu from "./components/TabActionMenu";
import TabActions from "./components/TabActions";

const WorkspaceContainer = styled(Box)({
  height: "100%",
});

type GuiWorkspaceProps = {
  publicAccess?: boolean;
};

const GuiWorkspace: React.FC<GuiWorkspaceProps> = (props) => {
  const { publicAccess = false } = props;

  const { slug, tab } = useParams<{ slug: string; tab: string }>();

  const navigate = useAppNavigate();
  const [searchParams] = useSearchParams();
  const guiTabEditorRef = useRef<AnimationLayoutRef>(null);

  const setTopBarProps = useSystemLayoutStore.useSetTopBarProps();
  const selectedTopBarTab = useSystemLayoutStore.useSelectedTopBarTab();
  const setSelectedTopBarTab = useSystemLayoutStore.useSetSelectedTopBarTab();
  const isParamEditorOpen = useGuiParamsStore.useIsParamEditorOpen();
  const setIsParamEditorOpen = useGuiParamsStore.useSetIsParamEditorOpen();
  const isFilterViewOpen = useGuiParamsStore.useIsFilterViewOpen();
  const setIsFilterViewOpen = useGuiParamsStore.useSetIsFilterViewOpen();

  const { data: gui } = useGetItem({ modelName: ApiModels.Gui, slug });

  const { data: guiTabs = [] } = useGuiTabs(gui?.slug);

  const guiTab = guiTabs[Number(selectedTopBarTab) || 0];
  const moduleName = publicAccess ? "gui-module-public" : "gui-module";
  const hasAccessToGui = useHasAccessToModule({ moduleName: "gui-module" });

  useEffect(() => {
    if (gui) {
      setTopBarProps({
        title: gui.name,
        icon: gui.icon ? <Icon iconName={gui.icon} /> : undefined,
        tabs: guiTabs.map((tab, index) => ({
          label: tab.tab_name,
          value: index,
          icon: <BarChart />,
          rightIcon: <TabActionMenu tab={tab} />,
        })),
        extra: {
          right:
            !publicAccess || hasAccessToGui ? <EditModeButton /> : undefined,
          left: <GuiSettingsButton />,
        },
        tabBarExtra: <AddTabButton guiSlug={gui.slug} />,
      });
      setSelectedTopBarTab(Number(tab || "0"));
    }
  }, [guiTabs, hasAccessToGui, publicAccess, tab]);

  useEffect(() => {
    if (selectedTopBarTab != null && selectedTopBarTab !== "") {
      navigate(
        `/${moduleName}/${slug}/${selectedTopBarTab}${
          searchParams.size > 0 ? "?" + searchParams.toString() : ""
        }`
      );
    }
  }, [selectedTopBarTab]);

  return (
    <>
      <GuiParamsLoader guiSlug={slug} fusionSlug={gui?.page_load_fusion} />
      <WorkspaceContainer>
        {guiTab && (
          <GuiTabContent
            tab={guiTab}
            publicAccess={publicAccess}
            guiTabEditorRef={guiTabEditorRef}
          />
        )}
      </WorkspaceContainer>
      <TabActions
        guiTabs={guiTabs}
        gui={gui}
        guiTabEditorRef={guiTabEditorRef}
      />
      <GuiFilters
        open={isParamEditorOpen}
        onClose={() => {
          setIsParamEditorOpen(false);
        }}
        guiSlug={slug}
      />
      <GuiFiltersView
        open={isFilterViewOpen}
        onClose={() => {
          setIsFilterViewOpen(false);
        }}
        guiSlug={slug}
      />
    </>
  );
};

export default GuiWorkspace;
