import { CustomCellRendererProps } from "ag-grid-react";
import Cell from "components/DatasetTable/Cell";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { FC, memo } from "react";

const DatasetDesignCellRenderer: FC<CustomCellRendererProps> = memo((props) => {
  const { data: datasetDesigns = [] } = useListItems({
    modelName: ApiModels.DatasetDesign,
  });

  return (
    <Cell isLoading={!props.data}>
      {datasetDesigns.find((design) => design.slug === props.value)?.name ?? ""}
    </Cell>
  );
});

export default DatasetDesignCellRenderer;
