import Model from "models";

class AgentDatasetRightModel extends Model<AgentDatasetRight> {
  constructor() {
    super("/agent-dataset-right", "gui-fusion");
  }
}

const model = new AgentDatasetRightModel();

export default model;
