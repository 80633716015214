import { CustomCellEditorProps } from "ag-grid-react";
import IconPickerField from "components/IconPicker";
import { AllIconPickerIcons } from "constants/index";
import { FC, memo } from "react";

const IconCellEditor: FC<CustomCellEditorProps> = memo((props) => (
  <IconPickerField
    icons={AllIconPickerIcons}
    value={props.value}
    onChange={(icon) => props.onValueChange(icon)}
    textFieldProps={{
      fullWidth: true,
      sx: { input: { padding: "8.5px 14px" } },
    }}
  />
));

export default IconCellEditor;
