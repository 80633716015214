import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as MessageLoadingSvg } from "../svg/message_loading.svg";

const MessageLoading: React.FC<React.ComponentProps<typeof SvgIcon>> = (
  props
) => {
  return <SvgIcon inheritViewBox {...props} component={MessageLoadingSvg} />;
};

export default MessageLoading;
