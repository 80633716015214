import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Avatar,
  ClickAwayListener,
  Paper,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import IconPicker from "components/IconPicker/IconPicker";
import Icon from "components/util-components/Icon";
import { AllIconPickerIcons } from "constants/index";
import { FC, useEffect, useState } from "react";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
))({
  backgroundColor: "transparent",
  maxWidth: "none",
  padding: 0,

  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: "#121212",
  },
});

type IconFieldProps = {
  value?: string;
  onChange?(value: string): void;
};

const IconField: FC<IconFieldProps> = (props) => {
  const { value, onChange } = props;

  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<Icon>();

  useEffect(() => {
    if (value) {
      setSelected(AllIconPickerIcons.find((icon) => icon.slug === value));
    }
  }, [value]);

  return (
    <StyledTooltip
      open={open}
      arrow
      disableFocusListener
      disableHoverListener
      disableTouchListener
      placement="bottom"
      title={
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <Paper sx={{ width: 500 }}>
            <IconPicker
              value={value}
              icons={AllIconPickerIcons}
              onSelectIcon={(iconSlug) => {
                setOpen(false);
                setSelected(
                  AllIconPickerIcons.find((i) => i.slug === iconSlug)
                );
                onChange?.(iconSlug);
              }}
            />
          </Paper>
        </ClickAwayListener>
      }
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height="50px"
        border="1px solid"
        borderColor={theme.palette.background.GF20}
        borderRadius="4px"
        p={0.5}
        onClick={() => setOpen(true)}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Avatar
            sx={{
              background: theme.palette.text.secondary_shades?.["4p"],
              color: "#ffffff",
            }}
            variant="rounded"
          >
            {selected?.slug && <Icon iconName={selected.slug} />}
          </Avatar>
          <Typography fontSize={13} fontWeight={400}>
            {selected?.slug}
          </Typography>
        </Stack>
        <KeyboardArrowDown />
      </Stack>
    </StyledTooltip>
  );
};

export default IconField;
