import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import { Stack, styled, Typography } from "@mui/material";
import { FC } from "react";
import DetailsWidget from "./DetailsWidget";
import FileList from "./FileList";
import JobList from "./JobList";

const WidgetContainer = styled(Stack)({
  padding: "16px",
});

type WidgetListProps = {
  onJobClick(job: Job): void;
};

const WidgetList: FC<WidgetListProps> = (props) => {
  const { onJobClick } = props;
  return (
    <WidgetContainer gap={2}>
      <Stack direction="row" alignItems="center" gap={1}>
        <AccountCircleOutlined
          sx={{ color: (theme) => theme.palette.text.primary }}
        />
        <Typography fontSize={15} fontWeight={500} color="text.primary">
          Something
        </Typography>
      </Stack>
      <DetailsWidget />
      <FileList />
      <JobList onJobClick={onJobClick} />
    </WidgetContainer>
  );
};

export default WidgetList;
