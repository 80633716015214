import AddOutlined from "@mui/icons-material/AddOutlined";
import CalendarViewDayOutlined from "@mui/icons-material/CalendarViewDayOutlined";
import { IconButton, Stack, Typography } from "@mui/material";
import Spin from "components/Spin";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { FC } from "react";

type GroupListingProps = {
  guiSlug?: string;
  onAddClick(): void;
  onEditClick(group: GuiParamGroup): void;
};

const GroupListing: FC<GroupListingProps> = (props) => {
  const { guiSlug, onAddClick, onEditClick } = props;

  const { data: groups = [], isFetching } = useListItems({
    modelName: ApiModels.GuiParamGroup,
    requestOptions: { query: { gui_slug: guiSlug } },
    queryKey: [ApiModels.GuiParamGroup, guiSlug],
    queryOptions: {
      enabled: !!guiSlug,
    },
  });

  return (
    <Stack direction="column" gap={2} width="100%">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography fontSize={16}>Filter Designer</Typography>
        <IconButton
          sx={{
            background: (theme) => theme.palette.primary.main,
            color: "white",
            width: "24px",
            height: "24px",
            borderRadius: "4px",
          }}
          onClick={onAddClick}
        >
          <AddOutlined />
        </IconButton>
      </Stack>
      <Spin spinning={isFetching}>
        <Stack gap={1}>
          {groups.map((group) => (
            <Stack
              key={group.slug}
              direction="row"
              gap={1}
              alignItems="center"
              width="100%"
              borderRadius="6px"
              padding="4px"
              sx={{
                background: (theme) => theme.palette.background.ListItem,
                cursor: "pointer",
              }}
              onClick={() => onEditClick(group)}
            >
              <Stack
                width="36px"
                height="36px"
                alignItems="center"
                justifyContent="center"
                sx={{
                  background: (theme) => theme.palette.background.ListItemIcon,
                }}
              >
                <CalendarViewDayOutlined />
              </Stack>
              <Stack direction="column">
                <Typography fontSize={14} color="text.primary">
                  {group.title}
                </Typography>
                <Typography
                  fontSize={13}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  color="text.secondary"
                  width="200px"
                >
                  {group.slug}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Spin>
    </Stack>
  );
};

export default GroupListing;
