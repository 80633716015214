import useAccountSlug from "hooks/useAccountSlug";
import useCurrentUser from "hooks/useCurrentUser";
import useSocket from "hooks/useSocket";
import FusionModel from "models/Fusion";
import { useGuiParams } from "queries/guiParams/useGuiParams";
import { FC, memo, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import useGuiParamsStore from "store/stores/gui-prams";

type GuiParamsLoaderProps = { guiSlug?: string; fusionSlug?: string };

const GuiParamsLoader: FC<GuiParamsLoaderProps> = memo(
  (props) => {
    const { guiSlug, fusionSlug } = props;

    const accountSlug = useAccountSlug();
    const user = useCurrentUser();
    const [searchParams] = useSearchParams();
    const setGuiParamsValues = useGuiParamsStore.useSetGuiParamValues();
    const mergeGuiParamValues = useGuiParamsStore.useMergeGuiParamValues();

    const { data: guiParams } = useGuiParams(guiSlug);
    const { subscribe, unsubscribe } = useSocket();

    const paramValues = useMemo(() => {
      if (guiParams) {
        return guiParams.reduce<Record<string, unknown>>((params, current) => {
          const searchKey = `filters.${current.param_slug}`;
          if (searchParams.has(searchKey)) {
            params[current.param_slug] = searchParams.get(searchKey)!;
          } else {
            params[current.param_slug] = current.default_value || undefined;
          }
          return params;
        }, {});
      }
    }, [guiParams, searchParams]);

    useEffect(() => {
      if (fusionSlug && paramValues && accountSlug && user.slug) {
        FusionModel.runFusion(fusionSlug, {
          popup_variables: paramValues,
          user_id: user.slug,
          account_id: accountSlug,
        }).catch(() => {});
      }
    }, [accountSlug, fusionSlug, paramValues, user.slug]);

    useEffect(() => {
      if (paramValues) {
        setGuiParamsValues(paramValues);
      }
    }, [paramValues, setGuiParamsValues]);

    useEffect(() => {
      subscribe(
        "gui_page_load_fusion_response",
        "gui_page_load_fusion_response",
        (response: SocketResponse<{ gui_params?: Record<string, string> }>) => {
          response.data?.gui_params &&
            mergeGuiParamValues(response.data.gui_params);
        }
      );

      return () => {
        unsubscribe(
          "gui_page_load_fusion_response",
          "gui_page_load_fusion_response"
        );
      };
    }, [subscribe, unsubscribe, mergeGuiParamValues]);

    return null;
  },
  (prev, next) =>
    prev.fusionSlug === next.fusionSlug && prev.guiSlug === next.guiSlug
);

export default GuiParamsLoader;
