import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  styled,
} from "@mui/material";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import { DocumentElementType } from "enums/Form";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const Container = styled(Stack)({
  maxWidth: "600px",
  width: "100%",
});

const formSchema = z.object({
  user_slug: z.string(),
  enabled: z.number().default(0),
});

type AddAgentUserFormType = z.infer<typeof formSchema>;

type AddAgentUserModalProps = {
  agent: Agent;
  onClose?(): void;
  onCreated?(): void;
} & DialogProps;

const AddAgentUserModal: FC<AddAgentUserModalProps> = (props) => {
  const { agent, onClose, onCreated, ...dialogProps } = props;

  const form = useForm<AddAgentUserFormType>({
    resolver: zodResolver(formSchema),
  });

  const { control, handleSubmit } = form;

  const { mutateAsync: createAgentUser, isLoading } = useCreateItem({
    modelName: ApiModels.AgentUser,
  });

  const submitHandler = async (values: AddAgentUserFormType) => {
    await createAgentUser({
      ...values,
      enabled: Boolean(values.enabled),
      agent_slug: agent.slug,
    });

    onClose?.();
    onCreated?.();
  };

  return (
    <Dialog
      onClose={(e, r) => {
        console.log(e, r);
      }}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Add Agent User</DialogTitle>
      <Scrollbar className="form-scroller">
        <DialogContent>
          <FormProvider {...form}>
            <Stack alignItems="center">
              <Container gap={2}>
                <FormField
                  label="User"
                  type={DocumentElementType.User}
                  formControl={{ control, name: "user_slug" }}
                />
                <FormField
                  label="Enabled"
                  type={DocumentElementType.Boolean}
                  formControl={{ control, name: "enabled" }}
                />
              </Container>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Scrollbar>
      <DialogActions>
        <LoadingButton onClick={onClose}>Cancel</LoadingButton>
        <LoadingButton
          onClick={handleSubmit(submitHandler, (error) => {
            console.log(error);
          })}
          variant="contained"
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddAgentUserModal;
