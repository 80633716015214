import { Box, Stack, SxProps, styled } from "@mui/material";
import AnimationLayout, {
  AnimationLayoutRef,
  Config,
} from "layouts/AnimationLayout";
import { FC, useCallback, useLayoutEffect, useRef } from "react";
import useGFChatWindow from "store/stores/gf-chat-window";
import ChatMessages from "./ChatMessages";
import ChatSessions from "./ChatSessions";
import Header from "./Header";

const Container = styled(Stack)({
  height: "100%",

  ".thumb-vertical": {
    background: "#8892b8",
  },

  ".animation-parent": {
    height: "calc(100% - 45px)",
  },
});

const TopContentWrapper = styled(Box)({
  height: "100%",
});

type ChatWindowProps = {
  sx?: SxProps;
  draggableId: string;
  collapsed: boolean;
  onCollapse(): void;
};

const ChatWindow: FC<ChatWindowProps> = (props) => {
  const { sx, draggableId, collapsed, onCollapse } = props;

  const ref = useRef<AnimationLayoutRef>(null);

  const activeChat = useGFChatWindow.useActiveChat();
  const activeView = useGFChatWindow.useActiveView();
  const setActiveView = useGFChatWindow.useSetActiveView();
  const setActiveChat = useGFChatWindow.useSetActiveChat();

  useLayoutEffect(() => {
    if (activeView === "messages") {
      ref.current?.setTransitionHistory([
        {
          name: "chat-list",
          id: "chat-list",
        },
        {
          name: "messages",
          id: activeChat || "messages",
        },
      ]);
    }
  }, []);

  const handleBackClick = () => {
    ref.current?.goBack();
    const previousView = ref.current?.getTransitionHistory()?.at(-1)?.name;
    if (previousView) {
      setActiveView(previousView as typeof activeView);
    }
  };

  const getComponents = useCallback<Config["getComponents"]>(
    (gotoComponent, goBack) => ({
      "chat-list": (
        <ChatSessions
          onChatClick={(slug) => {
            gotoComponent({ name: "messages", id: slug });
            setActiveView("messages");
            setActiveChat(slug);
          }}
        />
      ),
      messages: <ChatMessages />,
    }),
    [setActiveView, setActiveChat]
  );

  return (
    <Container sx={sx} direction="column" justifyContent="space-between">
      <TopContentWrapper>
        <Header
          draggableId={draggableId}
          collapsed={collapsed}
          onCollapseIconClick={onCollapse}
          onBackClick={handleBackClick}
        />
        <AnimationLayout
          ref={ref}
          config={{
            getComponents,
            initialComponent: activeView,
          }}
          updateUrl={false}
        />
      </TopContentWrapper>
    </Container>
  );
};

export default ChatWindow;
