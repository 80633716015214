import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { Avatar, IconButton, Stack, Typography } from "@mui/material";
import { File } from "assets/icons";
import useThreadAttachments from "queries/copilot/useThreadAttachments";
import { FC } from "react";
import useAgentChatStore from "store/stores/agent-chat";
import ListWidget from "./ListWidget";

type FileListProps = {};

const FileList: FC<FileListProps> = (props) => {
  const thread = useAgentChatStore.useSelectedThread();

  const { data: files = [] } = useThreadAttachments(`${thread?.id}`);

  return (
    <ListWidget
      title="File List"
      items={files}
      itemRenderer={(item) => (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="40px"
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Avatar
              variant="rounded"
              sx={{ background: (theme) => theme.palette.primary.main }}
            >
              <File
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              />
            </Avatar>
            <Typography fontSize={13} fontWeight={400} color="text.primary">
              {item.filename}
            </Typography>
          </Stack>
          <IconButton
            sx={{
              p: 0,
              mr: 1,
            }}
            size="small"
          >
            <MoreHoriz fontSize="small" />
          </IconButton>
        </Stack>
      )}
    />
  );
};

export default FileList;
