import { Stack, styled } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import SearchInput from "components/SearchInput";
import ButtonAdd from "components/layout-components/system-layout/SideNav/ButtonAdd";
import useOpenClose from "hooks/useOpenClose";
import debounce from "lodash/debounce";
import { FC, useEffect } from "react";
import useAgentChatStore from "store/stores/agent-chat";
import { useSystemLayoutStore } from "store/stores/systemLayout";
import AddAgentThreadModal from "./AddAgentThreadModal";

const Container = styled(Stack)(({ theme }) => ({
  // paddingTop: "16px",
  // paddingBottom: "16px",
  marginBottom: "8px",
  "&.search_bar": {
    ".search-field": {
      width: "80%",
      marginRight: "10px",

      ".MuiInputBase-root": {
        border: "1px solid #d2d2d9",
        background: "transparent",
      },
      ".MuiInputAdornment-root": {
        left: 0,
        right: "unset",
      },
      ".MuiInputBase-input": {
        padding: "5px 8px 5px 0px",
      },
    },
    ".add_item_btn": {
      height: "32px",
      width: "32px",
      background: theme.palette.primary.main,
    },
  },
}));

type TopBarProps = {};

const TopBar: FC<TopBarProps> = (props) => {
  // const theme = useTheme();
  const [isOpen, open, close] = useOpenClose();
  const queryClient = useQueryClient();

  const setAddButtonProps = useSystemLayoutStore.useSetButtonProps();
  // const setLeftBarCollapsed = useAgentChatStore.useSetLeftBarCollapsed();
  const searchValue = useAgentChatStore.useSearchValue();
  const setSearchValue = useAgentChatStore.useSetSearchValue();

  useEffect(() => {
    setAddButtonProps({ onClick: open, fullWidth: false, size: "small" });
  }, []);

  return (
    <Container
      direction="row"
      alignItems="center"
      gap={1}
      sx={{ py: 1.375, px: 1.75 }}
      className="search_bar"
    >
      <SearchInput
        id="outlined-adornment-password"
        placeholder="Search..."
        size="small"
        sx={{ m: 0 }}
        value={searchValue}
        onChange={debounce((e) => {
          setSearchValue(e.target.value);
        }, 300)}
      />
      <ButtonAdd />
      <AddAgentThreadModal
        open={isOpen}
        onClose={close}
        onCreated={() => {
          queryClient.refetchQueries({
            queryKey: ["copilot", "threads"],
          });
        }}
      />
    </Container>
  );
};

export default TopBar;
