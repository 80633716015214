import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as StroageIconSvg } from "../svg/stroage.svg";

const StroageIcon: React.FC<React.ComponentProps<typeof SvgIcon>> = (props) => {
  return (
    <SvgIcon
      inheritViewBox
      {...props}
      component={StroageIconSvg}
      // className="plus-icon"
    />
  );
};

export default StroageIcon;
