import { isValidUrl } from "utils";

export const APP_NAME = "GF";
export const APP_PREFIX_PATH = "/:accountSlug";
export const AUTH_PREFIX_PATH = "/auth";

export const CognitoConfig = {
  REGION: process.env.REACT_APP_AWS_REGION,
  USER_POOL_ID: process.env.REACT_APP_PUBLIC_COGNITO_USER_POOL,
  APP_CLIENT_ID: process.env.REACT_APP_PUBLIC_COGNITO_USER_POOL_CLIENT,
};

export const systemAdminRole = "systemdev";

let MEDIA_CDN_URL = process.env.REACT_APP_MEDIA_CDN_DISTRIBUTION_DOMAIN_NAME!;

if (isValidUrl(MEDIA_CDN_URL) && !/^https?:\/\//i.test(MEDIA_CDN_URL)) {
  MEDIA_CDN_URL = "https://" + MEDIA_CDN_URL;
}

export const S3_CLOUD_FRONT_URL = MEDIA_CDN_URL;
export const FUSION_SESSIONS_BUCKET =
  process.env.REACT_APP_FUSION_SESSIONS_BUCKET!;

export const MEDIA_BUCKET = process.env.REACT_APP_MEDIA_BUCKET!;

export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const LOG_ROCKET = process.env.REACT_APP_LOG_ROCKET;

export const STRIPE_KEY =
  "pk_test_51LwYZtBpvm0C4vU7qfSvU4V55U5qwut3Vtp1IXPIYvzzylFnJZhpb0LxGaFvVx43R2e9trKl7zcPAQXAYlXq9zdc00jlzZuERG";
export const SOCIAL_MEDIA_ACCOUNTS_TYPE = "accounts";
export const OWNER_ACCOUNT_TYPE = "account-owner-type";

export enum NAV_SELECTOR_TYPE {
  SIMPLE = "simple",
  SELECT = "select",
}

export const APP_CONFIG_DEFULT: AppConfig = {
  id: "string",
  slug: "string",
  title: "string",
  fusion_credit_check_trigger: 20,
  co_pilot_access: false,
  jobs_access: false,
  dataset_access: false,
  workflow_access: false,
  import_template_access: false,
  dashboards_access: false,
  media_access: false,
  icon_access: false,
  user_access: false,
  sylar_access: false,
  apps_access: false,
  vector_embeds_access: false,
  fine_tune_models_access: false,
  skills_access: false,
  adventure_access: false,
  billing_module: false,
  primary_color: "blue",
  gui_type_dashboard: false,
  gui_type_record_list: false,
  gui_type_reviewer: false,
  gui_type_workflow_board: false,
};
