import CloudUpload from "@mui/icons-material/CloudUpload";
import EditOutlined from "@mui/icons-material/EditOutlined";
import PlaylistAdd from "@mui/icons-material/PlaylistAdd";
import SchemaRounded from "@mui/icons-material/SchemaRounded";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import { DrawerProps, styled } from "@mui/material";
import GfDrawer from "components/GfDrawer";
import { FC } from "react";
import { useGuiDashboardV2Store } from "store/stores/gui-dashboard-v2";
import TabContent from "./TabContent";

const DrawerStyled = styled(GfDrawer)(({ theme }) => ({
  ".gf-drawer-inner-paper": {
    boxShadow: "none",

    ".drawer-head": {
      background: theme.palette.primary.main,

      ".drawer-icon-holder": {
        background: "none",
        width: "auto",
        height: "auto",
      },

      ".drawer-title-box": {
        marginLeft: "20px",
      },
    },
  },
}));

type WidgetEditorDrawerProps = {
  onClose(): void;
} & DrawerProps;

const WidgetEditorDrawer: FC<WidgetEditorDrawerProps> = (props) => {
  const { onClose, ...drawerProps } = props;

  const widget = useGuiDashboardV2Store.useWidget();

  return (
    <DrawerStyled
      anchor="right"
      onClose={onClose}
      title={`${widget ? "Edit" : "Add"} Widget`}
      icon={<PlaylistAdd />}
      width="420px"
      tablist={[
        { label: "", key: "edit_tab", icon: <EditOutlined /> },
        {
          label: "",
          key: "fusions_tab",
          icon: <CloudUpload />,
          disabled: !widget,
        },
        {
          label: "",
          key: "actions_tab",
          icon: <SchemaRounded />,
          disabled: !widget,
        },
        {
          label: "",
          key: "settings_tab",
          icon: <SettingsOutlined />,
          disabled: !widget,
        },
      ]}
      defaultTabKey={0}
      renderTabContent={(props) => (
        <TabContent activeTab={props.active ?? 0} onClose={onClose} />
      )}
      ModalProps={{
        keepMounted: false,
      }}
      keepMounted={false}
      variant="temporary"
      {...drawerProps}
    />
  );
};

export default WidgetEditorDrawer;
