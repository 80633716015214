import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";
import FormField from "components/FormField";
import { DateType, DocumentElementType } from "enums/Form";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import useSSAgent from "store/stores/ss-agent";
import { z } from "zod";

const formSchema = z.object({
  title: z.string(),
  tag: z.string().optional(),
  icon: z.string().optional(),
  expiration_date: z.string().optional(),
});

type EditAgentSkillFormType = z.infer<typeof formSchema>;

type AgentDocumentDetailsProps = {
  agentDocument?: AgentDocument;
};

const AgentDocumentDetails: FC<AgentDocumentDetailsProps> = (props) => {
  const { agentDocument } = props;

  const setEditDrawer = useSSAgent.useSetEditDrawer();

  const form = useForm<EditAgentSkillFormType>({
    defaultValues: agentDocument,
    resolver: zodResolver(formSchema),
  });

  const { control, handleSubmit, setValue } = form;

  const { mutateAsync: updateDocument, isLoading } = useUpdateItem({
    modelName: ApiModels.SSAgentDocument,
  });

  useEffect(() => {
    if (agentDocument) {
      setValue("title", agentDocument.title);
      setValue("tag", agentDocument.tag);
      setValue("expiration_date", agentDocument.expiration_date);
    }
  }, [setValue, agentDocument]);

  const onSubmit = async (data: EditAgentSkillFormType) => {
    if (agentDocument) {
      await updateDocument({
        slug: agentDocument.slug,
        data,
      });
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" gap={2} mt={2}>
          <FormField
            label="Title"
            type={DocumentElementType.TextField}
            formControl={{ control, name: "title" }}
          />
          <FormField
            label="Tag"
            type={DocumentElementType.TextField}
            formControl={{ control, name: "tag" }}
          />
          <FormField
            label="Expiration"
            type={DocumentElementType.Date}
            formControl={{ control, name: "expiration_date" }}
            fieldExtras={{ field: { date_type: DateType.DateOnly } }}
          />
          <FormField
            label="Document Upload"
            type={DocumentElementType.File}
            fieldExtras={{ field: { display_type: "v2" }, disabled: true }}
            value={agentDocument?.file}
          />
          <FormField
            label="Icon"
            type={DocumentElementType.Icon}
            formControl={{ control, name: "icon" }}
          />
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1.5}
            sx={{ mt: 2 }}
          >
            <Button
              variant="outlined"
              color="inherit"
              type="button"
              onClick={() => {
                setEditDrawer({
                  enabled: false,
                });
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default AgentDocumentDetails;
