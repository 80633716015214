import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from "@mui/material";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import { queryClient } from "queries";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  query_slug: z.string().min(1, "Slug is required"),
  value: z.string(),
  description: z.string().optional(),
});

type AddDatasetDesignFormType = z.infer<typeof formSchema>;

type AddSqlQueryModalProps = {
  onSubmit: (data: AddDatasetDesignFormType) => void;
  onClose: () => void;
} & Omit<DialogProps, "onSubmit">;

const AddSqlQueryModal: React.FC<AddSqlQueryModalProps> = (props) => {
  const { onClose, onSubmit, ...dialogProps } = props;

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
  } = useForm<AddDatasetDesignFormType>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });

  const { mutateAsync: createSqlQuery, isLoading } = useCreateItem({
    modelName: ApiModels.SqlQuery,
  });

  const submitHandler = async (data: AddDatasetDesignFormType) => {
    await createSqlQuery(data);

    queryClient.refetchQueries([ApiModels.SqlQuery]);
    onClose();
    reset();
  };

  return (
    <Dialog
      onClose={(e, r) => {
        console.log(e, r);
      }}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Add Sql Query</DialogTitle>
      <Scrollbar className="form-scroller">
        <DialogContent>
          <Box component="form">
            <FormField
              label="Query Name"
              error={dirtyFields.name ? errors.name : undefined}
            >
              <TextField
                {...register("name")}
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
              />
            </FormField>
            <FormField
              label="Query Slug"
              error={dirtyFields.query_slug ? errors.query_slug : undefined}
            >
              <TextField
                {...register("query_slug")}
                margin="dense"
                id="query_slug"
                type="text"
                fullWidth
              />
            </FormField>
            <FormField
              label="Query Value"
              error={dirtyFields.value ? errors.value : undefined}
            >
              <TextField
                {...register("value")}
                margin="dense"
                id="value"
                type="text"
                fullWidth
              />
            </FormField>
            <FormField
              label="Dataset Design Description"
              error={dirtyFields.description ? errors.description : undefined}
            >
              <TextField
                {...register("description")}
                margin="dense"
                id="description"
                type="text"
                fullWidth
                hiddenLabel
                rows={4}
                multiline
              />
            </FormField>
          </Box>
        </DialogContent>
      </Scrollbar>
      <DialogActions>
        <LoadingButton onClick={onClose}>Cancel</LoadingButton>
        <LoadingButton
          onClick={handleSubmit(submitHandler)}
          variant="contained"
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddSqlQueryModal;
