import {
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme,
} from "@mui/material";
import { CustomCellEditorProps } from "ag-grid-react";
import Cell from "components/DatasetTable/Cell";
import { FC, memo } from "react";

const DefaultMultiSelectEditor: FC<CustomCellEditorProps> = memo((props) => {
  const theme = useTheme();

  const options: (LabeledValue & { color?: string })[] =
    props.colDef?.cellEditorParams.values ?? [];

  return (
    <Cell
      isLoading={!props.data}
      sx={{ background: theme.palette.background.TableBody }}
    >
      <Select
        multiple
        value={props.value}
        onChange={(e) => {
          const value = e.target.value;
          props.onValueChange?.(
            typeof value === "string" ? value.split(",") : value
          );
        }}
        sx={{
          ".MuiSelect-select": { padding: 0, paddingLeft: "14px" },
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
          width: "100%",
        }}
        input={<OutlinedInput />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "nowrap", gap: 0.5 }}>
            {(selected as string[]).map((value) => {
              const option = options?.find((item) => item.value === value);
              return (
                <Chip
                  key={option?.value}
                  label={option?.label}
                  size="small"
                  sx={{ bgcolor: option?.color }}
                />
              );
            })}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8,
            },
          },
        }}
      >
        {options?.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </Cell>
  );
});

export default DefaultMultiSelectEditor;
