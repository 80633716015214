import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as FillStarIconSvg } from "../svg/fillStarIcon.svg";

const FillStarIcon: React.FC<React.ComponentProps<typeof SvgIcon>> = (props) => {
  return (
    <SvgIcon
      inheritViewBox
      {...props}
      component={FillStarIconSvg}
      className="plus-icon"
    />
  );
};

export default FillStarIcon;
