import Model from "models";

class AgentSkillAsscModel extends Model<AgentSkillAssc> {
  constructor() {
    super("/agent-skill-assc", "gui-fusion");
  }
}

const model = new AgentSkillAsscModel();

export default model;
