import SelectField from "components/FormField/FieldRenderer/SelectField";
import { ListSource } from "enums/Form";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { FC, useMemo } from "react";

type GuiDocumentFieldProps = {
  field: DataField;
  value?: string | string[];
  onChange?(value: string | string[]): void;
};

const GuiDocumentField: FC<GuiDocumentFieldProps> = (props) => {
  const { data = [] } = useListItems({ modelName: ApiModels.GuiDocument });

  const guiDocumentOptions = useMemo(
    () => data.map((d) => ({ label: d.doc_name ?? d.slug, value: d.slug })),
    [data]
  );

  return (
    <SelectField
      {...props}
      field={{
        ...(props.field ?? {}),
        list_source: ListSource.Hardcoded,
        list_items: guiDocumentOptions,
      }}
    />
  );
};

export default GuiDocumentField;
