import ExpandLess from "@mui/icons-material/ExpandLess";
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import useAppNavigate from "hooks/useAppNavigate";
import useThreads from "queries/copilot/useThreads";
import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useAgentChatStore from "store/stores/agent-chat";
import ListItem from "./ListItem";

const sampleThreads = Array(12)
  .fill(null)
  .map((_, idx) => [
    {
      id: `${idx}1`,
      agent_slug: "0:2hHgzUcxOrq4yENo2R5jGGQYtQF",
      agent_name: "Jimmy John GPT",
      status: "active",
      run_is_active: 0,
      chat_status_update: "",
      summary: "",
      open_ai_thead_id: "thread_c5HAbGCjKvdwUT1GslQfeHuA",
      is_active: 1,
      is_deleted: 0,
      created_at: `2023-${
        idx + 1 < 10 ? "0" + (idx + 1) : idx + 1
      }-06T01:17:52.000Z`,
      updated_at: `2023-${
        idx + 1 < 10 ? "0" + (idx + 1) : idx + 1
      }-06T01:17:52.000Z`,
      account_slug: "test2",
      user_slug: "false:add1e8e3-0218-4519-b599-c7ce909ed2ee",
      assistant_slug: "0:2hHgzUcxOrq4yENo2R5jGGQYtQF",
      attachment_count: 0,
    },
    {
      id: `${idx}2`,
      agent_slug: "0:2hHgzUcxOrq4yENo2R5jGGQYtQF",
      agent_name: "Jimmy John GPT",
      status: "active",
      run_is_active: 0,
      chat_status_update: "",
      summary: "",
      open_ai_thead_id: "thread_c5HAbGCjKvdwUT1GslQfeHuA",
      is_active: 1,
      is_deleted: 0,
      created_at: `2023-${
        idx + 1 < 10 ? "0" + (idx + 1) : idx + 1
      }-06T01:17:52.000Z`,
      updated_at: `2023-${
        idx + 1 < 10 ? "0" + (idx + 1) : idx + 1
      }-06T01:17:52.000Z`,
      account_slug: "test2",
      user_slug: "false:add1e8e3-0218-4519-b599-c7ce909ed2ee",
      assistant_slug: "0:2hHgzUcxOrq4yENo2R5jGGQYtQF",
      attachment_count: 0,
    },
  ])
  .reduce<CopilotThread[]>((acc, cur) => {
    acc.push(...(cur as unknown as CopilotThread[]));

    return acc;
  }, []);

function groupThreadsByDate(items: CopilotThread[]) {
  const now = new Date();
  const past30Days = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 30
  );

  const groups: { [key: string]: CopilotThread[] } = {};

  items.sort(
    (a, b) =>
      new Date(b.created_at!).getTime() - new Date(a.created_at!).getTime()
  );

  items.forEach((item) => {
    const createdAt = new Date(item.created_at!);

    // Check if the item is within the past 30 days
    if (createdAt >= past30Days) {
      if (!groups["Past 30 days"]) {
        groups["Past 30 days"] = [];
      }
      groups["Past 30 days"].push(item);
    } else {
      const monthYear = `${new Date(item.created_at!).toLocaleString("en-us", {
        month: "short",
        year: "numeric",
      })}`;
      if (!groups[monthYear]) {
        groups[monthYear] = [];
      }
      groups[monthYear].push(item);
    }
  });

  // Convert the grouped items into an array of Group objects
  const groupList = Object.keys(groups).map((groupName) => ({
    groupName,
    items: groups[groupName],
  }));

  return groupList;
}

const Container = styled(Box)({
  paddingLeft: "8px",
  paddingRight: "8px",
});

const ThreadGroup: FC<{ name: string; threads: CopilotThread[] }> = (props) => {
  const { name, threads } = props;

  const [collapsed, setCollapsed] = useState(false);

  const groupName = useMemo(() => {
    const nameSplits = name.split(" ");
    const year = nameSplits.pop();

    if (Number(year) === new Date().getFullYear()) {
      return nameSplits.join(" ");
    }

    return name;
  }, [name]);

  return (
    <Collapse in={!collapsed} collapsedSize={36}>
      <Stack direction="row" alignItems="center" height="36px" gap={1}>
        <IconButton
          sx={{
            p: 0,
            transform: collapsed ? "rotate(180deg)" : undefined,
            transition: "transform 0.2s ease",
          }}
          size="small"
          onClick={() => setCollapsed(!collapsed)}
        >
          <ExpandLess fontSize="small" />
        </IconButton>
        <Typography
          fontSize={13}
          fontWeight={400}
          lineHeight="22px"
          color="text.secondary"
        >
          {groupName}
        </Typography>
      </Stack>
      <Box>
        {threads.map((thread) => (
          <ListItem thread={thread} key={thread.id} />
        ))}
      </Box>
    </Collapse>
  );
};

type ItemsListingProps = {};

const ItemsListing: FC<ItemsListingProps> = (props) => {
  const { data } = useThreads();

  const { threadSlug } = useParams<{ threadSlug: string }>();
  const navigate = useAppNavigate();

  const setSelectedThread = useAgentChatStore.useSetSelectedThread();
  const searchValue = useAgentChatStore.useSearchValue();

  const threads = useMemo(
    () =>
      data?.pages.reduce<CopilotThread[]>((acc, cur) => {
        if (!searchValue) {
          acc.push(...cur.data);

          return acc;
        } else {
          const filtered = cur.data.filter(
            (d) =>
              d.agent_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
              d.summary?.toLowerCase().includes(searchValue.toLowerCase())
          );

          acc.push(...filtered);

          return acc;
        }
      }, []) ?? [],
    [data, searchValue]
  );

  const groupedThreads = useMemo(() => {
    return groupThreadsByDate(threads);
  }, [threads]);

  useEffect(() => {
    if (threads.length > 0) {
      const thread = threads.find((thread) => `${thread.id}` === threadSlug);
      if (!thread) {
        navigate(`/agent-chat/${threads[0].id}`);
      } else {
        setSelectedThread(thread);
      }
    }
  }, [setSelectedThread, navigate, threads, threadSlug]);

  return (
    <Container>
      {groupedThreads.map(({ groupName, items }) => (
        <ThreadGroup name={groupName} threads={items} />
      ))}
      {/* {sampleThreads.map((thread) => (
        <ListItem thread={thread} key={thread.id} />
      ))} */}
    </Container>
  );
};

export default ItemsListing;
