import { Rating, useTheme } from "@mui/material";
import { CustomCellEditorProps } from "ag-grid-react";
import Cell from "components/DatasetTable/Cell";
import { FC, memo } from "react";

const RatingCellEditor: FC<CustomCellEditorProps> = memo((props) => {
  const theme = useTheme();

  return (
    <Cell
      isLoading={!props.data}
      sx={{ background: theme.palette.background.TableBody }}
    >
      <Rating
        value={props.value}
        onChange={(event, newValue) => {
          props.onValueChange?.(newValue ?? 0);
        }}
        precision={0.5}
        sx={{
          ".MuiRating-iconFilled": { color: theme.palette.text.primary },
          ".MuiRating-iconEmpty": { color: theme.palette.text.secondary },
        }}
      />
    </Cell>
  );
});

export default RatingCellEditor;
