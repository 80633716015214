import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import FormField from "components/FormField";
import { DocumentElementType } from "enums/Form";
import GuiDocumentModal from "models/GuiDocument";
import { FC, useState } from "react";
import useGuiDocumentStore from "store/stores/gui-document";

type AddPageModalProps = {
  guiDocument?: GuiDocument;
  onPageCreated(): void;
} & Omit<DialogProps, "onSubmit" | "open">;

const AddPageModal: FC<AddPageModalProps> = (props) => {
  const { guiDocument, onPageCreated, ...dialogProps } = props;

  const isOpen = useGuiDocumentStore.useIsAddPageModalOpen();
  const setIsOpen = useGuiDocumentStore.useSetIsAddPageModalOpen();

  const [title, setTitle] = useState("");

  const { mutateAsync: createPage, isLoading } = useMutation({
    mutationFn: async (data: {
      documentSlug: string;
      data: Record<string, unknown>;
    }) => {
      const response = await GuiDocumentModal.createPage(
        data.documentSlug,
        data.data
      );

      return response.data;
    },
  });

  const handleSubmit = async () => {
    if (!guiDocument || !title) {
      return;
    }

    await createPage({
      documentSlug: guiDocument.slug,
      data: {
        title,
      },
    });

    onPageCreated();
    handleClose();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog scroll="body" {...dialogProps} open={isOpen} onClose={handleClose}>
      <DialogTitle>Add Page</DialogTitle>

      <DialogContent>
        <FormField
          label="Page Title"
          type={DocumentElementType.TextField}
          value={title}
          onChange={(v) => setTitle(v as string)}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddPageModal;
