import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Stack } from "@mui/material";
import FormField from "components/FormField";
import { DocumentElementType } from "enums/Form";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import useSSAgent from "store/stores/ss-agent";
import { z } from "zod";

const formSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  instruction: z.string().optional(),
  tag: z.string().optional(),
  icon: z.string().optional(),
});

type AgentSkillFormType = z.infer<typeof formSchema>;

type AgentSkillDetailsProps = {
  agentSkill?: AgentSkill;
};

const AgentSkillDetails: FC<AgentSkillDetailsProps> = (props) => {
  const { agentSkill } = props;

  const setEditDrawer = useSSAgent.useSetEditDrawer();

  const { mutateAsync: updateAgentSkill } = useUpdateItem({
    modelName: ApiModels.SSAgentSkill,
  });

  const from = useForm<AgentSkillFormType>({
    resolver: zodResolver(formSchema),
  });

  useEffect(() => {
    from.setValue("title", agentSkill?.title ?? "");
    from.setValue("description", agentSkill?.description);
    from.setValue("instruction", agentSkill?.instruction);
    from.setValue("tag", agentSkill?.tag);
    from.setValue("icon", agentSkill?.meta_data?.icon as string | undefined);
  }, [agentSkill, from]);

  const onSubmit = async (data: AgentSkillFormType) => {
    const { icon, ...rest } = data;
    if (agentSkill?.slug) {
      updateAgentSkill({
        slug: agentSkill.slug,
        data: {
          ...rest,
          meta_data: { ...(agentSkill?.meta_data ?? {}), icon },
        },
      });
    }
  };

  return (
    <FormProvider {...from}>
      <form onSubmit={from.handleSubmit(onSubmit)}>
        <Stack direction="column" gap={2} mt={2}>
          <FormField
            label="Name"
            type={DocumentElementType.TextField}
            formControl={{ name: "title", control: from.control }}
          />
          <FormField
            label="Name"
            type={DocumentElementType.TextField}
            value={agentSkill?.agent_skill_slug}
            fieldExtras={{ disabled: true, field: {} }}
          />
          <FormField
            label="Description"
            type={DocumentElementType.TextField}
            formControl={{ name: "description", control: from.control }}
          />
          <FormField
            label="Instruction"
            type={DocumentElementType.TextArea}
            formControl={{ name: "instruction", control: from.control }}
          />
          <FormField
            label="Tag"
            type={DocumentElementType.TextField}
            formControl={{ name: "tag", control: from.control }}
          />
          <FormField
            label="Icon"
            type={DocumentElementType.Icon}
            formControl={{ name: "icon", control: from.control }}
          />
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="center"
          >
            <Button variant="contained" type="submit">
              Save
            </Button>
            <Button
              variant="outlined"
              type="button"
              onClick={() => {
                setEditDrawer({
                  enabled: false,
                });
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default AgentSkillDetails;
