import { Chip } from "@mui/material";
import { ColDef } from "ag-grid-community";
import DefaultListCellTooltip from "components/DatasetTable/colDefs/DefaultListCellTooltip";
import getMultiSelectEditorDefs from "components/DatasetTable/colDefs/getMultiSelectEditorDefs";

const getCheckColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => ({
  ...defaults,
  ...getMultiSelectEditorDefs(field, options),
  tooltipField: field.slug,
  tooltipComponent: DefaultListCellTooltip,
  tooltipComponentParams: {
    getListData(value: string) {
      const option = field.list_items?.find((item) => item.value === value);

      return {
        text: (
          <Chip
            size="small"
            label={option?.label}
            sx={{ bgcolor: option?.color }}
          />
        ),
      };
    },
  },
});

export default getCheckColDef;
