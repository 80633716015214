import ChatBubbleOutlineOutlined from "@mui/icons-material/ChatBubbleOutlineOutlined";
import FileCopyOutlined from "@mui/icons-material/FileCopyOutlined";
import FilterAlt from "@mui/icons-material/FilterAlt";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlined from "@mui/icons-material/KeyboardArrowUpOutlined";
import ListAltOutlined from "@mui/icons-material/ListAltOutlined";
import Search from "@mui/icons-material/Search";
import StarOutlineOutlined from "@mui/icons-material/StarOutlineOutlined";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import TabStyled from "components/TabStyled";
import InnerPageLayout from "layouts/inner-app-layout";
import useThreadAgent from "queries/copilot/useThreadAgent";
import { Resizable } from "re-resizable";
import { FC, SyntheticEvent, memo, useEffect, useState } from "react";
import useAgentChatStore from "store/stores/agent-chat";
import Scratchpad from "../Scratchpad";
import TabSelector from "./TabSelector";

const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.background.InnerPageLayout,
  borderRadius: "6px 6px 0 0",
  height: "100%",
  marginLeft: "8px",
  marginRight: "8px",

  ".layout-container": {
    height: "100%",

    ".header-container": {
      minHeight: 0,
    },

    ".header-container+.MuiBox-root": {
      minHeight: 0,

      ".tabs-container": {
        minHeight: 0,
      },

      ".center-box": {
        minHeight: 0,
      },
    },

    ".tabs-container": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",

      ".MuiTabs-root": {
        width: "100%",

        ".MuiTabs-scroller": {
          width: "100%",

          ".MuiTabs-flexContainer": {
            borderBottom: `1px solid ${theme.palette.gfGrey.Divider}`,
            width: "100%",
          },

          ".MuiTabs-indicator": {
            bottom: "2px",
            height: "2px",
          },
        },
      },
    },
  },
}));

const MessagesComponent: FC = memo(
  () => {
    const theme = useTheme();
    const thread = useAgentChatStore.useSelectedThread();

    const agent = useThreadAgent(thread?.agent_slug);

    const [value, setValue] = useState(0);

    const handleChange = (_: SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    return (
      <Container>
        {thread && (
          <InnerPageLayout
            title={agent?.agent_name ?? "Copilot"}
            onChange={handleChange}
            tabList={[
              {
                label: <TabStyled title="Chat" />,
                icon: <ChatBubbleOutlineOutlined fontSize="small" />,
              },
              {
                label: "",
                icon: <Divider orientation="vertical" variant="middle" />,
                disabled: true,
              },
              {
                label: <TabStyled title="Files" />,
                icon: <FileCopyOutlined fontSize="small" />,
              },
              {
                label: "",
                icon: <Divider orientation="vertical" variant="middle" />,
                disabled: true,
              },
              {
                label: <TabStyled title="Jobs" />,
                icon: <ListAltOutlined fontSize="small" />,
              },
            ]}
            value={value}
            extra={
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                mt={0.5}
                color={theme.palette.gfGrey.GFIcon}
              >
                <InfoOutlined />
                <StarOutlineOutlined />
              </Stack>
            }
            tabsExtra={
              <Stack
                position="absolute"
                right="30px"
                bottom="6px"
                direction="row"
                alignItems="center"
                gap={2}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  <Search
                    fontSize="small"
                    sx={{ color: theme.palette.gfGrey.GFIcon }}
                  />
                  <Typography color="text.secondary">Search</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <FilterAlt
                    fontSize="small"
                    sx={{ color: theme.palette.gfGrey.GFIcon }}
                  />
                  <Typography color="text.secondary">Filter</Typography>
                  <KeyboardArrowDownOutlined
                    fontSize="small"
                    sx={{ color: theme.palette.gfGrey.GFIcon }}
                  />
                </Stack>
                <IconButton
                  sx={{ p: 0, borderRadius: "4px", border: "1px solid" }}
                  size="small"
                >
                  <KeyboardArrowUpOutlined fontSize="small" />
                </IconButton>
              </Stack>
            }
          >
            <Box height="100%" position="relative" paddingBottom="76px">
              <TabSelector tabIndex={value} />
            </Box>
          </InnerPageLayout>
        )}
      </Container>
    );
  },
  () => true
);

type MiddleSectionProps = {};

const MiddleSection: FC<MiddleSectionProps> = (props) => {
  const isScratchpadActive = useAgentChatStore.useIsScratchpadActive();

  const [size, setSize] = useState<{
    width: string | number;
    height: string | number;
  }>({ width: 350, height: "100%" });

  useEffect(() => {
    setSize({ width: isScratchpadActive ? 350 : "100%", height: "100%" });
  }, [isScratchpadActive]);

  return (
    <Stack
      sx={{ width: "100%", height: "100%" }}
      direction="row"
      overflow="hidden"
    >
      <Resizable
        maxWidth="100%"
        minWidth="300px"
        onResizeStop={(event, direction, element, delta) => {
          setSize((prev) => ({
            ...prev,
            width: Number(prev.width) + delta.width,
          }));
        }}
        enable={{
          right: isScratchpadActive,
        }}
        size={size}
      >
        <MessagesComponent />
      </Resizable>
      <Scratchpad></Scratchpad>
    </Stack>
  );
};

export default MiddleSection;
