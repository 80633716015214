import EditOutlined from "@mui/icons-material/EditOutlined";
import SchemaOutlined from "@mui/icons-material/SchemaOutlined";
import { Box, Link as MuiLink, Stack, Typography } from "@mui/material";
import FormField from "components/FormField";
import useAccountSlug from "hooks/useAccountSlug";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useGuiDashboardV2Store } from "store/stores/gui-dashboard-v2";

type FusionTabProps = {};

const FusionTab: FC<FusionTabProps> = (props) => {
  const widget = useGuiDashboardV2Store.useWidget();

  const accountSlug = useAccountSlug();

  return (
    <>
      <FormField label="Load Data Fusion" collapsible>
        <MuiLink
          component={Link}
          to={`/${accountSlug}/fusion/flow-designer/${widget?.associated_fusion_slug}`}
          target="_blank"
          sx={{ color: "#fff", textDecoration: "none" }}
        >
          <Box
            sx={{
              background: "#25252a",
              borderRadius: "4px",
              p: 2,
            }}
            onClick={() => {}}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <SchemaOutlined fontSize="large" />
                <Typography fontSize="15px">Load Data Fusion</Typography>
              </Stack>
              <EditOutlined />
            </Stack>
          </Box>
        </MuiLink>
      </FormField>
      {widget?.edit_fusion_slug && (
        <FormField label="Edit Data Fusion" collapsible>
          <MuiLink
            component={Link}
            to={`/${accountSlug}/fusion/flow-designer/${widget.edit_fusion_slug}`}
            target="_blank"
            sx={{ color: "#fff", textDecoration: "none" }}
          >
            <Box
              sx={{
                background: "#25252a",
                borderRadius: "4px",
                p: 2,
              }}
              onClick={() => {}}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  <SchemaOutlined fontSize="large" />
                  <Typography fontSize="15px">Edit Data Fusion</Typography>
                </Stack>
                <EditOutlined />
              </Stack>
            </Box>
          </MuiLink>
        </FormField>
      )}
    </>
  );
};

export default FusionTab;
