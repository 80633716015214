import { NO_DRAG_CLASS_NAME } from "constants/index";

export { default as GFMouseSensor } from "./GFMouseSensor";
export { default as GFTouchSensor } from "./GFTouchSensor";

export const isDraggable = (element: HTMLElement | null) => {
  let el = element;
  while (el) {
    if (el.classList.contains(NO_DRAG_CLASS_NAME)) {
      return false;
    }

    el = el.parentElement;
  }

  return true;
};
