
import { PlusIcon } from 'assets/icons';
import { useSystemLayoutStore } from "store/stores/systemLayout";
import { AddButton } from "./index.styles";

const ButtonAdd = () => {
  const addButtonProps = useSystemLayoutStore.useAddButtonProps?.();

  return (
    <AddButton
      className="add_item_btn"
      variant="outlined"
      color="secondary"
      sx={{ borderColor: (theme) => theme.palette.background.GF10 }}
      fullWidth
      {...addButtonProps}
    >
      <PlusIcon sx={{ color: "#ffffff" }} />
    </AddButton>
  );
};

export default ButtonAdd;
