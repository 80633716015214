import { ArrowBack } from "@mui/icons-material";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { cloneDeep, debounce, get, set } from "lodash";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { normalizeObjectForAPI } from "utils";
import ConditionSets from "views/app-view/gui-dataset/components/ConditionSets";

type RecordFilterProps = {
  gui: GfGui;
  guiTab: GuiTab;
  indexKey?: string;
  path?: string[];
  dynamicRHS?: boolean;
  onClickBack: () => void;
};

const name = "view_filters";

const RecordFilterRules: FC<RecordFilterProps> = (props) => {
  const { gui, guiTab, onClickBack, indexKey, path, dynamicRHS } = props;

  const { control, register, handleSubmit, watch, setValue, ...rest } =
    useForm();
  const initialValueSet = useRef(false);
  const allowNetworkRequest = useRef(false);

  const { mutate: updateGuiTab } = useUpdateItem({
    modelName: ApiModels.GuiTab,
  });

  const guiSlug = useMemo(() => gui.slug, [gui.slug]);
  const { fields, remove, insert } = useFieldArray({
    control,
    name,
  });

  const fieldsArray: {
    condition_set: Condition[];
  }[] = watch(name);

  useEffect(() => {
    if (!fieldsArray?.length) {
      setValue(name, [
        {
          condition_set: [{ a: "", b: "", o: "=" }],
        },
      ]);
    }
  }, [fieldsArray, setValue]);

  useEffect(() => {
    initialValueSet.current = false;
    allowNetworkRequest.current = false;
  }, [guiSlug]);

  useEffect(() => {
    if (guiSlug && !initialValueSet.current) {
      let filters = guiTab?.filter_settings?.view_filters?.[guiTab.slug!];
      if (indexKey && path) {
        let parentTab = guiTab[indexKey as keyof typeof guiTab];
        const selectedTab = get(parentTab, path.slice(0, -1));
        filters = selectedTab.filter_rules ?? [];
      }
      setValue(name, filters);
      initialValueSet.current = true;
      setTimeout(() => {
        allowNetworkRequest.current = true;
      }, 3000);
    }
  }, [guiSlug, guiTab?.filter_settings, setValue, guiTab, indexKey, path]);

  const submitHandler = useCallback(
    (data: Partial<{ view_filters: Condition[] }>) => {
      if (guiTab.slug && allowNetworkRequest.current) {
        if (indexKey && path) {
          let parentTab = guiTab[indexKey as keyof typeof guiTab];
          const selectedTab = get(parentTab, path.slice(0, -1));
          const tab = cloneDeep(guiTab);
          set(tab, [indexKey, ...path.slice(0, -1)], {
            ...selectedTab,
            filter_rules: data.view_filters,
          });
          updateGuiTab({
            slug: guiTab.slug,
            data: normalizeObjectForAPI({ ...guiTab, ...tab }, [
              "gui_slug",
              "parent_tab_slug",
              "tab_type",
            ]),
          });
        } else {
          const reqData = set(
            cloneDeep(guiTab?.filter_settings ?? {}),
            `view_filters.${guiTab.slug}`,
            data.view_filters
          );
          updateGuiTab({
            slug: guiTab.slug,
            data: normalizeObjectForAPI({ filter_settings: reqData }),
          });
        }
      }
    },
    [guiTab, indexKey, path, updateGuiTab]
  );

  useEffect(() => {
    const submitDeb = debounce(() => {
      handleSubmit(submitHandler)();
    }, 600);
    const subscription = watch((_) => {
      submitDeb();
    });
    return () => subscription.unsubscribe();
  }, [watch, submitHandler, handleSubmit]);

  return (
    <SidebarSectionWrap
      title="Manage Rules"
      sx={{ mt: 1 }}
      rightIcon={<></>}
      leftIcon={<ArrowBack />}
      onLeftIconClick={onClickBack}
    >
      {fields.map((field, fieldIdx) => (
        <ConditionSets
          key={field.id}
          index={fieldIdx}
          onRemoveSet={() => remove(fieldIdx)}
          onAddSet={() => {
            insert(fieldIdx + 1, {
              condition_set: [{ a: "", b: "", o: "=" }],
            });
          }}
          control={control}
          register={register}
          parentNamePath={`${name}[${fieldIdx}]`}
          conditionsLength={fields.length}
          indexKey={indexKey}
          path={path}
          dynamicRHS={dynamicRHS}
          {...rest}
        />
      ))}
    </SidebarSectionWrap>
  );
};

export default RecordFilterRules;
