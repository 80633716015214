import FormField from "components/FormField";
import IconPickerField from "components/IconPicker";
import { DASHBOARD_WIDGET_TYPE } from "constants/gui";
import { AllIconPickerIcons } from "constants/index";
import { ParameterType } from "enums/3pApp";
import { GuiType } from "enums/gui";
import capitalize from "lodash/capitalize";
import { ApiModels } from "queries/apiModelMapping";
import { useGuiTabs } from "queries/gui/useGuiTabs";
import useListItems from "queries/useListItems";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { ParamField } from "views/app-view/flow-designer/components/NodeEditorFields";

type CreateDashboardWidgetFieldsProps = {};

const CreateDashboardWidgetFields: FC<CreateDashboardWidgetFieldsProps> = (
  props
) => {
  const { control, watch } = useFormContext();

  const type = watch("type");
  const guiSlug = watch("gui_slug");

  const { data: guis = [] } = useListItems({
    modelName: ApiModels.Gui,
  });

  const guiOptions = useMemo<LabeledValue[]>(
    () => guis.map((gui) => ({ label: gui.name, value: gui.slug })),
    [guis]
  );

  const { data: guiTabs = [] } = useGuiTabs(guiSlug);
  const dashboardOptions = useMemo(
    () =>
      guiTabs
        .filter((tab) => tab.tab_type === GuiType.DashboardV2)
        .map((tab) => ({ label: tab.tab_name, value: tab.slug }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    [guiTabs]
  );

  const { data: datasetDesigns = [] } = useListItems({
    modelName: ApiModels.DatasetDesign,
  });

  const datasetDesignsOptions = useMemo(
    () =>
      datasetDesigns.map((design) => ({
        label: design.name,
        value: design.slug,
      })),
    [datasetDesigns]
  );

  const widgetTypeOptions = useMemo<MappableParameter["options"]>(() => {
    return Object.values(DASHBOARD_WIDGET_TYPE).map((type) => {
      const option = {
        label: type
          .split("-")
          .map((part) => capitalize(part))
          .join(" "),
        value: type,
        nested: [] as MappableParameter[],
      };

      if (type === DASHBOARD_WIDGET_TYPE.DATA_LIST) {
        option.nested.push({
          name: "dataset_design_slug",
          label: "Select Widget Dataset",
          type: ParameterType.Select,
          options: datasetDesignsOptions,
        });
      }

      return option;
    });
  }, [datasetDesignsOptions]);

  return (
    <>
      <ParamField
        mappable
        field={{
          name: "gui_slug",
          label: "Gui",
          type: ParameterType.Select,
          options: guiOptions,
          nested: [
            {
              name: "tab_slug",
              label: "Dashboard",
              type: ParameterType.Select,
              options: dashboardOptions,
            },
          ],
        }}
      />
      <ParamField
        mappable
        field={{
          name: "title",
          label: "Widget Title",
          type: ParameterType.Text,
        }}
      />
      <ParamField
        mappable
        field={{
          name: "type",
          label: "Widget Type",
          type: ParameterType.Select,
          options: widgetTypeOptions,
        }}
      />
      {type === DASHBOARD_WIDGET_TYPE.STAT && (
        <FormField
          label="Widget Icon"
          formControl={{ control, name: "icon" }}
          collapsible
        >
          {({ value, onChange }) => (
            <IconPickerField
              icons={AllIconPickerIcons}
              value={value as string}
              onChange={(iconSlug) => onChange?.(iconSlug)}
              placement="left"
              textFieldProps={{
                fullWidth: true,
                variant: "filled",
                size: "small",
                hiddenLabel: true,
              }}
            />
          )}
        </FormField>
      )}
    </>
  );
};

export default CreateDashboardWidgetFields;
