import Model from "models";

class AgentModel extends Model<Agent> {
  constructor() {
    super("/agent", "gui-fusion");
  }
}

const model = new AgentModel();

export default model;
