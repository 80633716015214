import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type State = {
  selectedPageId: string | null;
  isAddPageModalOpen: boolean;
  pagesContent: Record<string, unknown>;
  pageHeadings: Record<string, { key: string; text: string }[]>;
};

type Actions = {
  setSelectedPageId(id: string | null): void;
  setIsAddPageModalOpen(open: boolean): void;
  addPageContent(pageId: string, content: unknown): void;
  addPageHeadings(
    pageId: string,
    headings: { key: string; text: string }[]
  ): void;
};

export const useGuiDocumentStoreBase = create(
  devtools(
    immer<State & Actions>((set) => ({
      selectedPageId: null,
      isAddPageModalOpen: false,
      pagesContent: {},
      pageHeadings: {},
      setSelectedPageId(id) {
        set((state) => {
          state.selectedPageId = id;
        });
      },
      setIsAddPageModalOpen(open) {
        set((state) => {
          state.isAddPageModalOpen = open;
        });
      },
      addPageContent(pageId, content) {
        set((state) => {
          state.pagesContent[pageId] = content;
        });
      },
      addPageHeadings(pageId, headings) {
        set((state) => {
          state.pageHeadings[pageId] = headings;
        });
      },
    }))
  )
);

const useGuiDocumentStore = createSelectorHooks(useGuiDocumentStoreBase);

export default useGuiDocumentStore;
