import Model from "models";

class AgentUserModel extends Model<AgentUser> {
  constructor() {
    super("/agent-user", "gui-fusion");
  }
}

const model = new AgentUserModel();

export default model;
