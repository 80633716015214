import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import DatasetDataProvider from "components/DataProviders/DatasetDataProvider";
import { ListSource } from "enums/Form";
import { FC } from "react";

const RadioGroupWrapper = styled(Box)(({ theme }) => {
  return {
    padding: "12px 12px 12px 16px",
    borderRadius: "5px",
    background: theme.palette.background.GF5,

    ".MuiFormControlLabel-root ": {
      margin: "0",
      position: "relative",
      height: "24px",

      "+ .MuiFormControlLabel-root ": {
        marginTop: "16px",
      },
    },

    ".MuiButtonBase-root ": {
      position: "absolute",
      right: "12px",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "24px",
      height: "24px",
      padding: "0",
      background: theme.palette.background.GF5,
      borderRadius: "100%",
      border: `2px solid ${theme.palette.background.GF20}`,
      transition: "all 0.4s ease",

      "&.Mui-checked": {
        background: theme.palette.primary.main,
        "&:before": {
          opacity: "1",
          visibility: "visible",
        },
      },

      "&:before": {
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "10px",
        height: "10px",
        transform: "translate(-50%, -50%)",
        background: theme.palette.text.primary,
        borderRadius: "100%",
        content: `""`,
        transition: "all 0.4s ease",
        opacity: "0",
        visibility: "hidden",
      },
    },

    ".MuiTypography-root": {
      fontSize: "15px",
      lineHeight: "18px",
      fontWeight: "400",
      textTransform: "capitalize",
    },

    ".MuiSvgIcon-root ": {
      display: "none",
    },

    ".MuiTouchRipple-root": {
      display: "none",
    },
  };
});

type RadioFieldProps = {
  field: DataField;
  value?: string;
  onChange?(value: string): void;
};

const RadioList: FC<RadioFieldProps & { options?: LabeledValue[] }> = (
  props
) => {
  const { options, value, onChange } = props;
  return (
    <RadioGroupWrapper>
      <RadioGroup value={value} onChange={(_, value) => onChange?.(value)}>
        {options?.map((option) => {
          return (
            <FormControlLabel
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          );
        })}
      </RadioGroup>
    </RadioGroupWrapper>
  );
};

const RadioField: FC<RadioFieldProps> = (props) => {
  const {
    list_source: type,
    associated_document: datasetDesignSlug,
    associated_document_label_field: labelField,
  } = props.field;
  if (type === ListSource.RecordAssociation) {
    <DatasetDataProvider
      datasetDesignSlug={datasetDesignSlug!}
      fetchSelectedValueInList={true}
      keyBinding={{
        label: labelField,
        value: "id",
      }}
    >
      <RadioList {...props} />
    </DatasetDataProvider>;
  }

  return (
    <RadioList {...props} options={props.field.list_items as LabeledValue[]} />
  );
};

export default RadioField;
