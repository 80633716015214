import { CropperType } from "enums/gui";

export const GUI_TYPE = {
  DATASET_LIST: "dataset-list",
  DASHBOARD: "dashboard",
};

export const GUI_TYPE_OPTIONS = [
  { label: "Dataset List", value: GUI_TYPE.DATASET_LIST },
  { label: "Dashboard", value: GUI_TYPE.DASHBOARD },
];

export const DASHBOARD_WIDGET_TYPE = {
  BAR: "bar",
  LINE: "line",
  PIE: "pie",
  STAT: "stat",
  DATA_LIST: "data-list",
  DIVIDER: "divider",
} as const;

export const DASHBOARD_WIDGET_TYPE_OPTIONS = [
  {
    label: "Bar Chart",
    value: DASHBOARD_WIDGET_TYPE.BAR,
    icon: "BarChartOutlined",
  },
  {
    label: "Line Chart",
    value: DASHBOARD_WIDGET_TYPE.LINE,
    icon: "TimelineOutlined",
  },
  {
    label: "Pie Chart",
    value: DASHBOARD_WIDGET_TYPE.PIE,
    icon: "DataUsageOutlined",
  },
  {
    label: "Statistics",
    value: DASHBOARD_WIDGET_TYPE.STAT,
    icon: "ScoreOutlined",
  },
  {
    label: "Data List",
    value: DASHBOARD_WIDGET_TYPE.DATA_LIST,
    icon: "Toc",
  },
];

export const CROPPER_TYPE = [
  { label: "None", value: CropperType.None },
  { label: "Fix all", value: CropperType.FixAll },
  { label: "Fix width only", value: CropperType.FixWidthOnly },
  { label: "Fix height only", value: CropperType.FixHeightOnly },
];

export const DEFAULT_WIDGET_PROPS: Record<
  string,
  Omit<DashboardLayout[number], "i" | "x" | "y">
> = {
  [DASHBOARD_WIDGET_TYPE.STAT]: {
    w: 6,
    h: 22,
    minH: 30,
    minW: 4,
    isResizable: true,
    isDraggable: true,
  },
  [DASHBOARD_WIDGET_TYPE.PIE]: {
    w: 8,
    h: 35,
    minH: 30,
    minW: 4,
    isResizable: true,
    isDraggable: true,
  },
  [DASHBOARD_WIDGET_TYPE.BAR]: {
    w: 8,
    h: 35,
    minH: 30,
    minW: 4,
    isResizable: true,
    isDraggable: true,
  },
  [DASHBOARD_WIDGET_TYPE.LINE]: {
    w: 8,
    h: 35,
    minH: 30,
    minW: 4,
    isResizable: true,
    isDraggable: true,
  },
  [DASHBOARD_WIDGET_TYPE.DIVIDER]: {
    w: 24,
    h: 6,
    minW: 24,
    minH: 30,
    isResizable: false,
    isDraggable: true,
  },
  [DASHBOARD_WIDGET_TYPE.DATA_LIST]: {
    w: 24,
    h: 38,
    minH: 30,
    minW: 4,
    isResizable: true,
    isDraggable: true,
  },
};
