import { SerializedLexicalNode } from "lexical";

export const convertLexicalNodesToString = (
  nodes: SerializedLexicalNode[]
): string => {
  let value = "";

  nodes.forEach((node: any) => {
    if (node.type === "tag") {
      value += `{{{${node.tagType}.${node.tagSlug}}}}`;
    } else if (node.type === "linebreak") {
      value += "\n";
    } else {
      value += `${node.text ?? ""}${convertLexicalNodesToString(
        node.children ?? []
      )}`;
    }
  });

  return value;
};
