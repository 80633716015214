import { Box, useTheme } from "@mui/material";
import { forwardRef } from "react";

type ResizeHandleProps = { handleAxis?: string };

const ResizeHandle = forwardRef<unknown, ResizeHandleProps>((props, ref) => {
  const { handleAxis, ...rest } = props;
  const theme = useTheme();

  return (
    <Box
      {...rest}
      className={`react-resizable-handle react-resizable-handle-${handleAxis}`}
      ref={ref}
      sx={{
        width: "12px !important",
        height: "12px !important",
        borderRight: `1px solid ${theme.palette.text.primary}`,
        borderBottom: `1px solid ${theme.palette.text.primary}`,
        borderRadius: "3px",
        bottom: "24px !important",
        right: "24px !important",
        background: "transparent !important",
        ":after": {
          display: "none !important",
        },
      }}
    ></Box>
  );
});

export default ResizeHandle;
