import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type State = {
  scrollToHeading: ((heading: string) => void) | null;
};

type Actions = {
  registerScrollToHeadingFunction(func: (heading: string) => void): void;
};

export const useLexicalStoreBase = create(
  devtools(
    immer<State & Actions>((set) => ({
      scrollToHeading: null,
      registerScrollToHeadingFunction(func) {
        set((state) => {
          state.scrollToHeading = func;
        });
      },
    }))
  )
);

const useLexicalStore = createSelectorHooks(useLexicalStoreBase);

export default useLexicalStore;
