import AddIcon from "@mui/icons-material/Add";
import {
  Avatar,
  Box,
  BoxProps,
  ClickAwayListener,
  Link,
  Stack,
  styled,
} from "@mui/material";
import { ProfileIcon } from "assets/icons";
import classNames from "classnames";
import Scrollbar from "components/Scrollbar";
import useAccountSlug from "hooks/useAccountSlug";
import useCurrentUser from "hooks/useCurrentUser";
import useOpenClose from "hooks/useOpenClose";
import AddAccountModal from "module/SidebarNav/AddAccountModal";
import { queryClient } from "queries";
import useSignOut from "queries/auth/useSignOut";
import * as React from "react";
import { Fragment } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "store";
import { hasAccessToModule, pathToModule } from "utils/gaurd";
import isAccountOwner from "utils/isAccountOwner";
import { ProfileAvatar } from "./style";
type AccountSelectorProps = { postion?: "left" | "top" };

const AvatarDropDownWrapper = styled("div")((theme) => ({
  ".MuiButtonBase-root": {
    padding: "0",
  },

  ".MuiAvatar-root ": {
    width: "32px",
    height: "32px",
    borderRadius: "100%",
    overflow: "hidden",
  },
}));
const ProfileLinksListing = styled("ul")(({ theme }) => ({
  padding: "0 0 0 10px",
  margin: "0",
  listStyle: "none",

  li: {
    cursor: "pointer",
    margin: "0 0 1px",
  },

  a: {
    display: "block",
    fontSize: "13px",
    lineHeight: "30px",
    color: "rgba(0, 0, 0, 0.8)",
    textDecoration: "none",

    "&:hover": {
      color: "#000",
    },
  },

  ".profile-listing-counter": {
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: "11px",
  },
}));

interface ProfileDropDownProps extends BoxProps {
  placement: "left" | "top";
}
const ProfileDropDown = styled(Box)<ProfileDropDownProps>(
  ({ theme, placement }) => {
    return {
      position: "fixed",
      zIndex: "5",
      boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "6px",
      background: theme.palette.gfGrey[200],
      width: "258px",
      color: "rgba(0, 0, 0, 0.8)",
      overflow: "hidden",
      border: `1px solid ${theme.palette.primary.main}`,

      left: placement === "left" ? "26px" : "auto",
      bottom: placement === "left" ? "20px" : "auto",
      top: placement === "top" ? "48px" : "auto",
      right: placement === "top" ? "2px" : "auto",

      ".profile-dropmenu-head": {
        padding: "10px 14px",
        gap: "8px",
        fontSize: "13px",
        lineHeight: "30px",
        background: theme.palette.primary.main,
        fontWeight: "600",
        color: "#fff",
        borderBotom: "1px solid rgba(0, 0, 0, 0.1)",

        ".MuiAvatar-root": {
          width: "24px",
          height: "24px",
          fontSize: "12px",
          lineHeight: "24px",
        },
      },

      ".profile-dropmenu-listing": {
        width: "48px",
        borderRight: "1px solid rgba(215, 215, 215, 1)",
      },

      ".profile-dropmenu-listing_wrap": {
        padding: "7px 10px",

        ".MuiAvatar-root": {
          width: "30px",
          height: "30px",
          fontSize: "12px",
          lineHeight: "18px",
          fontWeight: "600",
          color: "#fff",
          cursor: "pointer",
          position: "relative",
          overflow: "visible",

          "&.active": {
            "&:before": {
              opacity: "1",
              visibility: "visible",
            },
          },

          "&:before": {
            border: "1px solid rgba(112, 111, 114, 1)",
            inset: "-4px",
            content: `""`,
            position: "absolute",
            borderRadius: "100%",
            opacity: "0",
            visibility: "hidden",
          },
        },

        ".add-profile": {
          width: "30px",
          height: "30px",
          border: "1px solid rgba(0, 0, 0, 0.31)",
          color: "rgba(28, 27, 31, 0.6)",
          borderRadius: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",

          svg: {
            width: "16px",
            height: "auto",
          },
        },
      },

      ".profile-guis-detail": {
        flexGrow: "1",
        flexBasis: "0",
        minWidth: "0",
      },

      ".profile-guis-detail-wrap": {
        padding: "7px 10px",
      },

      ".profile-guis-head": {
        alignItems: "center",
        fontSize: "12px",
        lineHeight: "15px",
        color: theme.palette.common.blackshades["100p"],
        margin: "0 0 0px",

        ".MuiAvatar-root": {
          width: "30px",
          height: "30px",
        },
      },
    };
  }
);
const navMenuList = [
  {
    path: "/account-user-module",
    title: "My profile",
  },
  {
    path: "/chat",
    title: "Co-Pilot",
  },
  {
    path: "#",
    title: "Jobs",
  },
  {
    path: "/dataset-design",
    title: "Datasets",
  },
  {
    path: "/fusion",
    title: "Workflows",
  },
  { title: "Import Templates", path: "/import-module" },
  {
    path: "/gui-module",
    title: "Dashboards",
  },
  {
    path: "/media",
    title: "Media",
  },
  { title: "Icons", path: "/icons" },
  {
    path: "/account-user-module",
    title: "Users",
  },
  {
    path: "/chat",
    title: "Sylar Credits",
  },
  {
    path: "/billing-module",
    title: "Account",
  },
  {
    path: "/billing-module",
    title: "Billing",
  },
  {
    path: "/fusion-action-module",
    title: "Apps",
  },
  {
    path: "/vector-knowledgebase-module",
    title: "Vector Embeds",
  },
  {
    path: "/sql-query-module",
    title: "SQL Queries",
  },
  {
    path: "/agent",
    title: "Agent Management",
  },
  {
    path: "/agent-chat",
    title: "Agent Chat",
  },
  {
    path: "/finetune-knowledgebase-module",
    title: "Fine Tune Models",
  },
  {
    path: "/skill-design-module",
    title: "Skills",
  },
  {
    path: "/adventure-module",
    title: "Adventure",
  },
  {
    path: "/double-talk-module",
    title: "Double Talk",
  },
  {
    path: "/saline-chat-module",
    title: "Saline Chat",
  },
  {
    path: "/fusion-connections-module",
    title: "Fusion Connections",
  },
  {
    path: "/connections-management",
    title: "Connections Management",
  },
  {
    path: "/fusion-webhooks-module",
    title: "Fusion Webhooks",
  },
  {
    path: "/ss-agent",
    title: "SS Agent",
  },
];
const AvatarDropDown: React.FC<AccountSelectorProps> = ({
  postion = "left",
}) => {
  const accountSlug = useAccountSlug();
  const currentUser = useCurrentUser();
  const { mutate: handleSignOut } = useSignOut();
  const [dropDown, setDropDown] = React.useState(false);
  const selectAccount = useStore.useSelectedAccount?.();
  const accounts = useStore.useAccounts?.();
  const setSelectedAccount = useStore.useSetSelectedAccount();
  const isOwner = isAccountOwner(
    selectAccount?.subscription?.account_user_type
  );
  const location = useLocation();
  const [open, onOpen, onClose] = useOpenClose();
  const navigate = useNavigate();
  const handleOnSelect = (acct: AppAccount) => {
    if (acct.slug === accountSlug) return;
    const pathAfterAccount = location.pathname.split("/").slice(2, 3).join("/");
    setSelectedAccount(acct.slug);
    queryClient.clear();
    setTimeout(() => navigate(`/${acct.slug}/${pathAfterAccount}`), 0);
  };
  const handleLogout: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    handleSignOut();
  };
  const userAllowedModules = React.useMemo(() => {
    return navMenuList?.filter((item) => {
      if (!item.path) return false;
      if (item.path === "/ss-agent") return true;
      const rmodule = pathToModule(`/accountId${item.path}`);
      const hasAccess = hasAccessToModule(
        rmodule!,
        selectAccount?.subscription?.permissions!,
        false
      );

      return isOwner || hasAccess;
    });
  }, [selectAccount?.subscription?.permissions, isOwner]);

  const closeHanlder = () => {
    setDropDown(false);
  };
  return (
    <React.Fragment>
      <AvatarDropDownWrapper>
        <ProfileAvatar
          className="avatar-area"
          onClick={() => setDropDown(true)}
        >
          <ProfileIcon />
        </ProfileAvatar>
        {dropDown && (
          <ClickAwayListener onClickAway={closeHanlder}>
            <ProfileDropDown className="profile-dropmenu" placement={postion}>
              <Stack
                alignItems={"center"}
                direction="row"
                className="profile-dropmenu-head"
              >
                {/* <GfLogo sx={{ fontSize: "24px" }} /> */}
                <Avatar
                  sx={{
                    backgroundColor: "rgba(107, 32, 19, 1)",
                    color: "white",
                  }}
                >
                  {selectAccount?.name?.[0]?.toLocaleUpperCase()}
                </Avatar>
                {selectAccount?.name}
              </Stack>
              <Stack className="profile-dropmenu-row" direction="row">
                <Box className="profile-dropmenu-listing">
                  <Stack gap={"8px"} className="profile-dropmenu-listing_wrap">
                    {accounts?.map((act) => {
                      return (
                        <Avatar
                          className={classNames({
                            active: act.slug === accountSlug,
                          })}
                          sx={{
                            backgroundColor: "rgba(107, 32, 19, 1)",
                          }}
                          onClick={() => handleOnSelect(act)}
                        >
                          {act.name?.[0]?.toLocaleUpperCase()}
                        </Avatar>
                      );
                    })}

                    <Box className="add-profile" onClick={onOpen}>
                      <AddIcon />
                    </Box>
                  </Stack>
                </Box>
                <Box className="profile-guis-detail">
                  <Scrollbar autoHeight autoHeightMax="498px">
                    <Box className="profile-guis-detail-wrap">
                      <Stack
                        direction="row"
                        className="profile-guis-head"
                        gap="8px"
                      >
                        <Avatar
                          src={
                            currentUser.image?.url ||
                            "/static/images/avatar/1.jpg"
                          }
                        />
                        <Box className="profile-user-name">
                          {currentUser?.first_name}
                        </Box>
                      </Stack>
                      <ProfileLinksListing>
                        {userAllowedModules.map((element) => {
                          return (
                            <Fragment key={element.path}>
                              <li onClick={closeHanlder}>
                                <Link
                                  to={`/${accountSlug}${element.path}`}
                                  component={RouterLink}
                                >
                                  {element.title}
                                </Link>
                              </li>
                            </Fragment>
                          );
                        })}
                        <Fragment key="logout">
                          <Link onClick={handleLogout}>
                            <li>Logout</li>
                          </Link>
                        </Fragment>
                      </ProfileLinksListing>
                    </Box>
                  </Scrollbar>
                </Box>
              </Stack>
            </ProfileDropDown>
          </ClickAwayListener>
        )}
      </AvatarDropDownWrapper>
      <AddAccountModal open={open} onClose={onClose} />
    </React.Fragment>
  );
};

export default AvatarDropDown;
