import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as EditIconSvg } from "../svg/edit.svg";

const EditIcon: React.FC<React.ComponentProps<typeof SvgIcon>> = (props) => {
  return (
    <SvgIcon
      inheritViewBox
      {...props}
      component={EditIconSvg}
      className="plus-icon"
    />
  );
};

export default EditIcon;
