import { Box, Collapse, Divider, styled } from "@mui/material";
import { FC, useState } from "react";
import AgentDetails from "./AgentDetails";
import SessionDetails from "./SessionDetails";

const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.background.CardBackground,
  borderRadius: "6px",
}));

const DetailsWidget: FC = () => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Collapse in={collapsed} collapsedSize={50} sx={{ borderRadius: "6px" }}>
      <Container>
        <AgentDetails isCollapsed={collapsed} setIsCollapsed={setCollapsed} />
        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.ListWidgetItem,
            mx: 2,
          }}
        />
        <SessionDetails />
      </Container>
    </Collapse>
  );
};

export default DetailsWidget;
