import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TollIcon from "@mui/icons-material/Toll";
import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  DrawerProps,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import LabelArrowIcon from "assets/icons/LabelArrowIcon";
import axios from "axios";
import CodeEditor from "components/CodeEditor";
import SidebarSection from "components/RightSidebar";
import RunFusion from "components/RunFusion/RunFusion";
import Scrollbar from "components/Scrollbar";
import Spin from "components/Spin";
import { FUSION_SESSIONS_BUCKET, S3_CLOUD_FRONT_URL } from "configs/AppConfig";
import { SessionStatus } from "enums/Fusion";
import useAccountSlug from "hooks/useAccountSlug";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import FusionModel from "models/Fusion";
import S3Model from "models/S3";
import moment from "moment";
import use3pApps from "queries/3p-app/use3pApps";
import { useExecutionFlow } from "queries/fusion/useExecutionFlow";
import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Components } from "react-virtuoso";
import { getNameInitial } from "utils";
import { v4 } from "uuid";

type OperationLogsV2Props = {
  title: string;
  logsPath?: string;
  outputsPath?: string;
};

const OperationLogsV2: React.FC<OperationLogsV2Props> = memo(
  (props) => {
    const { title, logsPath, outputsPath } = props;

    const theme = useTheme();

    const [collapsed, setCollapsed] = useState(true);
    const [logs, setLogs] = useState<(FusionOperatorLog & { id: string })[]>(
      []
    );
    const [output, setOutput] = useState<unknown>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (logsPath && !collapsed && !logs.length) {
        setLoading(true);
        S3Model.getDownloadUrl({
          key: logsPath,
          bucket: FUSION_SESSIONS_BUCKET,
        }).then((response) => {
          const url = response.data.url;

          if (!url) {
            return;
          }

          axios
            .get(url)
            .then(
              (res) =>
                isArray(res.data) &&
                setLogs(res.data.map((d) => ({ ...d, id: v4() })))
            )
            .catch(console.error)
            .finally(() => setLoading(false));
        });
      }
    }, [logsPath, collapsed]);

    useEffect(() => {
      if (outputsPath && !collapsed && !output) {
        S3Model.getDownloadUrl({
          key: outputsPath,
          bucket: FUSION_SESSIONS_BUCKET,
        }).then((response) => {
          const url = response.data.url;

          if (!url) {
            return;
          }

          axios
            .get(url)
            .then((res) => setOutput(res.data))
            .catch(console.error);
        });
      }
    }, [outputsPath, collapsed]);

    return (
      <Collapse in={!collapsed} collapsedSize={30} sx={{ mt: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <InfoOutlinedIcon />
            <Typography>{title}</Typography>
          </Stack>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => setCollapsed((prev) => !prev)}
          >
            <Spin
              spinning={loading}
              iconProps={{ size: 10, sx: { color: "#fff" } }}
              backdropSx={{ background: "transparent" }}
            >
              <LabelArrowIcon
                sx={{
                  fontSize: 16,
                  color: theme.palette.red[500],
                  transform: !collapsed ? "rotate(180deg)" : undefined,
                }}
              />
            </Spin>
          </IconButton>
        </Stack>
        {logs.map((log) => {
          return (
            <Box key={log.id}>
              <Typography>
                {log.status}: {log.message}:
              </Typography>
              {log.payload?.responseUrl ? (
                <Link href={log.payload.responseUrl} target="_blank">
                  payload
                </Link>
              ) : log.payload ? (
                <LogsCodeButton title="Payload" logs={log.payload} />
              ) : null}
            </Box>
          );
        })}
        {output ? (
          <Box>
            <Typography>Operation Output:</Typography>
            <LogsCodeButton title="Output" logs={output} />
          </Box>
        ) : null}
      </Collapse>
    );
  },
  (prev, next) =>
    prev.title === next.title &&
    prev.logsPath === next.logsPath &&
    prev.outputsPath === next.outputsPath
);

type OperationLogsProps = {
  title: string;
  logsUrl?: string;
  outputsUrl?: string;
};

const OperationLogs: React.FC<OperationLogsProps> = memo(
  (props) => {
    const { title, logsUrl, outputsUrl } = props;

    const theme = useTheme();

    const [collapsed, setCollapsed] = useState(true);
    const [logs, setLogs] = useState<(FusionOperatorLog & { id: string })[]>(
      []
    );
    const [output, setOutput] = useState<unknown>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (logsUrl && !collapsed && !logs.length) {
        setLoading(true);
        axios
          .get(logsUrl)
          .then(
            (res) =>
              isArray(res.data) &&
              setLogs(res.data.map((d) => ({ ...d, id: v4() })))
          )
          .catch(console.error)
          .finally(() => setLoading(false));
      }
    }, [logsUrl, collapsed]);

    useEffect(() => {
      if (outputsUrl && !collapsed && !output) {
        axios
          .get(outputsUrl)
          .then((res) => setOutput(res.data))
          .catch(console.error);
      }
    }, [outputsUrl, collapsed]);

    return (
      <Collapse in={!collapsed} collapsedSize={30} sx={{ mt: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <InfoOutlinedIcon />
            <Typography>{title}</Typography>
          </Stack>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => setCollapsed((prev) => !prev)}
          >
            <Spin
              spinning={loading}
              iconProps={{ size: 10, sx: { color: "#fff" } }}
              backdropSx={{ background: "transparent" }}
            >
              <LabelArrowIcon
                sx={{
                  fontSize: 16,
                  color: theme.palette.red[500],
                  transform: !collapsed ? "rotate(180deg)" : undefined,
                }}
              />
            </Spin>
          </IconButton>
        </Stack>
        {logs.map((log) => {
          return (
            <Box key={log.id}>
              <Typography>
                {log.status}: {log.message}:
              </Typography>
              {log.payload?.responseUrl ? (
                <Link href={log.payload.responseUrl} target="_blank">
                  payload
                </Link>
              ) : log.payload ? (
                <LogsCodeButton title="Payload" logs={log.payload} />
              ) : null}
            </Box>
          );
        })}
        {output ? (
          <Box>
            <Typography>Operation Output:</Typography>
            <LogsCodeButton title="Output" logs={output} />
          </Box>
        ) : null}
      </Collapse>
    );
  },
  (prev, next) =>
    prev.title === next.title &&
    prev.logsUrl === next.logsUrl &&
    prev.outputsUrl === next.outputsUrl
);

const SessionTimelineItem: React.FC<{
  operator?: SessionOperator;
  isLast: boolean;
  operatorOperations: OperatorResponse["operations"];
  operationCounter?: number | null;
  sessionSlug?: string;
  accountSlug?: string;
}> = (props) => {
  const {
    operator,
    isLast,
    operatorOperations = [],
    operationCounter,
    accountSlug,
    sessionSlug,
  } = props;

  const theme = useTheme();

  const { data: apps } = use3pApps();
  const app = apps?.find((app) => {
    return app.slug === operator?.app;
  });
  const color = app?.app_color || theme.palette.primary.main;
  const avatar = operator?.app === "system" ? undefined : app?.app_color_logo;

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            backgroundColor: "#fff",
            // borderColor: "blue",
            m: 0,
          }}
        >
          <Avatar
            src={avatar}
            variant="circular"
            sx={{
              height: 36,
              width: 36,
              backgroundColor: color,
              color: "#fff",
            }}
          >
            {getNameInitial(operator?.operator_title || "")}
          </Avatar>
        </TimelineDot>
        {!isLast && <TimelineConnector sx={{ background: color }} />}
      </TimelineSeparator>
      <TimelineContent sx={{ py: 0, px: 2, mb: 4 }}>
        <Typography variant="h6" component="span">
          {operator?.operator_title}
        </Typography>
        <Typography>{operator?.operator_subtitle}</Typography>
        {operationCounter != null ? (
          <OperationLogsV2
            title={`Operation ${operationCounter + 1}: Success`}
            logsPath={`${accountSlug}/fusion-sessions/${sessionSlug}/${operator?.operator_slug}/${operationCounter}.logs.json`}
            outputsPath={`${accountSlug}/fusion-sessions/${sessionSlug}/${operator?.operator_slug}/${operationCounter}.json`}
          />
        ) : (
          operatorOperations.map((operation, idx) => (
            <OperationLogs
              key={operation.id}
              title={`Operation ${idx + 1}: ${operation.data.status}`}
              logsUrl={operation.data.logs?.url}
              outputsUrl={
                (operation.data.outputs?.responsePath ??
                  operation.data.outputs?.url) as string
              }
            />
          ))
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

type ErrorLogsProps = {
  logs: unknown;
  title: string;
};

const LogsCodeButton: React.FC<ErrorLogsProps> = (props) => {
  const { logs, title } = props;

  const [open, setOpen] = useState(false);
  const [logsData, setLogsData] = useState<unknown>();
  const [loading, setLoading] = useState(false);

  const setLogs = () => {
    return new Promise<void>((resolve, rej) => {
      if (logsData) {
        resolve();
      }

      if (typeof logs === "string") {
        S3Model.getDownloadUrl({
          key: logs,
          bucket: FUSION_SESSIONS_BUCKET,
        })
          .then((response) => {
            const url = response.data.url;

            if (!url) {
              return;
            }

            axios
              .get(url)
              .then((res) => {
                setLogsData(res.data);
                resolve();
              })
              .catch((err) => {
                console.log(err);
                rej();
              });
          })
          .catch((err) => {
            console.log(err);
            rej();
          });
      } else {
        setLogsData(logs);
        resolve();
      }
    });
  };

  const openModal = async () => {
    setLoading(true);
    await setLogs()
      .then(() => {
        setOpen(true);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box>
      <LoadingButton
        variant="contained"
        color="primary"
        size="small"
        onClick={() => openModal()}
        loading={loading}
      >
        {title}
      </LoadingButton>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <CodeEditor
            style={{ maxHeight: "500px" }}
            value={JSON.stringify(logsData, null, 2)}
            readOnly
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const Scroller: Components["Scroller"] = React.forwardRef(
  ({ children, style, ...props }, ref: any) => {
    // an alternative option to assign the ref is
    // <div ref={(r) => ref.current = r}>
    const theme = useTheme();
    return (
      <Scrollbars
        autoHeight
        autoHeightMax="calc(100vh - 420px)"
        ref={(el: any) => {
          if (el?.view) {
            ref.current = el.view;
          }
        }}
        renderView={({ style: viewStyle }) => (
          <div
            {...props}
            style={{
              ...viewStyle,
              ...style,
              overflowX: "hidden",
              marginBottom: 0,
            }}
          ></div>
        )}
        renderThumbVertical={({ style: thumbStyle }) => (
          <div
            style={{
              ...thumbStyle,
              backgroundColor: theme.palette.background.GF40,
              borderRadius: "inherit",
            }}
          ></div>
        )}
        renderThumbHorizontal={({ style: thumbStyle }) => (
          <div
            style={{
              ...thumbStyle,
              display: "none",
            }}
          ></div>
        )}
      >
        {children}
      </Scrollbars>
    );
  }
);

type Props = {
  session?: FusionFlowSession;
  fusionSlug?: string;
} & DrawerProps;

export type SessionRunDrawerRef = {
  pushOperation(operation: {
    operator_slug: string;
    operation_idx: number;
    session_slug: string;
    operator_execution_slug: string;
  }): void;
  completeOperation(operation: {
    operator_slug: string;
    operation_idx: number;
    session_slug: string;
    operator_execution_slug: string;
  }): void;
};

const SessionRunDrawer = forwardRef<SessionRunDrawerRef | undefined, Props>(
  (props, ref) => {
    const { session, fusionSlug, ...drawerProps } = props;

    const theme = useTheme();
    const accountSlug = useAccountSlug();
    const queryClient = useQueryClient();
    const {
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      data: executionFlowData,
    } = useExecutionFlow(session?.slug);

    const [executionFlow, setExecutionFlow] = useState(executionFlowData);

    const duration = moment.duration(
      moment
        .utc(session?.session_data.finish_time)
        .diff(moment.utc(session?.created_at))
    );
    const formattedDuration = moment
      .utc(duration.asMilliseconds())
      .format("HH:mm:ss");

    const creditsUsed = session?.session_data?.total_credits_used ?? 0;
    const status = session?.session_status;
    const title = status === "Running" ? "Running Test" : status;

    useEffect(() => {
      setExecutionFlow(executionFlowData);
    }, [executionFlowData]);

    useImperativeHandle(
      ref,
      () => ({
        pushOperation(operation) {
          queryClient.setQueryData<
            | InfiniteData<
                ApiResponse<
                  { id: string; slug: string; [x: string]: unknown }[]
                >
              >
            | undefined
          >(["execution-flow", operation.session_slug], (old) => {
            if (old && !old.pageParams?.[old.pageParams.length - 1]) {
              const operatorOperation = old.pages[
                old.pages.length - 1
              ].data.find(
                (op) =>
                  op.id ===
                    `${operation.session_slug}:fusion_flow_operator_executions` &&
                  op.slug === operation.operator_execution_slug
              );
              if (!operatorOperation) {
                old.pages[old.pages.length - 1].data.push({
                  id: `${operation.session_slug}:fusion_flow_operator_executions`,
                  slug: operation.operator_execution_slug,
                  operator_slug: operation.operator_slug,
                  operation_index: operation.operation_idx,
                  logs_path: `${S3_CLOUD_FRONT_URL}/${accountSlug}/fusion-sessions/${operation.session_slug}/${operation.operator_slug}/${operation.operation_idx}.logs.json`,
                  response_path: `${S3_CLOUD_FRONT_URL}/${accountSlug}/fusion-sessions/${operation.session_slug}/${operation.operator_slug}/${operation.operation_idx}.json`,
                  start_time: Date.now(),
                  end_time: null,
                  status: "processing",
                });
              }
            }

            setExecutionFlow(cloneDeep(old));

            return cloneDeep(old);
          });
        },
        completeOperation(operation) {
          queryClient.setQueryData<
            | InfiniteData<
                ApiResponse<
                  { id: string; slug: string; [x: string]: unknown }[]
                >
              >
            | undefined
          >(["execution-flow", operation.session_slug], (old) => {
            if (old && !old.pageParams?.[old.pageParams.length - 1]) {
              const operatorOperation = old.pages[
                old.pages.length - 1
              ].data.find(
                (op) =>
                  op.id ===
                    `${operation.session_slug}:fusion_flow_operator_executions` &&
                  op.slug === operation.operator_execution_slug &&
                  op.status === "processing"
              );
              if (operatorOperation) {
                operatorOperation.status = "complete";
                operatorOperation.end_time = Date.now();
                operatorOperation.logs_path = `${S3_CLOUD_FRONT_URL}/${accountSlug}/fusion-sessions/${operation.session_slug}/${operation.operator_slug}/${operation.operation_idx}.logs.json`;
                operatorOperation.response_path = `${S3_CLOUD_FRONT_URL}/${accountSlug}/fusion-sessions/${operation.session_slug}/${operation.operator_slug}/${operation.operation_idx}.json`;
              }
            }

            setExecutionFlow(cloneDeep(old));

            return cloneDeep(old);
          });
        },
      }),
      [accountSlug, queryClient, setExecutionFlow]
    );

    const operatorItems = useMemo(
      () =>
        executionFlow?.pages.reduce<
          { id: string; slug: string; [x: string]: unknown }[]
        >((acc, page) => [...acc, ...page.data], []) ?? [],
      [executionFlow]
    );

    return (
      <Drawer
        sx={{
          width: 0,
          flexShrink: 0,
          zIndex: theme.zIndex.appBar - 1,

          [`& .MuiDrawer-paper`]: {
            zIndex: theme.zIndex.appBar - 1,
            width: 450,
            boxSizing: "border-box",
            boxShadow: "none",
            background: "none",
            backgroundImage: "none",
            backgroundColor: theme.palette.background.GFRightNavBackground,

            ".MuiPaper-root": {
              boxShadow: "none",
              background: "none",
              backgroundImage: "none",
            },
          },
        }}
        anchor="right"
        hideBackdrop
        {...drawerProps}
      >
        <Toolbar />
        <ClickAwayListener
          onClickAway={(e) => drawerProps.onClose?.(e, "backdropClick")}
        >
          <Stack spacing={1} height="100%">
            <RunFusion
              title={title}
              progress={status === SessionStatus.Complete ? 100 : 60}
              status={status}
              onPause={() => {
                FusionModel.updateSession(fusionSlug!, session?.slug!, {
                  is_paused: true,
                  is_stopped: false,
                });
              }}
              onStop={() => {
                FusionModel.updateSession(fusionSlug!, session?.slug!, {
                  is_stopped: true,
                  is_paused: false,
                });
              }}
              onPlay={() => {
                FusionModel.updateSession(fusionSlug!, session?.slug!, {
                  is_paused: false,
                  is_stopped: false,
                });
              }}
            />
            <Box height="100%">
              <Scrollbar>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        sx={{
                          background:
                            theme.palette.background.GFRightNavForeground,
                        }}
                      >
                        <AccessTimeIcon sx={{ color: "#fff", opacity: 0.6 }} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Stack direction="row" spacing={2}>
                          <Typography
                            variant="body2"
                            sx={{
                              color: theme.palette.text.secondary,
                              opacity: 0.7,
                            }}
                          >
                            Total run time:
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: theme.palette.text.primary }}
                          >
                            {formattedDuration}
                          </Typography>
                        </Stack>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        sx={{
                          background:
                            theme.palette.background.GFRightNavForeground,
                        }}
                      >
                        <TollIcon sx={{ color: "#fff", opacity: 0.6 }} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Stack direction="row" spacing={2}>
                          <Typography
                            variant="body2"
                            sx={{
                              color: theme.palette.text.secondary,
                              opacity: 0.7,
                            }}
                          >
                            Fuse credits used:
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: theme.palette.text.primary }}
                          >
                            {`${creditsUsed}`.padStart(2, "0")}
                          </Typography>
                        </Stack>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        sx={{
                          background:
                            theme.palette.background.GFRightNavForeground,
                        }}
                      >
                        <CalendarTodayIcon
                          sx={{ color: "#fff", opacity: 0.6 }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Stack direction="row" spacing={2}>
                          <Typography
                            variant="body2"
                            sx={{
                              color: theme.palette.text.secondary,
                              opacity: 0.7,
                            }}
                          >
                            Last updated:
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: theme.palette.text.primary }}
                          >
                            {moment(session?.updated_at).format("ll")}
                          </Typography>
                        </Stack>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        sx={{
                          background: theme.palette.red[500],
                        }}
                      >
                        <InfoOutlinedIcon sx={{ color: "#fff" }} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Stack direction="row" spacing={2}>
                          <Typography
                            variant="body2"
                            sx={{
                              color: theme.palette.text.secondary,
                              opacity: 0.7,
                            }}
                          >
                            Errors:
                          </Typography>
                          {/* <Typography
                            variant="subtitle2"
                            sx={{ color: theme.palette.text.primary }}
                          >
                            {session?.session_data.error_logs &&
                            session?.session_data.error_logs?.length > 0
                              ? 1
                              : 0}
                          </Typography> */}
                          {session?.session_data.error_logs && (
                            <LogsCodeButton
                              logs={session?.session_data.error_logs}
                              title="Error Logs"
                            />
                          )}
                        </Stack>
                      }
                    />
                  </ListItem>
                </List>
                <Box sx={{ px: 2 }}>
                  <SidebarSection title="Todays activity" rightIcon={false}>
                    <Timeline
                      sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                    >
                      {operatorItems.map((flowOp, index) => {
                        const op = (
                          session as FusionFlowSession
                        )?.session_operators?.find(
                          (o) => o.operator_slug === flowOp.operator_slug
                        ) as SessionOperator;
                        return (
                          <SessionTimelineItem
                            key={flowOp.slug}
                            operator={op}
                            isLast={operatorItems.length - 1 === index}
                            operatorOperations={[]}
                            operationCounter={flowOp.operation_index as number}
                            sessionSlug={session?.slug}
                            accountSlug={accountSlug}
                          />
                        );
                      })}
                      {/* <Virtuoso
                        style={{ height: "calc(100vh - 420px)" }}
                        data={operatorItems}
                        overscan={50}
                        itemContent={(index, flowOp) => {
                          const op = (
                            session as FusionFlowSession
                          ).session_operators.find(
                            (o) => o.operator_slug === flowOp.operator_slug
                          ) as SessionOperator;
                          return (
                            <SessionTimelineItem
                              key={flowOp.slug}
                              operator={op}
                              isLast={operatorItems.length - 1 === index}
                              operatorOperations={[]}
                              operationCounter={
                                flowOp.operation_index as number
                              }
                              sessionSlug={session?.slug}
                              accountSlug={accountSlug}
                            />
                          );
                        }}
                        components={{
                          Footer: () => (
                            <LoadingButton
                              onClick={() => fetchNextPage()}
                              disabled={!hasNextPage}
                              loading={isFetchingNextPage}
                              variant="outlined"
                              sx={{ my: 2 }}
                              fullWidth
                              size="small"
                            >
                              Load More
                            </LoadingButton>
                          ),
                          Scroller,
                        }}
                      /> */}
                    </Timeline>
                  </SidebarSection>
                </Box>
              </Scrollbar>
            </Box>
          </Stack>
        </ClickAwayListener>
      </Drawer>
    );
  }
);

export default SessionRunDrawer;
