import AddBoxOutlined from "@mui/icons-material/AddBoxOutlined";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { IconButton, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { DefaultCellIcon } from "components/DataTable";
import FormField from "components/FormField";
import IconPickerField from "components/IconPicker";
import ListWidget from "components/ListWidget";
import { DASHBOARD_WIDGET_TYPE } from "constants/gui";
import { AllIconPickerIcons } from "constants/index";
import { DocumentElementType } from "enums/Form";
import useOpenClose from "hooks/useOpenClose";
import capitalize from "lodash/capitalize";
import { ApiModels } from "queries/apiModelMapping";
import useActionFields from "queries/formDesign/useActionFields";
import useDeleteItem from "queries/useDeleteItem";
import useListItems from "queries/useListItems";
import { FC, useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useGuiDashboardV2Store } from "store/stores/gui-dashboard-v2";
import DataFieldDrawer from "views/app-view/gui-dashboard-v2/components/WidgetEditorDrawer/DataFieldDrawer";
import { WidgetFormType } from "./TabContent";

const DataFieldsSelector: FC = () => {
  const form = useFormContext<WidgetFormType>();

  const queryClient = useQueryClient();

  const widget = useGuiDashboardV2Store.useWidget();

  const { data: fields } = useActionFields(widget?.slug);

  const { data: datasetDesigns } = useListItems({
    modelName: ApiModels.DatasetDesign,
  });

  const { mutateAsync: deleteFormDesign } = useDeleteItem({
    modelName: ApiModels.FormDesign,
  });

  const [isOpen, open, close] = useOpenClose();

  const [selectedField, setSelectedField] =
    useState<DataField & { db_slug: string }>();

  const designOptions = useMemo(
    () =>
      datasetDesigns
        ?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        .map((design) => ({
          label: design.name,
          value: design.slug,
        })) ?? [],
    [datasetDesigns]
  );

  const handleDeleteField = useCallback(
    async (slug: string) => {
      await deleteFormDesign({ slug });

      queryClient.refetchQueries({
        queryKey: [ApiModels.FormDesign, widget?.slug],
      });
    },
    [widget?.slug, deleteFormDesign, queryClient]
  );

  return (
    <>
      <FormField
        label="Select Widget Dataset"
        type={DocumentElementType.Select}
        fieldExtras={{
          field: {
            list_source: "hardcoded",
            list_items: designOptions,
          },
        }}
        collapsible
        formControl={{
          control: form.control,
          name: "widget_data.base_dataset_slug",
        }}
      />
      {widget && (
        <FormField
          label="Fields to include"
          extra={
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                setSelectedField(undefined);
                open();
              }}
            >
              <AddBoxOutlined fontSize="small" />
            </IconButton>
          }
          collapsible
        >
          <ListWidget
            data={
              fields?.map((field) => ({
                id: field.slug,
                label: field.title,
                icon: (
                  <DefaultCellIcon
                    type={field.type as DocumentElementType}
                    size="small"
                  />
                ),
              })) ?? []
            }
            itemActions={(_, index) => (
              <Stack direction="row" alignItems="center" gap={1}>
                <IconButton
                  sx={{ p: 0 }}
                  onClick={() => {
                    const fieldSlug = fields?.[index].slug;
                    if (fieldSlug) {
                      handleDeleteField(fieldSlug);
                    }
                  }}
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
                <IconButton
                  sx={{ p: 0 }}
                  onClick={() => {
                    const field = fields?.[index];
                    if (!field) {
                      return;
                    }

                    setSelectedField({
                      ...field,
                      slug: field.field_slug,
                      db_slug: field.slug,
                      ...field.metadata,
                    });
                    open();
                  }}
                >
                  <EditOutlined fontSize="small" />
                </IconButton>
              </Stack>
            )}
          />
        </FormField>
      )}
      <DataFieldDrawer
        field={selectedField}
        setSelectedField={(data) => setSelectedField(data as any)}
        open={isOpen}
        onClose={() => {
          close();
          setSelectedField(undefined);
        }}
      />
    </>
  );
};

const TypeBasedFields: FC = () => {
  const form = useFormContext<WidgetFormType>();

  const type = form.watch("type");

  switch (type) {
    case DASHBOARD_WIDGET_TYPE.STAT:
      return (
        <FormField
          label="Widget Icon"
          collapsible
          formControl={{
            control: form.control,
            name: "widget_data.icon",
          }}
        >
          {({ value, onChange }) => (
            <IconPickerField
              icons={AllIconPickerIcons}
              value={value as string}
              onChange={(iconSlug) => onChange?.(iconSlug)}
              placement="left"
              textFieldProps={{
                fullWidth: true,
                variant: "filled",
                size: "small",
                hiddenLabel: true,
              }}
            />
          )}
        </FormField>
      );

    case DASHBOARD_WIDGET_TYPE.DATA_LIST:
      return <DataFieldsSelector />;

    default:
      return <></>;
  }
};

type EditTabProps = {};

const EditTab: FC<EditTabProps> = (props) => {
  const form = useFormContext<WidgetFormType>();

  return (
    <Stack direction="column" gap={2}>
      <FormField
        label="Select Widget Type"
        type={DocumentElementType.Select}
        fieldExtras={{
          field: {
            list_source: "hardcoded",
            list_items: Object.values(DASHBOARD_WIDGET_TYPE).map((value) => ({
              label: value
                .split("-")
                .map((part) => capitalize(part))
                .join(" "),
              value,
            })),
            default_value: DASHBOARD_WIDGET_TYPE.STAT,
          },
        }}
        collapsible
        formControl={{
          control: form.control,
          name: "type",
        }}
      />
      <FormField
        label="Widget Title"
        type={DocumentElementType.TextField}
        collapsible
        formControl={{
          control: form.control,
          name: "title",
        }}
      />
      <TypeBasedFields />
    </Stack>
  );
};

export default EditTab;
