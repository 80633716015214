import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { Box, IconButton, Stack } from "@mui/material";
import { IDatasource } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionMenu from "components/ActionMenu";
import { DatasetTableWithFields } from "components/DatasetTable";
import { DocumentElementType } from "enums/Form";
import useOpenClose from "hooks/useOpenClose";
import AgentSkillsModel from "models/AgentSkill";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import useDeleteItem from "queries/useDeleteItem";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import AddAgentSkillModal from "views/app-view/agent/TabContent/AgentSkills/AddAgentSkillModal";
import AgentSkillEditDrawer from "./AgentSkillEditDrawer";

type AgentSkillsProps = {
  agent: Agent;
};

const AgentSkills: FC<AgentSkillsProps> = (props) => {
  const { agent } = props;

  const [isSkillModalOpen, openSkillModal, closeSkillModal] = useOpenClose();
  const [isSkillEditorOpen, openSkillEditor, closeSkillEditor] = useOpenClose();

  const cursor = useRef<string>();
  const gridRef = useRef<AgGridReact>(null);

  const [selectedSkill, setSelectedSkill] = useState<AgentSkill>();

  const { mutateAsync: deleteAgentSkill } = useDeleteItem({
    modelName: ApiModels.AgentSkill,
  });

  const { mutateAsync: createAgentSkillAssc } = useCreateItem({
    modelName: ApiModels.AgentSkillAssc,
  });

  const { mutateAsync: deleteAgentSkillAssc } = useDeleteItem({
    modelName: ApiModels.AgentSkillAssc,
  });

  const getDataSource = useCallback(
    (): IDatasource => ({
      async getRows(params) {
        console.log(params);
        const response = await AgentSkillsModel.list({
          query: { agent_slug: agent.slug, cursor: cursor.current },
        });
        cursor.current = (response.cursor as Record<string, string>)?.slug;
        params.successCallback(
          (response.data ?? []).map((d) => ({
            ...d,
            icon: d.meta_data?.icon,
            agent_has_access: d.association ? 1 : 0,
          })),
          !response.cursor ? params.startRow + response.data.length : undefined
        );
      },
    }),
    [agent.slug]
  );

  const datasource = useMemo<IDatasource>(() => {
    return getDataSource();
  }, [getDataSource]);

  const fields = useMemo<DataField[]>(
    () => [
      {
        id: v4(),
        slug: "icon",
        title: "Skill Icon",
        type: "icon",
      },
      {
        id: v4(),
        slug: "title",
        title: "Skill Title",
        type: DocumentElementType.TextField,
      },
      {
        id: v4(),
        slug: "description",
        title: "Skill Description",
        type: DocumentElementType.TextArea,
      },
      {
        id: v4(),
        slug: "agent_has_access",
        title: "Is Active",
        editable: true,
        type: DocumentElementType.Boolean,
      },
    ],
    []
  );

  const handleDelete = async (slug: string) => {
    await deleteAgentSkill({ slug });
    refreshGrid();
  };

  const refreshGrid = () => {
    gridRef.current?.api.paginationGoToFirstPage();
    cursor.current = undefined;
    gridRef.current?.api.refreshInfiniteCache();
  };

  return (
    <Box sx={{ height: "400px" }}>
      <DatasetTableWithFields
        ref={gridRef}
        fields={fields}
        datasource={datasource}
        handleUpdate={(event) => {
          console.log(event);
          if (event.colDef.field === "agent_has_access" && event.valueChanged) {
            const agentSlug = agent.slug;
            const skillSlug = event.data.slug;
            if (event.newValue) {
              createAgentSkillAssc({
                agent_slug: agentSlug,
                skill_slug: skillSlug,
              });
            } else {
              deleteAgentSkillAssc({ slug: `${agentSlug}:${skillSlug}` });
            }
          }
        }}
        editable={false}
        defaultTitleColumn={false}
        suppressMovableColumns
        paginationAutoPageSize={false}
        paginationPageSizeSelector={false}
        paginationPageSize={10}
        getRowStyle={(params) => {
          return params.data ? { opacity: 1 } : { opacity: 0 };
        }}
        blockLoadDebounceMillis={500}
        autoSizeStrategy={{
          type: "fitGridWidth",
        }}
        actionsColumn={{
          enabled: true,
          getHeader: () => (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <IconButton onClick={openSkillModal}>
                <AddCircleOutline />
              </IconButton>
            </Stack>
          ),
          getRowAction(data) {
            return (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <ActionMenu
                  menuItems={[
                    {
                      label: "Edit",
                      value: "edit",
                      icon: <EditOutlined />,
                    },
                    {
                      label: "Delete",
                      value: "delete",
                      icon: <DeleteOutline />,
                    },
                  ]}
                  containerSx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onItemClick={(value) => {
                    if (value === "delete") {
                      handleDelete(data.slug as string);
                    } else if (value === "edit") {
                      setSelectedSkill(data as AgentSkill);
                      openSkillEditor();
                    }
                  }}
                >
                  <MoreHoriz />
                </ActionMenu>
              </Stack>
            );
          },
        }}
      />
      <AddAgentSkillModal
        open={isSkillModalOpen}
        onClose={closeSkillModal}
        agent={agent}
        onCreated={() => {
          refreshGrid();
        }}
      />
      <AgentSkillEditDrawer
        open={isSkillEditorOpen}
        onClose={() => {
          closeSkillEditor();
          setSelectedSkill(undefined);
        }}
        skill={selectedSkill}
        onSubmitDone={() => {
          refreshGrid();
        }}
      />
    </Box>
  );
};

export default AgentSkills;
