import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ApiModels } from "queries/apiModelMapping";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import useSSAgent from "store/stores/ss-agent";
import ParamEditor from "views/app-view/ss-agent/EditDrawer/AgentSkillSideBar/ParamEditor";
import ParamsList from "views/app-view/ss-agent/EditDrawer/AgentSkillSideBar/ParamList";

type AgentSkillParamsProps = {
  agentSkill?: AgentSkill;
};

const AgentSkillParams: FC<AgentSkillParamsProps> = (props) => {
  const { agentSkill } = props;

  const { agentSlug } = useParams<{ agentSlug: string }>();

  const queryClient = useQueryClient();

  const setEditDrawer = useSSAgent.useSetEditDrawer();

  const [selectedParam, setSelectedParam] = useState<AgentSkillParameter>();
  const [view, setView] = useState<"list" | "edit" | "add">("list");

  if (!agentSkill) {
    return null;
  }

  return (
    <Box mt={2}>
      {view === "edit" || view === "add" ? (
        <ParamEditor
          param={selectedParam}
          skill={agentSkill}
          onClose={() => {
            setView("list");
            setSelectedParam(undefined);
          }}
          updateParamCallback={(params) => {
            setEditDrawer({
              enabled: true,
              type: "skill",
              skill: {
                ...agentSkill,
                parameters: params,
              },
            });

            queryClient.invalidateQueries({
              queryKey: [ApiModels.SSAgentSkill, agentSlug],
            });
          }}
        />
      ) : (
        <ParamsList
          skill={agentSkill}
          onAddClick={() => {
            setView("add");
            setSelectedParam(undefined);
          }}
          onEditClick={(param) => {
            setView("edit");
            setSelectedParam(param);
          }}
        />
      )}
    </Box>
  );
};

export default AgentSkillParams;
