import EditNoteOutlined from "@mui/icons-material/EditNoteOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, styled } from "@mui/material";
import Scrollbar from "components/Scrollbar";
import useCreateThread from "queries/copilot/useCreateThread";
import useThreads from "queries/copilot/useThreads";
import { FC, useMemo, useState } from "react";
import ChatSessionItem from "./ChatSessionItem";

const ChatSessionsContainer = styled(Box)({
  height: "100%",
  color: "#000000",

  ".thumb-vertical": {
    background: "#00619A66 !important",
  },
});

const NewChatButton = styled(LoadingButton)({
  height: "35px",
  background: "#F1F1F1",
  color: "#00619A",

  ".MuiLoadingButton-loadingIndicator": {
    color: "#00619A",
  },
});

const ScrollbarContainer = styled(Box)({
  height: "calc(100% - 35px)",
});

type ChatSessionsProps = {
  onChatClick(slug: string): void;
};

const ChatSessions: FC<ChatSessionsProps> = (props) => {
  const { onChatClick } = props;

  const { data, refetch } = useThreads();
  const { mutateAsync: createThread } = useCreateThread();

  const [loading, setLoading] = useState(false);

  const threads = useMemo(
    () =>
      data?.pages.reduce<CopilotThread[]>((acc, cur) => {
        acc.push(...cur.data);

        return acc;
      }, []) ?? [],
    [data]
  );

  const handleNewChatClick = async () => {
    setLoading(true);
    const thread = await createThread({}).catch(() => {});
    refetch();
    if (thread) {
      onChatClick(thread.slug);
    }

    setLoading(false);
  };

  return (
    <ChatSessionsContainer>
      <NewChatButton
        onClick={handleNewChatClick}
        startIcon={<EditNoteOutlined />}
        fullWidth
        variant="text"
        loading={loading}
      >
        New Chat
      </NewChatButton>
      <ScrollbarContainer>
        <Scrollbar autoHide={false}>
          {threads.map((thread) => (
            <ChatSessionItem
              key={thread.slug}
              data={thread}
              onClick={() => onChatClick(thread.slug)}
            />
          ))}
        </Scrollbar>
      </ScrollbarContainer>
    </ChatSessionsContainer>
  );
};

export default ChatSessions;
