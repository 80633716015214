import { CustomCellEditorProps } from "ag-grid-react";
import AccountUserDataProvider from "components/DataProviders/AccountUserDataProvider";
import UserSelector from "components/Form/UserSelector";
import { FC } from "react";

type UserCellEditorProps = {
  field: DataField;
} & CustomCellEditorProps;

const UserCellEditor: FC<UserCellEditorProps> = (props) => {
  const { field, value, onValueChange } = props;

  return (
    <AccountUserDataProvider>
      <UserSelector
        actionButtons={false}
        multi={field?.multi_user ?? false}
        value={value}
        onChange={onValueChange}
      />
    </AccountUserDataProvider>
  );
};

export default UserCellEditor;
