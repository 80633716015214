import DatasetDataProvider from "components/DataProviders/DatasetDataProvider";
import BaseCheckboxList from "components/Form/BaseCheckboxList";
import { ListSource } from "enums/Form";
import { FC } from "react";

type CheckboxFieldProps = {
  field: DataField;
  value?: string[];
  onChange?(value: string[]): void;
};

const CheckboxList: FC<CheckboxFieldProps & { options?: LabeledValue[] }> = (
  props
) => {
  const { options, value, onChange } = props;
  return (
    <BaseCheckboxList
      compact={true}
      options={options ?? []}
      value={value ?? []}
      onChange={(selected) => {
        console.log(selected);
        onChange?.(selected);
      }}
    />
  );
};

const CheckboxField: FC<CheckboxFieldProps> = (props) => {
  const {
    list_source: type,
    associated_document: datasetDesignSlug,
    associated_document_label_field: labelField,
  } = props.field;
  if (type === ListSource.RecordAssociation) {
    <DatasetDataProvider
      datasetDesignSlug={datasetDesignSlug!}
      fetchSelectedValueInList={true}
      keyBinding={{
        label: labelField,
        value: "id",
      }}
    >
      <CheckboxList {...props} />
    </DatasetDataProvider>;
  }

  return (
    <CheckboxList
      {...props}
      options={props.field.list_items as LabeledValue[]}
    />
  );
};

export default CheckboxField;
