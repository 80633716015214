import { useMutation } from "@tanstack/react-query";
import Copilot from "models/Copilot";

const useCreateThread = () => {
  return useMutation({
    mutationKey: ["copilot", "threads", "post"],
    mutationFn: async (data: Partial<CopilotThread>) => {
      const response = await Copilot.createThread(data);
      return response.data;
    },
  });
};

export default useCreateThread;
