import { Direction } from "@mui/material/styles";

import type {} from "@mui/x-date-pickers/themeAugmentation";
import useGetAppConfig from "queries/useGetAppConfig";
import { useEffect, useMemo } from "react";
import useSystemLayoutStore from "store";
import componentsOverride from "theme/overrides";
import getThemeMode from "utils/getColorPresets";

type Props = {
  themeMode?: ThemeModes;
  themeDirection: "ltr" | "rtl";
  navbarHeight?: number;
};

const useThemeToggle = (props: Props) => {
  const { themeMode = "light", themeDirection = "ltr", navbarHeight } = props;
  const { data } = useGetAppConfig({ enabled: false });
  const setActiveTheme = useSystemLayoutStore.useSetActiveTheme();
  // const routeModule = useRouteModule();

  useEffect(() => {
    setActiveTheme(themeMode);
  }, [setActiveTheme, themeMode]);

  const theme = useMemo(() => {
    const theme = getThemeMode(themeMode, {
      defaultColor: data?.primary_color,
    });

    const newTheme = {
      ...theme.theme,

      direction: themeDirection as Direction,
      navStyle: data?.nav_style || "top",
      topNavHeight: 48,
      sideNavWidth: 48,
    };
    newTheme.components = componentsOverride(newTheme);
    return newTheme;
  }, [themeMode, data?.primary_color, data?.nav_style, themeDirection]);
  return theme;
};

export default useThemeToggle;
