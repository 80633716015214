import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createTagNode, TagNode } from "components/LexicalTagField/TagNode";
import { MappableTagType } from "enums/Fusion";
import {
  $insertNodes,
  COMMAND_PRIORITY_EDITOR,
  LexicalCommand,
  createCommand,
} from "lexical";
import { useEffect } from "react";
import { v4 } from "uuid";

export type InsertTagPayload =
  | { type: MappableTagType.Function; data: GFMLFunction }
  | {
      type: MappableTagType.Variable;
      data: MappableParameter;
    };

export const INSERT_TAG_COMMAND: LexicalCommand<InsertTagPayload> =
  createCommand("INSERT_TAG_COMMAND");

const getTagType = (type: MappableTagType) => {
  switch (type) {
    case MappableTagType.Function:
      return "fn";

    case MappableTagType.Variable:
      return "var";

    case MappableTagType.Separator:
      return "fn_sep";

    case MappableTagType.ClosingBracket:
      return "fn_close";
  }
};

export default function AddTagPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([TagNode])) {
      throw new Error("AddTagPlugin: TagNode not registered on editor");
    }

    return editor.registerCommand(
      INSERT_TAG_COMMAND,
      (payload) => {
        const { type, data } = payload;
        if (type === MappableTagType.Variable) {
          if ((data.tagLabel as string)?.includes("[]")) {
            const tagSplits = (data.tagLabel as string).split("[]");
            const tagSlugSplits = (data.slug as string).split("[]");
            const variableNodes: TagNode[] = [];
            tagSplits.forEach((tag, index) => {
              let label = "";
              let slug = "";
              const slugSplit = tagSlugSplits[index];
              if (index === 0) {
                label = `${tag}[`;
                slug = `${slugSplit}[`;
              } else if (index === tagSplits.length - 1) {
                label = `]${tag}`;
                slug = `]${slugSplit}`;
              } else {
                label = `]${tag}[`;
                slug = `]${slugSplit}[`;
              }
              variableNodes.push(
                $createTagNode(v4(), {
                  type: getTagType(type),
                  label,
                  slug,
                })
              );
            });
            $insertNodes(variableNodes);
          } else {
            const tagNode = $createTagNode(v4(), {
              type: getTagType(type),
              label: (data.tagLabel as string) || data.label!,
              slug: data.slug,
            });
            $insertNodes([tagNode]);
          }
        } else if (type === MappableTagType.Function) {
          const fnNode = $createTagNode(v4(), {
            type: getTagType(type),
            label: `${data.function_title}(`,
            slug: data.function_slug,
          });

          const fnCloseNode = $createTagNode(v4(), {
            type: getTagType(MappableTagType.ClosingBracket),
            label: ")",
            slug: data.function_slug,
          });
          $insertNodes([fnNode, fnCloseNode]);
        }
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
