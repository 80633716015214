import styled from "@emotion/styled";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack } from "@mui/material";
import FormField from "components/FormField";
import FusionLinkButton from "components/FusionLinkButton";
import IconPickerField from "components/IconPicker";
import { AllIconPickerIcons } from "constants/index";
import { DocumentElementType } from "enums";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ParamsList from "views/app-view/agent/TabContent/AgentSkills/AgentSkillEditDrawer/ParamList";
import { z } from "zod";

const Container = styled(Box)({
  padding: "16px",
});

const formSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  instruction: z.string().optional(),
  icon: z.string().optional(),
  tag: z.string().optional(),
  is_active: z.number().optional(),
});

type EditAgentSkillFormType = z.infer<typeof formSchema>;

type SkillFormProps = {
  skill: AgentSkill;
  onClose(): void;
  onSubmitDone(): void;
  onAddParam(): void;
  onEditParam(param: AgentSkillParameter): void;
};

const SkillForm: FC<SkillFormProps> = (props) => {
  const { skill, onClose, onSubmitDone, onAddParam, onEditParam } = props;

  const form = useForm<EditAgentSkillFormType>({
    defaultValues: skill,
    resolver: zodResolver(formSchema),
  });

  const { control, handleSubmit, setValue } = form;

  const { mutateAsync: updateAgentSkill, isLoading } = useUpdateItem({
    modelName: ApiModels.AgentSkill,
  });

  useEffect(() => {
    if (skill) {
      setValue("title", skill.title);
      setValue("description", skill.description);
      setValue("instruction", skill.instruction);
      setValue("icon", skill.meta_data?.icon as string | undefined);
      setValue("is_active", skill.is_active);
      setValue("tag", skill.tag);
    }
  }, [setValue, skill]);

  const onSubmit = async (values: EditAgentSkillFormType) => {
    if (skill) {
      const { icon, ...data } = values;
      await updateAgentSkill({
        slug: skill.slug,
        data: { ...data, meta_data: { ...(skill?.meta_data ?? {}), icon } },
      });
      onClose();
      onSubmitDone();
    }
  };

  return (
    <Container>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" gap={2}>
            <FormField
              label="Skill Name"
              type={DocumentElementType.TextField}
              formControl={{ control, name: "title" }}
            />
            <FormField
              label="Skill Slug"
              type={DocumentElementType.TextField}
              disabled
              value={skill?.agent_skill_slug}
              fieldExtras={{ disabled: true, field: {} }}
            />
            <FormField
              label="Skill Description"
              type={DocumentElementType.TextArea}
              formControl={{ control, name: "description" }}
            />
            <FormField
              label="Skill Instruction"
              type={DocumentElementType.TextArea}
              formControl={{ control, name: "instruction" }}
            />
            <FormField
              label="Skill Tag"
              type={DocumentElementType.TextField}
              formControl={{ control, name: "tag" }}
            />
            <FormField
              label="Skill Icon"
              formControl={{ control, name: "icon" }}
            >
              {({ value, onChange }) => (
                <IconPickerField
                  icons={AllIconPickerIcons}
                  value={value as string}
                  onChange={onChange}
                  textFieldProps={{ fullWidth: true, variant: "filled" }}
                />
              )}
            </FormField>
            {/* <FormField
              label="Is Active"
              type={DocumentElementType.Boolean}
              formControl={{ control, name: "is_active" }}
            /> */}
            {skill?.fusion && (
              <FusionLinkButton
                title="Skill Fusion"
                fusionSlug={skill.fusion}
              />
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1.5}
            sx={{ mt: 2 }}
          >
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                border: "none",
                color: "#fff",
                background: (theme) => theme.palette.background.GF10,
              }}
              onClick={(e) => onClose()}
              fullWidth
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                borderColor: "#fff",
                boxShadow: "none",
              }}
              fullWidth
              loading={isLoading}
            >
              Save Changes
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
      {skill && (
        <Box sx={{ my: 3 }}>
          <ParamsList
            onAddClick={onAddParam}
            onEditClick={onEditParam}
            skill={skill}
            onSubmitDone={onSubmitDone}
          />
        </Box>
      )}
    </Container>
  );
};

export default SkillForm;
