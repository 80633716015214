import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { CustomTooltipProps } from "ag-grid-react";
import AccountUserDataProvider from "components/DataProviders/AccountUserDataProvider";
import isArray from "lodash/isArray";
import { FC, ReactNode } from "react";

type DefaultListCellTooltipProps = {
  field: DataField;
  getListData(
    item: unknown,
    options: { users: User[] }
  ): { icon?: ReactNode; text?: ReactNode };
  defaultIcon?: ReactNode;
} & CustomTooltipProps;

const DefaultListCellTooltip: FC<DefaultListCellTooltipProps> = (props) => {
  const value: unknown[] = isArray(props.value) ? props.value : props.value;

  return (
    <AccountUserDataProvider>
      {(users = []) => (
        <Box
          sx={{
            maxHeight: "300px",
            minWidth: "100px",
            maxWidth: "260px",
            background: "#29292e",
            borderRadius: "4px",
            overflow: "auto",
          }}
        >
          <List sx={{ width: "100%" }}>
            {value.filter(Boolean).map((item) => {
              const data = props.getListData(item, { users });
              const icon = data.icon ?? props.defaultIcon;

              return (
                <ListItem sx={{ paddingY: "4px" }}>
                  {icon && <ListItemIcon>{icon}</ListItemIcon>}
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      },
                    }}
                  >
                    {data.text}
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </AccountUserDataProvider>
  );
};

export default DefaultListCellTooltip;
