import { Box } from "@mui/material";
import FusionLinkButton from "components/FusionLinkButton";
import { FC } from "react";

type AgentSkillFusionProps = {
  agentSkill?: AgentSkill;
};

const AgentSkillFusion: FC<AgentSkillFusionProps> = (props) => {
  const { agentSkill } = props;

  return (
    <Box mt={2}>
      {agentSkill?.fusion && (
        <FusionLinkButton title="Skill Fusion" fusionSlug={agentSkill.fusion} />
      )}
    </Box>
  );
};

export default AgentSkillFusion;
