import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import {
  Box,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { TopBarIconButton } from "components/IconButtonWithTooltip";
import { DocumentElementType } from "enums/Form";
import { GuiType } from "enums/gui";
import get from "lodash/get";
import { ApiModels } from "queries/apiModelMapping";
import { useGuiTabs } from "queries/gui/useGuiTabs";
import { useGuiParams } from "queries/guiParams/useGuiParams";
import useGetItem from "queries/useGetItem";
import { FC, useMemo } from "react";
import { Controller, UseFormReturn, useFieldArray } from "react-hook-form";
import { useParams } from "react-router-dom";
import useSystemLayoutStore from "store";
import FlowFieldWrapper from "views/app-view/flow-designer/components/FlowFieldWrapper";
import DynamicRHSField from "views/app-view/gui-dataset/components/ConditionSets/DynamicRHSField";
import TagsPopper from "views/app-view/gui-dataset/components/ConditionSets/TagsPopper";

const FieldWrapper = styled(FlowFieldWrapper)(({ theme }) => ({
  marginBottom: 0,
}));

const IconWrapper = styled(TopBarIconButton)({
  height: "18px",
  width: "18px",
  background: "#48494D",
  borderRadius: "3px",

  ".MuiSvgIcon-root": {
    fontSize: "16px",
  },
});

const operators = [
  {
    label: "Equal (=)",
    value: "=",
  },
  {
    label: "Not Equal (<>)",
    value: "<>",
  },
  {
    label: "Greater Than (>)",
    value: ">",
  },
  {
    label: "Less Than (<)",
    value: "<",
  },
  {
    label: "Greater Than or Equal (>=)",
    value: ">=",
  },
  {
    label: "Less Than or Equal (<=)",
    value: "<=",
  },
];

const defaultFields: DataField[] = [
  {
    id: "id",
    slug: "id",
    title: "id",
    type: DocumentElementType.TextField,
  },
];

type ConditionFieldsProps = {
  conditionIdx: number;
  name: string;
  fieldList: DataField[];
  dynamicRHS?: boolean;
} & Partial<UseFormReturn>;

const ConditionFields: FC<ConditionFieldsProps> = (props) => {
  const { conditionIdx, name, control, fieldList, dynamicRHS } = props;
  return (
    <Box
      sx={{
        pr: 3,
        pl: 2,
        py: 1,
        borderLeft: "1px solid #6DAAF8",
        ml: 1,
      }}
    >
      <FlowFieldWrapper label="LHS">
        <Controller
          name={`${name}[${conditionIdx}].a`}
          control={control}
          render={({ field }) => {
            return (
              <Select
                displayEmpty
                id={name}
                fullWidth
                size="small"
                sx={{ ".MuiPaper-root": { maxHeight: "300px" } }}
                value={field.value || ""}
                onChange={field.onChange}
              >
                {fieldList.map((field) => (
                  <MenuItem value={field.slug}>{field.title}</MenuItem>
                ))}
              </Select>
            );
          }}
        />
      </FlowFieldWrapper>
      <FlowFieldWrapper label="Condition">
        <Controller
          name={`${name}[${conditionIdx}].o`}
          control={control}
          render={({ field }) => {
            return (
              <Select
                displayEmpty
                id={name}
                fullWidth
                size="small"
                sx={{ ".MuiPaper-root": { maxHeight: "300px" } }}
                value={field.value || ""}
                onChange={field.onChange}
              >
                {operators.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            );
          }}
        />
      </FlowFieldWrapper>
      <FlowFieldWrapper label="RHS">
        <Controller
          name={`${name}[${conditionIdx}].b`}
          control={control}
          render={({ field }) => {
            return dynamicRHS ? (
              <DynamicRHSField
                name={field.name}
                defaultValue={field.value}
                onChange={field.onChange}
              />
            ) : (
              <TextField
                id={name}
                fullWidth
                variant="filled"
                size="small"
                value={field.value || ""}
                onChange={field.onChange}
              />
            );
          }}
        />
      </FlowFieldWrapper>
    </Box>
  );
};

export type BaseParamFieldProps = {
  parentNamePath?: string;
} & Partial<UseFormReturn>;

type ConditionSetProps = {
  conditionsLength: number;
  indexKey?: string;
  path?: string[];
  dynamicRHS?: boolean;
  index: number;
  onRemoveSet(): void;
  onAddSet(): void;
} & BaseParamFieldProps;

const ConditionSets: FC<ConditionSetProps> = (props) => {
  const {
    index,
    onRemoveSet,
    onAddSet,
    parentNamePath,
    indexKey,
    path,
    dynamicRHS,
    control,
    conditionsLength,
    ...form
  } = props;

  const { slug: guiSlug } =
    useParams<{
      slug: string;
    }>();

  const { data: gui } = useGetItem({
    modelName: ApiModels.Gui,
    slug: guiSlug,
    queryOptions: { enabled: !!guiSlug },
  });
  const { data: guiTabs = [] } = useGuiTabs(gui?.slug);
  const { data: guiParams = [] } = useGuiParams(gui?.slug);

  const selectedTab = useSystemLayoutStore.useSelectedTopBarTab();

  const guiTab = useMemo(() => {
    if (selectedTab != null && guiTabs) {
      return guiTabs[Number(selectedTab)];
    }
  }, [guiTabs, selectedTab]);

  const parentDatasetDesignSlug =
    guiTab?.tab_type === GuiType.RecordList ||
    guiTab?.tab_type === GuiType.Reviewer
      ? guiTab.dataset_design_slug
      : undefined;

  let datasetDesignSlug = parentDatasetDesignSlug;

  if (indexKey && path) {
    let parentTab = guiTab?.[indexKey as keyof typeof guiTab];
    const includedTab = get(parentTab, path.slice(0, -1)) as IncludeTabs;
    datasetDesignSlug = includedTab?.dataset_to_include;
  }

  const { data: parentDatasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: parentDatasetDesignSlug,
  });

  const { data: datasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: datasetDesignSlug,
  });

  const name = parentNamePath
    ? `${parentNamePath}.condition_set`
    : "condition_set";

  const { fields, remove, append } = useFieldArray({
    control,
    name,
  });

  const fieldList = useMemo(() => {
    return [...defaultFields, ...(datasetDesign?.fields?.fields ?? [])];
  }, [datasetDesign?.fields?.fields]);

  return (
    <Box sx={{ mb: 2 }}>
      <FieldWrapper
        label={
          <Typography>
            {index > 0 ? "OR " : ""}
            <Typography
              component="span"
              color={index > 0 ? "#1D850D" : undefined}
            >
              Condition Set
            </Typography>
          </Typography>
        }
        extra={
          <IconWrapper
            onClick={() => {
              onRemoveSet();
            }}
          >
            <Close fontSize="small" />
          </IconWrapper>
        }
      >
        {fields.map((field, conditionIdx) =>
          conditionIdx === 0 ? (
            <ConditionFields
              key={field.id}
              conditionIdx={conditionIdx}
              name={name}
              fieldList={fieldList}
              dynamicRHS={dynamicRHS}
              control={control}
              {...form}
            />
          ) : (
            <FieldWrapper
              key={field.id}
              sx={{ mt: 1 }}
              label={<Typography color="#6DAAF8">AND</Typography>}
              extra={
                <IconWrapper
                  onClick={() => {
                    remove(conditionIdx);
                  }}
                >
                  <Close fontSize="small" />
                </IconWrapper>
              }
            >
              <ConditionFields
                conditionIdx={conditionIdx}
                name={name}
                fieldList={fieldList}
                dynamicRHS={dynamicRHS}
                control={control}
                {...form}
              />
            </FieldWrapper>
          )
        )}
      </FieldWrapper>
      <Stack
        sx={{
          pl: 3,
          pt: 2,
          position: "relative",
          "&:before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            ml: 1,
            width: "10px",
            height: "28px",
            borderLeft: "1px dashed #6DAAF8",
            borderBottom: "1px dashed #6DAAF8",
            borderRadius: "1px",
          },
        }}
        gap={2}
        direction="row"
      >
        <Stack
          role="button"
          onClick={() => {
            append({ a: "", b: "", o: "" });
          }}
          sx={{ cursor: "pointer" }}
          direction="row"
          gap={1}
          alignItems="center"
        >
          <IconWrapper>
            <Add fontSize="small" />
          </IconWrapper>
          <Typography>
            Add{" "}
            <Typography component="span" color="#6DAAF8">
              Rule
            </Typography>
          </Typography>
        </Stack>
        <Stack
          role="button"
          onClick={() => {
            onAddSet();
          }}
          sx={{ cursor: "pointer" }}
          direction="row"
          gap={1}
          alignItems="center"
        >
          <IconWrapper>
            <Add fontSize="small" />
          </IconWrapper>
          <Typography>
            Add{" "}
            <Typography component="span" color="#1D850D">
              Condition Set
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <TagsPopper
        fields={parentDatasetDesign?.fields.fields ?? []}
        guiParams={guiParams}
      />
    </Box>
  );
};

export default ConditionSets;
