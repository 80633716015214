import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as ChattyStackSvg } from "../svg/ChattyStack.svg";

const ChattyStack: React.FC<React.ComponentProps<typeof SvgIcon>> = (props) => {
  return (
    <SvgIcon
      sx={{ color: "#2489E7" }}
      {...props}
      component={ChattyStackSvg}
      inheritViewBox
    />
  );
};

export default ChattyStack;
