import { Box } from "@mui/material";
import axios from "axios";
import Spin from "components/Spin";
import { FUSION_SESSIONS_BUCKET } from "configs/AppConfig";
import { DASHBOARD_WIDGET_TYPE } from "constants/gui";
import { SocketState } from "enums";
import { GuiType } from "enums/gui";
import { parseDashboardTabFilters } from "helpers/gui";
import useAccountSlug from "hooks/useAccountSlug";
import useCurrentUser from "hooks/useCurrentUser";
import useSocket from "hooks/useSocket";
import FusionModel from "models/Fusion";
import S3Model from "models/S3";
import { ApiModels } from "queries/apiModelMapping";
import useDeleteItem from "queries/useDeleteItem";
import {
  FC,
  PropsWithChildren,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useGuiDashboardV2Store } from "store/stores/gui-dashboard-v2";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import useGuiParamsStore from "store/stores/gui-prams";
import { useSocketStore } from "store/stores/socket";
import ChartWidget from "./ChartWidget";
import DataListWidget from "./DataListWidget";
import DividerWidget from "./DividerWidget";
import StatsWidget from "./StatsWidget";

type DashboardWidgetProps = {
  widget: DashboardWidget;
  widgetData?: Record<string, unknown>;
};

const WidgetSwitch: FC<
  DashboardWidgetProps & {
    onDelete(): void;
    setWidgetLoading(loading: boolean): void;
  }
> = (props) => {
  const { widget, widgetData, onDelete, setWidgetLoading } = props;
  switch (widget.type) {
    case DASHBOARD_WIDGET_TYPE.STAT:
      return (
        <StatsWidget
          widget={widget as DashboardWidget<"stat">}
          data={widgetData}
          onDelete={onDelete}
        />
      );
    case DASHBOARD_WIDGET_TYPE.DIVIDER:
      return (
        <DividerWidget
          widget={props.widget as DashboardWidget<"divider">}
          onDelete={onDelete}
        />
      );
    case DASHBOARD_WIDGET_TYPE.DATA_LIST:
      return (
        <DataListWidget
          widget={props.widget as DashboardWidget<"data-list">}
          onDelete={onDelete}
          data={widgetData}
          setWidgetLoading={setWidgetLoading}
        />
      );
    case DASHBOARD_WIDGET_TYPE.LINE:
    case DASHBOARD_WIDGET_TYPE.BAR:
    case DASHBOARD_WIDGET_TYPE.PIE:
      return (
        <ChartWidget
          widget={props.widget as DashboardWidget<"bar" | "line" | "pie">}
          type={props.widget.type as "pie" | "bar" | "line"}
          data={widgetData}
          onDelete={onDelete}
        />
      );
    default:
      return null;
  }
};

const DashboardWidget = forwardRef<
  unknown,
  PropsWithChildren<DashboardWidgetProps>
>((props, ref) => {
  const { widget, children, ...rest } = props;

  const accountSlug = useAccountSlug();
  const user = useCurrentUser();

  const [searchParams] = useSearchParams();
  const tab = useGuiDashboardStore.useGuiTabDraft();

  const { subscribe, unsubscribe } = useSocket();
  const socketState = useSocketStore.useState();
  const layout = useGuiDashboardV2Store.useLayout();
  const setLayout = useGuiDashboardV2Store.useSetLayout();
  const setWidgets = useGuiDashboardV2Store.useSetWidgets();
  const guiParams = useGuiParamsStore.useGuiParamValues();

  const [loading, setLoading] = useState(false);
  const [widgetData, setWidgetData] = useState<Record<string, unknown>>();

  const { mutateAsync: deleteWidget } = useDeleteItem({
    modelName: ApiModels.GuiDashboardWidgetV2,
  });

  const dataLoadCallDoneRef = useRef(false);

  useEffect(() => {
    if (
      !dataLoadCallDoneRef.current &&
      widget.associated_fusion_slug &&
      widget.type !== DASHBOARD_WIDGET_TYPE.DATA_LIST &&
      accountSlug &&
      user.slug
    ) {
      setLoading(true);
      const filters =
        tab?.tab_type === GuiType.DashboardV2 && tab.filters
          ? parseDashboardTabFilters(tab.filters, searchParams)
          : {};
      FusionModel.runFusion(widget.associated_fusion_slug, {
        user_id: user.slug,
        account_id: accountSlug,
        popup_variables: {
          popup_variables: {
            filters,
            gui_params: guiParams,
          },
          ...guiParams,
        },
      });
      dataLoadCallDoneRef.current = true;
    }
  }, [accountSlug, user.slug, widget]);

  useEffect(() => {
    if (socketState === SocketState.Open) {
      subscribe(
        "widget_data",
        widget.slug,
        (
          response: SocketResponse<{
            s3_url: string;
            s3_path: string;
            widget_slug: string;
            gui_slug: string;
            tab_slug: string;
          }>
        ) => {
          const { s3_path, widget_slug } = response.data ?? {};
          if (widget_slug === widget.slug) {
            S3Model.getDownloadUrl({
              key: s3_path,
              bucket: FUSION_SESSIONS_BUCKET,
            }).then((response) => {
              const url = response.data.url;

              if (!url) {
                return;
              }

              axios
                .get(url)
                .then((res) => {
                  const responseData = res.data as {
                    widget_data: Record<string, unknown>;
                  };
                  setWidgetData(responseData.widget_data);
                  setLoading(false);
                })
                .catch(console.error)
                .finally(() => setLoading(false));
            });
          }
        }
      );

      return () => {
        unsubscribe("widget_data", widget.slug);
      };
    }
  }, [socketState, subscribe, unsubscribe, widget]);

  const handleDelete = async () => {
    setLoading(true);

    await deleteWidget({ slug: widget.slug });

    setLayout((layout) => layout.filter((l) => l.i !== widget.slug));
    setWidgets((widgets) => widgets.filter((w) => w.slug !== widget.slug));

    setLoading(false);
  };

  const widgetLayout = layout?.find((l) => l.i === widget.slug);
  const paddingRight = widgetLayout
    ? widgetLayout.x + widgetLayout.w >= 24
      ? 0
      : 3
    : 0;

  const right = widgetLayout
    ? widgetLayout.x + widgetLayout.w >= 24
      ? 0
      : 24
    : 0;

  return (
    <Box
      {...rest}
      ref={ref}
      sx={{
        pr: paddingRight,
        pb: 3,
        ".react-resizable-handle-se": { right: `${right}px !important` },
      }}
    >
      <Spin
        spinning={loading}
        sx={{ height: "100%" }}
        backdropSx={{ background: "transparent", pointerEvents: "none" }}
      >
        <WidgetSwitch
          widget={widget}
          widgetData={widgetData}
          onDelete={handleDelete}
          setWidgetLoading={setLoading}
        />
      </Spin>
      {children}
    </Box>
  );
});

export default DashboardWidget;
