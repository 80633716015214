import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ProfileAvatar = styled(Box)(({ theme }) => ({
    background: "rgba(255, 255, 255, 0.2)",
    padding: "5px 5px",
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));