import { ColDef } from "ag-grid-community";
import RatingCellEditor from "components/DatasetTable/colDefs/rating/RatingCellEditor";
import RatingCellRenderer from "components/DatasetTable/colDefs/rating/RatingCellRenderer";

const getRatingColDef = (
  field: DataField,
  defaults: ColDef,
  options?: { disableColumnFilters?: boolean }
): ColDef => {
  return {
    ...defaults,
    cellRenderer: RatingCellRenderer,
    cellEditor: RatingCellEditor,
  };
};

export default getRatingColDef;
