import DeleteOutline from "@mui/icons-material/DeleteOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import MoreVert from "@mui/icons-material/MoreVert";
import {
  Box,
  IconButton,
  Stack,
  SxProps,
  Typography,
  styled,
} from "@mui/material";
import FusionActionsDropdown from "components/FusionAction/FusionActionsDropdown";
import { FC, PropsWithChildren } from "react";
import { useGuiDashboardV2Store } from "store/stores/gui-dashboard-v2";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";

const Container = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100%",
  background: theme.palette.background.DashboardWidgetBackground,
  border: `1px solid ${theme.palette.gfGrey.Divider}`,
  borderRadius: "6px",
  overflow: "hidden",
}));

const BottomBar = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "40px",
  paddingLeft: "10px",
  paddingRight: "10px",
  borderTop: `1px solid ${theme.palette.gfGrey.Divider}`,
}));

type WidgetWrapperProps = PropsWithChildren<{
  sx?: SxProps;
  widget: DashboardWidget;
  onDelete(): void;
}>;

const WidgetWrapper: FC<WidgetWrapperProps> = (props) => {
  const { children, sx, widget, onDelete } = props;

  const setWidget = useGuiDashboardV2Store.useSetWidget();
  const setIsWidgetEditorOpen =
    useGuiDashboardV2Store.useSetIsWidgetEditorOpen();
  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();

  return (
    <Container direction="column-reverse" alignItems="stretch">
      <BottomBar
        flexShrink={0}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontSize={15} fontWeight={300} color="text.primary">
          {widget?.title || "Widget Title"}
        </Typography>
        <FusionActionsDropdown
          widget={widget}
          widgetActionType="widget"
          actions={widget.associated_actions ?? []}
          editable={editModeEnabled}
          extraMenuItems={
            editModeEnabled
              ? [
                  {
                    title: "Edit",
                    id: "edit",
                    icon: <EditOutlined />,
                    onClick() {
                      setWidget(widget);
                      setIsWidgetEditorOpen(true);
                    },
                  },
                  {
                    title: "Delete",
                    id: "delete",
                    icon: <DeleteOutline />,
                    onClick() {
                      onDelete();
                    },
                  },
                ]
              : []
          }
        >
          <IconButton className="non-draggable-widget-area" sx={{ padding: 0 }}>
            <MoreVert sx={{ color: "#a2a2a6" }} />
          </IconButton>
        </FusionActionsDropdown>
      </BottomBar>
      <Box sx={{ flex: 1, ...sx }}>{children}</Box>
    </Container>
  );
};

export default WidgetWrapper;
