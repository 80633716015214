import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import PageMeta from "components/util-components/PageMeta";
import { enableMapSet } from "immer";
import { SnackbarProvider } from "notistack";
import useAuthenticate from "queries/auth/useAuthenticate";
import useGetAppConfig from "queries/useGetAppConfig";
import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import ThemeProvider from "theme";
import ThemeSettings from "theme/settings";
import "./App.css";
import routes from "./routes";

import { LOG_ROCKET } from "configs/AppConfig";
import GuiTabEditorProvider from "contexts/GuiTabEditorContext";
import LogRocket from "logrocket";
if (LOG_ROCKET) LogRocket.init(LOG_ROCKET);

function App() {
  const content = useRoutes(routes);
  const { isLoading } = useGetAppConfig({ enabled: true });
  useAuthenticate();

  useEffect(() => {
    enableMapSet();
  }, []);

  if (isLoading) return null;
  return (
    <React.Fragment>
      <ThemeProvider>
        <ThemeSettings>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <GuiTabEditorProvider>{content}</GuiTabEditorProvider>
          </SnackbarProvider>
        </ThemeSettings>
      </ThemeProvider>
      <PageMeta />
      <ReactQueryDevtools position="bottom-right" />
    </React.Fragment>
  );
}

export default App;
