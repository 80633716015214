import { Box, styled } from "@mui/material";
import AnimationLayout, { Config } from "layouts/AnimationLayout";
import { FC, useCallback, useState } from "react";
import ChatView from "./ChatView";
import ThreadList from "./ThreadList";

const Container = styled(Box)(({ theme }) => {
  return {
    position: "relative",
    width: "420px",
    background: theme.palette.background.InnerPageLayout,
    borderRadius: "6px 0 0 0",
  };
});

type SidebarContentProps = {
  agent: Agent;
};

const SidebarContent: FC<SidebarContentProps> = (props) => {
  const { agent } = props;

  const [thread, setThread] = useState<CopilotThread>();

  const getComponents: Config["getComponents"] = useCallback(
    (gotoComponent, goBack) => ({
      "thread-list": (
        <ThreadList
          agent={agent}
          onThreadClick={(thread) => {
            setThread(thread);
            gotoComponent({ name: "chat-view", id: thread.id });
          }}
        />
      ),
      "chat-view": thread ? (
        <ChatView thread={thread} onBack={goBack} />
      ) : (
        <></>
      ),
    }),
    [agent, thread]
  );

  return (
    <AnimationLayout
      config={{
        initialComponent: "thread-list",
        getComponents,
      }}
      updateUrl={false}
    />
  );
};

type SidebarProps = {
  agent?: Agent;
};

const Sidebar: FC<SidebarProps> = (props) => {
  const { agent } = props;

  return <Container>{agent && <SidebarContent agent={agent} />}</Container>;
};

export default Sidebar;
