import { TextField, createFilterOptions } from "@mui/material";
import GfAutoComplete from "components/FormField/GfAutoComplete";
import use3pApps from "queries/3p-app/use3pApps";
import { FC, useMemo } from "react";

const defaultFilter = createFilterOptions({
  ignoreCase: true,
  trim: true,
  matchFrom: "any",
  stringify: (option) => (option as LabeledValue).label,
  limit: 10,
});

type ThreePAppPickerProps = {
  value: string;
  allowNoneSelection?: boolean;
  disabled?: boolean;
  onChange(value: string): void;
};

const ThreePAppPicker: FC<ThreePAppPickerProps> = (props) => {
  const { value, onChange, allowNoneSelection, disabled } = props;

  const { data: apps = [], isLoading: loading } = use3pApps();

  const options: LabeledValue[] = useMemo(() => {
    const appOptions =
      apps?.map((app) => ({
        label: app.app_label,
        value: app.slug,
      })) ?? [];

    if (allowNoneSelection) {
      appOptions.unshift({
        label: "None",
        value: "none",
      });
    }

    return appOptions;
  }, [apps, allowNoneSelection]);

  return (
    <GfAutoComplete
      value={value ?? ""}
      size="small"
      sx={{
        minHeight: "40px",
      }}
      options={options}
      renderInput={(params) => <TextField {...params} />}
      onChange={(e, option) => {
        const value = (option as LabeledValue)?.value;
        if (value) {
          onChange(value);
        }
      }}
      filterOptions={defaultFilter}
      renderOption={(props, option: any) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      getOptionLabel={(option) => {
        return options?.find((op) => op.value === option)?.label ?? "";
      }}
      loading={loading}
      disabled={disabled}
    />
  );
};

export default ThreePAppPicker;
