import ContentCopyOutlined from "@mui/icons-material/ContentCopyOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import MoreVert from "@mui/icons-material/MoreVert";
import {
  Box,
  IconButton,
  Rating,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Code } from "assets/icons";
import AccountUserDataProvider from "components/DataProviders/AccountUserDataProvider";
import { DefaultCellIcon } from "components/DataTable";
import { DynamicEditFormProps } from "components/Form/DynamicEditForm";
import FormField from "components/FormField";
import { PopupField } from "components/FormField/PopupField";
import FusionActionsDropdown from "components/FusionAction/FusionActionsDropdown";
import { DateType, DocumentElementType } from "enums/Form";
import { findTabPath } from "helpers/gui";
import { TransitionComponent } from "layouts/AnimationLayout";
import isArray from "lodash/isArray";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ApiModels } from "queries/apiModelMapping";
import useDuplicateGuiData from "queries/gui/useDuplicateGuiData";
import { useGuiTabs } from "queries/gui/useGuiTabs";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import useSystemLayoutStore from "store";
import useGuiComponentPaths from "store/stores/gui-component-paths";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import useGuiTabEditor from "store/stores/gui-tab-editor";

export const BoxHeader = styled(Stack)(({ theme }) => ({
  gap: "24px",
  marginBottom: "48px",

  [`${theme.breakpoints.down("sm")}`]: {
    gap: "8px",
    margin: "-11px -15px 20px",
    padding: "11px 15px 11px",
    background: theme.palette.common.blackshades["30p"],
    borderBottom: `1px solid ${theme.palette.background.GFOutlineNav}`,
  },
}));

export const FieldValueDisplay: FC<{
  field: DataField;
  dataset?: Partial<Dataset>;
}> = (props) => {
  const { field, dataset } = props;

  const value = dataset?.fields?.[field.slug];

  if (value == null) {
    return <></>;
  }

  switch (field.type) {
    case DocumentElementType.User:
      return (
        <AccountUserDataProvider>
          {(users) => (
            <>
              {isArray(value)
                ? value
                    .map((v) => users?.find((u) => u.slug === v)?.email)
                    .filter(Boolean)
                    .join(", ")
                : users?.find((u) => u.slug === value)?.email}
            </>
          )}
        </AccountUserDataProvider>
      );
    case DocumentElementType.Select:
    case DocumentElementType.Radio:
    case DocumentElementType.Checkbox:
      return <>{isArray(value) ? value.join(", ") : `${value ?? ""}`}</>;
    case DocumentElementType.Rating:
      return (
        <Rating readOnly value={value as number} precision={0.5} size="large" />
      );
    case DocumentElementType.Image:
    case DocumentElementType.AudioVideo:
    case DocumentElementType.File:
      return (
        <>
          {isArray(value)
            ? value
                .map((v) => v.url ?? "")
                .filter(Boolean)
                .join(", ")
            : (value as { url: string })?.url ?? ""}
        </>
      );
    case DocumentElementType.CodeEditor:
      return <Code />;
    case DocumentElementType.Date: {
      if (field.date_type === DateType.DateOnly) {
        return <>{moment(value as string).format("DD-MM-YYYY")}</>;
      } else if (field.date_type === DateType.TimeOnly) {
        return <>{moment(value as string).format("HH:mm A")}</>;
      } else if (field.date_type === DateType.DateTime) {
        return <>{moment(value as string).format("DD-MM-YYYY HH:mm A")}</>;
      }

      return <>{moment(value as string).format()}</>;
    }
    default:
      return (
        <>
          {typeof value === "object" ? JSON.stringify(value) : `${value ?? ""}`}
        </>
      );
  }
};

type DataRowProps = {
  field: DataField;
  dataset?: Partial<Dataset>;
  setDataset?: Dispatch<SetStateAction<Partial<Dataset> | undefined>>;
  metadata?: FieldMetadata;
  onSubmit(): void;
};

const DataRow: FC<DataRowProps> = (props) => {
  const { dataset, field, metadata, onSubmit, setDataset } = props;

  const anchorEl = useRef<HTMLDivElement>(null);
  const revertValueRef = useRef<unknown>();

  const handleOpen = () => {
    revertValueRef.current = dataset?.fields?.[field.slug];
  };

  const handleChange = (value: unknown) => {
    setDataset?.((prev) => ({
      ...(prev ?? {}),
      fields: { ...(prev?.fields ?? {}), [field.slug]: value },
    }));
  };

  const handleCancel = () => {
    handleChange(revertValueRef.current);
  };

  return (
    <Box
      sx={{
        "&:hover": {
          background: "#2a2c30",
          borderRadius: "4px",
        },
        px: 2,
        py: 1,
        width: "100%",
      }}
      ref={anchorEl}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "15fr 1fr",
        }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <DefaultCellIcon type={field.type as DocumentElementType} />
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "250px",
            }}
            variant="body2"
            fontWeight={600}
            color="white"
          >
            {metadata?.field_label ?? field.title}:&nbsp;&nbsp;&nbsp;
            <Tooltip
              title={<FieldValueDisplay field={field} dataset={dataset} />}
              enterDelay={2000}
            >
              <Box
                sx={{ display: "inline", fontWeight: 400, color: "#a5a6a8" }}
              >
                <FieldValueDisplay field={field} dataset={dataset} />
              </Box>
            </Tooltip>
          </Typography>
        </Stack>
        <PopupField
          trigger={
            <IconButton sx={{ p: 0 }}>
              <EditOutlined fontSize="small" />
            </IconButton>
          }
          disabled={metadata?.read_only}
          name={field.slug}
          anchorEl={anchorEl}
          onOpen={handleOpen}
          onCancel={handleCancel}
          onSubmit={onSubmit}
        >
          <FormField
            label={field.title}
            type={field.type as DocumentElementType}
            value={dataset?.fields?.[field.slug]}
            onChange={(value) => handleChange(value)}
            fieldExtras={{ field }}
          />
        </PopupField>
      </Box>
    </Box>
  );
};

type SingleDatasetWidgetProps = {
  onFormEvent?: DynamicEditFormProps["onFormEvent"];
  data?: any;
  datasetDesignSlug?: string;
  datasetSlug?: string;
  includedFieldIds?: string[];
  title?: string;
  guiSlug?: string;
  tab?: IncludeTabs;
  index: number;
  isRightWidget?: boolean;
  onEdit?(dataset: Dataset): void;
};

const SingleDatasetWidget: FC<SingleDatasetWidgetProps> = (props) => {
  const {
    tab,
    guiSlug,
    datasetDesignSlug,
    datasetSlug,
    includedFieldIds,
    title,
    onEdit,
    index,
    isRightWidget = true,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedGuiTab = useSystemLayoutStore.useSelectedTopBarTab();
  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();
  const rightWidgetPaths = useGuiComponentPaths.useRightWidgetsPath();
  const middleWidgetsPath = useGuiComponentPaths.useMiddleWidgetsPath();
  const setTabEditorOpen = useGuiTabEditor.useSetEditorOpen();
  const setAdditionalTabPath = useGuiTabEditor.useSetAdditionalTabPath();
  const setEditorPath = useGuiTabEditor.useSetEditorPath();

  const [datasetDraft, setDatasetDraft] = useState<Partial<Dataset>>();

  const { data: guiTabs = [], refetch: refetchGuiTabs } = useGuiTabs(guiSlug);
  const { mutateAsync: duplicateGuiData } = useDuplicateGuiData();

  const guiTab = useMemo(() => {
    if (selectedGuiTab != null && guiTabs) {
      return guiTabs[Number(selectedGuiTab)];
    }
  }, [guiTabs, selectedGuiTab]);
  const hideGeneralTab = guiTab?.hide_general_tab;

  const tabPath = useMemo(
    () => (tab?.id ? findTabPath(guiTabs, tab.id) : ""),
    [guiTabs, tab?.id]
  );

  const { data: datasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: datasetDesignSlug,
  });

  const { data: dataset } = useGetItem({
    modelName: ApiModels.Dataset,
    slug: datasetSlug,
    requestOptions: { path: datasetDesignSlug },
    queryKey: [datasetDesignSlug, datasetSlug],
  });

  const { mutate: updateDataset } = useUpdateItem({
    modelName: ApiModels.Dataset,
    requestOptions: { path: datasetDesignSlug },
    queryKey: [ApiModels.Dataset, { datasetDesignSlug, datasetSlug }],
  });

  const includedFields = useMemo(() => {
    if (includedFieldIds?.length === 0) return [];

    if (datasetDesign?.fields?.fields?.length) {
      const includedGuiFields =
        datasetDesign?.fields?.fields
          .filter((f) => includedFieldIds?.includes(f.id!))
          .sort((a, b) => {
            const indexA = includedFieldIds?.indexOf(a.id) || 0;
            const indexB = includedFieldIds?.indexOf(b.id) || 0;
            return indexA - indexB;
          }) || [];

      return includedGuiFields;
    }
    return [];
  }, [datasetDesign?.fields?.fields, includedFieldIds]);

  useEffect(() => {
    if (dataset) setDatasetDraft(dataset);
  }, [dataset]);

  return (
    <Box
      sx={{
        px: 1,
        py: 2,
        background: "#1f2125",
        borderRadius: "4px",
      }}
    >
      <Stack direction="column" gap={2}>
        <Stack direction="row" justifyContent="space-between" sx={{ px: 1 }}>
          <Typography variant="subtitle1" component="div" sx={{ px: 1 }}>
            {title}
          </Typography>
          <FusionActionsDropdown
            actions={tab?.associated_actions ?? []}
            editable={editModeEnabled}
            pathPrefix={tabPath}
            dataset={dataset}
            extraMenuItems={
              editModeEnabled
                ? [
                    {
                      id: "edit-dataset",
                      title: "Edit Dataset",
                      icon: <EditOutlined />,
                      onClick() {
                        if (dataset) {
                          onEdit?.(dataset);
                        }
                      },
                    },
                    {
                      id: "edit-widget",
                      title: "Edit Widget",
                      icon: <EditOutlined />,
                      onClick() {
                        const paths = isRightWidget
                          ? rightWidgetPaths
                          : middleWidgetsPath;
                        if (paths.length && tab) {
                          searchParams.set("selectedTabId", tab.id);
                          setSearchParams(searchParams);
                          const additionalPath = [
                            ...paths.map((p) => `${p}`),
                            "additional_tabs",
                            `${index}`,
                            "additional_tabs",
                          ];
                          setAdditionalTabPath(additionalPath.slice(1));
                          const compHistory: TransitionComponent[] = [];
                          for (let i = 0; i < additionalPath.length; i += 2) {
                            const propName = additionalPath[i];
                            const propIndex = Number(additionalPath[i + 1]);
                            if (propName && !isNaN(propIndex)) {
                              if (propName === "included_sidebar_widgets") {
                                compHistory.push({ name: "main", id: "main" });
                              } else {
                                compHistory.push({
                                  name: "additional-widget",
                                  id: tab.id,
                                });
                              }
                            }
                          }
                          setEditorPath(compHistory);
                          setTabEditorOpen(true);
                        } else if (tab) {
                          searchParams.set("selectedTabId", tab.id);
                          setSearchParams(searchParams);
                          const additionalPath = [
                            `${index}`,
                            "additional_tabs",
                          ];
                          if (!isRightWidget) {
                            const mainTabIndex = Number(
                              searchParams.get("t") ?? "1"
                            );
                            additionalPath.unshift("additional_tabs");
                            additionalPath.unshift(
                              hideGeneralTab
                                ? `${mainTabIndex}`
                                : `${mainTabIndex - 1}`
                            );
                          }
                          setAdditionalTabPath(additionalPath);
                          setEditorPath([
                            {
                              id: "main",
                              name: "main",
                            },
                            {
                              id: tab.id,
                              name: isRightWidget
                                ? "additional-widget"
                                : "additional-tabs",
                            },
                          ]);
                          setTabEditorOpen(true);
                        }
                      },
                    },
                    {
                      id: "duplicate-widget",
                      title: "Duplicate Widget",
                      icon: <ContentCopyOutlined />,
                      async onClick() {
                        if (!guiTab?.slug) {
                          return;
                        }

                        const paths = isRightWidget
                          ? rightWidgetPaths
                          : middleWidgetsPath;
                        if (rightWidgetPaths.length > 0) {
                          const widgetPath = [
                            ...paths.map((p) => `${p}`),
                            "additional_tabs",
                            `${index}`,
                          ];
                          await duplicateGuiData({
                            type: "widget",
                            tabSlug: guiTab?.slug,
                            widgetPath,
                          });
                          await refetchGuiTabs();
                          enqueueSnackbar({
                            message: "Duplication in progress",
                            variant: "success",
                          });
                        } else {
                          const widgetPath = [
                            isRightWidget
                              ? "included_sidebar_widgets"
                              : "included_tabs",
                            `${index}`,
                          ];

                          await duplicateGuiData({
                            type: "widget",
                            tabSlug: guiTab?.slug,
                            widgetPath,
                          });
                          await refetchGuiTabs();
                          enqueueSnackbar({
                            message: "Duplication in progress",
                            variant: "success",
                          });
                        }
                      },
                    },
                  ]
                : [
                    {
                      id: "edit-dataset",
                      title: "Edit Dataset",
                      icon: <EditOutlined />,
                      onClick() {
                        if (dataset) {
                          onEdit?.(dataset);
                        }
                      },
                    },
                  ]
            }
          >
            <IconButton sx={{ p: 0 }}>
              <MoreVert />
            </IconButton>
          </FusionActionsDropdown>
        </Stack>
        <Stack direction="column" gap={1} sx={{ width: "100%" }}>
          {includedFields.map((field) => (
            <DataRow
              field={field}
              key={field.slug}
              dataset={datasetDraft}
              metadata={tab?.field_metadata?.[field.id]}
              setDataset={setDatasetDraft}
              onSubmit={() => {
                if (datasetSlug && datasetDraft) {
                  updateDataset({
                    slug: datasetSlug,
                    data: datasetDraft,
                  });
                }
              }}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default SingleDatasetWidget;
