import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type State = {
  leftBarCollapsed: boolean;
  isScratchpadActive: boolean;
  selectedScratchpad: Scratchpad | null;
  selectedThread: CopilotThread | null;
  showLoadingMessage: string | null;
  searchValue: string;
  chatStatusMessage: string;
};

type Actions = {
  setShowLoadingMessage(loading: string | null): void;
  setLeftBarCollapsed(collapsed: boolean): void;
  setIsScratchpadActive(active: boolean): void;
  setSelectedThread(thread: CopilotThread | null): void;
  setSearchValue(value: string): void;
  setSelectedScratchpad(scratchpad: Scratchpad | null): void;
  setChatStatusMessage(message: string): void;
};

export const useAgentChatStoreBase = create(
  devtools(
    immer<State & Actions>((set) => ({
      leftBarCollapsed: false,
      isScratchpadActive: false,
      selectedThread: null,
      showLoadingMessage: null,
      searchValue: "",
      selectedScratchpad: null,
      chatStatusMessage: "",
      setLeftBarCollapsed(collapsed) {
        set((state) => {
          state.leftBarCollapsed = collapsed;
        });
      },
      setIsScratchpadActive(active) {
        set((state) => {
          state.isScratchpadActive = active;
        });
      },
      setSelectedThread(thread) {
        set((state) => {
          state.selectedThread = thread;
        });
      },
      setShowLoadingMessage(loading) {
        set((state) => {
          state.showLoadingMessage = loading;
        });
      },
      setSearchValue(value) {
        set((state) => {
          state.searchValue = value;
        });
      },
      setSelectedScratchpad(scratchpad) {
        set((state) => {
          state.selectedScratchpad = scratchpad;
        });
      },
      setChatStatusMessage(message) {
        set((state) => {
          state.chatStatusMessage = message;
        });
      },
    }))
  )
);

const useAgentChatStore = createSelectorHooks(useAgentChatStoreBase);

export default useAgentChatStore;
